import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormProps } from './Form';

export const useStyles = makeStyles<
  Theme,
  Pick<FormProps<{}>, 'noElevation' | 'inline'>
>((theme) => ({
  root: {
    padding: ({ noElevation }) => (noElevation ? 0 : 16),
    display: ({ inline }) => (inline ? 'inline-block' : 'block'),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    padding: theme.spacing(4),
  },
  title: {
    textOverflow: 'ellipsis',
    width: '100%',
  },
}));
