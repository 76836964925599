import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './ui/app/App';

import { Settings } from 'luxon';
import './i18n/i18n';
import reportWebVitals from './reportWebVitals';
import useHotjar from 'react-use-hotjar';

import { register } from './serviceWorkerRegistration';

Settings.defaultLocale = 'pl';
Settings.defaultZone = 'Europe/Warsaw';

const HotjarReadyApp = () => {
  const { initHotjar } = useHotjar();

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      initHotjar(2570072, 6, false, console.info);
    }
  }, [initHotjar]);

  return <App />;
};

ReactDOM.render(<HotjarReadyApp />, document.getElementById('root'));

register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
  immediate: true,
});

// If you want to sttart measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
