import { Box, Link, Paper, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { toLocaleDateTime } from '../../../../../common/utils/date.utils';
import {
  Maybe,
  MessageType,
  useGetMessageQuery,
} from '../../../../../generated/graphql';
import { useMessagesApi } from '../hooks/useMessagesApi';

export const MessageView: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useGetMessageQuery({
    variables: {
      messageId: parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  const { onMarkMessagesAsRead } = useMessagesApi();

  useEffect(() => {
    if (!data?.message?.read && data?.message.id) {
      onMarkMessagesAsRead([data.message.id], true);
    }
  }, [data?.message, onMarkMessagesAsRead]);

  const CTAComponent = useCTAButton(
    data?.message.messageType,
    data?.message.linkedEntityId,
  );

  if (!data?.message) return null;

  return (
    <Box maxWidth={{ xs: '100%', md: '50%' }}>
      <Paper>
        <Box p={12} display="flex" flexDirection="column" gap={12}>
          <Typography variant="h6">{data.message.title}</Typography>
          <Typography variant="caption">
            {toLocaleDateTime(data.message.createdAt, DateTime.DATETIME_MED)}
          </Typography>
          <span
            dangerouslySetInnerHTML={{ __html: data.message.content }}
          ></span>
          {CTAComponent}
        </Box>
      </Paper>
    </Box>
  );
};

const useCTAButton = (
  messageType?: MessageType,
  linkedEntityId?: Maybe<number>,
) => {
  const history = useHistory();
  const { t } = useTranslation();

  if (!messageType || !linkedEntityId) return null;

  switch (messageType) {
    case MessageType.SharedStudy:
      return (
        <Link
          component="button"
          variant="h6"
          onClick={() => history.push(`/studies/view/${linkedEntityId}`)}
        >
          {t('app.messages.templates.SharedStudy.linkToStudy')}
        </Link>
      );

    default:
      return null;
  }
};
