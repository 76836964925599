import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useMessages = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return {
    success: (message?: string) =>
      enqueueSnackbar(message || t('app.messages.saveSuccess'), {
        variant: 'success',
      }),
    notFound: (error?: string, id?: number | string) =>
      enqueueSnackbar(error || t('app.messages.notFound', { id }), {
        variant: 'error',
      }),
    deleteSuccess: () =>
      enqueueSnackbar(t('app.messages.deleteSuccess'), {
        variant: 'success',
      }),
  };
};
