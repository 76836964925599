import React, { PropsWithChildren } from 'react';
import { UserRole } from '../../../../../generated/graphql';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { useAppContextState } from '../../settings/hooks/useSettingsState';

type Props = {
  roles: UserRole[];
  /**
   * If this is set to true all the roles of the user will be inspected and matched againes `roles` passed as prop.
   * Otherwise _**only**_ the `currentRole` (the role user is logged with) from app context will be used for matching.
   */
  checkAllRoles?: boolean;
};

export const Protect: React.FC<PropsWithChildren<Props>> = ({
  roles,
  children,
  checkAllRoles = false,
}) => {
  const { isAuthenticated, getAuthInfo } = useAuth();
  const [appContext] = useAppContextState();

  const hasRole = roles?.some((role) => {
    if (checkAllRoles) {
      return getAuthInfo().roles.includes(role);
    } else {
      return role === appContext.currentRole;
    }
  });

  if (isAuthenticated() && hasRole) return <>{children}</>;
  else return null;
};
