import { useDeleteFileMutation } from '../../generated/graphql';

export const useFileDelete = () => {
  const [deleteFile] = useDeleteFileMutation();

  return async (fileId: number) => {
    const response = await deleteFile({ variables: { id: fileId } });
    return response.data?.deleteFile || false;
  };
};
