import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ActionPanel } from '../../../../../common/components/action-panel/ActionPanel';
import { DateField } from '../../../../../common/components/form/fields/DateField';
import { MultiCheckboxField } from '../../../../../common/components/form/fields/MultiCheckboxField';
import { RichTextField } from '../../../../../common/components/form/fields/RichTextField';
import { SelectField } from '../../../../../common/components/form/fields/SelectField';
import { SwitchField } from '../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../common/components/form/Form';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { useSSNCheck } from '../../../../../common/hooks/useSSNCheck';
import { ItemActionsConfig } from '../../../../../common/types/actions';
import {
  extractBirthDateFromPESEL,
  extractGenderFromPESEL,
} from '../../../../../common/utils/pesel.utils';
import { patientValidationSchema } from '../../../../../common/validation/patient/schema';
import {
  Gender,
  MediaType,
  Referal,
  ReferalInput,
  ReferalToStudyType,
  StudyType,
  UserRole,
} from '../../../../../generated/graphql';
import { SSNField } from '../../common/fields/SSNField';
import { DoctorAutocomplete } from '../../doctors/fields/DoctorAutocomplete';
import { ReferalActionType } from '../hooks/useReferalActions';
import { useStyles } from './ReferalForm.styles';

type Props = {
  onSubmit: (data: ReferalInput) => void;
  onCancel: () => void;
  referal?: ReferalInput | Referal;
  disabled?: boolean;
  studyTypes?: StudyType[];
  actions?: ItemActionsConfig<Referal, ReferalActionType>;
};

export const MediaTypesDict: { [key in MediaType]: string } = {
  Online: 'Portal lekarza',
  Disc: 'Płyta',
  Film: 'Klisza',
  Pendrive: 'Pendrive (+20 PLN)',
};

const MediaTypeItems = Object.entries(MediaTypesDict).map(([key, value]) => ({
  id: key,
  name: value,
  disabled: key === 'Online',
}));

const useSubmitReferal = ({ onSubmit, referal }: Props) => {
  const submitReferal = (formData: ReferalInput) => {
    const referalToStudyTypes = formData.referalToStudyTypes.map((entry) => ({
      id:
        referal?.referalToStudyTypes?.find(
          ({ studyType }) => studyType.id === entry.studyType.id,
        )?.id || null,
      studyType: { id: entry.studyType.id },
      remarks: entry.remarks,
      studySubTypes: entry.studySubTypes,
    }));
    const mediaTypes = formData.mediaTypes
      .map((mt: any) => mt.id)
      .filter((mt) => mt);
    const patient = formData.patient;
    const issuer = { id: formData.issuer?.id ?? null };
    const description = formData.description;
    const remarks = formData.remarks;
    const requestDescription = formData.requestDescription;

    onSubmit({
      referalToStudyTypes,
      mediaTypes,
      patient,
      issuer,
      description,
      remarks,
      requestDescription,
    });
  };

  return { submitReferal };
};

const schema = (usersEmail: string, checkSSN?: boolean) =>
  yup.object().shape({
    issuer: yup.object().nullable().autocompleteRequired() as any,
    // patient: yup.object().nullable().autocompleteRequired(),
    patient: patientValidationSchema(usersEmail, checkSSN) as any,
    referalToStudyTypes: yup.array().required().min(1) as any,
    mediaTypes: yup.array().required().min(1) as any,
  });

const useIsDoctor = () => {
  const { getAuthInfo } = useAuth();
  const { roles } = getAuthInfo();
  return roles.includes(UserRole.Doctor) || !roles.includes(UserRole.Admin);
};

export const ReferalForm: React.FC<PropsWithChildren<Props>> = (props) => {
  const { referal, disabled = false, children, studyTypes, actions } = props;
  const { submitReferal } = useSubmitReferal(props);
  const { t } = useTranslation();
  const isDoctor = useIsDoctor();
  const classes = useStyles();

  const isAdd = isNil((referal as Referal).id);

  const { getAuthInfo } = useAuth();

  const { checkSSN, setCheckSSN } = useSSNCheck(referal?.patient?.contact?.ssn);

  const genderOptions = Object.values(Gender).map((gender) => ({
    value: gender,
    label: t(`fields.contact.gender.${gender}`),
  }));

  //const [usingExistingPatient, setUsingExistingPatient] = useState(false);

  return (
    <div className={classes.root}>
      <Form<ReferalInput>
        onSubmit={submitReferal}
        formOptions={{
          defaultValues: referal,
          resolver: yupResolver(schema(getAuthInfo().login, checkSSN)) as any,
        }}
        heading={
          actions &&
          referal && (
            <ActionPanel
              config={actions}
              alignment="right"
              item={referal as Referal}
            />
          )
        }
        render={({ setValue, register, clearErrors, trigger }) => {
          return (
            <Box display="flex" flexDirection="column">
              <Box
                mx="auto"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h4" className={classes.title}>
                  {t('pages.referal.add.title')}
                </Typography>
                <Typography variant="h5">
                  {t('pages.referal.add.subtitle')}
                </Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="patient.contact.lastName"
                    label={t('pages.referal.add.patientLastname')}
                    disabled={disabled /* || usingExistingPatient */}
                    required
                  />
                  {/*<PatientAutocomplete
                  controllerProps={{
                    required: true,
                    label: t('pages.referal.add.patientLastname'),
                  }}
                  autocompleteProps={{
                    freeSolo: true,
                    multiple: false,
                    disabled,
                    onChange: (_, val) => {
                      if (typeof val === 'string') {
                        setUsingExistingPatient(false);
                        setValue('patient', { lastName: val });
                      } else {
                        setUsingExistingPatient(true);
                        setValue(
                          'patient.contact.firstName',
                          val?.contact.firstName,
                        );
                        setValue(
                          'patient.contact.lastName',
                          val?.contact.lastName,
                        );
                        setValue(
                          'patient.contact.birthDate',
                          val?.contact.birthDate,
                        );
                        setValue(
                          'patient.contact.emailAddress[0]',
                          val?.contact.,
                        );
                        setValue(
                          'patient.contact.emailAddress',
                          val?.contact.firstName,
                        );
                      }
                    },
                    getOptionLabel: ({ contact }) => contact?.lastName,
                    renderOption: DEFAULT_PATIENT_RENDER_OPTION,
                  }}
                />*/}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="patient.contact.firstName"
                    label={t('pages.referal.add.patientFirstname')}
                    disabled={disabled /* || usingExistingPatient*/}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  <Box display="flex" gap={4}>
                    <SSNField
                      validate={checkSSN}
                      onValidationStateChange={(validationState) => {
                        setCheckSSN(validationState);
                        clearErrors('patient.contact.ssn');
                      }}
                      name="patient.contact.ssn"
                      disabled={disabled}
                      fullWidth
                      variant="outlined"
                      required
                      onBlur={(event) => {
                        const fieldValue = event.target.value;
                        setValue(
                          'patient.contact.birthDate',
                          extractBirthDateFromPESEL(fieldValue),
                        );
                        setValue(
                          'patient.contact.gender',
                          extractGenderFromPESEL(fieldValue),
                        );

                        if (fieldValue) {
                          trigger('patient.contact.birthDate');
                          trigger('patient.contact.gender');
                        }
                      }}
                    />
                    <SelectField
                      name="patient.contact.gender"
                      label="Płeć"
                      options={genderOptions}
                      required
                      disabled={disabled}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DateField
                    disableFuture
                    openTo="year"
                    views={['year', 'month', 'day']}
                    fullWidth
                    name="patient.contact.birthDate"
                    variant="outlined"
                    showDateFormatPlaceholder={false}
                    label={t('pages.referal.add.patientBirthDate')}
                    disabled={disabled /* || usingExistingPatient*/}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="patient.contact.emailAddresses[0]"
                    variant="outlined"
                    label={t('pages.referal.add.patientEmail')}
                    InputLabelProps={{ shrink: true }}
                    disabled={disabled /* || usingExistingPatient*/}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="patient.contact.phoneNumbers[0]"
                    variant="outlined"
                    label={t('pages.referal.add.patientPhone')}
                    InputLabelProps={{ shrink: true }}
                    disabled={disabled /* || usingExistingPatient*/}
                  />
                </Grid>
              </Grid>
              {!isDoctor ? (
                <DoctorAutocomplete
                  controllerProps={{
                    name: 'issuer',
                    required: true,
                  }}
                  autocompleteProps={{ disabled }}
                />
              ) : (
                <input
                  {...register('issuer.id', { valueAsNumber: true })}
                  type="hidden"
                />
              )}

              <Box mt={4}>
                <MultiCheckboxField
                  name="referalToStudyTypes"
                  items={studyTypes?.filter(
                    (studyType) => studyType.onReferalOrder,
                  )}
                  label={t('fields.studyTypes.label')}
                  disabled={disabled}
                  showComment={({ id: idc }) =>
                    studyTypes?.find(({ id }) => id === idc)?.hasRemarks ||
                    false
                  }
                  subTypeOptions={({ id: idc }) =>
                    studyTypes?.find(({ id }) => id === idc)?.studySubTypes ||
                    []
                  }
                  //commentLabel={t('fields.studyTypes.commentLabel')}
                  required
                  dataConverter={{
                    from: (item) => ({
                      studyType: { id: item.id },
                      remarks: item.remarks,
                      studySubTypes: item.subTypes,
                    }),
                    to: ({
                      studyType,
                      remarks,
                      studySubTypes,
                    }: ReferalToStudyType) => ({
                      id: studyType.id,
                      name: studyType.name,
                      remarks: remarks ?? '',
                      subTypes: studySubTypes,
                    }),
                  }}
                />
              </Box>
              <Box mt={4} mb={8}>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MultiCheckboxField
                      name="mediaTypes"
                      items={MediaTypeItems}
                      label={t('fields.mediaTypes')}
                      disabled={disabled}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SwitchField
                      label="Prośba o opis"
                      name="requestDescription"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RichTextField
                      name="description"
                      label="Cel badania..."
                      defaultValue={referal?.description}
                      readOnly={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RichTextField
                      name="remarks"
                      label="Uwagi..."
                      defaultValue={referal?.remarks}
                      readOnly={disabled}
                    />
                  </Grid>
                </Grid>
              </Box>
              {!disabled && (
                <Button variant="contained" type="submit" color="primary">
                  {isAdd
                    ? t('pages.referal.actions.save.add')
                    : t('pages.referal.actions.save.edit')}
                </Button>
              )}
              {children}
            </Box>
          );
        }}
      ></Form>
    </div>
  );
};
