import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  useAddEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  CreateEmailTemplate,
  useDeleteEmailTemplateMutation,
  EmailTemplateDocument,
  EmailTemplateQuery,
} from '../../../../../generated/graphql';
import { useHistory, useRouteMatch } from 'react-router';
import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';

export const useEmailTemplateApi = () => {
  const { success, deleteSuccess } = useMessages();
  const history = useHistory();

  const [addEmailTemplate] = useAddEmailTemplateMutation({
    onCompleted: () => success(),
  });
  const [updateEmailTemplate] = useUpdateEmailTemplateMutation({
    onCompleted: () => success(),
  });
  const [deleteEmailTemplate] = useDeleteEmailTemplateMutation({
    onCompleted: () => deleteSuccess(),
  });

  const { path } = useRouteMatch();
  const confirmRemoval = useRemoveConfirm();

  const onAddSubmit = (emailTemplateInput: CreateEmailTemplate) => {
    addEmailTemplate({
      variables: { newEmailTemplate: emailTemplateInput },
      update: (cache, { data: newEmailTemplate }) => {
        const data = cache.readQuery<EmailTemplateQuery>({
          query: EmailTemplateDocument,
        });
        const emailTemplate = data?.emailTemplates.edges;

        if (emailTemplate && newEmailTemplate) {
          cache.writeQuery<EmailTemplateQuery>({
            query: EmailTemplateDocument,
            data: {
              emailTemplates: {
                edges: [
                  ...emailTemplate,
                  {
                    node: newEmailTemplate?.createOneEmailTemplate,
                  },
                ],
              },
            },
          });
        }
      },
    });

    history.push(`${path}/`, { useCache: false });
  };

  const onSaveSubmit = (
    { id, ...emailTemplate }: CreateEmailTemplate,
    emailTemplateId: number,
  ) => {
    updateEmailTemplate({
      variables: { emailTemplate, emailTemplateId },
    });

    history.push(`${path}/`, { useCache: false });
  };

  const onDeleteEmailTemplate = (emailTemplateId: number) => {
    confirmRemoval();
    deleteEmailTemplate({
      variables: { emailTemplateId },
      update: (cache) => {
        const data = cache.readQuery<EmailTemplateQuery>({
          query: EmailTemplateDocument,
        });
        const emailTemplate = data?.emailTemplates.edges;

        if (emailTemplate) {
          cache.writeQuery<EmailTemplateQuery>({
            query: EmailTemplateDocument,
            data: {
              emailTemplates: {
                edges: emailTemplate.filter(
                  ({ node: { id } }) => id !== emailTemplateId,
                ),
              },
            },
          });
        }
      },
    });
  };

  return { onAddSubmit, onSaveSubmit, onDeleteEmailTemplate };
};
