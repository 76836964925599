import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { ActionTypes } from 'react-apollo-network-status';
import { OperationError } from 'react-apollo-network-status/dist/src/useApolloNetworkStatus';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAppContextState } from '../../../ui/app/components/settings/hooks/useSettingsState';
import { ApolloNetworkStatusHook, ApolloNetworkStatusReducer } from './types';

type Props = {
  useApolloNetworkStatus: ApolloNetworkStatusHook;
  useApolloNetworkStatusReducer: ApolloNetworkStatusReducer;
};

export function isTokenExpiredError(error?: OperationError | ApolloError) {
  return error?.graphQLErrors?.some(
    (error) => error?.message === 'jwt expired',
  );
}

export function isMaintenanceError(error?: OperationError | ApolloError) {
  return error?.graphQLErrors?.some(
    (error) => error?.extensions?.originalError?.statusCode === 503,
  );
}

export function isNetworkError(error?: OperationError | ApolloError) {
  return !!error?.networkError;
}

export const GlobalErrorHandler: React.FC<Props> = ({
  useApolloNetworkStatus,
  useApolloNetworkStatusReducer,
}) => {
  const { queryError, mutationError } = useApolloNetworkStatus();

  useApolloNetworkStatusReducer((state, action) => {
    if (action.type === ActionTypes.SUCCESS) {
      window.localStorage.removeItem('reloadedOnNetworkError');
    }
    return state;
  }, {});

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [{ currentRole }] = useAppContextState();

  useEffect(() => {
    if (
      isTokenExpiredError(queryError || mutationError) &&
      !history.location.pathname.includes('login') &&
      !!currentRole
    ) {
      enqueueSnackbar(t('auth.errors.tokenExpiredMessage'), {
        variant: 'info',
        preventDuplicate: true,
      });
      window.localStorage.setItem('reloadedOnNetworkError', 'true');
      history.push('/login');
    } else if (isMaintenanceError(queryError || mutationError)) {
      enqueueSnackbar(t('auth.errors.maintenanceMessage'), {
        variant: 'error',
        preventDuplicate: true,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 5000,
      });
      history.push('/login');
      setTimeout(() => window.location.reload(), 5000);
    } else if (
      isNetworkError(queryError || mutationError) &&
      !window.location.hostname.includes('localhost')
    ) {
      if (!(window.localStorage.getItem('reloadedOnNetworkError') === 'true')) {
        window.localStorage.setItem('reloadedOnNetworkError', 'true');
      } else {
        enqueueSnackbar(t('app.errors.networkFailure'), {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    }
  }, [currentRole, enqueueSnackbar, history, mutationError, queryError, t]);

  return null;
};
