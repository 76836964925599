import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AssignmentOutlined,
  Event,
  GetApp,
  ImageSearchOutlined,
  Room,
  SvgIconComponent,
} from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { titleCase } from 'title-case';

import { useStyles } from './PatientStudyCard.styles';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { toLocaleDateTime } from '../../../../../../common/utils/date.utils';
import { PatientStudy } from '../../../../../../generated/graphql';
import { DownloadMultipleFileButton } from './DownloadMultipleFileButton';

import {
  InstructionDownloadLink,
  PatientHelpPopover,
} from '../PatientHelpPopup';
import { Outline } from '../../../../../../common/components/form/LabeledOutline';

export const PatientStudyCardSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
        >
          <Skeleton variant="circular" width={120} height={120} />

          <Box m={8} display="flex" flexDirection="column">
            <Skeleton variant="rectangular" width={150} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export const PatientStudyCard: React.FC<PatientStudy> = ({
  studyDate,
  studyType,
  is3D,
  descriptionFileURLs,
  id,
  studyFileURLs,
  studyImageFileURL,
  branchName,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  return (
    <Card className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Typography gutterBottom variant="h6" component="h6">
          {studyType}
        </Typography>
        <PatientHelpPopover />
      </Box>
      <CardContent>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
        >
          <Box ml={8}>
            <PatientStudyCardLine Icon={Event}>
              <Typography variant="body2">
                <strong>{t('pages.patient-studies.labels.study-date')}</strong>
                {toLocaleDateTime(studyDate, DateTime.DATE_SHORT)}
              </Typography>
            </PatientStudyCardLine>
            <PatientStudyCardLine Icon={Room}>
              <Typography variant="body2">
                <strong>{t('pages.patient-studies.labels.branch')}</strong>{' '}
                {titleCase(branchName.toLowerCase())}
              </Typography>
            </PatientStudyCardLine>
          </Box>
        </Box>
      </CardContent>

      <CardActions>
        <Box
          display="flex"
          flexDirection={{ md: 'column', lg: 'row' }}
          gap={4}
          flexWrap="wrap"
          width="100%"
        >
          {studyFileURLs && (
            <DownloadMultipleFileButton
              fileUris={studyFileURLs}
              studyId={id}
              icon={<GetApp />}
              label={t('pages.patient-studies.actions.download-study')}
            />
          )}
          {studyImageFileURL && (
            <Button
              color="secondary"
              target="_blank"
              href={studyImageFileURL}
              startIcon={<ImageSearchOutlined />}
              variant="outlined"
              fullWidth
            >
              {t('pages.patient-studies.actions.preview-study')}
            </Button>
          )}
          {descriptionFileURLs && (
            <DownloadMultipleFileButton
              fileUris={descriptionFileURLs}
              studyId={id}
              icon={<AssignmentOutlined />}
              label={t('pages.patient-studies.actions.download-description')}
            />
          )}
        </Box>
      </CardActions>
      <Divider />
      <Box mt={6} />
      {is3D && (
        <Outline label={t('pages.patient-studies.help.files-to-download')}>
          <Box display="flex" flexDirection="column" gap={8} p={4}>
            <InstructionDownloadLink />

            <Link
              style={{ cursor: 'pointer' }}
              target="_blank"
              href="https://www.diagdent.pl/obrazki/file/ViewData.exe"
            >
              {t('pages.patient-studies.help.viewer-download')}
            </Link>
          </Box>
        </Outline>
      )}
    </Card>
  );
};

const PatientStudyCardLine: React.FC<PropsWithChildren<{
  Icon: SvgIconComponent;
}>> = ({ Icon, children }) => (
  <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
    <Box display="flex" alignItems="center" mr={2}>
      <Icon fontSize="small" color="disabled" />
    </Box>
    {children}
  </Box>
);
