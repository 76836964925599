import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CancelButton } from '../../../../../../common/components/button/CancelButton';
import { AddressField } from '../../../../../../common/components/form/fields/AddressField';
import { SwitchField } from '../../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../../common/components/form/Form';
import {
  BranchInput,
  useAppConfigQuery,
  useBranchQuery,
} from '../../../../../../generated/graphql';
import { TagsField } from '../../../tags/TagsField';
import { BranchDevices } from './components/BranchDevices';
import { branchValidationSchema } from './validation/schema';
import { SelectField } from '../../../../../../common/components/form/fields/SelectField';

type Props = {
  onSubmit: (data: BranchInput) => void;
  branchId?: number;
};

export const BranchForm: React.FC<Props> = ({ onSubmit, branchId }) => {
  const { t } = useTranslation();
  const [showAddressField, setShowAddressField] = React.useState(false);

  const { data: appConfig } = useAppConfigQuery();
  const { data: branch } = useBranchQuery({
    variables: {
      id: branchId as number,
    },
    skip: !branchId,
  });

  React.useEffect(() => {
    if (branch?.branch?.address) {
      setShowAddressField(true);
    }
  }, [branch?.branch?.address]);

  const regions = appConfig?.appConfig?.regions;

  return (
    <Form<BranchInput>
      onSubmit={onSubmit}
      formOptions={{
        defaultValues: branch?.branch || {
          name: '',
        },
        resolver: yupResolver(branchValidationSchema()) as any,
      }}
      render={({ getValues }) => (
        <>
          <Box display="flex" alignItems="center" gap={4}>
            <SwitchField
              name="ownBranch"
              label={t('settings.branch.fields.ownField')}
            />
            <TextField
              name="name"
              label={t('settings.branch.fields.name')}
              fullWidth
            />
            {regions && (
              <SelectField
                fullWidth
                name="region"
                options={regions.map((region) => ({
                  label: region,
                  value: region,
                }))}
                label={t('settings.branch.fields.region')}
              />
            )}
            <TextField
              name="color"
              label={t('settings.branch.fields.color')}
              type="color"
              fullWidth
            />
          </Box>
          {getValues('ownBranch') && (
            <Box
              mt={6}
              paddingBottom={8}
              display="flex"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row' }}
              gap={6}
            >
              <Box display="flex" flex="1">
                <BranchDevices />
              </Box>
            </Box>
          )}

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TagsField
                name="phoneNumbers"
                label={t('settings.branch.fields.phoneNumbers')}
                placeholder="Dodaj kolejny..."
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TagsField
                name="emailAddresses"
                label={t('settings.branch.fields.emailAddresses')}
                placeholder="Dodaj kolejny..."
              />
            </Grid>
          </Grid>

          <Box mt={6}>
            {showAddressField && (
              <AddressField
                label={t('app.common.fields.address')}
                addressFieldName="address"
              />
            )}

            <Box display={showAddressField ? 'none' : 'block'}>
              <Button color="primary" onClick={() => setShowAddressField(true)}>
                {t('app.common.fields.addAddress')}
              </Button>
            </Box>
          </Box>

          <Box mt={8}>
            <Button fullWidth variant="contained" type="submit" color="primary">
              {t('app.common.actions.save')}
            </Button>
            <CancelButton />
          </Box>
        </>
      )}
    />
  );
};
