import { Button, Menu, MenuItem } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import React, { useState } from 'react';
import {
  DownloadFileButton,
  DownloadFileButtonProps,
} from '../../../../components/files/DownloadFileButton';

type Props = Omit<DownloadFileButtonProps, 'fileUri'> & {
  fileUris: string[];
};

export const DownloadMultipleFileButton: React.FC<Props> = ({
  fileUris,
  studyId,
  icon,
  label,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);

  if (fileUris.length === 0) {
    return null;
  } else if (fileUris.length === 1) {
    return (
      <DownloadFileButton
        fileUri={fileUris[0]}
        icon={icon}
        label={label}
        studyId={studyId}
      />
    );
  }

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<CloudDownload />}
        aria-controls="multiple-file-menu"
        aria-haspopup="true"
        fullWidth
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {label}
      </Button>
      <Menu
        id="download-files-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fileUris.map((fileUri) => (
          <MenuItem key={fileUri}>
            <DownloadFileButton
              fileUri={fileUri}
              icon={icon}
              filenameAsLabel
              studyId={studyId}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
