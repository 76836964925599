import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import pl from './pl.json';
import en from './en.json';
import ua from './ua.json';
import { getRawLang } from '../common/components/form/validation/yup-locale';

// the translations
// (tip move them in a JSON file and import them)
export const resources = {
  pl: {
    translation: pl,
  },
  en: {
    translation: en,
  },
  ua: {
    translation: ua,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getRawLang(),

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
