import { useSnackbar } from 'notistack';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import {
  useGetProfileQuery,
  useUpdateStampPictureMutation,
  useDeleteExistingStampPictureMutation,
  GetProfileQuery,
  GetProfileDocument,
} from '../../../../../generated/graphql';

export const useMyStampPicture = () => {
  const snackBarError = useGraphQLError();
  const { enqueueSnackbar } = useSnackbar();

  const { data: profile } = useGetProfileQuery({
    onError: snackBarError,
    fetchPolicy: 'network-only',
  });

  const [updateStampPicture] = useUpdateStampPictureMutation({
    onError: snackBarError,

    onCompleted: () =>
      enqueueSnackbar('Zdjęcie pieczątki zostało ustawione', {
        variant: 'success',
      }),
    update: (cache, result) => {
      const data = cache.readQuery<GetProfileQuery>({
        query: GetProfileDocument,
      });
      const currentDoctor = data?.currentDoctor;

      if (currentDoctor) {
        cache.writeQuery<GetProfileQuery>({
          query: GetProfileDocument,
          data: {
            currentDoctor: {
              ...currentDoctor,
              stampImage: result.data?.updateStampPicture,
            },
          },
        });
      }
    },
    refetchQueries: ['GetProfile'],
  });

  const [deleteExistingStampPicture] = useDeleteExistingStampPictureMutation({
    onError: snackBarError,

    onCompleted: () =>
      enqueueSnackbar('Zdjęcie pieczątki zostało usunięte', {
        variant: 'success',
      }),
    update: (cache) => {
      const data = cache.readQuery<GetProfileQuery>({
        query: GetProfileDocument,
      });
      const currentDoctor = data?.currentDoctor;

      if (currentDoctor) {
        cache.writeQuery<GetProfileQuery>({
          query: GetProfileDocument,
          data: {
            currentDoctor: { ...currentDoctor, stampImage: null },
          },
        });
      }
    },
  });

  return {
    stampPictureURI: profile?.currentDoctor?.stampImage?.uri,
    updateStampPicture: updateStampPicture,
    deleteExistingStampPicture,
  };
};
