import * as yup from 'yup';

import { validatePESEL } from '../../utils/pesel.utils';

export const contactValidation = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const companyContactValidation = yup.object().shape({
  companyName: yup.string().required(),
  nip: yup.string().required(),
});

export const ssnNumberValidation = (checkPESEL?: boolean) => {
  const basicValidation = yup.string().nullable().required();
  if (checkPESEL) {
    return basicValidation
      .length(11)
      .matches(/^[\d]*$/, 'Numer może składać się wyłącznie z cyfr')
      .test(
        'peselCheckSum',
        'Nieprawidłowy numer PESEL',
        (value: string) => !!value && validatePESEL(value),
      );
  }
  return basicValidation;
};

export const fullContactValidation = (
  usersEmail?: string,
  checkPESEL?: boolean,
) =>
  yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    birthDate: yup
      .date()
      .max(new Date(), 'Data musi być w przeszłości')
      .required('Data jest wymagana')
      .typeError('Nieprawidłowa data'),
    ssn: ssnNumberValidation(checkPESEL),
    gender: yup.string().nullable().required(),
    //phoneNumbers: yup.array<string>().required().min(1),
    emailAddresses: yup
      .array()
      .of(
        yup
          .string()
          .email()
          .notOneOf([usersEmail], 'Niepoprawny adres e-mail') as any,
      )
      .nullable() as any,
  });

export const addressValidation = () =>
  yup.object().nullable().shape({
    street: yup.string().nullable().required(),
    postalCode: yup.string().nullable().required(),
    city: yup.string().nullable().required(),
    country: yup.string().nullable().required(),
  });
