import { Close, Settings as SettingsIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Popper,
  Tooltip,
  Typography,
  Divider,
} from '@mui/material';
import { PopperProps } from '@mui/material/Popper/BasePopper.types';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../../../common/components/form/Form';
import { BranchAutocomplete } from '../../common/fields/BranchAutocomplete';
import { DeviceAutocomplete } from '../../common/fields/DeviceAutocomplete';
import {
  CalendarSettings,
  useCalendarSettings,
} from '../hooks/useCalendarSettings';

const CustomPopper = (
  props: PopperProps & React.RefAttributes<HTMLDivElement>,
) => {
  return <Popper {...props} />;
};

export const CalendarSettingsPopover: React.FC = () => {
  const { t } = useTranslation();

  const [calendarSettings, setCalendarSettings] = useCalendarSettings();
  const [selectedBranchIds, setSelectedBranchIds] = useState<number[]>([]);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'contextPopover',
  });

  // Update selectedBranchIds when calendarSettings.branchIds changes
  useEffect(() => {
    if (calendarSettings.branchIds) {
      setSelectedBranchIds(
        calendarSettings.branchIds.map((branch) => branch.id),
      );
    }
  }, [calendarSettings.branchIds]);

  return (
    <>
      <Tooltip title={t('app.topBar.settings') || 'Settings'}>
        <IconButton
          aria-describedby="settings-popover"
          color="primary"
          {...bindTrigger(popupState)}
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box width={{ xs: 300, sm: 600 }} display="flex" flexDirection="column">
          <Box alignSelf="flex-end" padding={4}>
            <IconButton size="small" onClick={() => popupState.setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Form<CalendarSettings>
            onSubmit={({ branchIds, deviceIds }) => {
              setCalendarSettings({
                branchIds,
                deviceIds,
              });
            }}
            formOptions={{
              defaultValues: calendarSettings,
            }}
          >
            <Grid container direction="column" spacing={8}>
              <Grid item>
                <Typography variant="subtitle1" gutterBottom>
                  {t('Ustawienia kalendarza')}
                </Typography>
                <Divider />
              </Grid>

              <Grid item>
                <BranchAutocomplete
                  controllerProps={{
                    name: 'branchIds',
                    label: 'Widoczne placówki',
                  }}
                  autocompleteProps={{
                    multiple: true,
                    disableCloseOnSelect: true,
                    PopperComponent: CustomPopper,
                    onChange: (_, value) => {
                      setSelectedBranchIds(value.map((branch) => branch.id));
                    },
                  }}
                  multiple
                  ownOnly
                />
              </Grid>

              <Grid item>
                <DeviceAutocomplete
                  controllerProps={{
                    name: 'deviceIds',
                    label: 'Widoczne urządzenia',
                  }}
                  autocompleteProps={{
                    multiple: true,
                    disableCloseOnSelect: true,
                    PopperComponent: CustomPopper,
                  }}
                  multiple
                  branchIds={selectedBranchIds}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  onClick={() => popupState.setOpen(false)}
                >
                  {t('app.common.actions.save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Popover>
    </>
  );
};
