import { Link, TablePagination, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsePaginationInstanceProps } from 'react-table';
import { useDebouncedCallback } from 'use-debounce';
import { TextField } from '../../form/fields/TextField';
import { Form } from '../../form/Form';
import { TableContext } from '../state/table.context';
import { StartEndPaginationActions } from './actions/StartEndPaginationActions';

export const MANY_COUNT = 2147483647;

type Props = {
  pageSize: number;
  gotoPage: UsePaginationInstanceProps<any>['gotoPage'];
  pageIndex: number;
  totalCount: number;
  setTablePageSize: UsePaginationInstanceProps<any>['setPageSize'];
};

const PAGINATION_PAGE_SIZES = [10, 20, 30, 50];
const PAGINATION_DEBOUNCE_MILLIS = 500;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageInput: {
      width: theme.spacing(20),
      padding: 0,
    },
    pageLink: {
      cursor: 'pointer',
    },
  }),
);

export const Pagination: React.FC<Props> = ({
  pageSize,
  gotoPage,
  pageIndex,
  totalCount,
  setTablePageSize,
}) => {
  const { dispatch } = useContext(TableContext);
  const [showPageInput, setShowPageInput] = useState(false);
  const classes = useStyles();
  const pageInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const handleChangePage = useDebouncedCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      gotoPage(newPage);
      dispatch({
        type: 'PAGINATION_CHANGE',
        payload: { take: pageSize, skip: newPage * pageSize },
      });
      setShowPageInput(false);
    },
    PAGINATION_DEBOUNCE_MILLIS,
  );

  const handleChangePageSize = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const pageSize = parseInt(event.target.value, 10);
      setTablePageSize(pageSize);
      dispatch({
        type: 'PAGINATION_CHANGE',
        payload: { take: pageSize, skip: 0 },
      });
      gotoPage(0); //TODO: Maybe delete?
      setShowPageInput(false);
    },
    [dispatch, gotoPage, setTablePageSize],
  );

  const submitPage = useCallback(
    (formData: { pageNumber: number }) => {
      const maxPage = Math.ceil(totalCount / pageSize);
      const newPageNumber = Math.min(Math.max(1, formData.pageNumber), maxPage);

      gotoPage(newPageNumber - 1);

      dispatch({
        type: 'PAGINATION_CHANGE',
        payload: { take: pageSize, skip: (newPageNumber - 1) * pageSize },
      });

      setShowPageInput(false);
    },
    [dispatch, gotoPage, pageSize, totalCount],
  );

  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={PAGINATION_PAGE_SIZES}
      count={totalCount}
      rowsPerPage={pageSize}
      page={pageIndex}
      align="right"
      labelDisplayedRows={({ count, page }) => {
        return (
          <span>
            {t('app.common.table.pagination.page')}
            {showPageInput ? (
              <Form<{ pageNumber: number }>
                onSubmit={submitPage}
                noElevation
                inline
                formOptions={{
                  defaultValues: { pageNumber: page + 1 },
                  mode: 'onBlur',
                }}
                render={({ handleSubmit }) => (
                  <TextField
                    autoFocus
                    name="pageNumber"
                    type="number"
                    valueAsNumber
                    ref={pageInputRef}
                    className={classes.pageInput}
                    inputProps={{
                      style: {
                        padding: 4,
                      },
                    }}
                    onBlur={handleSubmit(submitPage)}
                  />
                )}
              ></Form>
            ) : (
              <Link
                onClick={() => {
                  setShowPageInput(true);
                  pageInputRef.current?.focus();
                }}
                className={classes.pageLink}
              >
                {page + 1}
              </Link>
            )}
            {t('app.common.table.pagination.from')}
            {count === MANY_COUNT
              ? t('app.common.table.pagination.all')
              : Math.ceil(count / pageSize)}
          </span>
        );
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangePageSize}
      ActionsComponent={StartEndPaginationActions}
    />
  );
};
