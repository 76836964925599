import { TableCell, TableHead, TableRow } from '@mui/material';
import { useMemo } from 'react';
import {
  UseSortByColumnProps,
  UseTableColumnProps,
  UseTableInstanceProps,
} from 'react-table';
import { TableRowItem } from '../../../types/table';
import { SortableColumn } from '../Table';

export interface FooterProps<T extends TableRowItem> {
  columns: (UseTableColumnProps<T> &
    SortableColumn<T> &
    UseSortByColumnProps<T> & { Footer: { name: string } })[];
  rows?: UseTableInstanceProps<T>['rows'];
}

export const Footer = <T extends TableRowItem>(props: FooterProps<T>) => {
  const { columns } = props;

  const shouldFooterShow = useMemo(
    () => columns.some(({ Footer }) => !!Footer),
    [columns],
  );

  if (!shouldFooterShow) return null;

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, i) => {
          return (
            <TableCell
              key={column.id || i}
              style={{
                ...(column.maxWidth
                  ? { maxWidth: `${column.maxWidth}px` }
                  : {}),
                ...(column.minWidth
                  ? { minWidth: `${column.minWidth}px` }
                  : {}),
                ...(column.width ? { width: `${column.width}px` } : {}),
              }}
            >
              {column.render('Footer')}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
