import { Metric } from 'web-vitals';
import { onCLS, onFCP, onFID, onLCP, onTTFB, onINP } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: Metric) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onCLS(onPerfEntry);
    onFCP(onPerfEntry);
    onFID(onPerfEntry); // Note: FID is deprecated but keeping for backward compatibility
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
    onINP(onPerfEntry); // New metric that replaces FID
  }
};

export default reportWebVitals;
