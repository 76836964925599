import { useSnackbar } from 'notistack';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import {
  GetDoctorDocument,
  GetDoctorQuery,
  useDeleteExistingStampPictureMutation,
  useGetDoctorQuery,
  useUpdateStampPictureMutation,
} from '../../../../../generated/graphql';

export const useDoctorStampPicture = (doctorId: number) => {
  const snackBarError = useGraphQLError();
  const { enqueueSnackbar } = useSnackbar();

  const { data: doctor } = useGetDoctorQuery({
    variables: {
      id: doctorId,
    },
    fetchPolicy: 'network-only',
    skip: doctorId === -1,
  });

  const [updateStampPicture] = useUpdateStampPictureMutation({
    onError: snackBarError,

    onCompleted: () =>
      enqueueSnackbar('Zdjęcie pieczątki zostało ustawione', {
        variant: 'success',
      }),
    update: (cache, result) => {
      const data = cache.readQuery<GetDoctorQuery>({
        query: GetDoctorDocument,
      });
      const currentDoctor = data?.doctor;

      if (currentDoctor) {
        cache.writeQuery<GetDoctorQuery>({
          query: GetDoctorDocument,
          data: {
            doctor: {
              ...currentDoctor,
              stampImage: result.data?.updateStampPicture,
            },
          },
        });
      }
    },
    refetchQueries: ['GetDoctor'],
  });

  const [deleteExistingStampPicture] = useDeleteExistingStampPictureMutation({
    onError: snackBarError,
    refetchQueries: ['GetDoctor'],
    onCompleted: () =>
      enqueueSnackbar('Zdjęcie pieczątki zostało usunięte', {
        variant: 'success',
      }),
    update: (cache) => {
      const data = cache.readQuery<GetDoctorQuery>({
        query: GetDoctorDocument,
      });
      const currentDoctor = data?.doctor;

      if (currentDoctor) {
        cache.writeQuery<GetDoctorQuery>({
          query: GetDoctorDocument,
          data: {
            doctor: { ...currentDoctor, stampImage: null },
          },
        });
      }
    },
  });

  return {
    stampPictureURI: doctor?.doctor?.stampImage?.uri,
    updateStampPicture: updateStampPicture,
    deleteExistingStampPicture,
  };
};
