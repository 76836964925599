import { Theme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router';
import { TableCellLink } from '../../../../../../common/components/table/cell/TableCellLink';
import { Doctor, Study, UserRole } from '../../../../../../generated/graphql';
import { useAppContextState } from '../../../../components/settings/hooks/useSettingsState';

type Props = {
  study: Study;
};

const useStyles = makeStyles({
  tooltipContent: {
    whiteSpace: 'pre-line',
  },
});

const useCellStyles = makeStyles<Theme, Partial<DoctorCellType>>({
  tooltipContent: {
    whiteSpace: 'pre-line',
  },
  doctorNameLabel: {
    fontWeight: ({ type }) => (type === 'leading' ? 'bold' : 'normal'),
    fontStyle: ({ type }) => (type === 'describing' ? 'italic' : 'normal'),
    color: ({ doctor, type }) =>
      type === 'leading' && doctor?.user?.login?.startsWith('NO_USER_')
        ? 'red'
        : 'green',
  },
});

type DoctorCellType = {
  doctor: Doctor;
  type?: 'leading' | 'supporting' | 'describing';
  isLast?: boolean;
};

const DoctorLink = ({ doctor, type, isLast }: DoctorCellType) => {
  const history = useHistory();
  const classes = useCellStyles({ doctor, type });

  return (
    <TableCellLink
      onClick={() =>
        history.push(
          `/${!doctor.facility ? 'doctors' : 'facilities'}/view/${doctor.id}`,
        )
      }
    >
      <span className={classes.doctorNameLabel}>
        {doctor.user?.contact?.companyName}
        {doctor.user?.contact?.lastName}&nbsp;{doctor.user?.contact?.firstName}
        {!isLast ? ', ' : ''}
      </span>
    </TableCellLink>
  );
};

export const DoctorCell: React.FC<Props> = ({ study }) => {
  const [appContext] = useAppContextState();

  const classes = useStyles();

  const isPowerUser =
    appContext.currentRole &&
    [UserRole.Admin, UserRole.Technician].includes(appContext.currentRole);

  const doctors: Partial<DoctorCellType>[] = [
    {
      type: 'leading',
      doctor: study.leadingDoctor as Doctor,
    },
  ];
  if (isPowerUser && study.supportingDoctors) {
    doctors.push(
      ...study.supportingDoctors.map((doctor) => ({
        type: 'supporting' as const,
        doctor,
      })),
    );
  }
  if (isPowerUser && study.describers) {
    doctors.push(
      ...study.describers.map((doctor) => ({
        type: 'describing' as const,
        doctor: doctor.doctor,
      })),
    );
  }

  return (
    <>
      {doctors.map(
        ({ doctor, type }, index, array) =>
          doctor && (
            <Tooltip
              key={doctor.id}
              title={
                isPowerUser ? (
                  <div className={classes.tooltipContent}>
                    {doctor?.user.contact.primaryDescription || ''}
                  </div>
                ) : (
                  ''
                )
              }
            >
              <span>
                <DoctorLink
                  doctor={doctor}
                  type={type}
                  isLast={index === array.length - 1}
                />
              </span>
            </Tooltip>
          ),
      )}
    </>
  );
};
