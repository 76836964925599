import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { AllInbox, DeleteSweep, ErrorOutline } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTime } from 'luxon';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CellProps } from 'react-table';
import { useTableActions } from '../../../../../common/components/table/hooks/useTableActions';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import { ActionColor } from '../../../../../common/types/actions';
import { toLocaleDateTime } from '../../../../../common/utils/date.utils';
import { Message, useMyMessagesQuery } from '../../../../../generated/graphql';
import { useMessagesApi } from '../hooks/useMessagesApi';

const UNREAD_MESSAGE_COLOR = 'rgb(230,200,0,0.07)';

export const MessagesList: React.FC = () => {
  const { data } = useMyMessagesQuery({
    context: { withGlobalLoading: true },
  });

  const history = useHistory();
  const { path } = useRouteMatch();

  const { onDeleteMessages, onMarkMessagesAsRead } = useMessagesApi();

  const tableActions = useTableActions<Omit<Message, 'content'>, 'delete'>([
    {
      id: 'delete',
      Icon: DeleteIcon,
      title: 'Usuń wiadomość',
      actionColor: ActionColor.RED,
      onAction: async ({ id }) => {
        await onDeleteMessages([id], true);
        history.push(`${path}/`);
      },
    },
  ]);

  const COLUMNS: SortableColumn<Omit<Message, 'content'>>[] = [
    {
      id: 'important',
      maxWidth: 5,
      Header: '',
      Cell: ({ row: { original } }: CellProps<Omit<Message, 'content'>>) =>
        original.important ? (
          <ErrorOutline fontSize="small" color="error" />
        ) : null,
    },
    {
      id: 'createdAt',
      Header: 'Data',
      accessor: 'createdAt',
      Cell: ({ row: { original } }: CellProps<Omit<Message, 'content'>>) => (
        <Typography style={{ fontWeight: original.read ? 400 : 700 }}>
          {toLocaleDateTime(original.createdAt, DateTime.DATETIME_MED)}
        </Typography>
      ),
    },
    {
      id: 'title',
      Header: 'Tytuł',
      accessor: 'title',
      Cell: ({ row: { original } }: CellProps<Omit<Message, 'content'>>) => (
        <Typography style={{ fontWeight: original.read ? 400 : 700 }}>
          {original.title}
        </Typography>
      ),
    },
  ];

  if (!data?.allMessages) return null;

  return (
    <Box position="relative">
      <Table<Omit<Message, 'content'>>
        columnConfig={[...COLUMNS, tableActions]}
        pagination={false}
        data={data?.allMessages}
        title="Wiadomości"
        manualSortBy={false}
        onRowClicked={(message) => history.push(`${path}/view/${message.id}`)}
        rowColor={({ original }) =>
          !original.read ? UNREAD_MESSAGE_COLOR : undefined
        }
        toolbarContent={
          <>
            <Tooltip title="Oznacz wszystkie wiadomości jako przeczytane">
              <IconButton
                aria-label="mark-all-as-read"
                onClick={() => onMarkMessagesAsRead()}
                size="large"
              >
                <AllInbox />
              </IconButton>
            </Tooltip>
            <Tooltip title="Usuń wszystkie wiadomości">
              <IconButton
                aria-label="delete-all"
                onClick={() => onDeleteMessages()}
                size="large"
              >
                <DeleteSweep />
              </IconButton>
            </Tooltip>
          </>
        }
      />
    </Box>
  );
};
