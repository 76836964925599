import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: 1250,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      textTransform: 'uppercase',
    },
  }),
);
