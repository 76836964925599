import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Stack,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Maybe } from '../../../../../generated/graphql';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CommentIcon from '@mui/icons-material/Comment';

type AppointmentType = {
  id: number;
  startDate: string | Date;
  endDate: string | Date;
  studyType?: Maybe<{
    id: number;
    name: string;
  }>;
  comments?: string | null;
};

type Props = {
  appointments: AppointmentType[];
};

export const PatientAppointments: React.FC<Props> = ({ appointments }) => {
  const history = useHistory();
  const now = DateTime.now();

  const upcomingAppointments = appointments
    .filter(
      (app: AppointmentType) =>
        DateTime.fromISO(app.startDate.toString()) >= now,
    )
    .sort(
      (a: AppointmentType, b: AppointmentType) =>
        DateTime.fromISO(a.startDate.toString()).toMillis() -
        DateTime.fromISO(b.startDate.toString()).toMillis(),
    );

  const pastAppointments = appointments
    .filter(
      (app: AppointmentType) =>
        DateTime.fromISO(app.startDate.toString()) < now,
    )
    .sort(
      (a: AppointmentType, b: AppointmentType) =>
        DateTime.fromISO(b.startDate.toString()).toMillis() -
        DateTime.fromISO(a.startDate.toString()).toMillis(),
    );

  const AppointmentList = ({
    items,
    title,
  }: {
    items: AppointmentType[];
    title: string;
  }) => {
    const theme = useTheme();

    const handleAppointmentClick = (appointmentId: number) => {
      history.push(`/appointments/${appointmentId}`);
    };

    return (
      <Box>
        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          {title}
        </Typography>
        {items.length === 0 ? (
          <Card variant="outlined" sx={{ bgcolor: 'background.default' }}>
            <CardContent>
              <Typography color="textSecondary" align="center">
                Brak wizyt
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <Stack spacing={2}>
            {items.map((appointment: AppointmentType) => (
              <Card
                key={appointment.id}
                variant="outlined"
                onClick={() => handleAppointmentClick(appointment.id)}
                sx={{
                  transition: 'all 0.2s',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: theme.shadows[2],
                    transform: 'translateY(-2px)',
                  },
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" color="primary">
                        {appointment.studyType?.name || 'Nieznany typ badania'}
                      </Typography>
                      <Chip
                        icon={<EventIcon />}
                        label={DateTime.fromISO(
                          appointment.startDate.toString(),
                        ).toFormat('dd.MM.yyyy')}
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                    </Box>

                    <Box display="flex" alignItems="center" gap={1}>
                      <AccessTimeIcon color="action" fontSize="small" />
                      <Typography variant="body2" color="textSecondary">
                        {DateTime.fromISO(
                          appointment.startDate.toString(),
                        ).toFormat('HH:mm')}{' '}
                        -{' '}
                        {DateTime.fromISO(
                          appointment.endDate.toString(),
                        ).toFormat('HH:mm')}
                      </Typography>
                    </Box>

                    {appointment.comments && (
                      <Box
                        display="flex"
                        alignItems="flex-start"
                        gap={1}
                        sx={{
                          bgcolor: 'background.default',
                          p: 1.5,
                          borderRadius: 1,
                        }}
                      >
                        <CommentIcon color="action" fontSize="small" />
                        <Typography variant="body2">
                          {appointment.comments}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <AppointmentList
            items={upcomingAppointments}
            title="Nadchodzące wizyty"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppointmentList items={pastAppointments} title="Historia wizyt" />
        </Grid>
      </Grid>
    </Box>
  );
};
