import { Button } from '@mui/material';
import { FC, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  PatientsDocument,
  ReferalDataFragment,
  ReferalInput,
  ReferalsDocument,
  useGetReferalQuery,
  useGetStudyTypesQuery,
  UserRole,
  useUpdateReferalMutation,
} from '../../../../../generated/graphql';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { createDefaultReferal } from '../add/ReferalAdd';
import { ReferalForm } from '../form/ReferalForm';
import { useCreateStudyFromReferalAction } from '../hooks/useReferalActions';
import {
  useCompleteReferalAction,
  useDeleteReferalAction,
  useDownloadReferalAction,
  useEditReferalAction,
} from '../hooks/useReferalActions';

type Props = {
  disabled?: boolean;
};

//TODO: Fix this hacky method
const fixMediaTypes = (referal: ReferalDataFragment) => {
  const referalCopy = { ...referal };
  referalCopy.mediaTypes = referal.mediaTypes.map((mt: any) =>
    mt.id ? mt : { id: mt },
  );
  return referalCopy;
};

export const ReferalEdit: FC<Props> = ({ disabled = false }) => {
  const { id } = useParams<{ id: string }>();

  const { success } = useMessages();
  const [updateReferal] = useUpdateReferalMutation({
    onCompleted: () => success(),
    refetchQueries: [ReferalsDocument, PatientsDocument],
    context: { withGlobalLoading: true },
  });
  const { data: studyTypes } = useGetStudyTypesQuery({
    variables: {},
  });

  const { data } = useGetReferalQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parseInt(id, 10),
    },
    context: { withGlobalLoading: true },
  });

  const defaultReferal = useMemo(() => {
    return data?.referal ? fixMediaTypes(data.referal) : createDefaultReferal();
  }, [data]);

  const history = useHistory();

  const onSubmit = async (referal: ReferalInput) => {
    await updateReferal({ variables: { id: parseInt(id, 10), referal } });
    history.push({ pathname: '/referals', state: { reload: true } });
  };

  const [{ currentRole }] = useAppContextState();

  const editReferal = useEditReferalAction();
  const completeReferal = useCompleteReferalAction();
  const deleteReferal = useDeleteReferalAction();
  const downloadReferal = useDownloadReferalAction();
  const createStudy = useCreateStudyFromReferalAction();

  const actions = useMemo(
    () => [
      editReferal,
      completeReferal,
      downloadReferal,
      deleteReferal,
      createStudy,
    ],
    [editReferal, completeReferal, deleteReferal, downloadReferal, createStudy],
  );

  return (
    <ReferalForm
      studyTypes={studyTypes?.studyTypes.edges.map(({ node }) => ({ ...node }))}
      onCancel={() => history.goBack()}
      onSubmit={onSubmit}
      referal={defaultReferal as ReferalInput}
      disabled={disabled}
      actions={actions}
    >
      {disabled &&
        !(currentRole === UserRole.Doctor || data?.referal.completed) && (
          <Button
            variant="contained"
            onClick={() => history.push(`/referals/edit/${data?.referal.id}`)}
            color="primary"
          >
            Edytuj skierowanie
          </Button>
        )}
      <Button type="button" color="secondary" onClick={() => history.goBack()}>
        Anuluj
      </Button>
    </ReferalForm>
  );
};
