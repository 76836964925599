import { CircularProgress, Tooltip } from '@mui/material';
import { CheckCircle, Error, ErrorOutline } from '@mui/icons-material';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Study,
  useGetStudyLazyQuery,
  UserRole,
} from '../../../../../../generated/graphql';
import { useAppContextState } from '../../../../components/settings/hooks/useSettingsState';

type Props = {
  studyListItem: Study;
};

export const StudyListDescriptionIcon: React.FC<Props> = ({
  studyListItem,
}) => {
  const [loadStudy, { loading, data: loadedStudy }] = useGetStudyLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const { t } = useTranslation();
  const [appContext] = useAppContextState();

  const onTooltipOpen = () => {
    loadStudy({ variables: { id: studyListItem.id } });
  };

  if (
    loadedStudy?.study.incognitoDescription &&
    (appContext.currentRole === UserRole.Doctor ||
      appContext.currentRole === UserRole.DentalOffice ||
      appContext.currentRole === UserRole.Laboratory)
  ) {
    return null;
  }

  return (
    (studyListItem.hasDescription && (
      <Tooltip title={t('pages.study.list.tooltips.hasDescription') || ''}>
        <CheckCircle fontSize="small" color="primary" />
      </Tooltip>
    )) ||
    (studyListItem.missingDescription && (
      <Tooltip
        disableFocusListener
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        title={
          loading || !loadStudy ? (
            <CircularProgress />
          ) : (
            <Trans
              i18nKey="pages.study.list.tooltips.missingDescription"
              components={[<strong />]}
              values={{
                describers:
                  loadedStudy?.study?.describers
                    ?.map(
                      ({ doctor }) =>
                        doctor.user.contact.firstName +
                        ' ' +
                        doctor.user.contact.lastName,
                    )
                    .join(',') || '',
              }}
            />
          )
        }
        onOpen={onTooltipOpen}
      >
        <ErrorOutline fontSize="small" color="error" />
      </Tooltip>
    )) ||
    (studyListItem.unassignedDescriber && (
      <Tooltip title={t('pages.study.list.tooltips.missingDescriber') || ''}>
        <Error fontSize="small" color="error" />
      </Tooltip>
    )) ||
    null
  );
};
