import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { CancelButton } from '../../../../../../common/components/button/CancelButton';
import { Form } from '../../../../../../common/components/form/Form';
import { useMessages } from '../../../../../../common/hooks/useMessages';
import {
  User,
  UserRole,
  useSignupOfficeOrLaboratoryMutation,
  useSignupUserMutation,
  useUpdateUserMutation,
  useUsersQuery,
} from '../../../../../../generated/graphql';
import { DEFAULT_USER, UserForm } from '../../../auth/profile/user/UserForm';

type Props = {
  user?: User;
};

export const UserEdit: React.FC<Props> = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { success } = useMessages();
  const [signupUser] = useSignupUserMutation({
    onCompleted: () => success(),
    refetchQueries: ['Users'],
  });
  const [signupOfficeOrLaboratory] = useSignupOfficeOrLaboratoryMutation({
    onCompleted: () => success(),
    refetchQueries: ['Users'],
  });
  const [userEdit] = useUpdateUserMutation({
    onCompleted: () => success(),
    refetchQueries: ['Users'],
  });
  const { data } = useUsersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: { id: parseInt(id, 10) },
      pagination: { skip: 0, take: 1 },
    },
  });

  const history = useHistory();

  const saveUser = ({
    roles,
    emailConfirmed,
    profilePicture,
    ...user
  }: User) => {
    if (id) {
      userEdit({
        variables: { id: parseInt(id, 10), user: { roles, ...user } },
      });
    } else {
      if (
        roles.includes(UserRole.DentalOffice) ||
        roles.includes(UserRole.Laboratory)
      ) {
        signupOfficeOrLaboratory({
          variables: { userData: { ...user, login: user.login }, roles },
        });
      } else {
        signupUser({
          variables: { userData: { ...user, login: user.login }, roles },
        });
      }
    }
    history.goBack();
  };

  const defaultValues = (id && data?.users?.items[0]) || {
    ...DEFAULT_USER,
    login: '',
  };

  return (
    <Form<User>
      onSubmit={saveUser}
      formOptions={{
        defaultValues,
      }}
    >
      <UserForm user={defaultValues} />

      <Box mt={8}>
        <Button fullWidth variant="contained" type="submit" color="primary">
          {t('app.common.actions.save')}
        </Button>
        <CancelButton />
      </Box>
    </Form>
  );
};
