import { Button } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import { EMPTY_ID_INPUT } from '../../../../../common/types/idInput';
import {
  DoctorDataFragment,
  GetPatientQuery,
  Maybe,
  MediaType,
  PatientsDocument,
  ReferalInput,
  ReferalsDocument,
  ReferalsQuery,
  UserRole,
  useAddReferalMutation,
  useGetPatientQuery,
  useGetProfileQuery,
  useGetStudyTypesQuery,
} from '../../../../../generated/graphql';
import { EMPTY_PATIENT } from '../../patients/form/PatientForm';
import { ReferalForm } from '../form/ReferalForm';
import MissingStampAlert from './components/MissingStampAlert';

export const createDefaultReferal = (
  currentDoctor?: Maybe<DoctorDataFragment>,
  patient?: Maybe<GetPatientQuery['patient']>,
) => {
  const newPatient = EMPTY_PATIENT();

  let defaultPatient;
  if (!patient) {
    defaultPatient = {
      ...newPatient,
      contact: {
        ...newPatient.contact,
        emailAddresses: [''],
        phoneNumbers: [''],
      },
    };
  } else {
    const { studies, createdById, updatedById, ...patientData } = patient;
    defaultPatient = patientData;
  }

  return {
    patient: defaultPatient,
    issuer: currentDoctor ?? EMPTY_ID_INPUT(),
    referalToStudyTypes: [],
    mediaTypes: [{ id: MediaType.Online }],
    requestDescription: false,
    description: '',
  };
};

export const ReferalAdd: React.FC = () => {
  const { success } = useMessages();
  const { getAuthInfo } = useAuth();

  const [addReferal] = useAddReferalMutation({
    onCompleted: () => success(),
    refetchQueries: [ReferalsDocument, PatientsDocument],
    context: { withGlobalLoading: true },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          referals(existing: ReferalsQuery['referals'], { toReference }) {
            if (data) {
              const newReferal = toReference({
                __typename: data.addReferal.__typename,
                id: data.addReferal.id,
              });

              return {
                ...existing,
                items: [newReferal, ...existing.items],
                total: existing.total + 1,
              } as any;
            }
          },
        },
      });
    },
  });
  const { data: studyTypes } = useGetStudyTypesQuery();
  const { data: doctorProfile } = useGetProfileQuery({
    skip: !getAuthInfo().roles.includes(UserRole.Doctor),
    fetchPolicy: 'network-only',
  });
  const history = useHistory();

  const queryParams = useQueryParams();
  const { data: patient } = useGetPatientQuery({
    variables: {
      id: parseInt(queryParams.get('patientId') || '', 10),
    },
    skip: !queryParams.get('patientId'),
  });

  const onSubmit = async (data: ReferalInput) => {
    await addReferal({
      variables: {
        newReferal: data,
      },
    });
    history.push('/referals', { reload: true });
  };

  const stampMissing = Boolean(
    doctorProfile?.currentDoctor &&
      !doctorProfile.currentDoctor.stampImage?.uri,
  );

  return (
    <>
      {stampMissing && <MissingStampAlert />}
      <ReferalForm
        disabled={stampMissing}
        onSubmit={onSubmit}
        onCancel={() => history.goBack()}
        studyTypes={studyTypes?.studyTypes.edges.map(({ node }) => ({
          ...node,
        }))}
        referal={
          createDefaultReferal(
            doctorProfile?.currentDoctor as any,
            patient?.patient,
          ) as any
        } //TODO: fix any
      >
        <Button
          type="button"
          color="secondary"
          onClick={() => history.goBack()}
        >
          Anuluj
        </Button>
      </ReferalForm>
    </>
  );
};
