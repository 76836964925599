import { FC } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  Typography,
  Box,
  styled,
  TypographyProps,
} from '@mui/material';

// Replace makeStyles with styled API for Material UI 5
const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(6, 6, 6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  position: 'relative', // Ensure proper stacking context
  zIndex: 1, // Make sure content is above the backdrop
}));

const ProgressContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ProgressText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// Fix: Properly type the styled component to accept Typography props
const ProgressTextInside = styled(Typography)<TypographyProps>(() => ({
  position: 'absolute',
}));

type Props = {
  open: boolean;
  title: string;
  progress: number;
  secondaryLabel?: string;
};

const ProgressModal: FC<Props> = ({
  open,
  title,
  progress,
  secondaryLabel,
}) => {
  return (
    <StyledModal
      aria-labelledby="progress-modal-title"
      aria-describedby="progress-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContent>
          <Typography variant="h6" id="progress-modal-title">
            {title}
          </Typography>
          <ProgressContainer>
            <CircularProgress
              variant="determinate"
              value={progress}
              size={60}
            />
            <ProgressTextInside
              variant="caption"
              sx={{ position: 'absolute' }}
              color="text.secondary"
            >
              {`${Math.round(progress)}%`}
            </ProgressTextInside>
          </ProgressContainer>
          <ProgressText id="progress-modal-description">
            {progress < 100
              ? secondaryLabel || 'Przetwarzanie...'
              : 'Zakończone!'}
          </ProgressText>
        </ModalContent>
      </Fade>
    </StyledModal>
  );
};

export default ProgressModal;
