import { Box, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import accept from 'attr-accept';
import { DropzoneArea, DropzoneAreaProps } from 'react-mui-dropzone';
import React from 'react';

type Props = Partial<DropzoneAreaProps> & {
  fileTypeLabel?: string;
  missingFiles?: boolean;
  iconColor?: string;
};

export const useStyles = makeStyles<
  Theme,
  Pick<Props, 'missingFiles' | 'iconColor'>
>((theme) => ({
  root: ({ missingFiles }) => ({
    minHeight: '120px',
    ...(missingFiles
      ? {
          borderColor: theme.palette.error.main,
        }
      : {}),
  }),
  text: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
  },
  textContainer: {
    padding: '16px',
  },
  icon: ({ iconColor }) => ({
    color: iconColor || 'rgba(0, 0, 0, 0.54)',
  }),
}));

type DropzoneMessagesType = Pick<
  DropzoneAreaProps,
  | 'dropzoneText'
  | 'previewText'
  | 'getFileLimitExceedMessage'
  | 'getDropRejectMessage'
  | 'getFileAddedMessage'
  | 'getFileRemovedMessage'
>;

//TODO: i18n
export const useDropzoneMessages: (
  fileTypeLabel?: string,
) => DropzoneMessagesType & {
  getFileUploadedMessage: (filenames: string[]) => string;
} = (fileTypeLabel) => ({
  dropzoneText: (
    <span>
      Przeciągnij <strong>{fileTypeLabel || 'plik'}</strong> z dysku lub kliknij
      by wybrać ręcznie.
    </span>
  ) as any, // This is to workaround the react-mui-dropzone API limitation that allows only strings to be passed
  previewText: 'Podgląd:',
  getFileLimitExceedMessage: (filesLimit) =>
    `Dopuszczalna liczba załączonych plików (${filesLimit}) została przekroczona. `,
  getFileUploadedMessage: (filenames) =>
    `Pliki ['${filenames}'] zostały pomyślnie dołączone. "`,
  getFileAddedMessage: () => '',
  getFileRemovedMessage: (filename) =>
    `Plik '${filename}' został pomyślnie usunięty. "`,
  getDropRejectMessage: (rejectedFile, acceptedFiles, maxFileSize) => {
    var message = `Plik '${rejectedFile.name}' został odrzucony. `;

    if (!acceptedFiles.some((accepted) => accept(rejectedFile, accepted))) {
      message += 'Ten rodzaj pliku nie jest akceptowany. ';
    }

    if (rejectedFile.size > maxFileSize) {
      message += `Plik jest za duży. Maksymalny rozmiar to ${
        maxFileSize / 1000000
      } MB.`;
    }

    return message;
  },
});

export const FileUpload: React.FC<Props> = ({
  fileTypeLabel,
  missingFiles,
  iconColor,
  ...props
}) => {
  const dropzoneMessages = useDropzoneMessages(fileTypeLabel);

  const classes = useStyles({ missingFiles, iconColor });

  return (
    <Box mt={4}>
      <DropzoneArea
        showAlerts={['error', 'info']}
        alertSnackbarProps={{ autoHideDuration: 5000 }}
        maxFileSize={5000000000}
        filesLimit={15}
        classes={classes}
        useChipsForPreview
        {...dropzoneMessages}
        {...props}
      />
    </Box>
  );
};
