import * as yup from 'yup';

import { fullContactValidation } from '../contact/schema';

export const patientValidationSchema = (
  usersEmail?: string,
  checkSSN?: boolean,
) =>
  yup.object().shape({
    contact: fullContactValidation(usersEmail, checkSSN) as any,
  });
