import React from 'react';
import { Dialog, DialogProps, DialogTitle, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const SignupConsentModal: React.FC<DialogProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog {...props} style={{ height: '90%' }}>
      <DialogTitle>{t('pages.signup.consent.dialog.title')}</DialogTitle>
      <Box p={8}>
        <div>
          <p>
            Administratorami podanych danych osobowych są podmioty lecznicze
            prowadzące pracownie radiologii stomatologicznej i laryngologicznej
            Diagdent:
          </p>
          <ul>
            <li>
              CBCT Sp. z o.o., 03-253 Warszawa, ul. Białołęcka 166D, KRS:
              935996, REGON: 520643089, NIP: 5242930030,
            </li>
            <li>
              CBCT Polska Sp. z o.o., 02-439 Warszawa, ul. Zdobnicza 8, KRS:
              936013, REGON: 520643333, NIP: 5223215013
            </li>
            oraz
            <li>
              Diagdent Polska Sp. z o.o., 50-046 Wrocław, ul. Sądowa 14/1A, KRS
              0001064129, REGON 526697293, NIP 8971928708
            </li>
          </ul>
          <p>
            {' '}
            Przetwarzamy Twoje dane osobowe zawarte w Portalu, aby umożliwić
            kontakt z Portalem lub korzystanie z niego.
          </p>
          <p>
            OBOWIĄZEK INFORMACYJNY
            <br />
            2. Dane będą przetwarzane w celu poprawnego funkcjonowania Portalu
            Lekarza oraz Portalu Pacjenta, udzielenia odpowiedzi na pytania
            przesłane przez formularz kontaktowy, lub innych czynności na
            podstawie prawnie uzasadnionych interesów realizowanych przez
            administratora; art. 6 ust. 1 lit. f RODO*.
            <br />
            3. Odbiorcą Pana/Pani danych osobowych mogą być dostawcy usług
            technicznych i organizacyjnych, którzy wspierają proces
            komunikowania się i funkcjonowania Portalu.
            <br />
            4. Dane będą przechowywane do czasu zakończenia korzystania z
            Portalu przez użytkownika.
            <br />
            5. Ma Pan/Pani prawo dostępu do swoich danych osobowych, ich
            sprostowania, usunięcia lub ograniczenia przetwarzania.
            <br />
            6. Ma Pan/Pani prawo do wniesienia sprzeciwu wobec dalszego
            przetwarzania.
            <br />
            7. Ma Pan/Pani także prawo do przenoszenia danych oraz wniesienia
            skargi do organu nadzorczego.
            <br />
            8. Podanie przez Pana/Panią danych osobowych jest dobrowolne. Odmowa
            podania danych uniemożliwi korzystanie z Portalu.
            <br />
            <br />
            *Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
            27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
            przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
            takich danych oraz uchylenia dyrektywy 95/46/WE (RODO).
          </p>
          <p>
            Podmiot przetwarzający zobowiązuje się do zachowania w tajemnicy
            wszelkich informacji; danych, materiałów, dokumentów i danych
            osobowych otrzymanych od Administratora danych i od współpracujących
            z nim osób oraz danych uzyskanych w jakikolwiek inny sposób,
            zamierzony czy przypadkowy, w formie ustnej , pisemnej lub
            elektronicznej („dane poufne”).
          </p>
        </div>
      </Box>
    </Dialog>
  );
};
