import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import { toLocaleDateTime } from '../../../../../common/utils/date.utils';
import { PatientStudyFragment } from '../../../../../generated/graphql';
import { useTableActions } from '../../../../../common/components/table/hooks/useTableActions';
import { useShareStudyAction } from '../../../modules/studies/actions/share';

type Props = {
  studies: PatientStudyFragment[];
};

export const PatientStudies: React.FunctionComponent<Props> = ({ studies }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const shareAction = useShareStudyAction<PatientStudyFragment>();
  const tableActions = useTableActions<PatientStudyFragment, 'share'>([
    shareAction,
  ]);

  const columns = useMemo<SortableColumn<PatientStudyFragment>[]>(
    () => [
      {
        id: 'studyType.name',
        Header: 'Nazwa badania',
        accessor: ({ studyType }) => studyType.name,
      },
      {
        id: 'studyDate',
        Header: 'Data badania',
        accessor: ({ studyDate }) => studyDate,
        Cell: ({
          value,
        }: CellProps<
          PatientStudyFragment,
          PatientStudyFragment['studyDate']
        >) => toLocaleDateTime(value),
      },
      tableActions,
    ],
    [tableActions],
  );

  return (
    <Table<PatientStudyFragment>
      pagination={false}
      manualSortBy={false}
      columnConfig={columns}
      data={studies}
      title={t('pages.patient.studyList')}
      totalCount={studies.length}
      defaultSort={[{ id: 'studyDate', desc: true }]}
      onRowClicked={({ id }) => history.push(`/studies/view/${id}`)}
    />
  );
};
