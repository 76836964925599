import { useEffect } from 'react';
import { useMyMessagesQuery } from '../../../../../generated/graphql';
import { useMessageSubscription } from './useMessageSubscription';

export const useUnreadMessages = () => {
  const { data: messages, client } = useMyMessagesQuery({
    fetchPolicy: 'network-only',
  });

  // Use the message subscription hook
  useMessageSubscription();

  // Clear cache when component mounts to ensure fresh data
  useEffect(() => {
    client?.cache.evict({
      fieldName: 'allMessages',
    });
    client?.refetchQueries({
      include: ['MyMessages'],
    });
  }, [client]);

  const unreadMessages = (messages?.allMessages || []).filter(
    ({ read }) => !read,
  );

  return {
    unreadCount: unreadMessages.length,
    unreadMessages,
  };
};
