import { IconButton } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import React from 'react';
import { useHistory } from 'react-router';
import { Patient } from '../../../../../../generated/graphql';

type GoToPatientButtonProps = {
  patient: Patient;
};

export const GoToPatientButton: React.FC<GoToPatientButtonProps> = ({
  patient,
}) => {
  const history = useHistory();

  if (!patient?.id) {
    return null;
  }

  return (
    <IconButton
      color="primary"
      onClick={() => history.push(`/patients/view/${patient.id}`)}
      size="large"
    >
      <OpenInNew />
    </IconButton>
  );
};
