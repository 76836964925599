import { AutocompleteProps } from '@mui/material/Autocomplete';
import { useCallback } from 'react';
import {
  AsyncAutocompleteField,
  AsyncAutocompleteProps,
  AutocompleteItem,
} from '../../../../../common/components/form/fields/AsyncAutocompleteField';
import { Unarray } from '../../../../../common/types/unarray';
import {
  Order,
  UserRole,
  UsersQuery,
  useUsersLazyQuery,
} from '../../../../../generated/graphql';

import {
  HideIfEmptyProps,
  LabelProps,
  SubmitOnChangeProps,
} from '../../../../../common/components/form/types';

type Props<
  T extends AutocompleteItem,
  Multiple extends boolean | undefined
> = SubmitOnChangeProps &
  HideIfEmptyProps & {
    controllerProps?: Partial<AsyncAutocompleteProps<T, Multiple, false>> &
      LabelProps;
    autocompleteProps?: Partial<
      AutocompleteProps<T, Multiple, undefined, undefined>
    >;
    role?: UserRole;
    multiple?: Multiple;
  };
type UserType = Unarray<UsersQuery['users']['items']>;

export const UserAutocomplete = <Multiple extends boolean | undefined>(
  props: Props<UserType, Multiple>,
) => {
  const [loadUsers, { called, refetch }] = useUsersLazyQuery({
    context: {
      disableGlobalLoading: true,
    },
  });

  const loadUserData = useCallback(
    async (autocompleteInputText?: string) => {
      const query = {
        where: {
          name: autocompleteInputText,
          ...(props.role ? { roles: [props.role] } : {}),
        },
        pagination: { skip: 0, take: 30 },
        orderBy: [
          {
            columnName: 'contact.lastName',
            columnOrder: Order.Asc,
          },
        ],
      };

      const response = called
        ? await refetch(query)
        : await loadUsers({ variables: query });
      return response?.data?.users?.items;
    },
    [props.role, refetch, called, loadUsers],
  );

  const getOptionLabel = ({ contact }: UserType) => {
    return contact ? `${contact.lastName} ${contact.firstName} ` : '';
  };

  return (
    <AsyncAutocompleteField<UserType, Multiple>
      {...props.autocompleteProps}
      loadData={loadUserData as any} //TODO: fix type
      getOptionLabel={getOptionLabel}
      name={props.controllerProps?.name || 'user'}
      label={props.controllerProps?.label || 'Użytkownik'}
      submitOnChange={props.submitOnChange}
      hideIfEmpty={props.hideIfEmpty}
      multiple={props.multiple}
    ></AsyncAutocompleteField>
  );
};
