import { Alert } from '@mui/material';
import { useFormTimer } from '../../../../../common/hooks/useFormTimer';
import { Box } from '@mui/material';
import { useState } from 'react';

type Props = {
  onTimeout?: () => void;
  defaultDuration?: number;
  restartOn?: string[];
  startOnFilled?: string[];
};

export function FormWatchTimer({
  onTimeout,
  defaultDuration,
  restartOn,
  startOnFilled,
}: Props) {
  const [expired, setExpired] = useState(false);

  const { isRunning, minutes, seconds } = useFormTimer(
    () => {
      setExpired(true);
      if (onTimeout) onTimeout();
    },
    defaultDuration,
    restartOn,
    startOnFilled,
  );

  if (expired) {
    return (
      <Box my={4}>
        <Alert severity="warning">
          Czas na wypełnienie formularza upłynął. Rezerwacja na zadeklarowany
          poniżej termin wygasła.
        </Alert>
      </Box>
    );
  }

  if (!isRunning) {
    return null;
  }

  return (
    <Box my={4}>
      <Alert severity="info">
        Czas pozostały do zapisania formularza:{' '}
        {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
      </Alert>
    </Box>
  );
}
