import { Box, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import {
  ContactCard,
  ContactCardSkeleton,
} from '../../../../../common/components/contact-card/ContactCard';
import { InfoLabel } from '../../../../../common/components/info-label/InfoLabel';
import { Contact, Maybe } from '../../../../../generated/graphql';

type Props = {
  members?: {
    councilId: number;
    contact: Contact;
    profilePicture?: Maybe<string>;
  }[];
  emptyLabel: string;
  actions?: (councilId: number, contact: Contact) => ReactNode;
  showInitials?: boolean;
};

export const CouncilMembers: React.FC<Props> = ({
  members,
  actions,
  emptyLabel,
  showInitials = true,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Paper>
      <Box p={8}>
        <Grid
          container
          spacing={8}
          justifyContent={isMobile ? 'center' : 'flex-start'}
        >
          {members ? (
            <>
              {members.length === 0 ? (
                <InfoLabel>{emptyLabel}</InfoLabel>
              ) : (
                members.map((member) => (
                  <Grid item key={`${member.contact}-${member.councilId}`}>
                    {member.contact && (
                      <ContactCard
                        contact={member.contact}
                        profilePictureURL={member.profilePicture}
                        showInitials={showInitials}
                        actions={
                          actions && actions(member.councilId, member.contact)
                        }
                      />
                    )}
                  </Grid>
                ))
              )}
            </>
          ) : (
            [...Array(4)].map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={12} md={6} key={i}>
                <ContactCardSkeleton />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </Paper>
  );
};
