import { useApolloClient } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  MyMessagesDocument,
  MyMessagesQuery,
  useMessageAddedSubscription,
} from '../../../../../generated/graphql';

// Helper function for subscription error handling
const handleSubscriptionError = (subscriptionName: string, error: any) => {
  console.error(`${subscriptionName} subscription error:`, error);
};

export const useMessageSubscription = () => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  // Subscription for new messages
  useMessageAddedSubscription({
    onData: ({ client, data }) => {
      if (!data?.data?.messageAdded) {
        console.warn('Received empty message data:', data);
        return;
      }

      try {
        // Read existing messages from cache
        const existingData = client.cache.readQuery<MyMessagesQuery>({
          query: MyMessagesDocument,
        });

        if (existingData) {
          // Update cache with new message
          client.cache.writeQuery({
            query: MyMessagesDocument,
            data: {
              allMessages: [
                data.data.messageAdded,
                ...(existingData.allMessages || []),
              ],
            },
          });

          // Optionally show notification
          enqueueSnackbar('Otrzymałeś nową wiadomość', {
            variant: 'info',
            autoHideDuration: 3000,
          });
        } else {
          // If cache is empty, refetch
          client.refetchQueries({
            include: [MyMessagesDocument],
          });
        }
      } catch (error) {
        console.error('Error updating messages cache:', error);
        // Fallback to refetch
        client.refetchQueries({
          include: [MyMessagesDocument],
        });
      }
    },
    onError: (error) => {
      handleSubscriptionError('MessageAdded', error);
      // If there's an error, still try to refetch messages
      client.refetchQueries({
        include: [MyMessagesDocument],
      });
    },
  });
};
