import { Grid, InputBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { DEFAULT_DEBOUNCE_MILLIS } from '../../../form/consts';
import { useFieldRegister } from '../../../form/hooks/useFieldRegister';
import { Outline } from '../../../form/LabeledOutline';
import { LabelProps, SubmitOnChangeProps } from '../../../form/types';

type FieldProps = {
  fromName: string;
  toName: string;
};

type Props = LabelProps & FieldProps & SubmitOnChangeProps;

const useStyles = makeStyles({
  shortInput: {
    width: 50,
  },
  longInput: {
    width: 80,
  },
});

const useSearchByDate = (
  { fromName, toName }: FieldProps,
  formContext: UseFormReturn<Record<string, any>>,
) => {
  const { setValue, watch } = formContext;
  const [formDay, setFormDay] = useState('');
  const [formMonth, setFormMonth] = useState('');
  const [formYear, setFormYear] = useState('');

  const fromValue = watch(fromName);
  const toValue = watch(toName);

  if (
    (fromValue === null || toValue === null) &&
    (!!formDay || !!formMonth || (!!formYear && formYear.length === 4))
  ) {
    setFormDay('');
    setFormMonth('');
    setFormYear('');
    setValue(fromName, '');
    setValue(toName, '');
  } else if (
    fromValue !== null &&
    toValue !== null &&
    !formYear &&
    formYear.length === 4
  ) {
    const fromDate = DateTime.fromISO(fromValue);
    const toDate = DateTime.fromISO(toValue);

    setFormYear(fromDate.year.toString());
    if (
      fromDate.month === toDate.month - 1 &&
      fromDate.day === 1 &&
      toDate.day === 1
    ) {
      setFormMonth(fromDate.month.toString());
    }

    if (fromDate.day !== 1) {
      setFormDay(fromDate.day.toString());
      setFormMonth(fromDate.month.toString());
    }
  }

  useEffect(() => {
    let year = parseInt(formYear, 10) || null;
    if (!year) {
      setValue(fromName, null);
      setValue(toName, null);
      return;
    }

    const monthFrom = formMonth ? parseInt(formMonth, 10) - 1 : 0;
    let monthTo = formMonth ? parseInt(formMonth, 10) - 1 : 11;
    const dayFrom = formDay ? parseInt(formDay, 10) : 1;
    const dayTo = formDay ? parseInt(formDay, 10) : 0;

    if (monthTo === 0 && dayTo === 0 && !formMonth) {
      year++;
      monthTo++;
    } else if (dayTo === 0) {
      monthTo++;
    }

    setValue(fromName, new Date(Date.UTC(year, monthFrom, dayFrom)));
    setValue(toName, new Date(Date.UTC(year, monthTo, dayTo + 1)));
  }, [formDay, formMonth, formYear, fromName, setValue, toName]);

  return {
    formYear,
    setFormYear,
    formMonth,
    setFormMonth,
    formDay,
    setFormDay,
  };
};

export const SearchDateTextField: React.FC<Props> = ({
  label,
  fromName,
  toName,
  submitOnChange,
}) => {
  const formContext = useFormContext();

  const {
    formYear,
    setFormYear,
    formMonth,
    setFormMonth,
    formDay,
    setFormDay,
  } = useSearchByDate({ fromName, toName }, formContext);

  const debouncedSubmitForm = useDebouncedCallback(
    () => formContext.handleSubmit(submitOnChange ?? (() => {}))(),
    DEFAULT_DEBOUNCE_MILLIS,
  );

  useFieldRegister(fromName);
  useFieldRegister(toName);

  const classes = useStyles();

  return (
    <Outline label={label}>
      <Grid container spacing={2} direction="row" wrap="nowrap">
        <Grid item>
          <InputBase
            className={classes.longInput}
            type="number"
            margin="dense"
            placeholder="RRRR"
            inputProps={{ min: 1900, max: 2200 }}
            value={formYear}
            onChange={(event) => {
              setFormYear(event.target.value);
              debouncedSubmitForm();
            }}
          ></InputBase>
        </Grid>
        <Grid item>
          <InputBase
            className={classes.shortInput}
            type="number"
            margin="dense"
            placeholder="MM"
            inputProps={{ min: 1, max: 12 }}
            value={formMonth}
            onChange={(event) => {
              setFormMonth(event.target.value);
              debouncedSubmitForm();
            }}
          ></InputBase>
        </Grid>
        <Grid item>
          <InputBase
            className={classes.shortInput}
            type="number"
            margin="dense"
            inputProps={{ min: 1, max: 31 }}
            value={formDay}
            placeholder="DD"
            onChange={(event) => {
              setFormDay(event.target.value);
              debouncedSubmitForm();
            }}
          ></InputBase>
        </Grid>
      </Grid>
    </Outline>
  );
};
