import { TableActionConfig } from '../components/table/hooks/useTableActions';
import { ItemActionConfig } from '../types/actions';

export const useTableRefresh = (dispatch: React.Dispatch<any>) => {
  return {
    reloadTable: () => dispatch({ type: 'RELOAD' }),
    reloadAfterAction: <T, AT>(
      actionConfig: ItemActionConfig<T, AT>,
    ): TableActionConfig<T, AT> => ({
      ...actionConfig,
      onSuccess: () => dispatch({ type: 'RELOAD' }),
    }),
  };
};
