import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import doctorRegulations from './Regulations_0.1.htm';
import patientRegulations from './Regulations_0.1_patient.htm';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: '90%',
  },
  actions: {
    justifyContent: 'center',
    padding: theme.spacing(16),
  },
}));

const SCROLL_MARGIN = 200;

export const RegulationsModal: React.FC<
  DialogProps & { onAccept?: () => void; type?: 'patient' | 'doctor' }
> = ({ type = 'doctor', ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  return (
    <Dialog
      {...props}
      style={{ height: '90%' }}
      classes={{ paper: classes.paper }}
    >
      <DialogContent
        onScroll={({
          currentTarget: { scrollHeight, scrollTop, clientHeight },
        }) => {
          if (scrollHeight - (scrollTop + clientHeight) < SCROLL_MARGIN) {
            setScrolledToBottom(true);
          }
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: type === 'doctor' ? doctorRegulations : patientRegulations,
          }}
        ></div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Box display="flex" gap={8}>
          {props.onAccept && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.onAccept}
              disabled={!scrolledToBottom}
            >
              {t('app.regulations.dialog.accept')}
            </Button>
          )}
          {props.onClose && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                props.onClose && props.onClose({}, 'escapeKeyDown')
              }
            >
              {t('app.common.actions.close')}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
