import {
  Avatar,
  Box,
  Card,
  CardMedia,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Delete, PhotoCamera } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageUploadDialog } from '../../../../../common/components/images/ImageUploadDialog';
import { useGetDownloadUrlQuery } from '../../../../../generated/graphql';
import { useStampPicture } from '../hooks/useStampPicture';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stampImageCard: {
      cursor: 'pointer',
      width: '100%',
      maxWidth: theme.spacing(250),
      '&:hover': {
        opacity: 0.6,
      },
    },
    stampImage: {
      height: 0,
      paddingTop: '56.25%',
      objectFit: 'contain',
      backgroundSize: 'contain',
    },
    avatar: {
      width: theme.spacing(48),
      height: theme.spacing(48),
    },
    noAvatar: {
      fontSize: 64,
    },
  }),
);

type Props = {
  doctorId?: number;
};

export const StampPicture: React.FC<Props> = ({ doctorId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [cropData, setCropData] = useState<string | undefined>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    stampPictureURI,
    updateStampPicture,
    deleteExistingStampPicture,
  } = useStampPicture(doctorId);

  const { data: stampURL } = useGetDownloadUrlQuery({
    skip: !stampPictureURI,
    variables: {
      filePath: stampPictureURI || '',
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Box mt={6}>
      <Typography variant="h6">{t('profile.stampPicture.title')}</Typography>
      <Box
        mt={6}
        gap={20}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        {stampPictureURI && stampURL ? (
          <Card className={classes.stampImageCard}>
            <CardMedia
              onClick={() => setDialogOpen(true)}
              className={classes.stampImage}
              image={stampURL.getDownloadURL || cropData}
            />
          </Card>
        ) : (
          <Avatar alt="stamp" className={classes.avatar}>
            <IconButton onClick={() => setDialogOpen(true)} size="large">
              <PhotoCamera className={classes.noAvatar} />
            </IconButton>
          </Avatar>
        )}
        <IconButton
          onClick={async () => {
            await deleteExistingStampPicture({
              variables: {
                doctorId,
              },
            });
            setCropData(undefined);
          }}
          aria-label="delete stamp picture"
          size="large"
        >
          <Delete fontSize="small" color="error" />
        </IconButton>
      </Box>
      <ImageUploadDialog
        open={dialogOpen}
        onClose={(croppedImageData) => {
          if (croppedImageData) {
            setCropData(croppedImageData);
            updateStampPicture({
              variables: { updateStampPictureData: croppedImageData, doctorId },
            });
          }

          setDialogOpen(false);
        }}
      />
    </Box>
  );
};
