import { People } from '@mui/icons-material';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { useMyCouncilInvitesQuery } from '../../../../../generated/graphql';

import { useCouncilSubscription } from '../hooks/useCouncilSubscription';
export const CouncilBadge: React.FC = () => {
  const { data: inviteData, client } = useMyCouncilInvitesQuery();
  const { t } = useTranslation();

  useCouncilSubscription();

  useEffect(() => {
    client?.cache.evict({
      fieldName: 'getMyCouncilInvites',
    });
    client?.refetchQueries({
      include: ['MyCouncilInvites'],
    });
  }, [client]);

  return inviteData?.getMyCouncilInvites.length ? (
    <Box mx={4}>
      <Tooltip title={t('pages.council.badge.pendingInvites') as string}>
        <IconButton component={Link} to="/councils?tab=invites" size="large">
          <Badge
            color="error"
            variant="standard"
            badgeContent={inviteData?.getMyCouncilInvites.length}
          >
            <People />
          </Badge>
        </IconButton>
      </Tooltip>
    </Box>
  ) : null;
};
