export function isFirefox(): boolean {
  return (
    typeof navigator !== 'undefined' && /firefox/i.test(navigator.userAgent)
  );
}

export function isSafari(): boolean {
  return (
    typeof navigator !== 'undefined' &&
    /safari/i.test(navigator.userAgent) &&
    !/chrome|chromium|crios/i.test(navigator.userAgent)
  );
}

export function isChrome(): boolean {
  return (
    typeof navigator !== 'undefined' &&
    /chrome|chromium|crios|edg|opr/i.test(navigator.userAgent) &&
    !/brave/i.test(navigator.userAgent)
  );
}
