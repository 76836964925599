import { Box, Button, Theme, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Form } from '../../../../../common/components/form/Form';
import { PatientStudyInput } from '../types';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Alert } from '@mui/material';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { TopBarLogo } from '../../../components/top-bar/logo/TopBarLogo';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from '../../../../../common/components/lang-selector/LanguageSelector';
import { PatientHelpPopover } from './PatientHelpPopup';
import { AcceptTerms } from '../../../../../common/components/accept-terms/AcceptTerms';

type Props = {
  onSubmit: (data: PatientStudyInput) => Promise<void>;
  isSubmitted: boolean;
};

const validationSchema = yup.object().shape({
  ssn: yup.string().required(),
  email: yup.string().email(),
});

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: theme.spacing(8),
  },
}));

export const PatientStudyForm: React.FC<Props> = ({
  onSubmit,
  isSubmitted,
}) => {
  const { t } = useTranslation();
  const { getAuthInfo } = useAuth();
  const classes = useStyles();

  return isSubmitted ? (
    <Paper>
      <Box padding={8} flexDirection="column" gap={8} display="flex">
        <TopBarLogo alt="logo" src="/img/logo.svg" />
        <Alert severity="info">
          {t('pages.patient-studies.credentials-confirmation-message')}
        </Alert>
      </Box>
    </Paper>
  ) : (
    <div className={classes.form}>
      <Form<PatientStudyInput & { acceptTerms: boolean }>
        onSubmit={onSubmit}
        showLeaveUnsavedPrompt={false}
        formOptions={{
          resolver: yupResolver(validationSchema) as any,
        }}
        render={({ watch }) => (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="start"
            >
              <Box pt={8} style={{ zIndex: 100 }}>
                <LanguageSelector />
              </Box>
              <TopBarLogo alt="logo" src="/img/logo.svg" />
              <PatientHelpPopover showDownloads={false} />
            </Box>

            <Box m={8} maxWidth={400} justifyContent="center">
              <Box mb={8}>
                <Alert severity="info">
                  {getAuthInfo().token
                    ? t('pages.patient-studies.provide-ssn-label')
                    : t('pages.patient-studies.provide-credentials-label')}
                </Alert>
              </Box>

              <TextField
                name="ssn"
                label={t('pages.patient-studies.fields.pesel')}
                fullWidth
                required
              />
              {!getAuthInfo().token && (
                <TextField
                  name="email"
                  type="email"
                  label={t('pages.patient-studies.fields.email')}
                  fullWidth
                  required
                />
              )}
            </Box>
            <Box py={8} maxWidth={400}>
              <AcceptTerms type="patient" />
            </Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              disabled={!watch('acceptTerms')}
            >
              {getAuthInfo().token
                ? t('pages.patient-studies.actions.go-to-studies')
                : t('pages.patient-studies.actions.send-request')}
            </Button>
          </>
        )}
      ></Form>
    </div>
  );
};
