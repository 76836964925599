import { AppBar, Box, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabContext } from '@mui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import { CouncilForm, CouncilFormType } from '../form/CouncilForm';
import { useCouncilApi } from '../hooks/useCouncilApi';
import { CouncilDoctorsTab } from './tabs/CouncilDoctorsTab';
import { CouncilPresenceTab } from './tabs/CouncilPresenceTab';
import { InvitedDoctorsTab } from './tabs/InvitedDoctorsTab';
import { PendingInvitationsTab } from './tabs/PendingInvitationsTab';

export const useStyles = makeStyles({
  tabs: {
    maxWidth: '90vw',
  },
});

//TODO: convert to feature toggle
export const COUNCIL_MODE_CONVERSE = true;
const TABS = ['existing', 'pending', 'invites'];
if (!COUNCIL_MODE_CONVERSE) TABS.push('others');

export const SingleCouncilPanel: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { success } = useMessages();

  const queryParams = useQueryParams();
  const [selectedTab, setSelectedTab] = React.useState(
    queryParams.get('tab') || 'existing',
  );
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const { inviteDoctorToCouncil } = useCouncilApi();

  const handleAddCouncil = async ({ invites }: CouncilFormType) => {
    const response = await inviteDoctorToCouncil({
      variables: {
        doctorEmail: invites,
      },
    });
    if (!response.errors) {
      success(
        t('pages.council.messages.inviteSuccess', {
          emails: invites.join(','),
        }),
      );
    }
  };

  const tabs = [
    <CouncilDoctorsTab />,
    <PendingInvitationsTab />,
    <InvitedDoctorsTab />,
  ];

  if (!COUNCIL_MODE_CONVERSE) tabs.push(<CouncilPresenceTab />);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      width="100%"
      gap={10}
    >
      <CouncilForm onSubmit={(newCouncil) => handleAddCouncil(newCouncil)} />
      <TabContext value={selectedTab}>
        <AppBar position="static" color="inherit">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            variant="scrollable"
            className={classes.tabs}
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              value="existing"
              label={t('pages.council.panel.doctors.title')}
            ></Tab>
            <Tab
              value="pending"
              label={t('pages.council.panel.pendingDoctors.title')}
            ></Tab>
            <Tab
              value="invites"
              label={t('pages.council.panel.myInvites.title')}
            ></Tab>
            {!COUNCIL_MODE_CONVERSE && (
              <Tab
                value="others"
                label={t('pages.council.panel.others.title')}
              ></Tab>
            )}
          </Tabs>
        </AppBar>

        <SwipeableViews
          index={TABS.indexOf(selectedTab)}
          onChangeIndex={(index) => {
            if (index < TABS.length) TABS.length && setSelectedTab(TABS[index]);
          }}
        >
          {tabs}
        </SwipeableViews>
      </TabContext>
    </Box>
  );
};
