import { useState } from 'react';
import { Maybe } from '../../generated/graphql';

import { ssnNumberValidation } from '../validation/contact/schema';

export function useSSNCheck(defaultSSN?: Maybe<string>) {
  const [checkSSN, setCheckSSN] = useState<boolean>(
    !defaultSSN || ssnNumberValidation(true).isValidSync(defaultSSN),
  );

  return { checkSSN, setCheckSSN };
}
