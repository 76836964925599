import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { googleLogout } from '@react-oauth/google';
import React, { useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { UserRole } from '../../../../generated/graphql';
import theme from '../../../theme';
import { AppointmentsPanel } from '../../modules/appointments/AppointmentsPanel';
import { AppointmentEdit } from '../../modules/appointments/edit/AppointmentEdit';
import { HelpPage } from '../../modules/common/pages/Help';
import { SingleCouncilPanel } from '../../modules/councils/list/SingleCouncilPanel';
import { DoctorAdd } from '../../modules/doctors/edit/DoctorAdd';
import { DoctorEdit } from '../../modules/doctors/edit/DoctorEdit';
import { DoctorsList } from '../../modules/doctors/list/DoctorList';
import { FacilitiesList } from '../../modules/facilities/list/FacilitiesList';
import { MessagesList } from '../../modules/messages/list/MessagesList';
import { MessageView } from '../../modules/messages/view/MessageView';
import { PatientAdd } from '../../modules/patients/add/PatientAdd';
import { PatientEdit } from '../../modules/patients/edit/PatientEdit';
import { PatientsList } from '../../modules/patients/list/PatientList';
import { ReferalAdd } from '../../modules/referals/add/ReferalAdd';
import { ReferalEdit } from '../../modules/referals/edit/ReferalEdit';
import { ReferalsList } from '../../modules/referals/list/ReferalList';
import { ReportsPage } from '../../modules/reports/ReportsPage';
import { StudyAdd } from '../../modules/studies/add/StudyAdd';
import { StudyEdit } from '../../modules/studies/edit/StudyEdit';
import { StudyList } from '../../modules/studies/list/StudyList';
import { AppDrawer } from '../app-drawer/AppDrawer';
import { ProtectedRoute } from '../auth/ProtectedRoute';
import { ProfilePage } from '../auth/profile/ProfilePage';
import { AdminPage } from '../settings/AdminPage';
import { useAppContextState } from '../settings/hooks/useSettingsState';
import { TopBar } from '../top-bar/TopBar';
import { useStyles } from './Dashboard.styles';
import { ComplaintsList } from '../../modules/complaints/list/ComplaintList';
import { ComplaintEdit } from '../../modules/complaints/edit/ComplaintEdit';
import { ComplaintAdd } from '../../modules/complaints/add/ComplaintAdd';

export const Dashboard: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const [open, setOpen] = React.useState(!isMobile);

  const [{ currentRole }] = useAppContextState();

  const handleOpenDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = useCallback(() => {
    window.localStorage.removeItem('auth');
    window.location.reload();
    window.sessionStorage.clear();

    googleLogout();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar handleOpenDrawer={handleOpenDrawer} handleLogout={handleLogout} />
      {/* <CampaignDialog open /> */}
      <AppDrawer open={open} setOpen={setOpen} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Redirect
                to={currentRole === UserRole.Admin ? '/reports' : '/studies'}
              />
            )}
          />
          <ProtectedRoute exact path="/studies">
            <StudyList />
          </ProtectedRoute>
          <ProtectedRoute
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
            exact
            path="/studies/add"
          >
            <StudyAdd />
          </ProtectedRoute>
          <ProtectedRoute exact path="/studies/view/:id">
            <StudyEdit disabled />
          </ProtectedRoute>
          <ProtectedRoute
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
            exact
            path="/studies/edit/:id"
          >
            <StudyEdit />
          </ProtectedRoute>
          <ProtectedRoute exact path="/patients">
            <PatientsList />
          </ProtectedRoute>
          <ProtectedRoute
            path="/patients/add"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
          >
            <PatientAdd />
          </ProtectedRoute>
          <ProtectedRoute path="/patients/edit/:id">
            <PatientEdit />
          </ProtectedRoute>
          <ProtectedRoute path="/patients/view/:id">
            <PatientEdit disabled />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/doctors"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
            ]}
          >
            <DoctorsList />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/facilities"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
            ]}
          >
            <FacilitiesList />
          </ProtectedRoute>
          <ProtectedRoute
            path="/facilities/edit/:id"
            requiredRoles={[UserRole.Admin, UserRole.Manager]}
          >
            <DoctorEdit facility />
          </ProtectedRoute>
          <ProtectedRoute
            path="/doctors/edit/:id"
            requiredRoles={[UserRole.Admin, UserRole.Manager]}
          >
            <DoctorEdit />
          </ProtectedRoute>
          <ProtectedRoute
            path="/doctors/add"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Manager,
              UserRole.Technician,
            ]}
          >
            <DoctorAdd />
          </ProtectedRoute>
          <ProtectedRoute
            path="/facilities/add"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Manager,
              UserRole.Technician,
            ]}
          >
            <DoctorAdd facility />
          </ProtectedRoute>

          <ProtectedRoute path="/doctors/view/:id">
            <DoctorEdit disabled />
          </ProtectedRoute>
          <ProtectedRoute path="/facilities/view/:id">
            <DoctorEdit disabled facility />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/referals"
            forbiddenRoles={[UserRole.DentalOffice]}
          >
            <ReferalsList />
          </ProtectedRoute>
          <ProtectedRoute
            path="/referals/add"
            forbiddenRoles={[UserRole.DentalOffice]}
          >
            <ReferalAdd />
          </ProtectedRoute>
          <ProtectedRoute
            path="/referals/edit/:id"
            requiredRoles={[UserRole.Admin, UserRole.Doctor]}
            forbiddenRoles={[UserRole.DentalOffice]}
          >
            <ReferalEdit />
          </ProtectedRoute>
          <ProtectedRoute path="/referals/view/:id">
            <ReferalEdit disabled />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/appointments"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
          >
            <AppointmentsPanel />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/appointments/new"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
          >
            <AppointmentEdit />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/appointments/:id"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
          >
            <AppointmentEdit />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/complaints"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
          >
            <ComplaintsList />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/complaints/add"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
          >
            <ComplaintAdd />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/complaints/:id"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
          >
            <ComplaintEdit />
          </ProtectedRoute>

          <ProtectedRoute
            path="/reports"
            requiredRoles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ]}
          >
            <ReportsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/profile">
            <ProfilePage />
          </ProtectedRoute>
          <ProtectedRoute
            path="/settings"
            requiredRoles={[UserRole.Admin, UserRole.Manager]}
          >
            <AdminPage />
          </ProtectedRoute>
          <ProtectedRoute
            path="/councils"
            requiredRoles={[
              UserRole.Doctor,
              UserRole.Laboratory,
              UserRole.DentalOffice,
            ]}
          >
            <SingleCouncilPanel />
          </ProtectedRoute>
          <ProtectedRoute exact path="/messages">
            <MessagesList />
          </ProtectedRoute>
          <ProtectedRoute exact path="/messages/view/:id">
            <MessageView />
          </ProtectedRoute>
          <Route path="/help">
            <HelpPage />
          </Route>
        </Switch>
      </main>
    </div>
  );
};
