import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useActionConfirm } from '../../../../../../common/hooks/useConfirmation';
import { ActionColor } from '../../../../../../common/types/actions';
import { useMarkForDeleteMutation } from '../../../../../../generated/graphql';
import { useAuth } from '../../../../../../common/hooks/useAuth';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../../common/components/form/Form';
import { CancelButton } from '../../../../../../common/components/button/CancelButton';
import { UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

type Props = {
  onChangeLogin: (newLogin: string) => void;
};

export const ProfileActions: React.FC<Props> = ({ onChangeLogin }) => {
  const { t } = useTranslation();
  const confirmAction = useActionConfirm();
  const [markForDeletion] = useMarkForDeleteMutation();
  const { removeAuthInfo } = useAuth();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const descriptionText = (
    <Trans
      i18nKey="profile.confirm.deleteAccount.description"
      components={[<strong />]}
    />
  );

  const deleteAccountAction = async () => {
    await confirmAction({
      description: descriptionText,
    });
    await markForDeletion();
    removeAuthInfo();
    history.push('/login');
    enqueueSnackbar(t('profile.messages.deleteAccount.afterDeleteMessage'), {
      variant: 'info',
      persist: true,
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{t('profile.actions.title')}</Typography>
        <Box
          mt={12}
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          gap={10}
        >
          <ChangeLogin onChangeLogin={onChangeLogin} />
          <Button
            onClick={deleteAccountAction}
            variant="outlined"
            style={{
              color: ActionColor.RED,
              borderColor: ActionColor.RED,
            }}
          >
            {t('profile.actions.deleteAccount')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

type ChangeLoginType = { newLogin: string };

const useStyles = makeStyles({
  changeLoginButton: {
    alignSelf: 'center',
    height: 36,
  },
});

const ChangeLogin: React.FC<Props> = ({ onChangeLogin }) => {
  const [changing, setChanging] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const onSubmit = (
    { newLogin }: ChangeLoginType,
    { reset }: UseFormReturn<ChangeLoginType>,
  ) => {
    onChangeLogin(newLogin);
    reset({ newLogin: '' });
    setChanging(false);
  };

  return changing ? (
    <Form<ChangeLoginType>
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(
          yup.object().shape({
            newLogin: yup.string().email().required().min(5),
          }),
        ),
      }}
      noElevation
    >
      <Box display="flex" flexDirection="column" gap={10} flexWrap="wrap">
        <TextField
          name="newLogin"
          label={t('profile.changeLogin.form.newLogin')}
          type="email"
          required
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          className={classes.changeLoginButton}
        >
          {t('profile.changeLogin.button')}
        </Button>
        <CancelButton cancelAction={() => setChanging(false)} />
      </Box>
    </Form>
  ) : (
    <Button
      variant="contained"
      color="primary"
      onClick={() => setChanging(true)}
    >
      {t('profile.changeLogin.title')}
    </Button>
  );
};
