import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CookieModal } from './CookieDialog';
import { Box, Button, Paper, Typography, Slide } from '@mui/material';
import { CookieConfigDialog } from './CookieConfigDialog';
import { useCookieSettings } from '../../../../common/hooks/useCookieSettings';

export const CookieConsent: React.FC = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [configOpen, setConfigOpen] = useState(false);
  const {
    hasConsent,
    cookieSettings,
    saveSettings,
    acceptAll,
    rejectAllExceptNecessary,
  } = useCookieSettings();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenConfig = () => {
    setConfigOpen(true);
  };

  const handleCloseConfig = () => {
    setConfigOpen(false);
  };

  return (
    <>
      <Slide direction="up" in={!hasConsent} mountOnEnter unmountOnExit>
        <Paper
          elevation={3}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1300,
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '4px 4px 0 0',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
          }}
        >
          <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
            {t('app.cookieConsent.message')}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleOpenConfig}
            >
              {t('app.cookieConsent.configure')}
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleOpenModal}
            >
              {t('app.cookieConsent.findOutMore')}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={acceptAll}
            >
              {t('app.cookieConsent.acceptButton')}
            </Button>
          </Box>
        </Paper>
      </Slide>
      <CookieModal open={modalOpen} onClose={handleCloseModal} />
      <CookieConfigDialog
        open={configOpen}
        onClose={handleCloseConfig}
        onSave={saveSettings}
        onReject={rejectAllExceptNecessary}
        initialSettings={cookieSettings}
      />
    </>
  );
};
