import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';

interface CommentInputProps {
  onSubmit: (message: string) => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center', // Align icon with the TextField
  },
  textField: {
    flexGrow: 1, // TextField to take up available space
    borderRadius: 12,
  },
});

const CommentInput: React.FC<CommentInputProps> = ({ onSubmit }) => {
  const classes = useStyles();
  const [message, setMessage] = React.useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(message);
    setMessage(''); // Clear the text field after submission
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Box display="flex" alignItems="center" width="100%">
        <TextField
          fullWidth
          className={classes.textField}
          placeholder="Nowy komentarz..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(e);
            }
          }}
          margin="normal"
          variant="outlined"
        />
        <IconButton
          disabled={message.length === 0}
          type="submit"
          style={{ color: message ? 'green' : 'inherit' }}
          size="large"
        >
          <SendIcon />
        </IconButton>
      </Box>
    </form>
  );
};

export default CommentInput;
