import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: 'calc(100vw - 24px)',
        overflowX: 'auto',
      },
    },
    sortLabel: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
    headerCell: {
      textAlign: 'left',
    },
    headerLabel: {
      fontWeight: 'bold',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actions: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    iconButton: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    iconLoading: {
      marginLeft: 8,
      marginTop: 8,
    },
  }),
);
