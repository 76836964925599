import { Button } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const PreviewFileButton: React.FC<{
  downloadURL: string;
}> = ({ downloadURL }) => {
  const { t } = useTranslation();
  return (
    <Button
      color="primary"
      variant="outlined"
      aria-label="preview"
      fullWidth
      href={downloadURL}
      target="_blank"
      startIcon={<Search />}
    >
      {t('app.common.actions.preview')}
    </Button>
  );
};
