import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import React from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { CenteredPage } from '../../../../../common/components/centered-page/CenteredPage';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../common/components/form/Form';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useInitPasswordResetMutation } from '../../../../../generated/graphql';
import { TopBarLogo } from '../../top-bar/logo/TopBarLogo';

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
});

const ResetPasswordSuccess: React.FC = () => {
  return (
    <Box mt={12}>
      <Alert severity="success">
        <AlertTitle>Resetowanie hasła</AlertTitle>
        Jeżeli dla podanego adresu e-mail istnieje konto w systemie to dostaną
        Państwo wiadomość z odnośnikiem do strony na której będzie można
        zresetować hasło.
      </Alert>
    </Box>
  );
};
type ResetPasswordInput = { email: string };

export const ResetPasswordRequestPage: React.FC = () => {
  const snackBarError = useGraphQLError();

  const [initPasswordResetMutation, { data }] = useInitPasswordResetMutation({
    onError: (error) => snackBarError(error),
  });

  const history = useHistory();

  const onSubmit = async ({ email }: ResetPasswordInput) =>
    await initPasswordResetMutation({
      variables: { email },
    });

  return (
    <CenteredPage>
      <Form<ResetPasswordInput>
        onSubmit={onSubmit}
        formOptions={{
          resolver: yupResolver(validationSchema),
          defaultValues: { email: '' },
        }}
      >
        <TopBarLogo alt="logo" src="/img/logo.svg" />
        <Box mt={16} justifyContent="center">
          {data ? (
            <ResetPasswordSuccess />
          ) : (
            <>
              <TextField
                name="email"
                label="Login (adres e-mail) w systemie"
                fullWidth
              />
              {/* TODO: Add captcha (potentially using react-google-recaptcha */}
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Przypomnij hasło
              </Button>
              <Button
                type="button"
                color="primary"
                fullWidth
                onClick={() => history.goBack()}
              >
                Anuluj
              </Button>
            </>
          )}
        </Box>
      </Form>
    </CenteredPage>
  );
};
