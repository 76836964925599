import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CancelButton } from '../../../../../../common/components/button/CancelButton';
import { SwitchField } from '../../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../../common/components/form/Form';
import { studyTypeValidation } from '../../../../../../common/validation/study-type/schema';
import { StudyType, StudyTypeInput } from '../../../../../../generated/graphql';
import { TagsField } from '../../../tags/TagsField';
import { StudyTypePrices } from './components/StudyTypePrices';
import { StudyTypeRadiology } from './components/StudyTypeRadiology';
import { StudyTypeDevices } from './components/StudyTypeDevices';

type Props = {
  onSubmit: (data: StudyTypeInput) => void;
  studyType?: StudyType;
};

const DEFAULT_STUDY_TYPE = {
  name: '',
  code: '',
  onReferalOrder: null,
  hasRemarks: false,
  studySubTypes: [],
  studyTypePrices: [],
  studyTypeRadiologies: [],
  descriptionPrice: 0,
};

export const StudyTypeForm: React.FC<Props> = ({ onSubmit, studyType }) => {
  const { t } = useTranslation();

  return (
    <Form<StudyTypeInput>
      onSubmit={onSubmit}
      formOptions={{
        defaultValues: studyType ?? DEFAULT_STUDY_TYPE,
        resolver: yupResolver(studyTypeValidation) as any,
      }}
    >
      <Box display="flex" gap={10}>
        <Box style={{ width: '50%' }} display="flex" flexDirection="column">
          <TextField
            name="code"
            label={t('settings.studyType.fields.code')}
            fullWidth
          />
          <Box mt={6}>
            <TextField
              name="name"
              label={t('settings.studyType.fields.name')}
              fullWidth
            />
          </Box>
          <TextField
            type="number"
            name="onReferalOrder"
            label={t('settings.studyType.fields.onReferalOrder')}
            valueAsNumber
          />
          <TextField
            type="number"
            name="timeToDescribe"
            label={t('settings.studyType.fields.timeToDescribe')}
            valueAsNumber
          />
          <SwitchField
            name="hasRemarks"
            label={t('settings.studyType.fields.hasRemarks')}
          />
          <SwitchField
            name="is3D"
            label={t('settings.studyType.fields.is3D')}
          />
          <TextField
            name="descriptionPrice"
            currency
            label={t('settings.studyType.fields.descriptionPrice')}
            valueAsNumber
          />
          <TextField
            name="urgentDescriptionPrice"
            currency
            label={t('settings.studyType.fields.urgentDescriptionPrice')}
            valueAsNumber
          />
          <TextField
            name="comparisonDescriptionPrice"
            currency
            label={t('settings.studyType.fields.comparisonDescriptionPrice')}
            valueAsNumber
          />
          <TextField
            name="comment"
            label={t('settings.studyType.fields.comment')}
            defaultValue={studyType?.comment || ''}
            inputProps={{ maxLength: 200 }}
            multiline
            minRows={3}
          />
          <TagsField
            name="studySubTypes"
            label="Dodakowa klasyfikacja (podtypy)"
            placeholder="Dodaj kolejny..."
          />
          <Box mt={8}>
            <StudyTypeDevices />
          </Box>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" gap={10}>
          <StudyTypePrices />
          <StudyTypeRadiology />
        </Box>
      </Box>

      <Box mt={8}>
        <Button fullWidth variant="contained" type="submit" color="primary">
          {t('app.common.actions.save')}
        </Button>
        <CancelButton />
      </Box>
    </Form>
  );
};
