import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  useSaveBranchMutation,
  BranchInput,
  useDeleteBranchMutation,
  BranchesDocument,
  BranchesQuery,
} from '../../../../../generated/graphql';
import { useHistory, useRouteMatch } from 'react-router';
import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';

export const useBranchApi = () => {
  const { success, deleteSuccess } = useMessages();
  const history = useHistory();

  const [saveBranch] = useSaveBranchMutation({
    onCompleted: () => {
      success();
      history.push(`${path}/`, { useCache: false, reload: true });
    },
    refetchQueries: [BranchesDocument],
  });

  const [deleteBranch] = useDeleteBranchMutation({
    onCompleted: () => deleteSuccess(),
    refetchQueries: [BranchesDocument],
  });

  const { path } = useRouteMatch();
  const confirmRemoval = useRemoveConfirm();

  const onSaveSubmit = (
    { id, ...branchInput }: BranchInput,
    branchId?: number,
  ) => {
    saveBranch({
      variables: { branch: { id: branchId, ...branchInput } },
      update: (cache, { data: newBranch }) => {
        const data = cache.readQuery<BranchesQuery>({
          query: BranchesDocument,
        });
        const branches = data?.branches.nodes;

        if (branches && newBranch) {
          cache.writeQuery<BranchesQuery>({
            query: BranchesDocument,
            data: {
              branches: {
                nodes: [...branches, newBranch?.saveBranch],
                totalCount: branches.length,
              },
            },
          });
        }
      },
    });
  };

  const onDeleteBranch = async (branchId: number) => {
    await confirmRemoval();
    deleteBranch({
      variables: { branchId },
      update: (cache) => {
        const data = cache.readQuery<BranchesQuery>({
          query: BranchesDocument,
        });
        const branches = data?.branches.nodes;

        if (branches) {
          cache.writeQuery<BranchesQuery>({
            query: BranchesDocument,
            data: {
              branches: {
                nodes: branches.filter(({ id }) => id !== branchId),
                totalCount: branches.length,
              },
            },
          });
        }
      },
    });
  };

  return { onSaveSubmit, onDeleteBranch };
};
