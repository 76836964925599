import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 16,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 0,
      padding: theme.spacing(4),
    },
    grid: {
      marginBottom: 8,
    },
    filename: {
      marginRight: '50px',
      '& span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    branchSelect: {
      flexGrow: 1,
    },
  }),
);
