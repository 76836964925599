import { Box, Button, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { useMemo } from 'react';

import { Form } from '../../../../common/components/form/Form';
import { DateStepperField } from '../../../../common/components/form/fields/DateStepperField';
import {
  Branch,
  BranchSortFields,
  Device,
  SortDirection,
  useBranchesQuery,
} from '../../../../generated/graphql';
import { CalendarSettingsPopover } from './components/CalendarSettingsPopover';
import { DeviceCalendar } from './components/DeviceCalendar';

import './appointments.css';
import { useAppointmentSubscriptions } from './hooks/useAppointmentsSubscriptions';
import { useCalendarSettings } from './hooks/useCalendarSettings';
import { createUseStatePersisted } from 'use-persisted';
import { InfoLabel } from '../../../../common/components/info-label/InfoLabel';
import { Settings } from '@mui/icons-material';
import { useReservationSubscriptions } from './hooks/useReservationsSubscriptions';

type AppointmentsPanelForm = {
  currentDate: Date;
  lastDeviceId: number;
  lastSlotDate: Date;
};

const usePersistedAppointmentsPanelForm = createUseStatePersisted(
  'appointmentsFilters',
  globalThis.sessionStorage,
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'calc(100vh - 180px)',
      marginTop: '50px',
      display: 'flex',
      overflow: 'auto',
      gridGap: 10,
      [theme.breakpoints.down('md')]: {
        flexWrap: 'nowrap',
        width: '90vw',
        scrollSnapType: 'x mandatory',
        marginTop: '200px',
        height: 'calc(100vh - 330px)',
      },
    },
  }),
);

export const AppointmentsPanel = () => {
  const [calendarSettings] = useCalendarSettings();

  const classes = useStyles();

  const [
    appointmentsForm,
    setAppointmentsForm,
  ] = usePersistedAppointmentsPanelForm<AppointmentsPanelForm>({
    currentDate: new Date(),
    lastDeviceId: 0,
    lastSlotDate: new Date(),
  });

  const onSubmit = (form: AppointmentsPanelForm) => setAppointmentsForm(form);

  useAppointmentSubscriptions();
  useReservationSubscriptions();

  const { data: ownBranches } = useBranchesQuery({
    variables: {
      filter: { ownBranch: { is: true } },
      sorting: [{ direction: SortDirection.Asc, field: BranchSortFields.Name }],
      paging: { limit: 30, offset: 0 },
    },
  });

  const allDevices = useMemo(
    () =>
      ownBranches?.branches?.nodes
        ? ownBranches.branches.nodes.reduce((acc, branch) => {
            const devices = (branch.devices || []).map((device) => ({
              ...device,
              branch,
            }));

            return [...acc, ...devices];
          }, [] as (Device & { branch: Omit<Branch, 'employedDoctors'> })[])
        : [],
    [ownBranches?.branches?.nodes],
  );

  return (
    <Box display="flex" flexDirection="column">
      <Form<AppointmentsPanelForm>
        showLeaveUnsavedPrompt={false}
        formOptions={{
          defaultValues: appointmentsForm,
        }}
        onSubmit={onSubmit}
        noElevation
        render={({ setValue, handleSubmit }) => (
          <Box
            display="flex"
            alignItems="center"
            position="fixed"
            gap={20}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              gap={10}
            >
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setValue('currentDate', new Date());
                  handleSubmit(onSubmit)();
                }}
              >
                Dzisiaj
              </Button>
              <DateStepperField
                showDateFormatPlaceholder={false}
                name="currentDate"
                label={'Data wizyty'}
                submitOnChange={onSubmit}
              />
            </Box>
            <Box display="flex" flexDirection="row" gap={10}>
              <CalendarSettingsPopover />
            </Box>
          </Box>
        )}
      />

      <Box className={classes.root}>
        <Box display="flex" alignSelf="start">
          {calendarSettings.branchIds?.length === 0 && (
            <InfoLabel>
              Nie wybrano żadnego kalendarza. Kliknij <Settings /> powyżej by
              wybrać placówki.
            </InfoLabel>
          )}
        </Box>
        {calendarSettings.deviceIds?.length > 0
          ? // If specific devices are selected, show only those devices
            calendarSettings.deviceIds.map((deviceId) => {
              const deviceWithBranch = allDevices?.find(
                (device) => device.id === deviceId.id,
              );
              if (!deviceWithBranch) return null;

              return (
                <DeviceCalendar
                  key={`${deviceWithBranch.id}-${deviceWithBranch.branch.id}`}
                  branch={deviceWithBranch.branch}
                  device={deviceWithBranch}
                  date={appointmentsForm.currentDate}
                />
              );
            })
          : // Otherwise show all devices from selected branches
            calendarSettings.branchIds?.map((branchId) => {
              return allDevices
                ?.filter(({ branch }) => branch.id === branchId.id)
                .map((device) => {
                  return (
                    <DeviceCalendar
                      key={`${device.id}-${device.branch.id}`}
                      branch={device.branch}
                      device={device}
                      date={appointmentsForm.currentDate}
                    />
                  );
                });
            })}
      </Box>
    </Box>
  );
};
