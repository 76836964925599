import { Grid } from '@mui/material';
import React from 'react';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { SearchDateTextField } from '../../../../../common/components/search/fields/date/SearchDateTextField';
import { SearchPanel } from '../../../../../common/components/search/SearchPanel';

import { PatientFiltersInput } from '../../../../../generated/graphql';

type Props = {
  onSearch: (filter: PatientFiltersInput) => void;
  filters?: PatientFiltersInput;
  expanded: boolean;
};

export const DEFAULTS = {
  name: '',
  birthDateFrom: null,
  birthDateTo: null,
  ssn: '',
};

export const PatientSearchPanel: React.FC<Props> = ({
  expanded,
  onSearch,
  filters = { ...DEFAULTS },
}) => {
  return (
    <SearchPanel
      expanded={expanded}
      onSearch={onSearch}
      defaultValues={{ ...filters }}
      resetValues={{ ...DEFAULTS }}
      mainFields={
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md="auto">
            <TextField
              fullWidth
              name="name"
              label="Imię lub nazwisko"
              submitOnChange={onSearch}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <TextField
              fullWidth
              name="ssn"
              label="PESEL"
              submitOnChange={onSearch}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <SearchDateTextField
              fromName="birthDateFrom"
              toName="birthDateTo"
              label="Data urodzenia"
              submitOnChange={onSearch}
            />
          </Grid>

          {/* <Grid item xs={12} md={3}>
            <DateField
              openTo="year"
              views={['year', 'month']}
              name="birthDateFrom"
              label="Data urodzenia (od)"
              showDateFormatPlaceholder={false}
              submitOnChange={onSearch}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DateField
              openTo="year"
              views={['year', 'month']}
              name="birthDateTo"
              label="Data urodzenia (do)"
              showDateFormatPlaceholder={false}
              submitOnChange={onSearch}
            />
          </Grid> */}
        </Grid>
      }
    />
  );
};
