import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

type Props = {
  index: number;
  selectedIndex: number;
};

export const TabPanel: React.FC<PropsWithChildren<Props>> = ({
  index,
  selectedIndex,
  children,
}) => {
  return (
    <div role="tabpanel" hidden={selectedIndex !== index}>
      {selectedIndex === index && <Box p={3}>{children}</Box>}
    </div>
  );
};
