import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
  ContactCard,
  ContactCardSkeleton,
} from '../../../../../common/components/contact-card/ContactCard';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  DoctorInput,
  GetDoctorQuery,
  useDoctorCommentsQuery,
  useGetDoctorQuery,
  UserRole,
  useUpdateDoctorMutation,
  useUserCouncilsQuery,
} from '../../../../../generated/graphql';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { CouncilForm, CouncilFormType } from '../../councils/form/CouncilForm';
import { useCouncilApi } from '../../councils/hooks/useCouncilApi';
import { CouncilMembers } from '../../councils/panel/CouncilMembers';
import { DoctorForm } from '../form/DoctorForm';
import {
  useActivateDoctorAction,
  useDeleteDoctorAction,
  useEditDoctorAction,
  useResetPasswordDoctorAction,
} from '../hooks/useDoctorActions';
import CommentMessage from '../../../components/comment-box/components/CommentMessage';
import { useAuth } from '../../../../../common/hooks/useAuth';
import CommentInput from '../../../components/comment-box/components/CommentInput';
import { useCommentApi } from '../../../components/comment-box/hooks/useCommentApi';
import { Protect } from '../../../components/auth/components/Protect';

type Props = {
  disabled?: boolean;
  facility?: boolean;
};

const convertToInput = (
  doctor: GetDoctorQuery['doctor'] & DoctorInput,
): DoctorInput => {
  const {
    maxStudyHoursToDescribe,
    licenseNumber,
    stampImage,
    specializations,
  } = doctor;

  const describingStudyTypes =
    doctor.describingStudyTypes &&
    doctor.describingStudyTypes.map(({ id }) => ({ id }));

  const employedInBranches =
    doctor.employedInBranches &&
    doctor.employedInBranches.map(({ id }) => ({ id }));

  const { updatedAt, createdAt, profilePicture, ...doctorUser } = doctor.user;

  return {
    maxStudyHoursToDescribe,
    licenseNumber,
    user: doctorUser,
    describingStudyTypes,
    employedInBranches,
    stampImage: stampImage && { id: stampImage.id },
    specializations,
  };
};

export const DoctorEdit: React.FC<Props> = ({ disabled = false, facility }) => {
  const { id } = useParams<{ id: string }>();

  const { success } = useMessages();
  const [updateDoctor] = useUpdateDoctorMutation({
    onCompleted: () => success(),
  });

  const { t } = useTranslation();

  const { data } = useGetDoctorQuery({
    variables: {
      id: parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  const { data: doctorComments } = useDoctorCommentsQuery({
    variables: {
      doctorId: parseInt(id, 10),
    },
  });

  const { onAddComment, onUpdateComment, onDeleteComment } = useCommentApi();

  const [{ currentRole }] = useAppContextState();
  const { getAuthInfo } = useAuth();

  const currentUserID = useMemo(() => getAuthInfo().id, [getAuthInfo]);

  const { data: userCouncils } = useUserCouncilsQuery({
    variables: { userId: data?.doctor.user.id as number },
    skip: !data || currentRole !== UserRole.Admin,
    fetchPolicy: 'no-cache',
  });

  const history = useHistory();

  const editDoctor = useEditDoctorAction(facility);
  const activateDoctor = useActivateDoctorAction(facility);
  const deleteDoctor = useDeleteDoctorAction(facility);
  const resetPassword = useResetPasswordDoctorAction(facility);

  const actions = useMemo(() => {
    const standardActions = [activateDoctor, resetPassword, deleteDoctor];
    return disabled ? [editDoctor, ...standardActions] : standardActions;
  }, [activateDoctor, deleteDoctor, disabled, editDoctor, resetPassword]);

  const onSubmit = async (doctor: DoctorInput) => {
    await updateDoctor({
      variables: {
        id: parseInt(id, 10),
        doctor: convertToInput(doctor as any),
      },
    });
    setTimeout(() =>
      history.push(facility ? '/facilities' : '/doctors', { useCache: false }),
    );
  };

  const { inviteDoctorToCouncil } = useCouncilApi();

  const handleAddCouncil = async (
    doctorId: number,
    { invites }: CouncilFormType,
  ) => {
    const response = await inviteDoctorToCouncil({
      variables: {
        doctorEmail: invites,
        userId: doctorId,
      },
    });
    if (!response.errors) {
      success(
        t('pages.council.messages.inviteSuccess', {
          emails: invites.join(','),
        }),
      );
    }
  };

  return (
    <div>
      {currentRole === UserRole.Doctor ||
      currentRole === UserRole.DentalOffice ||
      currentRole === UserRole.Laboratory ? (
        data?.doctor ? (
          <ContactCard contact={data?.doctor.user.contact} />
        ) : (
          <ContactCardSkeleton />
        )
      ) : (
        <>
          <DoctorForm
            onSubmit={onSubmit}
            doctor={data?.doctor as DoctorInput}
            disabled={disabled}
            title={`Edycja ${facility ? 'gabinetu' : 'lekarza'}`}
            actions={actions}
            facility={facility}
            stampSelection
          >
            {disabled &&
              currentRole &&
              [UserRole.Admin, UserRole.Manager].includes(currentRole) && (
                <Button
                  variant="contained"
                  onClick={() =>
                    history.push(
                      `/${facility ? 'facilities' : 'doctors'}/edit/${
                        data?.doctor.id
                      }`,
                    )
                  }
                  color="primary"
                >
                  Edytuj {facility ? 'gabinet' : 'lekarza'}
                </Button>
              )}
            <Button
              type="button"
              color="secondary"
              onClick={() => history.goBack()}
            >
              Anuluj
            </Button>
          </DoctorForm>
          {disabled &&
            currentRole &&
            [UserRole.Admin].includes(currentRole) &&
            data?.doctor &&
            !data?.doctor.user.login.startsWith('NO_USER') && (
              <Box display="flex" flexDirection="column" gap={20} mb={12}>
                <Typography variant="h6">
                  {t('pages.council.panel.doctors.title')}
                </Typography>
                <CouncilForm
                  onSubmit={(newCouncil) =>
                    data && handleAddCouncil(data?.doctor.user.id, newCouncil)
                  }
                />
                <CouncilMembers
                  emptyLabel={t('pages.council.panel.doctors.empty')}
                  members={
                    userCouncils &&
                    (userCouncils.getCouncils[0]?.councilMembers
                      .filter(({ owner }) => !owner)
                      .map(({ councilMemberDoctor }) => ({
                        contact: councilMemberDoctor.user.contact,
                        profilePicture:
                          councilMemberDoctor.user.profilePicture?.publicURL,
                        councilId: userCouncils.getCouncils[0].id,
                      })) ||
                      [])
                  }
                />
              </Box>
            )}

          <Protect roles={[UserRole.Admin, UserRole.Manager]}>
            <Box mb={30}>
              <Paper>
                <Box p={12}>
                  <Typography noWrap variant="h6">
                    Komentarze
                  </Typography>
                  {doctorComments?.doctorComments?.map((comment) => (
                    <CommentMessage
                      message={comment.content}
                      avatarUrl={
                        comment.createdBy.profilePicture?.publicURL || ''
                      }
                      timestampISO={String(comment.createdAt)}
                      isMine={comment.createdBy.id === currentUserID}
                      onDelete={() => onDeleteComment(comment.id)}
                      onEdit={(newComment) => {
                        onUpdateComment(
                          {
                            content: newComment,
                            title: 'Komentarz',
                            doctorId: parseInt(id, 10),
                          },
                          comment.id,
                        );
                      }}
                    />
                  ))}
                  <Box mt={12}>
                    <CommentInput
                      onSubmit={(a) =>
                        onAddComment({
                          doctorId: parseInt(id, 10),
                          content: a,
                          title: 'Komentarz',
                        })
                      }
                    />
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Protect>
        </>
      )}
    </div>
  );
};
