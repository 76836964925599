import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

type Props = {
  cancelAction?: () => void;
};

export const CancelButton: React.FC<Props> = ({ cancelAction }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      type="button"
      color="primary"
      onClick={cancelAction ? cancelAction : () => history.goBack()}
    >
      {t('app.common.actions.cancel')}
    </Button>
  );
};
