import {
  IconButton,
  InputAdornment,
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { get } from 'lodash-es';
import React, { ReactNode, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { DEFAULT_CURRENCY, DEFAULT_DEBOUNCE_MILLIS } from '../consts';
import { HideIfEmptyProps, NameProps, SubmitOnChangeProps } from '../types';

const valueToNumberTransformer = {
  input: (value: number) => {
    return value === null || value === undefined || isNaN(value)
      ? null
      : value.toString();
  },
  output: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const output =
      e.target.value === ''
        ? NaN
        : e.target.value.endsWith('.') // Allow the user to specify a decimal point number
        ? e.target.value
        : +e.target.value;
    return output;
  },
};

const DecimalFormat = React.forwardRef<any, any>((props, ref) => {
  const { onChange, inputRef, decimalScale, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      decimalScale={decimalScale ?? 2}
      fixedDecimalScale
    />
  );
});

const CurrencyFormat = React.forwardRef<any, any>((props, ref) => (
  <DecimalFormat {...props} suffix={` ${DEFAULT_CURRENCY}`} ref={ref} />
));

const CurrencyFormatNoDecimal = React.forwardRef<any, any>((props, ref) => (
  <CurrencyFormat {...props} decimalScale={0} ref={ref} />
));

export type TextFieldProps = Partial<MUITextFieldProps> &
  NameProps &
  HideIfEmptyProps &
  SubmitOnChangeProps & {
    htmlAutoComplete?: boolean;
    password?: boolean;
    valueAsNumber?: boolean;
    currency?: boolean;
    decimal?: boolean;
    currencyNoDecimal?: boolean;
    submitOnChangeDelay?: number;
  };

export const TextField: React.FC<TextFieldProps> = ({
  htmlAutoComplete = true,
  submitOnChange,
  hideIfEmpty = false,
  password = false,
  currency = false,
  decimal = false,
  currencyNoDecimal = false,
  valueAsNumber = false,
  submitOnChangeDelay = DEFAULT_DEBOUNCE_MILLIS,
  defaultValue,
  ...props
}) => {
  const formContext = useFormContext();
  const debouncedSubmitForm = useDebouncedCallback(
    formContext.handleSubmit(submitOnChange ?? (() => {})),
    submitOnChangeDelay,
  );

  const [passwordMode, setPasswordMode] = useState(password);

  return (
    <Controller
      name={props.name}
      control={formContext?.control}
      render={({ field: { onChange, onBlur, value } }) => {
        if (hideIfEmpty && props.disabled && !value) return <></>;

        let fieldValue = valueAsNumber
          ? valueToNumberTransformer.input(value)
          : value;

        if (
          !get(formContext.formState.dirtyFields, props.name) &&
          defaultValue !== undefined
        ) {
          fieldValue = defaultValue;
        }

        return (
          <MUITextField
            type={props.type ? props.type : passwordMode ? 'password' : 'text'}
            variant="outlined"
            size="small"
            margin="dense"
            {...props}
            onChange={(event) => {
              onChange(
                valueAsNumber
                  ? valueToNumberTransformer.output(event)
                  : event.target.value,
              );
              if (submitOnChange) {
                debouncedSubmitForm();
              }
            }}
            onBlur={(e) => {
              onBlur();
              props?.onBlur && props?.onBlur(e);
            }}
            value={fieldValue}
            error={!!get(formContext.formState.errors, props.name)}
            helperText={
              get(formContext.formState.errors, props.name)
                ?.message as ReactNode
            }
            InputLabelProps={{
              ...(currency || currencyNoDecimal
                ? { shrink: true }
                : props.InputLabelProps
                ? props.InputLabelProps
                : {}),
            }}
            InputProps={{
              autoComplete: htmlAutoComplete ? 'on' : 'new-password',
              ...(currency ? { inputComponent: CurrencyFormat } : {}),
              ...(decimal ? { inputComponent: DecimalFormat } : {}),
              ...(currencyNoDecimal
                ? { inputComponent: CurrencyFormatNoDecimal }
                : {}),
              endAdornment: password ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordMode(!passwordMode)}
                    onMouseDown={(event) => event.preventDefault()}
                    size="large"
                  >
                    {passwordMode ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : null,
              ...props.InputProps,
            }}
          />
        );
      }}
    />
  );
};
