import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useActionConfirm } from '../../../../../common/hooks/useConfirmation';
import { useFileDelete } from '../../../../../common/hooks/useFileDelete';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import {
  StudiesQuery,
  useAddStudyMutation,
  useAppConfigQuery,
  useGetAppointmentQuery,
  useGetCurrentUserQuery,
  useGetReferalLazyQuery,
  useGetReferalQuery,
  useGetStudyTypesQuery,
  User,
  UserRole,
} from '../../../../../generated/graphql';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { useSelectStudyType } from '../../studyTypes/hooks/useSelectStudyType';
import { StudyForm } from '../form/StudyForm';
import { TabbedStudyForm } from '../form/TabbedStudyForm';

import { StudyFormType } from '../types';
import { getDefaultStudy, transformStudySchema } from '../utils';

export const StudyAdd: React.FC = () => {
  const { success } = useMessages();
  const onError = useGraphQLError();

  const queryParams = useQueryParams();
  const referalId = parseInt(queryParams.get('referalId') || '', 10);
  const studyTypeId = parseInt(queryParams.get('studyTypeId') || '', 10);
  const appointmentId = parseInt(queryParams.get('appointmentId') || '', 10);

  const [addStudy] = useAddStudyMutation({
    onCompleted: () => success(),
    onError,
    context: { withGlobalLoading: true },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          studies(existing: StudiesQuery['studies'], { toReference }) {
            if (data) {
              const newStudy = toReference({
                __typename: data.addStudy.__typename,
                id: data.addStudy.id,
              });

              return {
                ...existing,
                items: [newStudy, ...existing.items],
                total: existing.total + 1,
              } as any;
            }
          },
        },
      });
    },
  });
  const { data: currentUser } = useGetCurrentUserQuery();

  const { data: studyTypes } = useGetStudyTypesQuery({
    skip: !referalId && !appointmentId,
  });

  const history = useHistory();
  const deleteFile = useFileDelete();
  const location = useLocation();

  const [settings] = useAppContextState();

  const confirmAction = useActionConfirm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [addNewStudy, setAddNewStudy] = useState(false);

  const { data: referal } = useGetReferalQuery({
    variables: {
      id: referalId,
      withStudies: true,
      withStudyTypePrices: true,
    },
    skip: !referalId,
  });

  const { data: appointment } = useGetAppointmentQuery({
    variables: {
      id: appointmentId,
    },
    skip: !appointmentId,
  });

  const [loadReferal] = useGetReferalLazyQuery({
    fetchPolicy: 'network-only',
  });

  const { data: appConfig } = useAppConfigQuery();

  const selectStudyType = useSelectStudyType();

  const onSubmit = async (data: StudyFormType) => {
    if (!data.leadingDoctor) {
      await confirmAction({
        description: t('pages.study.add.confirmNoLeadingDoctor'),
      });
    }

    const response = await addStudy({
      variables: {
        newStudy: transformStudySchema(data, referalId, appointmentId),
      },
    });
    if (response.errors) return;

    if (addNewStudy) {
      const referal = await loadReferal({
        variables: { withStudies: true, id: referalId },
      });
      const referalStudyTypes = referal.data?.referal.referalToStudyTypes;
      const existingReferalStudies = referal.data?.referal?.studies;

      if (existingReferalStudies?.length === referalStudyTypes?.length) {
        enqueueSnackbar(
          `Dla tego skierowania wystawiono już wszyskie badania.`,
          {
            variant: 'info',
          },
        );
        history.push('/studies');
      } else {
        const selectedStudyType = await selectStudyType({
          getOptionDisabled: (studyType) => {
            const studyTypeOnReferal = referalStudyTypes?.some(
              ({ studyType: { id } }) => studyType.id === id,
            );
            const studyExistsAlerady = existingReferalStudies?.some(
              ({ studyType: { id } }) => studyType.id === id,
            );

            return !studyTypeOnReferal || !!studyExistsAlerady;
          },
        });

        if (selectedStudyType) {
          history.push('/studies');
          history.push(
            `/studies/add?referalId=${referalId}&studyTypeId=${selectedStudyType.id}`,
          );
        }
      }
    } else {
      history.push('/studies');
    }
  };

  const defaultStudy = useMemo(
    () =>
      getDefaultStudy(
        appConfig?.appConfig,
        currentUser?.currentUser as User,
        settings?.currentBranch?.id,
        referal?.referal,
        studyTypeId,
        settings.currentRole,
        appointment?.appointment,
        studyTypes?.studyTypes.edges.map(({ node }) => node) || [],
      ),
    [
      appConfig?.appConfig,
      currentUser?.currentUser,
      settings?.currentBranch?.id,
      settings.currentRole,
      referal?.referal,
      studyTypeId,
      appointment?.appointment,
      studyTypes?.studyTypes.edges,
    ],
  );

  const StudyFormComponent =
    settings.currentRole &&
    [
      UserRole.Admin,
      UserRole.Technician,
      UserRole.Manager,
      UserRole.Registration,
    ].includes(settings.currentRole)
      ? TabbedStudyForm
      : StudyForm;

  return (
    <StudyFormComponent
      key={location.key}
      onDeleteFile={deleteFile}
      onSubmit={onSubmit}
      onCancel={() => history.goBack()}
      study={defaultStudy}
      withAddPatient
    >
      {!!referalId && (
        <Box display="flex" mt={8} mb={4} width="100%">
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            fullWidth
            onClick={() => setAddNewStudy(true)}
          >
            Zapisz badanie i dodaj kolejne
          </Button>
        </Box>
      )}

      <Button
        variant={referalId ? 'outlined' : 'contained'}
        type="submit"
        color="primary"
      >
        Zapisz badanie
      </Button>

      <Button type="button" color="secondary" onClick={() => history.goBack()}>
        Anuluj
      </Button>
    </StudyFormComponent>
  );
};
