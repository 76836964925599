import React, { PropsWithChildren } from 'react';
import { Divider, Box } from '@mui/material';

export const LabelDivider: React.FC<PropsWithChildren> = ({ children }) => (
  <Box display="flex" alignItems="center">
    <Box flexGrow={1}>
      <Divider />
    </Box>
    <Box m={8}>{children}</Box>
    <Box flexGrow={1}>
      <Divider />
    </Box>
  </Box>
);
