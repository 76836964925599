import { Button } from '@mui/material';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { useFileDelete } from '../../../../../common/hooks/useFileDelete';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  useAppConfigQuery,
  useGetStudyQuery,
  UserRole,
  useUpdateStudyMutation,
} from '../../../../../generated/graphql';
import { Protect } from '../../../components/auth/components/Protect';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { StudyForm } from '../form/StudyForm';
import { TabbedStudyForm } from '../form/TabbedStudyForm';
import { StudyFormType } from '../types';
import {
  getDefaultStudy,
  transformStudySchema,
  convertStudyToFormStudy,
} from '../utils';

type Props = {
  disabled?: boolean;
};

export const StudyEdit: React.FC<Props> = ({ disabled = false }) => {
  const { id } = useParams<{ id: string }>();
  const { success } = useMessages();
  const onError = useGraphQLError();

  const { data, refetch } = useGetStudyQuery({
    variables: {
      id: parseInt(id, 10),
    },
    onError,
  });

  const history = useHistory();

  const [updateStudy] = useUpdateStudyMutation({
    onCompleted: async () => {
      success();
      await refetch();
      history.goBack();
    },
    onError,
  });

  const { data: appConfig } = useAppConfigQuery();

  const study = React.useMemo(() => {
    return data?.study
      ? convertStudyToFormStudy(data.study)
      : getDefaultStudy(appConfig?.appConfig);
  }, [data, appConfig]);

  const deleteFile = useFileDelete();

  const { getAuthInfo } = useAuth();
  const [{ currentRole }] = useAppContextState();

  const onSubmit = async (study: StudyFormType) => {
    await updateStudy({
      variables: { id: parseInt(id, 10), study: transformStudySchema(study) },
    });
  };

  const onDeleteFile = async (fileId: number) => {
    const response = await deleteFile(fileId);
    refetch();
    return response;
  };

  const isSupportingDoctor = study?.supportingDoctors?.some(
    ({ user: { id } }) => getAuthInfo().id === id,
  );

  const StudyFormComponent =
    currentRole &&
    [
      UserRole.Admin,
      UserRole.Technician,
      UserRole.Manager,
      UserRole.Registration,
      UserRole.DescribingDoctor,
    ].includes(currentRole)
      ? TabbedStudyForm
      : StudyForm;

  return (
    <StudyFormComponent
      onCancel={() => history.goBack()}
      onSubmit={onSubmit}
      onDeleteFile={onDeleteFile}
      study={study}
      disabled={disabled}
      isSupportingDoctor={isSupportingDoctor}
    >
      {disabled && (
        <Protect
          roles={[
            UserRole.Admin,
            UserRole.Technician,
            UserRole.Manager,
            UserRole.Registration,
          ]}
        >
          <Button
            variant="contained"
            onClick={() => history.push(`/studies/edit/${data?.study.id}`)}
            color="primary"
          >
            Edytuj badanie
          </Button>
        </Protect>
      )}
      {/* <Protect roles={[UserRole.Technician, UserRole.Admin, UserRole.Manager]}>
        <Button
          color="secondary"
          onClick={async () => {
            const descriptionRequestComment = await showRequestDescriptionDialog();
            requestDescription({
              variables: {
                descriptionRequestComment,
                id: parseInt(id, 10),
              },
            });
          }}
        >
          Wyślij prośbę o opis
        </Button>
      </Protect>*/}
      {!disabled && (
        <Button variant="contained" type="submit" color="primary">
          Zapisz badanie
        </Button>
      )}
      <Button type="button" color="secondary" onClick={() => history.goBack()}>
        Anuluj
      </Button>
    </StudyFormComponent>
  );
};
