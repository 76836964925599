import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './Loading.styles';
import { Box, Typography } from '@mui/material';

type Props = {
  open: boolean;
  debounceMilis?: number;
  text?: string;
  contained?: boolean;
};

export const Loading: React.FC<Props> = ({
  open,
  debounceMilis = 300,
  text,
  contained,
}) => {
  const classes = useStyles({ contained });
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setOpen(open), debounceMilis);
    return () => clearTimeout(timeoutId);
  }, [debounceMilis, open]);

  return (
    <Backdrop className={classes.loading} open={isOpen}>
      <CircularProgress color="inherit" />
      {text && (
        <Box ml={4}>
          <Typography variant="body2">{text}</Typography>
        </Box>
      )}
    </Backdrop>
  );
};
