import { Box, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../../../common/components/form/fields/TextField';
import { Outline } from '../../../../../../../common/components/form/LabeledOutline';
import { ActionColor } from '../../../../../../../common/types/actions';
import {
  StudyType,
  useAppConfigQuery,
} from '../../../../../../../generated/graphql';

export const StudyTypeRadiology: React.FC = () => {
  const { control } = useFormContext<StudyType>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray<
    StudyType,
    'studyTypeRadiologies'
  >({
    name: 'studyTypeRadiologies',
    control,
  });

  const { data: appConfig } = useAppConfigQuery();

  const radiationGroups = appConfig?.appConfig?.radiationGroups;
  if (!radiationGroups) return null;

  return (
    <Outline label={t('settings.studyType.sections.radiology')}>
      {fields.map((studyTypeRadiology, index) => (
        <Box display="flex" gap={6} pt={4}>
          <SelectField
            name={`studyTypeRadiologies[${index}].radiology.presetName`}
            options={radiationGroups.map((radiationGroup) => ({
              label: radiationGroup,
              value: radiationGroup,
            }))}
            defaultValue={studyTypeRadiology.radiology?.presetName}
            label={t('app.common.fields.radiology.preset')}
          />
          <TextField
            name={`studyTypeRadiologies[${index}].radiology.voltageKilovolts`}
            defaultValue={studyTypeRadiology.radiology?.voltageKilovolts}
            label={t('app.common.fields.radiology.voltage')}
            valueAsNumber
          />
          <TextField
            name={`studyTypeRadiologies[${index}].radiology.anodeCurrentMiliamper`}
            defaultValue={studyTypeRadiology.radiology?.anodeCurrentMiliamper}
            label={t('app.common.fields.radiology.current')}
            valueAsNumber
          />
          <TextField
            name={`studyTypeRadiologies[${index}].radiology.exposureTimeSeconds`}
            defaultValue={studyTypeRadiology.radiology?.exposureTimeSeconds}
            label={t('app.common.fields.radiology.exposure')}
            valueAsNumber
          />
          <TextField
            name={`studyTypeRadiologies[${index}].radiology.radiationDoseMilligrayPerSquareCentimeter`}
            defaultValue={
              studyTypeRadiology.radiology
                ?.radiationDoseMilligrayPerSquareCentimeter
            }
            label={t('app.common.fields.radiology.dose')}
            valueAsNumber
          />
          <IconButton
            aria-label="delete"
            style={{ color: ActionColor.RED }}
            onClick={() => remove(index)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Box mt={6}>
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={() =>
            append({
              radiology: {
                voltageKilovolts: 0,
                anodeCurrentMiliamper: 0,
                exposureTimeSeconds: 0,
                radiationDoseMilligrayPerSquareCentimeter: 0,
                presetName: null,
              },
            })
          }
        >
          {t('settings.studyType.buttons.addNewRadiology')}
        </Button>
      </Box>
    </Outline>
  );
};
