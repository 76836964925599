import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //display: 'inline-block',
      maxWidth: '100vw',
      padding: theme.spacing(8),
    },
  }),
);
