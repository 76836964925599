import { AutocompleteProps } from '@mui/material/Autocomplete';
import React, { useCallback } from 'react';

import {
  AsyncAutocompleteField,
  AsyncAutocompleteProps,
  AutocompleteItem,
} from '../../../../../common/components/form/fields/AsyncAutocompleteField';
import { Unarray } from '../../../../../common/types/unarray';
import {
  DoctorsQuery,
  Order,
  QueryDoctorsArgs,
  UserRole,
  useDoctorsLazyQuery,
  useGetProfileQuery,
} from '../../../../../generated/graphql';

import { Tooltip, Typography } from '@mui/material';
import {
  HideIfEmptyProps,
  SubmitOnChangeProps,
} from '../../../../../common/components/form/types';
import { NO_USER_ROW_COLOR } from '../list/DoctorList';

type Props<
  T extends AutocompleteItem,
  Multiple extends boolean | undefined
> = SubmitOnChangeProps &
  HideIfEmptyProps & {
    controllerProps?: Partial<AsyncAutocompleteProps<T, Multiple, false>>;
    autocompleteProps?: Partial<
      AutocompleteProps<T, Multiple, undefined, undefined>
    >;
    multiple?: Multiple;
    roles?: UserRole[];
    ignoreSelf?: boolean;
    describingStudyTypeId?: number;
    showNoUserDoctors?: boolean;
    showFacilities?: boolean;
  };
export type DoctorType = Unarray<DoctorsQuery['doctors']['items']>;

export const DoctorAutocomplete = <Multiple extends boolean | undefined>(
  props: Props<DoctorType, Multiple>,
) => {
  const [loadDoctors, { refetch, called }] = useDoctorsLazyQuery({
    fetchPolicy: 'no-cache',
    context: {
      disableGlobalLoading: true,
    },
  });

  const { data: currentDoctor } = useGetProfileQuery();

  const loadDoctorData = useCallback(
    async (autocompleteInputText?: string) => {
      const query: QueryDoctorsArgs = {
        where: {
          name: autocompleteInputText,
          describingStudyTypeId: props.describingStudyTypeId,
          user: { roles: props.roles || [] },
          showNoUserDoctors: props.showNoUserDoctors ?? false,
          facility: props.showFacilities,
        },
        pagination: { skip: 0, take: 20 },
        orderBy: [
          {
            columnName: 'contact.lastName',
            columnOrder: Order.Asc,
          },
          {
            columnName: 'contact.companyName',
            columnOrder: Order.Asc,
          },
        ],
      };

      const response = called
        ? await refetch(query)
        : await loadDoctors({ variables: query });
      return response?.data?.doctors?.items?.filter(
        ({ id }) =>
          !props.ignoreSelf ||
          !currentDoctor?.currentDoctor ||
          currentDoctor.currentDoctor.id !== id,
      ) as DoctorType[];
    },
    [
      props.describingStudyTypeId,
      props.roles,
      props.showNoUserDoctors,
      props.showFacilities,
      props.ignoreSelf,
      called,
      refetch,
      loadDoctors,
      currentDoctor?.currentDoctor,
    ],
  );

  const getOptionLabel = (doctor: DoctorType) =>
    doctor.user?.contact.firstName || doctor.user?.contact.lastName
      ? `${doctor.user?.contact?.firstName || ''} ${
          doctor.user?.contact?.lastName || ''
        }`
      : `${doctor.user?.contact.companyName || ''}`;

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    doctor: DoctorType,
  ) => {
    return (
      <li {...props}>
        <Tooltip
          title={
            <>
              {doctor.licenseNumber && (
                <>
                  <Typography variant="caption">PWZ: </Typography>
                  {doctor.licenseNumber}
                </>
              )}
              {doctor.user?.contact?.primaryDescription && (
                <>
                  <br />
                  <Typography variant="caption">Notatka: </Typography>
                  {doctor.user.contact.primaryDescription}
                </>
              )}
            </>
          }
        >
          <span
            style={{
              backgroundColor: doctor.user.login.startsWith('NO_USER')
                ? NO_USER_ROW_COLOR
                : 'inherit',
            }}
          >
            {getOptionLabel(doctor)}
          </span>
        </Tooltip>
      </li>
    );
  };

  return (
    <AsyncAutocompleteField<DoctorType, Multiple>
      getOptionLabel={getOptionLabel}
      loadData={loadDoctorData}
      renderOption={renderOption}
      {...props.autocompleteProps}
      name={props.controllerProps?.name || 'doctor'}
      label={props.controllerProps?.label || 'Lekarz'}
      submitOnChange={props.submitOnChange}
      hideIfEmpty={props.hideIfEmpty}
    />
  );
};
