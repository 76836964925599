// eslint-disable @typescript-eslint/no-redeclare
/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/no-anonymous-default-export */

export const getRawLang = () => {
  const lSLang = window.localStorage.getItem('lang');

  if (lSLang) {
    try {
      return JSON.parse(lSLang).lang;
    } catch {
      return 'pl';
    }
  }
  return 'pl';
};

export const mixed = {
  default: 'Pole nie jest prawidłowe',
  required: () => {
    const lang = getRawLang();

    if (lang === 'en') {
      return 'This field is required';
    } else if (lang === 'ua') {
      return "Це поле обов'язкове для заповнення";
    }

    return 'Pole jest wymagane';
  },

  oneOf: 'Pole może przyjąć wyłącznie jedną z wartości: ${values}',
  notOneOf: 'Pole nie może przyjąć jednej z wartości: ${values}',
};

export const string = {
  length: 'Pole musi mieć długość ${length} znaków',
  min: 'Pole musi składać się z co najmniej ${min} znaków',
  max: 'Pole musi składać się z co najwyżej ${max} znaków',
  matches: 'Pole nie spełnia kryteriów wyrażenia regularnego: "${regex}"',
  email: 'Pole nie jest prawidłowym adresem e-mail',
  url: 'Pole nie jest prawidłowym adresem URL',
  lowercase: 'Pole może składać się wyłącznie z małych liter',
  uppercase: 'Pole może składać się wyłącznie z dużych liter',
};

export const number = {
  min: 'Pole musi być większe lub równe ${min}',
  max: 'Pole musi być mniejsze lub równe ${max}',
  lessThan: 'Pole musi być mniejsze niż ${less}',
  moreThan: 'Pole musi być większe niż ${more}',
  notEqual: 'Pole nie może być równe "${notEqual}"',
  positive: 'Pole musi mieć wartość pozytywną',
  negative: 'Pole musi mieć wartość negatywną',
  integer: 'Pole musi mieć wartość całkowitą',
};

export const date = {
  min: 'Pole musi być datą późniejszą niż ${min}',
  max: 'Pole musi być datą wcześniejszą niż ${max}',
};

export const array = {
  min: 'Pole powinno mieć przynajmniej ${min} wartości',
  max: 'Pole powinno mieć maksymalnie ${max} wartości',
  string: {
    email: 'Pole nie jest prawidłowym adresem e-mail',
  },
};

export default {
  mixed,
  string,
  number,
  date,
  array,
};
