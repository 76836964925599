import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  useAddCommentMutation,
  useUpdateCommentMutation,
  CommentInput,
  useDeleteCommentsMutation,
  MyCommentsDocument,
  DoctorCommentsDocument,
} from '../../../../../generated/graphql';

import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';

export const useCommentApi = () => {
  const { success, deleteSuccess } = useMessages();

  const [addComment] = useAddCommentMutation({
    onCompleted: () => success(),
  });
  const [updateComment] = useUpdateCommentMutation({
    onCompleted: () => success(),
  });
  const [deleteComment] = useDeleteCommentsMutation({
    onCompleted: () => deleteSuccess(),
  });

  const confirmRemoval = useRemoveConfirm();

  const onAddComment = (commentInput: CommentInput) => {
    addComment({
      variables: { comment: commentInput },
      refetchQueries: [MyCommentsDocument, DoctorCommentsDocument],
    });
  };

  const onUpdateComment = (comment: CommentInput, commentId: number) => {
    updateComment({
      variables: { comment, id: commentId },
      refetchQueries: [MyCommentsDocument, DoctorCommentsDocument],
    });
  };

  const onDeleteComment = async (commentId: number) => {
    await confirmRemoval();
    deleteComment({
      variables: { ids: [commentId] },
      refetchQueries: [MyCommentsDocument, DoctorCommentsDocument],
    });
  };

  return { onAddComment, onUpdateComment, onDeleteComment };
};
