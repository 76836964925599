import { Box, Grid } from '@mui/material';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStatePersisted } from 'use-persisted';
import { SelectField } from '../../../../../common/components/form/fields/SelectField';
import { SwitchField } from '../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { SearchDateTextField } from '../../../../../common/components/search/fields/date/SearchDateTextField';
import { SearchPanel } from '../../../../../common/components/search/SearchPanel';
import { TableContext } from '../../../../../common/components/table/state/table.context';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { EMPTY_ID_INPUT } from '../../../../../common/types/idInput';
import {
  IdInput,
  Maybe,
  StudyFiltersInput,
  StudyStatus,
  useGetProfileQuery,
  UserRole,
} from '../../../../../generated/graphql';
import { Protect } from '../../../components/auth/components/Protect';

import { BranchSelect } from '../../common/fields/BranchSelect';
import { StudyTypeAutocomplete } from '../../common/fields/StudyTypeAutocomplete';
import { DoctorAutocomplete } from '../../doctors/fields/DoctorAutocomplete';
import { UserAutocomplete } from '../../users/fields/UserAutocomplete';
import { useStyles } from './StudySearch.styles';

type Props = {
  onSearch: (filter: StudySearchModel) => void;
};

type StudySearchModel = StudyFiltersInput & {
  performer?: Maybe<Partial<IdInput>>;
  anyDoctor?: Maybe<Partial<IdInput>>;
  describer?: Maybe<Partial<IdInput>>;
  studyType?: Maybe<Partial<IdInput>>;
  onlyOwn: boolean;
  patientSsn?: string;
};

export const FORM_DEFAULTS = {
  patient: { birthDateFrom: null, birthDateTo: null, name: '' },
  //studyDateFrom: todayStart().minus({ days: 290 }).toJSDate(),
  studyDateFrom: null,
  studyDateTo: null,
  performer: EMPTY_ID_INPUT(),
  anyDoctor: EMPTY_ID_INPUT(),
  describer: EMPTY_ID_INPUT(),
  studyType: EMPTY_ID_INPUT(),
  onlyOwn: false,
  status: null,
  studyDescriptionFilter: null,
  branchId: null,
  patientSsn: '',
};

export const convertFromFormModel = ({
  performer,
  anyDoctor,
  describer,
  studyType,
  onlyOwn,
  patientSsn,
  ...formModel
}: StudySearchModel): StudyFiltersInput => ({
  ...formModel,
  performerId: performer?.id,
  anyDoctorId: anyDoctor?.id,
  describerId: describer?.id,
  studyTypeId: studyType?.id,
  patientSsn,
});

const usePersistedSearchForm = createUseStatePersisted(
  'studySearch',
  globalThis.sessionStorage,
);

export const StudySearchPanel: React.FC<Props> = ({ onSearch }) => {
  const { state } = useContext(TableContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const snackBarError = useGraphQLError();
  const { data } = useGetProfileQuery({
    onError: snackBarError,
  });

  const [studyForm, setStudyForm] = usePersistedSearchForm<StudySearchModel>(
    FORM_DEFAULTS,
  );

  const handleOnSearch = (filterInput: StudySearchModel) => {
    if (data?.currentDoctor?.id && filterInput.onlyOwn) {
      filterInput.leadingDoctorId = data?.currentDoctor?.id;
    } else if (data?.currentDoctor?.id) {
      filterInput.anyDoctor = {
        id: data.currentDoctor.id,
      };
    }

    onSearch(filterInput);
    setStudyForm(filterInput);
  };

  return (
    <SearchPanel
      expanded={state.filtersExpanded}
      defaultValues={studyForm}
      onSearch={handleOnSearch}
      resetValues={{ ...FORM_DEFAULTS }}
      mainFields={
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md="auto">
            <TextField
              name="patient.name"
              label="Dane pacjenta"
              fullWidth
              variant="outlined"
              submitOnChange={handleOnSearch}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <TextField
              name="patientSsn"
              label="PESEL pacjenta"
              fullWidth
              variant="outlined"
              submitOnChange={handleOnSearch}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <SearchDateTextField
              fromName="patient.birthDateFrom"
              toName="patient.birthDateTo"
              submitOnChange={handleOnSearch}
              label="Data ur. pacjenta"
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <SearchDateTextField
              fromName="studyDateFrom"
              toName="studyDateTo"
              submitOnChange={handleOnSearch}
              label="Data badania"
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <Protect
              roles={[
                UserRole.Doctor,
                UserRole.DentalOffice,
                UserRole.Laboratory,
              ]}
            >
              <SwitchField
                name="onlyOwn"
                label={t('pages.study.list.ownership.own')}
                submitOnChange={handleOnSearch}
              ></SwitchField>
            </Protect>
          </Grid>
          <Protect
            roles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.DescribingDoctor,
              UserRole.Manager,
            ]}
          >
            <Grid item xs={12} md="auto">
              <SelectField
                className={classes.selectDescription}
                name="studyDescriptionFilter"
                submitOnChange={handleOnSearch}
                label="Opis"
                options={[
                  {
                    label: 'Brak lekarza opisującego',
                    value: 'UnassignedDescriber',
                  },
                  {
                    label: 'Do opisu',
                    value: 'NoDescription',
                  },
                  { label: 'Z załączonym opisem', value: 'WithDescription' },
                ]}
              />
            </Grid>
          </Protect>

          <Protect
            roles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.DescribingDoctor,
              UserRole.Manager,
            ]}
          >
            <Grid item xs={12} md="auto">
              <SelectField
                name="status"
                options={Object.values(StudyStatus).map((status) => ({
                  value: status,
                  label: t(`fields.study.status.options.${status}`),
                }))}
                label={t('fields.study.status.label')}
                withDefault
              />
            </Grid>
          </Protect>
        </Grid>
      }
      collapsableFields={
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          gap={10}
        >
          <Protect
            roles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.DescribingDoctor,
              UserRole.Manager,
              UserRole.Registration,
            ]}
          >
            <UserAutocomplete
              role={UserRole.Technician}
              controllerProps={{
                label: 'Wykonał',
                name: 'performer',
              }}
              submitOnChange={handleOnSearch}
            />
            <DoctorAutocomplete
              controllerProps={{
                label: 'Lekarz',
                name: 'anyDoctor',
              }}
              submitOnChange={handleOnSearch}
              showNoUserDoctors
            />
            <Protect
              roles={[UserRole.Admin, UserRole.Technician, UserRole.Manager]}
            >
              <DoctorAutocomplete
                controllerProps={{
                  label: 'Lekarz opisujący',
                  name: 'describer',
                }}
                submitOnChange={handleOnSearch}
                roles={[UserRole.DescribingDoctor]}
              />
            </Protect>
            <Protect roles={[UserRole.Admin]}>
              <SwitchField
                name="externalReferal"
                label={t('fields.study.externalReferal')}
                submitOnChange={handleOnSearch}
              />
            </Protect>
            <BranchSelect
              ownOnly
              withDefault
              name="branchId"
              fullWidth={false}
              submitOnChange={handleOnSearch}
            />
          </Protect>
          <StudyTypeAutocomplete submitOnChange={handleOnSearch} />
        </Box>
      }
    />
  );
};
