import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTimer } from 'react-timer-hook';
import {
  useAddReservationMutation,
  useDeleteReservationMutation,
  useUpdateReservationMutation,
} from '../../generated/graphql';

type Callback = () => void;

export function useFormTimer(
  callback: Callback,
  defaultDuration: number = 300,
  restartOn: string[] = [],
  startWhenFilled: string[] = [],
) {
  const { watch } = useFormContext();

  const savedCallback = useRef<Callback>();
  const processing = useRef<boolean>(false);

  const [addReservation] = useAddReservationMutation();
  const [updateReservation] = useUpdateReservationMutation();
  const [deleteReservation] = useDeleteReservationMutation();

  const [reservationId, setReservationId] = useState<string | null>(null);

  const { seconds, minutes, start, isRunning, restart } = useTimer({
    expiryTimestamp: DateTime.local()
      .plus({ seconds: defaultDuration })
      .toJSDate(),
    onExpire: () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
      if (reservationId) {
        deleteReservation({ variables: { id: reservationId } });
      }
    },
    autoStart: false,
  });

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      if (reservationId) {
        deleteReservation({ variables: { id: reservationId } });
      }
    };
  }, [deleteReservation, reservationId]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name && restartOn.includes(name)) {
        const allFilled = startWhenFilled.every((filledField) => {
          const val = value[filledField];
          return !!val && val !== '';
        });

        if (allFilled) {
          if (!isRunning && !processing.current) {
            start();
            processing.current = true;

            addReservation({
              variables: {
                data: {
                  deviceId: watch('device').id,
                  startDate: watch('startDate'),
                  endDate: watch('endDate'),
                },
              },
              onCompleted: ({ addReservation }) => {
                setReservationId(addReservation.id || null);
                processing.current = false;
              },
            });
          } else {
            restart(
              DateTime.local().plus({ seconds: defaultDuration }).toJSDate(),
            );
            if (reservationId) {
              updateReservation({
                variables: {
                  id: reservationId,
                  data: {
                    deviceId: watch('device').id,
                    startDate: watch('startDate'),
                    endDate: watch('endDate'),
                  },
                },
              });
            }
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [
    addReservation,
    defaultDuration,
    deleteReservation,
    isRunning,
    reservationId,
    restart,
    restartOn,
    start,
    startWhenFilled,
    updateReservation,
    watch,
  ]);

  return { seconds, minutes, isRunning };
}
