import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const logoWithMobile = 100;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      margin: theme.spacing(0, 1),
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoImg: {
      borderRadius: 4,
      backgroundColor: 'white',
      padding: theme.spacing(0, 4, 0, 4),
      [theme.breakpoints.down('md')]: {
        width: logoWithMobile,
      },
    },
  }),
);
