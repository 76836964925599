import AddIcon from '@mui/icons-material/Add';

import React, { useMemo } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { CellProps, Row } from 'react-table';
import { createUseReducerPersisted } from 'use-persisted';

import { useTableActions } from '../../../../../common/components/table/hooks/useTableActions';
import { TableContext } from '../../../../../common/components/table/state/table.context';
import { tableReducer } from '../../../../../common/components/table/state/table.reducer';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import useDidMountEffect from '../../../../../common/hooks/useDidMountEffect';
import { toLocaleDateTime } from '../../../../../common/utils/date.utils';
import {
  Doctor,
  useDoctorsQuery,
  UserRole,
} from '../../../../../generated/graphql';
import {
  useActivateDoctorAction,
  useChangeDoctorDescriptionAction,
  useChangeDoctorLoginAction,
  useDeleteDoctorAction,
  useEditDoctorAction,
} from '../../doctors/hooks/useDoctorActions';

import { HybridTableStorage } from '../../../../../common/components/table/state/table.storage';
import { FacilitySearchPanel } from '../search/FacilitiesSearch';

import { useTableRefresh } from '../../../../../common/hooks/useTableRefresh';
import { Protect } from '../../../components/auth/components/Protect';
import { Fab } from '@mui/material';
import { useStyles } from './FacilitiesList.styles';

export const NO_USER_ROW_COLOR = 'rgb(25,118,210,0.10)';

const initialState = {
  orderBy: {
    columnName: 'updatedAt',
    columnOrder: 'DESC',
  },
  pagination: {
    take: 20,
    skip: 0,
  },
  filter: {
    showNoUserDoctors: true,
    facility: true,
  },
};
const usePersistedReducer = createUseReducerPersisted(
  'facilitiesList',
  new HybridTableStorage(),
);

export const FacilitiesList: React.FunctionComponent = () => {
  const history = useHistory();
  const classes = useStyles();

  const [state, dispatch] = usePersistedReducer(
    tableReducer,
    HybridTableStorage.getInitialState('facilitiesList', initialState),
  );
  const { reloadAfterAction } = useTableRefresh(dispatch);

  const { data, fetchMore } = useDoctorsQuery({
    variables: {
      where: state.filter,
      pagination: { skip: 0, take: state.pagination.take },
      orderBy: [state.orderBy],
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const editDoctor = useEditDoctorAction(true);
  const activateDoctor = useActivateDoctorAction();
  const deleteDoctor = useDeleteDoctorAction();
  const changeDoctorLogin = useChangeDoctorLoginAction();
  const changeDoctorDescription = useChangeDoctorDescriptionAction();

  const tableActions = useTableActions([
    editDoctor,
    reloadAfterAction(activateDoctor),
    reloadAfterAction(changeDoctorLogin),
    reloadAfterAction(deleteDoctor),
    changeDoctorDescription,
  ]);

  const columns = useMemo<SortableColumn<Doctor>[]>(
    () => [
      {
        id: 'contact.companyName',
        Header: 'Nazwa',
        accessor: ({ user }) => user.contact.companyName,
        Cell: ({ row: { original } }: { row: Row<Doctor> }) =>
          original.user.contact.companyName,
      },
      {
        id: 'user.login',
        Header: 'Adres e-mail',
        accessor: ({ user }) =>
          user.login.startsWith('NO_USER') ? 'Brak użytkownika' : user.login,
      },
      {
        id: 'createdAt',
        Header: 'Data rejestracji',
        accessor: (doctor) => doctor.createdAt,
        Cell: ({ value }: CellProps<Doctor, Doctor['createdAt']>) =>
          toLocaleDateTime(value),
      },
      {
        id: 'userLoginEvents.createdAt',
        Header: 'Data ostatniego logowania',
        accessor: (doctor) => doctor.user?.lastLoginDate,
        Cell: ({ value }: CellProps<Doctor, Doctor['createdAt']>) =>
          toLocaleDateTime(value),
      },
      {
        id: 'attempts',
        Header: 'Liczba logowań (90 dni)',
        //disableSortBy: true,
        accessor: (doctor) => doctor.user?.loginAttempts,
      },
      tableActions,
    ],
    [tableActions],
  );

  useDidMountEffect(() => {
    fetchMore({
      variables: {
        where: state.filter,
        pagination: state.pagination,
        orderBy: [state.orderBy],
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  }, [fetchMore, state.filter, state.pagination, state.orderBy]);

  return (
    <>
      <TableContext.Provider value={{ state, dispatch }}>
        <FacilitySearchPanel
          onSearch={(filter) => {
            dispatch({ type: 'FILTER_CHANGE', payload: filter });
          }}
          filters={state.filter}
        />
        <Table<Doctor>
          title="Lista gabinetów"
          columnConfig={columns}
          data={data?.doctors.items as Doctor[]}
          totalCount={data?.doctors.total}
          onRowClicked={({ id }) => history.push(`/facilities/view/${id}`)}
        />
        <Protect
          roles={[UserRole.Admin, UserRole.Technician, UserRole.Manager]}
        >
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            component={RouterLink}
            to="/facilities/add"
          >
            <AddIcon />
          </Fab>
        </Protect>
      </TableContext.Provider>
    </>
  );
};
