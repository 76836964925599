import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { AddToQueue } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneField } from '../../../../../../common/components/form/fields/DropzoneField';
import { FileType } from '../../../../../../generated/graphql';
import { AttachedFiles } from '../../../../components/files/AttachedFiles';
import { StudyFormType } from '../../types';

type Props = {
  disabled: boolean;
  onDeleteFile: (fileId: number) => Promise<boolean>;
  study?: StudyFormType;
};

export const RejectedFilesTab: React.FC<Props> = ({
  disabled,
  onDeleteFile,
  study,
}) => {
  const { t } = useTranslation();

  return (
    <Box mb={12}>
      <Grid container spacing={4} direction="row">
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={8}>
            {!disabled && (
              <DropzoneField
                fileTypeLabel="plik z badaniem"
                name="files"
                required
                onDeleteFile={onDeleteFile}
                fileType={FileType.StudyImage}
                Icon={AddToQueue}
                iconColor="#7900bf"
                rejected
              />
            )}
            {study && (
              <AttachedFiles
                title={t('app.common.fields.attachedFiles.title.rejected')}
                files={study?.files?.filter(({ rejected }) => rejected) || []}
                studyId={study.id}
                onDeleteFile={onDeleteFile}
                showActions={!disabled}
                rejected
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
