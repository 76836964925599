import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ActionPanelProps } from './ActionPanel';

export const useStyles = makeStyles<
  Theme,
  Pick<ActionPanelProps<any, any>, 'alignment'>
>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: ({ alignment }) =>
      alignment === 'left'
        ? 'flex-start'
        : alignment === 'center'
        ? 'center'
        : 'flex-end',
    width: '100%',
  },
  button: {
    margin: theme.spacing(4),
  },
}));
