import * as React from 'react';
import { useHistory } from 'react-router';
import {
  PatientInput,
  PatientsDocument,
  PatientsQuery,
  useAddPatientMutation,
} from '../../../../../generated/graphql';
import { EMPTY_PATIENT, PatientForm } from '../form/PatientForm';
import { Button } from '@mui/material';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';

export const PatientAdd: React.FC = () => {
  const { success } = useMessages();
  const snackBarError = useGraphQLError();
  const history = useHistory();

  const [addPatient] = useAddPatientMutation({
    onCompleted: () => {
      success();
      history.push('/patients', { useCache: false, reload: true });
    },
    onError: snackBarError,
    refetchQueries: [PatientsDocument],
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          patients(existing: PatientsQuery['patients'], { toReference }) {
            if (data) {
              const newPatient = toReference({
                __typename: data.addPatient.__typename,
                id: data.addPatient.id,
              });

              return {
                ...existing,
                items: [newPatient, ...existing.items],
                total: existing.total + 1,
              } as any;
            }
          },
        },
      });
    },
  });

  const patientRef = React.useRef<PatientInput>(EMPTY_PATIENT());

  const onSubmit = async (data: PatientInput) => {
    await addPatient({ variables: { newPatient: data } });
    patientRef.current = data;
  };

  return (
    <PatientForm onSubmit={onSubmit} patient={patientRef.current}>
      <Button type="button" color="secondary" onClick={() => history.goBack()}>
        Anuluj
      </Button>
    </PatientForm>
  );
};
