import { DateTime } from 'luxon';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  AppointmentsDocument,
  AppointmentsQuery,
  useAddAppointmentMutation,
  useAddBreakMutation,
  useDeleteAppointmentMutation,
  useUpdateAppointmentMutation,
} from '../../../../../generated/graphql';
import { appointmentRefetchNeeded } from '../../../../../common/gql/appointmentCachePolicy';

export const useAppointments = (onCompleted?: () => {}) => {
  const { success, deleteSuccess } = useMessages();
  const snackBarError = useGraphQLError();

  const [addAppointment] = useAddAppointmentMutation({
    onCompleted: () => {
      success();
      appointmentRefetchNeeded(true);
      if (onCompleted) {
        onCompleted();
      }
    },
    onError: snackBarError,
    update: (cache, { data }) => {
      data?.addAppointments?.forEach((appointment) => {
        const variables = {
          where: {
            deviceId: appointment.device.id,
            startDateFrom: DateTime.fromJSDate(new Date(appointment.startDate))
              .startOf('day')
              .toISO(),
            startDateTo: DateTime.fromJSDate(new Date(appointment.startDate))
              .endOf('day')
              .toISO(),
          },
          pagination: {
            skip: 0,
            take: 100,
          },
        };

        const existing = cache.readQuery<AppointmentsQuery>({
          query: AppointmentsDocument,
          variables,
        });
        const appointments = existing?.appointments;
        if (!appointments || !appointment) return;
        cache.writeQuery({
          query: AppointmentsDocument,
          data: {
            appointments: [...appointments, appointment],
          },
          variables,
        });
      });
    },
  });

  const [addBreak] = useAddBreakMutation({
    onCompleted: () => {
      success();
      if (onCompleted) {
        onCompleted();
      }
    },
    onError: snackBarError,
    refetchQueries: [{ query: AppointmentsDocument }, 'appointments'],
  });

  const [updateAppointment] = useUpdateAppointmentMutation({
    onCompleted: () => {
      success();
      appointmentRefetchNeeded(true);
      if (onCompleted) {
        onCompleted();
      }
    },
    onError: snackBarError,
    update: (cache, { data }) => {
      if (!data?.updateAppointment) return;

      const updatedAppointment = data.updateAppointment;
      const updatedDeviceId = updatedAppointment.device.id;
      const startDate = new Date(updatedAppointment.startDate);

      const startDateFrom = DateTime.fromJSDate(startDate)
        .startOf('day')
        .toISO();
      const startDateTo = DateTime.fromJSDate(startDate).endOf('day').toISO();

      const targetDeviceVariables = {
        where: {
          deviceId: updatedDeviceId,
          startDateFrom,
          startDateTo,
        },
        pagination: {
          skip: 0,
          take: 100,
        },
      };

      const targetDeviceData = cache.readQuery<AppointmentsQuery>({
        query: AppointmentsDocument,
        variables: targetDeviceVariables,
      });

      if (targetDeviceData?.appointments) {
        const appointmentExists = targetDeviceData.appointments.some(
          (appointment) => appointment.id === updatedAppointment.id,
        );

        if (!appointmentExists) {
          cache.writeQuery({
            query: AppointmentsDocument,
            variables: targetDeviceVariables,
            data: {
              appointments: [
                ...targetDeviceData.appointments,
                updatedAppointment,
              ],
            },
          });
        } else {
          cache.modify({
            id: cache.identify(updatedAppointment),
            fields: {
              startDate: () => updatedAppointment.startDate,
              endDate: () => updatedAppointment.endDate,
              device: () => updatedAppointment.device,
            },
          });
        }
      }

      const possibleDeviceIds = cache.extract()?.ROOT_QUERY
        ? Object.keys(cache.extract().ROOT_QUERY)
            .filter((key) => key.startsWith('appointments'))
            .map((key) => {
              const match = key.match(/deviceId:\s*(\d+)/);
              return match ? parseInt(match[1], 10) : null;
            })
            .filter((id) => id !== null && id !== updatedDeviceId)
        : [];

      possibleDeviceIds.forEach((deviceId) => {
        if (!deviceId) return;

        const sourceDeviceVariables = {
          where: {
            deviceId,
            startDateFrom,
            startDateTo,
          },
          pagination: {
            skip: 0,
            take: 100,
          },
        };

        const sourceDeviceData = cache.readQuery<AppointmentsQuery>({
          query: AppointmentsDocument,
          variables: sourceDeviceVariables,
        });

        if (sourceDeviceData?.appointments) {
          const hasAppointment = sourceDeviceData.appointments.some(
            (appointment) => appointment.id === updatedAppointment.id,
          );

          if (hasAppointment) {
            cache.writeQuery({
              query: AppointmentsDocument,
              variables: sourceDeviceVariables,
              data: {
                appointments: sourceDeviceData.appointments.filter(
                  (appointment) => appointment.id !== updatedAppointment.id,
                ),
              },
            });
          }
        }
      });
    },
    onQueryUpdated(observableQuery) {
      if (observableQuery.queryName === 'Appointments') {
        observableQuery.refetch();
      }
      return false;
    },
  });

  const [deleteAppointment] = useDeleteAppointmentMutation({
    onCompleted: () => {
      deleteSuccess();
      appointmentRefetchNeeded(true);
      if (onCompleted) {
        onCompleted();
      }
    },
    onError: snackBarError,
    update: (cache, { data }) => {
      if (data?.deleteAppointment) {
        cache.evict({
          id: cache.identify({
            __typename: 'Appointment',
            id: data.deleteAppointment,
          }),
        });
        cache.gc();
      }
    },
  });

  return { addAppointment, updateAppointment, deleteAppointment, addBreak };
};
