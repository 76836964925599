import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { get } from 'lodash-es';
import { useCallback, useContext } from 'react';
import {
  Row,
  UseRowSelectRowProps,
  UseRowSelectState,
  UseTableInstanceProps,
} from 'react-table';
import { RowID, TableRowItem } from '../../../types/table';
import { TableContext } from '../state/table.context';
import { TableProps } from '../Table';

export type TableBodyProps<T extends TableRowItem> = {
  onRowClicked: TableProps<T>['onRowClicked'];
  pageIndex: number;
  prepareRow: UseTableInstanceProps<T>['prepareRow'];
  rowColor: TableProps<T>['rowColor'];
  rows: UseTableInstanceProps<T>['rows'];
  selectable: TableProps<T>['selectable'];
  selectedRowIds: UseRowSelectState<T>['selectedRowIds'];
};

export const Body = <T extends TableRowItem>({
  prepareRow,
  onRowClicked,
  rowColor,
  selectedRowIds,
  selectable,
  pageIndex,
  rows,
}: TableBodyProps<T>) => {
  const isSelected = useCallback(
    (id: RowID) => {
      return selectable && !!selectedRowIds[id];
    },
    [selectable, selectedRowIds],
  );
  const { state } = useContext(TableContext);

  return (
    <TableBody>
      {(rows as (Row<T> & UseRowSelectRowProps<T>)[]).map((row, index) => {
        prepareRow(row);
        const isPreviouslyClickedRow = row.id === state.lastSelectedRowId;
        const isItemSelected = isSelected(row.id) || isPreviouslyClickedRow;

        const labelId = `enhanced-table-checkbox-${index}`;
        const backgroundColor = !isItemSelected && rowColor && rowColor(row);
        return (
          <TableRow
            hover
            style={{
              cursor: onRowClicked ? 'pointer' : 'default',
              ...(backgroundColor ? { backgroundColor } : {}),
            }}
            onClick={() => {
              if (onRowClicked) {
                onRowClicked(row);
              }
            }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={pageIndex + row.id}
            selected={isItemSelected}
          >
            {selectable && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                />
              </TableCell>
            )}
            {row.cells.map((cell) => {
              return (
                <TableCell
                  key={`${cell.column.id}-${cell.row.id}`}
                  style={{
                    ...(cell.column.id === 'actions' ? { padding: 0 } : {}),
                    ...(cell.column.maxWidth
                      ? { maxWidth: `${cell.column.maxWidth}px` }
                      : {}),
                    ...(cell.column.width
                      ? { width: `${cell.column.width}px` }
                      : {}),
                    ...(cell.column.minWidth
                      ? { minWidth: `${cell.column.minWidth}px` }
                      : {}),
                  }}
                >
                  {cell.render
                    ? cell.render('Cell')
                    : (cell.column as any).accessor
                    ? get(row.original, (cell.column as any).accessor as string)
                    : ''}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
