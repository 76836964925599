import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  useAddSoftwareMutation,
  useUpdateSoftwareMutation,
  SoftwareInput,
  useDeleteSoftwareMutation,
  SoftwareDocument,
  SoftwareQuery,
} from '../../../../../generated/graphql';
import { useHistory, useRouteMatch } from 'react-router';
import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';

export const useSoftwareApi = () => {
  const { success, deleteSuccess } = useMessages();
  const history = useHistory();

  const [addSoftware] = useAddSoftwareMutation({
    onCompleted: () => success(),
  });
  const [updateSoftware] = useUpdateSoftwareMutation({
    onCompleted: () => success(),
  });
  const [deleteSoftware] = useDeleteSoftwareMutation({
    onCompleted: () => deleteSuccess(),
  });

  const { path } = useRouteMatch();
  const confirmRemoval = useRemoveConfirm();

  const onAddSubmit = (softwareInput: SoftwareInput) => {
    addSoftware({
      variables: { newSoftware: softwareInput },
      update: (cache, { data: newSoftware }) => {
        const data = cache.readQuery<SoftwareQuery>({
          query: SoftwareDocument,
        });
        const software = data?.software.edges;

        if (software && newSoftware) {
          cache.writeQuery<SoftwareQuery>({
            query: SoftwareDocument,
            data: {
              software: {
                edges: [
                  ...software,
                  {
                    node: newSoftware?.createOneSoftware,
                  },
                ],
              },
            },
          });
        }
      },
    });

    history.push(`${path}/`, { useCache: false });
  };

  const onSaveSubmit = (software: SoftwareInput, softwareId: number) => {
    updateSoftware({
      variables: { software, softwareId },
    });

    history.push(`${path}/`, { useCache: false });
  };

  const onDeleteSoftware = async (softwareId: number) => {
    await confirmRemoval();
    deleteSoftware({
      variables: { softwareId },
      update: (cache) => {
        const data = cache.readQuery<SoftwareQuery>({
          query: SoftwareDocument,
        });
        const software = data?.software.edges;

        if (software) {
          cache.writeQuery<SoftwareQuery>({
            query: SoftwareDocument,
            data: {
              software: {
                edges: software.filter(({ node: { id } }) => id !== softwareId),
              },
            },
          });
        }
      },
    });
  };

  return { onAddSubmit, onSaveSubmit, onDeleteSoftware };
};
