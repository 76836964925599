import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRawLang } from '../../../../common/components/form/validation/yup-locale';
import { CookieTerms } from './CootkieTerms';
import { CookieTermsEN } from './CootkieTermsEN';
import { CookieTermsUA } from './CootkieTermsUA';

export const CookieModal: React.FC<DialogProps> = (props) => {
  const { t } = useTranslation();
  const { onClose } = props;

  const currentLang = getRawLang();

  const handleClose = (
    event: {},
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (onClose) {
      onClose(event, reason || 'backdropClick');
    }
  };

  return (
    <Dialog {...props} style={{ height: '90%' }}>
      <Box sx={{ position: 'relative' }}>
        <DialogTitle>{t('app.cookieConsent.dialog.title')}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={(e) => handleClose(e)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {currentLang === 'pl' && <CookieTerms />}
      {currentLang === 'en' && <CookieTermsEN />}
      {currentLang === 'ua' && <CookieTermsUA />}
      <DialogActions>
        <Button onClick={(e) => handleClose(e)} color="primary">
          {t('app.common.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
