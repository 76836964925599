import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Paper, Tab, Tabs } from '@mui/material';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { TFunction } from 'i18next';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { AcceptTerms } from '../../../../../common/components/accept-terms/AcceptTerms';
import { CenteredPage } from '../../../../../common/components/centered-page/CenteredPage';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../common/components/form/Form';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useRecaptcha } from '../../../../../common/hooks/useRecaptcha';
import {
  SignupType,
  signupValidationSchema,
} from '../../../../../common/validation/doctor/schema';
import { passwordValidationSchema } from '../../../../../common/validation/password';
import {
  useOauthSignupDoctorMutation,
  useSignupDoctorMutation,
} from '../../../../../generated/graphql';
import { TopBarLogo } from '../../top-bar/logo/TopBarLogo';
import { OAuthState, SignupForm } from '../oauth/useOAuthLogin';
import { useStyles } from './SignupPage.styles';

const validationSchema = (
  t: TFunction,
  requirePassword: boolean,
  signupType: SignupType,
) => {
  const baseValidation = requirePassword
    ? passwordValidationSchema(t)
    : yup.object();

  return baseValidation
    .shape({
      login: yup.string().required().email(),
      acceptTerms: yup
        .boolean()
        .oneOf([true], 'Akceptacja tego pola jest wymagana'),
    })
    .concat(signupValidationSchema(t, signupType) as any);
};

const SignupSuccess: React.FC = () => {
  return (
    <Paper>
      <Box mt={12}>
        <Alert severity="success">
          <AlertTitle>Rejestracja zakończona sukcesem!</AlertTitle>
          Dziękujemy za zarejestrowanie na Portalu Lekarza. Niebawem otrzymasz
          od nas wiadomość e-mail dotyczącą aktywacji twojego konta.
        </Alert>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          fullWidth
          href="/login"
        >
          Logowanie
        </Button>
      </Box>
    </Paper>
  );
};

const getDefaultValues = (oauthInfo?: OAuthState): SignupForm => ({
  login: oauthInfo?.login || '',
  password: '',
  confirmPassword: '',
  contact: {
    firstName: oauthInfo?.firstName || '',
    lastName: oauthInfo?.lastName || '',
  },
  licenseNumber: '',
  acceptTerms: false,
  phoneNumber: '',
});

export const SignupPage: React.FC = () => {
  const snackBarError = useGraphQLError();
  const [signup, { data }] = useSignupDoctorMutation({
    onError: snackBarError,
  });
  const [oauthSignup, { data: oAuthSignupData }] = useOauthSignupDoctorMutation(
    {
      onError: snackBarError,
    },
  );

  const { t } = useTranslation();
  const history = useHistory<{ oauth: OAuthState }>();
  const classes = useStyles();

  const [signupType, setSignupType] = useState<SignupType>('doctor');

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newValue: SignupType,
  ) => {
    setSignupType(newValue);
  };

  const { executeRecaptcha } = useRecaptcha();

  const oAuthSignupToken = history.location.state?.oauth.token;

  const onSubmit = async ({
    phoneNumber,
    confirmPassword,
    acceptTerms,
    ...signupFormData
  }: SignupForm) => {
    const token = await executeRecaptcha?.('signup');

    const signupData = {
      ...signupFormData,
      contact: {
        ...signupFormData.contact,
        phoneNumbers: [phoneNumber],
        emailAddresses: [signupFormData.login],
      },
    };

    if (oAuthSignupToken)
      oauthSignup({
        variables: { signupData },
        context: {
          headers: {
            access_token: oAuthSignupToken,
            oauth_type: history.location.state?.oauth.type,
          },
        },
      });
    else
      signup({
        variables: { signupData },
        context: {
          headers: {
            recaptcha: token,
          },
        },
      });
  };

  const defaultSignupData = useMemo(
    () => getDefaultValues(history.location.state?.oauth),
    [history.location.state?.oauth],
  );

  const signupSuccess =
    !!data?.signupDoctor?.email || !!oAuthSignupData?.oauthSignupDoctor?.email;

  return (
    <CenteredPage>
      <Paper className={classes.root}>
        <Box p={8}>
          <Box pt={8}>
            <TopBarLogo alt="logo" src="/img/logo.svg" />
          </Box>

          {signupSuccess ? (
            <SignupSuccess />
          ) : (
            <Form<SignupForm>
              onSubmit={onSubmit}
              formOptions={{
                resolver: yupResolver(
                  validationSchema(t, !oAuthSignupToken, signupType) as any,
                ),
                defaultValues: defaultSignupData,
              }}
              noElevation
            >
              <Box p={8}>
                <Alert severity="info">
                  <AlertTitle>{t('pages.signup.title')}</AlertTitle>
                  {t('pages.signup.info')}
                </Alert>
              </Box>

              <Tabs
                value={signupType}
                onChange={handleTabChange}
                variant="fullWidth"
                centered
              >
                <Tab label="Lekarz" value="doctor" />
                <Tab label="Gabinet" value="facility" />
              </Tabs>

              <TextField
                name="login"
                label="Login (e-mail)"
                fullWidth
                htmlAutoComplete={false}
                required
                disabled={!!history.location.state?.oauth.type}
              />

              {!oAuthSignupToken && (
                <Grid container spacing={8}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="password"
                      label="Hasło"
                      password
                      fullWidth
                      htmlAutoComplete={false}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      name="confirmPassword"
                      label="Powtórz Hasło"
                      password
                      fullWidth
                      required
                      htmlAutoComplete={false}
                    />
                  </Grid>
                </Grid>
              )}

              {signupType === 'doctor' && (
                <Grid container spacing={8}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="contact.firstName"
                      label="Imię"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      name="contact.lastName"
                      label="Nazwisko"
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              )}
              {signupType === 'facility' && (
                <Grid container spacing={8}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="contact.companyName"
                      label="Nazwa"
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    name="phoneNumber"
                    label="Numer telefonu"
                    fullWidth
                    required
                  />
                </Grid>
                {signupType === 'doctor' && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      name="licenseNumber"
                      label="Numer PWZ"
                      fullWidth
                      required
                    />
                  </Grid>
                )}
                {signupType === 'facility' && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      name="contact.nip"
                      label="Numer NIP"
                      fullWidth
                      required
                    />
                  </Grid>
                )}
              </Grid>
              <Box>
                <Box py={8}>
                  <AcceptTerms />
                </Box>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  Załóż konto
                </Button>

                {/* TODO: Refactor to BackButton */}
                <Button
                  type="button"
                  color="primary"
                  fullWidth
                  onClick={() => history.goBack()}
                >
                  Anuluj
                </Button>
              </Box>
            </Form>
          )}
        </Box>
      </Paper>
    </CenteredPage>
  );
};
