import { useConfirm } from 'material-ui-confirm';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe } from '../../generated/graphql';
import { TextField, TextFieldProps } from '../components/form/fields/TextField';
import { Form } from '../components/form/Form';

export const useModalInput = (
  inputKey: string,
  titleKey: string,
  textFieldProps: TextFieldProps = { name: 'value' },
): ((defaultValue?: Maybe<string>) => Promise<string | undefined>) => {
  const confirm = useConfirm();
  const { t } = useTranslation();
  const selectedValue = useRef<string>();

  const onSubmit = (data?: { value: string }) => {
    selectedValue.current = data?.value;
  };

  return async (defaultValue) => {
    await confirm({
      title: t(titleKey),
      description: (
        <>
          <Form<{ value: string }>
            onSubmit={onSubmit}
            noElevation
            formOptions={{
              defaultValues: {
                value: defaultValue || undefined,
              },
            }}
          >
            <TextField
              minRows={4}
              maxRows={8}
              multiline
              label={t(inputKey)}
              submitOnChange={onSubmit}
              submitOnChangeDelay={0}
              {...textFieldProps}
            />
          </Form>
        </>
      ),
      cancellationText: t('confirmationDialog.action.cancelButton'),
      confirmationText: t('confirmationDialog.action.confirmButton'),
      allowClose: false,
    });
    return selectedValue.current;
  };
};
