import { Box } from '@mui/material';
import React from 'react';
import { SwitchField } from '../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { SearchPanel } from '../../../../../common/components/search/SearchPanel';
import {
  DoctorFiltersInput,
  useAppConfigQuery,
} from '../../../../../generated/graphql';
import { TagsField } from '../../../components/tags/TagsField';
import { SelectField } from '../../../../../common/components/form/fields/SelectField';

type Props = {
  onSearch: (filter: DoctorFiltersInput) => void;
  filters?: DoctorFiltersInput;
};

export const DEFAULTS = {
  name: '',
  licenseNumber: '',
  showNoUserDoctors: true,
  specializations: [],
  region: '',
  facility: false,
};

export const DoctorSearchPanel: React.FC<Props> = ({
  onSearch,
  filters = { ...DEFAULTS },
}) => {
  const { data: appConfigData } = useAppConfigQuery();
  const tagsFieldOptions = appConfigData?.appConfig.specializations
    ? appConfigData.appConfig.specializations
    : [];

  const regions = appConfigData?.appConfig?.regions || [];

  return (
    <SearchPanel
      defaultValues={filters}
      onSearch={onSearch}
      resetValues={{ ...DEFAULTS }}
      mainFields={
        <Box
          display="flex"
          gap={10}
          alignItems="center"
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <TextField
            name="name"
            label="Imię/Nazwisko"
            submitOnChange={onSearch}
            fullWidth
          />
          <TextField
            name="licenseNumber"
            label="PWZ"
            submitOnChange={onSearch}
            fullWidth
          />
          <TagsField
            label="Specializacje"
            name="specializations"
            submitOnChange={onSearch}
            options={tagsFieldOptions}
            fullWidth
          />
          <SelectField
            label="Region"
            name="region"
            submitOnChange={onSearch}
            options={regions.map((region) => ({
              label: region,
              value: region,
            }))}
            fullWidth
          />
          <SwitchField
            label="Pokaż lekarzy bez użytkownika"
            name="showNoUserDoctors"
            submitOnChange={onSearch}
          />
        </Box>
      }
    />
  );
};
