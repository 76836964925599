import { Button, ButtonProps } from '@mui/material';
import React from 'react';

export interface OAuthButtonProps extends Omit<ButtonProps, 'fullWidth'> {
  logo: string;
  text: string;
  onClick: () => void;
}

export const OAuthButton: React.FC<OAuthButtonProps> = ({
  logo,
  text,
  onClick,
  ...buttonProps
}) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      fullWidth
      sx={{
        textTransform: 'none',
        fontWeight: 600,
        width: '100%',
        position: 'relative',
        paddingLeft: '48px',
        paddingRight: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
        minHeight: '42px',
        '& .logo-container': {
          position: 'absolute',
          left: '16px',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '24px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
        },
        '& .logo-image': {
          width: '24px',
          height: '24px',
          objectFit: 'contain',
          display: 'block',
        },
        '& .button-text': {
          textAlign: 'center',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }}
      {...buttonProps}
    >
      <div className="logo-container">
        <img className="logo-image" alt="oauth-logo" src={logo} />
      </div>

      <div className="button-text">{text}</div>
    </Button>
  );
};
