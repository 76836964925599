import {
  EventInput,
  EventRemoveArg,
  EventSourceInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import '../appointments.css';
import {
  Appointment,
  AppointmentDataFragment,
  AppointmentUpdateInput,
  Branch,
  Device,
  useAppointmentsQuery,
  useGetStudyTypesForBranchQuery,
  useReservationsQuery,
} from '../../../../../generated/graphql';
import { AppointmentLocationState } from '../edit/AppointmentEdit';
import { useAppointments } from '../hooks/useAppointments';
import {
  appointmentRefetchNeeded,
  APPOINTMENTS_CACHE_TIME,
} from '../../../../../common/gql/appointmentCachePolicy';
import { useReactiveVar } from '@apollo/client';

type Props = {
  device: Device;
  branch: Omit<Branch, 'employedDoctors'>;
  date: Date;
  //lastSelectedSlot: Date | null;
  //onCellClicked: (date: Date, deviceId: number) => void;
};

function getAppointmentColor(appointment: AppointmentDataFragment): string {
  const is3D = appointment.studyType?.is3D.toString() || '';

  let hash = 0;

  for (let i = 0; i < is3D.length; i++) {
    hash = is3D.charCodeAt(i) + ((hash << 5) - hash);
  }
  let h = 100 + (hash % 90); // Range from 100 to 150
  let s = 40 + (Math.abs(hash) % 30); // Same as before
  let l = 60 + (Math.abs(hash) % 20); // Initially same as before

  if (new Date(appointment.endDate) < new Date() || appointment.study?.id) {
    h -= 15;
    l += 15; // Adjust this value to get the desired dimming effect
    s -= 40;
  }

  return `hsl(${h}, ${s}%, ${l}%)`;
}

const BUSINESS_HOURS: EventInput[] = [
  {
    daysOfWeek: [1, 2, 3, 4, 5],
    startTime: '08:00',
    endTime: '20:00',
  },
  {
    daysOfWeek: [6],
    startTime: '09:00',
    endTime: '15:00',
  },
];

function isBusinessHour(date: DateTime): boolean {
  const dayOfWeek = date.weekday;

  for (let i = 0; i < BUSINESS_HOURS.length; ++i) {
    const businessHour = BUSINESS_HOURS[i];

    if (
      businessHour.daysOfWeek.includes(dayOfWeek) &&
      compareTime(
        businessHour.startTime,
        businessHour.endTime,
        date.toFormat('HH:mm'),
      )
    ) {
      return true;
    }
  }

  return false;
}

function getAppointmentTitle(appointment: Appointment): string {
  if (appointment.isBreak) {
    return 'Przerwa';
  } else {
    return (
      `${appointment.patient?.contact?.lastName} ${appointment.patient?.contact?.firstName}` +
      ' - ' +
      appointment.studyType?.name
    );
  }
}

function compareTime(
  startTime: string,
  endTime: string,
  checkTime: string,
): boolean {
  const startLuxTime = DateTime.fromFormat(startTime, 'HH:mm');
  const endLuxTime = DateTime.fromFormat(endTime, 'HH:mm');
  const checkLuxTime = DateTime.fromFormat(checkTime, 'HH:mm');

  return checkLuxTime >= startLuxTime && checkLuxTime < endLuxTime;
}

const customSlotTimes: Record<string, string> = {
  '19:15': '19:10',
  '19:30': '19:20',
  '19:45': '19:30',
} as const;

export const DeviceCalendar: React.FC<Props> = ({
  device,
  branch,
  date,
  //lastSelectedSlot,
  //onCellClicked,
}) => {
  const calendarRef = useRef<FullCalendar | null>(null);

  const history = useHistory<AppointmentLocationState>();

  const [removedEvent, setRemovedEvent] = useState<EventRemoveArg | null>();

  useEffect(() => {
    document
      .getElementsByClassName('fc-timegrid-now-indicator-arrow')[0]
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      queueMicrotask(() => {
        const api = calendarRef.current?.getApi();
        api?.gotoDate(date);
      });
    }
  }, [date]);

  const startDateFrom = useMemo(
    () => DateTime.fromJSDate(new Date(date)).startOf('day').toISO(),
    [date],
  );

  const startDateTo = useMemo(
    () => DateTime.fromJSDate(new Date(date)).endOf('day').toISO(),
    [date],
  );

  const where = useMemo(
    () => ({
      deviceId: device.id,
      startDateFrom,
      startDateTo,
    }),
    [device.id, startDateFrom, startDateTo],
  );

  const { data: reservations } = useReservationsQuery({
    variables: {
      deviceId: device.id,
    },
    pollInterval: APPOINTMENTS_CACHE_TIME,
    notifyOnNetworkStatusChange: true,
  });

  const shouldRefetch = useReactiveVar(appointmentRefetchNeeded);

  // When refetch is needed, reset the reactive variable
  useEffect(() => {
    if (shouldRefetch) {
      appointmentRefetchNeeded(false);
    }
  }, [shouldRefetch]);

  const { data: appointments } = useAppointmentsQuery({
    variables: {
      where,
      pagination: {
        skip: 0,
        take: 100,
      },
    },
    fetchPolicy: shouldRefetch ? 'network-only' : 'cache-first',
    nextFetchPolicy: 'cache-first',
    pollInterval: APPOINTMENTS_CACHE_TIME,
    notifyOnNetworkStatusChange: true,
  });

  const { data: studyTypesAllowed } = useGetStudyTypesForBranchQuery({
    variables: { branchId: branch.id, deviceId: device.id },
  });

  const { updateAppointment } = useAppointments();

  const events: EventSourceInput =
    appointments?.appointments.map((appointment) => ({
      date: appointment.startDate,
      end: appointment.endDate,
      title: getAppointmentTitle(appointment as Appointment),
      key: appointment.id,
      borderColor: getAppointmentColor(appointment),
      ...(appointment.isBreak
        ? {}
        : { backgroundColor: getAppointmentColor(appointment) }),
      textColor: '#111',
      classNames: appointment.study?.id
        ? ['completed-appointment']
        : appointment.isBreak
        ? ['break']
        : [],
      extendedProps: {
        appointment,
      },
    })) || [];

  events.push(
    ...(reservations?.reservations?.map((reservation) => ({
      date: reservation.startDate,
      end: reservation.endDate,
      title: `Zajęte przez ${reservation.createdBy}`,
      key: reservation.id,
      textColor: '#111',
      classNames: ['reservation'],
      extendedProps: {
        reservation,
      },
    })) || []),
  );

  return (
    <Box minWidth={300} style={{ scrollSnapAlign: 'start' }}>
      <div className="fullcalendar-custom-font">
        <FullCalendar
          eventContent={(event) => {
            const appointment = event.event.extendedProps
              .appointment as Appointment;
            return appointment?.isBreak ? (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'black',
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {event.event.title}
              </div>
            ) : (
              <Tooltip
                enterDelay={1000}
                enterNextDelay={1000}
                style={{ minWidth: 300 }}
                title={
                  appointment ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {appointment.patient?.contact.phoneNumbers?.map(
                        (number) => (
                          <span key={number}>Telefon: {number}</span>
                        ),
                      )}
                      {appointment.patient?.contact.emailAddresses?.map(
                        (address) => (
                          <span key={address}>E-mail: {address}</span>
                        ),
                      )}
                      {appointment.comments && (
                        <span>{`Notatka: ${appointment.comments}`}</span>
                      )}
                      {appointment.patient?.contact.primaryDescription && (
                        <span>
                          {`Notatka Pacjenta: ${appointment.patient?.contact.primaryDescription}`}
                        </span>
                      )}
                    </div>
                  ) : (
                    event.event.extendedProps.reservation && (
                      <span>{`Zajęte przez: ${event.event.extendedProps.reservation.createdBy}`}</span>
                    )
                  )
                }
              >
                <div
                  style={{
                    height: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {event.event.title}
                </div>
              </Tooltip>
            );
          }}
          editable
          eventResizableFromStart={false}
          eventDurationEditable={false}
          droppable
          nowIndicator
          eventOverlap={false}
          eventRemove={(event) => setRemovedEvent(event)}
          eventAllow={(_, event) => {
            const appointment = event?.extendedProps.appointment as Appointment;
            return (
              appointment &&
              !appointment.isBreak &&
              new Date(appointment.endDate) > new Date()
            );
          }}
          eventDrop={async (event) => {
            const appointment = event.event.extendedProps
              .appointment as AppointmentUpdateInput;

            if (
              !appointment ||
              appointment.isBreak ||
              !event.event.start ||
              (event.event.end || new Date()) < new Date()
            ) {
              event.revert();
              return null;
            }

            const result = await updateAppointment({
              variables: {
                id: event.event.extendedProps.key,
                data: {
                  id: appointment.id,
                  branch: {
                    id: appointment.branch?.id,
                  },
                  device: {
                    id: appointment.device?.id,
                  },
                  patient: {
                    id: appointment.patient?.id,
                  },
                  studyType: {
                    id: appointment.studyType?.id,
                  },
                  startDate: event.event.start,
                },
              },
            });

            if (result.errors) {
              event.revert();
            }
          }}
          eventReceive={async (event) => {
            const appointment = event.event.extendedProps
              .appointment as AppointmentUpdateInput;

            if (
              !appointment ||
              appointment.isBreak ||
              !event.event.start ||
              (event.event.end || new Date()) < new Date() ||
              !studyTypesAllowed?.branchStudyTypes.find(
                ({ id }) => appointment.studyType?.id === id,
              )
            ) {
              event.revert();
              removedEvent?.revert();

              return null;
            }

            const result = await updateAppointment({
              variables: {
                id: event.event.extendedProps.key,
                data: {
                  id: appointment.id,
                  branch: {
                    id: branch?.id,
                  },
                  device: {
                    id: device?.id,
                  },
                  patient: {
                    id: appointment.patient?.id,
                  },
                  studyType: {
                    id: appointment.studyType?.id,
                  },
                  startDate: event.event.start,
                },
              },
            });

            if (result.errors) {
              event.revert();
            }
          }}
          displayEventTime={false}
          events={events}
          expandRows={true}
          initialDate={date}
          ref={calendarRef}
          height="auto"
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridDay"
          dateClick={(args) => {
            //onCellClicked(args.date, device.id);

            if (
              isBusinessHour(DateTime.fromJSDate(args.date)) &&
              args.date > new Date()
            ) {
              history.push({
                pathname: `/appointments/new`,
                state: {
                  defaultDate: args.date,
                  branch,
                  device,
                },
              });
            }
          }}
          eventClick={({ event: { extendedProps } }) => {
            if (extendedProps.reservation) return;
            history.push(`/appointments/${extendedProps.key}`);
          }}
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          dayHeaderContent={() => (
            <div
              style={{
                backgroundColor: branch.color || '#fff',
                minHeight: 40,
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {branch.name} - {device.name}
            </div>
          )}
          locale="pl"
          weekText=""
          buttonText={{
            today: 'Dziś',
            month: 'Miesiąć',
            week: 'Tydzień',
            day: 'Dzień',
            list: 'Lista',
          }}
          slotLabelContent={({ text }) => {
            const customText = customSlotTimes[text] || text;
            return (
              <div
                style={{
                  background: `linear-gradient(to right, transparent 0%, ${branch.color}50 50%, transparent 100%)`,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {customText}
              </div>
            );
          }}
          allDaySlot={false}
          slotMinTime="07:00"
          slotMaxTime="21:00"
          slotDuration="00:15"
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'narrow',
          }}
          slotLabelInterval="00:15"
          businessHours={BUSINESS_HOURS}
        />
      </div>
    </Box>
  );
};
