import { Grid } from '@mui/material';
import React from 'react';
import { SwitchField } from '../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { SearchDateTextField } from '../../../../../common/components/search/fields/date/SearchDateTextField';
import { SearchPanel } from '../../../../../common/components/search/SearchPanel';
import { EMPTY_ID_INPUT } from '../../../../../common/types/idInput';
import {
  IdInput,
  Maybe,
  ReferalFiltersInput,
  UserRole,
} from '../../../../../generated/graphql';
import { Protect } from '../../../components/auth/components/Protect';
import { DoctorAutocomplete } from '../../doctors/fields/DoctorAutocomplete';

type ReferalSearchFormModel = Omit<ReferalFiltersInput, 'issuerId'> & {
  issuer?: Maybe<IdInput>;
};

type Props = {
  onSearch: (filter: ReferalSearchFormModel) => void;
  filters?: ReferalSearchFormModel;
};

export const DEFAULTS = {
  patient: { name: '' },
  completed: null,
  issuer: EMPTY_ID_INPUT(),
};

export const ReferalSearchPanel: React.FC<Props> = ({
  onSearch,
  filters = { ...DEFAULTS },
}) => {
  return (
    <SearchPanel
      defaultValues={filters}
      onSearch={onSearch}
      resetValues={{ ...DEFAULTS }}
      mainFields={
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md="auto">
            <TextField
              name="patient.name"
              label="Imię/Nazwisko"
              submitOnChange={onSearch}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <SwitchField
              name="completed"
              label="Zrealizowane"
              submitOnChange={onSearch}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <SearchDateTextField
              fromName="patient.birthDateFrom"
              toName="patient.birthDateTo"
              label="Data urodzenia pacjenta"
              submitOnChange={onSearch}
            />
          </Grid>
          <Protect
            roles={[UserRole.Admin, UserRole.Technician, UserRole.Registration]}
          >
            <Grid item xs={12} md="auto">
              <DoctorAutocomplete
                controllerProps={{
                  label: 'Lekarz',
                  name: 'issuer',
                }}
                submitOnChange={onSearch}
              />
            </Grid>
          </Protect>
        </Grid>
      }
    />
  );
};
