import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SwitchProps, Switch, FormLabel, Box } from '@mui/material';
import {
  LabelProps,
  DefaultValueProps,
  NameProps,
  SubmitOnChangeProps,
} from '../types';

type Props = Partial<SwitchProps> &
  LabelProps &
  NameProps &
  SubmitOnChangeProps &
  DefaultValueProps;

export const SwitchField: React.FC<Props> = ({ submitOnChange, ...props }) => {
  const { control, handleSubmit } = useFormContext();
  return (
    <Box display="flex" alignItems="center">
      <FormLabel>{props.label}</FormLabel>
      <Controller
        control={control}
        name={props.name}
        render={({ field: { value, onChange, onBlur } }) => (
          <Switch
            {...props}
            disabled={props.disabled}
            onBlur={onBlur}
            onChange={(e, checked) => {
              onChange(e.target.checked);
              if (props.onChange) props.onChange(e, checked);
              if (submitOnChange) handleSubmit(submitOnChange)();
            }}
            checked={
              value ?? (props.defaultValue || props.defaultChecked || false)
            }
          />
        )}
      />
    </Box>
  );
};
