import { AutocompleteProps } from '@mui/material/Autocomplete';
import { useCallback } from 'react';
import {
  AsyncAutocompleteField,
  AsyncAutocompleteProps,
  AutocompleteItem,
} from '../../../../../common/components/form/fields/AsyncAutocompleteField';
import { SubmitOnChangeProps } from '../../../../../common/components/form/types';
import {
  Branch,
  Device,
  useBranchesLazyQuery,
} from '../../../../../generated/graphql';

type Props<
  T extends AutocompleteItem,
  Multiple extends boolean | undefined
> = SubmitOnChangeProps & {
  controllerProps?: Partial<AsyncAutocompleteProps<T, Multiple, false>>;
  autocompleteProps?: Partial<
    AutocompleteProps<T, Multiple, undefined, undefined>
  >;
  multiple?: Multiple;
  branchIds?: number[];
};

export type DeviceType = Device & { branch?: Omit<Branch, 'employedDoctors'> };

export const DeviceAutocomplete = <Multiple extends boolean | undefined>(
  props: Props<DeviceType, Multiple>,
) => {
  const [loadBranches, { refetch, called }] = useBranchesLazyQuery({
    context: {
      disableGlobalLoading: true,
    },
  });

  const loadDeviceData = useCallback(
    async (autocompleteInputText?: string) => {
      const query = {
        paging: {
          offset: 0,
          limit: 50,
        },
        filter: {
          id: props.branchIds?.length ? { in: props.branchIds } : undefined,
        },
      };

      const response = called
        ? await refetch(query)
        : await loadBranches({ variables: query });

      // Extract all devices from branches and add branch reference
      const devices = response?.data?.branches?.nodes
        ? response.data.branches.nodes.reduce((acc, branch) => {
            const branchDevices = (branch.devices || []).map((device) => ({
              ...device,
              branch,
            }));
            return [...acc, ...branchDevices];
          }, [] as DeviceType[])
        : [];

      // Filter by name if input text is provided
      return autocompleteInputText
        ? devices.filter((device) =>
            device.name
              .toLowerCase()
              .includes(autocompleteInputText.toLowerCase()),
          )
        : devices;
    },
    [called, loadBranches, props.branchIds, refetch],
  );

  const getOptionLabel = (device: DeviceType) => {
    return device.branch
      ? `${device.name} (${device.branch.name})`
      : device.name;
  };

  return (
    <AsyncAutocompleteField<DeviceType, Multiple>
      getOptionLabel={getOptionLabel}
      loadData={loadDeviceData}
      {...props.autocompleteProps}
      name={props.controllerProps?.name || 'device'}
      label={props.controllerProps?.label || 'Urządzenie'}
      submitOnChange={props.submitOnChange}
    />
  );
};
