import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  AccountCircle,
  BarChart,
  FindInPage,
  Help,
  Receipt,
  SupervisedUserCircle,
  SvgIconComponent,
  ChevronLeft,
  Settings,
  Group,
  Person,
  Mail,
  EventNote,
  Apartment,
  ReportProblem, // Ensure that this icon is imported
} from '@mui/icons-material';
import React, { useCallback } from 'react';
import {
  NavLink as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Tooltip, useMediaQuery, Badge } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useAppContextState } from '../settings/hooks/useSettingsState';
import { useAuth } from '../../../../common/hooks/useAuth';
import { UserRole } from '../../../../generated/graphql';
import { useUnreadMessages } from '../../modules/messages/hooks/useUnreadMessages';

type MenuItems = [string, string, SvgIconComponent, UserRole[], UserRole[]?][];

const MODULE_ITEMS: MenuItems = [
  ['Pacjenci', '/patients', Person, []],
  ['Badania', '/studies', FindInPage, []],
  [
    'Lekarze',
    '/doctors',
    SupervisedUserCircle,
    [UserRole.Admin, UserRole.Technician, UserRole.Manager],
  ],
  [
    'Gabinety',
    '/facilities',
    Apartment,
    [UserRole.Admin, UserRole.Technician, UserRole.Manager],
  ],
  [
    'Skierowania',
    '/referals',
    Receipt,
    [
      UserRole.Admin,
      UserRole.DescribingDoctor,
      UserRole.Doctor,
      UserRole.HelpContact,
      UserRole.Manager,
      UserRole.Patient,
      UserRole.Registration,
      UserRole.Technician,
    ],
    [UserRole.DentalOffice],
  ],
  [
    'Wizyty',
    '/appointments',
    EventNote,
    [
      UserRole.Admin,
      UserRole.Technician,
      UserRole.Manager,
      UserRole.Registration,
    ],
  ],
  [
    'Reklamacje',
    '/complaints',
    ReportProblem,
    [
      UserRole.Admin,
      UserRole.Technician,
      UserRole.Manager,
      UserRole.Registration,
    ],
  ], // New entry for Complaints
  [
    'Konsylium',
    '/councils',
    Group,
    [UserRole.Doctor, UserRole.DentalOffice, UserRole.Laboratory],
  ],
  [
    'Wiadomości',
    '/messages',
    Mail,
    [UserRole.Doctor, UserRole.DentalOffice, UserRole.Laboratory],
  ],
];

const OTHER_ITEMS: MenuItems = [
  [
    'Raporty',
    '/reports',
    BarChart,
    [
      UserRole.Admin,
      UserRole.Technician,
      UserRole.Manager,
      UserRole.Registration,
    ],
  ],
  ['Mój profil', '/profile', AccountCircle, []],
  ['Administracja', '/settings', Settings, [UserRole.Admin, UserRole.Manager]],
  ['Pomoc', '/help', Help, []],
  //TODO: Update license
  //['Licencja', '/license', Copyright, [UserRole.Admin, UserRole.Technician]],
];

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

interface ListItemLinkProps {
  icon: React.ReactElement;
  primary: string;
  to: string;
  onClick?: () => void;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, onClick = () => {} } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<
        HTMLAnchorElement,
        Omit<RouterLinkProps, 'innerRef' | 'to'>
      >((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <RouterLink to={to} {...itemProps} ref={ref} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme: any) => ({
  width: 240,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: any) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(18)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(18)} + 1px)`,
  },
});

const DrawerStyled = styled(Drawer)(
  ({ theme, open }) =>
    ({
      width: 240,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    } as any),
);

export const AppDrawer: React.FC<Props> = ({ open, setOpen }: Props) => {
  const theme = useTheme();
  const [appContext] = useAppContextState();
  const { getAuthInfo } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const { unreadCount } = useUnreadMessages();

  const closeOnMobile = useCallback(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile, setOpen]);

  return (
    <DrawerStyled variant={isMobile ? 'temporary' : 'permanent'} open={open}>
      <DrawerHeader>
        <IconButton onClick={() => setOpen(false)} size="large">
          <ChevronLeft />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {MODULE_ITEMS.map(([text, route, Icon, roles, forbiddenRoles]) => {
          const hasRoles =
            !roles.length ||
            (roles.some((role) => appContext.currentRole === role) &&
              !forbiddenRoles?.some((role) =>
                getAuthInfo().roles.includes(role),
              ));

          const isMessages = route === '/messages';
          const iconElement =
            isMessages && unreadCount ? (
              <Badge color="error" badgeContent={unreadCount}>
                <Icon />
              </Badge>
            ) : (
              <Icon />
            );

          return hasRoles ? (
            <ListItemLink
              primary={text}
              icon={
                <Tooltip title={text} enterDelay={1000} enterNextDelay={1000}>
                  {iconElement}
                </Tooltip>
              }
              key={text}
              to={route}
              onClick={closeOnMobile}
            />
          ) : null;
        })}
      </List>
      <Divider />
      <List>
        {OTHER_ITEMS.map(([text, route, Icon, roles]) => {
          const hasRoles =
            !roles.length ||
            roles.some((role) => appContext.currentRole === role);
          return hasRoles ? (
            <ListItemLink
              primary={text}
              icon={
                <Tooltip title={text} enterDelay={1000} enterNextDelay={1000}>
                  <Icon />
                </Tooltip>
              }
              key={text}
              to={route}
              onClick={closeOnMobile}
            />
          ) : null;
        })}
      </List>
    </DrawerStyled>
  );
};
