import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: number; output: number };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ConnectionCursor: { input: any; output: any };
  DateTime: { input: Date; output: Date };
  JSON: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type ActivationOutput = {
  __typename?: 'ActivationOutput';
  email: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  postalCode: Scalars['String']['output'];
  street: Scalars['String']['output'];
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** Array of edges. */
  edges: Array<AddressEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type AddressDeleteFilter = {
  and?: InputMaybe<Array<AddressDeleteFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressDeleteFilter>>;
  postalCode?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
};

export type AddressDeleteResponse = {
  __typename?: 'AddressDeleteResponse';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Address */
  node: Address;
};

export type AddressFilter = {
  and?: InputMaybe<Array<AddressFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressFilter>>;
  postalCode?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type AddressSort = {
  direction: SortDirection;
  field: AddressSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AddressSortFields {
  City = 'city',
  Country = 'country',
  PostalCode = 'postalCode',
  Street = 'street',
}

export type AddressUpdateFilter = {
  and?: InputMaybe<Array<AddressUpdateFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressUpdateFilter>>;
  postalCode?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
};

export type AppConfig = {
  __typename?: 'AppConfig';
  carrierPrices?: Maybe<Scalars['JSON']['output']>;
  complaintResolutionTimeHours?: Maybe<Scalars['Float']['output']>;
  complaintTypes?: Maybe<Array<Scalars['String']['output']>>;
  deviceTypes?: Maybe<Array<Scalars['String']['output']>>;
  paymentTypes?: Maybe<Array<Scalars['String']['output']>>;
  priceGroups?: Maybe<Array<Scalars['String']['output']>>;
  radiationGroups?: Maybe<Array<Scalars['String']['output']>>;
  regions?: Maybe<Array<Scalars['String']['output']>>;
  specializations?: Maybe<Array<Scalars['String']['output']>>;
};

export type AppConfigInput = {
  carrierPrices?: InputMaybe<Scalars['JSON']['input']>;
  complaintResolutionTimeHours?: InputMaybe<Scalars['Float']['input']>;
  complaintTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  priceGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  radiationGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
  specializations?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Appointment = {
  __typename?: 'Appointment';
  branch: Branch;
  comments?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  device: Device;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  isBreak: Scalars['Boolean']['output'];
  patient?: Maybe<Patient>;
  referal?: Maybe<Referal>;
  startDate: Scalars['DateTime']['output'];
  study?: Maybe<Study>;
  studyType?: Maybe<StudyType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type AppointmentAddInput = {
  branch: IdInput;
  comments?: InputMaybe<Scalars['String']['input']>;
  device: IdInput;
  isBreak?: Scalars['Boolean']['input'];
  patient: IdInput;
  referal?: InputMaybe<IdInput>;
  startDate: Scalars['DateTime']['input'];
  studyTypes: Array<IdInput>;
};

export type AppointmentBreakInput = {
  branch: IdInput;
  comments?: InputMaybe<Scalars['String']['input']>;
  device: IdInput;
  endDate: Scalars['DateTime']['input'];
  isBreak?: Scalars['Boolean']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type AppointmentFiltersInput = {
  deviceId?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  patientId?: InputMaybe<Scalars['Float']['input']>;
  startDateFrom?: InputMaybe<Scalars['String']['input']>;
  startDateTo?: InputMaybe<Scalars['String']['input']>;
  studyTypeId?: InputMaybe<Scalars['Float']['input']>;
};

export type AppointmentUpdateInput = {
  branch: IdInput;
  comments?: InputMaybe<Scalars['String']['input']>;
  device: IdInput;
  id: Scalars['Int']['input'];
  isBreak?: Scalars['Boolean']['input'];
  patient: IdInput;
  referal?: InputMaybe<IdInput>;
  startDate: Scalars['DateTime']['input'];
  study?: InputMaybe<IdInput>;
  studyType: IdInput;
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT Bearer token */
  token: Scalars['String']['output'];
  user: User;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Branch = {
  __typename?: 'Branch';
  address?: Maybe<Address>;
  color?: Maybe<Scalars['String']['output']>;
  devices: Array<Device>;
  emailAddresses?: Maybe<Array<Scalars['String']['output']>>;
  employedDoctors: Array<Doctor>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  ownBranch: Scalars['Boolean']['output'];
  phoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Scalars['String']['output']>;
};

export type BranchDevicesArgs = {
  filter?: DeviceFilter;
  sorting?: Array<DeviceSort>;
};

export type BranchEmployedDoctorsArgs = {
  filter?: DoctorFilter;
  sorting?: Array<DoctorSort>;
};

export type BranchConnection = {
  __typename?: 'BranchConnection';
  /** Array of nodes. */
  nodes: Array<Branch>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type BranchDeleteFilter = {
  and?: InputMaybe<Array<BranchDeleteFilter>>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BranchDeleteFilter>>;
  ownBranch?: InputMaybe<BooleanFieldComparison>;
  region?: InputMaybe<StringFieldComparison>;
};

export type BranchDeleteResponse = {
  __typename?: 'BranchDeleteResponse';
  color?: Maybe<Scalars['String']['output']>;
  emailAddresses?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownBranch?: Maybe<Scalars['Boolean']['output']>;
  phoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Scalars['String']['output']>;
};

export type BranchFilter = {
  and?: InputMaybe<Array<BranchFilter>>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BranchFilter>>;
  ownBranch?: InputMaybe<BooleanFieldComparison>;
  region?: InputMaybe<StringFieldComparison>;
};

export type BranchInput = {
  address?: InputMaybe<AddressInput>;
  color?: InputMaybe<Scalars['String']['input']>;
  devices?: InputMaybe<Array<DeviceInput>>;
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  employedDoctors?: InputMaybe<Array<IdInput>>;
  id?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  ownBranch?: Scalars['Boolean']['input'];
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Scalars['String']['input']>;
  specializations?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BranchSort = {
  direction: SortDirection;
  field: BranchSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BranchSortFields {
  Color = 'color',
  Id = 'id',
  Name = 'name',
  OwnBranch = 'ownBranch',
  Region = 'region',
}

export type BranchUpdateFilter = {
  and?: InputMaybe<Array<BranchUpdateFilter>>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BranchUpdateFilter>>;
  ownBranch?: InputMaybe<BooleanFieldComparison>;
  region?: InputMaybe<StringFieldComparison>;
};

export type Comment = {
  __typename?: 'Comment';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  doctor: Doctor;
  id: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type CommentInput = {
  content: Scalars['String']['input'];
  doctorId: Scalars['Float']['input'];
  title: Scalars['String']['input'];
};

export type Complaint = {
  __typename?: 'Complaint';
  complainantEmailAddress?: Maybe<Scalars['String']['output']>;
  complainantName: Scalars['String']['output'];
  complainantPhoneNumber?: Maybe<Scalars['String']['output']>;
  complaintDescription: Scalars['String']['output'];
  complaintReason: Scalars['String']['output'];
  complaintResolution?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  doctor?: Maybe<Doctor>;
  dueDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  patient?: Maybe<Patient>;
  responsiblePerson?: Maybe<User>;
  status: ComplaintStatusEnum;
  study?: Maybe<Study>;
  type: ComplaintTypeEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type ComplaintFiltersInput = {
  complainantName?: InputMaybe<Scalars['String']['input']>;
  complaintDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  complaintDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  complaintReason?: InputMaybe<Scalars['String']['input']>;
  responsiblePersonId?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<ComplaintStatusEnum>;
};

export type ComplaintInput = {
  complainantEmailAddress: Scalars['String']['input'];
  complainantName: Scalars['String']['input'];
  complainantPhoneNumber: Scalars['String']['input'];
  complaintDescription: Scalars['String']['input'];
  complaintReason: Scalars['String']['input'];
  complaintResolution: Scalars['String']['input'];
  doctor?: InputMaybe<IdInput>;
  patient?: InputMaybe<IdInput>;
  responsiblePerson?: InputMaybe<IdInput>;
  study?: InputMaybe<IdInput>;
  type: ComplaintTypeEnum;
};

/** The status of the complaint */
export enum ComplaintStatusEnum {
  Closed = 'Closed',
  InProgress = 'InProgress',
}

/** The type of the complainant */
export enum ComplaintTypeEnum {
  Doctor = 'Doctor',
  Patient = 'Patient',
}

export type Contact = {
  __typename?: 'Contact';
  addresses?: Maybe<Array<Maybe<Address>>>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  emailAddresses?: Maybe<Array<Scalars['String']['output']>>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  nip?: Maybe<Scalars['String']['output']>;
  phoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
  primaryDescription?: Maybe<Scalars['String']['output']>;
  secondaryDescription?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  nip?: InputMaybe<Scalars['String']['input']>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryDescription?: InputMaybe<Scalars['String']['input']>;
  secondaryDescription?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type Council = {
  __typename?: 'Council';
  councilMembers: Array<CouncilMember>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  invitedMemberEmail?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  studies?: Maybe<Array<Study>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type CouncilInput = {
  name: Scalars['String']['input'];
};

export type CouncilMember = {
  __typename?: 'CouncilMember';
  councilMemberDoctor: Doctor;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  owner: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type CreateAddress = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  postalCode: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type CreateBranch = {
  color?: InputMaybe<Scalars['String']['input']>;
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  ownBranch?: Scalars['Boolean']['input'];
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEmailTemplate = {
  content: Scalars['String']['input'];
  defaultRecipientRoles?: InputMaybe<Array<UserRole>>;
  defaultRecipients?: InputMaybe<Array<Scalars['String']['input']>>;
  embeddedType: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type CreateManyAddressesInput = {
  /** Array of records to create */
  addresses: Array<CreateAddress>;
};

export type CreateManyBranchesInput = {
  /** Array of records to create */
  branches: Array<CreateBranch>;
};

export type CreateManyEmailTemplatesInput = {
  /** Array of records to create */
  emailTemplates: Array<CreateEmailTemplate>;
};

export type CreateManyMailingsInput = {
  /** Array of records to create */
  mailings: Array<MailingInput>;
};

export type CreateManySoftwareInput = {
  /** Array of records to create */
  software: Array<SoftwareInput>;
};

export type CreateManyStudyTypesInput = {
  /** Array of records to create */
  studyTypes: Array<StudyTypeInput>;
};

export type CreateOneAddressInput = {
  /** The record to create */
  address: CreateAddress;
};

export type CreateOneBranchInput = {
  /** The record to create */
  branch: CreateBranch;
};

export type CreateOneEmailTemplateInput = {
  /** The record to create */
  emailTemplate: CreateEmailTemplate;
};

export type CreateOneMailingInput = {
  /** The record to create */
  mailing: MailingInput;
};

export type CreateOneSoftwareInput = {
  /** The record to create */
  software: SoftwareInput;
};

export type CreateOneStudyTypeInput = {
  /** The record to create */
  studyType: StudyTypeInput;
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DailyStudyPaymentMethodRow = {
  __typename?: 'DailyStudyPaymentMethodRow';
  branchName: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentType: PaymentTypeEnum;
  paymentValue: Scalars['Float']['output'];
  performerName: Scalars['String']['output'];
  studyDate: Scalars['DateTime']['output'];
  studyPaymentDate: Scalars['DateTime']['output'];
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DeleteManyAddressesInput = {
  /** Filter to find records to delete */
  filter: AddressDeleteFilter;
};

export type DeleteManyBranchesInput = {
  /** Filter to find records to delete */
  filter: BranchDeleteFilter;
};

export type DeleteManyEmailTemplatesInput = {
  /** Filter to find records to delete */
  filter: EmailTemplateDeleteFilter;
};

export type DeleteManyMailingsInput = {
  /** Filter to find records to delete */
  filter: MailingDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int']['output'];
};

export type DeleteManySoftwareInput = {
  /** Filter to find records to delete */
  filter: SoftwareDeleteFilter;
};

export type DeleteManyStudyTypesInput = {
  /** Filter to find records to delete */
  filter: StudyTypeDeleteFilter;
};

export type DeleteOneAddressInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneBranchInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneEmailTemplateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneMailingInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneSoftwareInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneStudyTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DescriberInfo = {
  __typename?: 'DescriberInfo';
  assignedStudies: Scalars['Float']['output'];
  descriptionOccupancyHours: Scalars['Float']['output'];
  totalAssignedStudies: Scalars['Float']['output'];
};

export type Device = {
  __typename?: 'Device';
  appointments?: Maybe<Array<Appointment>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  deviceType: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  radiationGroups?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type DeviceFilter = {
  and?: InputMaybe<Array<DeviceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deviceType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DeviceFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type DeviceInput = {
  deviceType: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  radiationGroups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DeviceSort = {
  direction: SortDirection;
  field: DeviceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DeviceSortFields {
  CreatedAt = 'createdAt',
  DeviceType = 'deviceType',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type Doctor = {
  __typename?: 'Doctor';
  comments?: Maybe<Array<Comment>>;
  complaints: Array<Complaint>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  describerInfo?: Maybe<DescriberInfo>;
  describingStudyTypes?: Maybe<Array<StudyType>>;
  employedInBranches?: Maybe<Array<Branch>>;
  facility: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  licenseNumber?: Maybe<Scalars['String']['output']>;
  maxStudyHoursToDescribe?: Maybe<Scalars['Float']['output']>;
  specializations?: Maybe<Array<Scalars['String']['output']>>;
  stampImage?: Maybe<File>;
  statistics?: Maybe<DoctorStatisticsOutput>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
  user: User;
};

export type DoctorFilter = {
  and?: InputMaybe<Array<DoctorFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  maxStudyHoursToDescribe?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<DoctorFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type DoctorFiltersInput = {
  /** This field will be enforced for regular doctors (without eleveted access rights) */
  councilMembersOnly?: InputMaybe<Scalars['Boolean']['input']>;
  describingStudyTypeId?: InputMaybe<Scalars['Float']['input']>;
  facility?: InputMaybe<Scalars['Boolean']['input']>;
  hasLicenseNumber?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  showNoUserDoctors?: InputMaybe<Scalars['Boolean']['input']>;
  specializations?: InputMaybe<Array<Scalars['String']['input']>>;
  user?: InputMaybe<UserFiltersInput>;
};

export type DoctorInput = {
  describingStudyTypes?: InputMaybe<Array<IdInput>>;
  employedInBranches?: InputMaybe<Array<IdInput>>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  maxStudyHoursToDescribe?: InputMaybe<Scalars['Float']['input']>;
  specializations?: InputMaybe<Array<Scalars['String']['input']>>;
  stampImage?: InputMaybe<IdInput>;
  user: UserInput;
};

export type DoctorSignupInput = {
  contact: UserContactInput;
  licenseNumber: Scalars['String']['input'];
  login: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type DoctorSignupOutput = {
  __typename?: 'DoctorSignupOutput';
  email: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type DoctorSort = {
  direction: SortDirection;
  field: DoctorSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DoctorSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  MaxStudyHoursToDescribe = 'maxStudyHoursToDescribe',
  UpdatedAt = 'updatedAt',
}

export type DoctorStatisticsOutput = {
  __typename?: 'DoctorStatisticsOutput';
  lastLoginDate?: Maybe<Scalars['DateTime']['output']>;
  loginCount7Days: Scalars['Float']['output'];
  loginCount30Days: Scalars['Float']['output'];
  loginCount90Days: Scalars['Float']['output'];
  studiesCount: Scalars['Float']['output'];
  studiesValue?: Maybe<Scalars['Float']['output']>;
  study2DCount7Days: Scalars['Float']['output'];
  study2DCount30Days: Scalars['Float']['output'];
  study2DCount90Days: Scalars['Float']['output'];
  study3DCount7Days: Scalars['Float']['output'];
  study3DCount30Days: Scalars['Float']['output'];
  study3DCount90Days: Scalars['Float']['output'];
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  content: Scalars['String']['output'];
  defaultRecipientRoles?: Maybe<Array<UserRole>>;
  defaultRecipients?: Maybe<Array<Scalars['String']['output']>>;
  embeddedType: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type EmailTemplateConnection = {
  __typename?: 'EmailTemplateConnection';
  /** Array of edges. */
  edges: Array<EmailTemplateEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type EmailTemplateDeleteFilter = {
  and?: InputMaybe<Array<EmailTemplateDeleteFilter>>;
  embeddedType?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<EmailTemplateDeleteFilter>>;
};

export type EmailTemplateDeleteResponse = {
  __typename?: 'EmailTemplateDeleteResponse';
  content?: Maybe<Scalars['String']['output']>;
  defaultRecipientRoles?: Maybe<Array<UserRole>>;
  defaultRecipients?: Maybe<Array<Scalars['String']['output']>>;
  embeddedType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EmailTemplateEdge = {
  __typename?: 'EmailTemplateEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the EmailTemplate */
  node: EmailTemplate;
};

export type EmailTemplateFilter = {
  and?: InputMaybe<Array<EmailTemplateFilter>>;
  embeddedType?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<EmailTemplateFilter>>;
};

export type EmailTemplateSort = {
  direction: SortDirection;
  field: EmailTemplateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EmailTemplateSortFields {
  EmbeddedType = 'embeddedType',
  Id = 'id',
  Name = 'name',
}

export type EmailTemplateUpdateFilter = {
  and?: InputMaybe<Array<EmailTemplateUpdateFilter>>;
  embeddedType?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<EmailTemplateUpdateFilter>>;
};

export type File = {
  __typename?: 'File';
  confirmed: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  fileType: FileType;
  id: Scalars['Int']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  publicURL?: Maybe<Scalars['String']['output']>;
  rejected: Scalars['Boolean']['output'];
  rejectionReason?: Maybe<Scalars['String']['output']>;
  sizeInBytes?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
  uri: Scalars['String']['output'];
};

export type FileInput = {
  file: Scalars['Upload']['input'];
  fileSizeInBytes: Scalars['Float']['input'];
  fileType: FileType;
  rejected?: InputMaybe<Scalars['Boolean']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
};

export type FileMetaInput = {
  fileSizeInBytes: Scalars['Float']['input'];
  fileType: FileType;
  filename: Scalars['String']['input'];
  mimetype: Scalars['String']['input'];
  rejected?: InputMaybe<Scalars['Boolean']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
};

/** Types of files that can be attached to various entities. */
export enum FileType {
  DoctorStamp = 'DoctorStamp',
  ProfilePicture = 'ProfilePicture',
  StudyDescription = 'StudyDescription',
  StudyDescriptionRequest = 'StudyDescriptionRequest',
  StudyImage = 'StudyImage',
  StudyReferal = 'StudyReferal',
}

export type FloatFieldComparison = {
  between?: InputMaybe<FloatFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<FloatFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

/** Gender */
export enum Gender {
  Female = 'Female',
  Male = 'Male',
}

export type HelpContact = {
  __typename?: 'HelpContact';
  addresses?: Maybe<Array<Maybe<Address>>>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  emailAddresses?: Maybe<Array<Scalars['String']['output']>>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  nip?: Maybe<Scalars['String']['output']>;
  phoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
  primaryDescription?: Maybe<Scalars['String']['output']>;
  profilePictureURL?: Maybe<Scalars['String']['output']>;
  secondaryDescription?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
};

export type IdInput = {
  id: Scalars['Int']['input'];
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mailing = {
  __typename?: 'Mailing';
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  emailChannel: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  important: Scalars['Boolean']['output'];
  internalMessageChannel: Scalars['Boolean']['output'];
  recipients?: Maybe<Array<Scalars['String']['output']>>;
  showFooter: Scalars['Boolean']['output'];
  specializations?: Maybe<Array<Scalars['String']['output']>>;
  status: MailingStatus;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type MailingConnection = {
  __typename?: 'MailingConnection';
  /** Array of edges. */
  edges: Array<MailingEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type MailingDeleteFilter = {
  and?: InputMaybe<Array<MailingDeleteFilter>>;
  content?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  emailChannel?: InputMaybe<BooleanFieldComparison>;
  important?: InputMaybe<BooleanFieldComparison>;
  internalMessageChannel?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<MailingDeleteFilter>>;
  showFooter?: InputMaybe<BooleanFieldComparison>;
  status?: InputMaybe<MailingStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  width?: InputMaybe<NumberFieldComparison>;
};

export type MailingDeleteResponse = {
  __typename?: 'MailingDeleteResponse';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  emailChannel?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  important?: Maybe<Scalars['Boolean']['output']>;
  internalMessageChannel?: Maybe<Scalars['Boolean']['output']>;
  recipients?: Maybe<Array<Scalars['String']['output']>>;
  showFooter?: Maybe<Scalars['Boolean']['output']>;
  specializations?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<MailingStatus>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type MailingEdge = {
  __typename?: 'MailingEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Mailing */
  node: Mailing;
};

export type MailingFilter = {
  and?: InputMaybe<Array<MailingFilter>>;
  content?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  emailChannel?: InputMaybe<BooleanFieldComparison>;
  important?: InputMaybe<BooleanFieldComparison>;
  internalMessageChannel?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<MailingFilter>>;
  showFooter?: InputMaybe<BooleanFieldComparison>;
  status?: InputMaybe<MailingStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  width?: InputMaybe<NumberFieldComparison>;
};

export type MailingInput = {
  content: Scalars['String']['input'];
  emailChannel: Scalars['Boolean']['input'];
  important?: Scalars['Boolean']['input'];
  internalMessageChannel: Scalars['Boolean']['input'];
  recipients?: InputMaybe<Array<Scalars['String']['input']>>;
  selectedDoctors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showFooter: Scalars['Boolean']['input'];
  specializations?: InputMaybe<Array<Scalars['String']['input']>>;
  subject?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MailingSort = {
  direction: SortDirection;
  field: MailingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MailingSortFields {
  Content = 'content',
  CreatedAt = 'createdAt',
  EmailChannel = 'emailChannel',
  Important = 'important',
  InternalMessageChannel = 'internalMessageChannel',
  ShowFooter = 'showFooter',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Width = 'width',
}

/** Current state of mailing */
export enum MailingStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  Sending = 'Sending',
  Suspended = 'Suspended',
}

export type MailingStatusFilterComparison = {
  eq?: InputMaybe<MailingStatus>;
  gt?: InputMaybe<MailingStatus>;
  gte?: InputMaybe<MailingStatus>;
  iLike?: InputMaybe<MailingStatus>;
  in?: InputMaybe<Array<MailingStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<MailingStatus>;
  lt?: InputMaybe<MailingStatus>;
  lte?: InputMaybe<MailingStatus>;
  neq?: InputMaybe<MailingStatus>;
  notILike?: InputMaybe<MailingStatus>;
  notIn?: InputMaybe<Array<MailingStatus>>;
  notLike?: InputMaybe<MailingStatus>;
};

export type MailingUpdateFilter = {
  and?: InputMaybe<Array<MailingUpdateFilter>>;
  content?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  emailChannel?: InputMaybe<BooleanFieldComparison>;
  important?: InputMaybe<BooleanFieldComparison>;
  internalMessageChannel?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<MailingUpdateFilter>>;
  showFooter?: InputMaybe<BooleanFieldComparison>;
  status?: InputMaybe<MailingStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  width?: InputMaybe<NumberFieldComparison>;
};

/** Different types of media used for delivery of study images */
export enum MediaType {
  Disc = 'Disc',
  Film = 'Film',
  Online = 'Online',
  Pendrive = 'Pendrive',
}

export type Message = {
  __typename?: 'Message';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  important: Scalars['Boolean']['output'];
  linkedEntityId?: Maybe<Scalars['Float']['output']>;
  messageType: MessageType;
  read: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

/** Message type */
export enum MessageType {
  CouncilInviteUpdate = 'CouncilInviteUpdate',
  Mailing = 'Mailing',
  SharedStudy = 'SharedStudy',
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptInviteToCouncil: Council;
  acceptTerms: Scalars['Boolean']['output'];
  activate: ActivationOutput;
  addAppointments: Array<Appointment>;
  addBreak: Appointment;
  addDoctor: Doctor;
  addDoctorToCouncil: Council;
  addPatient: Patient;
  addReferal: Referal;
  addReservation: Reservation;
  addStudy: Study;
  addStudyToCouncil: Council;
  changeLogin: User;
  changePassword: User;
  completeReferal: Scalars['Boolean']['output'];
  confirmEmail: Scalars['Boolean']['output'];
  confirmUpload: Scalars['Boolean']['output'];
  createAdminMessage: Message;
  createComment: Comment;
  createComplaint: Complaint;
  createCouncil: Council;
  createManyAddresses: Array<Address>;
  createManyBranches: Array<Branch>;
  createManyEmailTemplates: Array<EmailTemplate>;
  createManyMailings: Array<Mailing>;
  createManySoftware: Array<Software>;
  createManyStudyTypes: Array<StudyType>;
  createOneAddress: Address;
  createOneBranch: Branch;
  createOneEmailTemplate: EmailTemplate;
  createOneMailing: Mailing;
  createOneSoftware: Software;
  createOneStudyType: StudyType;
  deleteAppointment: Scalars['Int']['output'];
  deleteComments: Scalars['Boolean']['output'];
  deleteDoctor: Scalars['Int']['output'];
  deleteExistingProfilePicture: Scalars['Boolean']['output'];
  deleteExistingStampPicture: Scalars['Boolean']['output'];
  deleteFile: Scalars['Boolean']['output'];
  deleteManyAddresses: DeleteManyResponse;
  deleteManyBranches: DeleteManyResponse;
  deleteManyEmailTemplates: DeleteManyResponse;
  deleteManyMailings: DeleteManyResponse;
  deleteManySoftware: DeleteManyResponse;
  deleteManyStudyTypes: DeleteManyResponse;
  deleteMessage: Scalars['Boolean']['output'];
  deleteOneAddress: AddressDeleteResponse;
  deleteOneBranch: BranchDeleteResponse;
  deleteOneEmailTemplate: EmailTemplateDeleteResponse;
  deleteOneMailing: MailingDeleteResponse;
  deleteOneSoftware: SoftwareDeleteResponse;
  deleteOneStudyType: StudyTypeDeleteResponse;
  deletePatient: Scalars['Boolean']['output'];
  deleteReferal: Scalars['Boolean']['output'];
  deleteReservation: Scalars['String']['output'];
  deleteStudy: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  diagdentImport: Scalars['Boolean']['output'];
  diagdentImportOldFiles: Scalars['Boolean']['output'];
  fileDescriptionRequest: Study;
  initPasswordReset: Scalars['Boolean']['output'];
  inviteDoctorsToCouncil: Council;
  login: Auth;
  markAsRead: Scalars['Int']['output'];
  markForDelete: Scalars['Boolean']['output'];
  oAuthConnectAccount: Scalars['Boolean']['output'];
  oauthLogin: Auth;
  oauthSignupDoctor: DoctorSignupOutput;
  patientStudies: PatientStudies;
  prepareUpload: UploadOutput;
  rejectInviteToCouncil: Council;
  removeDoctorFromCouncil: Council;
  resendConfirmationEmail: Scalars['Boolean']['output'];
  resendStudiesLink: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  saveAppConfig: AppConfig;
  saveBranch: Branch;
  sendMailing: Scalars['Boolean']['output'];
  sendPatientStudiesLink: Scalars['Boolean']['output'];
  sendStudiesLink: Scalars['Boolean']['output'];
  shareStudy: Study;
  signup: Auth;
  signupDoctor: DoctorSignupOutput;
  signupOfficeOrLaboratory: Auth;
  startMailing: Scalars['Boolean']['output'];
  stopMailing: Scalars['Boolean']['output'];
  updateAppointment: Appointment;
  updateComment: Comment;
  updateComplaint: Complaint;
  updateCurrentDoctor: Doctor;
  updateDoctor: Doctor;
  updateManyAddresses: UpdateManyResponse;
  updateManyBranches: UpdateManyResponse;
  updateManyEmailTemplates: UpdateManyResponse;
  updateManyMailings: UpdateManyResponse;
  updateManySoftware: UpdateManyResponse;
  updateManyStudyTypes: UpdateManyResponse;
  updateOneAddress: Address;
  updateOneBranch: Branch;
  updateOneEmailTemplate: EmailTemplate;
  updateOneMailing: Mailing;
  updateOneSoftware: Software;
  updateOneStudyType: StudyType;
  updatePatient: Patient;
  updatePreferences: Preferences;
  updateProfilePicture: File;
  updateReferal: Referal;
  updateReservation: Reservation;
  updateStampPicture: File;
  updateStudy: Study;
  updateStudyDescriptionDate: StudyDescriber;
  updateUser: User;
  uploadFile: File;
};

export type MutationAcceptInviteToCouncilArgs = {
  councilId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationActivateArgs = {
  email: Scalars['String']['input'];
};

export type MutationAddAppointmentsArgs = {
  data: AppointmentAddInput;
};

export type MutationAddBreakArgs = {
  data: AppointmentBreakInput;
};

export type MutationAddDoctorArgs = {
  data: NoUserDoctorInput;
};

export type MutationAddDoctorToCouncilArgs = {
  councilId?: InputMaybe<Scalars['Int']['input']>;
  doctorId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddPatientArgs = {
  data: PatientInput;
};

export type MutationAddReferalArgs = {
  data: ReferalInput;
};

export type MutationAddReservationArgs = {
  data: ReservationInput;
};

export type MutationAddStudyArgs = {
  data: StudyInput;
};

export type MutationAddStudyToCouncilArgs = {
  councilId?: InputMaybe<Scalars['Int']['input']>;
  studyId: Scalars['Int']['input'];
};

export type MutationChangeLoginArgs = {
  newLogin: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
};

export type MutationCompleteReferalArgs = {
  id: Scalars['Int']['input'];
};

export type MutationConfirmEmailArgs = {
  token: Scalars['String']['input'];
};

export type MutationConfirmUploadArgs = {
  fileId: Scalars['Int']['input'];
};

export type MutationCreateAdminMessageArgs = {
  content: Scalars['String']['input'];
};

export type MutationCreateCommentArgs = {
  data: CommentInput;
};

export type MutationCreateComplaintArgs = {
  createComplaintInput: ComplaintInput;
};

export type MutationCreateCouncilArgs = {
  data?: InputMaybe<CouncilInput>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateManyAddressesArgs = {
  input: CreateManyAddressesInput;
};

export type MutationCreateManyBranchesArgs = {
  input: CreateManyBranchesInput;
};

export type MutationCreateManyEmailTemplatesArgs = {
  input: CreateManyEmailTemplatesInput;
};

export type MutationCreateManyMailingsArgs = {
  input: CreateManyMailingsInput;
};

export type MutationCreateManySoftwareArgs = {
  input: CreateManySoftwareInput;
};

export type MutationCreateManyStudyTypesArgs = {
  input: CreateManyStudyTypesInput;
};

export type MutationCreateOneAddressArgs = {
  input: CreateOneAddressInput;
};

export type MutationCreateOneBranchArgs = {
  input: CreateOneBranchInput;
};

export type MutationCreateOneEmailTemplateArgs = {
  input: CreateOneEmailTemplateInput;
};

export type MutationCreateOneMailingArgs = {
  input: CreateOneMailingInput;
};

export type MutationCreateOneSoftwareArgs = {
  input: CreateOneSoftwareInput;
};

export type MutationCreateOneStudyTypeArgs = {
  input: CreateOneStudyTypeInput;
};

export type MutationDeleteAppointmentArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteCommentsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MutationDeleteDoctorArgs = {
  assignStudiesTo?: InputMaybe<Scalars['Int']['input']>;
  id: Array<Scalars['Int']['input']>;
};

export type MutationDeleteExistingStampPictureArgs = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteFileArgs = {
  id: Array<Scalars['Int']['input']>;
};

export type MutationDeleteManyAddressesArgs = {
  input: DeleteManyAddressesInput;
};

export type MutationDeleteManyBranchesArgs = {
  input: DeleteManyBranchesInput;
};

export type MutationDeleteManyEmailTemplatesArgs = {
  input: DeleteManyEmailTemplatesInput;
};

export type MutationDeleteManyMailingsArgs = {
  input: DeleteManyMailingsInput;
};

export type MutationDeleteManySoftwareArgs = {
  input: DeleteManySoftwareInput;
};

export type MutationDeleteManyStudyTypesArgs = {
  input: DeleteManyStudyTypesInput;
};

export type MutationDeleteMessageArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeImportant?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationDeleteOneAddressArgs = {
  input: DeleteOneAddressInput;
};

export type MutationDeleteOneBranchArgs = {
  input: DeleteOneBranchInput;
};

export type MutationDeleteOneEmailTemplateArgs = {
  input: DeleteOneEmailTemplateInput;
};

export type MutationDeleteOneMailingArgs = {
  input: DeleteOneMailingInput;
};

export type MutationDeleteOneSoftwareArgs = {
  input: DeleteOneSoftwareInput;
};

export type MutationDeleteOneStudyTypeArgs = {
  input: DeleteOneStudyTypeInput;
};

export type MutationDeletePatientArgs = {
  id: Array<Scalars['Int']['input']>;
};

export type MutationDeleteReferalArgs = {
  id: Array<Scalars['Int']['input']>;
};

export type MutationDeleteReservationArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteStudyArgs = {
  id: Array<Scalars['Int']['input']>;
};

export type MutationDeleteUserArgs = {
  id: Array<Scalars['Int']['input']>;
};

export type MutationDiagdentImportArgs = {
  key: Scalars['String']['input'];
};

export type MutationDiagdentImportOldFilesArgs = {
  key: Scalars['String']['input'];
};

export type MutationFileDescriptionRequestArgs = {
  descriptionRequestComment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

export type MutationInitPasswordResetArgs = {
  email: Scalars['String']['input'];
};

export type MutationInviteDoctorsToCouncilArgs = {
  councilId?: InputMaybe<Scalars['Int']['input']>;
  doctorEmail: Array<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationLoginArgs = {
  data: LoginInput;
};

export type MutationMarkAsReadArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeImportant?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationOauthSignupDoctorArgs = {
  signup: DoctorSignupInput;
};

export type MutationPatientStudiesArgs = {
  socialSecurityNumber: Scalars['String']['input'];
};

export type MutationPrepareUploadArgs = {
  file: FileMetaInput;
};

export type MutationRejectInviteToCouncilArgs = {
  councilId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationRemoveDoctorFromCouncilArgs = {
  councilId?: InputMaybe<Scalars['Int']['input']>;
  doctorEmail?: InputMaybe<Scalars['String']['input']>;
  doctorId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationResendConfirmationEmailArgs = {
  login: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationSaveAppConfigArgs = {
  data: AppConfigInput;
};

export type MutationSaveBranchArgs = {
  data: BranchInput;
};

export type MutationSendMailingArgs = {
  key: Scalars['String']['input'];
};

export type MutationSendPatientStudiesLinkArgs = {
  id: Scalars['Int']['input'];
};

export type MutationSendStudiesLinkArgs = {
  email: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
};

export type MutationShareStudyArgs = {
  doctorIds: Array<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type MutationSignupArgs = {
  data: UserInput;
  roles: UserRolesInput;
};

export type MutationSignupDoctorArgs = {
  signup: DoctorSignupInput;
};

export type MutationSignupOfficeOrLaboratoryArgs = {
  roles: UserRolesInput;
  signup: UserInput;
};

export type MutationStartMailingArgs = {
  mailingId: Scalars['ID']['input'];
};

export type MutationStopMailingArgs = {
  mailingId: Scalars['ID']['input'];
};

export type MutationUpdateAppointmentArgs = {
  data: AppointmentUpdateInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateCommentArgs = {
  data: CommentInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateComplaintArgs = {
  id: Scalars['Int']['input'];
  updateComplaintInput: UpdateComplaintInput;
};

export type MutationUpdateCurrentDoctorArgs = {
  data: DoctorInput;
};

export type MutationUpdateDoctorArgs = {
  data: DoctorInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateManyAddressesArgs = {
  input: UpdateManyAddressesInput;
};

export type MutationUpdateManyBranchesArgs = {
  input: UpdateManyBranchesInput;
};

export type MutationUpdateManyEmailTemplatesArgs = {
  input: UpdateManyEmailTemplatesInput;
};

export type MutationUpdateManyMailingsArgs = {
  input: UpdateManyMailingsInput;
};

export type MutationUpdateManySoftwareArgs = {
  input: UpdateManySoftwareInput;
};

export type MutationUpdateManyStudyTypesArgs = {
  input: UpdateManyStudyTypesInput;
};

export type MutationUpdateOneAddressArgs = {
  input: UpdateOneAddressInput;
};

export type MutationUpdateOneBranchArgs = {
  input: UpdateOneBranchInput;
};

export type MutationUpdateOneEmailTemplateArgs = {
  input: UpdateOneEmailTemplateInput;
};

export type MutationUpdateOneMailingArgs = {
  input: UpdateOneMailingInput;
};

export type MutationUpdateOneSoftwareArgs = {
  input: UpdateOneSoftwareInput;
};

export type MutationUpdateOneStudyTypeArgs = {
  input: UpdateOneStudyTypeInput;
};

export type MutationUpdatePatientArgs = {
  data: PatientInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdatePreferencesArgs = {
  newPreferences: PreferencesInput;
};

export type MutationUpdateProfilePictureArgs = {
  pictureData: Scalars['String']['input'];
};

export type MutationUpdateReferalArgs = {
  data: ReferalInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateReservationArgs = {
  data: ReservationInput;
  id: Scalars['String']['input'];
};

export type MutationUpdateStampPictureArgs = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  pictureData: Scalars['String']['input'];
};

export type MutationUpdateStudyArgs = {
  data: StudyInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateStudyDescriptionDateArgs = {
  doctorId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  newDate: Scalars['DateTime']['input'];
};

export type MutationUpdateUserArgs = {
  data: UserInput;
  id: Scalars['Int']['input'];
};

export type MutationUploadFileArgs = {
  data: FileInput;
};

export type NoUserDoctorInput = {
  facility?: InputMaybe<Scalars['Boolean']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  noUserContact: ContactInput;
  specializations?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Sorting order */
export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderColumn = {
  columnName: Scalars['String']['input'];
  columnOrder?: Order;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
};

export type PaginatedComplaintResponse = {
  __typename?: 'PaginatedComplaintResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Complaint>;
  total: Scalars['Int']['output'];
};

export type PaginatedDoctorResponse = {
  __typename?: 'PaginatedDoctorResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Doctor>;
  total: Scalars['Int']['output'];
};

export type PaginatedPatientResponse = {
  __typename?: 'PaginatedPatientResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Patient>;
  total: Scalars['Int']['output'];
};

export type PaginatedReferalResponse = {
  __typename?: 'PaginatedReferalResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Referal>;
  total: Scalars['Int']['output'];
};

export type PaginatedStudyResponse = {
  __typename?: 'PaginatedStudyResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Study>;
  total: Scalars['Int']['output'];
};

export type PaginatedUserResponse = {
  __typename?: 'PaginatedUserResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<User>;
  total: Scalars['Int']['output'];
};

export type PaginationInput = {
  skip: Scalars['Float']['input'];
  take: Scalars['Float']['input'];
};

export type Patient = {
  __typename?: 'Patient';
  appointments: Array<Appointment>;
  complaints: Array<Complaint>;
  contact: Contact;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  referals: Array<Referal>;
  registrationDate: Scalars['DateTime']['output'];
  studies: Array<Study>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type PatientFiltersInput = {
  birthDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  birthDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  patientsAssignedToUserId?: InputMaybe<Scalars['Float']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type PatientInput = {
  contact: ContactInput;
  id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  registrationDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PatientStudies = {
  __typename?: 'PatientStudies';
  patientBirthDate?: Maybe<Scalars['DateTime']['output']>;
  patientName: Scalars['String']['output'];
  studies?: Maybe<Array<PatientStudy>>;
};

export type PatientStudy = {
  __typename?: 'PatientStudy';
  branchName: Scalars['String']['output'];
  descriptionFileURLs?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Float']['output'];
  is3D: Scalars['Boolean']['output'];
  studyDate: Scalars['DateTime']['output'];
  studyFileURLs?: Maybe<Array<Scalars['String']['output']>>;
  studyImageFileURL?: Maybe<Scalars['String']['output']>;
  studyType: Scalars['String']['output'];
};

/** Types of payments */
export enum PaymentTypeEnum {
  DataCarrier = 'DataCarrier',
  Description = 'Description',
  Study = 'Study',
}

export type Preferences = {
  __typename?: 'Preferences';
  mailNotifications: Scalars['Boolean']['output'];
};

export type PreferencesInput = {
  mailNotifications?: Scalars['Boolean']['input'];
};

export type Query = {
  __typename?: 'Query';
  address: Address;
  addresses: AddressConnection;
  allMessages: Array<Message>;
  appConfig: AppConfig;
  appointment: Appointment;
  appointments: Array<Appointment>;
  branch: Branch;
  branchStudyTypes: Array<StudyType>;
  branches: BranchConnection;
  complaint?: Maybe<Complaint>;
  complaints: PaginatedComplaintResponse;
  currentDoctor?: Maybe<Doctor>;
  currentUser: User;
  doctor: Doctor;
  doctorComments: Array<Comment>;
  doctors: PaginatedDoctorResponse;
  emailTemplate: EmailTemplate;
  emailTemplates: EmailTemplateConnection;
  /** Get all councils for a user */
  getCouncils: Array<Council>;
  getDownloadURL: Scalars['String']['output'];
  /** Get council invites for the current user */
  getMyCouncilInvites: Array<Council>;
  /** Get council participations for the current user */
  getMyCouncilParticipations: Array<Council>;
  /** Get councils created by the current user */
  getMyCouncils: Array<Council>;
  helpUserContacts: Array<HelpContact>;
  mailing: Mailing;
  mailings: MailingConnection;
  message: Message;
  myComments: Array<Comment>;
  patient: Patient;
  patients: PaginatedPatientResponse;
  referal: Referal;
  referals: PaginatedReferalResponse;
  reservations: Array<Reservation>;
  software: SoftwareConnection;
  studies: PaginatedStudyResponse;
  studiesDailyFinancial: Array<DailyStudyPaymentMethodRow>;
  studiesFinancial: Array<StudyFinancialRow>;
  studiesGroupedByBranch: Array<StudyByBranchRow>;
  studiesGroupedByGender: Array<StudyByGenderRow>;
  studiesGroupedByPaymentMethod: Array<StudyByPaymentMethodRow>;
  studiesGroupedByStudyType: Array<StudyByStudyTypeRow>;
  study: Study;
  studyType: StudyType;
  studyTypes: StudyTypeConnection;
  users: PaginatedUserResponse;
};

export type QueryAddressArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAddressesArgs = {
  filter?: AddressFilter;
  paging?: CursorPaging;
  sorting?: Array<AddressSort>;
};

export type QueryAppointmentArgs = {
  id: Scalars['Int']['input'];
};

export type QueryAppointmentsArgs = {
  orderBy?: InputMaybe<Array<OrderColumn>>;
  pagination: PaginationInput;
  where?: InputMaybe<AppointmentFiltersInput>;
};

export type QueryBranchArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBranchStudyTypesArgs = {
  branchId: Scalars['Int']['input'];
  deviceId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBranchesArgs = {
  filter?: BranchFilter;
  paging?: OffsetPaging;
  sorting?: Array<BranchSort>;
};

export type QueryComplaintArgs = {
  id: Scalars['Int']['input'];
};

export type QueryComplaintsArgs = {
  orderBy?: InputMaybe<Array<OrderColumn>>;
  pagination: PaginationInput;
  where?: InputMaybe<ComplaintFiltersInput>;
};

export type QueryDoctorArgs = {
  id: Scalars['Int']['input'];
};

export type QueryDoctorCommentsArgs = {
  doctorId: Scalars['Int']['input'];
};

export type QueryDoctorsArgs = {
  orderBy?: InputMaybe<Array<OrderColumn>>;
  pagination: PaginationInput;
  where?: InputMaybe<DoctorFiltersInput>;
};

export type QueryEmailTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEmailTemplatesArgs = {
  filter?: EmailTemplateFilter;
  paging?: CursorPaging;
  sorting?: Array<EmailTemplateSort>;
};

export type QueryGetCouncilsArgs = {
  userId: Scalars['Float']['input'];
};

export type QueryGetDownloadUrlArgs = {
  filePath: Scalars['String']['input'];
  studyId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryHelpUserContactsArgs = {
  count: Scalars['Int']['input'];
};

export type QueryMailingArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMailingsArgs = {
  filter?: MailingFilter;
  paging?: CursorPaging;
  sorting?: Array<MailingSort>;
};

export type QueryMessageArgs = {
  id: Scalars['Int']['input'];
};

export type QueryPatientArgs = {
  id: Scalars['Int']['input'];
};

export type QueryPatientsArgs = {
  orderBy?: InputMaybe<Array<OrderColumn>>;
  pagination: PaginationInput;
  where?: InputMaybe<PatientFiltersInput>;
};

export type QueryReferalArgs = {
  id: Scalars['Int']['input'];
};

export type QueryReferalsArgs = {
  orderBy?: InputMaybe<Array<OrderColumn>>;
  pagination: PaginationInput;
  where?: InputMaybe<ReferalFiltersInput>;
};

export type QueryReservationsArgs = {
  deviceId: Scalars['Int']['input'];
};

export type QuerySoftwareArgs = {
  filter?: SoftwareFilter;
  paging?: CursorPaging;
  sorting?: Array<SoftwareSort>;
};

export type QueryStudiesArgs = {
  orderBy?: InputMaybe<Array<OrderColumn>>;
  pagination: PaginationInput;
  where?: InputMaybe<StudyFiltersInput>;
};

export type QueryStudiesDailyFinancialArgs = {
  filter: StudyReportFiltersInput;
};

export type QueryStudiesFinancialArgs = {
  filter: StudyReportFiltersInput;
};

export type QueryStudiesGroupedByBranchArgs = {
  filter: StudyReportFiltersInput;
};

export type QueryStudiesGroupedByGenderArgs = {
  filter: StudyReportFiltersInput;
};

export type QueryStudiesGroupedByPaymentMethodArgs = {
  filter: StudyReportFiltersInput;
};

export type QueryStudiesGroupedByStudyTypeArgs = {
  filter: StudyReportFiltersInput;
};

export type QueryStudyArgs = {
  id: Scalars['Int']['input'];
};

export type QueryStudyTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStudyTypesArgs = {
  filter?: StudyTypeFilter;
  paging?: CursorPaging;
  sorting?: Array<StudyTypeSort>;
};

export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<OrderColumn>>;
  pagination: PaginationInput;
  where?: InputMaybe<UserFiltersInput>;
};

export type Radiology = {
  __typename?: 'Radiology';
  anodeCurrentMiliamper: Scalars['Float']['output'];
  exposureTimeSeconds: Scalars['Float']['output'];
  presetName?: Maybe<Scalars['String']['output']>;
  radiationDoseMilligrayPerSquareCentimeter: Scalars['Float']['output'];
  voltageKilovolts: Scalars['Float']['output'];
};

export type RadiologyInput = {
  anodeCurrentMiliamper: Scalars['Float']['input'];
  exposureTimeSeconds: Scalars['Float']['input'];
  presetName?: InputMaybe<Scalars['String']['input']>;
  radiationDoseMilligrayPerSquareCentimeter: Scalars['Float']['input'];
  voltageKilovolts: Scalars['Float']['input'];
};

export type Referal = {
  __typename?: 'Referal';
  appointments: Array<Appointment>;
  completed: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  issuer: Doctor;
  mediaTypes: Array<MediaType>;
  patient: Patient;
  referalToStudyTypes: Array<ReferalToStudyType>;
  remarks?: Maybe<Scalars['String']['output']>;
  requestDescription: Scalars['Boolean']['output'];
  studies?: Maybe<Array<Study>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type ReferalFiltersInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  issuerId?: InputMaybe<Scalars['Float']['input']>;
  patient?: InputMaybe<PatientFiltersInput>;
  referalDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  referalDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  studyTypes?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ReferalInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  issuer: IdInput;
  mediaTypes: Array<MediaType>;
  patient?: InputMaybe<PatientInput>;
  referalToStudyTypes: Array<ReferalToStudyTypeInput>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  requestDescription?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReferalToStudyType = {
  __typename?: 'ReferalToStudyType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  referal: Referal;
  remarks?: Maybe<Scalars['String']['output']>;
  studySubTypes?: Maybe<Array<Scalars['String']['output']>>;
  studyType: StudyType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type ReferalToStudyTypeInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  studySubTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  studyType: IdInput;
};

export type Reservation = {
  __typename?: 'Reservation';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdById?: Maybe<Scalars['Int']['output']>;
  deviceId: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
};

export type ReservationInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  deviceId: Scalars['Int']['input'];
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type Software = {
  __typename?: 'Software';
  downloadURL: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SoftwareConnection = {
  __typename?: 'SoftwareConnection';
  /** Array of edges. */
  edges: Array<SoftwareEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SoftwareDeleteFilter = {
  and?: InputMaybe<Array<SoftwareDeleteFilter>>;
  downloadURL?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SoftwareDeleteFilter>>;
};

export type SoftwareDeleteResponse = {
  __typename?: 'SoftwareDeleteResponse';
  downloadURL?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SoftwareEdge = {
  __typename?: 'SoftwareEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Software */
  node: Software;
};

export type SoftwareFilter = {
  and?: InputMaybe<Array<SoftwareFilter>>;
  downloadURL?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SoftwareFilter>>;
};

export type SoftwareInput = {
  downloadURL: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SoftwareSort = {
  direction: SortDirection;
  field: SoftwareSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SoftwareSortFields {
  DownloadUrl = 'downloadURL',
  Id = 'id',
  Name = 'name',
}

export type SoftwareUpdateFilter = {
  and?: InputMaybe<Array<SoftwareUpdateFilter>>;
  downloadURL?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SoftwareUpdateFilter>>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST',
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type Study = {
  __typename?: 'Study';
  appointments?: Maybe<Array<Appointment>>;
  branch?: Maybe<Branch>;
  /** Is the description a comparison? */
  comparisonDescription?: Maybe<Scalars['Boolean']['output']>;
  complaints: Array<Complaint>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['Float']['output']>;
  describers?: Maybe<Array<StudyDescriber>>;
  descriptionRequestComment?: Maybe<Scalars['String']['output']>;
  externalReferal?: Maybe<Scalars['Boolean']['output']>;
  files?: Maybe<Array<File>>;
  hasDescription?: Maybe<Scalars['Boolean']['output']>;
  /** This study will be hidden and not accessible for doctors regardless if they are set as leading or supporting */
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  importantInformation?: Maybe<Scalars['String']['output']>;
  incognitoDescription?: Maybe<Scalars['Boolean']['output']>;
  internalInformation?: Maybe<Scalars['String']['output']>;
  leadingDoctor?: Maybe<Doctor>;
  leadingDoctorBranch?: Maybe<Branch>;
  missingDescription?: Maybe<Scalars['Boolean']['output']>;
  notifyDescribers?: Maybe<Scalars['Boolean']['output']>;
  notifyDoctorsAboutDescription?: Maybe<Scalars['Boolean']['output']>;
  notifyLeaderAndSupportingDoctors?: Maybe<Scalars['Boolean']['output']>;
  patient: Patient;
  payments?: Maybe<Array<StudyPayment>>;
  performer?: Maybe<User>;
  radiology: Radiology;
  referal?: Maybe<Referal>;
  status: StudyStatus;
  studyDate: Scalars['DateTime']['output'];
  studyType: StudyType;
  supportingDoctors: Array<Doctor>;
  unassignedDescriber?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['Float']['output']>;
  /** Is the description urgent? */
  urgentDescription?: Maybe<Scalars['Boolean']['output']>;
};

export type StudyByBranchRow = {
  __typename?: 'StudyByBranchRow';
  branchName?: Maybe<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  studyCount: Scalars['Int']['output'];
  sum: Scalars['Float']['output'];
};

export type StudyByGenderRow = {
  __typename?: 'StudyByGenderRow';
  count: Scalars['Int']['output'];
  gender?: Maybe<Gender>;
};

export type StudyByPaymentMethodRow = {
  __typename?: 'StudyByPaymentMethodRow';
  count: Scalars['Int']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  sum: Scalars['Float']['output'];
};

export type StudyByStudyTypeRow = {
  __typename?: 'StudyByStudyTypeRow';
  count: Scalars['Int']['output'];
  studyType?: Maybe<Scalars['String']['output']>;
};

export type StudyDescriber = {
  __typename?: 'StudyDescriber';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  doctor: Doctor;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  stat: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

/** Types of filters for study descriptions. */
export enum StudyDescriptionFilterType {
  NoDescription = 'NoDescription',
  UnassignedDescriber = 'UnassignedDescriber',
  WithDescription = 'WithDescription',
}

export type StudyFiltersInput = {
  anyDoctorId?: InputMaybe<Scalars['Float']['input']>;
  branchId?: InputMaybe<Scalars['Float']['input']>;
  describerId?: InputMaybe<Scalars['Float']['input']>;
  externalReferal?: InputMaybe<Scalars['Boolean']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  leadingDoctorId?: InputMaybe<Scalars['Float']['input']>;
  patient?: InputMaybe<PatientFiltersInput>;
  patientSsn?: InputMaybe<Scalars['String']['input']>;
  performerId?: InputMaybe<Scalars['Float']['input']>;
  referalId?: InputMaybe<Scalars['Float']['input']>;
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<StudyStatus>;
  studyDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  studyDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  studyDescriptionFilter?: InputMaybe<StudyDescriptionFilterType>;
  studyTypeId?: InputMaybe<Scalars['Float']['input']>;
};

export type StudyFinancialRow = {
  __typename?: 'StudyFinancialRow';
  count: Scalars['Int']['output'];
  externalReferal?: Maybe<Scalars['Boolean']['output']>;
  is3D?: Maybe<Scalars['Boolean']['output']>;
  studyDate: Scalars['DateTime']['output'];
  sum?: Maybe<Scalars['Float']['output']>;
};

export type StudyInput = {
  appointment?: InputMaybe<IdInput>;
  branch: IdInput;
  comparisonDescription?: InputMaybe<Scalars['Boolean']['input']>;
  describers?: InputMaybe<Array<IdInput>>;
  descriptionRequestComment?: InputMaybe<Scalars['String']['input']>;
  externalReferal?: InputMaybe<Scalars['Boolean']['input']>;
  files?: InputMaybe<Array<IdInput>>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  importantInformation?: InputMaybe<Scalars['String']['input']>;
  incognitoDescription?: InputMaybe<Scalars['Boolean']['input']>;
  internalInformation?: InputMaybe<Scalars['String']['input']>;
  leadingDoctor?: InputMaybe<IdInput>;
  leadingDoctorBranch?: InputMaybe<IdInput>;
  notifyDescribers?: InputMaybe<Scalars['Boolean']['input']>;
  notifyDoctorsAboutDescription?: InputMaybe<Scalars['Boolean']['input']>;
  notifyLeaderAndSupportingDoctors?: InputMaybe<Scalars['Boolean']['input']>;
  patient: IdInput;
  payments?: InputMaybe<Array<StudyPaymentInput>>;
  performer?: InputMaybe<IdInput>;
  radiology?: InputMaybe<RadiologyInput>;
  referal?: InputMaybe<IdInput>;
  status: StudyStatus;
  studyDate: Scalars['DateTime']['input'];
  studyType: IdInput;
  supportingDoctors?: InputMaybe<Array<IdInput>>;
  urgentDescription?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudyPayment = {
  __typename?: 'StudyPayment';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  /** Special price group (i.e. family, doctors, Public health fund) */
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  method: Scalars['String']['output'];
  type: PaymentTypeEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
  value: Scalars['Float']['output'];
};

export type StudyPaymentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** Special price group (i.e. family, doctors, Public health fund) */
  group?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  method: Scalars['String']['input'];
  type: PaymentTypeEnum;
  value: Scalars['Float']['input'];
};

export type StudyReportFiltersInput = {
  branchId?: InputMaybe<Scalars['Int']['input']>;
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
  studyDateFrom: Scalars['DateTime']['input'];
  studyDateTo: Scalars['DateTime']['input'];
};

/** The status of the study */
export enum StudyStatus {
  Draft = 'Draft',
  Paid = 'Paid',
  Performed = 'Performed',
}

export type StudyType = {
  __typename?: 'StudyType';
  code: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  /** Optional comparison description price of the study type */
  comparisonDescriptionPrice?: Maybe<Scalars['Float']['output']>;
  descriptionPrice: Scalars['Float']['output'];
  hasRemarks?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  is3D: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  onReferalOrder?: Maybe<Scalars['Int']['output']>;
  studySubTypes?: Maybe<Array<Scalars['String']['output']>>;
  studyTypeDevices: Array<StudyTypeDevice>;
  studyTypePrices: Array<StudyTypePrice>;
  studyTypeRadiologies: Array<StudyTypeRadiology>;
  /** Amount of minutes a doctor needs (by average) to make a description */
  timeToDescribe?: Maybe<Scalars['Float']['output']>;
  /** Optional urgent description price of the study type */
  urgentDescriptionPrice?: Maybe<Scalars['Float']['output']>;
};

export type StudyTypeStudyTypeDevicesArgs = {
  filter?: StudyTypeDeviceFilter;
  sorting?: Array<StudyTypeDeviceSort>;
};

export type StudyTypeStudyTypePricesArgs = {
  filter?: StudyTypePriceFilter;
  sorting?: Array<StudyTypePriceSort>;
};

export type StudyTypeStudyTypeRadiologiesArgs = {
  filter?: StudyTypeRadiologyFilter;
  sorting?: Array<StudyTypeRadiologySort>;
};

export type StudyTypeConnection = {
  __typename?: 'StudyTypeConnection';
  /** Array of edges. */
  edges: Array<StudyTypeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type StudyTypeDeleteFilter = {
  and?: InputMaybe<Array<StudyTypeDeleteFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  comment?: InputMaybe<StringFieldComparison>;
  comparisonDescriptionPrice?: InputMaybe<FloatFieldComparison>;
  descriptionPrice?: InputMaybe<FloatFieldComparison>;
  hasRemarks?: InputMaybe<BooleanFieldComparison>;
  is3D?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  onReferalOrder?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StudyTypeDeleteFilter>>;
  timeToDescribe?: InputMaybe<NumberFieldComparison>;
  urgentDescriptionPrice?: InputMaybe<FloatFieldComparison>;
};

export type StudyTypeDeleteResponse = {
  __typename?: 'StudyTypeDeleteResponse';
  code?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  /** Optional comparison description price of the study type */
  comparisonDescriptionPrice?: Maybe<Scalars['Float']['output']>;
  descriptionPrice?: Maybe<Scalars['Float']['output']>;
  hasRemarks?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is3D?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onReferalOrder?: Maybe<Scalars['Int']['output']>;
  studySubTypes?: Maybe<Array<Scalars['String']['output']>>;
  studyTypeDevices?: Maybe<Array<StudyTypeDevice>>;
  studyTypePrices?: Maybe<Array<StudyTypePrice>>;
  studyTypeRadiologies?: Maybe<Array<StudyTypeRadiology>>;
  /** Amount of minutes a doctor needs (by average) to make a description */
  timeToDescribe?: Maybe<Scalars['Float']['output']>;
  /** Optional urgent description price of the study type */
  urgentDescriptionPrice?: Maybe<Scalars['Float']['output']>;
};

export type StudyTypeDevice = {
  __typename?: 'StudyTypeDevice';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  deviceType: Scalars['String']['output'];
  durationMinutes: Scalars['Float']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type StudyTypeDeviceFilter = {
  and?: InputMaybe<Array<StudyTypeDeviceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StudyTypeDeviceFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StudyTypeDeviceInput = {
  deviceType: Scalars['String']['input'];
  durationMinutes: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StudyTypeDeviceSort = {
  direction: SortDirection;
  field: StudyTypeDeviceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StudyTypeDeviceSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

export type StudyTypeEdge = {
  __typename?: 'StudyTypeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the StudyType */
  node: StudyType;
};

export type StudyTypeFilter = {
  and?: InputMaybe<Array<StudyTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  comment?: InputMaybe<StringFieldComparison>;
  comparisonDescriptionPrice?: InputMaybe<FloatFieldComparison>;
  descriptionPrice?: InputMaybe<FloatFieldComparison>;
  hasRemarks?: InputMaybe<BooleanFieldComparison>;
  is3D?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  onReferalOrder?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StudyTypeFilter>>;
  studyTypeRadiologies?: InputMaybe<StudyTypeFilterStudyTypeRadiologyFilter>;
  timeToDescribe?: InputMaybe<NumberFieldComparison>;
  urgentDescriptionPrice?: InputMaybe<FloatFieldComparison>;
};

export type StudyTypeFilterStudyTypeRadiologyFilter = {
  and?: InputMaybe<Array<StudyTypeFilterStudyTypeRadiologyFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StudyTypeFilterStudyTypeRadiologyFilter>>;
  radiology?: InputMaybe<
    StudyTypeFilterStudyTypeRadiologyFilterRadiologyFilter
  >;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StudyTypeFilterStudyTypeRadiologyFilterRadiologyFilter = {
  and?: InputMaybe<
    Array<StudyTypeFilterStudyTypeRadiologyFilterRadiologyFilter>
  >;
  anodeCurrentMiliamper?: InputMaybe<FloatFieldComparison>;
  exposureTimeSeconds?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<
    Array<StudyTypeFilterStudyTypeRadiologyFilterRadiologyFilter>
  >;
  presetName?: InputMaybe<StringFieldComparison>;
  radiationDoseMilligrayPerSquareCentimeter?: InputMaybe<FloatFieldComparison>;
  voltageKilovolts?: InputMaybe<FloatFieldComparison>;
};

export type StudyTypeInput = {
  code: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  comparisonDescriptionPrice?: InputMaybe<Scalars['Float']['input']>;
  descriptionPrice: Scalars['Float']['input'];
  hasRemarks?: InputMaybe<Scalars['Boolean']['input']>;
  is3D?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  onReferalOrder?: InputMaybe<Scalars['Int']['input']>;
  studySubTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  studyTypeDevices?: InputMaybe<Array<StudyTypeDeviceInput>>;
  studyTypePrices?: InputMaybe<Array<StudyTypePriceInput>>;
  studyTypeRadiologies?: InputMaybe<Array<StudyTypeRadiologyInput>>;
  timeToDescribe?: InputMaybe<Scalars['Float']['input']>;
  urgentDescriptionPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type StudyTypePrice = {
  __typename?: 'StudyTypePrice';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  presetName?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type StudyTypePriceFilter = {
  and?: InputMaybe<Array<StudyTypePriceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StudyTypePriceFilter>>;
  price?: InputMaybe<FloatFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StudyTypePriceInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  presetName?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
};

export type StudyTypePriceSort = {
  direction: SortDirection;
  field: StudyTypePriceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StudyTypePriceSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Price = 'price',
  UpdatedAt = 'updatedAt',
}

export type StudyTypeRadiology = {
  __typename?: 'StudyTypeRadiology';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  radiology: Radiology;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Float']['output']>;
};

export type StudyTypeRadiologyFilter = {
  and?: InputMaybe<Array<StudyTypeRadiologyFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StudyTypeRadiologyFilter>>;
  radiology?: InputMaybe<StudyTypeRadiologyFilterRadiologyFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StudyTypeRadiologyFilterRadiologyFilter = {
  and?: InputMaybe<Array<StudyTypeRadiologyFilterRadiologyFilter>>;
  anodeCurrentMiliamper?: InputMaybe<FloatFieldComparison>;
  exposureTimeSeconds?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<StudyTypeRadiologyFilterRadiologyFilter>>;
  presetName?: InputMaybe<StringFieldComparison>;
  radiationDoseMilligrayPerSquareCentimeter?: InputMaybe<FloatFieldComparison>;
  voltageKilovolts?: InputMaybe<FloatFieldComparison>;
};

export type StudyTypeRadiologyInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  radiology: RadiologyInput;
};

export type StudyTypeRadiologySort = {
  direction: SortDirection;
  field: StudyTypeRadiologySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StudyTypeRadiologySortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Radiology = 'radiology',
  UpdatedAt = 'updatedAt',
}

export type StudyTypeSort = {
  direction: SortDirection;
  field: StudyTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StudyTypeSortFields {
  Code = 'code',
  Comment = 'comment',
  ComparisonDescriptionPrice = 'comparisonDescriptionPrice',
  DescriptionPrice = 'descriptionPrice',
  HasRemarks = 'hasRemarks',
  Is3D = 'is3D',
  Name = 'name',
  OnReferalOrder = 'onReferalOrder',
  TimeToDescribe = 'timeToDescribe',
  UrgentDescriptionPrice = 'urgentDescriptionPrice',
}

export type StudyTypeUpdateFilter = {
  and?: InputMaybe<Array<StudyTypeUpdateFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  comment?: InputMaybe<StringFieldComparison>;
  comparisonDescriptionPrice?: InputMaybe<FloatFieldComparison>;
  descriptionPrice?: InputMaybe<FloatFieldComparison>;
  hasRemarks?: InputMaybe<BooleanFieldComparison>;
  is3D?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  onReferalOrder?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StudyTypeUpdateFilter>>;
  timeToDescribe?: InputMaybe<NumberFieldComparison>;
  urgentDescriptionPrice?: InputMaybe<FloatFieldComparison>;
};

export type Subscription = {
  __typename?: 'Subscription';
  appointmentAdded: Appointment;
  appointmentDeleted: Scalars['Int']['output'];
  appointmentUpdated: Appointment;
  commentAdded: Comment;
  councilInviteAccepted: Council;
  councilInviteAdded: Council;
  councilInviteRejected: Council;
  messageAdded: Message;
  reservationAdded: Reservation;
  reservationDeleted: Scalars['String']['output'];
  reservationUpdated: Reservation;
};

export type UpdateAddress = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBranch = {
  color?: InputMaybe<Scalars['String']['input']>;
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownBranch?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateComplaintInput = {
  complainantEmailAddress: Scalars['String']['input'];
  complainantName: Scalars['String']['input'];
  complainantPhoneNumber: Scalars['String']['input'];
  complaintDescription: Scalars['String']['input'];
  complaintReason: Scalars['String']['input'];
  complaintResolution: Scalars['String']['input'];
  doctor?: InputMaybe<IdInput>;
  patient?: InputMaybe<IdInput>;
  responsiblePerson?: InputMaybe<IdInput>;
  status: ComplaintStatusEnum;
  study?: InputMaybe<IdInput>;
  type: ComplaintTypeEnum;
};

export type UpdateEmailTemplate = {
  content?: InputMaybe<Scalars['String']['input']>;
  defaultRecipientRoles?: InputMaybe<Array<UserRole>>;
  defaultRecipients?: InputMaybe<Array<Scalars['String']['input']>>;
  embeddedType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyAddressesInput = {
  /** Filter used to find fields to update */
  filter: AddressUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateAddress;
};

export type UpdateManyBranchesInput = {
  /** Filter used to find fields to update */
  filter: BranchUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateBranch;
};

export type UpdateManyEmailTemplatesInput = {
  /** Filter used to find fields to update */
  filter: EmailTemplateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateEmailTemplate;
};

export type UpdateManyMailingsInput = {
  /** Filter used to find fields to update */
  filter: MailingUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: MailingInput;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int']['output'];
};

export type UpdateManySoftwareInput = {
  /** Filter used to find fields to update */
  filter: SoftwareUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSoftware;
};

export type UpdateManyStudyTypesInput = {
  /** Filter used to find fields to update */
  filter: StudyTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: StudyTypeInput;
};

export type UpdateOneAddressInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateAddress;
};

export type UpdateOneBranchInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateBranch;
};

export type UpdateOneEmailTemplateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateEmailTemplate;
};

export type UpdateOneMailingInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: MailingInput;
};

export type UpdateOneSoftwareInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateSoftware;
};

export type UpdateOneStudyTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: StudyTypeInput;
};

export type UpdateSoftware = {
  downloadURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UploadOutput = {
  __typename?: 'UploadOutput';
  fileId: Scalars['Float']['output'];
  uploadSignedURL: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  acceptedTerms: Scalars['Boolean']['output'];
  active: Scalars['Boolean']['output'];
  contact: Contact;
  createdAt: Scalars['DateTime']['output'];
  createdByUser?: Maybe<Scalars['Float']['output']>;
  emailConfirmed: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  lastLoginDate?: Maybe<Scalars['DateTime']['output']>;
  login: Scalars['String']['output'];
  loginAttempts?: Maybe<Scalars['Float']['output']>;
  messages: Array<Message>;
  preferences: Preferences;
  profilePicture?: Maybe<File>;
  regions?: Maybe<Array<Scalars['String']['output']>>;
  roles: Array<UserRole>;
  updatedAt: Scalars['DateTime']['output'];
  updatedByUser?: Maybe<Scalars['Float']['output']>;
  vacationEnd?: Maybe<Scalars['DateTime']['output']>;
  vacationStart?: Maybe<Scalars['DateTime']['output']>;
};

export type UserContactInput = {
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  nip?: InputMaybe<Scalars['String']['input']>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryDescription?: InputMaybe<Scalars['String']['input']>;
  secondaryDescription?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type UserFiltersInput = {
  id?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
};

export type UserInput = {
  acceptedTerms?: InputMaybe<Scalars['Boolean']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  contact: UserContactInput;
  id?: InputMaybe<Scalars['Int']['input']>;
  login: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
  roles?: InputMaybe<Array<UserRole>>;
  vacationEnd?: InputMaybe<Scalars['DateTime']['input']>;
  vacationStart?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Role of user */
export enum UserRole {
  Admin = 'Admin',
  Complaints = 'Complaints',
  DentalOffice = 'DentalOffice',
  DescribingDoctor = 'DescribingDoctor',
  Doctor = 'Doctor',
  HelpContact = 'HelpContact',
  Laboratory = 'Laboratory',
  Manager = 'Manager',
  Patient = 'Patient',
  Registration = 'Registration',
  Technician = 'Technician',
}

export type UserRolesInput = {
  roles: Array<UserRole>;
};

export type GetDownloadUrlQueryVariables = Exact<{
  filePath: Scalars['String']['input'];
  studyId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetDownloadUrlQuery = {
  __typename?: 'Query';
  getDownloadURL: string;
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  fileType: FileType;
  fileSizeInBytes: Scalars['Float']['input'];
  rejected?: InputMaybe<Scalars['Boolean']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
}>;

export type UploadFileMutation = {
  __typename?: 'Mutation';
  uploadFile: { __typename?: 'File'; id: number };
};

export type PrepareUploadMutationVariables = Exact<{
  file: FileMetaInput;
}>;

export type PrepareUploadMutation = {
  __typename?: 'Mutation';
  prepareUpload: {
    __typename?: 'UploadOutput';
    fileId: number;
    uploadSignedURL: string;
  };
};

export type ConfirmUploadMutationVariables = Exact<{
  fileId: Scalars['Int']['input'];
}>;

export type ConfirmUploadMutation = {
  __typename?: 'Mutation';
  confirmUpload: boolean;
};

export type DeleteFileMutationVariables = Exact<{
  id: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type DeleteFileMutation = {
  __typename?: 'Mutation';
  deleteFile: boolean;
};

export type ConfirmEmailMutationVariables = Exact<{
  confirmInput: Scalars['String']['input'];
}>;

export type ConfirmEmailMutation = {
  __typename?: 'Mutation';
  confirmEmail: boolean;
};

export type ResendConfirmationEmailMutationVariables = Exact<{
  login: Scalars['String']['input'];
}>;

export type ResendConfirmationEmailMutation = {
  __typename?: 'Mutation';
  resendConfirmationEmail: boolean;
};

export type LoginMutationVariables = Exact<{
  loginData: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'Auth';
    token: string;
    user: {
      __typename?: 'User';
      id: number;
      login: string;
      roles: Array<UserRole>;
      acceptedTerms: boolean;
    };
  };
};

export type AcceptTermsMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptTermsMutation = {
  __typename?: 'Mutation';
  acceptTerms: boolean;
};

export type OAuthLoginMutationVariables = Exact<{ [key: string]: never }>;

export type OAuthLoginMutation = {
  __typename?: 'Mutation';
  oauthLogin: {
    __typename?: 'Auth';
    token: string;
    user: {
      __typename?: 'User';
      id: number;
      login: string;
      roles: Array<UserRole>;
      acceptedTerms: boolean;
    };
  };
};

export type OAuthConnectAccountMutationVariables = Exact<{
  [key: string]: never;
}>;

export type OAuthConnectAccountMutation = {
  __typename?: 'Mutation';
  oAuthConnectAccount: boolean;
};

export type GetProfileQueryVariables = Exact<{
  withPreferences?: Scalars['Boolean']['input'];
}>;

export type GetProfileQuery = {
  __typename?: 'Query';
  currentDoctor?:
    | {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        specializations?: Array<string> | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        maxStudyHoursToDescribe?: number | null | undefined;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        describingStudyTypes?:
          | Array<{
              __typename?: 'StudyType';
              id: number;
              code: string;
              name: string;
              timeToDescribe?: number | null | undefined;
            }>
          | null
          | undefined;
        describerInfo?:
          | {
              __typename?: 'DescriberInfo';
              assignedStudies: number;
              totalAssignedStudies: number;
              descriptionOccupancyHours: number;
            }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          createdAt: Date;
          updatedAt: Date;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          regions?: Array<string> | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
            addresses?:
              | Array<
                  | {
                      __typename?: 'Address';
                      id: number;
                      street: string;
                      city: string;
                      postalCode: string;
                      country: string;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          };
          preferences?: {
            __typename?: 'Preferences';
            mailNotifications: boolean;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword: { __typename?: 'User'; id: number };
};

export type ChangeLoginMutationVariables = Exact<{
  newLogin: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ChangeLoginMutation = {
  __typename?: 'Mutation';
  changeLogin: { __typename?: 'User'; id: number };
};

export type UpdateCurrentDoctorMutationVariables = Exact<{
  doctor: DoctorInput;
}>;

export type UpdateCurrentDoctorMutation = {
  __typename?: 'Mutation';
  updateCurrentDoctor: {
    __typename?: 'Doctor';
    id: number;
    createdAt?: Date | null | undefined;
    licenseNumber?: string | null | undefined;
    facility: boolean;
    stampImage?:
      | { __typename?: 'File'; id: number; uri: string }
      | null
      | undefined;
    user: {
      __typename?: 'User';
      id: number;
      active: boolean;
      login: string;
      roles: Array<UserRole>;
      regions?: Array<string> | null | undefined;
      vacationStart?: Date | null | undefined;
      vacationEnd?: Date | null | undefined;
      profilePicture?:
        | { __typename?: 'File'; publicURL?: string | null | undefined }
        | null
        | undefined;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        middleName?: string | null | undefined;
        lastName: string;
        companyName?: string | null | undefined;
        birthDate?: Date | null | undefined;
        phoneNumbers?: Array<string> | null | undefined;
        emailAddresses?: Array<string> | null | undefined;
        primaryDescription?: string | null | undefined;
        nip?: string | null | undefined;
      };
    };
    employedInBranches?:
      | Array<{ __typename?: 'Branch'; id: number; name: string }>
      | null
      | undefined;
  };
};

export type UpdateProfilePictureMutationVariables = Exact<{
  updateProfilePicturePictureData: Scalars['String']['input'];
}>;

export type UpdateProfilePictureMutation = {
  __typename?: 'Mutation';
  updateProfilePicture: {
    __typename?: 'File';
    id: number;
    publicURL?: string | null | undefined;
  };
};

export type DeleteExistingProfilePictureMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteExistingProfilePictureMutation = {
  __typename?: 'Mutation';
  deleteExistingProfilePicture: boolean;
};

export type UpdatePreferencesMutationVariables = Exact<{
  newPreferences: PreferencesInput;
}>;

export type UpdatePreferencesMutation = {
  __typename?: 'Mutation';
  updatePreferences: { __typename?: 'Preferences'; mailNotifications: boolean };
};

export type InitPasswordResetMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type InitPasswordResetMutation = {
  __typename?: 'Mutation';
  initPasswordReset: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: boolean;
};

export type OauthSignupDoctorMutationVariables = Exact<{
  signupData: DoctorSignupInput;
}>;

export type OauthSignupDoctorMutation = {
  __typename?: 'Mutation';
  oauthSignupDoctor: {
    __typename?: 'DoctorSignupOutput';
    token: string;
    email: string;
  };
};

export type SignupDoctorMutationVariables = Exact<{
  signupData: DoctorSignupInput;
}>;

export type SignupDoctorMutation = {
  __typename?: 'Mutation';
  signupDoctor: {
    __typename?: 'DoctorSignupOutput';
    token: string;
    email: string;
  };
};

export type CommentFragmentFragment = {
  __typename?: 'Comment';
  id: number;
  title: string;
  content: string;
  createdAt: Date;
  createdBy: {
    __typename?: 'User';
    id: number;
    contact: { __typename?: 'Contact'; firstName: string; lastName: string };
    profilePicture?:
      | { __typename?: 'File'; publicURL?: string | null | undefined }
      | null
      | undefined;
  };
  doctor: {
    __typename?: 'Doctor';
    id: number;
    user: {
      __typename?: 'User';
      contact: { __typename?: 'Contact'; firstName: string; lastName: string };
    };
  };
};

export type MyCommentsQueryVariables = Exact<{ [key: string]: never }>;

export type MyCommentsQuery = {
  __typename?: 'Query';
  myComments: Array<{
    __typename?: 'Comment';
    id: number;
    title: string;
    content: string;
    createdAt: Date;
    createdBy: {
      __typename?: 'User';
      id: number;
      contact: { __typename?: 'Contact'; firstName: string; lastName: string };
      profilePicture?:
        | { __typename?: 'File'; publicURL?: string | null | undefined }
        | null
        | undefined;
    };
    doctor: {
      __typename?: 'Doctor';
      id: number;
      user: {
        __typename?: 'User';
        contact: {
          __typename?: 'Contact';
          firstName: string;
          lastName: string;
        };
      };
    };
  }>;
};

export type DoctorCommentsQueryVariables = Exact<{
  doctorId: Scalars['Int']['input'];
}>;

export type DoctorCommentsQuery = {
  __typename?: 'Query';
  doctorComments: Array<{
    __typename?: 'Comment';
    id: number;
    title: string;
    content: string;
    createdAt: Date;
    createdBy: {
      __typename?: 'User';
      id: number;
      contact: { __typename?: 'Contact'; firstName: string; lastName: string };
      profilePicture?:
        | { __typename?: 'File'; publicURL?: string | null | undefined }
        | null
        | undefined;
    };
    doctor: {
      __typename?: 'Doctor';
      id: number;
      user: {
        __typename?: 'User';
        contact: {
          __typename?: 'Contact';
          firstName: string;
          lastName: string;
        };
      };
    };
  }>;
};

export type AddCommentMutationVariables = Exact<{
  comment: CommentInput;
}>;

export type AddCommentMutation = {
  __typename?: 'Mutation';
  createComment: {
    __typename?: 'Comment';
    id: number;
    title: string;
    content: string;
    createdAt: Date;
    createdBy: {
      __typename?: 'User';
      id: number;
      contact: { __typename?: 'Contact'; firstName: string; lastName: string };
      profilePicture?:
        | { __typename?: 'File'; publicURL?: string | null | undefined }
        | null
        | undefined;
    };
    doctor: {
      __typename?: 'Doctor';
      id: number;
      user: {
        __typename?: 'User';
        contact: {
          __typename?: 'Contact';
          firstName: string;
          lastName: string;
        };
      };
    };
  };
};

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  comment: CommentInput;
}>;

export type UpdateCommentMutation = {
  __typename?: 'Mutation';
  updateComment: {
    __typename?: 'Comment';
    id: number;
    title: string;
    content: string;
    createdAt: Date;
    createdBy: {
      __typename?: 'User';
      id: number;
      contact: { __typename?: 'Contact'; firstName: string; lastName: string };
      profilePicture?:
        | { __typename?: 'File'; publicURL?: string | null | undefined }
        | null
        | undefined;
    };
    doctor: {
      __typename?: 'Doctor';
      id: number;
      user: {
        __typename?: 'User';
        contact: {
          __typename?: 'Contact';
          firstName: string;
          lastName: string;
        };
      };
    };
  };
};

export type DeleteCommentsMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type DeleteCommentsMutation = {
  __typename?: 'Mutation';
  deleteComments: boolean;
};

export type SaveAppConfigMutationVariables = Exact<{
  data: AppConfigInput;
}>;

export type SaveAppConfigMutation = {
  __typename?: 'Mutation';
  saveAppConfig: {
    __typename?: 'AppConfig';
    paymentTypes?: Array<string> | null | undefined;
    priceGroups?: Array<string> | null | undefined;
    radiationGroups?: Array<string> | null | undefined;
    carrierPrices?: any | null | undefined;
    deviceTypes?: Array<string> | null | undefined;
    regions?: Array<string> | null | undefined;
    complaintTypes?: Array<string> | null | undefined;
    complaintResolutionTimeHours?: number | null | undefined;
    specializations?: Array<string> | null | undefined;
  };
};

export type AppConfigFragmentFragment = {
  __typename?: 'AppConfig';
  paymentTypes?: Array<string> | null | undefined;
  priceGroups?: Array<string> | null | undefined;
  radiationGroups?: Array<string> | null | undefined;
  carrierPrices?: any | null | undefined;
  deviceTypes?: Array<string> | null | undefined;
  regions?: Array<string> | null | undefined;
  complaintTypes?: Array<string> | null | undefined;
  complaintResolutionTimeHours?: number | null | undefined;
  specializations?: Array<string> | null | undefined;
};

export type AppConfigQueryVariables = Exact<{ [key: string]: never }>;

export type AppConfigQuery = {
  __typename?: 'Query';
  appConfig: {
    __typename?: 'AppConfig';
    paymentTypes?: Array<string> | null | undefined;
    priceGroups?: Array<string> | null | undefined;
    radiationGroups?: Array<string> | null | undefined;
    carrierPrices?: any | null | undefined;
    deviceTypes?: Array<string> | null | undefined;
    regions?: Array<string> | null | undefined;
    complaintTypes?: Array<string> | null | undefined;
    complaintResolutionTimeHours?: number | null | undefined;
    specializations?: Array<string> | null | undefined;
  };
};

export type SaveBranchMutationVariables = Exact<{
  branch: BranchInput;
}>;

export type SaveBranchMutation = {
  __typename?: 'Mutation';
  saveBranch: {
    __typename?: 'Branch';
    id: number;
    name: string;
    ownBranch: boolean;
    color?: string | null | undefined;
    phoneNumbers?: Array<string> | null | undefined;
    emailAddresses?: Array<string> | null | undefined;
    region?: string | null | undefined;
    address?:
      | {
          __typename?: 'Address';
          id: number;
          street: string;
          postalCode: string;
          city: string;
          country: string;
        }
      | null
      | undefined;
    devices: Array<{
      __typename?: 'Device';
      id: number;
      name: string;
      deviceType: string;
      radiationGroups?: Array<string> | null | undefined;
    }>;
  };
};

export type DeleteBranchMutationVariables = Exact<{
  branchId: Scalars['ID']['input'];
}>;

export type DeleteBranchMutation = {
  __typename?: 'Mutation';
  deleteOneBranch: {
    __typename?: 'BranchDeleteResponse';
    id?: number | null | undefined;
    name?: string | null | undefined;
  };
};

export type BranchFieldsFragment = {
  __typename?: 'Branch';
  id: number;
  name: string;
  ownBranch: boolean;
  color?: string | null | undefined;
  phoneNumbers?: Array<string> | null | undefined;
  emailAddresses?: Array<string> | null | undefined;
  region?: string | null | undefined;
  address?:
    | {
        __typename?: 'Address';
        id: number;
        street: string;
        postalCode: string;
        city: string;
        country: string;
      }
    | null
    | undefined;
  devices: Array<{
    __typename?: 'Device';
    id: number;
    name: string;
    deviceType: string;
    radiationGroups?: Array<string> | null | undefined;
  }>;
};

export type BranchesQueryVariables = Exact<{
  paging?: InputMaybe<OffsetPaging>;
  filter?: InputMaybe<BranchFilter>;
  sorting?: InputMaybe<Array<BranchSort> | BranchSort>;
}>;

export type BranchesQuery = {
  __typename?: 'Query';
  branches: {
    __typename?: 'BranchConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Branch';
      id: number;
      name: string;
      ownBranch: boolean;
      color?: string | null | undefined;
      phoneNumbers?: Array<string> | null | undefined;
      emailAddresses?: Array<string> | null | undefined;
      region?: string | null | undefined;
      address?:
        | {
            __typename?: 'Address';
            id: number;
            street: string;
            postalCode: string;
            city: string;
            country: string;
          }
        | null
        | undefined;
      devices: Array<{
        __typename?: 'Device';
        id: number;
        name: string;
        deviceType: string;
        radiationGroups?: Array<string> | null | undefined;
      }>;
    }>;
  };
};

export type BranchQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BranchQuery = {
  __typename?: 'Query';
  branch: {
    __typename?: 'Branch';
    id: number;
    name: string;
    ownBranch: boolean;
    color?: string | null | undefined;
    phoneNumbers?: Array<string> | null | undefined;
    emailAddresses?: Array<string> | null | undefined;
    region?: string | null | undefined;
    address?:
      | {
          __typename?: 'Address';
          id: number;
          street: string;
          postalCode: string;
          city: string;
          country: string;
        }
      | null
      | undefined;
    devices: Array<{
      __typename?: 'Device';
      id: number;
      name: string;
      deviceType: string;
      radiationGroups?: Array<string> | null | undefined;
    }>;
  };
};

export type AddEmailTemplateMutationVariables = Exact<{
  newEmailTemplate: CreateEmailTemplate;
}>;

export type AddEmailTemplateMutation = {
  __typename?: 'Mutation';
  createOneEmailTemplate: {
    __typename?: 'EmailTemplate';
    id: number;
    name: string;
    defaultRecipientRoles?: Array<UserRole> | null | undefined;
    defaultRecipients?: Array<string> | null | undefined;
    content: string;
    embeddedType: string;
  };
};

export type UpdateEmailTemplateMutationVariables = Exact<{
  emailTemplateId: Scalars['ID']['input'];
  emailTemplate: UpdateEmailTemplate;
}>;

export type UpdateEmailTemplateMutation = {
  __typename?: 'Mutation';
  updateOneEmailTemplate: {
    __typename?: 'EmailTemplate';
    id: number;
    name: string;
    defaultRecipientRoles?: Array<UserRole> | null | undefined;
    defaultRecipients?: Array<string> | null | undefined;
    content: string;
    embeddedType: string;
  };
};

export type DeleteEmailTemplateMutationVariables = Exact<{
  emailTemplateId: Scalars['ID']['input'];
}>;

export type DeleteEmailTemplateMutation = {
  __typename?: 'Mutation';
  deleteOneEmailTemplate: {
    __typename?: 'EmailTemplateDeleteResponse';
    id?: number | null | undefined;
  };
};

export type EmailTemplateFragmentFragment = {
  __typename?: 'EmailTemplate';
  id: number;
  name: string;
  defaultRecipientRoles?: Array<UserRole> | null | undefined;
  defaultRecipients?: Array<string> | null | undefined;
  content: string;
  embeddedType: string;
};

export type EmailTemplateQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<EmailTemplateFilter>;
  sorting?: InputMaybe<Array<EmailTemplateSort> | EmailTemplateSort>;
}>;

export type EmailTemplateQuery = {
  __typename?: 'Query';
  emailTemplates: {
    __typename?: 'EmailTemplateConnection';
    edges: Array<{
      __typename?: 'EmailTemplateEdge';
      node: {
        __typename?: 'EmailTemplate';
        id: number;
        name: string;
        defaultRecipientRoles?: Array<UserRole> | null | undefined;
        defaultRecipients?: Array<string> | null | undefined;
        content: string;
        embeddedType: string;
      };
    }>;
  };
};

export type AddMailingMutationVariables = Exact<{
  newMailing: MailingInput;
}>;

export type AddMailingMutation = {
  __typename?: 'Mutation';
  createOneMailing: {
    __typename?: 'Mailing';
    content: string;
    id: number;
    createdById?: number | null | undefined;
    createdAt?: Date | null | undefined;
    subject?: string | null | undefined;
    status: MailingStatus;
    recipients?: Array<string> | null | undefined;
    specializations?: Array<string> | null | undefined;
    width?: number | null | undefined;
    emailChannel: boolean;
    internalMessageChannel: boolean;
    showFooter: boolean;
    important: boolean;
  };
};

export type UpdateMailingMutationVariables = Exact<{
  mailingId: Scalars['ID']['input'];
  mailing: MailingInput;
}>;

export type UpdateMailingMutation = {
  __typename?: 'Mutation';
  updateOneMailing: {
    __typename?: 'Mailing';
    content: string;
    id: number;
    createdById?: number | null | undefined;
    createdAt?: Date | null | undefined;
    subject?: string | null | undefined;
    status: MailingStatus;
    recipients?: Array<string> | null | undefined;
    specializations?: Array<string> | null | undefined;
    width?: number | null | undefined;
    emailChannel: boolean;
    internalMessageChannel: boolean;
    showFooter: boolean;
    important: boolean;
  };
};

export type DeleteMailingMutationVariables = Exact<{
  mailingId: Scalars['ID']['input'];
}>;

export type DeleteMailingMutation = {
  __typename?: 'Mutation';
  deleteOneMailing: {
    __typename?: 'MailingDeleteResponse';
    id?: number | null | undefined;
  };
};

export type StartMailingMutationVariables = Exact<{
  mailingId: Scalars['ID']['input'];
}>;

export type StartMailingMutation = {
  __typename?: 'Mutation';
  startMailing: boolean;
};

export type StopMailingMutationVariables = Exact<{
  mailingId: Scalars['ID']['input'];
}>;

export type StopMailingMutation = {
  __typename?: 'Mutation';
  stopMailing: boolean;
};

export type MailingFragmentFragment = {
  __typename?: 'Mailing';
  id: number;
  createdById?: number | null | undefined;
  createdAt?: Date | null | undefined;
  subject?: string | null | undefined;
  status: MailingStatus;
  recipients?: Array<string> | null | undefined;
  specializations?: Array<string> | null | undefined;
  width?: number | null | undefined;
  emailChannel: boolean;
  internalMessageChannel: boolean;
  showFooter: boolean;
  important: boolean;
};

export type MailingQueryVariables = Exact<{
  mailingId: Scalars['ID']['input'];
}>;

export type MailingQuery = {
  __typename?: 'Query';
  mailing: {
    __typename?: 'Mailing';
    content: string;
    id: number;
    createdById?: number | null | undefined;
    createdAt?: Date | null | undefined;
    subject?: string | null | undefined;
    status: MailingStatus;
    recipients?: Array<string> | null | undefined;
    specializations?: Array<string> | null | undefined;
    width?: number | null | undefined;
    emailChannel: boolean;
    internalMessageChannel: boolean;
    showFooter: boolean;
    important: boolean;
  };
};

export type MailingsQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<MailingFilter>;
  sorting?: InputMaybe<Array<MailingSort> | MailingSort>;
}>;

export type MailingsQuery = {
  __typename?: 'Query';
  mailings: {
    __typename?: 'MailingConnection';
    edges: Array<{
      __typename?: 'MailingEdge';
      node: {
        __typename?: 'Mailing';
        id: number;
        createdById?: number | null | undefined;
        createdAt?: Date | null | undefined;
        subject?: string | null | undefined;
        status: MailingStatus;
        recipients?: Array<string> | null | undefined;
        specializations?: Array<string> | null | undefined;
        width?: number | null | undefined;
        emailChannel: boolean;
        internalMessageChannel: boolean;
        showFooter: boolean;
        important: boolean;
      };
    }>;
  };
};

export type AddSoftwareMutationVariables = Exact<{
  newSoftware: SoftwareInput;
}>;

export type AddSoftwareMutation = {
  __typename?: 'Mutation';
  createOneSoftware: {
    __typename?: 'Software';
    id: number;
    name: string;
    downloadURL: string;
  };
};

export type UpdateSoftwareMutationVariables = Exact<{
  softwareId: Scalars['ID']['input'];
  software: UpdateSoftware;
}>;

export type UpdateSoftwareMutation = {
  __typename?: 'Mutation';
  updateOneSoftware: {
    __typename?: 'Software';
    id: number;
    name: string;
    downloadURL: string;
  };
};

export type DeleteSoftwareMutationVariables = Exact<{
  softwareId: Scalars['ID']['input'];
}>;

export type DeleteSoftwareMutation = {
  __typename?: 'Mutation';
  deleteOneSoftware: {
    __typename?: 'SoftwareDeleteResponse';
    id?: number | null | undefined;
  };
};

export type SoftwareFragmentFragment = {
  __typename?: 'Software';
  id: number;
  name: string;
  downloadURL: string;
};

export type SoftwareQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<SoftwareFilter>;
  sorting?: InputMaybe<Array<SoftwareSort> | SoftwareSort>;
}>;

export type SoftwareQuery = {
  __typename?: 'Query';
  software: {
    __typename?: 'SoftwareConnection';
    edges: Array<{
      __typename?: 'SoftwareEdge';
      node: {
        __typename?: 'Software';
        id: number;
        name: string;
        downloadURL: string;
      };
    }>;
  };
};

export type StudyTypeFragmentFragment = {
  __typename?: 'StudyType';
  id: number;
  code: string;
  name: string;
  onReferalOrder?: number | null | undefined;
  hasRemarks?: boolean | null | undefined;
  is3D: boolean;
  timeToDescribe?: number | null | undefined;
  comment?: string | null | undefined;
  descriptionPrice: number;
  urgentDescriptionPrice?: number | null | undefined;
  comparisonDescriptionPrice?: number | null | undefined;
  studySubTypes?: Array<string> | null | undefined;
  studyTypePrices: Array<{
    __typename?: 'StudyTypePrice';
    id?: number | null | undefined;
    price: number;
    presetName?: string | null | undefined;
  }>;
  studyTypeRadiologies: Array<{
    __typename?: 'StudyTypeRadiology';
    id?: number | null | undefined;
    radiology: {
      __typename?: 'Radiology';
      presetName?: string | null | undefined;
      anodeCurrentMiliamper: number;
      voltageKilovolts: number;
      exposureTimeSeconds: number;
      radiationDoseMilligrayPerSquareCentimeter: number;
    };
  }>;
  studyTypeDevices: Array<{
    __typename?: 'StudyTypeDevice';
    id?: number | null | undefined;
    durationMinutes: number;
    deviceType: string;
  }>;
};

export type AddStudyTypeMutationVariables = Exact<{
  newStudyType: StudyTypeInput;
}>;

export type AddStudyTypeMutation = {
  __typename?: 'Mutation';
  createOneStudyType: {
    __typename?: 'StudyType';
    id: number;
    code: string;
    name: string;
    onReferalOrder?: number | null | undefined;
    hasRemarks?: boolean | null | undefined;
    is3D: boolean;
    timeToDescribe?: number | null | undefined;
    comment?: string | null | undefined;
    descriptionPrice: number;
    urgentDescriptionPrice?: number | null | undefined;
    comparisonDescriptionPrice?: number | null | undefined;
    studySubTypes?: Array<string> | null | undefined;
    studyTypePrices: Array<{
      __typename?: 'StudyTypePrice';
      id?: number | null | undefined;
      price: number;
      presetName?: string | null | undefined;
    }>;
    studyTypeRadiologies: Array<{
      __typename?: 'StudyTypeRadiology';
      id?: number | null | undefined;
      radiology: {
        __typename?: 'Radiology';
        presetName?: string | null | undefined;
        anodeCurrentMiliamper: number;
        voltageKilovolts: number;
        exposureTimeSeconds: number;
        radiationDoseMilligrayPerSquareCentimeter: number;
      };
    }>;
    studyTypeDevices: Array<{
      __typename?: 'StudyTypeDevice';
      id?: number | null | undefined;
      durationMinutes: number;
      deviceType: string;
    }>;
  };
};

export type UpdateStudyTypeMutationVariables = Exact<{
  studyTypeId: Scalars['ID']['input'];
  studyType: StudyTypeInput;
}>;

export type UpdateStudyTypeMutation = {
  __typename?: 'Mutation';
  updateOneStudyType: {
    __typename?: 'StudyType';
    id: number;
    code: string;
    name: string;
    onReferalOrder?: number | null | undefined;
    hasRemarks?: boolean | null | undefined;
    is3D: boolean;
    timeToDescribe?: number | null | undefined;
    comment?: string | null | undefined;
    descriptionPrice: number;
    urgentDescriptionPrice?: number | null | undefined;
    comparisonDescriptionPrice?: number | null | undefined;
    studySubTypes?: Array<string> | null | undefined;
    studyTypePrices: Array<{
      __typename?: 'StudyTypePrice';
      id?: number | null | undefined;
      price: number;
      presetName?: string | null | undefined;
    }>;
    studyTypeRadiologies: Array<{
      __typename?: 'StudyTypeRadiology';
      id?: number | null | undefined;
      radiology: {
        __typename?: 'Radiology';
        presetName?: string | null | undefined;
        anodeCurrentMiliamper: number;
        voltageKilovolts: number;
        exposureTimeSeconds: number;
        radiationDoseMilligrayPerSquareCentimeter: number;
      };
    }>;
    studyTypeDevices: Array<{
      __typename?: 'StudyTypeDevice';
      id?: number | null | undefined;
      durationMinutes: number;
      deviceType: string;
    }>;
  };
};

export type DeleteStudyTypeMutationVariables = Exact<{
  studyTypeId: Scalars['ID']['input'];
}>;

export type DeleteStudyTypeMutation = {
  __typename?: 'Mutation';
  deleteOneStudyType: {
    __typename?: 'StudyTypeDeleteResponse';
    id?: number | null | undefined;
  };
};

export type SignupUserMutationVariables = Exact<{
  userData: UserInput;
  roles: Array<UserRole> | UserRole;
}>;

export type SignupUserMutation = {
  __typename?: 'Mutation';
  signup: { __typename?: 'Auth'; user: { __typename?: 'User'; login: string } };
};

export type SignupOfficeOrLaboratoryMutationVariables = Exact<{
  userData: UserInput;
  roles: Array<UserRole> | UserRole;
}>;

export type SignupOfficeOrLaboratoryMutation = {
  __typename?: 'Mutation';
  signupOfficeOrLaboratory: {
    __typename?: 'Auth';
    token: string;
    user: { __typename?: 'User'; login: string };
  };
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser: boolean;
};

export type AppointmentDataFragment = {
  __typename?: 'Appointment';
  id: number;
  startDate: Date;
  endDate: Date;
  isBreak: boolean;
  comments?: string | null | undefined;
  studyType?:
    | { __typename?: 'StudyType'; id: number; name: string; is3D: boolean }
    | null
    | undefined;
  branch: { __typename?: 'Branch'; id: number };
  device: { __typename?: 'Device'; id: number; name: string };
  study?: { __typename?: 'Study'; id: number } | null | undefined;
  referal?:
    | { __typename?: 'Referal'; id: number; completed: boolean }
    | null
    | undefined;
  patient?:
    | {
        __typename?: 'Patient';
        id: number;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          lastName: string;
          birthDate?: Date | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          emailAddresses?: Array<string> | null | undefined;
          primaryDescription?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type ReservationDataFragment = {
  __typename?: 'Reservation';
  id?: string | null | undefined;
  startDate: Date;
  endDate: Date;
  deviceId: number;
  createdById?: number | null | undefined;
  createdBy?: string | null | undefined;
};

export type AppointmentsQueryVariables = Exact<{
  pagination: PaginationInput;
  where?: InputMaybe<AppointmentFiltersInput>;
  orderBy?: InputMaybe<Array<OrderColumn> | OrderColumn>;
}>;

export type AppointmentsQuery = {
  __typename?: 'Query';
  appointments: Array<{
    __typename?: 'Appointment';
    id: number;
    startDate: Date;
    endDate: Date;
    isBreak: boolean;
    comments?: string | null | undefined;
    studyType?:
      | { __typename?: 'StudyType'; id: number; name: string; is3D: boolean }
      | null
      | undefined;
    branch: { __typename?: 'Branch'; id: number };
    device: { __typename?: 'Device'; id: number; name: string };
    study?: { __typename?: 'Study'; id: number } | null | undefined;
    referal?:
      | { __typename?: 'Referal'; id: number; completed: boolean }
      | null
      | undefined;
    patient?:
      | {
          __typename?: 'Patient';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
          };
        }
      | null
      | undefined;
  }>;
};

export type ReservationsQueryVariables = Exact<{
  deviceId: Scalars['Int']['input'];
}>;

export type ReservationsQuery = {
  __typename?: 'Query';
  reservations: Array<{
    __typename?: 'Reservation';
    id?: string | null | undefined;
    startDate: Date;
    endDate: Date;
    deviceId: number;
    createdById?: number | null | undefined;
    createdBy?: string | null | undefined;
  }>;
};

export type GetAppointmentQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetAppointmentQuery = {
  __typename?: 'Query';
  appointment: {
    __typename?: 'Appointment';
    id: number;
    startDate: Date;
    endDate: Date;
    isBreak: boolean;
    comments?: string | null | undefined;
    studyType?:
      | { __typename?: 'StudyType'; id: number; name: string; is3D: boolean }
      | null
      | undefined;
    branch: { __typename?: 'Branch'; id: number };
    device: { __typename?: 'Device'; id: number; name: string };
    study?: { __typename?: 'Study'; id: number } | null | undefined;
    referal?:
      | { __typename?: 'Referal'; id: number; completed: boolean }
      | null
      | undefined;
    patient?:
      | {
          __typename?: 'Patient';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
          };
        }
      | null
      | undefined;
  };
};

export type AddAppointmentMutationVariables = Exact<{
  data: AppointmentAddInput;
}>;

export type AddAppointmentMutation = {
  __typename?: 'Mutation';
  addAppointments: Array<{
    __typename?: 'Appointment';
    id: number;
    startDate: Date;
    endDate: Date;
    isBreak: boolean;
    comments?: string | null | undefined;
    studyType?:
      | { __typename?: 'StudyType'; id: number; name: string; is3D: boolean }
      | null
      | undefined;
    branch: { __typename?: 'Branch'; id: number };
    device: { __typename?: 'Device'; id: number; name: string };
    study?: { __typename?: 'Study'; id: number } | null | undefined;
    referal?:
      | { __typename?: 'Referal'; id: number; completed: boolean }
      | null
      | undefined;
    patient?:
      | {
          __typename?: 'Patient';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
          };
        }
      | null
      | undefined;
  }>;
};

export type AddBreakMutationVariables = Exact<{
  data: AppointmentBreakInput;
}>;

export type AddBreakMutation = {
  __typename?: 'Mutation';
  addBreak: {
    __typename?: 'Appointment';
    id: number;
    startDate: Date;
    endDate: Date;
    isBreak: boolean;
    comments?: string | null | undefined;
    studyType?:
      | { __typename?: 'StudyType'; id: number; name: string; is3D: boolean }
      | null
      | undefined;
    branch: { __typename?: 'Branch'; id: number };
    device: { __typename?: 'Device'; id: number; name: string };
    study?: { __typename?: 'Study'; id: number } | null | undefined;
    referal?:
      | { __typename?: 'Referal'; id: number; completed: boolean }
      | null
      | undefined;
    patient?:
      | {
          __typename?: 'Patient';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
          };
        }
      | null
      | undefined;
  };
};

export type UpdateAppointmentMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  data: AppointmentUpdateInput;
}>;

export type UpdateAppointmentMutation = {
  __typename?: 'Mutation';
  updateAppointment: {
    __typename?: 'Appointment';
    id: number;
    startDate: Date;
    endDate: Date;
    isBreak: boolean;
    comments?: string | null | undefined;
    studyType?:
      | { __typename?: 'StudyType'; id: number; name: string; is3D: boolean }
      | null
      | undefined;
    branch: { __typename?: 'Branch'; id: number };
    device: { __typename?: 'Device'; id: number; name: string };
    study?: { __typename?: 'Study'; id: number } | null | undefined;
    referal?:
      | { __typename?: 'Referal'; id: number; completed: boolean }
      | null
      | undefined;
    patient?:
      | {
          __typename?: 'Patient';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
          };
        }
      | null
      | undefined;
  };
};

export type DeleteAppointmentMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteAppointmentMutation = {
  __typename?: 'Mutation';
  deleteAppointment: number;
};

export type OnAppointmentAddedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnAppointmentAddedSubscription = {
  __typename?: 'Subscription';
  appointmentAdded: {
    __typename?: 'Appointment';
    id: number;
    startDate: Date;
    endDate: Date;
    isBreak: boolean;
    comments?: string | null | undefined;
    studyType?:
      | { __typename?: 'StudyType'; id: number; name: string; is3D: boolean }
      | null
      | undefined;
    branch: { __typename?: 'Branch'; id: number };
    device: { __typename?: 'Device'; id: number; name: string };
    study?: { __typename?: 'Study'; id: number } | null | undefined;
    referal?:
      | { __typename?: 'Referal'; id: number; completed: boolean }
      | null
      | undefined;
    patient?:
      | {
          __typename?: 'Patient';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
          };
        }
      | null
      | undefined;
  };
};

export type AddReservationMutationVariables = Exact<{
  data: ReservationInput;
}>;

export type AddReservationMutation = {
  __typename?: 'Mutation';
  addReservation: {
    __typename?: 'Reservation';
    id?: string | null | undefined;
    startDate: Date;
    endDate: Date;
    deviceId: number;
    createdById?: number | null | undefined;
    createdBy?: string | null | undefined;
  };
};

export type UpdateReservationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: ReservationInput;
}>;

export type UpdateReservationMutation = {
  __typename?: 'Mutation';
  updateReservation: {
    __typename?: 'Reservation';
    id?: string | null | undefined;
    startDate: Date;
    endDate: Date;
    deviceId: number;
    createdById?: number | null | undefined;
    createdBy?: string | null | undefined;
  };
};

export type DeleteReservationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteReservationMutation = {
  __typename?: 'Mutation';
  deleteReservation: string;
};

export type OnAppointmentUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnAppointmentUpdatedSubscription = {
  __typename?: 'Subscription';
  appointmentUpdated: {
    __typename?: 'Appointment';
    id: number;
    startDate: Date;
    endDate: Date;
    isBreak: boolean;
    comments?: string | null | undefined;
    studyType?:
      | { __typename?: 'StudyType'; id: number; name: string; is3D: boolean }
      | null
      | undefined;
    branch: { __typename?: 'Branch'; id: number };
    device: { __typename?: 'Device'; id: number; name: string };
    study?: { __typename?: 'Study'; id: number } | null | undefined;
    referal?:
      | { __typename?: 'Referal'; id: number; completed: boolean }
      | null
      | undefined;
    patient?:
      | {
          __typename?: 'Patient';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
          };
        }
      | null
      | undefined;
  };
};

export type OnAppointmentDeletedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnAppointmentDeletedSubscription = {
  __typename?: 'Subscription';
  appointmentDeleted: number;
};

export type OnReservationAddedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnReservationAddedSubscription = {
  __typename?: 'Subscription';
  reservationAdded: {
    __typename?: 'Reservation';
    id?: string | null | undefined;
    startDate: Date;
    endDate: Date;
    deviceId: number;
    createdById?: number | null | undefined;
    createdBy?: string | null | undefined;
  };
};

export type OnReservationUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnReservationUpdatedSubscription = {
  __typename?: 'Subscription';
  reservationUpdated: {
    __typename?: 'Reservation';
    id?: string | null | undefined;
    startDate: Date;
    endDate: Date;
    deviceId: number;
    createdById?: number | null | undefined;
    createdBy?: string | null | undefined;
  };
};

export type OnReservationDeletedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnReservationDeletedSubscription = {
  __typename?: 'Subscription';
  reservationDeleted: string;
};

export type DoctorDataFragment = {
  __typename?: 'Doctor';
  id: number;
  createdAt?: Date | null | undefined;
  licenseNumber?: string | null | undefined;
  facility: boolean;
  stampImage?:
    | { __typename?: 'File'; id: number; uri: string }
    | null
    | undefined;
  user: {
    __typename?: 'User';
    id: number;
    active: boolean;
    login: string;
    roles: Array<UserRole>;
    regions?: Array<string> | null | undefined;
    vacationStart?: Date | null | undefined;
    vacationEnd?: Date | null | undefined;
    profilePicture?:
      | { __typename?: 'File'; publicURL?: string | null | undefined }
      | null
      | undefined;
    contact: {
      __typename?: 'Contact';
      id: number;
      firstName: string;
      middleName?: string | null | undefined;
      lastName: string;
      companyName?: string | null | undefined;
      birthDate?: Date | null | undefined;
      phoneNumbers?: Array<string> | null | undefined;
      emailAddresses?: Array<string> | null | undefined;
      primaryDescription?: string | null | undefined;
      nip?: string | null | undefined;
    };
  };
  employedInBranches?:
    | Array<{ __typename?: 'Branch'; id: number; name: string }>
    | null
    | undefined;
};

export type PatientDataFragment = {
  __typename?: 'Patient';
  id: number;
  notes?: string | null | undefined;
  registrationDate: Date;
  createdById?: number | null | undefined;
  updatedById?: number | null | undefined;
  contact: {
    __typename?: 'Contact';
    id: number;
    firstName: string;
    middleName?: string | null | undefined;
    lastName: string;
    companyName?: string | null | undefined;
    ssn?: string | null | undefined;
    gender?: Gender | null | undefined;
    birthDate?: Date | null | undefined;
    phoneNumbers?: Array<string> | null | undefined;
    emailAddresses?: Array<string> | null | undefined;
    primaryDescription?: string | null | undefined;
    secondaryDescription?: string | null | undefined;
    addresses?:
      | Array<
          | {
              __typename?: 'Address';
              id: number;
              street: string;
              postalCode: string;
              city: string;
              country: string;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type PatientWithAppointmentsFragment = {
  __typename?: 'Patient';
  id: number;
  notes?: string | null | undefined;
  registrationDate: Date;
  createdById?: number | null | undefined;
  updatedById?: number | null | undefined;
  appointments: Array<{
    __typename?: 'Appointment';
    id: number;
    startDate: Date;
    endDate: Date;
    comments?: string | null | undefined;
    createdAt?: Date | null | undefined;
    updatedAt?: Date | null | undefined;
    studyType?:
      | { __typename?: 'StudyType'; id: number; name: string }
      | null
      | undefined;
  }>;
  contact: {
    __typename?: 'Contact';
    id: number;
    firstName: string;
    middleName?: string | null | undefined;
    lastName: string;
    companyName?: string | null | undefined;
    ssn?: string | null | undefined;
    gender?: Gender | null | undefined;
    birthDate?: Date | null | undefined;
    phoneNumbers?: Array<string> | null | undefined;
    emailAddresses?: Array<string> | null | undefined;
    primaryDescription?: string | null | undefined;
    secondaryDescription?: string | null | undefined;
    addresses?:
      | Array<
          | {
              __typename?: 'Address';
              id: number;
              street: string;
              postalCode: string;
              city: string;
              country: string;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type PatientListDataFragment = {
  __typename?: 'Patient';
  id: number;
  contact: {
    __typename?: 'Contact';
    id: number;
    firstName: string;
    lastName: string;
    companyName?: string | null | undefined;
    birthDate?: Date | null | undefined;
    ssn?: string | null | undefined;
  };
};

export type ReferalDataFragment = {
  __typename?: 'Referal';
  id: number;
  completed: boolean;
  description?: string | null | undefined;
  remarks?: string | null | undefined;
  requestDescription: boolean;
  mediaTypes: Array<MediaType>;
  referalToStudyTypes: Array<{
    __typename?: 'ReferalToStudyType';
    id: number;
    remarks?: string | null | undefined;
    studySubTypes?: Array<string> | null | undefined;
    studyType: { __typename?: 'StudyType'; id: number; name: string };
  }>;
  issuer: {
    __typename?: 'Doctor';
    id: number;
    user: {
      __typename?: 'User';
      id: number;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        lastName: string;
        primaryDescription?: string | null | undefined;
      };
    };
  };
  patient: {
    __typename?: 'Patient';
    id: number;
    contact: {
      __typename?: 'Contact';
      id: number;
      firstName: string;
      lastName: string;
      emailAddresses?: Array<string> | null | undefined;
      phoneNumbers?: Array<string> | null | undefined;
      birthDate?: Date | null | undefined;
      ssn?: string | null | undefined;
      gender?: Gender | null | undefined;
    };
  };
};

export type StudyPaymentFragment = {
  __typename?: 'StudyPayment';
  createdAt?: Date | null | undefined;
  createdById?: number | null | undefined;
  id?: number | null | undefined;
  method: string;
  type: PaymentTypeEnum;
  value: number;
  discount?: number | null | undefined;
  group?: string | null | undefined;
  comment?: string | null | undefined;
};

export type StudyDataFragment = {
  __typename?: 'Study';
  id: number;
  studyDate: Date;
  status: StudyStatus;
  urgentDescription?: boolean | null | undefined;
  comparisonDescription?: boolean | null | undefined;
  createdById?: number | null | undefined;
  updatedById?: number | null | undefined;
  hidden?: boolean | null | undefined;
  incognitoDescription?: boolean | null | undefined;
  internalInformation?: string | null | undefined;
  importantInformation?: string | null | undefined;
  descriptionRequestComment?: string | null | undefined;
  notifyLeaderAndSupportingDoctors?: boolean | null | undefined;
  notifyDoctorsAboutDescription?: boolean | null | undefined;
  notifyDescribers?: boolean | null | undefined;
  externalReferal?: boolean | null | undefined;
  missingDescription?: boolean | null | undefined;
  unassignedDescriber?: boolean | null | undefined;
  hasDescription?: boolean | null | undefined;
  createdAt?: Date | null | undefined;
  updatedAt?: Date | null | undefined;
  studyType: {
    __typename?: 'StudyType';
    id: number;
    code: string;
    name: string;
    timeToDescribe?: number | null | undefined;
    descriptionPrice: number;
    urgentDescriptionPrice?: number | null | undefined;
    comparisonDescriptionPrice?: number | null | undefined;
    studyTypePrices: Array<{
      __typename?: 'StudyTypePrice';
      id?: number | null | undefined;
      price: number;
      presetName?: string | null | undefined;
    }>;
    studyTypeRadiologies: Array<{
      __typename?: 'StudyTypeRadiology';
      radiology: {
        __typename?: 'Radiology';
        presetName?: string | null | undefined;
        anodeCurrentMiliamper: number;
        voltageKilovolts: number;
        exposureTimeSeconds: number;
        radiationDoseMilligrayPerSquareCentimeter: number;
      };
    }>;
  };
  patient: {
    __typename?: 'Patient';
    id: number;
    contact: {
      __typename?: 'Contact';
      id: number;
      firstName: string;
      lastName: string;
      birthDate?: Date | null | undefined;
      ssn?: string | null | undefined;
    };
    studies: Array<{ __typename?: 'Study'; id: number }>;
  };
  leadingDoctor?:
    | {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
  leadingDoctorBranch?:
    | { __typename?: 'Branch'; id: number; name: string }
    | null
    | undefined;
  describers?:
    | Array<{
        __typename?: 'StudyDescriber';
        id: number;
        stat: boolean;
        dueDate?: Date | null | undefined;
        doctor: {
          __typename?: 'Doctor';
          id: number;
          createdAt?: Date | null | undefined;
          licenseNumber?: string | null | undefined;
          facility: boolean;
          stampImage?:
            | { __typename?: 'File'; id: number; uri: string }
            | null
            | undefined;
          user: {
            __typename?: 'User';
            id: number;
            active: boolean;
            login: string;
            roles: Array<UserRole>;
            regions?: Array<string> | null | undefined;
            vacationStart?: Date | null | undefined;
            vacationEnd?: Date | null | undefined;
            profilePicture?:
              | { __typename?: 'File'; publicURL?: string | null | undefined }
              | null
              | undefined;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              middleName?: string | null | undefined;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              phoneNumbers?: Array<string> | null | undefined;
              emailAddresses?: Array<string> | null | undefined;
              primaryDescription?: string | null | undefined;
              nip?: string | null | undefined;
            };
          };
          employedInBranches?:
            | Array<{ __typename?: 'Branch'; id: number; name: string }>
            | null
            | undefined;
        };
      }>
    | null
    | undefined;
  supportingDoctors: Array<{
    __typename?: 'Doctor';
    id: number;
    createdAt?: Date | null | undefined;
    licenseNumber?: string | null | undefined;
    facility: boolean;
    stampImage?:
      | { __typename?: 'File'; id: number; uri: string }
      | null
      | undefined;
    user: {
      __typename?: 'User';
      id: number;
      active: boolean;
      login: string;
      roles: Array<UserRole>;
      regions?: Array<string> | null | undefined;
      vacationStart?: Date | null | undefined;
      vacationEnd?: Date | null | undefined;
      profilePicture?:
        | { __typename?: 'File'; publicURL?: string | null | undefined }
        | null
        | undefined;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        middleName?: string | null | undefined;
        lastName: string;
        companyName?: string | null | undefined;
        birthDate?: Date | null | undefined;
        phoneNumbers?: Array<string> | null | undefined;
        emailAddresses?: Array<string> | null | undefined;
        primaryDescription?: string | null | undefined;
        nip?: string | null | undefined;
      };
    };
    employedInBranches?:
      | Array<{ __typename?: 'Branch'; id: number; name: string }>
      | null
      | undefined;
  }>;
  performer?:
    | {
        __typename?: 'User';
        id: number;
        active: boolean;
        login: string;
        roles: Array<UserRole>;
        regions?: Array<string> | null | undefined;
        vacationStart?: Date | null | undefined;
        vacationEnd?: Date | null | undefined;
        profilePicture?:
          | { __typename?: 'File'; publicURL?: string | null | undefined }
          | null
          | undefined;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          middleName?: string | null | undefined;
          lastName: string;
          companyName?: string | null | undefined;
          birthDate?: Date | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          emailAddresses?: Array<string> | null | undefined;
          primaryDescription?: string | null | undefined;
          nip?: string | null | undefined;
        };
      }
    | null
    | undefined;
  files?:
    | Array<{
        __typename?: 'File';
        id: number;
        uri: string;
        fileType: FileType;
        mimeType?: string | null | undefined;
        createdAt?: Date | null | undefined;
        rejected: boolean;
        rejectionReason?: string | null | undefined;
      }>
    | null
    | undefined;
  branch?:
    | { __typename?: 'Branch'; id: number; name: string }
    | null
    | undefined;
  payments?:
    | Array<{
        __typename?: 'StudyPayment';
        createdAt?: Date | null | undefined;
        createdById?: number | null | undefined;
        id?: number | null | undefined;
        method: string;
        type: PaymentTypeEnum;
        value: number;
        discount?: number | null | undefined;
        group?: string | null | undefined;
        comment?: string | null | undefined;
      }>
    | null
    | undefined;
  radiology: {
    __typename?: 'Radiology';
    presetName?: string | null | undefined;
    anodeCurrentMiliamper: number;
    voltageKilovolts: number;
    exposureTimeSeconds: number;
    radiationDoseMilligrayPerSquareCentimeter: number;
  };
  updatedBy?:
    | {
        __typename?: 'User';
        id: number;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          lastName: string;
        };
      }
    | null
    | undefined;
  createdBy?:
    | {
        __typename?: 'User';
        id: number;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          lastName: string;
        };
      }
    | null
    | undefined;
};

export type ContactDataFragment = {
  __typename?: 'Contact';
  id: number;
  firstName: string;
  middleName?: string | null | undefined;
  lastName: string;
  companyName?: string | null | undefined;
  birthDate?: Date | null | undefined;
  phoneNumbers?: Array<string> | null | undefined;
  emailAddresses?: Array<string> | null | undefined;
  primaryDescription?: string | null | undefined;
  nip?: string | null | undefined;
};

export type UserDataFragment = {
  __typename?: 'User';
  id: number;
  active: boolean;
  login: string;
  roles: Array<UserRole>;
  regions?: Array<string> | null | undefined;
  vacationStart?: Date | null | undefined;
  vacationEnd?: Date | null | undefined;
  profilePicture?:
    | { __typename?: 'File'; publicURL?: string | null | undefined }
    | null
    | undefined;
  contact: {
    __typename?: 'Contact';
    id: number;
    firstName: string;
    middleName?: string | null | undefined;
    lastName: string;
    companyName?: string | null | undefined;
    birthDate?: Date | null | undefined;
    phoneNumbers?: Array<string> | null | undefined;
    emailAddresses?: Array<string> | null | undefined;
    primaryDescription?: string | null | undefined;
    nip?: string | null | undefined;
  };
};

export type AddComplaintMutationVariables = Exact<{
  newComplaint: ComplaintInput;
}>;

export type AddComplaintMutation = {
  __typename?: 'Mutation';
  createComplaint: {
    __typename?: 'Complaint';
    id: number;
    createdAt?: Date | null | undefined;
    complaintReason: string;
    complaintDescription: string;
    complaintResolution?: string | null | undefined;
    createdById?: number | null | undefined;
    updatedById?: number | null | undefined;
    complainantName: string;
    type: ComplaintTypeEnum;
    status: ComplaintStatusEnum;
    complainantPhoneNumber?: string | null | undefined;
    complainantEmailAddress?: string | null | undefined;
    dueDate: Date;
    study?:
      | {
          __typename?: 'Study';
          id: number;
          studyType: {
            __typename?: 'StudyType';
            id: number;
            code: string;
            name: string;
            onReferalOrder?: number | null | undefined;
            hasRemarks?: boolean | null | undefined;
            is3D: boolean;
            timeToDescribe?: number | null | undefined;
            comment?: string | null | undefined;
            descriptionPrice: number;
            urgentDescriptionPrice?: number | null | undefined;
            comparisonDescriptionPrice?: number | null | undefined;
            studySubTypes?: Array<string> | null | undefined;
            studyTypePrices: Array<{
              __typename?: 'StudyTypePrice';
              id?: number | null | undefined;
              price: number;
              presetName?: string | null | undefined;
            }>;
            studyTypeRadiologies: Array<{
              __typename?: 'StudyTypeRadiology';
              id?: number | null | undefined;
              radiology: {
                __typename?: 'Radiology';
                presetName?: string | null | undefined;
                anodeCurrentMiliamper: number;
                voltageKilovolts: number;
                exposureTimeSeconds: number;
                radiationDoseMilligrayPerSquareCentimeter: number;
              };
            }>;
            studyTypeDevices: Array<{
              __typename?: 'StudyTypeDevice';
              id?: number | null | undefined;
              durationMinutes: number;
              deviceType: string;
            }>;
          };
        }
      | null
      | undefined;
    patient?:
      | {
          __typename?: 'Patient';
          id: number;
          notes?: string | null | undefined;
          registrationDate: Date;
          createdById?: number | null | undefined;
          updatedById?: number | null | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            ssn?: string | null | undefined;
            gender?: Gender | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            secondaryDescription?: string | null | undefined;
            addresses?:
              | Array<
                  | {
                      __typename?: 'Address';
                      id: number;
                      street: string;
                      postalCode: string;
                      city: string;
                      country: string;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          };
        }
      | null
      | undefined;
    doctor?:
      | {
          __typename?: 'Doctor';
          id: number;
          createdAt?: Date | null | undefined;
          licenseNumber?: string | null | undefined;
          facility: boolean;
          stampImage?:
            | { __typename?: 'File'; id: number; uri: string }
            | null
            | undefined;
          user: {
            __typename?: 'User';
            id: number;
            active: boolean;
            login: string;
            roles: Array<UserRole>;
            regions?: Array<string> | null | undefined;
            vacationStart?: Date | null | undefined;
            vacationEnd?: Date | null | undefined;
            profilePicture?:
              | { __typename?: 'File'; publicURL?: string | null | undefined }
              | null
              | undefined;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              middleName?: string | null | undefined;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              phoneNumbers?: Array<string> | null | undefined;
              emailAddresses?: Array<string> | null | undefined;
              primaryDescription?: string | null | undefined;
              nip?: string | null | undefined;
            };
          };
          employedInBranches?:
            | Array<{ __typename?: 'Branch'; id: number; name: string }>
            | null
            | undefined;
        }
      | null
      | undefined;
    responsiblePerson?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
  };
};

export type UpdateComplaintMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  complaint: UpdateComplaintInput;
}>;

export type UpdateComplaintMutation = {
  __typename?: 'Mutation';
  updateComplaint: {
    __typename?: 'Complaint';
    id: number;
    createdAt?: Date | null | undefined;
    complaintReason: string;
    complaintDescription: string;
    complaintResolution?: string | null | undefined;
    createdById?: number | null | undefined;
    updatedById?: number | null | undefined;
    complainantName: string;
    type: ComplaintTypeEnum;
    status: ComplaintStatusEnum;
    complainantPhoneNumber?: string | null | undefined;
    complainantEmailAddress?: string | null | undefined;
    dueDate: Date;
    study?:
      | {
          __typename?: 'Study';
          id: number;
          studyType: {
            __typename?: 'StudyType';
            id: number;
            code: string;
            name: string;
            onReferalOrder?: number | null | undefined;
            hasRemarks?: boolean | null | undefined;
            is3D: boolean;
            timeToDescribe?: number | null | undefined;
            comment?: string | null | undefined;
            descriptionPrice: number;
            urgentDescriptionPrice?: number | null | undefined;
            comparisonDescriptionPrice?: number | null | undefined;
            studySubTypes?: Array<string> | null | undefined;
            studyTypePrices: Array<{
              __typename?: 'StudyTypePrice';
              id?: number | null | undefined;
              price: number;
              presetName?: string | null | undefined;
            }>;
            studyTypeRadiologies: Array<{
              __typename?: 'StudyTypeRadiology';
              id?: number | null | undefined;
              radiology: {
                __typename?: 'Radiology';
                presetName?: string | null | undefined;
                anodeCurrentMiliamper: number;
                voltageKilovolts: number;
                exposureTimeSeconds: number;
                radiationDoseMilligrayPerSquareCentimeter: number;
              };
            }>;
            studyTypeDevices: Array<{
              __typename?: 'StudyTypeDevice';
              id?: number | null | undefined;
              durationMinutes: number;
              deviceType: string;
            }>;
          };
        }
      | null
      | undefined;
    patient?:
      | {
          __typename?: 'Patient';
          id: number;
          notes?: string | null | undefined;
          registrationDate: Date;
          createdById?: number | null | undefined;
          updatedById?: number | null | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            ssn?: string | null | undefined;
            gender?: Gender | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            secondaryDescription?: string | null | undefined;
            addresses?:
              | Array<
                  | {
                      __typename?: 'Address';
                      id: number;
                      street: string;
                      postalCode: string;
                      city: string;
                      country: string;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          };
        }
      | null
      | undefined;
    doctor?:
      | {
          __typename?: 'Doctor';
          id: number;
          createdAt?: Date | null | undefined;
          licenseNumber?: string | null | undefined;
          facility: boolean;
          stampImage?:
            | { __typename?: 'File'; id: number; uri: string }
            | null
            | undefined;
          user: {
            __typename?: 'User';
            id: number;
            active: boolean;
            login: string;
            roles: Array<UserRole>;
            regions?: Array<string> | null | undefined;
            vacationStart?: Date | null | undefined;
            vacationEnd?: Date | null | undefined;
            profilePicture?:
              | { __typename?: 'File'; publicURL?: string | null | undefined }
              | null
              | undefined;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              middleName?: string | null | undefined;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              phoneNumbers?: Array<string> | null | undefined;
              emailAddresses?: Array<string> | null | undefined;
              primaryDescription?: string | null | undefined;
              nip?: string | null | undefined;
            };
          };
          employedInBranches?:
            | Array<{ __typename?: 'Branch'; id: number; name: string }>
            | null
            | undefined;
        }
      | null
      | undefined;
    responsiblePerson?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
  };
};

export type GetComplaintQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetComplaintQuery = {
  __typename?: 'Query';
  complaint?:
    | {
        __typename?: 'Complaint';
        id: number;
        createdAt?: Date | null | undefined;
        complaintReason: string;
        complaintDescription: string;
        complaintResolution?: string | null | undefined;
        createdById?: number | null | undefined;
        updatedById?: number | null | undefined;
        complainantName: string;
        type: ComplaintTypeEnum;
        status: ComplaintStatusEnum;
        complainantPhoneNumber?: string | null | undefined;
        complainantEmailAddress?: string | null | undefined;
        dueDate: Date;
        study?:
          | {
              __typename?: 'Study';
              id: number;
              studyType: {
                __typename?: 'StudyType';
                id: number;
                code: string;
                name: string;
                onReferalOrder?: number | null | undefined;
                hasRemarks?: boolean | null | undefined;
                is3D: boolean;
                timeToDescribe?: number | null | undefined;
                comment?: string | null | undefined;
                descriptionPrice: number;
                urgentDescriptionPrice?: number | null | undefined;
                comparisonDescriptionPrice?: number | null | undefined;
                studySubTypes?: Array<string> | null | undefined;
                studyTypePrices: Array<{
                  __typename?: 'StudyTypePrice';
                  id?: number | null | undefined;
                  price: number;
                  presetName?: string | null | undefined;
                }>;
                studyTypeRadiologies: Array<{
                  __typename?: 'StudyTypeRadiology';
                  id?: number | null | undefined;
                  radiology: {
                    __typename?: 'Radiology';
                    presetName?: string | null | undefined;
                    anodeCurrentMiliamper: number;
                    voltageKilovolts: number;
                    exposureTimeSeconds: number;
                    radiationDoseMilligrayPerSquareCentimeter: number;
                  };
                }>;
                studyTypeDevices: Array<{
                  __typename?: 'StudyTypeDevice';
                  id?: number | null | undefined;
                  durationMinutes: number;
                  deviceType: string;
                }>;
              };
            }
          | null
          | undefined;
        patient?:
          | {
              __typename?: 'Patient';
              id: number;
              notes?: string | null | undefined;
              registrationDate: Date;
              createdById?: number | null | undefined;
              updatedById?: number | null | undefined;
              contact: {
                __typename?: 'Contact';
                id: number;
                firstName: string;
                middleName?: string | null | undefined;
                lastName: string;
                companyName?: string | null | undefined;
                ssn?: string | null | undefined;
                gender?: Gender | null | undefined;
                birthDate?: Date | null | undefined;
                phoneNumbers?: Array<string> | null | undefined;
                emailAddresses?: Array<string> | null | undefined;
                primaryDescription?: string | null | undefined;
                secondaryDescription?: string | null | undefined;
                addresses?:
                  | Array<
                      | {
                          __typename?: 'Address';
                          id: number;
                          street: string;
                          postalCode: string;
                          city: string;
                          country: string;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
              };
            }
          | null
          | undefined;
        doctor?:
          | {
              __typename?: 'Doctor';
              id: number;
              createdAt?: Date | null | undefined;
              licenseNumber?: string | null | undefined;
              facility: boolean;
              stampImage?:
                | { __typename?: 'File'; id: number; uri: string }
                | null
                | undefined;
              user: {
                __typename?: 'User';
                id: number;
                active: boolean;
                login: string;
                roles: Array<UserRole>;
                regions?: Array<string> | null | undefined;
                vacationStart?: Date | null | undefined;
                vacationEnd?: Date | null | undefined;
                profilePicture?:
                  | {
                      __typename?: 'File';
                      publicURL?: string | null | undefined;
                    }
                  | null
                  | undefined;
                contact: {
                  __typename?: 'Contact';
                  id: number;
                  firstName: string;
                  middleName?: string | null | undefined;
                  lastName: string;
                  companyName?: string | null | undefined;
                  birthDate?: Date | null | undefined;
                  phoneNumbers?: Array<string> | null | undefined;
                  emailAddresses?: Array<string> | null | undefined;
                  primaryDescription?: string | null | undefined;
                  nip?: string | null | undefined;
                };
              };
              employedInBranches?:
                | Array<{ __typename?: 'Branch'; id: number; name: string }>
                | null
                | undefined;
            }
          | null
          | undefined;
        responsiblePerson?:
          | {
              __typename?: 'User';
              id: number;
              contact: {
                __typename?: 'Contact';
                id: number;
                firstName: string;
                lastName: string;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ComplaintDataFragment = {
  __typename?: 'Complaint';
  id: number;
  createdAt?: Date | null | undefined;
  complaintReason: string;
  complaintDescription: string;
  complaintResolution?: string | null | undefined;
  createdById?: number | null | undefined;
  updatedById?: number | null | undefined;
  complainantName: string;
  type: ComplaintTypeEnum;
  status: ComplaintStatusEnum;
  complainantPhoneNumber?: string | null | undefined;
  complainantEmailAddress?: string | null | undefined;
  dueDate: Date;
  study?:
    | {
        __typename?: 'Study';
        id: number;
        studyType: {
          __typename?: 'StudyType';
          id: number;
          code: string;
          name: string;
          onReferalOrder?: number | null | undefined;
          hasRemarks?: boolean | null | undefined;
          is3D: boolean;
          timeToDescribe?: number | null | undefined;
          comment?: string | null | undefined;
          descriptionPrice: number;
          urgentDescriptionPrice?: number | null | undefined;
          comparisonDescriptionPrice?: number | null | undefined;
          studySubTypes?: Array<string> | null | undefined;
          studyTypePrices: Array<{
            __typename?: 'StudyTypePrice';
            id?: number | null | undefined;
            price: number;
            presetName?: string | null | undefined;
          }>;
          studyTypeRadiologies: Array<{
            __typename?: 'StudyTypeRadiology';
            id?: number | null | undefined;
            radiology: {
              __typename?: 'Radiology';
              presetName?: string | null | undefined;
              anodeCurrentMiliamper: number;
              voltageKilovolts: number;
              exposureTimeSeconds: number;
              radiationDoseMilligrayPerSquareCentimeter: number;
            };
          }>;
          studyTypeDevices: Array<{
            __typename?: 'StudyTypeDevice';
            id?: number | null | undefined;
            durationMinutes: number;
            deviceType: string;
          }>;
        };
      }
    | null
    | undefined;
  patient?:
    | {
        __typename?: 'Patient';
        id: number;
        notes?: string | null | undefined;
        registrationDate: Date;
        createdById?: number | null | undefined;
        updatedById?: number | null | undefined;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          middleName?: string | null | undefined;
          lastName: string;
          companyName?: string | null | undefined;
          ssn?: string | null | undefined;
          gender?: Gender | null | undefined;
          birthDate?: Date | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          emailAddresses?: Array<string> | null | undefined;
          primaryDescription?: string | null | undefined;
          secondaryDescription?: string | null | undefined;
          addresses?:
            | Array<
                | {
                    __typename?: 'Address';
                    id: number;
                    street: string;
                    postalCode: string;
                    city: string;
                    country: string;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        };
      }
    | null
    | undefined;
  doctor?:
    | {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
  responsiblePerson?:
    | {
        __typename?: 'User';
        id: number;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          lastName: string;
        };
      }
    | null
    | undefined;
};

export type ComplaintsQueryVariables = Exact<{
  pagination: PaginationInput;
  where?: InputMaybe<ComplaintFiltersInput>;
  orderBy?: InputMaybe<Array<OrderColumn> | OrderColumn>;
}>;

export type ComplaintsQuery = {
  __typename?: 'Query';
  complaints: {
    __typename?: 'PaginatedComplaintResponse';
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: 'Complaint';
      id: number;
      createdAt?: Date | null | undefined;
      complaintReason: string;
      complaintDescription: string;
      complaintResolution?: string | null | undefined;
      createdById?: number | null | undefined;
      updatedById?: number | null | undefined;
      complainantName: string;
      type: ComplaintTypeEnum;
      status: ComplaintStatusEnum;
      complainantPhoneNumber?: string | null | undefined;
      complainantEmailAddress?: string | null | undefined;
      dueDate: Date;
      study?:
        | {
            __typename?: 'Study';
            id: number;
            studyType: {
              __typename?: 'StudyType';
              id: number;
              code: string;
              name: string;
              onReferalOrder?: number | null | undefined;
              hasRemarks?: boolean | null | undefined;
              is3D: boolean;
              timeToDescribe?: number | null | undefined;
              comment?: string | null | undefined;
              descriptionPrice: number;
              urgentDescriptionPrice?: number | null | undefined;
              comparisonDescriptionPrice?: number | null | undefined;
              studySubTypes?: Array<string> | null | undefined;
              studyTypePrices: Array<{
                __typename?: 'StudyTypePrice';
                id?: number | null | undefined;
                price: number;
                presetName?: string | null | undefined;
              }>;
              studyTypeRadiologies: Array<{
                __typename?: 'StudyTypeRadiology';
                id?: number | null | undefined;
                radiology: {
                  __typename?: 'Radiology';
                  presetName?: string | null | undefined;
                  anodeCurrentMiliamper: number;
                  voltageKilovolts: number;
                  exposureTimeSeconds: number;
                  radiationDoseMilligrayPerSquareCentimeter: number;
                };
              }>;
              studyTypeDevices: Array<{
                __typename?: 'StudyTypeDevice';
                id?: number | null | undefined;
                durationMinutes: number;
                deviceType: string;
              }>;
            };
          }
        | null
        | undefined;
      patient?:
        | {
            __typename?: 'Patient';
            id: number;
            notes?: string | null | undefined;
            registrationDate: Date;
            createdById?: number | null | undefined;
            updatedById?: number | null | undefined;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              middleName?: string | null | undefined;
              lastName: string;
              companyName?: string | null | undefined;
              ssn?: string | null | undefined;
              gender?: Gender | null | undefined;
              birthDate?: Date | null | undefined;
              phoneNumbers?: Array<string> | null | undefined;
              emailAddresses?: Array<string> | null | undefined;
              primaryDescription?: string | null | undefined;
              secondaryDescription?: string | null | undefined;
              addresses?:
                | Array<
                    | {
                        __typename?: 'Address';
                        id: number;
                        street: string;
                        postalCode: string;
                        city: string;
                        country: string;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            };
          }
        | null
        | undefined;
      doctor?:
        | {
            __typename?: 'Doctor';
            id: number;
            createdAt?: Date | null | undefined;
            licenseNumber?: string | null | undefined;
            facility: boolean;
            stampImage?:
              | { __typename?: 'File'; id: number; uri: string }
              | null
              | undefined;
            user: {
              __typename?: 'User';
              id: number;
              active: boolean;
              login: string;
              roles: Array<UserRole>;
              regions?: Array<string> | null | undefined;
              vacationStart?: Date | null | undefined;
              vacationEnd?: Date | null | undefined;
              profilePicture?:
                | { __typename?: 'File'; publicURL?: string | null | undefined }
                | null
                | undefined;
              contact: {
                __typename?: 'Contact';
                id: number;
                firstName: string;
                middleName?: string | null | undefined;
                lastName: string;
                companyName?: string | null | undefined;
                birthDate?: Date | null | undefined;
                phoneNumbers?: Array<string> | null | undefined;
                emailAddresses?: Array<string> | null | undefined;
                primaryDescription?: string | null | undefined;
                nip?: string | null | undefined;
              };
            };
            employedInBranches?:
              | Array<{ __typename?: 'Branch'; id: number; name: string }>
              | null
              | undefined;
          }
        | null
        | undefined;
      responsiblePerson?:
        | {
            __typename?: 'User';
            id: number;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              lastName: string;
            };
          }
        | null
        | undefined;
    }>;
  };
};

export type CouncilFragmentFragment = {
  __typename?: 'Council';
  id: number;
  name: string;
  createdAt?: Date | null | undefined;
  invitedMemberEmail?: Array<string> | null | undefined;
  councilMembers: Array<{
    __typename?: 'CouncilMember';
    owner: boolean;
    councilMemberDoctor: {
      __typename?: 'Doctor';
      id: number;
      createdAt?: Date | null | undefined;
      licenseNumber?: string | null | undefined;
      facility: boolean;
      stampImage?:
        | { __typename?: 'File'; id: number; uri: string }
        | null
        | undefined;
      user: {
        __typename?: 'User';
        id: number;
        active: boolean;
        login: string;
        roles: Array<UserRole>;
        regions?: Array<string> | null | undefined;
        vacationStart?: Date | null | undefined;
        vacationEnd?: Date | null | undefined;
        profilePicture?:
          | { __typename?: 'File'; publicURL?: string | null | undefined }
          | null
          | undefined;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          middleName?: string | null | undefined;
          lastName: string;
          companyName?: string | null | undefined;
          birthDate?: Date | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          emailAddresses?: Array<string> | null | undefined;
          primaryDescription?: string | null | undefined;
          nip?: string | null | undefined;
        };
      };
      employedInBranches?:
        | Array<{ __typename?: 'Branch'; id: number; name: string }>
        | null
        | undefined;
    };
  }>;
  studies?:
    | Array<{
        __typename?: 'Study';
        id: number;
        studyDate: Date;
        patient: {
          __typename?: 'Patient';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            ssn?: string | null | undefined;
          };
        };
        studyType: {
          __typename?: 'StudyType';
          id: number;
          code: string;
          name: string;
        };
      }>
    | null
    | undefined;
};

export type UserCouncilsQueryVariables = Exact<{
  userId: Scalars['Float']['input'];
}>;

export type UserCouncilsQuery = {
  __typename?: 'Query';
  getCouncils: Array<{
    __typename?: 'Council';
    id: number;
    name: string;
    createdAt?: Date | null | undefined;
    invitedMemberEmail?: Array<string> | null | undefined;
    councilMembers: Array<{
      __typename?: 'CouncilMember';
      owner: boolean;
      councilMemberDoctor: {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      };
    }>;
    studies?:
      | Array<{
          __typename?: 'Study';
          id: number;
          studyDate: Date;
          patient: {
            __typename?: 'Patient';
            id: number;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              ssn?: string | null | undefined;
            };
          };
          studyType: {
            __typename?: 'StudyType';
            id: number;
            code: string;
            name: string;
          };
        }>
      | null
      | undefined;
  }>;
};

export type MyCouncilsQueryVariables = Exact<{ [key: string]: never }>;

export type MyCouncilsQuery = {
  __typename?: 'Query';
  getMyCouncils: Array<{
    __typename?: 'Council';
    id: number;
    name: string;
    createdAt?: Date | null | undefined;
    invitedMemberEmail?: Array<string> | null | undefined;
    councilMembers: Array<{
      __typename?: 'CouncilMember';
      owner: boolean;
      councilMemberDoctor: {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      };
    }>;
    studies?:
      | Array<{
          __typename?: 'Study';
          id: number;
          studyDate: Date;
          patient: {
            __typename?: 'Patient';
            id: number;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              ssn?: string | null | undefined;
            };
          };
          studyType: {
            __typename?: 'StudyType';
            id: number;
            code: string;
            name: string;
          };
        }>
      | null
      | undefined;
  }>;
};

export type MyCouncilInvitesQueryVariables = Exact<{ [key: string]: never }>;

export type MyCouncilInvitesQuery = {
  __typename?: 'Query';
  getMyCouncilInvites: Array<{
    __typename?: 'Council';
    id: number;
    name: string;
    createdAt?: Date | null | undefined;
    invitedMemberEmail?: Array<string> | null | undefined;
    councilMembers: Array<{
      __typename?: 'CouncilMember';
      owner: boolean;
      councilMemberDoctor: {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      };
    }>;
    studies?:
      | Array<{
          __typename?: 'Study';
          id: number;
          studyDate: Date;
          patient: {
            __typename?: 'Patient';
            id: number;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              ssn?: string | null | undefined;
            };
          };
          studyType: {
            __typename?: 'StudyType';
            id: number;
            code: string;
            name: string;
          };
        }>
      | null
      | undefined;
  }>;
};

export type MyCouncilParticipationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MyCouncilParticipationsQuery = {
  __typename?: 'Query';
  getMyCouncilParticipations: Array<{
    __typename?: 'Council';
    id: number;
    name: string;
    createdAt?: Date | null | undefined;
    invitedMemberEmail?: Array<string> | null | undefined;
    councilMembers: Array<{
      __typename?: 'CouncilMember';
      owner: boolean;
      councilMemberDoctor: {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      };
    }>;
    studies?:
      | Array<{
          __typename?: 'Study';
          id: number;
          studyDate: Date;
          patient: {
            __typename?: 'Patient';
            id: number;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              ssn?: string | null | undefined;
            };
          };
          studyType: {
            __typename?: 'StudyType';
            id: number;
            code: string;
            name: string;
          };
        }>
      | null
      | undefined;
  }>;
};

export type CreateCouncilMutationVariables = Exact<{
  councilInput: CouncilInput;
}>;

export type CreateCouncilMutation = {
  __typename?: 'Mutation';
  createCouncil: { __typename?: 'Council'; id: number };
};

export type RemoveDoctorFromCouncilMutationVariables = Exact<{
  councilId?: InputMaybe<Scalars['Int']['input']>;
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  doctorEmail?: InputMaybe<Scalars['String']['input']>;
}>;

export type RemoveDoctorFromCouncilMutation = {
  __typename?: 'Mutation';
  removeDoctorFromCouncil: { __typename?: 'Council'; id: number };
};

export type InviteDoctorToCouncilMutationVariables = Exact<{
  councilId?: InputMaybe<Scalars['Int']['input']>;
  doctorEmail: Array<Scalars['String']['input']> | Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InviteDoctorToCouncilMutation = {
  __typename?: 'Mutation';
  inviteDoctorsToCouncil: { __typename?: 'Council'; id: number };
};

export type AcceptInviteToCouncilMutationVariables = Exact<{
  councilId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AcceptInviteToCouncilMutation = {
  __typename?: 'Mutation';
  acceptInviteToCouncil: { __typename?: 'Council'; id: number };
};

export type RejectInviteToCouncilMutationVariables = Exact<{
  councilId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RejectInviteToCouncilMutation = {
  __typename?: 'Mutation';
  rejectInviteToCouncil: { __typename?: 'Council'; id: number };
};

export type CouncilInviteAddedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CouncilInviteAddedSubscription = {
  __typename?: 'Subscription';
  councilInviteAdded: {
    __typename?: 'Council';
    id: number;
    name: string;
    createdAt?: Date | null | undefined;
    invitedMemberEmail?: Array<string> | null | undefined;
    councilMembers: Array<{
      __typename?: 'CouncilMember';
      owner: boolean;
      councilMemberDoctor: {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      };
    }>;
    studies?:
      | Array<{
          __typename?: 'Study';
          id: number;
          studyDate: Date;
          patient: {
            __typename?: 'Patient';
            id: number;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              ssn?: string | null | undefined;
            };
          };
          studyType: {
            __typename?: 'StudyType';
            id: number;
            code: string;
            name: string;
          };
        }>
      | null
      | undefined;
  };
};

export type CouncilInviteAcceptedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CouncilInviteAcceptedSubscription = {
  __typename?: 'Subscription';
  councilInviteAccepted: {
    __typename?: 'Council';
    id: number;
    name: string;
    createdAt?: Date | null | undefined;
    invitedMemberEmail?: Array<string> | null | undefined;
    councilMembers: Array<{
      __typename?: 'CouncilMember';
      owner: boolean;
      councilMemberDoctor: {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      };
    }>;
    studies?:
      | Array<{
          __typename?: 'Study';
          id: number;
          studyDate: Date;
          patient: {
            __typename?: 'Patient';
            id: number;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              ssn?: string | null | undefined;
            };
          };
          studyType: {
            __typename?: 'StudyType';
            id: number;
            code: string;
            name: string;
          };
        }>
      | null
      | undefined;
  };
};

export type CouncilInviteRejectedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CouncilInviteRejectedSubscription = {
  __typename?: 'Subscription';
  councilInviteRejected: {
    __typename?: 'Council';
    id: number;
    name: string;
    createdAt?: Date | null | undefined;
    invitedMemberEmail?: Array<string> | null | undefined;
    councilMembers: Array<{
      __typename?: 'CouncilMember';
      owner: boolean;
      councilMemberDoctor: {
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      };
    }>;
    studies?:
      | Array<{
          __typename?: 'Study';
          id: number;
          studyDate: Date;
          patient: {
            __typename?: 'Patient';
            id: number;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              ssn?: string | null | undefined;
            };
          };
          studyType: {
            __typename?: 'StudyType';
            id: number;
            code: string;
            name: string;
          };
        }>
      | null
      | undefined;
  };
};

export type AddDoctorMutationVariables = Exact<{
  data: NoUserDoctorInput;
}>;

export type AddDoctorMutation = {
  __typename?: 'Mutation';
  addDoctor: {
    __typename?: 'Doctor';
    createdAt?: Date | null | undefined;
    createdById?: number | null | undefined;
    licenseNumber?: string | null | undefined;
    user: {
      __typename?: 'User';
      contact: {
        __typename?: 'Contact';
        emailAddresses?: Array<string> | null | undefined;
        phoneNumbers?: Array<string> | null | undefined;
        primaryDescription?: string | null | undefined;
        firstName: string;
        lastName: string;
        gender?: Gender | null | undefined;
        addresses?:
          | Array<
              | {
                  __typename?: 'Address';
                  city: string;
                  country: string;
                  id: number;
                  postalCode: string;
                  street: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      };
    };
  };
};

export type UpdateDoctorMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  doctor: DoctorInput;
}>;

export type UpdateDoctorMutation = {
  __typename?: 'Mutation';
  updateDoctor: {
    __typename?: 'Doctor';
    id: number;
    createdAt?: Date | null | undefined;
    licenseNumber?: string | null | undefined;
    facility: boolean;
    stampImage?:
      | { __typename?: 'File'; id: number; uri: string }
      | null
      | undefined;
    user: {
      __typename?: 'User';
      id: number;
      active: boolean;
      login: string;
      roles: Array<UserRole>;
      regions?: Array<string> | null | undefined;
      vacationStart?: Date | null | undefined;
      vacationEnd?: Date | null | undefined;
      profilePicture?:
        | { __typename?: 'File'; publicURL?: string | null | undefined }
        | null
        | undefined;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        middleName?: string | null | undefined;
        lastName: string;
        companyName?: string | null | undefined;
        birthDate?: Date | null | undefined;
        phoneNumbers?: Array<string> | null | undefined;
        emailAddresses?: Array<string> | null | undefined;
        primaryDescription?: string | null | undefined;
        nip?: string | null | undefined;
      };
    };
    employedInBranches?:
      | Array<{ __typename?: 'Branch'; id: number; name: string }>
      | null
      | undefined;
  };
};

export type FullDoctorFragment = {
  __typename?: 'Doctor';
  id: number;
  createdAt?: Date | null | undefined;
  specializations?: Array<string> | null | undefined;
  licenseNumber?: string | null | undefined;
  facility: boolean;
  maxStudyHoursToDescribe?: number | null | undefined;
  stampImage?:
    | { __typename?: 'File'; id: number; uri: string }
    | null
    | undefined;
  describingStudyTypes?:
    | Array<{
        __typename?: 'StudyType';
        id: number;
        code: string;
        name: string;
        timeToDescribe?: number | null | undefined;
      }>
    | null
    | undefined;
  describerInfo?:
    | {
        __typename?: 'DescriberInfo';
        assignedStudies: number;
        totalAssignedStudies: number;
        descriptionOccupancyHours: number;
      }
    | null
    | undefined;
  user: {
    __typename?: 'User';
    id: number;
    active: boolean;
    login: string;
    roles: Array<UserRole>;
    createdAt: Date;
    updatedAt: Date;
    vacationStart?: Date | null | undefined;
    vacationEnd?: Date | null | undefined;
    regions?: Array<string> | null | undefined;
    profilePicture?:
      | { __typename?: 'File'; publicURL?: string | null | undefined }
      | null
      | undefined;
    contact: {
      __typename?: 'Contact';
      id: number;
      firstName: string;
      middleName?: string | null | undefined;
      lastName: string;
      companyName?: string | null | undefined;
      birthDate?: Date | null | undefined;
      phoneNumbers?: Array<string> | null | undefined;
      emailAddresses?: Array<string> | null | undefined;
      primaryDescription?: string | null | undefined;
      nip?: string | null | undefined;
      addresses?:
        | Array<
            | {
                __typename?: 'Address';
                id: number;
                street: string;
                city: string;
                postalCode: string;
                country: string;
              }
            | null
            | undefined
          >
        | null
        | undefined;
    };
    preferences?: { __typename?: 'Preferences'; mailNotifications: boolean };
  };
  employedInBranches?:
    | Array<{ __typename?: 'Branch'; id: number; name: string }>
    | null
    | undefined;
};

export type GetDoctorQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  withPreferences?: Scalars['Boolean']['input'];
}>;

export type GetDoctorQuery = {
  __typename?: 'Query';
  doctor: {
    __typename?: 'Doctor';
    id: number;
    createdAt?: Date | null | undefined;
    specializations?: Array<string> | null | undefined;
    licenseNumber?: string | null | undefined;
    facility: boolean;
    maxStudyHoursToDescribe?: number | null | undefined;
    statistics?:
      | {
          __typename?: 'DoctorStatisticsOutput';
          loginCount7Days: number;
          loginCount30Days: number;
          loginCount90Days: number;
          study2DCount7Days: number;
          study2DCount30Days: number;
          study2DCount90Days: number;
          study3DCount7Days: number;
          study3DCount30Days: number;
          study3DCount90Days: number;
          studiesValue?: number | null | undefined;
          studiesCount: number;
          lastLoginDate?: Date | null | undefined;
        }
      | null
      | undefined;
    stampImage?:
      | { __typename?: 'File'; id: number; uri: string }
      | null
      | undefined;
    describingStudyTypes?:
      | Array<{
          __typename?: 'StudyType';
          id: number;
          code: string;
          name: string;
          timeToDescribe?: number | null | undefined;
        }>
      | null
      | undefined;
    describerInfo?:
      | {
          __typename?: 'DescriberInfo';
          assignedStudies: number;
          totalAssignedStudies: number;
          descriptionOccupancyHours: number;
        }
      | null
      | undefined;
    user: {
      __typename?: 'User';
      id: number;
      active: boolean;
      login: string;
      roles: Array<UserRole>;
      createdAt: Date;
      updatedAt: Date;
      vacationStart?: Date | null | undefined;
      vacationEnd?: Date | null | undefined;
      regions?: Array<string> | null | undefined;
      profilePicture?:
        | { __typename?: 'File'; publicURL?: string | null | undefined }
        | null
        | undefined;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        middleName?: string | null | undefined;
        lastName: string;
        companyName?: string | null | undefined;
        birthDate?: Date | null | undefined;
        phoneNumbers?: Array<string> | null | undefined;
        emailAddresses?: Array<string> | null | undefined;
        primaryDescription?: string | null | undefined;
        nip?: string | null | undefined;
        addresses?:
          | Array<
              | {
                  __typename?: 'Address';
                  id: number;
                  street: string;
                  city: string;
                  postalCode: string;
                  country: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      };
      preferences?: { __typename?: 'Preferences'; mailNotifications: boolean };
    };
    employedInBranches?:
      | Array<{ __typename?: 'Branch'; id: number; name: string }>
      | null
      | undefined;
  };
};

export type DeleteDoctorMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  assignStudiesTo?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DeleteDoctorMutation = {
  __typename?: 'Mutation';
  deleteDoctor: number;
};

export type ActivateUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ActivateUserMutation = {
  __typename?: 'Mutation';
  activate: {
    __typename?: 'ActivationOutput';
    email: string;
    isActive: boolean;
  };
};

export type UpdateStampPictureMutationVariables = Exact<{
  updateStampPictureData: Scalars['String']['input'];
  doctorId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpdateStampPictureMutation = {
  __typename?: 'Mutation';
  updateStampPicture: { __typename?: 'File'; id: number; uri: string };
};

export type DeleteExistingStampPictureMutationVariables = Exact<{
  doctorId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DeleteExistingStampPictureMutation = {
  __typename?: 'Mutation';
  deleteExistingStampPicture: boolean;
};

export type DoctorsQueryVariables = Exact<{
  pagination: PaginationInput;
  where?: InputMaybe<DoctorFiltersInput>;
  orderBy?: InputMaybe<Array<OrderColumn> | OrderColumn>;
}>;

export type DoctorsQuery = {
  __typename?: 'Query';
  doctors: {
    __typename?: 'PaginatedDoctorResponse';
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: 'Doctor';
      id: number;
      createdAt?: Date | null | undefined;
      licenseNumber?: string | null | undefined;
      maxStudyHoursToDescribe?: number | null | undefined;
      specializations?: Array<string> | null | undefined;
      user: {
        __typename?: 'User';
        id: number;
        roles: Array<UserRole>;
        login: string;
        active: boolean;
        vacationStart?: Date | null | undefined;
        vacationEnd?: Date | null | undefined;
        lastLoginDate?: Date | null | undefined;
        loginAttempts?: number | null | undefined;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          lastName: string;
          companyName?: string | null | undefined;
          primaryDescription?: string | null | undefined;
        };
      };
      describerInfo?:
        | {
            __typename?: 'DescriberInfo';
            assignedStudies: number;
            descriptionOccupancyHours: number;
            totalAssignedStudies: number;
          }
        | null
        | undefined;
      employedInBranches?:
        | Array<{ __typename?: 'Branch'; id: number; name: string }>
        | null
        | undefined;
    }>;
  };
};

export type MyMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type MyMessagesQuery = {
  __typename?: 'Query';
  allMessages: Array<{
    __typename?: 'Message';
    id: number;
    linkedEntityId?: number | null | undefined;
    read: boolean;
    title: string;
    messageType: MessageType;
    createdAt: Date;
    important: boolean;
  }>;
};

export type GetMessageQueryVariables = Exact<{
  messageId: Scalars['Int']['input'];
}>;

export type GetMessageQuery = {
  __typename?: 'Query';
  message: {
    __typename?: 'Message';
    id: number;
    linkedEntityId?: number | null | undefined;
    read: boolean;
    title: string;
    content: string;
    messageType: MessageType;
    createdAt: Date;
    important: boolean;
  };
};

export type DeleteMessagesMutationVariables = Exact<{
  messageIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >;
  includeImportant?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteMessagesMutation = {
  __typename?: 'Mutation';
  deleteMessage: boolean;
};

export type MarkMessageAsReadMutationVariables = Exact<{
  messageIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >;
  includeImportant?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MarkMessageAsReadMutation = {
  __typename?: 'Mutation';
  markAsRead: number;
};

export type MessageAddedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MessageAddedSubscription = {
  __typename?: 'Subscription';
  messageAdded: {
    __typename?: 'Message';
    id: number;
    linkedEntityId?: number | null | undefined;
    read: boolean;
    title: string;
    messageType: MessageType;
    createdAt: Date;
    important: boolean;
    content: string;
  };
};

export type PatientStudiesMutationVariables = Exact<{
  socialSecurityNumber: Scalars['String']['input'];
}>;

export type PatientStudiesMutation = {
  __typename?: 'Mutation';
  patientStudies: {
    __typename?: 'PatientStudies';
    patientName: string;
    patientBirthDate?: Date | null | undefined;
    studies?:
      | Array<{
          __typename?: 'PatientStudy';
          id: number;
          studyDate: Date;
          studyFileURLs?: Array<string> | null | undefined;
          descriptionFileURLs?: Array<string> | null | undefined;
          studyImageFileURL?: string | null | undefined;
          studyType: string;
          is3D: boolean;
          branchName: string;
        }>
      | null
      | undefined;
  };
};

export type ResendPatientStudiesEmailMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendPatientStudiesEmailMutation = {
  __typename?: 'Mutation';
  resendStudiesLink: boolean;
};

export type SendPatientStudiesEmailMutationVariables = Exact<{
  ssn: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;

export type SendPatientStudiesEmailMutation = {
  __typename?: 'Mutation';
  sendStudiesLink: boolean;
};

export type AddPatientMutationVariables = Exact<{
  newPatient: PatientInput;
}>;

export type AddPatientMutation = {
  __typename?: 'Mutation';
  addPatient: {
    __typename?: 'Patient';
    id: number;
    notes?: string | null | undefined;
    registrationDate: Date;
    createdById?: number | null | undefined;
    updatedById?: number | null | undefined;
    contact: {
      __typename?: 'Contact';
      id: number;
      firstName: string;
      middleName?: string | null | undefined;
      lastName: string;
      companyName?: string | null | undefined;
      ssn?: string | null | undefined;
      gender?: Gender | null | undefined;
      birthDate?: Date | null | undefined;
      phoneNumbers?: Array<string> | null | undefined;
      emailAddresses?: Array<string> | null | undefined;
      primaryDescription?: string | null | undefined;
      secondaryDescription?: string | null | undefined;
      addresses?:
        | Array<
            | {
                __typename?: 'Address';
                id: number;
                street: string;
                postalCode: string;
                city: string;
                country: string;
              }
            | null
            | undefined
          >
        | null
        | undefined;
    };
  };
};

export type UpdatePatientMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  patient: PatientInput;
}>;

export type UpdatePatientMutation = {
  __typename?: 'Mutation';
  updatePatient: {
    __typename?: 'Patient';
    id: number;
    notes?: string | null | undefined;
    registrationDate: Date;
    createdById?: number | null | undefined;
    updatedById?: number | null | undefined;
    contact: {
      __typename?: 'Contact';
      id: number;
      firstName: string;
      middleName?: string | null | undefined;
      lastName: string;
      companyName?: string | null | undefined;
      ssn?: string | null | undefined;
      gender?: Gender | null | undefined;
      birthDate?: Date | null | undefined;
      phoneNumbers?: Array<string> | null | undefined;
      emailAddresses?: Array<string> | null | undefined;
      primaryDescription?: string | null | undefined;
      secondaryDescription?: string | null | undefined;
      addresses?:
        | Array<
            | {
                __typename?: 'Address';
                id: number;
                street: string;
                postalCode: string;
                city: string;
                country: string;
              }
            | null
            | undefined
          >
        | null
        | undefined;
    };
  };
};

export type GetPatientQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  withStudies?: Scalars['Boolean']['input'];
  withAppointments?: Scalars['Boolean']['input'];
}>;

export type GetPatientQuery = {
  __typename?: 'Query';
  patient: {
    __typename?: 'Patient';
    id: number;
    notes?: string | null | undefined;
    registrationDate: Date;
    createdById?: number | null | undefined;
    updatedById?: number | null | undefined;
    studies?: Array<{
      __typename?: 'Study';
      id: number;
      studyDate: Date;
      status: StudyStatus;
      hidden?: boolean | null | undefined;
      studyType: {
        __typename?: 'StudyType';
        id: number;
        code: string;
        name: string;
      };
    }>;
    appointments?: Array<{
      __typename?: 'Appointment';
      id: number;
      startDate: Date;
      endDate: Date;
      isBreak: boolean;
      comments?: string | null | undefined;
      studyType?:
        | { __typename?: 'StudyType'; id: number; name: string; is3D: boolean }
        | null
        | undefined;
      branch: { __typename?: 'Branch'; id: number };
      device: { __typename?: 'Device'; id: number; name: string };
      study?: { __typename?: 'Study'; id: number } | null | undefined;
      referal?:
        | { __typename?: 'Referal'; id: number; completed: boolean }
        | null
        | undefined;
      patient?:
        | {
            __typename?: 'Patient';
            id: number;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              lastName: string;
              birthDate?: Date | null | undefined;
              phoneNumbers?: Array<string> | null | undefined;
              emailAddresses?: Array<string> | null | undefined;
              primaryDescription?: string | null | undefined;
            };
          }
        | null
        | undefined;
    }>;
    contact: {
      __typename?: 'Contact';
      id: number;
      firstName: string;
      middleName?: string | null | undefined;
      lastName: string;
      companyName?: string | null | undefined;
      ssn?: string | null | undefined;
      gender?: Gender | null | undefined;
      birthDate?: Date | null | undefined;
      phoneNumbers?: Array<string> | null | undefined;
      emailAddresses?: Array<string> | null | undefined;
      primaryDescription?: string | null | undefined;
      secondaryDescription?: string | null | undefined;
      addresses?:
        | Array<
            | {
                __typename?: 'Address';
                id: number;
                street: string;
                postalCode: string;
                city: string;
                country: string;
              }
            | null
            | undefined
          >
        | null
        | undefined;
    };
  };
};

export type DeletePatientMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeletePatientMutation = {
  __typename?: 'Mutation';
  deletePatient: boolean;
};

export type SendPatientStudiesLinkMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type SendPatientStudiesLinkMutation = {
  __typename?: 'Mutation';
  sendPatientStudiesLink: boolean;
};

export type PatientStudyFragment = {
  __typename?: 'Study';
  id: number;
  studyDate: Date;
  status: StudyStatus;
  hidden?: boolean | null | undefined;
  studyType: {
    __typename?: 'StudyType';
    id: number;
    code: string;
    name: string;
  };
};

export type PatientsQueryVariables = Exact<{
  pagination: PaginationInput;
  where?: InputMaybe<PatientFiltersInput>;
  orderBy?: InputMaybe<Array<OrderColumn> | OrderColumn>;
  withStudies?: Scalars['Boolean']['input'];
}>;

export type PatientsQuery = {
  __typename?: 'Query';
  patients: {
    __typename?: 'PaginatedPatientResponse';
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: 'Patient';
      id: number;
      registrationDate: Date;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        lastName: string;
        birthDate?: Date | null | undefined;
        gender?: Gender | null | undefined;
        emailAddresses?: Array<string> | null | undefined;
        primaryDescription?: string | null | undefined;
        ssn?: string | null | undefined;
      };
      studies?: Array<{ __typename?: 'Study'; id: number }>;
    }>;
  };
};

export type AddReferalMutationVariables = Exact<{
  newReferal: ReferalInput;
}>;

export type AddReferalMutation = {
  __typename?: 'Mutation';
  addReferal: { __typename?: 'Referal'; id: number };
};

export type UpdateReferalMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  referal: ReferalInput;
}>;

export type UpdateReferalMutation = {
  __typename?: 'Mutation';
  updateReferal: {
    __typename?: 'Referal';
    id: number;
    completed: boolean;
    description?: string | null | undefined;
    remarks?: string | null | undefined;
    requestDescription: boolean;
    mediaTypes: Array<MediaType>;
    referalToStudyTypes: Array<{
      __typename?: 'ReferalToStudyType';
      id: number;
      remarks?: string | null | undefined;
      studySubTypes?: Array<string> | null | undefined;
      studyType: { __typename?: 'StudyType'; id: number; name: string };
    }>;
    issuer: {
      __typename?: 'Doctor';
      id: number;
      user: {
        __typename?: 'User';
        id: number;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          lastName: string;
          primaryDescription?: string | null | undefined;
        };
      };
    };
    patient: {
      __typename?: 'Patient';
      id: number;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        lastName: string;
        emailAddresses?: Array<string> | null | undefined;
        phoneNumbers?: Array<string> | null | undefined;
        birthDate?: Date | null | undefined;
        ssn?: string | null | undefined;
        gender?: Gender | null | undefined;
      };
    };
  };
};

export type GetReferalQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  withStudies?: Scalars['Boolean']['input'];
  withStudyTypePrices?: Scalars['Boolean']['input'];
}>;

export type GetReferalQuery = {
  __typename?: 'Query';
  referal: {
    __typename?: 'Referal';
    id: number;
    completed: boolean;
    description?: string | null | undefined;
    remarks?: string | null | undefined;
    requestDescription: boolean;
    mediaTypes: Array<MediaType>;
    referalToStudyTypes: Array<{
      __typename?: 'ReferalToStudyType';
      id: number;
      remarks?: string | null | undefined;
      studySubTypes?: Array<string> | null | undefined;
      studyType: {
        __typename?: 'StudyType';
        id: number;
        name: string;
        descriptionPrice?: number;
        studyTypePrices?: Array<{
          __typename?: 'StudyTypePrice';
          id?: number | null | undefined;
          price: number;
          presetName?: string | null | undefined;
        }>;
        studyTypeRadiologies?: Array<{
          __typename?: 'StudyTypeRadiology';
          radiology: {
            __typename?: 'Radiology';
            presetName?: string | null | undefined;
            anodeCurrentMiliamper: number;
            voltageKilovolts: number;
            exposureTimeSeconds: number;
            radiationDoseMilligrayPerSquareCentimeter: number;
          };
        }>;
      };
    }>;
    studies?:
      | Array<{
          __typename?: 'Study';
          id: number;
          studyType: { __typename?: 'StudyType'; id: number; name: string };
        }>
      | null
      | undefined;
    issuer: {
      __typename?: 'Doctor';
      id: number;
      user: {
        __typename?: 'User';
        id: number;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          lastName: string;
          primaryDescription?: string | null | undefined;
        };
      };
    };
    patient: {
      __typename?: 'Patient';
      id: number;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        lastName: string;
        emailAddresses?: Array<string> | null | undefined;
        phoneNumbers?: Array<string> | null | undefined;
        birthDate?: Date | null | undefined;
        ssn?: string | null | undefined;
        gender?: Gender | null | undefined;
      };
    };
  };
};

export type DeleteReferalMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteReferalMutation = {
  __typename?: 'Mutation';
  deleteReferal: boolean;
};

export type CompleteReferalMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type CompleteReferalMutation = {
  __typename?: 'Mutation';
  completeReferal: boolean;
};

export type ReferalsQueryVariables = Exact<{
  pagination: PaginationInput;
  where?: InputMaybe<ReferalFiltersInput>;
  orderBy?: InputMaybe<Array<OrderColumn> | OrderColumn>;
}>;

export type ReferalsQuery = {
  __typename?: 'Query';
  referals: {
    __typename?: 'PaginatedReferalResponse';
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: 'Referal';
      createdAt?: Date | null | undefined;
      id: number;
      completed: boolean;
      description?: string | null | undefined;
      remarks?: string | null | undefined;
      requestDescription: boolean;
      mediaTypes: Array<MediaType>;
      referalToStudyTypes: Array<{
        __typename?: 'ReferalToStudyType';
        id: number;
        remarks?: string | null | undefined;
        studySubTypes?: Array<string> | null | undefined;
        studyType: { __typename?: 'StudyType'; id: number; name: string };
      }>;
      issuer: {
        __typename?: 'Doctor';
        id: number;
        user: {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
            primaryDescription?: string | null | undefined;
          };
        };
      };
      patient: {
        __typename?: 'Patient';
        id: number;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          lastName: string;
          emailAddresses?: Array<string> | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          birthDate?: Date | null | undefined;
          ssn?: string | null | undefined;
          gender?: Gender | null | undefined;
        };
      };
    }>;
  };
};

export type StudyFinancialsDailyQueryVariables = Exact<{
  filter: StudyReportFiltersInput;
}>;

export type StudyFinancialsDailyQuery = {
  __typename?: 'Query';
  studiesFinancial: Array<{
    __typename?: 'StudyFinancialRow';
    count: number;
    is3D?: boolean | null | undefined;
    externalReferal?: boolean | null | undefined;
    studyDate: Date;
    sum?: number | null | undefined;
  }>;
};

export type StudiesByPaymentMethodQueryVariables = Exact<{
  filter: StudyReportFiltersInput;
}>;

export type StudiesByPaymentMethodQuery = {
  __typename?: 'Query';
  studiesGroupedByPaymentMethod: Array<{
    __typename?: 'StudyByPaymentMethodRow';
    paymentMethod?: string | null | undefined;
    count: number;
    sum: number;
  }>;
};

export type StudiesByBranchQueryVariables = Exact<{
  filter: StudyReportFiltersInput;
}>;

export type StudiesByBranchQuery = {
  __typename?: 'Query';
  studiesGroupedByBranch: Array<{
    __typename?: 'StudyByBranchRow';
    branchName?: string | null | undefined;
    count: number;
    studyCount: number;
    sum: number;
  }>;
};

export type DailyStudyPaymentsQueryVariables = Exact<{
  filter: StudyReportFiltersInput;
}>;

export type DailyStudyPaymentsQuery = {
  __typename?: 'Query';
  studiesDailyFinancial: Array<{
    __typename?: 'DailyStudyPaymentMethodRow';
    paymentMethod?: string | null | undefined;
    paymentType: PaymentTypeEnum;
    paymentValue: number;
    studyDate: Date;
    studyPaymentDate: Date;
    patientName: string;
    branchName: string;
    performerName: string;
  }>;
};

export type StudiesByStudyTypeQueryVariables = Exact<{
  filter: StudyReportFiltersInput;
}>;

export type StudiesByStudyTypeQuery = {
  __typename?: 'Query';
  studiesGroupedByStudyType: Array<{
    __typename?: 'StudyByStudyTypeRow';
    studyType?: string | null | undefined;
    count: number;
  }>;
};

export type StudiesByGenderQueryVariables = Exact<{
  filter: StudyReportFiltersInput;
}>;

export type StudiesByGenderQuery = {
  __typename?: 'Query';
  studiesGroupedByGender: Array<{
    __typename?: 'StudyByGenderRow';
    gender?: Gender | null | undefined;
    count: number;
  }>;
};

export type AddStudyMutationVariables = Exact<{
  newStudy: StudyInput;
}>;

export type AddStudyMutation = {
  __typename?: 'Mutation';
  addStudy: {
    __typename?: 'Study';
    id: number;
    studyDate: Date;
    status: StudyStatus;
    urgentDescription?: boolean | null | undefined;
    comparisonDescription?: boolean | null | undefined;
    createdById?: number | null | undefined;
    updatedById?: number | null | undefined;
    hidden?: boolean | null | undefined;
    incognitoDescription?: boolean | null | undefined;
    internalInformation?: string | null | undefined;
    importantInformation?: string | null | undefined;
    descriptionRequestComment?: string | null | undefined;
    notifyLeaderAndSupportingDoctors?: boolean | null | undefined;
    notifyDoctorsAboutDescription?: boolean | null | undefined;
    notifyDescribers?: boolean | null | undefined;
    externalReferal?: boolean | null | undefined;
    missingDescription?: boolean | null | undefined;
    unassignedDescriber?: boolean | null | undefined;
    hasDescription?: boolean | null | undefined;
    createdAt?: Date | null | undefined;
    updatedAt?: Date | null | undefined;
    studyType: {
      __typename?: 'StudyType';
      id: number;
      code: string;
      name: string;
      timeToDescribe?: number | null | undefined;
      descriptionPrice: number;
      urgentDescriptionPrice?: number | null | undefined;
      comparisonDescriptionPrice?: number | null | undefined;
      studyTypePrices: Array<{
        __typename?: 'StudyTypePrice';
        id?: number | null | undefined;
        price: number;
        presetName?: string | null | undefined;
      }>;
      studyTypeRadiologies: Array<{
        __typename?: 'StudyTypeRadiology';
        radiology: {
          __typename?: 'Radiology';
          presetName?: string | null | undefined;
          anodeCurrentMiliamper: number;
          voltageKilovolts: number;
          exposureTimeSeconds: number;
          radiationDoseMilligrayPerSquareCentimeter: number;
        };
      }>;
    };
    patient: {
      __typename?: 'Patient';
      id: number;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        lastName: string;
        birthDate?: Date | null | undefined;
        ssn?: string | null | undefined;
      };
      studies: Array<{ __typename?: 'Study'; id: number }>;
    };
    leadingDoctor?:
      | {
          __typename?: 'Doctor';
          id: number;
          createdAt?: Date | null | undefined;
          licenseNumber?: string | null | undefined;
          facility: boolean;
          stampImage?:
            | { __typename?: 'File'; id: number; uri: string }
            | null
            | undefined;
          user: {
            __typename?: 'User';
            id: number;
            active: boolean;
            login: string;
            roles: Array<UserRole>;
            regions?: Array<string> | null | undefined;
            vacationStart?: Date | null | undefined;
            vacationEnd?: Date | null | undefined;
            profilePicture?:
              | { __typename?: 'File'; publicURL?: string | null | undefined }
              | null
              | undefined;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              middleName?: string | null | undefined;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              phoneNumbers?: Array<string> | null | undefined;
              emailAddresses?: Array<string> | null | undefined;
              primaryDescription?: string | null | undefined;
              nip?: string | null | undefined;
            };
          };
          employedInBranches?:
            | Array<{ __typename?: 'Branch'; id: number; name: string }>
            | null
            | undefined;
        }
      | null
      | undefined;
    leadingDoctorBranch?:
      | { __typename?: 'Branch'; id: number; name: string }
      | null
      | undefined;
    describers?:
      | Array<{
          __typename?: 'StudyDescriber';
          id: number;
          stat: boolean;
          dueDate?: Date | null | undefined;
          doctor: {
            __typename?: 'Doctor';
            id: number;
            createdAt?: Date | null | undefined;
            licenseNumber?: string | null | undefined;
            facility: boolean;
            stampImage?:
              | { __typename?: 'File'; id: number; uri: string }
              | null
              | undefined;
            user: {
              __typename?: 'User';
              id: number;
              active: boolean;
              login: string;
              roles: Array<UserRole>;
              regions?: Array<string> | null | undefined;
              vacationStart?: Date | null | undefined;
              vacationEnd?: Date | null | undefined;
              profilePicture?:
                | { __typename?: 'File'; publicURL?: string | null | undefined }
                | null
                | undefined;
              contact: {
                __typename?: 'Contact';
                id: number;
                firstName: string;
                middleName?: string | null | undefined;
                lastName: string;
                companyName?: string | null | undefined;
                birthDate?: Date | null | undefined;
                phoneNumbers?: Array<string> | null | undefined;
                emailAddresses?: Array<string> | null | undefined;
                primaryDescription?: string | null | undefined;
                nip?: string | null | undefined;
              };
            };
            employedInBranches?:
              | Array<{ __typename?: 'Branch'; id: number; name: string }>
              | null
              | undefined;
          };
        }>
      | null
      | undefined;
    supportingDoctors: Array<{
      __typename?: 'Doctor';
      id: number;
      createdAt?: Date | null | undefined;
      licenseNumber?: string | null | undefined;
      facility: boolean;
      stampImage?:
        | { __typename?: 'File'; id: number; uri: string }
        | null
        | undefined;
      user: {
        __typename?: 'User';
        id: number;
        active: boolean;
        login: string;
        roles: Array<UserRole>;
        regions?: Array<string> | null | undefined;
        vacationStart?: Date | null | undefined;
        vacationEnd?: Date | null | undefined;
        profilePicture?:
          | { __typename?: 'File'; publicURL?: string | null | undefined }
          | null
          | undefined;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          middleName?: string | null | undefined;
          lastName: string;
          companyName?: string | null | undefined;
          birthDate?: Date | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          emailAddresses?: Array<string> | null | undefined;
          primaryDescription?: string | null | undefined;
          nip?: string | null | undefined;
        };
      };
      employedInBranches?:
        | Array<{ __typename?: 'Branch'; id: number; name: string }>
        | null
        | undefined;
    }>;
    performer?:
      | {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        }
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: number;
          uri: string;
          fileType: FileType;
          mimeType?: string | null | undefined;
          createdAt?: Date | null | undefined;
          rejected: boolean;
          rejectionReason?: string | null | undefined;
        }>
      | null
      | undefined;
    branch?:
      | { __typename?: 'Branch'; id: number; name: string }
      | null
      | undefined;
    payments?:
      | Array<{
          __typename?: 'StudyPayment';
          createdAt?: Date | null | undefined;
          createdById?: number | null | undefined;
          id?: number | null | undefined;
          method: string;
          type: PaymentTypeEnum;
          value: number;
          discount?: number | null | undefined;
          group?: string | null | undefined;
          comment?: string | null | undefined;
        }>
      | null
      | undefined;
    radiology: {
      __typename?: 'Radiology';
      presetName?: string | null | undefined;
      anodeCurrentMiliamper: number;
      voltageKilovolts: number;
      exposureTimeSeconds: number;
      radiationDoseMilligrayPerSquareCentimeter: number;
    };
    updatedBy?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
    createdBy?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
  };
};

export type UpdateStudyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  study: StudyInput;
}>;

export type UpdateStudyMutation = {
  __typename?: 'Mutation';
  updateStudy: {
    __typename?: 'Study';
    id: number;
    studyDate: Date;
    status: StudyStatus;
    urgentDescription?: boolean | null | undefined;
    comparisonDescription?: boolean | null | undefined;
    createdById?: number | null | undefined;
    updatedById?: number | null | undefined;
    hidden?: boolean | null | undefined;
    incognitoDescription?: boolean | null | undefined;
    internalInformation?: string | null | undefined;
    importantInformation?: string | null | undefined;
    descriptionRequestComment?: string | null | undefined;
    notifyLeaderAndSupportingDoctors?: boolean | null | undefined;
    notifyDoctorsAboutDescription?: boolean | null | undefined;
    notifyDescribers?: boolean | null | undefined;
    externalReferal?: boolean | null | undefined;
    missingDescription?: boolean | null | undefined;
    unassignedDescriber?: boolean | null | undefined;
    hasDescription?: boolean | null | undefined;
    createdAt?: Date | null | undefined;
    updatedAt?: Date | null | undefined;
    studyType: {
      __typename?: 'StudyType';
      id: number;
      code: string;
      name: string;
      timeToDescribe?: number | null | undefined;
      descriptionPrice: number;
      urgentDescriptionPrice?: number | null | undefined;
      comparisonDescriptionPrice?: number | null | undefined;
      studyTypePrices: Array<{
        __typename?: 'StudyTypePrice';
        id?: number | null | undefined;
        price: number;
        presetName?: string | null | undefined;
      }>;
      studyTypeRadiologies: Array<{
        __typename?: 'StudyTypeRadiology';
        radiology: {
          __typename?: 'Radiology';
          presetName?: string | null | undefined;
          anodeCurrentMiliamper: number;
          voltageKilovolts: number;
          exposureTimeSeconds: number;
          radiationDoseMilligrayPerSquareCentimeter: number;
        };
      }>;
    };
    patient: {
      __typename?: 'Patient';
      id: number;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        lastName: string;
        birthDate?: Date | null | undefined;
        ssn?: string | null | undefined;
      };
      studies: Array<{ __typename?: 'Study'; id: number }>;
    };
    leadingDoctor?:
      | {
          __typename?: 'Doctor';
          id: number;
          createdAt?: Date | null | undefined;
          licenseNumber?: string | null | undefined;
          facility: boolean;
          stampImage?:
            | { __typename?: 'File'; id: number; uri: string }
            | null
            | undefined;
          user: {
            __typename?: 'User';
            id: number;
            active: boolean;
            login: string;
            roles: Array<UserRole>;
            regions?: Array<string> | null | undefined;
            vacationStart?: Date | null | undefined;
            vacationEnd?: Date | null | undefined;
            profilePicture?:
              | { __typename?: 'File'; publicURL?: string | null | undefined }
              | null
              | undefined;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              middleName?: string | null | undefined;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              phoneNumbers?: Array<string> | null | undefined;
              emailAddresses?: Array<string> | null | undefined;
              primaryDescription?: string | null | undefined;
              nip?: string | null | undefined;
            };
          };
          employedInBranches?:
            | Array<{ __typename?: 'Branch'; id: number; name: string }>
            | null
            | undefined;
        }
      | null
      | undefined;
    leadingDoctorBranch?:
      | { __typename?: 'Branch'; id: number; name: string }
      | null
      | undefined;
    describers?:
      | Array<{
          __typename?: 'StudyDescriber';
          id: number;
          stat: boolean;
          dueDate?: Date | null | undefined;
          doctor: {
            __typename?: 'Doctor';
            id: number;
            createdAt?: Date | null | undefined;
            licenseNumber?: string | null | undefined;
            facility: boolean;
            stampImage?:
              | { __typename?: 'File'; id: number; uri: string }
              | null
              | undefined;
            user: {
              __typename?: 'User';
              id: number;
              active: boolean;
              login: string;
              roles: Array<UserRole>;
              regions?: Array<string> | null | undefined;
              vacationStart?: Date | null | undefined;
              vacationEnd?: Date | null | undefined;
              profilePicture?:
                | { __typename?: 'File'; publicURL?: string | null | undefined }
                | null
                | undefined;
              contact: {
                __typename?: 'Contact';
                id: number;
                firstName: string;
                middleName?: string | null | undefined;
                lastName: string;
                companyName?: string | null | undefined;
                birthDate?: Date | null | undefined;
                phoneNumbers?: Array<string> | null | undefined;
                emailAddresses?: Array<string> | null | undefined;
                primaryDescription?: string | null | undefined;
                nip?: string | null | undefined;
              };
            };
            employedInBranches?:
              | Array<{ __typename?: 'Branch'; id: number; name: string }>
              | null
              | undefined;
          };
        }>
      | null
      | undefined;
    supportingDoctors: Array<{
      __typename?: 'Doctor';
      id: number;
      createdAt?: Date | null | undefined;
      licenseNumber?: string | null | undefined;
      facility: boolean;
      stampImage?:
        | { __typename?: 'File'; id: number; uri: string }
        | null
        | undefined;
      user: {
        __typename?: 'User';
        id: number;
        active: boolean;
        login: string;
        roles: Array<UserRole>;
        regions?: Array<string> | null | undefined;
        vacationStart?: Date | null | undefined;
        vacationEnd?: Date | null | undefined;
        profilePicture?:
          | { __typename?: 'File'; publicURL?: string | null | undefined }
          | null
          | undefined;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          middleName?: string | null | undefined;
          lastName: string;
          companyName?: string | null | undefined;
          birthDate?: Date | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          emailAddresses?: Array<string> | null | undefined;
          primaryDescription?: string | null | undefined;
          nip?: string | null | undefined;
        };
      };
      employedInBranches?:
        | Array<{ __typename?: 'Branch'; id: number; name: string }>
        | null
        | undefined;
    }>;
    performer?:
      | {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        }
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: number;
          uri: string;
          fileType: FileType;
          mimeType?: string | null | undefined;
          createdAt?: Date | null | undefined;
          rejected: boolean;
          rejectionReason?: string | null | undefined;
        }>
      | null
      | undefined;
    branch?:
      | { __typename?: 'Branch'; id: number; name: string }
      | null
      | undefined;
    payments?:
      | Array<{
          __typename?: 'StudyPayment';
          createdAt?: Date | null | undefined;
          createdById?: number | null | undefined;
          id?: number | null | undefined;
          method: string;
          type: PaymentTypeEnum;
          value: number;
          discount?: number | null | undefined;
          group?: string | null | undefined;
          comment?: string | null | undefined;
        }>
      | null
      | undefined;
    radiology: {
      __typename?: 'Radiology';
      presetName?: string | null | undefined;
      anodeCurrentMiliamper: number;
      voltageKilovolts: number;
      exposureTimeSeconds: number;
      radiationDoseMilligrayPerSquareCentimeter: number;
    };
    updatedBy?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
    createdBy?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
  };
};

export type FileDescriptionRequestMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  descriptionRequestComment?: InputMaybe<Scalars['String']['input']>;
}>;

export type FileDescriptionRequestMutation = {
  __typename?: 'Mutation';
  fileDescriptionRequest: {
    __typename?: 'Study';
    id: number;
    studyDate: Date;
    status: StudyStatus;
    urgentDescription?: boolean | null | undefined;
    comparisonDescription?: boolean | null | undefined;
    createdById?: number | null | undefined;
    updatedById?: number | null | undefined;
    hidden?: boolean | null | undefined;
    incognitoDescription?: boolean | null | undefined;
    internalInformation?: string | null | undefined;
    importantInformation?: string | null | undefined;
    descriptionRequestComment?: string | null | undefined;
    notifyLeaderAndSupportingDoctors?: boolean | null | undefined;
    notifyDoctorsAboutDescription?: boolean | null | undefined;
    notifyDescribers?: boolean | null | undefined;
    externalReferal?: boolean | null | undefined;
    missingDescription?: boolean | null | undefined;
    unassignedDescriber?: boolean | null | undefined;
    hasDescription?: boolean | null | undefined;
    createdAt?: Date | null | undefined;
    updatedAt?: Date | null | undefined;
    studyType: {
      __typename?: 'StudyType';
      id: number;
      code: string;
      name: string;
      timeToDescribe?: number | null | undefined;
      descriptionPrice: number;
      urgentDescriptionPrice?: number | null | undefined;
      comparisonDescriptionPrice?: number | null | undefined;
      studyTypePrices: Array<{
        __typename?: 'StudyTypePrice';
        id?: number | null | undefined;
        price: number;
        presetName?: string | null | undefined;
      }>;
      studyTypeRadiologies: Array<{
        __typename?: 'StudyTypeRadiology';
        radiology: {
          __typename?: 'Radiology';
          presetName?: string | null | undefined;
          anodeCurrentMiliamper: number;
          voltageKilovolts: number;
          exposureTimeSeconds: number;
          radiationDoseMilligrayPerSquareCentimeter: number;
        };
      }>;
    };
    patient: {
      __typename?: 'Patient';
      id: number;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        lastName: string;
        birthDate?: Date | null | undefined;
        ssn?: string | null | undefined;
      };
      studies: Array<{ __typename?: 'Study'; id: number }>;
    };
    leadingDoctor?:
      | {
          __typename?: 'Doctor';
          id: number;
          createdAt?: Date | null | undefined;
          licenseNumber?: string | null | undefined;
          facility: boolean;
          stampImage?:
            | { __typename?: 'File'; id: number; uri: string }
            | null
            | undefined;
          user: {
            __typename?: 'User';
            id: number;
            active: boolean;
            login: string;
            roles: Array<UserRole>;
            regions?: Array<string> | null | undefined;
            vacationStart?: Date | null | undefined;
            vacationEnd?: Date | null | undefined;
            profilePicture?:
              | { __typename?: 'File'; publicURL?: string | null | undefined }
              | null
              | undefined;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              middleName?: string | null | undefined;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              phoneNumbers?: Array<string> | null | undefined;
              emailAddresses?: Array<string> | null | undefined;
              primaryDescription?: string | null | undefined;
              nip?: string | null | undefined;
            };
          };
          employedInBranches?:
            | Array<{ __typename?: 'Branch'; id: number; name: string }>
            | null
            | undefined;
        }
      | null
      | undefined;
    leadingDoctorBranch?:
      | { __typename?: 'Branch'; id: number; name: string }
      | null
      | undefined;
    describers?:
      | Array<{
          __typename?: 'StudyDescriber';
          id: number;
          stat: boolean;
          dueDate?: Date | null | undefined;
          doctor: {
            __typename?: 'Doctor';
            id: number;
            createdAt?: Date | null | undefined;
            licenseNumber?: string | null | undefined;
            facility: boolean;
            stampImage?:
              | { __typename?: 'File'; id: number; uri: string }
              | null
              | undefined;
            user: {
              __typename?: 'User';
              id: number;
              active: boolean;
              login: string;
              roles: Array<UserRole>;
              regions?: Array<string> | null | undefined;
              vacationStart?: Date | null | undefined;
              vacationEnd?: Date | null | undefined;
              profilePicture?:
                | { __typename?: 'File'; publicURL?: string | null | undefined }
                | null
                | undefined;
              contact: {
                __typename?: 'Contact';
                id: number;
                firstName: string;
                middleName?: string | null | undefined;
                lastName: string;
                companyName?: string | null | undefined;
                birthDate?: Date | null | undefined;
                phoneNumbers?: Array<string> | null | undefined;
                emailAddresses?: Array<string> | null | undefined;
                primaryDescription?: string | null | undefined;
                nip?: string | null | undefined;
              };
            };
            employedInBranches?:
              | Array<{ __typename?: 'Branch'; id: number; name: string }>
              | null
              | undefined;
          };
        }>
      | null
      | undefined;
    supportingDoctors: Array<{
      __typename?: 'Doctor';
      id: number;
      createdAt?: Date | null | undefined;
      licenseNumber?: string | null | undefined;
      facility: boolean;
      stampImage?:
        | { __typename?: 'File'; id: number; uri: string }
        | null
        | undefined;
      user: {
        __typename?: 'User';
        id: number;
        active: boolean;
        login: string;
        roles: Array<UserRole>;
        regions?: Array<string> | null | undefined;
        vacationStart?: Date | null | undefined;
        vacationEnd?: Date | null | undefined;
        profilePicture?:
          | { __typename?: 'File'; publicURL?: string | null | undefined }
          | null
          | undefined;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          middleName?: string | null | undefined;
          lastName: string;
          companyName?: string | null | undefined;
          birthDate?: Date | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          emailAddresses?: Array<string> | null | undefined;
          primaryDescription?: string | null | undefined;
          nip?: string | null | undefined;
        };
      };
      employedInBranches?:
        | Array<{ __typename?: 'Branch'; id: number; name: string }>
        | null
        | undefined;
    }>;
    performer?:
      | {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        }
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: number;
          uri: string;
          fileType: FileType;
          mimeType?: string | null | undefined;
          createdAt?: Date | null | undefined;
          rejected: boolean;
          rejectionReason?: string | null | undefined;
        }>
      | null
      | undefined;
    branch?:
      | { __typename?: 'Branch'; id: number; name: string }
      | null
      | undefined;
    payments?:
      | Array<{
          __typename?: 'StudyPayment';
          createdAt?: Date | null | undefined;
          createdById?: number | null | undefined;
          id?: number | null | undefined;
          method: string;
          type: PaymentTypeEnum;
          value: number;
          discount?: number | null | undefined;
          group?: string | null | undefined;
          comment?: string | null | undefined;
        }>
      | null
      | undefined;
    radiology: {
      __typename?: 'Radiology';
      presetName?: string | null | undefined;
      anodeCurrentMiliamper: number;
      voltageKilovolts: number;
      exposureTimeSeconds: number;
      radiationDoseMilligrayPerSquareCentimeter: number;
    };
    updatedBy?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
    createdBy?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
  };
};

export type UpdateDescriptionDateMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  doctorId: Scalars['Int']['input'];
  newDate: Scalars['DateTime']['input'];
}>;

export type UpdateDescriptionDateMutation = {
  __typename?: 'Mutation';
  updateStudyDescriptionDate: {
    __typename?: 'StudyDescriber';
    id: number;
    stat: boolean;
    dueDate?: Date | null | undefined;
    doctor: {
      __typename?: 'Doctor';
      id: number;
      createdAt?: Date | null | undefined;
      licenseNumber?: string | null | undefined;
      facility: boolean;
      stampImage?:
        | { __typename?: 'File'; id: number; uri: string }
        | null
        | undefined;
      user: {
        __typename?: 'User';
        id: number;
        active: boolean;
        login: string;
        roles: Array<UserRole>;
        regions?: Array<string> | null | undefined;
        vacationStart?: Date | null | undefined;
        vacationEnd?: Date | null | undefined;
        profilePicture?:
          | { __typename?: 'File'; publicURL?: string | null | undefined }
          | null
          | undefined;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          middleName?: string | null | undefined;
          lastName: string;
          companyName?: string | null | undefined;
          birthDate?: Date | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          emailAddresses?: Array<string> | null | undefined;
          primaryDescription?: string | null | undefined;
          nip?: string | null | undefined;
        };
      };
      employedInBranches?:
        | Array<{ __typename?: 'Branch'; id: number; name: string }>
        | null
        | undefined;
    };
  };
};

export type GetStudyQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetStudyQuery = {
  __typename?: 'Query';
  study: {
    __typename?: 'Study';
    id: number;
    studyDate: Date;
    status: StudyStatus;
    urgentDescription?: boolean | null | undefined;
    comparisonDescription?: boolean | null | undefined;
    createdById?: number | null | undefined;
    updatedById?: number | null | undefined;
    hidden?: boolean | null | undefined;
    incognitoDescription?: boolean | null | undefined;
    internalInformation?: string | null | undefined;
    importantInformation?: string | null | undefined;
    descriptionRequestComment?: string | null | undefined;
    notifyLeaderAndSupportingDoctors?: boolean | null | undefined;
    notifyDoctorsAboutDescription?: boolean | null | undefined;
    notifyDescribers?: boolean | null | undefined;
    externalReferal?: boolean | null | undefined;
    missingDescription?: boolean | null | undefined;
    unassignedDescriber?: boolean | null | undefined;
    hasDescription?: boolean | null | undefined;
    createdAt?: Date | null | undefined;
    updatedAt?: Date | null | undefined;
    studyType: {
      __typename?: 'StudyType';
      id: number;
      code: string;
      name: string;
      timeToDescribe?: number | null | undefined;
      descriptionPrice: number;
      urgentDescriptionPrice?: number | null | undefined;
      comparisonDescriptionPrice?: number | null | undefined;
      studyTypePrices: Array<{
        __typename?: 'StudyTypePrice';
        id?: number | null | undefined;
        price: number;
        presetName?: string | null | undefined;
      }>;
      studyTypeRadiologies: Array<{
        __typename?: 'StudyTypeRadiology';
        radiology: {
          __typename?: 'Radiology';
          presetName?: string | null | undefined;
          anodeCurrentMiliamper: number;
          voltageKilovolts: number;
          exposureTimeSeconds: number;
          radiationDoseMilligrayPerSquareCentimeter: number;
        };
      }>;
    };
    patient: {
      __typename?: 'Patient';
      id: number;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        lastName: string;
        birthDate?: Date | null | undefined;
        ssn?: string | null | undefined;
      };
      studies: Array<{ __typename?: 'Study'; id: number }>;
    };
    leadingDoctor?:
      | {
          __typename?: 'Doctor';
          id: number;
          createdAt?: Date | null | undefined;
          licenseNumber?: string | null | undefined;
          facility: boolean;
          stampImage?:
            | { __typename?: 'File'; id: number; uri: string }
            | null
            | undefined;
          user: {
            __typename?: 'User';
            id: number;
            active: boolean;
            login: string;
            roles: Array<UserRole>;
            regions?: Array<string> | null | undefined;
            vacationStart?: Date | null | undefined;
            vacationEnd?: Date | null | undefined;
            profilePicture?:
              | { __typename?: 'File'; publicURL?: string | null | undefined }
              | null
              | undefined;
            contact: {
              __typename?: 'Contact';
              id: number;
              firstName: string;
              middleName?: string | null | undefined;
              lastName: string;
              companyName?: string | null | undefined;
              birthDate?: Date | null | undefined;
              phoneNumbers?: Array<string> | null | undefined;
              emailAddresses?: Array<string> | null | undefined;
              primaryDescription?: string | null | undefined;
              nip?: string | null | undefined;
            };
          };
          employedInBranches?:
            | Array<{ __typename?: 'Branch'; id: number; name: string }>
            | null
            | undefined;
        }
      | null
      | undefined;
    leadingDoctorBranch?:
      | { __typename?: 'Branch'; id: number; name: string }
      | null
      | undefined;
    describers?:
      | Array<{
          __typename?: 'StudyDescriber';
          id: number;
          stat: boolean;
          dueDate?: Date | null | undefined;
          doctor: {
            __typename?: 'Doctor';
            id: number;
            createdAt?: Date | null | undefined;
            licenseNumber?: string | null | undefined;
            facility: boolean;
            stampImage?:
              | { __typename?: 'File'; id: number; uri: string }
              | null
              | undefined;
            user: {
              __typename?: 'User';
              id: number;
              active: boolean;
              login: string;
              roles: Array<UserRole>;
              regions?: Array<string> | null | undefined;
              vacationStart?: Date | null | undefined;
              vacationEnd?: Date | null | undefined;
              profilePicture?:
                | { __typename?: 'File'; publicURL?: string | null | undefined }
                | null
                | undefined;
              contact: {
                __typename?: 'Contact';
                id: number;
                firstName: string;
                middleName?: string | null | undefined;
                lastName: string;
                companyName?: string | null | undefined;
                birthDate?: Date | null | undefined;
                phoneNumbers?: Array<string> | null | undefined;
                emailAddresses?: Array<string> | null | undefined;
                primaryDescription?: string | null | undefined;
                nip?: string | null | undefined;
              };
            };
            employedInBranches?:
              | Array<{ __typename?: 'Branch'; id: number; name: string }>
              | null
              | undefined;
          };
        }>
      | null
      | undefined;
    supportingDoctors: Array<{
      __typename?: 'Doctor';
      id: number;
      createdAt?: Date | null | undefined;
      licenseNumber?: string | null | undefined;
      facility: boolean;
      stampImage?:
        | { __typename?: 'File'; id: number; uri: string }
        | null
        | undefined;
      user: {
        __typename?: 'User';
        id: number;
        active: boolean;
        login: string;
        roles: Array<UserRole>;
        regions?: Array<string> | null | undefined;
        vacationStart?: Date | null | undefined;
        vacationEnd?: Date | null | undefined;
        profilePicture?:
          | { __typename?: 'File'; publicURL?: string | null | undefined }
          | null
          | undefined;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          middleName?: string | null | undefined;
          lastName: string;
          companyName?: string | null | undefined;
          birthDate?: Date | null | undefined;
          phoneNumbers?: Array<string> | null | undefined;
          emailAddresses?: Array<string> | null | undefined;
          primaryDescription?: string | null | undefined;
          nip?: string | null | undefined;
        };
      };
      employedInBranches?:
        | Array<{ __typename?: 'Branch'; id: number; name: string }>
        | null
        | undefined;
    }>;
    performer?:
      | {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        }
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: number;
          uri: string;
          fileType: FileType;
          mimeType?: string | null | undefined;
          createdAt?: Date | null | undefined;
          rejected: boolean;
          rejectionReason?: string | null | undefined;
        }>
      | null
      | undefined;
    branch?:
      | { __typename?: 'Branch'; id: number; name: string }
      | null
      | undefined;
    payments?:
      | Array<{
          __typename?: 'StudyPayment';
          createdAt?: Date | null | undefined;
          createdById?: number | null | undefined;
          id?: number | null | undefined;
          method: string;
          type: PaymentTypeEnum;
          value: number;
          discount?: number | null | undefined;
          group?: string | null | undefined;
          comment?: string | null | undefined;
        }>
      | null
      | undefined;
    radiology: {
      __typename?: 'Radiology';
      presetName?: string | null | undefined;
      anodeCurrentMiliamper: number;
      voltageKilovolts: number;
      exposureTimeSeconds: number;
      radiationDoseMilligrayPerSquareCentimeter: number;
    };
    updatedBy?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
    createdBy?:
      | {
          __typename?: 'User';
          id: number;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
  };
};

export type DeleteStudyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteStudyMutation = {
  __typename?: 'Mutation';
  deleteStudy: boolean;
};

export type ShareStudyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  doctorIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type ShareStudyMutation = {
  __typename?: 'Mutation';
  shareStudy: { __typename?: 'Study'; id: number };
};

export type GetStudyTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetStudyTypesQuery = {
  __typename?: 'Query';
  studyTypes: {
    __typename?: 'StudyTypeConnection';
    edges: Array<{
      __typename?: 'StudyTypeEdge';
      node: {
        __typename?: 'StudyType';
        id: number;
        code: string;
        name: string;
        onReferalOrder?: number | null | undefined;
        hasRemarks?: boolean | null | undefined;
        is3D: boolean;
        timeToDescribe?: number | null | undefined;
        comment?: string | null | undefined;
        descriptionPrice: number;
        urgentDescriptionPrice?: number | null | undefined;
        comparisonDescriptionPrice?: number | null | undefined;
        studySubTypes?: Array<string> | null | undefined;
        studyTypePrices: Array<{
          __typename?: 'StudyTypePrice';
          id?: number | null | undefined;
          price: number;
          presetName?: string | null | undefined;
        }>;
        studyTypeRadiologies: Array<{
          __typename?: 'StudyTypeRadiology';
          id?: number | null | undefined;
          radiology: {
            __typename?: 'Radiology';
            presetName?: string | null | undefined;
            anodeCurrentMiliamper: number;
            voltageKilovolts: number;
            exposureTimeSeconds: number;
            radiationDoseMilligrayPerSquareCentimeter: number;
          };
        }>;
        studyTypeDevices: Array<{
          __typename?: 'StudyTypeDevice';
          id?: number | null | undefined;
          durationMinutes: number;
          deviceType: string;
        }>;
      };
    }>;
  };
};

export type GetStudyTypesForBranchQueryVariables = Exact<{
  branchId: Scalars['Int']['input'];
  deviceId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetStudyTypesForBranchQuery = {
  __typename?: 'Query';
  branchStudyTypes: Array<{
    __typename?: 'StudyType';
    id: number;
    code: string;
    name: string;
    onReferalOrder?: number | null | undefined;
    hasRemarks?: boolean | null | undefined;
    is3D: boolean;
    timeToDescribe?: number | null | undefined;
    comment?: string | null | undefined;
    descriptionPrice: number;
    urgentDescriptionPrice?: number | null | undefined;
    comparisonDescriptionPrice?: number | null | undefined;
    studySubTypes?: Array<string> | null | undefined;
    studyTypePrices: Array<{
      __typename?: 'StudyTypePrice';
      id?: number | null | undefined;
      price: number;
      presetName?: string | null | undefined;
    }>;
    studyTypeRadiologies: Array<{
      __typename?: 'StudyTypeRadiology';
      id?: number | null | undefined;
      radiology: {
        __typename?: 'Radiology';
        presetName?: string | null | undefined;
        anodeCurrentMiliamper: number;
        voltageKilovolts: number;
        exposureTimeSeconds: number;
        radiationDoseMilligrayPerSquareCentimeter: number;
      };
    }>;
    studyTypeDevices: Array<{
      __typename?: 'StudyTypeDevice';
      id?: number | null | undefined;
      durationMinutes: number;
      deviceType: string;
    }>;
  }>;
};

export type StudiesQueryVariables = Exact<{
  pagination: PaginationInput;
  where?: InputMaybe<StudyFiltersInput>;
  orderBy?: InputMaybe<Array<OrderColumn> | OrderColumn>;
}>;

export type StudiesQuery = {
  __typename?: 'Query';
  studies: {
    __typename?: 'PaginatedStudyResponse';
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: 'Study';
      id: number;
      studyDate: Date;
      missingDescription?: boolean | null | undefined;
      unassignedDescriber?: boolean | null | undefined;
      incognitoDescription?: boolean | null | undefined;
      hasDescription?: boolean | null | undefined;
      hidden?: boolean | null | undefined;
      status: StudyStatus;
      patient: {
        __typename?: 'Patient';
        id: number;
        contact: {
          __typename?: 'Contact';
          id: number;
          firstName: string;
          lastName: string;
          companyName?: string | null | undefined;
          birthDate?: Date | null | undefined;
          ssn?: string | null | undefined;
        };
      };
      studyType: {
        __typename?: 'StudyType';
        id: number;
        code: string;
        name: string;
      };
      leadingDoctor?:
        | {
            __typename?: 'Doctor';
            id: number;
            createdAt?: Date | null | undefined;
            licenseNumber?: string | null | undefined;
            facility: boolean;
            stampImage?:
              | { __typename?: 'File'; id: number; uri: string }
              | null
              | undefined;
            user: {
              __typename?: 'User';
              id: number;
              active: boolean;
              login: string;
              roles: Array<UserRole>;
              regions?: Array<string> | null | undefined;
              vacationStart?: Date | null | undefined;
              vacationEnd?: Date | null | undefined;
              profilePicture?:
                | { __typename?: 'File'; publicURL?: string | null | undefined }
                | null
                | undefined;
              contact: {
                __typename?: 'Contact';
                id: number;
                firstName: string;
                middleName?: string | null | undefined;
                lastName: string;
                companyName?: string | null | undefined;
                birthDate?: Date | null | undefined;
                phoneNumbers?: Array<string> | null | undefined;
                emailAddresses?: Array<string> | null | undefined;
                primaryDescription?: string | null | undefined;
                nip?: string | null | undefined;
              };
            };
            employedInBranches?:
              | Array<{ __typename?: 'Branch'; id: number; name: string }>
              | null
              | undefined;
          }
        | null
        | undefined;
      supportingDoctors: Array<{
        __typename?: 'Doctor';
        id: number;
        createdAt?: Date | null | undefined;
        licenseNumber?: string | null | undefined;
        facility: boolean;
        stampImage?:
          | { __typename?: 'File'; id: number; uri: string }
          | null
          | undefined;
        user: {
          __typename?: 'User';
          id: number;
          active: boolean;
          login: string;
          roles: Array<UserRole>;
          regions?: Array<string> | null | undefined;
          vacationStart?: Date | null | undefined;
          vacationEnd?: Date | null | undefined;
          profilePicture?:
            | { __typename?: 'File'; publicURL?: string | null | undefined }
            | null
            | undefined;
          contact: {
            __typename?: 'Contact';
            id: number;
            firstName: string;
            middleName?: string | null | undefined;
            lastName: string;
            companyName?: string | null | undefined;
            birthDate?: Date | null | undefined;
            phoneNumbers?: Array<string> | null | undefined;
            emailAddresses?: Array<string> | null | undefined;
            primaryDescription?: string | null | undefined;
            nip?: string | null | undefined;
          };
        };
        employedInBranches?:
          | Array<{ __typename?: 'Branch'; id: number; name: string }>
          | null
          | undefined;
      }>;
      describers?:
        | Array<{
            __typename?: 'StudyDescriber';
            id: number;
            stat: boolean;
            dueDate?: Date | null | undefined;
            doctor: {
              __typename?: 'Doctor';
              id: number;
              createdAt?: Date | null | undefined;
              licenseNumber?: string | null | undefined;
              facility: boolean;
              stampImage?:
                | { __typename?: 'File'; id: number; uri: string }
                | null
                | undefined;
              user: {
                __typename?: 'User';
                id: number;
                active: boolean;
                login: string;
                roles: Array<UserRole>;
                regions?: Array<string> | null | undefined;
                vacationStart?: Date | null | undefined;
                vacationEnd?: Date | null | undefined;
                profilePicture?:
                  | {
                      __typename?: 'File';
                      publicURL?: string | null | undefined;
                    }
                  | null
                  | undefined;
                contact: {
                  __typename?: 'Contact';
                  id: number;
                  firstName: string;
                  middleName?: string | null | undefined;
                  lastName: string;
                  companyName?: string | null | undefined;
                  birthDate?: Date | null | undefined;
                  phoneNumbers?: Array<string> | null | undefined;
                  emailAddresses?: Array<string> | null | undefined;
                  primaryDescription?: string | null | undefined;
                  nip?: string | null | undefined;
                };
              };
              employedInBranches?:
                | Array<{ __typename?: 'Branch'; id: number; name: string }>
                | null
                | undefined;
            };
          }>
        | null
        | undefined;
      branch?:
        | { __typename?: 'Branch'; id: number; name: string }
        | null
        | undefined;
    }>;
  };
};

export type FullUserDataFragment = {
  __typename?: 'User';
  id: number;
  active: boolean;
  login: string;
  regions?: Array<string> | null | undefined;
  roles: Array<UserRole>;
  vacationStart?: Date | null | undefined;
  vacationEnd?: Date | null | undefined;
  profilePicture?:
    | { __typename?: 'File'; publicURL?: string | null | undefined }
    | null
    | undefined;
  contact: {
    __typename?: 'Contact';
    id: number;
    firstName: string;
    middleName?: string | null | undefined;
    lastName: string;
    companyName?: string | null | undefined;
    birthDate?: Date | null | undefined;
    phoneNumbers?: Array<string> | null | undefined;
    emailAddresses?: Array<string> | null | undefined;
    primaryDescription?: string | null | undefined;
    nip?: string | null | undefined;
    addresses?:
      | Array<
          | {
              __typename?: 'Address';
              id: number;
              street: string;
              postalCode: string;
              city: string;
              country: string;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type UsersQueryVariables = Exact<{
  pagination: PaginationInput;
  where?: InputMaybe<UserFiltersInput>;
  orderBy?: InputMaybe<Array<OrderColumn> | OrderColumn>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'PaginatedUserResponse';
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: 'User';
      roles: Array<UserRole>;
      id: number;
      active: boolean;
      login: string;
      regions?: Array<string> | null | undefined;
      vacationStart?: Date | null | undefined;
      vacationEnd?: Date | null | undefined;
      profilePicture?:
        | { __typename?: 'File'; publicURL?: string | null | undefined }
        | null
        | undefined;
      contact: {
        __typename?: 'Contact';
        id: number;
        firstName: string;
        middleName?: string | null | undefined;
        lastName: string;
        companyName?: string | null | undefined;
        birthDate?: Date | null | undefined;
        phoneNumbers?: Array<string> | null | undefined;
        emailAddresses?: Array<string> | null | undefined;
        primaryDescription?: string | null | undefined;
        nip?: string | null | undefined;
        addresses?:
          | Array<
              | {
                  __typename?: 'Address';
                  id: number;
                  street: string;
                  postalCode: string;
                  city: string;
                  country: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      };
    }>;
  };
};

export type HelpUserContactsQueryVariables = Exact<{
  count: Scalars['Int']['input'];
}>;

export type HelpUserContactsQuery = {
  __typename?: 'Query';
  helpUserContacts: Array<{
    __typename?: 'HelpContact';
    firstName: string;
    lastName: string;
    phoneNumbers?: Array<string> | null | undefined;
    emailAddresses?: Array<string> | null | undefined;
    profilePictureURL?: string | null | undefined;
  }>;
};

export type GetCurrentUserQueryVariables = Exact<{
  withPreferences?: Scalars['Boolean']['input'];
}>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    id: number;
    active: boolean;
    login: string;
    regions?: Array<string> | null | undefined;
    roles: Array<UserRole>;
    emailConfirmed: boolean;
    profilePicture?:
      | { __typename?: 'File'; publicURL?: string | null | undefined }
      | null
      | undefined;
    contact: {
      __typename?: 'Contact';
      id: number;
      firstName: string;
      middleName?: string | null | undefined;
      lastName: string;
      companyName?: string | null | undefined;
      birthDate?: Date | null | undefined;
      phoneNumbers?: Array<string> | null | undefined;
      emailAddresses?: Array<string> | null | undefined;
      primaryDescription?: string | null | undefined;
      nip?: string | null | undefined;
    };
    preferences?: { __typename?: 'Preferences'; mailNotifications: boolean };
  };
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  user: UserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    emailConfirmed: boolean;
    id: number;
    active: boolean;
    login: string;
    roles: Array<UserRole>;
    regions?: Array<string> | null | undefined;
    vacationStart?: Date | null | undefined;
    vacationEnd?: Date | null | undefined;
    profilePicture?:
      | { __typename?: 'File'; publicURL?: string | null | undefined }
      | null
      | undefined;
    contact: {
      __typename?: 'Contact';
      id: number;
      firstName: string;
      middleName?: string | null | undefined;
      lastName: string;
      companyName?: string | null | undefined;
      birthDate?: Date | null | undefined;
      phoneNumbers?: Array<string> | null | undefined;
      emailAddresses?: Array<string> | null | undefined;
      primaryDescription?: string | null | undefined;
      nip?: string | null | undefined;
    };
  };
};

export type MarkForDeleteMutationVariables = Exact<{ [key: string]: never }>;

export type MarkForDeleteMutation = {
  __typename?: 'Mutation';
  markForDelete: boolean;
};

export const CommentFragmentFragmentDoc = gql`
  fragment CommentFragment on Comment {
    id
    title
    content
    createdAt
    createdBy {
      id
      contact {
        firstName
        lastName
      }
      profilePicture {
        publicURL
      }
    }
    doctor {
      id
      user {
        contact {
          firstName
          lastName
        }
      }
    }
  }
`;
export const AppConfigFragmentFragmentDoc = gql`
  fragment AppConfigFragment on AppConfig {
    paymentTypes
    priceGroups
    radiationGroups
    carrierPrices
    deviceTypes
    regions
    complaintTypes
    complaintResolutionTimeHours
    specializations
  }
`;
export const BranchFieldsFragmentDoc = gql`
  fragment BranchFields on Branch {
    id
    name
    ownBranch
    color
    address {
      id
      street
      postalCode
      city
      country
    }
    phoneNumbers
    emailAddresses
    devices {
      id
      name
      deviceType
      radiationGroups
    }
    region
  }
`;
export const EmailTemplateFragmentFragmentDoc = gql`
  fragment EmailTemplateFragment on EmailTemplate {
    id
    name
    defaultRecipientRoles
    defaultRecipients
    content
    embeddedType
  }
`;
export const MailingFragmentFragmentDoc = gql`
  fragment MailingFragment on Mailing {
    id
    createdById
    createdAt
    subject
    status
    recipients
    specializations
    width
    emailChannel
    internalMessageChannel
    showFooter
    important
  }
`;
export const SoftwareFragmentFragmentDoc = gql`
  fragment SoftwareFragment on Software {
    id
    name
    downloadURL
  }
`;
export const AppointmentDataFragmentDoc = gql`
  fragment AppointmentData on Appointment {
    id
    startDate
    endDate
    isBreak
    comments
    studyType {
      id
      name
      is3D
    }
    branch {
      id
    }
    device {
      id
      name
    }
    study {
      id
    }
    referal {
      id
      completed
    }
    patient {
      id
      contact {
        id
        firstName
        lastName
        birthDate
        phoneNumbers
        emailAddresses
        primaryDescription
      }
    }
  }
`;
export const ReservationDataFragmentDoc = gql`
  fragment ReservationData on Reservation {
    id
    startDate
    endDate
    deviceId
    createdById
    createdBy
  }
`;
export const PatientDataFragmentDoc = gql`
  fragment PatientData on Patient {
    id
    notes
    contact {
      id
      firstName
      middleName
      lastName
      companyName
      ssn
      gender
      birthDate
      phoneNumbers
      emailAddresses
      primaryDescription
      secondaryDescription
      addresses {
        id
        street
        postalCode
        city
        country
      }
    }
    registrationDate
    createdById
    updatedById
  }
`;
export const PatientWithAppointmentsFragmentDoc = gql`
  fragment PatientWithAppointments on Patient {
    ...PatientData
    appointments {
      id
      startDate
      endDate
      studyType {
        id
        name
      }
      comments
      createdAt
      updatedAt
    }
  }
  ${PatientDataFragmentDoc}
`;
export const ReferalDataFragmentDoc = gql`
  fragment ReferalData on Referal {
    id
    completed
    description
    remarks
    requestDescription
    mediaTypes
    referalToStudyTypes {
      id
      studyType {
        id
        name
      }
      remarks
      studySubTypes
    }
    issuer {
      id
      user {
        id
        contact {
          id
          firstName
          lastName
          primaryDescription
        }
      }
    }
    patient {
      id
      contact {
        id
        firstName
        lastName
        emailAddresses
        phoneNumbers
        birthDate
        ssn
        gender
      }
    }
  }
`;
export const ContactDataFragmentDoc = gql`
  fragment ContactData on Contact {
    id
    firstName
    middleName
    lastName
    companyName
    birthDate
    phoneNumbers
    emailAddresses
    primaryDescription
    nip
  }
`;
export const UserDataFragmentDoc = gql`
  fragment UserData on User {
    id
    active
    login
    roles
    regions
    profilePicture {
      publicURL
    }
    contact {
      ...ContactData
    }
    vacationStart
    vacationEnd
  }
  ${ContactDataFragmentDoc}
`;
export const DoctorDataFragmentDoc = gql`
  fragment DoctorData on Doctor {
    id
    createdAt
    licenseNumber
    facility
    stampImage {
      id
      uri
    }
    user {
      ...UserData
    }
    employedInBranches {
      id
      name
    }
  }
  ${UserDataFragmentDoc}
`;
export const StudyPaymentFragmentDoc = gql`
  fragment StudyPayment on StudyPayment {
    createdAt
    createdById
    id
    method
    type
    value
    discount
    group
    comment
  }
`;
export const StudyDataFragmentDoc = gql`
  fragment StudyData on Study {
    id
    studyDate
    status
    studyType {
      id
      code
      name
      timeToDescribe
      studyTypePrices {
        id
        price
        presetName
      }
      studyTypeRadiologies {
        radiology {
          presetName
          anodeCurrentMiliamper
          voltageKilovolts
          exposureTimeSeconds
          radiationDoseMilligrayPerSquareCentimeter
        }
      }
      descriptionPrice
      urgentDescriptionPrice
      comparisonDescriptionPrice
    }
    urgentDescription
    comparisonDescription
    patient {
      id
      contact {
        id
        firstName
        lastName
        birthDate
        ssn
      }
      studies {
        id
      }
    }
    leadingDoctor {
      ...DoctorData
    }
    leadingDoctorBranch {
      id
      name
    }
    describers {
      id
      stat
      dueDate
      doctor {
        ...DoctorData
      }
    }
    supportingDoctors {
      ...DoctorData
    }
    performer {
      ...UserData
    }
    createdById
    updatedById
    files {
      id
      uri
      fileType
      mimeType
      createdAt
      rejected
      rejectionReason
    }
    branch {
      id
      name
    }
    hidden
    incognitoDescription
    internalInformation
    importantInformation
    descriptionRequestComment
    notifyLeaderAndSupportingDoctors
    notifyDoctorsAboutDescription
    notifyDescribers
    externalReferal
    payments {
      ...StudyPayment
    }
    radiology {
      presetName
      anodeCurrentMiliamper
      voltageKilovolts
      exposureTimeSeconds
      radiationDoseMilligrayPerSquareCentimeter
    }
    missingDescription
    unassignedDescriber
    hasDescription
    updatedBy {
      id
      contact {
        id
        firstName
        lastName
      }
    }
    createdBy {
      id
      contact {
        id
        firstName
        lastName
      }
    }
    createdById
    updatedById
    createdAt
    updatedAt
  }
  ${DoctorDataFragmentDoc}
  ${UserDataFragmentDoc}
  ${StudyPaymentFragmentDoc}
`;
export const StudyTypeFragmentFragmentDoc = gql`
  fragment StudyTypeFragment on StudyType {
    id
    code
    name
    onReferalOrder
    hasRemarks
    is3D
    timeToDescribe
    comment
    descriptionPrice
    urgentDescriptionPrice
    comparisonDescriptionPrice
    studySubTypes
    studyTypePrices {
      id
      price
      presetName
    }
    studyTypeRadiologies {
      id
      radiology {
        presetName
        anodeCurrentMiliamper
        voltageKilovolts
        exposureTimeSeconds
        radiationDoseMilligrayPerSquareCentimeter
      }
    }
    studyTypeDevices {
      id
      durationMinutes
      deviceType
    }
  }
`;
export const ComplaintDataFragmentDoc = gql`
  fragment ComplaintData on Complaint {
    id
    createdAt
    study {
      id
      studyType {
        ...StudyTypeFragment
      }
    }
    patient {
      ...PatientData
    }
    doctor {
      ...DoctorData
    }
    complaintReason
    complaintDescription
    responsiblePerson {
      id
      contact {
        id
        firstName
        lastName
      }
    }
    complaintResolution
    createdById
    updatedById
    complainantName
    type
    status
    complainantPhoneNumber
    complainantEmailAddress
    dueDate
  }
  ${StudyTypeFragmentFragmentDoc}
  ${PatientDataFragmentDoc}
  ${DoctorDataFragmentDoc}
`;
export const PatientListDataFragmentDoc = gql`
  fragment PatientListData on Patient {
    id
    contact {
      id
      firstName
      lastName
      companyName
      birthDate
      ssn
    }
  }
`;
export const CouncilFragmentFragmentDoc = gql`
  fragment CouncilFragment on Council {
    id
    name
    createdAt
    invitedMemberEmail
    councilMembers {
      owner
      councilMemberDoctor @connection(key: "councilMemberDoctor") {
        ...DoctorData
      }
    }
    studies {
      id
      patient {
        ...PatientListData
      }
      studyType {
        id
        code
        name
      }
      studyDate
    }
  }
  ${DoctorDataFragmentDoc}
  ${PatientListDataFragmentDoc}
`;
export const FullDoctorFragmentDoc = gql`
  fragment FullDoctor on Doctor {
    id
    createdAt
    specializations
    licenseNumber
    facility
    stampImage {
      id
      uri
    }
    maxStudyHoursToDescribe
    describingStudyTypes {
      id
      code
      name
      timeToDescribe
    }
    describerInfo {
      assignedStudies
      totalAssignedStudies
      descriptionOccupancyHours
    }
    user {
      id
      active
      login
      roles
      createdAt
      updatedAt
      vacationStart
      vacationEnd
      profilePicture {
        publicURL
      }
      regions
      contact {
        ...ContactData
        addresses {
          id
          street
          city
          postalCode
          country
        }
      }
      preferences @include(if: $withPreferences) {
        mailNotifications
      }
    }
    employedInBranches {
      id
      name
    }
  }
  ${ContactDataFragmentDoc}
`;
export const PatientStudyFragmentDoc = gql`
  fragment PatientStudy on Study {
    id
    studyDate
    studyType {
      id
      code
      name
    }
    status
    hidden
  }
`;
export const FullUserDataFragmentDoc = gql`
  fragment FullUserData on User {
    id
    active
    login
    regions
    roles
    profilePicture {
      publicURL
    }
    contact {
      ...ContactData
      addresses {
        id
        street
        postalCode
        city
        country
      }
    }
    vacationStart
    vacationEnd
  }
  ${ContactDataFragmentDoc}
`;
export const GetDownloadUrlDocument = gql`
  query getDownloadURL($filePath: String!, $studyId: Int) {
    getDownloadURL(filePath: $filePath, studyId: $studyId)
  }
`;

/**
 * __useGetDownloadUrlQuery__
 *
 * To run a query within a React component, call `useGetDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownloadUrlQuery({
 *   variables: {
 *      filePath: // value for 'filePath'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetDownloadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDownloadUrlQuery,
    GetDownloadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDownloadUrlQuery, GetDownloadUrlQueryVariables>(
    GetDownloadUrlDocument,
    options,
  );
}
export function useGetDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDownloadUrlQuery,
    GetDownloadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDownloadUrlQuery, GetDownloadUrlQueryVariables>(
    GetDownloadUrlDocument,
    options,
  );
}
export type GetDownloadUrlQueryHookResult = ReturnType<
  typeof useGetDownloadUrlQuery
>;
export type GetDownloadUrlLazyQueryHookResult = ReturnType<
  typeof useGetDownloadUrlLazyQuery
>;
export type GetDownloadUrlQueryResult = Apollo.QueryResult<
  GetDownloadUrlQuery,
  GetDownloadUrlQueryVariables
>;
export const UploadFileDocument = gql`
  mutation uploadFile(
    $file: Upload!
    $fileType: FileType!
    $fileSizeInBytes: Float!
    $rejected: Boolean
    $rejectionReason: String
  ) {
    uploadFile(
      data: {
        file: $file
        fileType: $fileType
        fileSizeInBytes: $fileSizeInBytes
        rejected: $rejected
        rejectionReason: $rejectionReason
      }
    ) {
      id
    }
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      fileType: // value for 'fileType'
 *      fileSizeInBytes: // value for 'fileSizeInBytes'
 *      rejected: // value for 'rejected'
 *      rejectionReason: // value for 'rejectionReason'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options,
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult = Apollo.MutationResult<
  UploadFileMutation
>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const PrepareUploadDocument = gql`
  mutation prepareUpload($file: FileMetaInput!) {
    prepareUpload(file: $file) {
      fileId
      uploadSignedURL
    }
  }
`;
export type PrepareUploadMutationFn = Apollo.MutationFunction<
  PrepareUploadMutation,
  PrepareUploadMutationVariables
>;

/**
 * __usePrepareUploadMutation__
 *
 * To run a mutation, you first call `usePrepareUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareUploadMutation, { data, loading, error }] = usePrepareUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function usePrepareUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PrepareUploadMutation,
    PrepareUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PrepareUploadMutation,
    PrepareUploadMutationVariables
  >(PrepareUploadDocument, options);
}
export type PrepareUploadMutationHookResult = ReturnType<
  typeof usePrepareUploadMutation
>;
export type PrepareUploadMutationResult = Apollo.MutationResult<
  PrepareUploadMutation
>;
export type PrepareUploadMutationOptions = Apollo.BaseMutationOptions<
  PrepareUploadMutation,
  PrepareUploadMutationVariables
>;
export const ConfirmUploadDocument = gql`
  mutation confirmUpload($fileId: Int!) {
    confirmUpload(fileId: $fileId)
  }
`;
export type ConfirmUploadMutationFn = Apollo.MutationFunction<
  ConfirmUploadMutation,
  ConfirmUploadMutationVariables
>;

/**
 * __useConfirmUploadMutation__
 *
 * To run a mutation, you first call `useConfirmUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUploadMutation, { data, loading, error }] = useConfirmUploadMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useConfirmUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmUploadMutation,
    ConfirmUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmUploadMutation,
    ConfirmUploadMutationVariables
  >(ConfirmUploadDocument, options);
}
export type ConfirmUploadMutationHookResult = ReturnType<
  typeof useConfirmUploadMutation
>;
export type ConfirmUploadMutationResult = Apollo.MutationResult<
  ConfirmUploadMutation
>;
export type ConfirmUploadMutationOptions = Apollo.BaseMutationOptions<
  ConfirmUploadMutation,
  ConfirmUploadMutationVariables
>;
export const DeleteFileDocument = gql`
  mutation deleteFile($id: [Int!]!) {
    deleteFile(id: $id)
  }
`;
export type DeleteFileMutationFn = Apollo.MutationFunction<
  DeleteFileMutation,
  DeleteFileMutationVariables
>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFileMutation,
    DeleteFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(
    DeleteFileDocument,
    options,
  );
}
export type DeleteFileMutationHookResult = ReturnType<
  typeof useDeleteFileMutation
>;
export type DeleteFileMutationResult = Apollo.MutationResult<
  DeleteFileMutation
>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileMutation,
  DeleteFileMutationVariables
>;
export const ConfirmEmailDocument = gql`
  mutation ConfirmEmail($confirmInput: String!) {
    confirmEmail(token: $confirmInput)
  }
`;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      confirmInput: // value for 'confirmInput'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >(ConfirmEmailDocument, options);
}
export type ConfirmEmailMutationHookResult = ReturnType<
  typeof useConfirmEmailMutation
>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<
  ConfirmEmailMutation
>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;
export const ResendConfirmationEmailDocument = gql`
  mutation ResendConfirmationEmail($login: String!) {
    resendConfirmationEmail(login: $login)
  }
`;
export type ResendConfirmationEmailMutationFn = Apollo.MutationFunction<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendConfirmationEmailMutation,
    ResendConfirmationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendConfirmationEmailMutation,
    ResendConfirmationEmailMutationVariables
  >(ResendConfirmationEmailDocument, options);
}
export type ResendConfirmationEmailMutationHookResult = ReturnType<
  typeof useResendConfirmationEmailMutation
>;
export type ResendConfirmationEmailMutationResult = Apollo.MutationResult<
  ResendConfirmationEmailMutation
>;
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($loginData: LoginInput!) {
    login(data: $loginData) {
      token
      user {
        id
        login
        roles
        acceptedTerms
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginData: // value for 'loginData'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const AcceptTermsDocument = gql`
  mutation AcceptTerms {
    acceptTerms
  }
`;
export type AcceptTermsMutationFn = Apollo.MutationFunction<
  AcceptTermsMutation,
  AcceptTermsMutationVariables
>;

/**
 * __useAcceptTermsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsMutation, { data, loading, error }] = useAcceptTermsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTermsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTermsMutation,
    AcceptTermsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptTermsMutation, AcceptTermsMutationVariables>(
    AcceptTermsDocument,
    options,
  );
}
export type AcceptTermsMutationHookResult = ReturnType<
  typeof useAcceptTermsMutation
>;
export type AcceptTermsMutationResult = Apollo.MutationResult<
  AcceptTermsMutation
>;
export type AcceptTermsMutationOptions = Apollo.BaseMutationOptions<
  AcceptTermsMutation,
  AcceptTermsMutationVariables
>;
export const OAuthLoginDocument = gql`
  mutation OAuthLogin {
    oauthLogin {
      token
      user {
        id
        login
        roles
        acceptedTerms
      }
    }
  }
`;
export type OAuthLoginMutationFn = Apollo.MutationFunction<
  OAuthLoginMutation,
  OAuthLoginMutationVariables
>;

/**
 * __useOAuthLoginMutation__
 *
 * To run a mutation, you first call `useOAuthLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOAuthLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oAuthLoginMutation, { data, loading, error }] = useOAuthLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useOAuthLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OAuthLoginMutation,
    OAuthLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OAuthLoginMutation, OAuthLoginMutationVariables>(
    OAuthLoginDocument,
    options,
  );
}
export type OAuthLoginMutationHookResult = ReturnType<
  typeof useOAuthLoginMutation
>;
export type OAuthLoginMutationResult = Apollo.MutationResult<
  OAuthLoginMutation
>;
export type OAuthLoginMutationOptions = Apollo.BaseMutationOptions<
  OAuthLoginMutation,
  OAuthLoginMutationVariables
>;
export const OAuthConnectAccountDocument = gql`
  mutation OAuthConnectAccount {
    oAuthConnectAccount
  }
`;
export type OAuthConnectAccountMutationFn = Apollo.MutationFunction<
  OAuthConnectAccountMutation,
  OAuthConnectAccountMutationVariables
>;

/**
 * __useOAuthConnectAccountMutation__
 *
 * To run a mutation, you first call `useOAuthConnectAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOAuthConnectAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oAuthConnectAccountMutation, { data, loading, error }] = useOAuthConnectAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useOAuthConnectAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OAuthConnectAccountMutation,
    OAuthConnectAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OAuthConnectAccountMutation,
    OAuthConnectAccountMutationVariables
  >(OAuthConnectAccountDocument, options);
}
export type OAuthConnectAccountMutationHookResult = ReturnType<
  typeof useOAuthConnectAccountMutation
>;
export type OAuthConnectAccountMutationResult = Apollo.MutationResult<
  OAuthConnectAccountMutation
>;
export type OAuthConnectAccountMutationOptions = Apollo.BaseMutationOptions<
  OAuthConnectAccountMutation,
  OAuthConnectAccountMutationVariables
>;
export const GetProfileDocument = gql`
  query GetProfile($withPreferences: Boolean! = true) {
    currentDoctor {
      ...FullDoctor
    }
  }
  ${FullDoctorFragmentDoc}
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      withPreferences: // value for 'withPreferences'
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options,
  );
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options,
  );
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<
  typeof useGetProfileLazyQuery
>;
export type GetProfileQueryResult = Apollo.QueryResult<
  GetProfileQuery,
  GetProfileQueryVariables
>;
export const ChangePasswordDocument = gql`
  mutation changePassword($newPassword: String!) {
    changePassword(newPassword: $newPassword) {
      id
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult = Apollo.MutationResult<
  ChangePasswordMutation
>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ChangeLoginDocument = gql`
  mutation changeLogin($newLogin: String!, $userId: Int) {
    changeLogin(newLogin: $newLogin, userId: $userId) {
      id
    }
  }
`;
export type ChangeLoginMutationFn = Apollo.MutationFunction<
  ChangeLoginMutation,
  ChangeLoginMutationVariables
>;

/**
 * __useChangeLoginMutation__
 *
 * To run a mutation, you first call `useChangeLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLoginMutation, { data, loading, error }] = useChangeLoginMutation({
 *   variables: {
 *      newLogin: // value for 'newLogin'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChangeLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeLoginMutation,
    ChangeLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeLoginMutation, ChangeLoginMutationVariables>(
    ChangeLoginDocument,
    options,
  );
}
export type ChangeLoginMutationHookResult = ReturnType<
  typeof useChangeLoginMutation
>;
export type ChangeLoginMutationResult = Apollo.MutationResult<
  ChangeLoginMutation
>;
export type ChangeLoginMutationOptions = Apollo.BaseMutationOptions<
  ChangeLoginMutation,
  ChangeLoginMutationVariables
>;
export const UpdateCurrentDoctorDocument = gql`
  mutation UpdateCurrentDoctor($doctor: DoctorInput!) {
    updateCurrentDoctor(data: $doctor) {
      ...DoctorData
    }
  }
  ${DoctorDataFragmentDoc}
`;
export type UpdateCurrentDoctorMutationFn = Apollo.MutationFunction<
  UpdateCurrentDoctorMutation,
  UpdateCurrentDoctorMutationVariables
>;

/**
 * __useUpdateCurrentDoctorMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentDoctorMutation, { data, loading, error }] = useUpdateCurrentDoctorMutation({
 *   variables: {
 *      doctor: // value for 'doctor'
 *   },
 * });
 */
export function useUpdateCurrentDoctorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentDoctorMutation,
    UpdateCurrentDoctorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentDoctorMutation,
    UpdateCurrentDoctorMutationVariables
  >(UpdateCurrentDoctorDocument, options);
}
export type UpdateCurrentDoctorMutationHookResult = ReturnType<
  typeof useUpdateCurrentDoctorMutation
>;
export type UpdateCurrentDoctorMutationResult = Apollo.MutationResult<
  UpdateCurrentDoctorMutation
>;
export type UpdateCurrentDoctorMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentDoctorMutation,
  UpdateCurrentDoctorMutationVariables
>;
export const UpdateProfilePictureDocument = gql`
  mutation UpdateProfilePicture($updateProfilePicturePictureData: String!) {
    updateProfilePicture(pictureData: $updateProfilePicturePictureData) {
      id
      publicURL
    }
  }
`;
export type UpdateProfilePictureMutationFn = Apollo.MutationFunction<
  UpdateProfilePictureMutation,
  UpdateProfilePictureMutationVariables
>;

/**
 * __useUpdateProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePictureMutation, { data, loading, error }] = useUpdateProfilePictureMutation({
 *   variables: {
 *      updateProfilePicturePictureData: // value for 'updateProfilePicturePictureData'
 *   },
 * });
 */
export function useUpdateProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfilePictureMutation,
    UpdateProfilePictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfilePictureMutation,
    UpdateProfilePictureMutationVariables
  >(UpdateProfilePictureDocument, options);
}
export type UpdateProfilePictureMutationHookResult = ReturnType<
  typeof useUpdateProfilePictureMutation
>;
export type UpdateProfilePictureMutationResult = Apollo.MutationResult<
  UpdateProfilePictureMutation
>;
export type UpdateProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfilePictureMutation,
  UpdateProfilePictureMutationVariables
>;
export const DeleteExistingProfilePictureDocument = gql`
  mutation DeleteExistingProfilePicture {
    deleteExistingProfilePicture
  }
`;
export type DeleteExistingProfilePictureMutationFn = Apollo.MutationFunction<
  DeleteExistingProfilePictureMutation,
  DeleteExistingProfilePictureMutationVariables
>;

/**
 * __useDeleteExistingProfilePictureMutation__
 *
 * To run a mutation, you first call `useDeleteExistingProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExistingProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExistingProfilePictureMutation, { data, loading, error }] = useDeleteExistingProfilePictureMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteExistingProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExistingProfilePictureMutation,
    DeleteExistingProfilePictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExistingProfilePictureMutation,
    DeleteExistingProfilePictureMutationVariables
  >(DeleteExistingProfilePictureDocument, options);
}
export type DeleteExistingProfilePictureMutationHookResult = ReturnType<
  typeof useDeleteExistingProfilePictureMutation
>;
export type DeleteExistingProfilePictureMutationResult = Apollo.MutationResult<
  DeleteExistingProfilePictureMutation
>;
export type DeleteExistingProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  DeleteExistingProfilePictureMutation,
  DeleteExistingProfilePictureMutationVariables
>;
export const UpdatePreferencesDocument = gql`
  mutation UpdatePreferences($newPreferences: PreferencesInput!) {
    updatePreferences(newPreferences: $newPreferences) {
      mailNotifications
    }
  }
`;
export type UpdatePreferencesMutationFn = Apollo.MutationFunction<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>;

/**
 * __useUpdatePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferencesMutation, { data, loading, error }] = useUpdatePreferencesMutation({
 *   variables: {
 *      newPreferences: // value for 'newPreferences'
 *   },
 * });
 */
export function useUpdatePreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePreferencesMutation,
    UpdatePreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePreferencesMutation,
    UpdatePreferencesMutationVariables
  >(UpdatePreferencesDocument, options);
}
export type UpdatePreferencesMutationHookResult = ReturnType<
  typeof useUpdatePreferencesMutation
>;
export type UpdatePreferencesMutationResult = Apollo.MutationResult<
  UpdatePreferencesMutation
>;
export type UpdatePreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>;
export const InitPasswordResetDocument = gql`
  mutation InitPasswordReset($email: String!) {
    initPasswordReset(email: $email)
  }
`;
export type InitPasswordResetMutationFn = Apollo.MutationFunction<
  InitPasswordResetMutation,
  InitPasswordResetMutationVariables
>;

/**
 * __useInitPasswordResetMutation__
 *
 * To run a mutation, you first call `useInitPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initPasswordResetMutation, { data, loading, error }] = useInitPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInitPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitPasswordResetMutation,
    InitPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitPasswordResetMutation,
    InitPasswordResetMutationVariables
  >(InitPasswordResetDocument, options);
}
export type InitPasswordResetMutationHookResult = ReturnType<
  typeof useInitPasswordResetMutation
>;
export type InitPasswordResetMutationResult = Apollo.MutationResult<
  InitPasswordResetMutation
>;
export type InitPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  InitPasswordResetMutation,
  InitPasswordResetMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult = Apollo.MutationResult<
  ResetPasswordMutation
>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const OauthSignupDoctorDocument = gql`
  mutation oauthSignupDoctor($signupData: DoctorSignupInput!) {
    oauthSignupDoctor(signup: $signupData) {
      token
      email
    }
  }
`;
export type OauthSignupDoctorMutationFn = Apollo.MutationFunction<
  OauthSignupDoctorMutation,
  OauthSignupDoctorMutationVariables
>;

/**
 * __useOauthSignupDoctorMutation__
 *
 * To run a mutation, you first call `useOauthSignupDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOauthSignupDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oauthSignupDoctorMutation, { data, loading, error }] = useOauthSignupDoctorMutation({
 *   variables: {
 *      signupData: // value for 'signupData'
 *   },
 * });
 */
export function useOauthSignupDoctorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OauthSignupDoctorMutation,
    OauthSignupDoctorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OauthSignupDoctorMutation,
    OauthSignupDoctorMutationVariables
  >(OauthSignupDoctorDocument, options);
}
export type OauthSignupDoctorMutationHookResult = ReturnType<
  typeof useOauthSignupDoctorMutation
>;
export type OauthSignupDoctorMutationResult = Apollo.MutationResult<
  OauthSignupDoctorMutation
>;
export type OauthSignupDoctorMutationOptions = Apollo.BaseMutationOptions<
  OauthSignupDoctorMutation,
  OauthSignupDoctorMutationVariables
>;
export const SignupDoctorDocument = gql`
  mutation signupDoctor($signupData: DoctorSignupInput!) {
    signupDoctor(signup: $signupData) {
      token
      email
    }
  }
`;
export type SignupDoctorMutationFn = Apollo.MutationFunction<
  SignupDoctorMutation,
  SignupDoctorMutationVariables
>;

/**
 * __useSignupDoctorMutation__
 *
 * To run a mutation, you first call `useSignupDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupDoctorMutation, { data, loading, error }] = useSignupDoctorMutation({
 *   variables: {
 *      signupData: // value for 'signupData'
 *   },
 * });
 */
export function useSignupDoctorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupDoctorMutation,
    SignupDoctorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignupDoctorMutation,
    SignupDoctorMutationVariables
  >(SignupDoctorDocument, options);
}
export type SignupDoctorMutationHookResult = ReturnType<
  typeof useSignupDoctorMutation
>;
export type SignupDoctorMutationResult = Apollo.MutationResult<
  SignupDoctorMutation
>;
export type SignupDoctorMutationOptions = Apollo.BaseMutationOptions<
  SignupDoctorMutation,
  SignupDoctorMutationVariables
>;
export const MyCommentsDocument = gql`
  query MyComments {
    myComments {
      ...CommentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
`;

/**
 * __useMyCommentsQuery__
 *
 * To run a query within a React component, call `useMyCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCommentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCommentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyCommentsQuery,
    MyCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCommentsQuery, MyCommentsQueryVariables>(
    MyCommentsDocument,
    options,
  );
}
export function useMyCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCommentsQuery,
    MyCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyCommentsQuery, MyCommentsQueryVariables>(
    MyCommentsDocument,
    options,
  );
}
export type MyCommentsQueryHookResult = ReturnType<typeof useMyCommentsQuery>;
export type MyCommentsLazyQueryHookResult = ReturnType<
  typeof useMyCommentsLazyQuery
>;
export type MyCommentsQueryResult = Apollo.QueryResult<
  MyCommentsQuery,
  MyCommentsQueryVariables
>;
export const DoctorCommentsDocument = gql`
  query DoctorComments($doctorId: Int!) {
    doctorComments(doctorId: $doctorId) {
      ...CommentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
`;

/**
 * __useDoctorCommentsQuery__
 *
 * To run a query within a React component, call `useDoctorCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorCommentsQuery({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *   },
 * });
 */
export function useDoctorCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DoctorCommentsQuery,
    DoctorCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DoctorCommentsQuery, DoctorCommentsQueryVariables>(
    DoctorCommentsDocument,
    options,
  );
}
export function useDoctorCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DoctorCommentsQuery,
    DoctorCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DoctorCommentsQuery, DoctorCommentsQueryVariables>(
    DoctorCommentsDocument,
    options,
  );
}
export type DoctorCommentsQueryHookResult = ReturnType<
  typeof useDoctorCommentsQuery
>;
export type DoctorCommentsLazyQueryHookResult = ReturnType<
  typeof useDoctorCommentsLazyQuery
>;
export type DoctorCommentsQueryResult = Apollo.QueryResult<
  DoctorCommentsQuery,
  DoctorCommentsQueryVariables
>;
export const AddCommentDocument = gql`
  mutation AddComment($comment: CommentInput!) {
    createComment(data: $comment) {
      ...CommentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
`;
export type AddCommentMutationFn = Apollo.MutationFunction<
  AddCommentMutation,
  AddCommentMutationVariables
>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCommentMutation,
    AddCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(
    AddCommentDocument,
    options,
  );
}
export type AddCommentMutationHookResult = ReturnType<
  typeof useAddCommentMutation
>;
export type AddCommentMutationResult = Apollo.MutationResult<
  AddCommentMutation
>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<
  AddCommentMutation,
  AddCommentMutationVariables
>;
export const UpdateCommentDocument = gql`
  mutation UpdateComment($id: Int!, $comment: CommentInput!) {
    updateComment(data: $comment, id: $id) {
      ...CommentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >(UpdateCommentDocument, options);
}
export type UpdateCommentMutationHookResult = ReturnType<
  typeof useUpdateCommentMutation
>;
export type UpdateCommentMutationResult = Apollo.MutationResult<
  UpdateCommentMutation
>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>;
export const DeleteCommentsDocument = gql`
  mutation DeleteComments($ids: [Int!]!) {
    deleteComments(ids: $ids)
  }
`;
export type DeleteCommentsMutationFn = Apollo.MutationFunction<
  DeleteCommentsMutation,
  DeleteCommentsMutationVariables
>;

/**
 * __useDeleteCommentsMutation__
 *
 * To run a mutation, you first call `useDeleteCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentsMutation, { data, loading, error }] = useDeleteCommentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteCommentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommentsMutation,
    DeleteCommentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCommentsMutation,
    DeleteCommentsMutationVariables
  >(DeleteCommentsDocument, options);
}
export type DeleteCommentsMutationHookResult = ReturnType<
  typeof useDeleteCommentsMutation
>;
export type DeleteCommentsMutationResult = Apollo.MutationResult<
  DeleteCommentsMutation
>;
export type DeleteCommentsMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentsMutation,
  DeleteCommentsMutationVariables
>;
export const SaveAppConfigDocument = gql`
  mutation saveAppConfig($data: AppConfigInput!) {
    saveAppConfig(data: $data) {
      ...AppConfigFragment
    }
  }
  ${AppConfigFragmentFragmentDoc}
`;
export type SaveAppConfigMutationFn = Apollo.MutationFunction<
  SaveAppConfigMutation,
  SaveAppConfigMutationVariables
>;

/**
 * __useSaveAppConfigMutation__
 *
 * To run a mutation, you first call `useSaveAppConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAppConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAppConfigMutation, { data, loading, error }] = useSaveAppConfigMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveAppConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAppConfigMutation,
    SaveAppConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveAppConfigMutation,
    SaveAppConfigMutationVariables
  >(SaveAppConfigDocument, options);
}
export type SaveAppConfigMutationHookResult = ReturnType<
  typeof useSaveAppConfigMutation
>;
export type SaveAppConfigMutationResult = Apollo.MutationResult<
  SaveAppConfigMutation
>;
export type SaveAppConfigMutationOptions = Apollo.BaseMutationOptions<
  SaveAppConfigMutation,
  SaveAppConfigMutationVariables
>;
export const AppConfigDocument = gql`
  query AppConfig {
    appConfig {
      ...AppConfigFragment
    }
  }
  ${AppConfigFragmentFragmentDoc}
`;

/**
 * __useAppConfigQuery__
 *
 * To run a query within a React component, call `useAppConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AppConfigQuery,
    AppConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppConfigQuery, AppConfigQueryVariables>(
    AppConfigDocument,
    options,
  );
}
export function useAppConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppConfigQuery,
    AppConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppConfigQuery, AppConfigQueryVariables>(
    AppConfigDocument,
    options,
  );
}
export type AppConfigQueryHookResult = ReturnType<typeof useAppConfigQuery>;
export type AppConfigLazyQueryHookResult = ReturnType<
  typeof useAppConfigLazyQuery
>;
export type AppConfigQueryResult = Apollo.QueryResult<
  AppConfigQuery,
  AppConfigQueryVariables
>;
export const SaveBranchDocument = gql`
  mutation saveBranch($branch: BranchInput!) {
    saveBranch(data: $branch) {
      ...BranchFields
    }
  }
  ${BranchFieldsFragmentDoc}
`;
export type SaveBranchMutationFn = Apollo.MutationFunction<
  SaveBranchMutation,
  SaveBranchMutationVariables
>;

/**
 * __useSaveBranchMutation__
 *
 * To run a mutation, you first call `useSaveBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBranchMutation, { data, loading, error }] = useSaveBranchMutation({
 *   variables: {
 *      branch: // value for 'branch'
 *   },
 * });
 */
export function useSaveBranchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveBranchMutation,
    SaveBranchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveBranchMutation, SaveBranchMutationVariables>(
    SaveBranchDocument,
    options,
  );
}
export type SaveBranchMutationHookResult = ReturnType<
  typeof useSaveBranchMutation
>;
export type SaveBranchMutationResult = Apollo.MutationResult<
  SaveBranchMutation
>;
export type SaveBranchMutationOptions = Apollo.BaseMutationOptions<
  SaveBranchMutation,
  SaveBranchMutationVariables
>;
export const DeleteBranchDocument = gql`
  mutation deleteBranch($branchId: ID!) {
    deleteOneBranch(input: { id: $branchId }) {
      id
      name
    }
  }
`;
export type DeleteBranchMutationFn = Apollo.MutationFunction<
  DeleteBranchMutation,
  DeleteBranchMutationVariables
>;

/**
 * __useDeleteBranchMutation__
 *
 * To run a mutation, you first call `useDeleteBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBranchMutation, { data, loading, error }] = useDeleteBranchMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useDeleteBranchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBranchMutation,
    DeleteBranchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBranchMutation,
    DeleteBranchMutationVariables
  >(DeleteBranchDocument, options);
}
export type DeleteBranchMutationHookResult = ReturnType<
  typeof useDeleteBranchMutation
>;
export type DeleteBranchMutationResult = Apollo.MutationResult<
  DeleteBranchMutation
>;
export type DeleteBranchMutationOptions = Apollo.BaseMutationOptions<
  DeleteBranchMutation,
  DeleteBranchMutationVariables
>;
export const BranchesDocument = gql`
  query Branches(
    $paging: OffsetPaging
    $filter: BranchFilter = {}
    $sorting: [BranchSort!] = []
  ) {
    branches(paging: $paging, filter: $filter, sorting: $sorting) {
      nodes {
        ...BranchFields
      }
      totalCount
    }
  }
  ${BranchFieldsFragmentDoc}
`;

/**
 * __useBranchesQuery__
 *
 * To run a query within a React component, call `useBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useBranchesQuery(
  baseOptions?: Apollo.QueryHookOptions<BranchesQuery, BranchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchesQuery, BranchesQueryVariables>(
    BranchesDocument,
    options,
  );
}
export function useBranchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BranchesQuery,
    BranchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchesQuery, BranchesQueryVariables>(
    BranchesDocument,
    options,
  );
}
export type BranchesQueryHookResult = ReturnType<typeof useBranchesQuery>;
export type BranchesLazyQueryHookResult = ReturnType<
  typeof useBranchesLazyQuery
>;
export type BranchesQueryResult = Apollo.QueryResult<
  BranchesQuery,
  BranchesQueryVariables
>;
export const BranchDocument = gql`
  query Branch($id: ID!) {
    branch(id: $id) {
      ...BranchFields
    }
  }
  ${BranchFieldsFragmentDoc}
`;

/**
 * __useBranchQuery__
 *
 * To run a query within a React component, call `useBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchQuery(
  baseOptions: Apollo.QueryHookOptions<BranchQuery, BranchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchQuery, BranchQueryVariables>(
    BranchDocument,
    options,
  );
}
export function useBranchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BranchQuery, BranchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchQuery, BranchQueryVariables>(
    BranchDocument,
    options,
  );
}
export type BranchQueryHookResult = ReturnType<typeof useBranchQuery>;
export type BranchLazyQueryHookResult = ReturnType<typeof useBranchLazyQuery>;
export type BranchQueryResult = Apollo.QueryResult<
  BranchQuery,
  BranchQueryVariables
>;
export const AddEmailTemplateDocument = gql`
  mutation addEmailTemplate($newEmailTemplate: CreateEmailTemplate!) {
    createOneEmailTemplate(input: { emailTemplate: $newEmailTemplate }) {
      ...EmailTemplateFragment
    }
  }
  ${EmailTemplateFragmentFragmentDoc}
`;
export type AddEmailTemplateMutationFn = Apollo.MutationFunction<
  AddEmailTemplateMutation,
  AddEmailTemplateMutationVariables
>;

/**
 * __useAddEmailTemplateMutation__
 *
 * To run a mutation, you first call `useAddEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailTemplateMutation, { data, loading, error }] = useAddEmailTemplateMutation({
 *   variables: {
 *      newEmailTemplate: // value for 'newEmailTemplate'
 *   },
 * });
 */
export function useAddEmailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEmailTemplateMutation,
    AddEmailTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEmailTemplateMutation,
    AddEmailTemplateMutationVariables
  >(AddEmailTemplateDocument, options);
}
export type AddEmailTemplateMutationHookResult = ReturnType<
  typeof useAddEmailTemplateMutation
>;
export type AddEmailTemplateMutationResult = Apollo.MutationResult<
  AddEmailTemplateMutation
>;
export type AddEmailTemplateMutationOptions = Apollo.BaseMutationOptions<
  AddEmailTemplateMutation,
  AddEmailTemplateMutationVariables
>;
export const UpdateEmailTemplateDocument = gql`
  mutation updateEmailTemplate(
    $emailTemplateId: ID!
    $emailTemplate: UpdateEmailTemplate!
  ) {
    updateOneEmailTemplate(
      input: { id: $emailTemplateId, update: $emailTemplate }
    ) {
      ...EmailTemplateFragment
    }
  }
  ${EmailTemplateFragmentFragmentDoc}
`;
export type UpdateEmailTemplateMutationFn = Apollo.MutationFunction<
  UpdateEmailTemplateMutation,
  UpdateEmailTemplateMutationVariables
>;

/**
 * __useUpdateEmailTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailTemplateMutation, { data, loading, error }] = useUpdateEmailTemplateMutation({
 *   variables: {
 *      emailTemplateId: // value for 'emailTemplateId'
 *      emailTemplate: // value for 'emailTemplate'
 *   },
 * });
 */
export function useUpdateEmailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailTemplateMutation,
    UpdateEmailTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmailTemplateMutation,
    UpdateEmailTemplateMutationVariables
  >(UpdateEmailTemplateDocument, options);
}
export type UpdateEmailTemplateMutationHookResult = ReturnType<
  typeof useUpdateEmailTemplateMutation
>;
export type UpdateEmailTemplateMutationResult = Apollo.MutationResult<
  UpdateEmailTemplateMutation
>;
export type UpdateEmailTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailTemplateMutation,
  UpdateEmailTemplateMutationVariables
>;
export const DeleteEmailTemplateDocument = gql`
  mutation deleteEmailTemplate($emailTemplateId: ID!) {
    deleteOneEmailTemplate(input: { id: $emailTemplateId }) {
      id
    }
  }
`;
export type DeleteEmailTemplateMutationFn = Apollo.MutationFunction<
  DeleteEmailTemplateMutation,
  DeleteEmailTemplateMutationVariables
>;

/**
 * __useDeleteEmailTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailTemplateMutation, { data, loading, error }] = useDeleteEmailTemplateMutation({
 *   variables: {
 *      emailTemplateId: // value for 'emailTemplateId'
 *   },
 * });
 */
export function useDeleteEmailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEmailTemplateMutation,
    DeleteEmailTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEmailTemplateMutation,
    DeleteEmailTemplateMutationVariables
  >(DeleteEmailTemplateDocument, options);
}
export type DeleteEmailTemplateMutationHookResult = ReturnType<
  typeof useDeleteEmailTemplateMutation
>;
export type DeleteEmailTemplateMutationResult = Apollo.MutationResult<
  DeleteEmailTemplateMutation
>;
export type DeleteEmailTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailTemplateMutation,
  DeleteEmailTemplateMutationVariables
>;
export const EmailTemplateDocument = gql`
  query EmailTemplate(
    $paging: CursorPaging = { first: 50 }
    $filter: EmailTemplateFilter = {}
    $sorting: [EmailTemplateSort!] = []
  ) {
    emailTemplates(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...EmailTemplateFragment
        }
      }
    }
  }
  ${EmailTemplateFragmentFragmentDoc}
`;

/**
 * __useEmailTemplateQuery__
 *
 * To run a query within a React component, call `useEmailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplateQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useEmailTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmailTemplateQuery,
    EmailTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailTemplateQuery, EmailTemplateQueryVariables>(
    EmailTemplateDocument,
    options,
  );
}
export function useEmailTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailTemplateQuery,
    EmailTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailTemplateQuery, EmailTemplateQueryVariables>(
    EmailTemplateDocument,
    options,
  );
}
export type EmailTemplateQueryHookResult = ReturnType<
  typeof useEmailTemplateQuery
>;
export type EmailTemplateLazyQueryHookResult = ReturnType<
  typeof useEmailTemplateLazyQuery
>;
export type EmailTemplateQueryResult = Apollo.QueryResult<
  EmailTemplateQuery,
  EmailTemplateQueryVariables
>;
export const AddMailingDocument = gql`
  mutation addMailing($newMailing: MailingInput!) {
    createOneMailing(input: { mailing: $newMailing }) {
      ...MailingFragment
      content
    }
  }
  ${MailingFragmentFragmentDoc}
`;
export type AddMailingMutationFn = Apollo.MutationFunction<
  AddMailingMutation,
  AddMailingMutationVariables
>;

/**
 * __useAddMailingMutation__
 *
 * To run a mutation, you first call `useAddMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMailingMutation, { data, loading, error }] = useAddMailingMutation({
 *   variables: {
 *      newMailing: // value for 'newMailing'
 *   },
 * });
 */
export function useAddMailingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMailingMutation,
    AddMailingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMailingMutation, AddMailingMutationVariables>(
    AddMailingDocument,
    options,
  );
}
export type AddMailingMutationHookResult = ReturnType<
  typeof useAddMailingMutation
>;
export type AddMailingMutationResult = Apollo.MutationResult<
  AddMailingMutation
>;
export type AddMailingMutationOptions = Apollo.BaseMutationOptions<
  AddMailingMutation,
  AddMailingMutationVariables
>;
export const UpdateMailingDocument = gql`
  mutation updateMailing($mailingId: ID!, $mailing: MailingInput!) {
    updateOneMailing(input: { id: $mailingId, update: $mailing }) {
      ...MailingFragment
      content
    }
  }
  ${MailingFragmentFragmentDoc}
`;
export type UpdateMailingMutationFn = Apollo.MutationFunction<
  UpdateMailingMutation,
  UpdateMailingMutationVariables
>;

/**
 * __useUpdateMailingMutation__
 *
 * To run a mutation, you first call `useUpdateMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMailingMutation, { data, loading, error }] = useUpdateMailingMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *      mailing: // value for 'mailing'
 *   },
 * });
 */
export function useUpdateMailingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMailingMutation,
    UpdateMailingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMailingMutation,
    UpdateMailingMutationVariables
  >(UpdateMailingDocument, options);
}
export type UpdateMailingMutationHookResult = ReturnType<
  typeof useUpdateMailingMutation
>;
export type UpdateMailingMutationResult = Apollo.MutationResult<
  UpdateMailingMutation
>;
export type UpdateMailingMutationOptions = Apollo.BaseMutationOptions<
  UpdateMailingMutation,
  UpdateMailingMutationVariables
>;
export const DeleteMailingDocument = gql`
  mutation deleteMailing($mailingId: ID!) {
    deleteOneMailing(input: { id: $mailingId }) {
      id
    }
  }
`;
export type DeleteMailingMutationFn = Apollo.MutationFunction<
  DeleteMailingMutation,
  DeleteMailingMutationVariables
>;

/**
 * __useDeleteMailingMutation__
 *
 * To run a mutation, you first call `useDeleteMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMailingMutation, { data, loading, error }] = useDeleteMailingMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *   },
 * });
 */
export function useDeleteMailingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMailingMutation,
    DeleteMailingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMailingMutation,
    DeleteMailingMutationVariables
  >(DeleteMailingDocument, options);
}
export type DeleteMailingMutationHookResult = ReturnType<
  typeof useDeleteMailingMutation
>;
export type DeleteMailingMutationResult = Apollo.MutationResult<
  DeleteMailingMutation
>;
export type DeleteMailingMutationOptions = Apollo.BaseMutationOptions<
  DeleteMailingMutation,
  DeleteMailingMutationVariables
>;
export const StartMailingDocument = gql`
  mutation startMailing($mailingId: ID!) {
    startMailing(mailingId: $mailingId)
  }
`;
export type StartMailingMutationFn = Apollo.MutationFunction<
  StartMailingMutation,
  StartMailingMutationVariables
>;

/**
 * __useStartMailingMutation__
 *
 * To run a mutation, you first call `useStartMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMailingMutation, { data, loading, error }] = useStartMailingMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *   },
 * });
 */
export function useStartMailingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartMailingMutation,
    StartMailingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartMailingMutation,
    StartMailingMutationVariables
  >(StartMailingDocument, options);
}
export type StartMailingMutationHookResult = ReturnType<
  typeof useStartMailingMutation
>;
export type StartMailingMutationResult = Apollo.MutationResult<
  StartMailingMutation
>;
export type StartMailingMutationOptions = Apollo.BaseMutationOptions<
  StartMailingMutation,
  StartMailingMutationVariables
>;
export const StopMailingDocument = gql`
  mutation stopMailing($mailingId: ID!) {
    stopMailing(mailingId: $mailingId)
  }
`;
export type StopMailingMutationFn = Apollo.MutationFunction<
  StopMailingMutation,
  StopMailingMutationVariables
>;

/**
 * __useStopMailingMutation__
 *
 * To run a mutation, you first call `useStopMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopMailingMutation, { data, loading, error }] = useStopMailingMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *   },
 * });
 */
export function useStopMailingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StopMailingMutation,
    StopMailingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StopMailingMutation, StopMailingMutationVariables>(
    StopMailingDocument,
    options,
  );
}
export type StopMailingMutationHookResult = ReturnType<
  typeof useStopMailingMutation
>;
export type StopMailingMutationResult = Apollo.MutationResult<
  StopMailingMutation
>;
export type StopMailingMutationOptions = Apollo.BaseMutationOptions<
  StopMailingMutation,
  StopMailingMutationVariables
>;
export const MailingDocument = gql`
  query Mailing($mailingId: ID!) {
    mailing(id: $mailingId) {
      ...MailingFragment
      content
    }
  }
  ${MailingFragmentFragmentDoc}
`;

/**
 * __useMailingQuery__
 *
 * To run a query within a React component, call `useMailingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailingQuery({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *   },
 * });
 */
export function useMailingQuery(
  baseOptions: Apollo.QueryHookOptions<MailingQuery, MailingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MailingQuery, MailingQueryVariables>(
    MailingDocument,
    options,
  );
}
export function useMailingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MailingQuery,
    MailingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MailingQuery, MailingQueryVariables>(
    MailingDocument,
    options,
  );
}
export type MailingQueryHookResult = ReturnType<typeof useMailingQuery>;
export type MailingLazyQueryHookResult = ReturnType<typeof useMailingLazyQuery>;
export type MailingQueryResult = Apollo.QueryResult<
  MailingQuery,
  MailingQueryVariables
>;
export const MailingsDocument = gql`
  query Mailings(
    $paging: CursorPaging = { first: 100 }
    $filter: MailingFilter = {}
    $sorting: [MailingSort!] = []
  ) {
    mailings(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...MailingFragment
        }
      }
    }
  }
  ${MailingFragmentFragmentDoc}
`;

/**
 * __useMailingsQuery__
 *
 * To run a query within a React component, call `useMailingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailingsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useMailingsQuery(
  baseOptions?: Apollo.QueryHookOptions<MailingsQuery, MailingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MailingsQuery, MailingsQueryVariables>(
    MailingsDocument,
    options,
  );
}
export function useMailingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MailingsQuery,
    MailingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MailingsQuery, MailingsQueryVariables>(
    MailingsDocument,
    options,
  );
}
export type MailingsQueryHookResult = ReturnType<typeof useMailingsQuery>;
export type MailingsLazyQueryHookResult = ReturnType<
  typeof useMailingsLazyQuery
>;
export type MailingsQueryResult = Apollo.QueryResult<
  MailingsQuery,
  MailingsQueryVariables
>;
export const AddSoftwareDocument = gql`
  mutation addSoftware($newSoftware: SoftwareInput!) {
    createOneSoftware(input: { software: $newSoftware }) {
      ...SoftwareFragment
    }
  }
  ${SoftwareFragmentFragmentDoc}
`;
export type AddSoftwareMutationFn = Apollo.MutationFunction<
  AddSoftwareMutation,
  AddSoftwareMutationVariables
>;

/**
 * __useAddSoftwareMutation__
 *
 * To run a mutation, you first call `useAddSoftwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSoftwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSoftwareMutation, { data, loading, error }] = useAddSoftwareMutation({
 *   variables: {
 *      newSoftware: // value for 'newSoftware'
 *   },
 * });
 */
export function useAddSoftwareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSoftwareMutation,
    AddSoftwareMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSoftwareMutation, AddSoftwareMutationVariables>(
    AddSoftwareDocument,
    options,
  );
}
export type AddSoftwareMutationHookResult = ReturnType<
  typeof useAddSoftwareMutation
>;
export type AddSoftwareMutationResult = Apollo.MutationResult<
  AddSoftwareMutation
>;
export type AddSoftwareMutationOptions = Apollo.BaseMutationOptions<
  AddSoftwareMutation,
  AddSoftwareMutationVariables
>;
export const UpdateSoftwareDocument = gql`
  mutation updateSoftware($softwareId: ID!, $software: UpdateSoftware!) {
    updateOneSoftware(input: { id: $softwareId, update: $software }) {
      ...SoftwareFragment
    }
  }
  ${SoftwareFragmentFragmentDoc}
`;
export type UpdateSoftwareMutationFn = Apollo.MutationFunction<
  UpdateSoftwareMutation,
  UpdateSoftwareMutationVariables
>;

/**
 * __useUpdateSoftwareMutation__
 *
 * To run a mutation, you first call `useUpdateSoftwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoftwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoftwareMutation, { data, loading, error }] = useUpdateSoftwareMutation({
 *   variables: {
 *      softwareId: // value for 'softwareId'
 *      software: // value for 'software'
 *   },
 * });
 */
export function useUpdateSoftwareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSoftwareMutation,
    UpdateSoftwareMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSoftwareMutation,
    UpdateSoftwareMutationVariables
  >(UpdateSoftwareDocument, options);
}
export type UpdateSoftwareMutationHookResult = ReturnType<
  typeof useUpdateSoftwareMutation
>;
export type UpdateSoftwareMutationResult = Apollo.MutationResult<
  UpdateSoftwareMutation
>;
export type UpdateSoftwareMutationOptions = Apollo.BaseMutationOptions<
  UpdateSoftwareMutation,
  UpdateSoftwareMutationVariables
>;
export const DeleteSoftwareDocument = gql`
  mutation deleteSoftware($softwareId: ID!) {
    deleteOneSoftware(input: { id: $softwareId }) {
      id
    }
  }
`;
export type DeleteSoftwareMutationFn = Apollo.MutationFunction<
  DeleteSoftwareMutation,
  DeleteSoftwareMutationVariables
>;

/**
 * __useDeleteSoftwareMutation__
 *
 * To run a mutation, you first call `useDeleteSoftwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSoftwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSoftwareMutation, { data, loading, error }] = useDeleteSoftwareMutation({
 *   variables: {
 *      softwareId: // value for 'softwareId'
 *   },
 * });
 */
export function useDeleteSoftwareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSoftwareMutation,
    DeleteSoftwareMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSoftwareMutation,
    DeleteSoftwareMutationVariables
  >(DeleteSoftwareDocument, options);
}
export type DeleteSoftwareMutationHookResult = ReturnType<
  typeof useDeleteSoftwareMutation
>;
export type DeleteSoftwareMutationResult = Apollo.MutationResult<
  DeleteSoftwareMutation
>;
export type DeleteSoftwareMutationOptions = Apollo.BaseMutationOptions<
  DeleteSoftwareMutation,
  DeleteSoftwareMutationVariables
>;
export const SoftwareDocument = gql`
  query Software(
    $paging: CursorPaging = { first: 50 }
    $filter: SoftwareFilter = {}
    $sorting: [SoftwareSort!] = []
  ) {
    software(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...SoftwareFragment
        }
      }
    }
  }
  ${SoftwareFragmentFragmentDoc}
`;

/**
 * __useSoftwareQuery__
 *
 * To run a query within a React component, call `useSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSoftwareQuery(
  baseOptions?: Apollo.QueryHookOptions<SoftwareQuery, SoftwareQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SoftwareQuery, SoftwareQueryVariables>(
    SoftwareDocument,
    options,
  );
}
export function useSoftwareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoftwareQuery,
    SoftwareQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SoftwareQuery, SoftwareQueryVariables>(
    SoftwareDocument,
    options,
  );
}
export type SoftwareQueryHookResult = ReturnType<typeof useSoftwareQuery>;
export type SoftwareLazyQueryHookResult = ReturnType<
  typeof useSoftwareLazyQuery
>;
export type SoftwareQueryResult = Apollo.QueryResult<
  SoftwareQuery,
  SoftwareQueryVariables
>;
export const AddStudyTypeDocument = gql`
  mutation addStudyType($newStudyType: StudyTypeInput!) {
    createOneStudyType(input: { studyType: $newStudyType }) {
      ...StudyTypeFragment
    }
  }
  ${StudyTypeFragmentFragmentDoc}
`;
export type AddStudyTypeMutationFn = Apollo.MutationFunction<
  AddStudyTypeMutation,
  AddStudyTypeMutationVariables
>;

/**
 * __useAddStudyTypeMutation__
 *
 * To run a mutation, you first call `useAddStudyTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudyTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudyTypeMutation, { data, loading, error }] = useAddStudyTypeMutation({
 *   variables: {
 *      newStudyType: // value for 'newStudyType'
 *   },
 * });
 */
export function useAddStudyTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStudyTypeMutation,
    AddStudyTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddStudyTypeMutation,
    AddStudyTypeMutationVariables
  >(AddStudyTypeDocument, options);
}
export type AddStudyTypeMutationHookResult = ReturnType<
  typeof useAddStudyTypeMutation
>;
export type AddStudyTypeMutationResult = Apollo.MutationResult<
  AddStudyTypeMutation
>;
export type AddStudyTypeMutationOptions = Apollo.BaseMutationOptions<
  AddStudyTypeMutation,
  AddStudyTypeMutationVariables
>;
export const UpdateStudyTypeDocument = gql`
  mutation updateStudyType($studyTypeId: ID!, $studyType: StudyTypeInput!) {
    updateOneStudyType(input: { id: $studyTypeId, update: $studyType }) {
      ...StudyTypeFragment
    }
  }
  ${StudyTypeFragmentFragmentDoc}
`;
export type UpdateStudyTypeMutationFn = Apollo.MutationFunction<
  UpdateStudyTypeMutation,
  UpdateStudyTypeMutationVariables
>;

/**
 * __useUpdateStudyTypeMutation__
 *
 * To run a mutation, you first call `useUpdateStudyTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudyTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudyTypeMutation, { data, loading, error }] = useUpdateStudyTypeMutation({
 *   variables: {
 *      studyTypeId: // value for 'studyTypeId'
 *      studyType: // value for 'studyType'
 *   },
 * });
 */
export function useUpdateStudyTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudyTypeMutation,
    UpdateStudyTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStudyTypeMutation,
    UpdateStudyTypeMutationVariables
  >(UpdateStudyTypeDocument, options);
}
export type UpdateStudyTypeMutationHookResult = ReturnType<
  typeof useUpdateStudyTypeMutation
>;
export type UpdateStudyTypeMutationResult = Apollo.MutationResult<
  UpdateStudyTypeMutation
>;
export type UpdateStudyTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudyTypeMutation,
  UpdateStudyTypeMutationVariables
>;
export const DeleteStudyTypeDocument = gql`
  mutation deleteStudyType($studyTypeId: ID!) {
    deleteOneStudyType(input: { id: $studyTypeId }) {
      id
    }
  }
`;
export type DeleteStudyTypeMutationFn = Apollo.MutationFunction<
  DeleteStudyTypeMutation,
  DeleteStudyTypeMutationVariables
>;

/**
 * __useDeleteStudyTypeMutation__
 *
 * To run a mutation, you first call `useDeleteStudyTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudyTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudyTypeMutation, { data, loading, error }] = useDeleteStudyTypeMutation({
 *   variables: {
 *      studyTypeId: // value for 'studyTypeId'
 *   },
 * });
 */
export function useDeleteStudyTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStudyTypeMutation,
    DeleteStudyTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteStudyTypeMutation,
    DeleteStudyTypeMutationVariables
  >(DeleteStudyTypeDocument, options);
}
export type DeleteStudyTypeMutationHookResult = ReturnType<
  typeof useDeleteStudyTypeMutation
>;
export type DeleteStudyTypeMutationResult = Apollo.MutationResult<
  DeleteStudyTypeMutation
>;
export type DeleteStudyTypeMutationOptions = Apollo.BaseMutationOptions<
  DeleteStudyTypeMutation,
  DeleteStudyTypeMutationVariables
>;
export const SignupUserDocument = gql`
  mutation signupUser($userData: UserInput!, $roles: [UserRole!]!) {
    signup(roles: { roles: $roles }, data: $userData) {
      user {
        login
      }
    }
  }
`;
export type SignupUserMutationFn = Apollo.MutationFunction<
  SignupUserMutation,
  SignupUserMutationVariables
>;

/**
 * __useSignupUserMutation__
 *
 * To run a mutation, you first call `useSignupUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserMutation, { data, loading, error }] = useSignupUserMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useSignupUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupUserMutation,
    SignupUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupUserMutation, SignupUserMutationVariables>(
    SignupUserDocument,
    options,
  );
}
export type SignupUserMutationHookResult = ReturnType<
  typeof useSignupUserMutation
>;
export type SignupUserMutationResult = Apollo.MutationResult<
  SignupUserMutation
>;
export type SignupUserMutationOptions = Apollo.BaseMutationOptions<
  SignupUserMutation,
  SignupUserMutationVariables
>;
export const SignupOfficeOrLaboratoryDocument = gql`
  mutation signupOfficeOrLaboratory(
    $userData: UserInput!
    $roles: [UserRole!]!
  ) {
    signupOfficeOrLaboratory(roles: { roles: $roles }, signup: $userData) {
      token
      user {
        login
      }
    }
  }
`;
export type SignupOfficeOrLaboratoryMutationFn = Apollo.MutationFunction<
  SignupOfficeOrLaboratoryMutation,
  SignupOfficeOrLaboratoryMutationVariables
>;

/**
 * __useSignupOfficeOrLaboratoryMutation__
 *
 * To run a mutation, you first call `useSignupOfficeOrLaboratoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupOfficeOrLaboratoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupOfficeOrLaboratoryMutation, { data, loading, error }] = useSignupOfficeOrLaboratoryMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useSignupOfficeOrLaboratoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupOfficeOrLaboratoryMutation,
    SignupOfficeOrLaboratoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignupOfficeOrLaboratoryMutation,
    SignupOfficeOrLaboratoryMutationVariables
  >(SignupOfficeOrLaboratoryDocument, options);
}
export type SignupOfficeOrLaboratoryMutationHookResult = ReturnType<
  typeof useSignupOfficeOrLaboratoryMutation
>;
export type SignupOfficeOrLaboratoryMutationResult = Apollo.MutationResult<
  SignupOfficeOrLaboratoryMutation
>;
export type SignupOfficeOrLaboratoryMutationOptions = Apollo.BaseMutationOptions<
  SignupOfficeOrLaboratoryMutation,
  SignupOfficeOrLaboratoryMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: [$id])
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult = Apollo.MutationResult<
  DeleteUserMutation
>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const AppointmentsDocument = gql`
  query Appointments(
    $pagination: PaginationInput!
    $where: AppointmentFiltersInput
    $orderBy: [OrderColumn!]
  ) {
    appointments(pagination: $pagination, where: $where, orderBy: $orderBy) {
      ...AppointmentData
    }
  }
  ${AppointmentDataFragmentDoc}
`;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentsQuery,
    AppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(
    AppointmentsDocument,
    options,
  );
}
export function useAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentsQuery,
    AppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(
    AppointmentsDocument,
    options,
  );
}
export type AppointmentsQueryHookResult = ReturnType<
  typeof useAppointmentsQuery
>;
export type AppointmentsLazyQueryHookResult = ReturnType<
  typeof useAppointmentsLazyQuery
>;
export type AppointmentsQueryResult = Apollo.QueryResult<
  AppointmentsQuery,
  AppointmentsQueryVariables
>;
export const ReservationsDocument = gql`
  query Reservations($deviceId: Int!) {
    reservations(deviceId: $deviceId) {
      ...ReservationData
    }
  }
  ${ReservationDataFragmentDoc}
`;

/**
 * __useReservationsQuery__
 *
 * To run a query within a React component, call `useReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useReservationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservationsQuery,
    ReservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservationsQuery, ReservationsQueryVariables>(
    ReservationsDocument,
    options,
  );
}
export function useReservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationsQuery,
    ReservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservationsQuery, ReservationsQueryVariables>(
    ReservationsDocument,
    options,
  );
}
export type ReservationsQueryHookResult = ReturnType<
  typeof useReservationsQuery
>;
export type ReservationsLazyQueryHookResult = ReturnType<
  typeof useReservationsLazyQuery
>;
export type ReservationsQueryResult = Apollo.QueryResult<
  ReservationsQuery,
  ReservationsQueryVariables
>;
export const GetAppointmentDocument = gql`
  query GetAppointment($id: Int!) {
    appointment(id: $id) {
      ...AppointmentData
    }
  }
  ${AppointmentDataFragmentDoc}
`;

/**
 * __useGetAppointmentQuery__
 *
 * To run a query within a React component, call `useGetAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentQuery,
    GetAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentQuery, GetAppointmentQueryVariables>(
    GetAppointmentDocument,
    options,
  );
}
export function useGetAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentQuery,
    GetAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppointmentQuery, GetAppointmentQueryVariables>(
    GetAppointmentDocument,
    options,
  );
}
export type GetAppointmentQueryHookResult = ReturnType<
  typeof useGetAppointmentQuery
>;
export type GetAppointmentLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentLazyQuery
>;
export type GetAppointmentQueryResult = Apollo.QueryResult<
  GetAppointmentQuery,
  GetAppointmentQueryVariables
>;
export const AddAppointmentDocument = gql`
  mutation AddAppointment($data: AppointmentAddInput!) {
    addAppointments(data: $data) {
      ...AppointmentData
    }
  }
  ${AppointmentDataFragmentDoc}
`;
export type AddAppointmentMutationFn = Apollo.MutationFunction<
  AddAppointmentMutation,
  AddAppointmentMutationVariables
>;

/**
 * __useAddAppointmentMutation__
 *
 * To run a mutation, you first call `useAddAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAppointmentMutation, { data, loading, error }] = useAddAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAppointmentMutation,
    AddAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAppointmentMutation,
    AddAppointmentMutationVariables
  >(AddAppointmentDocument, options);
}
export type AddAppointmentMutationHookResult = ReturnType<
  typeof useAddAppointmentMutation
>;
export type AddAppointmentMutationResult = Apollo.MutationResult<
  AddAppointmentMutation
>;
export type AddAppointmentMutationOptions = Apollo.BaseMutationOptions<
  AddAppointmentMutation,
  AddAppointmentMutationVariables
>;
export const AddBreakDocument = gql`
  mutation AddBreak($data: AppointmentBreakInput!) {
    addBreak(data: $data) {
      ...AppointmentData
    }
  }
  ${AppointmentDataFragmentDoc}
`;
export type AddBreakMutationFn = Apollo.MutationFunction<
  AddBreakMutation,
  AddBreakMutationVariables
>;

/**
 * __useAddBreakMutation__
 *
 * To run a mutation, you first call `useAddBreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBreakMutation, { data, loading, error }] = useAddBreakMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddBreakMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBreakMutation,
    AddBreakMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBreakMutation, AddBreakMutationVariables>(
    AddBreakDocument,
    options,
  );
}
export type AddBreakMutationHookResult = ReturnType<typeof useAddBreakMutation>;
export type AddBreakMutationResult = Apollo.MutationResult<AddBreakMutation>;
export type AddBreakMutationOptions = Apollo.BaseMutationOptions<
  AddBreakMutation,
  AddBreakMutationVariables
>;
export const UpdateAppointmentDocument = gql`
  mutation UpdateAppointment($id: Int!, $data: AppointmentUpdateInput!) {
    updateAppointment(id: $id, data: $data) {
      ...AppointmentData
    }
  }
  ${AppointmentDataFragmentDoc}
`;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >(UpdateAppointmentDocument, options);
}
export type UpdateAppointmentMutationHookResult = ReturnType<
  typeof useUpdateAppointmentMutation
>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<
  UpdateAppointmentMutation
>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;
export const DeleteAppointmentDocument = gql`
  mutation DeleteAppointment($id: Int!) {
    deleteAppointment(id: $id)
  }
`;
export type DeleteAppointmentMutationFn = Apollo.MutationFunction<
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables
>;

/**
 * __useDeleteAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentMutation, { data, loading, error }] = useDeleteAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppointmentMutation,
    DeleteAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAppointmentMutation,
    DeleteAppointmentMutationVariables
  >(DeleteAppointmentDocument, options);
}
export type DeleteAppointmentMutationHookResult = ReturnType<
  typeof useDeleteAppointmentMutation
>;
export type DeleteAppointmentMutationResult = Apollo.MutationResult<
  DeleteAppointmentMutation
>;
export type DeleteAppointmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables
>;
export const OnAppointmentAddedDocument = gql`
  subscription OnAppointmentAdded {
    appointmentAdded {
      ...AppointmentData
    }
  }
  ${AppointmentDataFragmentDoc}
`;

/**
 * __useOnAppointmentAddedSubscription__
 *
 * To run a query within a React component, call `useOnAppointmentAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAppointmentAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAppointmentAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAppointmentAddedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnAppointmentAddedSubscription,
    OnAppointmentAddedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnAppointmentAddedSubscription,
    OnAppointmentAddedSubscriptionVariables
  >(OnAppointmentAddedDocument, options);
}
export type OnAppointmentAddedSubscriptionHookResult = ReturnType<
  typeof useOnAppointmentAddedSubscription
>;
export type OnAppointmentAddedSubscriptionResult = Apollo.SubscriptionResult<
  OnAppointmentAddedSubscription
>;
export const AddReservationDocument = gql`
  mutation AddReservation($data: ReservationInput!) {
    addReservation(data: $data) {
      ...ReservationData
    }
  }
  ${ReservationDataFragmentDoc}
`;
export type AddReservationMutationFn = Apollo.MutationFunction<
  AddReservationMutation,
  AddReservationMutationVariables
>;

/**
 * __useAddReservationMutation__
 *
 * To run a mutation, you first call `useAddReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReservationMutation, { data, loading, error }] = useAddReservationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddReservationMutation,
    AddReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddReservationMutation,
    AddReservationMutationVariables
  >(AddReservationDocument, options);
}
export type AddReservationMutationHookResult = ReturnType<
  typeof useAddReservationMutation
>;
export type AddReservationMutationResult = Apollo.MutationResult<
  AddReservationMutation
>;
export type AddReservationMutationOptions = Apollo.BaseMutationOptions<
  AddReservationMutation,
  AddReservationMutationVariables
>;
export const UpdateReservationDocument = gql`
  mutation UpdateReservation($id: String!, $data: ReservationInput!) {
    updateReservation(id: $id, data: $data) {
      ...ReservationData
    }
  }
  ${ReservationDataFragmentDoc}
`;
export type UpdateReservationMutationFn = Apollo.MutationFunction<
  UpdateReservationMutation,
  UpdateReservationMutationVariables
>;

/**
 * __useUpdateReservationMutation__
 *
 * To run a mutation, you first call `useUpdateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationMutation, { data, loading, error }] = useUpdateReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReservationMutation,
    UpdateReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReservationMutation,
    UpdateReservationMutationVariables
  >(UpdateReservationDocument, options);
}
export type UpdateReservationMutationHookResult = ReturnType<
  typeof useUpdateReservationMutation
>;
export type UpdateReservationMutationResult = Apollo.MutationResult<
  UpdateReservationMutation
>;
export type UpdateReservationMutationOptions = Apollo.BaseMutationOptions<
  UpdateReservationMutation,
  UpdateReservationMutationVariables
>;
export const DeleteReservationDocument = gql`
  mutation DeleteReservation($id: String!) {
    deleteReservation(id: $id)
  }
`;
export type DeleteReservationMutationFn = Apollo.MutationFunction<
  DeleteReservationMutation,
  DeleteReservationMutationVariables
>;

/**
 * __useDeleteReservationMutation__
 *
 * To run a mutation, you first call `useDeleteReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReservationMutation, { data, loading, error }] = useDeleteReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReservationMutation,
    DeleteReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteReservationMutation,
    DeleteReservationMutationVariables
  >(DeleteReservationDocument, options);
}
export type DeleteReservationMutationHookResult = ReturnType<
  typeof useDeleteReservationMutation
>;
export type DeleteReservationMutationResult = Apollo.MutationResult<
  DeleteReservationMutation
>;
export type DeleteReservationMutationOptions = Apollo.BaseMutationOptions<
  DeleteReservationMutation,
  DeleteReservationMutationVariables
>;
export const OnAppointmentUpdatedDocument = gql`
  subscription OnAppointmentUpdated {
    appointmentUpdated {
      ...AppointmentData
    }
  }
  ${AppointmentDataFragmentDoc}
`;

/**
 * __useOnAppointmentUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnAppointmentUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAppointmentUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAppointmentUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAppointmentUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnAppointmentUpdatedSubscription,
    OnAppointmentUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnAppointmentUpdatedSubscription,
    OnAppointmentUpdatedSubscriptionVariables
  >(OnAppointmentUpdatedDocument, options);
}
export type OnAppointmentUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnAppointmentUpdatedSubscription
>;
export type OnAppointmentUpdatedSubscriptionResult = Apollo.SubscriptionResult<
  OnAppointmentUpdatedSubscription
>;
export const OnAppointmentDeletedDocument = gql`
  subscription OnAppointmentDeleted {
    appointmentDeleted
  }
`;

/**
 * __useOnAppointmentDeletedSubscription__
 *
 * To run a query within a React component, call `useOnAppointmentDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAppointmentDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAppointmentDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAppointmentDeletedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnAppointmentDeletedSubscription,
    OnAppointmentDeletedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnAppointmentDeletedSubscription,
    OnAppointmentDeletedSubscriptionVariables
  >(OnAppointmentDeletedDocument, options);
}
export type OnAppointmentDeletedSubscriptionHookResult = ReturnType<
  typeof useOnAppointmentDeletedSubscription
>;
export type OnAppointmentDeletedSubscriptionResult = Apollo.SubscriptionResult<
  OnAppointmentDeletedSubscription
>;
export const OnReservationAddedDocument = gql`
  subscription OnReservationAdded {
    reservationAdded {
      ...ReservationData
    }
  }
  ${ReservationDataFragmentDoc}
`;

/**
 * __useOnReservationAddedSubscription__
 *
 * To run a query within a React component, call `useOnReservationAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnReservationAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnReservationAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnReservationAddedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnReservationAddedSubscription,
    OnReservationAddedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnReservationAddedSubscription,
    OnReservationAddedSubscriptionVariables
  >(OnReservationAddedDocument, options);
}
export type OnReservationAddedSubscriptionHookResult = ReturnType<
  typeof useOnReservationAddedSubscription
>;
export type OnReservationAddedSubscriptionResult = Apollo.SubscriptionResult<
  OnReservationAddedSubscription
>;
export const OnReservationUpdatedDocument = gql`
  subscription OnReservationUpdated {
    reservationUpdated {
      ...ReservationData
    }
  }
  ${ReservationDataFragmentDoc}
`;

/**
 * __useOnReservationUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnReservationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnReservationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnReservationUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnReservationUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnReservationUpdatedSubscription,
    OnReservationUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnReservationUpdatedSubscription,
    OnReservationUpdatedSubscriptionVariables
  >(OnReservationUpdatedDocument, options);
}
export type OnReservationUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnReservationUpdatedSubscription
>;
export type OnReservationUpdatedSubscriptionResult = Apollo.SubscriptionResult<
  OnReservationUpdatedSubscription
>;
export const OnReservationDeletedDocument = gql`
  subscription OnReservationDeleted {
    reservationDeleted
  }
`;

/**
 * __useOnReservationDeletedSubscription__
 *
 * To run a query within a React component, call `useOnReservationDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnReservationDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnReservationDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnReservationDeletedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnReservationDeletedSubscription,
    OnReservationDeletedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnReservationDeletedSubscription,
    OnReservationDeletedSubscriptionVariables
  >(OnReservationDeletedDocument, options);
}
export type OnReservationDeletedSubscriptionHookResult = ReturnType<
  typeof useOnReservationDeletedSubscription
>;
export type OnReservationDeletedSubscriptionResult = Apollo.SubscriptionResult<
  OnReservationDeletedSubscription
>;
export const AddComplaintDocument = gql`
  mutation AddComplaint($newComplaint: ComplaintInput!) {
    createComplaint(createComplaintInput: $newComplaint) {
      ...ComplaintData
    }
  }
  ${ComplaintDataFragmentDoc}
`;
export type AddComplaintMutationFn = Apollo.MutationFunction<
  AddComplaintMutation,
  AddComplaintMutationVariables
>;

/**
 * __useAddComplaintMutation__
 *
 * To run a mutation, you first call `useAddComplaintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddComplaintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addComplaintMutation, { data, loading, error }] = useAddComplaintMutation({
 *   variables: {
 *      newComplaint: // value for 'newComplaint'
 *   },
 * });
 */
export function useAddComplaintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddComplaintMutation,
    AddComplaintMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddComplaintMutation,
    AddComplaintMutationVariables
  >(AddComplaintDocument, options);
}
export type AddComplaintMutationHookResult = ReturnType<
  typeof useAddComplaintMutation
>;
export type AddComplaintMutationResult = Apollo.MutationResult<
  AddComplaintMutation
>;
export type AddComplaintMutationOptions = Apollo.BaseMutationOptions<
  AddComplaintMutation,
  AddComplaintMutationVariables
>;
export const UpdateComplaintDocument = gql`
  mutation UpdateComplaint($id: Int!, $complaint: UpdateComplaintInput!) {
    updateComplaint(id: $id, updateComplaintInput: $complaint) {
      ...ComplaintData
    }
  }
  ${ComplaintDataFragmentDoc}
`;
export type UpdateComplaintMutationFn = Apollo.MutationFunction<
  UpdateComplaintMutation,
  UpdateComplaintMutationVariables
>;

/**
 * __useUpdateComplaintMutation__
 *
 * To run a mutation, you first call `useUpdateComplaintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComplaintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComplaintMutation, { data, loading, error }] = useUpdateComplaintMutation({
 *   variables: {
 *      id: // value for 'id'
 *      complaint: // value for 'complaint'
 *   },
 * });
 */
export function useUpdateComplaintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateComplaintMutation,
    UpdateComplaintMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateComplaintMutation,
    UpdateComplaintMutationVariables
  >(UpdateComplaintDocument, options);
}
export type UpdateComplaintMutationHookResult = ReturnType<
  typeof useUpdateComplaintMutation
>;
export type UpdateComplaintMutationResult = Apollo.MutationResult<
  UpdateComplaintMutation
>;
export type UpdateComplaintMutationOptions = Apollo.BaseMutationOptions<
  UpdateComplaintMutation,
  UpdateComplaintMutationVariables
>;
export const GetComplaintDocument = gql`
  query GetComplaint($id: Int!) {
    complaint(id: $id) {
      ...ComplaintData
    }
  }
  ${ComplaintDataFragmentDoc}
`;

/**
 * __useGetComplaintQuery__
 *
 * To run a query within a React component, call `useGetComplaintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplaintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplaintQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetComplaintQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplaintQuery,
    GetComplaintQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetComplaintQuery, GetComplaintQueryVariables>(
    GetComplaintDocument,
    options,
  );
}
export function useGetComplaintLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplaintQuery,
    GetComplaintQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetComplaintQuery, GetComplaintQueryVariables>(
    GetComplaintDocument,
    options,
  );
}
export type GetComplaintQueryHookResult = ReturnType<
  typeof useGetComplaintQuery
>;
export type GetComplaintLazyQueryHookResult = ReturnType<
  typeof useGetComplaintLazyQuery
>;
export type GetComplaintQueryResult = Apollo.QueryResult<
  GetComplaintQuery,
  GetComplaintQueryVariables
>;
export const ComplaintsDocument = gql`
  query Complaints(
    $pagination: PaginationInput!
    $where: ComplaintFiltersInput
    $orderBy: [OrderColumn!]
  ) {
    complaints(pagination: $pagination, where: $where, orderBy: $orderBy) {
      items {
        ...ComplaintData
      }
      total
      hasMore
    }
  }
  ${ComplaintDataFragmentDoc}
`;

/**
 * __useComplaintsQuery__
 *
 * To run a query within a React component, call `useComplaintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplaintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplaintsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useComplaintsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplaintsQuery,
    ComplaintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ComplaintsQuery, ComplaintsQueryVariables>(
    ComplaintsDocument,
    options,
  );
}
export function useComplaintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplaintsQuery,
    ComplaintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ComplaintsQuery, ComplaintsQueryVariables>(
    ComplaintsDocument,
    options,
  );
}
export type ComplaintsQueryHookResult = ReturnType<typeof useComplaintsQuery>;
export type ComplaintsLazyQueryHookResult = ReturnType<
  typeof useComplaintsLazyQuery
>;
export type ComplaintsQueryResult = Apollo.QueryResult<
  ComplaintsQuery,
  ComplaintsQueryVariables
>;
export const UserCouncilsDocument = gql`
  query UserCouncils($userId: Float!) {
    getCouncils(userId: $userId) {
      ...CouncilFragment
    }
  }
  ${CouncilFragmentFragmentDoc}
`;

/**
 * __useUserCouncilsQuery__
 *
 * To run a query within a React component, call `useUserCouncilsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCouncilsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCouncilsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserCouncilsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserCouncilsQuery,
    UserCouncilsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserCouncilsQuery, UserCouncilsQueryVariables>(
    UserCouncilsDocument,
    options,
  );
}
export function useUserCouncilsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCouncilsQuery,
    UserCouncilsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserCouncilsQuery, UserCouncilsQueryVariables>(
    UserCouncilsDocument,
    options,
  );
}
export type UserCouncilsQueryHookResult = ReturnType<
  typeof useUserCouncilsQuery
>;
export type UserCouncilsLazyQueryHookResult = ReturnType<
  typeof useUserCouncilsLazyQuery
>;
export type UserCouncilsQueryResult = Apollo.QueryResult<
  UserCouncilsQuery,
  UserCouncilsQueryVariables
>;
export const MyCouncilsDocument = gql`
  query MyCouncils {
    getMyCouncils {
      ...CouncilFragment
    }
  }
  ${CouncilFragmentFragmentDoc}
`;

/**
 * __useMyCouncilsQuery__
 *
 * To run a query within a React component, call `useMyCouncilsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCouncilsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCouncilsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCouncilsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyCouncilsQuery,
    MyCouncilsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCouncilsQuery, MyCouncilsQueryVariables>(
    MyCouncilsDocument,
    options,
  );
}
export function useMyCouncilsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCouncilsQuery,
    MyCouncilsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyCouncilsQuery, MyCouncilsQueryVariables>(
    MyCouncilsDocument,
    options,
  );
}
export type MyCouncilsQueryHookResult = ReturnType<typeof useMyCouncilsQuery>;
export type MyCouncilsLazyQueryHookResult = ReturnType<
  typeof useMyCouncilsLazyQuery
>;
export type MyCouncilsQueryResult = Apollo.QueryResult<
  MyCouncilsQuery,
  MyCouncilsQueryVariables
>;
export const MyCouncilInvitesDocument = gql`
  query MyCouncilInvites {
    getMyCouncilInvites {
      ...CouncilFragment
    }
  }
  ${CouncilFragmentFragmentDoc}
`;

/**
 * __useMyCouncilInvitesQuery__
 *
 * To run a query within a React component, call `useMyCouncilInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCouncilInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCouncilInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCouncilInvitesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyCouncilInvitesQuery,
    MyCouncilInvitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCouncilInvitesQuery, MyCouncilInvitesQueryVariables>(
    MyCouncilInvitesDocument,
    options,
  );
}
export function useMyCouncilInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCouncilInvitesQuery,
    MyCouncilInvitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyCouncilInvitesQuery,
    MyCouncilInvitesQueryVariables
  >(MyCouncilInvitesDocument, options);
}
export type MyCouncilInvitesQueryHookResult = ReturnType<
  typeof useMyCouncilInvitesQuery
>;
export type MyCouncilInvitesLazyQueryHookResult = ReturnType<
  typeof useMyCouncilInvitesLazyQuery
>;
export type MyCouncilInvitesQueryResult = Apollo.QueryResult<
  MyCouncilInvitesQuery,
  MyCouncilInvitesQueryVariables
>;
export const MyCouncilParticipationsDocument = gql`
  query MyCouncilParticipations {
    getMyCouncilParticipations {
      ...CouncilFragment
    }
  }
  ${CouncilFragmentFragmentDoc}
`;

/**
 * __useMyCouncilParticipationsQuery__
 *
 * To run a query within a React component, call `useMyCouncilParticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCouncilParticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCouncilParticipationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCouncilParticipationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyCouncilParticipationsQuery,
    MyCouncilParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyCouncilParticipationsQuery,
    MyCouncilParticipationsQueryVariables
  >(MyCouncilParticipationsDocument, options);
}
export function useMyCouncilParticipationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCouncilParticipationsQuery,
    MyCouncilParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyCouncilParticipationsQuery,
    MyCouncilParticipationsQueryVariables
  >(MyCouncilParticipationsDocument, options);
}
export type MyCouncilParticipationsQueryHookResult = ReturnType<
  typeof useMyCouncilParticipationsQuery
>;
export type MyCouncilParticipationsLazyQueryHookResult = ReturnType<
  typeof useMyCouncilParticipationsLazyQuery
>;
export type MyCouncilParticipationsQueryResult = Apollo.QueryResult<
  MyCouncilParticipationsQuery,
  MyCouncilParticipationsQueryVariables
>;
export const CreateCouncilDocument = gql`
  mutation CreateCouncil($councilInput: CouncilInput!) {
    createCouncil(data: $councilInput) {
      id
    }
  }
`;
export type CreateCouncilMutationFn = Apollo.MutationFunction<
  CreateCouncilMutation,
  CreateCouncilMutationVariables
>;

/**
 * __useCreateCouncilMutation__
 *
 * To run a mutation, you first call `useCreateCouncilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouncilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouncilMutation, { data, loading, error }] = useCreateCouncilMutation({
 *   variables: {
 *      councilInput: // value for 'councilInput'
 *   },
 * });
 */
export function useCreateCouncilMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCouncilMutation,
    CreateCouncilMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCouncilMutation,
    CreateCouncilMutationVariables
  >(CreateCouncilDocument, options);
}
export type CreateCouncilMutationHookResult = ReturnType<
  typeof useCreateCouncilMutation
>;
export type CreateCouncilMutationResult = Apollo.MutationResult<
  CreateCouncilMutation
>;
export type CreateCouncilMutationOptions = Apollo.BaseMutationOptions<
  CreateCouncilMutation,
  CreateCouncilMutationVariables
>;
export const RemoveDoctorFromCouncilDocument = gql`
  mutation RemoveDoctorFromCouncil(
    $councilId: Int
    $doctorId: Int
    $doctorEmail: String
  ) {
    removeDoctorFromCouncil(
      councilId: $councilId
      doctorId: $doctorId
      doctorEmail: $doctorEmail
    ) {
      id
    }
  }
`;
export type RemoveDoctorFromCouncilMutationFn = Apollo.MutationFunction<
  RemoveDoctorFromCouncilMutation,
  RemoveDoctorFromCouncilMutationVariables
>;

/**
 * __useRemoveDoctorFromCouncilMutation__
 *
 * To run a mutation, you first call `useRemoveDoctorFromCouncilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDoctorFromCouncilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDoctorFromCouncilMutation, { data, loading, error }] = useRemoveDoctorFromCouncilMutation({
 *   variables: {
 *      councilId: // value for 'councilId'
 *      doctorId: // value for 'doctorId'
 *      doctorEmail: // value for 'doctorEmail'
 *   },
 * });
 */
export function useRemoveDoctorFromCouncilMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDoctorFromCouncilMutation,
    RemoveDoctorFromCouncilMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveDoctorFromCouncilMutation,
    RemoveDoctorFromCouncilMutationVariables
  >(RemoveDoctorFromCouncilDocument, options);
}
export type RemoveDoctorFromCouncilMutationHookResult = ReturnType<
  typeof useRemoveDoctorFromCouncilMutation
>;
export type RemoveDoctorFromCouncilMutationResult = Apollo.MutationResult<
  RemoveDoctorFromCouncilMutation
>;
export type RemoveDoctorFromCouncilMutationOptions = Apollo.BaseMutationOptions<
  RemoveDoctorFromCouncilMutation,
  RemoveDoctorFromCouncilMutationVariables
>;
export const InviteDoctorToCouncilDocument = gql`
  mutation InviteDoctorToCouncil(
    $councilId: Int
    $doctorEmail: [String!]!
    $userId: Int
  ) {
    inviteDoctorsToCouncil(
      councilId: $councilId
      doctorEmail: $doctorEmail
      userId: $userId
    ) {
      id
    }
  }
`;
export type InviteDoctorToCouncilMutationFn = Apollo.MutationFunction<
  InviteDoctorToCouncilMutation,
  InviteDoctorToCouncilMutationVariables
>;

/**
 * __useInviteDoctorToCouncilMutation__
 *
 * To run a mutation, you first call `useInviteDoctorToCouncilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteDoctorToCouncilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteDoctorToCouncilMutation, { data, loading, error }] = useInviteDoctorToCouncilMutation({
 *   variables: {
 *      councilId: // value for 'councilId'
 *      doctorEmail: // value for 'doctorEmail'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInviteDoctorToCouncilMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteDoctorToCouncilMutation,
    InviteDoctorToCouncilMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteDoctorToCouncilMutation,
    InviteDoctorToCouncilMutationVariables
  >(InviteDoctorToCouncilDocument, options);
}
export type InviteDoctorToCouncilMutationHookResult = ReturnType<
  typeof useInviteDoctorToCouncilMutation
>;
export type InviteDoctorToCouncilMutationResult = Apollo.MutationResult<
  InviteDoctorToCouncilMutation
>;
export type InviteDoctorToCouncilMutationOptions = Apollo.BaseMutationOptions<
  InviteDoctorToCouncilMutation,
  InviteDoctorToCouncilMutationVariables
>;
export const AcceptInviteToCouncilDocument = gql`
  mutation AcceptInviteToCouncil($councilId: Int) {
    acceptInviteToCouncil(councilId: $councilId) {
      id
    }
  }
`;
export type AcceptInviteToCouncilMutationFn = Apollo.MutationFunction<
  AcceptInviteToCouncilMutation,
  AcceptInviteToCouncilMutationVariables
>;

/**
 * __useAcceptInviteToCouncilMutation__
 *
 * To run a mutation, you first call `useAcceptInviteToCouncilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteToCouncilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteToCouncilMutation, { data, loading, error }] = useAcceptInviteToCouncilMutation({
 *   variables: {
 *      councilId: // value for 'councilId'
 *   },
 * });
 */
export function useAcceptInviteToCouncilMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInviteToCouncilMutation,
    AcceptInviteToCouncilMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptInviteToCouncilMutation,
    AcceptInviteToCouncilMutationVariables
  >(AcceptInviteToCouncilDocument, options);
}
export type AcceptInviteToCouncilMutationHookResult = ReturnType<
  typeof useAcceptInviteToCouncilMutation
>;
export type AcceptInviteToCouncilMutationResult = Apollo.MutationResult<
  AcceptInviteToCouncilMutation
>;
export type AcceptInviteToCouncilMutationOptions = Apollo.BaseMutationOptions<
  AcceptInviteToCouncilMutation,
  AcceptInviteToCouncilMutationVariables
>;
export const RejectInviteToCouncilDocument = gql`
  mutation RejectInviteToCouncil($councilId: Int) {
    rejectInviteToCouncil(councilId: $councilId) {
      id
    }
  }
`;
export type RejectInviteToCouncilMutationFn = Apollo.MutationFunction<
  RejectInviteToCouncilMutation,
  RejectInviteToCouncilMutationVariables
>;

/**
 * __useRejectInviteToCouncilMutation__
 *
 * To run a mutation, you first call `useRejectInviteToCouncilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectInviteToCouncilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectInviteToCouncilMutation, { data, loading, error }] = useRejectInviteToCouncilMutation({
 *   variables: {
 *      councilId: // value for 'councilId'
 *   },
 * });
 */
export function useRejectInviteToCouncilMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectInviteToCouncilMutation,
    RejectInviteToCouncilMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectInviteToCouncilMutation,
    RejectInviteToCouncilMutationVariables
  >(RejectInviteToCouncilDocument, options);
}
export type RejectInviteToCouncilMutationHookResult = ReturnType<
  typeof useRejectInviteToCouncilMutation
>;
export type RejectInviteToCouncilMutationResult = Apollo.MutationResult<
  RejectInviteToCouncilMutation
>;
export type RejectInviteToCouncilMutationOptions = Apollo.BaseMutationOptions<
  RejectInviteToCouncilMutation,
  RejectInviteToCouncilMutationVariables
>;
export const CouncilInviteAddedDocument = gql`
  subscription councilInviteAdded {
    councilInviteAdded {
      ...CouncilFragment
    }
  }
  ${CouncilFragmentFragmentDoc}
`;

/**
 * __useCouncilInviteAddedSubscription__
 *
 * To run a query within a React component, call `useCouncilInviteAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCouncilInviteAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouncilInviteAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCouncilInviteAddedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CouncilInviteAddedSubscription,
    CouncilInviteAddedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CouncilInviteAddedSubscription,
    CouncilInviteAddedSubscriptionVariables
  >(CouncilInviteAddedDocument, options);
}
export type CouncilInviteAddedSubscriptionHookResult = ReturnType<
  typeof useCouncilInviteAddedSubscription
>;
export type CouncilInviteAddedSubscriptionResult = Apollo.SubscriptionResult<
  CouncilInviteAddedSubscription
>;
export const CouncilInviteAcceptedDocument = gql`
  subscription councilInviteAccepted {
    councilInviteAccepted {
      ...CouncilFragment
    }
  }
  ${CouncilFragmentFragmentDoc}
`;

/**
 * __useCouncilInviteAcceptedSubscription__
 *
 * To run a query within a React component, call `useCouncilInviteAcceptedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCouncilInviteAcceptedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouncilInviteAcceptedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCouncilInviteAcceptedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CouncilInviteAcceptedSubscription,
    CouncilInviteAcceptedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CouncilInviteAcceptedSubscription,
    CouncilInviteAcceptedSubscriptionVariables
  >(CouncilInviteAcceptedDocument, options);
}
export type CouncilInviteAcceptedSubscriptionHookResult = ReturnType<
  typeof useCouncilInviteAcceptedSubscription
>;
export type CouncilInviteAcceptedSubscriptionResult = Apollo.SubscriptionResult<
  CouncilInviteAcceptedSubscription
>;
export const CouncilInviteRejectedDocument = gql`
  subscription councilInviteRejected {
    councilInviteRejected {
      ...CouncilFragment
    }
  }
  ${CouncilFragmentFragmentDoc}
`;

/**
 * __useCouncilInviteRejectedSubscription__
 *
 * To run a query within a React component, call `useCouncilInviteRejectedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCouncilInviteRejectedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouncilInviteRejectedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCouncilInviteRejectedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CouncilInviteRejectedSubscription,
    CouncilInviteRejectedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CouncilInviteRejectedSubscription,
    CouncilInviteRejectedSubscriptionVariables
  >(CouncilInviteRejectedDocument, options);
}
export type CouncilInviteRejectedSubscriptionHookResult = ReturnType<
  typeof useCouncilInviteRejectedSubscription
>;
export type CouncilInviteRejectedSubscriptionResult = Apollo.SubscriptionResult<
  CouncilInviteRejectedSubscription
>;
export const AddDoctorDocument = gql`
  mutation AddDoctor($data: NoUserDoctorInput!) {
    addDoctor(data: $data) {
      createdAt
      createdById
      licenseNumber
      user {
        contact {
          addresses {
            city
            country
            id
            postalCode
            street
          }
          emailAddresses
          phoneNumbers
          primaryDescription
          firstName
          lastName
          gender
        }
      }
    }
  }
`;
export type AddDoctorMutationFn = Apollo.MutationFunction<
  AddDoctorMutation,
  AddDoctorMutationVariables
>;

/**
 * __useAddDoctorMutation__
 *
 * To run a mutation, you first call `useAddDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDoctorMutation, { data, loading, error }] = useAddDoctorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddDoctorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDoctorMutation,
    AddDoctorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDoctorMutation, AddDoctorMutationVariables>(
    AddDoctorDocument,
    options,
  );
}
export type AddDoctorMutationHookResult = ReturnType<
  typeof useAddDoctorMutation
>;
export type AddDoctorMutationResult = Apollo.MutationResult<AddDoctorMutation>;
export type AddDoctorMutationOptions = Apollo.BaseMutationOptions<
  AddDoctorMutation,
  AddDoctorMutationVariables
>;
export const UpdateDoctorDocument = gql`
  mutation UpdateDoctor($id: Int!, $doctor: DoctorInput!) {
    updateDoctor(id: $id, data: $doctor) {
      ...DoctorData
    }
  }
  ${DoctorDataFragmentDoc}
`;
export type UpdateDoctorMutationFn = Apollo.MutationFunction<
  UpdateDoctorMutation,
  UpdateDoctorMutationVariables
>;

/**
 * __useUpdateDoctorMutation__
 *
 * To run a mutation, you first call `useUpdateDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoctorMutation, { data, loading, error }] = useUpdateDoctorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doctor: // value for 'doctor'
 *   },
 * });
 */
export function useUpdateDoctorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDoctorMutation,
    UpdateDoctorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDoctorMutation,
    UpdateDoctorMutationVariables
  >(UpdateDoctorDocument, options);
}
export type UpdateDoctorMutationHookResult = ReturnType<
  typeof useUpdateDoctorMutation
>;
export type UpdateDoctorMutationResult = Apollo.MutationResult<
  UpdateDoctorMutation
>;
export type UpdateDoctorMutationOptions = Apollo.BaseMutationOptions<
  UpdateDoctorMutation,
  UpdateDoctorMutationVariables
>;
export const GetDoctorDocument = gql`
  query GetDoctor($id: Int!, $withPreferences: Boolean! = false) {
    doctor(id: $id) {
      ...FullDoctor
      statistics {
        loginCount7Days
        loginCount30Days
        loginCount90Days
        study2DCount7Days
        study2DCount30Days
        study2DCount90Days
        study3DCount7Days
        study3DCount30Days
        study3DCount90Days
        studiesValue
        studiesCount
        lastLoginDate
      }
    }
  }
  ${FullDoctorFragmentDoc}
`;

/**
 * __useGetDoctorQuery__
 *
 * To run a query within a React component, call `useGetDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withPreferences: // value for 'withPreferences'
 *   },
 * });
 */
export function useGetDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<GetDoctorQuery, GetDoctorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDoctorQuery, GetDoctorQueryVariables>(
    GetDoctorDocument,
    options,
  );
}
export function useGetDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDoctorQuery,
    GetDoctorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDoctorQuery, GetDoctorQueryVariables>(
    GetDoctorDocument,
    options,
  );
}
export type GetDoctorQueryHookResult = ReturnType<typeof useGetDoctorQuery>;
export type GetDoctorLazyQueryHookResult = ReturnType<
  typeof useGetDoctorLazyQuery
>;
export type GetDoctorQueryResult = Apollo.QueryResult<
  GetDoctorQuery,
  GetDoctorQueryVariables
>;
export const DeleteDoctorDocument = gql`
  mutation deleteDoctor($id: Int!, $assignStudiesTo: Int) {
    deleteDoctor(id: [$id], assignStudiesTo: $assignStudiesTo)
  }
`;
export type DeleteDoctorMutationFn = Apollo.MutationFunction<
  DeleteDoctorMutation,
  DeleteDoctorMutationVariables
>;

/**
 * __useDeleteDoctorMutation__
 *
 * To run a mutation, you first call `useDeleteDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDoctorMutation, { data, loading, error }] = useDeleteDoctorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      assignStudiesTo: // value for 'assignStudiesTo'
 *   },
 * });
 */
export function useDeleteDoctorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDoctorMutation,
    DeleteDoctorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDoctorMutation,
    DeleteDoctorMutationVariables
  >(DeleteDoctorDocument, options);
}
export type DeleteDoctorMutationHookResult = ReturnType<
  typeof useDeleteDoctorMutation
>;
export type DeleteDoctorMutationResult = Apollo.MutationResult<
  DeleteDoctorMutation
>;
export type DeleteDoctorMutationOptions = Apollo.BaseMutationOptions<
  DeleteDoctorMutation,
  DeleteDoctorMutationVariables
>;
export const ActivateUserDocument = gql`
  mutation activateUser($email: String!) {
    activate(email: $email) {
      email
      isActive
    }
  }
`;
export type ActivateUserMutationFn = Apollo.MutationFunction<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useActivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateUserMutation,
    ActivateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateUserMutation,
    ActivateUserMutationVariables
  >(ActivateUserDocument, options);
}
export type ActivateUserMutationHookResult = ReturnType<
  typeof useActivateUserMutation
>;
export type ActivateUserMutationResult = Apollo.MutationResult<
  ActivateUserMutation
>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;
export const UpdateStampPictureDocument = gql`
  mutation UpdateStampPicture(
    $updateStampPictureData: String!
    $doctorId: Int
  ) {
    updateStampPicture(
      pictureData: $updateStampPictureData
      doctorId: $doctorId
    ) {
      id
      uri
    }
  }
`;
export type UpdateStampPictureMutationFn = Apollo.MutationFunction<
  UpdateStampPictureMutation,
  UpdateStampPictureMutationVariables
>;

/**
 * __useUpdateStampPictureMutation__
 *
 * To run a mutation, you first call `useUpdateStampPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStampPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStampPictureMutation, { data, loading, error }] = useUpdateStampPictureMutation({
 *   variables: {
 *      updateStampPictureData: // value for 'updateStampPictureData'
 *      doctorId: // value for 'doctorId'
 *   },
 * });
 */
export function useUpdateStampPictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStampPictureMutation,
    UpdateStampPictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStampPictureMutation,
    UpdateStampPictureMutationVariables
  >(UpdateStampPictureDocument, options);
}
export type UpdateStampPictureMutationHookResult = ReturnType<
  typeof useUpdateStampPictureMutation
>;
export type UpdateStampPictureMutationResult = Apollo.MutationResult<
  UpdateStampPictureMutation
>;
export type UpdateStampPictureMutationOptions = Apollo.BaseMutationOptions<
  UpdateStampPictureMutation,
  UpdateStampPictureMutationVariables
>;
export const DeleteExistingStampPictureDocument = gql`
  mutation DeleteExistingStampPicture($doctorId: Int) {
    deleteExistingStampPicture(doctorId: $doctorId)
  }
`;
export type DeleteExistingStampPictureMutationFn = Apollo.MutationFunction<
  DeleteExistingStampPictureMutation,
  DeleteExistingStampPictureMutationVariables
>;

/**
 * __useDeleteExistingStampPictureMutation__
 *
 * To run a mutation, you first call `useDeleteExistingStampPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExistingStampPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExistingStampPictureMutation, { data, loading, error }] = useDeleteExistingStampPictureMutation({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *   },
 * });
 */
export function useDeleteExistingStampPictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExistingStampPictureMutation,
    DeleteExistingStampPictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExistingStampPictureMutation,
    DeleteExistingStampPictureMutationVariables
  >(DeleteExistingStampPictureDocument, options);
}
export type DeleteExistingStampPictureMutationHookResult = ReturnType<
  typeof useDeleteExistingStampPictureMutation
>;
export type DeleteExistingStampPictureMutationResult = Apollo.MutationResult<
  DeleteExistingStampPictureMutation
>;
export type DeleteExistingStampPictureMutationOptions = Apollo.BaseMutationOptions<
  DeleteExistingStampPictureMutation,
  DeleteExistingStampPictureMutationVariables
>;
export const DoctorsDocument = gql`
  query Doctors(
    $pagination: PaginationInput!
    $where: DoctorFiltersInput
    $orderBy: [OrderColumn!]
  ) {
    doctors(pagination: $pagination, where: $where, orderBy: $orderBy)
      @connection(key: "doctors") {
      items {
        id
        createdAt
        licenseNumber
        user {
          id
          roles
          login
          active
          contact {
            id
            firstName
            lastName
            companyName
            primaryDescription
          }
          vacationStart
          vacationEnd
          lastLoginDate
          loginAttempts
        }
        maxStudyHoursToDescribe
        specializations
        describerInfo {
          assignedStudies
          descriptionOccupancyHours
          totalAssignedStudies
        }
        employedInBranches {
          id
          name
        }
      }
      total
      hasMore
    }
  }
`;

/**
 * __useDoctorsQuery__
 *
 * To run a query within a React component, call `useDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDoctorsQuery(
  baseOptions: Apollo.QueryHookOptions<DoctorsQuery, DoctorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DoctorsQuery, DoctorsQueryVariables>(
    DoctorsDocument,
    options,
  );
}
export function useDoctorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DoctorsQuery,
    DoctorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DoctorsQuery, DoctorsQueryVariables>(
    DoctorsDocument,
    options,
  );
}
export type DoctorsQueryHookResult = ReturnType<typeof useDoctorsQuery>;
export type DoctorsLazyQueryHookResult = ReturnType<typeof useDoctorsLazyQuery>;
export type DoctorsQueryResult = Apollo.QueryResult<
  DoctorsQuery,
  DoctorsQueryVariables
>;
export const MyMessagesDocument = gql`
  query MyMessages {
    allMessages {
      id
      linkedEntityId
      read
      title
      messageType
      createdAt
      important
    }
  }
`;

/**
 * __useMyMessagesQuery__
 *
 * To run a query within a React component, call `useMyMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyMessagesQuery,
    MyMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyMessagesQuery, MyMessagesQueryVariables>(
    MyMessagesDocument,
    options,
  );
}
export function useMyMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyMessagesQuery,
    MyMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyMessagesQuery, MyMessagesQueryVariables>(
    MyMessagesDocument,
    options,
  );
}
export type MyMessagesQueryHookResult = ReturnType<typeof useMyMessagesQuery>;
export type MyMessagesLazyQueryHookResult = ReturnType<
  typeof useMyMessagesLazyQuery
>;
export type MyMessagesQueryResult = Apollo.QueryResult<
  MyMessagesQuery,
  MyMessagesQueryVariables
>;
export const GetMessageDocument = gql`
  query getMessage($messageId: Int!) {
    message(id: $messageId) {
      id
      linkedEntityId
      read
      title
      content
      messageType
      createdAt
      important
    }
  }
`;

/**
 * __useGetMessageQuery__
 *
 * To run a query within a React component, call `useGetMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useGetMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessageQuery,
    GetMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMessageQuery, GetMessageQueryVariables>(
    GetMessageDocument,
    options,
  );
}
export function useGetMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageQuery,
    GetMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMessageQuery, GetMessageQueryVariables>(
    GetMessageDocument,
    options,
  );
}
export type GetMessageQueryHookResult = ReturnType<typeof useGetMessageQuery>;
export type GetMessageLazyQueryHookResult = ReturnType<
  typeof useGetMessageLazyQuery
>;
export type GetMessageQueryResult = Apollo.QueryResult<
  GetMessageQuery,
  GetMessageQueryVariables
>;
export const DeleteMessagesDocument = gql`
  mutation deleteMessages($messageIds: [Int!], $includeImportant: Boolean) {
    deleteMessage(ids: $messageIds, includeImportant: $includeImportant)
  }
`;
export type DeleteMessagesMutationFn = Apollo.MutationFunction<
  DeleteMessagesMutation,
  DeleteMessagesMutationVariables
>;

/**
 * __useDeleteMessagesMutation__
 *
 * To run a mutation, you first call `useDeleteMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessagesMutation, { data, loading, error }] = useDeleteMessagesMutation({
 *   variables: {
 *      messageIds: // value for 'messageIds'
 *      includeImportant: // value for 'includeImportant'
 *   },
 * });
 */
export function useDeleteMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMessagesMutation,
    DeleteMessagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMessagesMutation,
    DeleteMessagesMutationVariables
  >(DeleteMessagesDocument, options);
}
export type DeleteMessagesMutationHookResult = ReturnType<
  typeof useDeleteMessagesMutation
>;
export type DeleteMessagesMutationResult = Apollo.MutationResult<
  DeleteMessagesMutation
>;
export type DeleteMessagesMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessagesMutation,
  DeleteMessagesMutationVariables
>;
export const MarkMessageAsReadDocument = gql`
  mutation markMessageAsRead($messageIds: [Int!], $includeImportant: Boolean) {
    markAsRead(ids: $messageIds, includeImportant: $includeImportant)
  }
`;
export type MarkMessageAsReadMutationFn = Apollo.MutationFunction<
  MarkMessageAsReadMutation,
  MarkMessageAsReadMutationVariables
>;

/**
 * __useMarkMessageAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageAsReadMutation, { data, loading, error }] = useMarkMessageAsReadMutation({
 *   variables: {
 *      messageIds: // value for 'messageIds'
 *      includeImportant: // value for 'includeImportant'
 *   },
 * });
 */
export function useMarkMessageAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkMessageAsReadMutation,
    MarkMessageAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkMessageAsReadMutation,
    MarkMessageAsReadMutationVariables
  >(MarkMessageAsReadDocument, options);
}
export type MarkMessageAsReadMutationHookResult = ReturnType<
  typeof useMarkMessageAsReadMutation
>;
export type MarkMessageAsReadMutationResult = Apollo.MutationResult<
  MarkMessageAsReadMutation
>;
export type MarkMessageAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkMessageAsReadMutation,
  MarkMessageAsReadMutationVariables
>;
export const MessageAddedDocument = gql`
  subscription messageAdded {
    messageAdded {
      id
      linkedEntityId
      read
      title
      messageType
      createdAt
      important
      content
    }
  }
`;

/**
 * __useMessageAddedSubscription__
 *
 * To run a query within a React component, call `useMessageAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMessageAddedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MessageAddedSubscription,
    MessageAddedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MessageAddedSubscription,
    MessageAddedSubscriptionVariables
  >(MessageAddedDocument, options);
}
export type MessageAddedSubscriptionHookResult = ReturnType<
  typeof useMessageAddedSubscription
>;
export type MessageAddedSubscriptionResult = Apollo.SubscriptionResult<
  MessageAddedSubscription
>;
export const PatientStudiesDocument = gql`
  mutation PatientStudies($socialSecurityNumber: String!) {
    patientStudies(socialSecurityNumber: $socialSecurityNumber) {
      patientName
      patientBirthDate
      studies {
        id
        studyDate
        studyFileURLs
        descriptionFileURLs
        studyImageFileURL
        studyType
        is3D
        branchName
      }
    }
  }
`;
export type PatientStudiesMutationFn = Apollo.MutationFunction<
  PatientStudiesMutation,
  PatientStudiesMutationVariables
>;

/**
 * __usePatientStudiesMutation__
 *
 * To run a mutation, you first call `usePatientStudiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientStudiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientStudiesMutation, { data, loading, error }] = usePatientStudiesMutation({
 *   variables: {
 *      socialSecurityNumber: // value for 'socialSecurityNumber'
 *   },
 * });
 */
export function usePatientStudiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatientStudiesMutation,
    PatientStudiesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PatientStudiesMutation,
    PatientStudiesMutationVariables
  >(PatientStudiesDocument, options);
}
export type PatientStudiesMutationHookResult = ReturnType<
  typeof usePatientStudiesMutation
>;
export type PatientStudiesMutationResult = Apollo.MutationResult<
  PatientStudiesMutation
>;
export type PatientStudiesMutationOptions = Apollo.BaseMutationOptions<
  PatientStudiesMutation,
  PatientStudiesMutationVariables
>;
export const ResendPatientStudiesEmailDocument = gql`
  mutation ResendPatientStudiesEmail {
    resendStudiesLink
  }
`;
export type ResendPatientStudiesEmailMutationFn = Apollo.MutationFunction<
  ResendPatientStudiesEmailMutation,
  ResendPatientStudiesEmailMutationVariables
>;

/**
 * __useResendPatientStudiesEmailMutation__
 *
 * To run a mutation, you first call `useResendPatientStudiesEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPatientStudiesEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPatientStudiesEmailMutation, { data, loading, error }] = useResendPatientStudiesEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendPatientStudiesEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendPatientStudiesEmailMutation,
    ResendPatientStudiesEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendPatientStudiesEmailMutation,
    ResendPatientStudiesEmailMutationVariables
  >(ResendPatientStudiesEmailDocument, options);
}
export type ResendPatientStudiesEmailMutationHookResult = ReturnType<
  typeof useResendPatientStudiesEmailMutation
>;
export type ResendPatientStudiesEmailMutationResult = Apollo.MutationResult<
  ResendPatientStudiesEmailMutation
>;
export type ResendPatientStudiesEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendPatientStudiesEmailMutation,
  ResendPatientStudiesEmailMutationVariables
>;
export const SendPatientStudiesEmailDocument = gql`
  mutation SendPatientStudiesEmail($ssn: String!, $email: String!) {
    sendStudiesLink(ssn: $ssn, email: $email)
  }
`;
export type SendPatientStudiesEmailMutationFn = Apollo.MutationFunction<
  SendPatientStudiesEmailMutation,
  SendPatientStudiesEmailMutationVariables
>;

/**
 * __useSendPatientStudiesEmailMutation__
 *
 * To run a mutation, you first call `useSendPatientStudiesEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPatientStudiesEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPatientStudiesEmailMutation, { data, loading, error }] = useSendPatientStudiesEmailMutation({
 *   variables: {
 *      ssn: // value for 'ssn'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPatientStudiesEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPatientStudiesEmailMutation,
    SendPatientStudiesEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPatientStudiesEmailMutation,
    SendPatientStudiesEmailMutationVariables
  >(SendPatientStudiesEmailDocument, options);
}
export type SendPatientStudiesEmailMutationHookResult = ReturnType<
  typeof useSendPatientStudiesEmailMutation
>;
export type SendPatientStudiesEmailMutationResult = Apollo.MutationResult<
  SendPatientStudiesEmailMutation
>;
export type SendPatientStudiesEmailMutationOptions = Apollo.BaseMutationOptions<
  SendPatientStudiesEmailMutation,
  SendPatientStudiesEmailMutationVariables
>;
export const AddPatientDocument = gql`
  mutation AddPatient($newPatient: PatientInput!) {
    addPatient(data: $newPatient) {
      ...PatientData
    }
  }
  ${PatientDataFragmentDoc}
`;
export type AddPatientMutationFn = Apollo.MutationFunction<
  AddPatientMutation,
  AddPatientMutationVariables
>;

/**
 * __useAddPatientMutation__
 *
 * To run a mutation, you first call `useAddPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientMutation, { data, loading, error }] = useAddPatientMutation({
 *   variables: {
 *      newPatient: // value for 'newPatient'
 *   },
 * });
 */
export function useAddPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPatientMutation,
    AddPatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPatientMutation, AddPatientMutationVariables>(
    AddPatientDocument,
    options,
  );
}
export type AddPatientMutationHookResult = ReturnType<
  typeof useAddPatientMutation
>;
export type AddPatientMutationResult = Apollo.MutationResult<
  AddPatientMutation
>;
export type AddPatientMutationOptions = Apollo.BaseMutationOptions<
  AddPatientMutation,
  AddPatientMutationVariables
>;
export const UpdatePatientDocument = gql`
  mutation UpdatePatient($id: Int!, $patient: PatientInput!) {
    updatePatient(id: $id, data: $patient) {
      ...PatientData
    }
  }
  ${PatientDataFragmentDoc}
`;
export type UpdatePatientMutationFn = Apollo.MutationFunction<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patient: // value for 'patient'
 *   },
 * });
 */
export function useUpdatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >(UpdatePatientDocument, options);
}
export type UpdatePatientMutationHookResult = ReturnType<
  typeof useUpdatePatientMutation
>;
export type UpdatePatientMutationResult = Apollo.MutationResult<
  UpdatePatientMutation
>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;
export const GetPatientDocument = gql`
  query GetPatient(
    $id: Int!
    $withStudies: Boolean! = true
    $withAppointments: Boolean! = false
  ) {
    patient(id: $id) {
      ...PatientData
      studies @include(if: $withStudies) {
        ...PatientStudy
      }
      appointments @include(if: $withAppointments) {
        ...AppointmentData
      }
    }
  }
  ${PatientDataFragmentDoc}
  ${PatientStudyFragmentDoc}
  ${AppointmentDataFragmentDoc}
`;

/**
 * __useGetPatientQuery__
 *
 * To run a query within a React component, call `useGetPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withStudies: // value for 'withStudies'
 *      withAppointments: // value for 'withAppointments'
 *   },
 * });
 */
export function useGetPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientQuery,
    GetPatientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientQuery, GetPatientQueryVariables>(
    GetPatientDocument,
    options,
  );
}
export function useGetPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientQuery,
    GetPatientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientQuery, GetPatientQueryVariables>(
    GetPatientDocument,
    options,
  );
}
export type GetPatientQueryHookResult = ReturnType<typeof useGetPatientQuery>;
export type GetPatientLazyQueryHookResult = ReturnType<
  typeof useGetPatientLazyQuery
>;
export type GetPatientQueryResult = Apollo.QueryResult<
  GetPatientQuery,
  GetPatientQueryVariables
>;
export const DeletePatientDocument = gql`
  mutation deletePatient($id: Int!) {
    deletePatient(id: [$id])
  }
`;
export type DeletePatientMutationFn = Apollo.MutationFunction<
  DeletePatientMutation,
  DeletePatientMutationVariables
>;

/**
 * __useDeletePatientMutation__
 *
 * To run a mutation, you first call `useDeletePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientMutation, { data, loading, error }] = useDeletePatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePatientMutation,
    DeletePatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePatientMutation,
    DeletePatientMutationVariables
  >(DeletePatientDocument, options);
}
export type DeletePatientMutationHookResult = ReturnType<
  typeof useDeletePatientMutation
>;
export type DeletePatientMutationResult = Apollo.MutationResult<
  DeletePatientMutation
>;
export type DeletePatientMutationOptions = Apollo.BaseMutationOptions<
  DeletePatientMutation,
  DeletePatientMutationVariables
>;
export const SendPatientStudiesLinkDocument = gql`
  mutation sendPatientStudiesLink($id: Int!) {
    sendPatientStudiesLink(id: $id)
  }
`;
export type SendPatientStudiesLinkMutationFn = Apollo.MutationFunction<
  SendPatientStudiesLinkMutation,
  SendPatientStudiesLinkMutationVariables
>;

/**
 * __useSendPatientStudiesLinkMutation__
 *
 * To run a mutation, you first call `useSendPatientStudiesLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPatientStudiesLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPatientStudiesLinkMutation, { data, loading, error }] = useSendPatientStudiesLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendPatientStudiesLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPatientStudiesLinkMutation,
    SendPatientStudiesLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPatientStudiesLinkMutation,
    SendPatientStudiesLinkMutationVariables
  >(SendPatientStudiesLinkDocument, options);
}
export type SendPatientStudiesLinkMutationHookResult = ReturnType<
  typeof useSendPatientStudiesLinkMutation
>;
export type SendPatientStudiesLinkMutationResult = Apollo.MutationResult<
  SendPatientStudiesLinkMutation
>;
export type SendPatientStudiesLinkMutationOptions = Apollo.BaseMutationOptions<
  SendPatientStudiesLinkMutation,
  SendPatientStudiesLinkMutationVariables
>;
export const PatientsDocument = gql`
  query Patients(
    $pagination: PaginationInput!
    $where: PatientFiltersInput
    $orderBy: [OrderColumn!]
    $withStudies: Boolean! = true
  ) {
    patients(pagination: $pagination, where: $where, orderBy: $orderBy) {
      items {
        id
        contact {
          id
          firstName
          lastName
          birthDate
          gender
          emailAddresses
          primaryDescription
          ssn
        }
        studies @include(if: $withStudies) {
          id
        }
        registrationDate
      }
      total
      hasMore
    }
  }
`;

/**
 * __usePatientsQuery__
 *
 * To run a query within a React component, call `usePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      withStudies: // value for 'withStudies'
 *   },
 * });
 */
export function usePatientsQuery(
  baseOptions: Apollo.QueryHookOptions<PatientsQuery, PatientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientsQuery, PatientsQueryVariables>(
    PatientsDocument,
    options,
  );
}
export function usePatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientsQuery,
    PatientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientsQuery, PatientsQueryVariables>(
    PatientsDocument,
    options,
  );
}
export type PatientsQueryHookResult = ReturnType<typeof usePatientsQuery>;
export type PatientsLazyQueryHookResult = ReturnType<
  typeof usePatientsLazyQuery
>;
export type PatientsQueryResult = Apollo.QueryResult<
  PatientsQuery,
  PatientsQueryVariables
>;
export const AddReferalDocument = gql`
  mutation AddReferal($newReferal: ReferalInput!) {
    addReferal(data: $newReferal) {
      id
    }
  }
`;
export type AddReferalMutationFn = Apollo.MutationFunction<
  AddReferalMutation,
  AddReferalMutationVariables
>;

/**
 * __useAddReferalMutation__
 *
 * To run a mutation, you first call `useAddReferalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReferalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReferalMutation, { data, loading, error }] = useAddReferalMutation({
 *   variables: {
 *      newReferal: // value for 'newReferal'
 *   },
 * });
 */
export function useAddReferalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddReferalMutation,
    AddReferalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddReferalMutation, AddReferalMutationVariables>(
    AddReferalDocument,
    options,
  );
}
export type AddReferalMutationHookResult = ReturnType<
  typeof useAddReferalMutation
>;
export type AddReferalMutationResult = Apollo.MutationResult<
  AddReferalMutation
>;
export type AddReferalMutationOptions = Apollo.BaseMutationOptions<
  AddReferalMutation,
  AddReferalMutationVariables
>;
export const UpdateReferalDocument = gql`
  mutation UpdateReferal($id: Int!, $referal: ReferalInput!) {
    updateReferal(id: $id, data: $referal) {
      ...ReferalData
    }
  }
  ${ReferalDataFragmentDoc}
`;
export type UpdateReferalMutationFn = Apollo.MutationFunction<
  UpdateReferalMutation,
  UpdateReferalMutationVariables
>;

/**
 * __useUpdateReferalMutation__
 *
 * To run a mutation, you first call `useUpdateReferalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferalMutation, { data, loading, error }] = useUpdateReferalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      referal: // value for 'referal'
 *   },
 * });
 */
export function useUpdateReferalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReferalMutation,
    UpdateReferalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReferalMutation,
    UpdateReferalMutationVariables
  >(UpdateReferalDocument, options);
}
export type UpdateReferalMutationHookResult = ReturnType<
  typeof useUpdateReferalMutation
>;
export type UpdateReferalMutationResult = Apollo.MutationResult<
  UpdateReferalMutation
>;
export type UpdateReferalMutationOptions = Apollo.BaseMutationOptions<
  UpdateReferalMutation,
  UpdateReferalMutationVariables
>;
export const GetReferalDocument = gql`
  query GetReferal(
    $id: Int!
    $withStudies: Boolean! = false
    $withStudyTypePrices: Boolean! = false
  ) {
    referal(id: $id) {
      ...ReferalData
      referalToStudyTypes {
        id
        studyType {
          id
          name
          studyTypePrices @include(if: $withStudyTypePrices) {
            id
            price
            presetName
          }
          studyTypeRadiologies @include(if: $withStudyTypePrices) {
            radiology {
              presetName
              anodeCurrentMiliamper
              voltageKilovolts
              exposureTimeSeconds
              radiationDoseMilligrayPerSquareCentimeter
            }
          }
          descriptionPrice @include(if: $withStudyTypePrices)
        }
        remarks
        studySubTypes
      }
      studies @include(if: $withStudies) {
        id
        studyType {
          id
          name
        }
      }
    }
  }
  ${ReferalDataFragmentDoc}
`;

/**
 * __useGetReferalQuery__
 *
 * To run a query within a React component, call `useGetReferalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withStudies: // value for 'withStudies'
 *      withStudyTypePrices: // value for 'withStudyTypePrices'
 *   },
 * });
 */
export function useGetReferalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReferalQuery,
    GetReferalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReferalQuery, GetReferalQueryVariables>(
    GetReferalDocument,
    options,
  );
}
export function useGetReferalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReferalQuery,
    GetReferalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReferalQuery, GetReferalQueryVariables>(
    GetReferalDocument,
    options,
  );
}
export type GetReferalQueryHookResult = ReturnType<typeof useGetReferalQuery>;
export type GetReferalLazyQueryHookResult = ReturnType<
  typeof useGetReferalLazyQuery
>;
export type GetReferalQueryResult = Apollo.QueryResult<
  GetReferalQuery,
  GetReferalQueryVariables
>;
export const DeleteReferalDocument = gql`
  mutation deleteReferal($id: Int!) {
    deleteReferal(id: [$id])
  }
`;
export type DeleteReferalMutationFn = Apollo.MutationFunction<
  DeleteReferalMutation,
  DeleteReferalMutationVariables
>;

/**
 * __useDeleteReferalMutation__
 *
 * To run a mutation, you first call `useDeleteReferalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferalMutation, { data, loading, error }] = useDeleteReferalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReferalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReferalMutation,
    DeleteReferalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteReferalMutation,
    DeleteReferalMutationVariables
  >(DeleteReferalDocument, options);
}
export type DeleteReferalMutationHookResult = ReturnType<
  typeof useDeleteReferalMutation
>;
export type DeleteReferalMutationResult = Apollo.MutationResult<
  DeleteReferalMutation
>;
export type DeleteReferalMutationOptions = Apollo.BaseMutationOptions<
  DeleteReferalMutation,
  DeleteReferalMutationVariables
>;
export const CompleteReferalDocument = gql`
  mutation completeReferal($id: Int!) {
    completeReferal(id: $id)
  }
`;
export type CompleteReferalMutationFn = Apollo.MutationFunction<
  CompleteReferalMutation,
  CompleteReferalMutationVariables
>;

/**
 * __useCompleteReferalMutation__
 *
 * To run a mutation, you first call `useCompleteReferalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteReferalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeReferalMutation, { data, loading, error }] = useCompleteReferalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteReferalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteReferalMutation,
    CompleteReferalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteReferalMutation,
    CompleteReferalMutationVariables
  >(CompleteReferalDocument, options);
}
export type CompleteReferalMutationHookResult = ReturnType<
  typeof useCompleteReferalMutation
>;
export type CompleteReferalMutationResult = Apollo.MutationResult<
  CompleteReferalMutation
>;
export type CompleteReferalMutationOptions = Apollo.BaseMutationOptions<
  CompleteReferalMutation,
  CompleteReferalMutationVariables
>;
export const ReferalsDocument = gql`
  query Referals(
    $pagination: PaginationInput!
    $where: ReferalFiltersInput
    $orderBy: [OrderColumn!]
  ) {
    referals(pagination: $pagination, where: $where, orderBy: $orderBy) {
      items {
        createdAt
        ...ReferalData
      }
      total
      hasMore
    }
  }
  ${ReferalDataFragmentDoc}
`;

/**
 * __useReferalsQuery__
 *
 * To run a query within a React component, call `useReferalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferalsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useReferalsQuery(
  baseOptions: Apollo.QueryHookOptions<ReferalsQuery, ReferalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferalsQuery, ReferalsQueryVariables>(
    ReferalsDocument,
    options,
  );
}
export function useReferalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferalsQuery,
    ReferalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferalsQuery, ReferalsQueryVariables>(
    ReferalsDocument,
    options,
  );
}
export type ReferalsQueryHookResult = ReturnType<typeof useReferalsQuery>;
export type ReferalsLazyQueryHookResult = ReturnType<
  typeof useReferalsLazyQuery
>;
export type ReferalsQueryResult = Apollo.QueryResult<
  ReferalsQuery,
  ReferalsQueryVariables
>;
export const StudyFinancialsDailyDocument = gql`
  query StudyFinancialsDaily($filter: StudyReportFiltersInput!) {
    studiesFinancial(filter: $filter) {
      count
      is3D
      externalReferal
      studyDate
      sum
    }
  }
`;

/**
 * __useStudyFinancialsDailyQuery__
 *
 * To run a query within a React component, call `useStudyFinancialsDailyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyFinancialsDailyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyFinancialsDailyQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStudyFinancialsDailyQuery(
  baseOptions: Apollo.QueryHookOptions<
    StudyFinancialsDailyQuery,
    StudyFinancialsDailyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StudyFinancialsDailyQuery,
    StudyFinancialsDailyQueryVariables
  >(StudyFinancialsDailyDocument, options);
}
export function useStudyFinancialsDailyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudyFinancialsDailyQuery,
    StudyFinancialsDailyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StudyFinancialsDailyQuery,
    StudyFinancialsDailyQueryVariables
  >(StudyFinancialsDailyDocument, options);
}
export type StudyFinancialsDailyQueryHookResult = ReturnType<
  typeof useStudyFinancialsDailyQuery
>;
export type StudyFinancialsDailyLazyQueryHookResult = ReturnType<
  typeof useStudyFinancialsDailyLazyQuery
>;
export type StudyFinancialsDailyQueryResult = Apollo.QueryResult<
  StudyFinancialsDailyQuery,
  StudyFinancialsDailyQueryVariables
>;
export const StudiesByPaymentMethodDocument = gql`
  query StudiesByPaymentMethod($filter: StudyReportFiltersInput!) {
    studiesGroupedByPaymentMethod(filter: $filter) {
      paymentMethod
      count
      sum
    }
  }
`;

/**
 * __useStudiesByPaymentMethodQuery__
 *
 * To run a query within a React component, call `useStudiesByPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudiesByPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudiesByPaymentMethodQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStudiesByPaymentMethodQuery(
  baseOptions: Apollo.QueryHookOptions<
    StudiesByPaymentMethodQuery,
    StudiesByPaymentMethodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StudiesByPaymentMethodQuery,
    StudiesByPaymentMethodQueryVariables
  >(StudiesByPaymentMethodDocument, options);
}
export function useStudiesByPaymentMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudiesByPaymentMethodQuery,
    StudiesByPaymentMethodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StudiesByPaymentMethodQuery,
    StudiesByPaymentMethodQueryVariables
  >(StudiesByPaymentMethodDocument, options);
}
export type StudiesByPaymentMethodQueryHookResult = ReturnType<
  typeof useStudiesByPaymentMethodQuery
>;
export type StudiesByPaymentMethodLazyQueryHookResult = ReturnType<
  typeof useStudiesByPaymentMethodLazyQuery
>;
export type StudiesByPaymentMethodQueryResult = Apollo.QueryResult<
  StudiesByPaymentMethodQuery,
  StudiesByPaymentMethodQueryVariables
>;
export const StudiesByBranchDocument = gql`
  query StudiesByBranch($filter: StudyReportFiltersInput!) {
    studiesGroupedByBranch(filter: $filter) {
      branchName
      count
      studyCount
      sum
    }
  }
`;

/**
 * __useStudiesByBranchQuery__
 *
 * To run a query within a React component, call `useStudiesByBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudiesByBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudiesByBranchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStudiesByBranchQuery(
  baseOptions: Apollo.QueryHookOptions<
    StudiesByBranchQuery,
    StudiesByBranchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StudiesByBranchQuery, StudiesByBranchQueryVariables>(
    StudiesByBranchDocument,
    options,
  );
}
export function useStudiesByBranchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudiesByBranchQuery,
    StudiesByBranchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StudiesByBranchQuery,
    StudiesByBranchQueryVariables
  >(StudiesByBranchDocument, options);
}
export type StudiesByBranchQueryHookResult = ReturnType<
  typeof useStudiesByBranchQuery
>;
export type StudiesByBranchLazyQueryHookResult = ReturnType<
  typeof useStudiesByBranchLazyQuery
>;
export type StudiesByBranchQueryResult = Apollo.QueryResult<
  StudiesByBranchQuery,
  StudiesByBranchQueryVariables
>;
export const DailyStudyPaymentsDocument = gql`
  query DailyStudyPayments($filter: StudyReportFiltersInput!) {
    studiesDailyFinancial(filter: $filter) {
      paymentMethod
      paymentType
      paymentValue
      studyDate
      studyPaymentDate
      patientName
      branchName
      performerName
    }
  }
`;

/**
 * __useDailyStudyPaymentsQuery__
 *
 * To run a query within a React component, call `useDailyStudyPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyStudyPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyStudyPaymentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDailyStudyPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DailyStudyPaymentsQuery,
    DailyStudyPaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DailyStudyPaymentsQuery,
    DailyStudyPaymentsQueryVariables
  >(DailyStudyPaymentsDocument, options);
}
export function useDailyStudyPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DailyStudyPaymentsQuery,
    DailyStudyPaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DailyStudyPaymentsQuery,
    DailyStudyPaymentsQueryVariables
  >(DailyStudyPaymentsDocument, options);
}
export type DailyStudyPaymentsQueryHookResult = ReturnType<
  typeof useDailyStudyPaymentsQuery
>;
export type DailyStudyPaymentsLazyQueryHookResult = ReturnType<
  typeof useDailyStudyPaymentsLazyQuery
>;
export type DailyStudyPaymentsQueryResult = Apollo.QueryResult<
  DailyStudyPaymentsQuery,
  DailyStudyPaymentsQueryVariables
>;
export const StudiesByStudyTypeDocument = gql`
  query StudiesByStudyType($filter: StudyReportFiltersInput!) {
    studiesGroupedByStudyType(filter: $filter) {
      studyType
      count
    }
  }
`;

/**
 * __useStudiesByStudyTypeQuery__
 *
 * To run a query within a React component, call `useStudiesByStudyTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudiesByStudyTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudiesByStudyTypeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStudiesByStudyTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    StudiesByStudyTypeQuery,
    StudiesByStudyTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StudiesByStudyTypeQuery,
    StudiesByStudyTypeQueryVariables
  >(StudiesByStudyTypeDocument, options);
}
export function useStudiesByStudyTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudiesByStudyTypeQuery,
    StudiesByStudyTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StudiesByStudyTypeQuery,
    StudiesByStudyTypeQueryVariables
  >(StudiesByStudyTypeDocument, options);
}
export type StudiesByStudyTypeQueryHookResult = ReturnType<
  typeof useStudiesByStudyTypeQuery
>;
export type StudiesByStudyTypeLazyQueryHookResult = ReturnType<
  typeof useStudiesByStudyTypeLazyQuery
>;
export type StudiesByStudyTypeQueryResult = Apollo.QueryResult<
  StudiesByStudyTypeQuery,
  StudiesByStudyTypeQueryVariables
>;
export const StudiesByGenderDocument = gql`
  query StudiesByGender($filter: StudyReportFiltersInput!) {
    studiesGroupedByGender(filter: $filter) {
      gender
      count
    }
  }
`;

/**
 * __useStudiesByGenderQuery__
 *
 * To run a query within a React component, call `useStudiesByGenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudiesByGenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudiesByGenderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStudiesByGenderQuery(
  baseOptions: Apollo.QueryHookOptions<
    StudiesByGenderQuery,
    StudiesByGenderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StudiesByGenderQuery, StudiesByGenderQueryVariables>(
    StudiesByGenderDocument,
    options,
  );
}
export function useStudiesByGenderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudiesByGenderQuery,
    StudiesByGenderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StudiesByGenderQuery,
    StudiesByGenderQueryVariables
  >(StudiesByGenderDocument, options);
}
export type StudiesByGenderQueryHookResult = ReturnType<
  typeof useStudiesByGenderQuery
>;
export type StudiesByGenderLazyQueryHookResult = ReturnType<
  typeof useStudiesByGenderLazyQuery
>;
export type StudiesByGenderQueryResult = Apollo.QueryResult<
  StudiesByGenderQuery,
  StudiesByGenderQueryVariables
>;
export const AddStudyDocument = gql`
  mutation AddStudy($newStudy: StudyInput!) {
    addStudy(data: $newStudy) {
      ...StudyData
    }
  }
  ${StudyDataFragmentDoc}
`;
export type AddStudyMutationFn = Apollo.MutationFunction<
  AddStudyMutation,
  AddStudyMutationVariables
>;

/**
 * __useAddStudyMutation__
 *
 * To run a mutation, you first call `useAddStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudyMutation, { data, loading, error }] = useAddStudyMutation({
 *   variables: {
 *      newStudy: // value for 'newStudy'
 *   },
 * });
 */
export function useAddStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStudyMutation,
    AddStudyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddStudyMutation, AddStudyMutationVariables>(
    AddStudyDocument,
    options,
  );
}
export type AddStudyMutationHookResult = ReturnType<typeof useAddStudyMutation>;
export type AddStudyMutationResult = Apollo.MutationResult<AddStudyMutation>;
export type AddStudyMutationOptions = Apollo.BaseMutationOptions<
  AddStudyMutation,
  AddStudyMutationVariables
>;
export const UpdateStudyDocument = gql`
  mutation UpdateStudy($id: Int!, $study: StudyInput!) {
    updateStudy(id: $id, data: $study) {
      ...StudyData
    }
  }
  ${StudyDataFragmentDoc}
`;
export type UpdateStudyMutationFn = Apollo.MutationFunction<
  UpdateStudyMutation,
  UpdateStudyMutationVariables
>;

/**
 * __useUpdateStudyMutation__
 *
 * To run a mutation, you first call `useUpdateStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudyMutation, { data, loading, error }] = useUpdateStudyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      study: // value for 'study'
 *   },
 * });
 */
export function useUpdateStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudyMutation,
    UpdateStudyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStudyMutation, UpdateStudyMutationVariables>(
    UpdateStudyDocument,
    options,
  );
}
export type UpdateStudyMutationHookResult = ReturnType<
  typeof useUpdateStudyMutation
>;
export type UpdateStudyMutationResult = Apollo.MutationResult<
  UpdateStudyMutation
>;
export type UpdateStudyMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudyMutation,
  UpdateStudyMutationVariables
>;
export const FileDescriptionRequestDocument = gql`
  mutation FileDescriptionRequest(
    $id: Int!
    $descriptionRequestComment: String
  ) {
    fileDescriptionRequest(
      id: $id
      descriptionRequestComment: $descriptionRequestComment
    ) {
      ...StudyData
    }
  }
  ${StudyDataFragmentDoc}
`;
export type FileDescriptionRequestMutationFn = Apollo.MutationFunction<
  FileDescriptionRequestMutation,
  FileDescriptionRequestMutationVariables
>;

/**
 * __useFileDescriptionRequestMutation__
 *
 * To run a mutation, you first call `useFileDescriptionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileDescriptionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileDescriptionRequestMutation, { data, loading, error }] = useFileDescriptionRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      descriptionRequestComment: // value for 'descriptionRequestComment'
 *   },
 * });
 */
export function useFileDescriptionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FileDescriptionRequestMutation,
    FileDescriptionRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FileDescriptionRequestMutation,
    FileDescriptionRequestMutationVariables
  >(FileDescriptionRequestDocument, options);
}
export type FileDescriptionRequestMutationHookResult = ReturnType<
  typeof useFileDescriptionRequestMutation
>;
export type FileDescriptionRequestMutationResult = Apollo.MutationResult<
  FileDescriptionRequestMutation
>;
export type FileDescriptionRequestMutationOptions = Apollo.BaseMutationOptions<
  FileDescriptionRequestMutation,
  FileDescriptionRequestMutationVariables
>;
export const UpdateDescriptionDateDocument = gql`
  mutation UpdateDescriptionDate(
    $id: Int!
    $doctorId: Int!
    $newDate: DateTime!
  ) {
    updateStudyDescriptionDate(
      id: $id
      doctorId: $doctorId
      newDate: $newDate
    ) {
      id
      stat
      dueDate
      doctor {
        ...DoctorData
      }
    }
  }
  ${DoctorDataFragmentDoc}
`;
export type UpdateDescriptionDateMutationFn = Apollo.MutationFunction<
  UpdateDescriptionDateMutation,
  UpdateDescriptionDateMutationVariables
>;

/**
 * __useUpdateDescriptionDateMutation__
 *
 * To run a mutation, you first call `useUpdateDescriptionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDescriptionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDescriptionDateMutation, { data, loading, error }] = useUpdateDescriptionDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doctorId: // value for 'doctorId'
 *      newDate: // value for 'newDate'
 *   },
 * });
 */
export function useUpdateDescriptionDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDescriptionDateMutation,
    UpdateDescriptionDateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDescriptionDateMutation,
    UpdateDescriptionDateMutationVariables
  >(UpdateDescriptionDateDocument, options);
}
export type UpdateDescriptionDateMutationHookResult = ReturnType<
  typeof useUpdateDescriptionDateMutation
>;
export type UpdateDescriptionDateMutationResult = Apollo.MutationResult<
  UpdateDescriptionDateMutation
>;
export type UpdateDescriptionDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateDescriptionDateMutation,
  UpdateDescriptionDateMutationVariables
>;
export const GetStudyDocument = gql`
  query GetStudy($id: Int!) {
    study(id: $id) {
      ...StudyData
    }
  }
  ${StudyDataFragmentDoc}
`;

/**
 * __useGetStudyQuery__
 *
 * To run a query within a React component, call `useGetStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudyQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudyQuery, GetStudyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudyQuery, GetStudyQueryVariables>(
    GetStudyDocument,
    options,
  );
}
export function useGetStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudyQuery,
    GetStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudyQuery, GetStudyQueryVariables>(
    GetStudyDocument,
    options,
  );
}
export type GetStudyQueryHookResult = ReturnType<typeof useGetStudyQuery>;
export type GetStudyLazyQueryHookResult = ReturnType<
  typeof useGetStudyLazyQuery
>;
export type GetStudyQueryResult = Apollo.QueryResult<
  GetStudyQuery,
  GetStudyQueryVariables
>;
export const DeleteStudyDocument = gql`
  mutation deleteStudy($id: Int!) {
    deleteStudy(id: [$id])
  }
`;
export type DeleteStudyMutationFn = Apollo.MutationFunction<
  DeleteStudyMutation,
  DeleteStudyMutationVariables
>;

/**
 * __useDeleteStudyMutation__
 *
 * To run a mutation, you first call `useDeleteStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudyMutation, { data, loading, error }] = useDeleteStudyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStudyMutation,
    DeleteStudyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteStudyMutation, DeleteStudyMutationVariables>(
    DeleteStudyDocument,
    options,
  );
}
export type DeleteStudyMutationHookResult = ReturnType<
  typeof useDeleteStudyMutation
>;
export type DeleteStudyMutationResult = Apollo.MutationResult<
  DeleteStudyMutation
>;
export type DeleteStudyMutationOptions = Apollo.BaseMutationOptions<
  DeleteStudyMutation,
  DeleteStudyMutationVariables
>;
export const ShareStudyDocument = gql`
  mutation shareStudy($id: Int!, $doctorIds: [Int!]!) {
    shareStudy(id: $id, doctorIds: $doctorIds) {
      id
    }
  }
`;
export type ShareStudyMutationFn = Apollo.MutationFunction<
  ShareStudyMutation,
  ShareStudyMutationVariables
>;

/**
 * __useShareStudyMutation__
 *
 * To run a mutation, you first call `useShareStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareStudyMutation, { data, loading, error }] = useShareStudyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doctorIds: // value for 'doctorIds'
 *   },
 * });
 */
export function useShareStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareStudyMutation,
    ShareStudyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShareStudyMutation, ShareStudyMutationVariables>(
    ShareStudyDocument,
    options,
  );
}
export type ShareStudyMutationHookResult = ReturnType<
  typeof useShareStudyMutation
>;
export type ShareStudyMutationResult = Apollo.MutationResult<
  ShareStudyMutation
>;
export type ShareStudyMutationOptions = Apollo.BaseMutationOptions<
  ShareStudyMutation,
  ShareStudyMutationVariables
>;
export const GetStudyTypesDocument = gql`
  query GetStudyTypes {
    studyTypes(
      paging: { first: 100 }
      sorting: [
        { field: onReferalOrder, direction: ASC }
        { field: name, direction: ASC }
      ]
    ) {
      edges {
        node {
          ...StudyTypeFragment
        }
      }
    }
  }
  ${StudyTypeFragmentFragmentDoc}
`;

/**
 * __useGetStudyTypesQuery__
 *
 * To run a query within a React component, call `useGetStudyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudyTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStudyTypesQuery,
    GetStudyTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudyTypesQuery, GetStudyTypesQueryVariables>(
    GetStudyTypesDocument,
    options,
  );
}
export function useGetStudyTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudyTypesQuery,
    GetStudyTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudyTypesQuery, GetStudyTypesQueryVariables>(
    GetStudyTypesDocument,
    options,
  );
}
export type GetStudyTypesQueryHookResult = ReturnType<
  typeof useGetStudyTypesQuery
>;
export type GetStudyTypesLazyQueryHookResult = ReturnType<
  typeof useGetStudyTypesLazyQuery
>;
export type GetStudyTypesQueryResult = Apollo.QueryResult<
  GetStudyTypesQuery,
  GetStudyTypesQueryVariables
>;
export const GetStudyTypesForBranchDocument = gql`
  query GetStudyTypesForBranch($branchId: Int!, $deviceId: Int) {
    branchStudyTypes(branchId: $branchId, deviceId: $deviceId) {
      ...StudyTypeFragment
    }
  }
  ${StudyTypeFragmentFragmentDoc}
`;

/**
 * __useGetStudyTypesForBranchQuery__
 *
 * To run a query within a React component, call `useGetStudyTypesForBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyTypesForBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyTypesForBranchQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetStudyTypesForBranchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudyTypesForBranchQuery,
    GetStudyTypesForBranchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudyTypesForBranchQuery,
    GetStudyTypesForBranchQueryVariables
  >(GetStudyTypesForBranchDocument, options);
}
export function useGetStudyTypesForBranchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudyTypesForBranchQuery,
    GetStudyTypesForBranchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudyTypesForBranchQuery,
    GetStudyTypesForBranchQueryVariables
  >(GetStudyTypesForBranchDocument, options);
}
export type GetStudyTypesForBranchQueryHookResult = ReturnType<
  typeof useGetStudyTypesForBranchQuery
>;
export type GetStudyTypesForBranchLazyQueryHookResult = ReturnType<
  typeof useGetStudyTypesForBranchLazyQuery
>;
export type GetStudyTypesForBranchQueryResult = Apollo.QueryResult<
  GetStudyTypesForBranchQuery,
  GetStudyTypesForBranchQueryVariables
>;
export const StudiesDocument = gql`
  query Studies(
    $pagination: PaginationInput!
    $where: StudyFiltersInput
    $orderBy: [OrderColumn!]
  ) {
    studies(pagination: $pagination, where: $where, orderBy: $orderBy)
      @connection(key: "studies", filter: ["where"]) {
      items {
        id
        patient {
          ...PatientListData
        }
        studyType {
          id
          code
          name
        }
        studyDate
        leadingDoctor {
          ...DoctorData
        }
        supportingDoctors {
          ...DoctorData
        }
        describers {
          id
          stat
          dueDate
          doctor {
            ...DoctorData
          }
        }
        missingDescription
        unassignedDescriber
        incognitoDescription
        hasDescription
        hidden
        status
        branch {
          id
          name
        }
      }
      total
      hasMore
    }
  }
  ${PatientListDataFragmentDoc}
  ${DoctorDataFragmentDoc}
`;

/**
 * __useStudiesQuery__
 *
 * To run a query within a React component, call `useStudiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudiesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useStudiesQuery(
  baseOptions: Apollo.QueryHookOptions<StudiesQuery, StudiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StudiesQuery, StudiesQueryVariables>(
    StudiesDocument,
    options,
  );
}
export function useStudiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudiesQuery,
    StudiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StudiesQuery, StudiesQueryVariables>(
    StudiesDocument,
    options,
  );
}
export type StudiesQueryHookResult = ReturnType<typeof useStudiesQuery>;
export type StudiesLazyQueryHookResult = ReturnType<typeof useStudiesLazyQuery>;
export type StudiesQueryResult = Apollo.QueryResult<
  StudiesQuery,
  StudiesQueryVariables
>;
export const UsersDocument = gql`
  query Users(
    $pagination: PaginationInput!
    $where: UserFiltersInput
    $orderBy: [OrderColumn!]
  ) {
    users(pagination: $pagination, where: $where, orderBy: $orderBy) {
      items {
        ...FullUserData
        roles
      }
      total
      hasMore
    }
  }
  ${FullUserDataFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const HelpUserContactsDocument = gql`
  query HelpUserContacts($count: Int!) {
    helpUserContacts(count: $count) {
      firstName
      lastName
      phoneNumbers
      emailAddresses
      profilePictureURL
    }
  }
`;

/**
 * __useHelpUserContactsQuery__
 *
 * To run a query within a React component, call `useHelpUserContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpUserContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpUserContactsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useHelpUserContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HelpUserContactsQuery,
    HelpUserContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HelpUserContactsQuery, HelpUserContactsQueryVariables>(
    HelpUserContactsDocument,
    options,
  );
}
export function useHelpUserContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HelpUserContactsQuery,
    HelpUserContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HelpUserContactsQuery,
    HelpUserContactsQueryVariables
  >(HelpUserContactsDocument, options);
}
export type HelpUserContactsQueryHookResult = ReturnType<
  typeof useHelpUserContactsQuery
>;
export type HelpUserContactsLazyQueryHookResult = ReturnType<
  typeof useHelpUserContactsLazyQuery
>;
export type HelpUserContactsQueryResult = Apollo.QueryResult<
  HelpUserContactsQuery,
  HelpUserContactsQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser($withPreferences: Boolean! = false) {
    currentUser @connection(key: "currentUser") {
      id
      active
      login
      regions
      roles
      emailConfirmed
      profilePicture @connection(key: "currentUserProfilePicture") {
        publicURL
      }
      contact {
        ...ContactData
      }
      preferences @include(if: $withPreferences) {
        mailNotifications
      }
    }
  }
  ${ContactDataFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      withPreferences: // value for 'withPreferences'
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: Int!, $user: UserInput!) {
    updateUser(id: $id, data: $user) {
      ...UserData
      emailConfirmed
    }
  }
  ${UserDataFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = Apollo.MutationResult<
  UpdateUserMutation
>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const MarkForDeleteDocument = gql`
  mutation MarkForDelete {
    markForDelete
  }
`;
export type MarkForDeleteMutationFn = Apollo.MutationFunction<
  MarkForDeleteMutation,
  MarkForDeleteMutationVariables
>;

/**
 * __useMarkForDeleteMutation__
 *
 * To run a mutation, you first call `useMarkForDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkForDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markForDeleteMutation, { data, loading, error }] = useMarkForDeleteMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkForDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkForDeleteMutation,
    MarkForDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkForDeleteMutation,
    MarkForDeleteMutationVariables
  >(MarkForDeleteDocument, options);
}
export type MarkForDeleteMutationHookResult = ReturnType<
  typeof useMarkForDeleteMutation
>;
export type MarkForDeleteMutationResult = Apollo.MutationResult<
  MarkForDeleteMutation
>;
export type MarkForDeleteMutationOptions = Apollo.BaseMutationOptions<
  MarkForDeleteMutation,
  MarkForDeleteMutationVariables
>;
