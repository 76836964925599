import {
  AlternateEmailOutlined,
  AssignmentLate,
  Description,
} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useActionConfirm } from '../../../../../common/hooks/useConfirmation';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { useModalInput } from '../../../../../common/hooks/useModalInput';
import {
  ActionColor,
  ItemActionHook,
} from '../../../../../common/types/actions';
import {
  Doctor,
  DoctorsDocument,
  DoctorsQuery,
  useActivateUserMutation,
  useChangeLoginMutation,
  useDeleteDoctorMutation,
  useInitPasswordResetMutation,
  UserRole,
  useUpdateDoctorMutation,
} from '../../../../../generated/graphql';
import { useSelectDoctor } from './useSelectDoctor';

export type DoctorActionType =
  | 'edit'
  | 'activate'
  | 'resetPassword'
  | 'delete'
  | 'changeDescription'
  | 'changeLogin'
  | 'createComplaint';

type DoctorActionHook = ItemActionHook<Doctor, DoctorActionType, boolean>;

export const useEditDoctorAction: DoctorActionHook = (facility?: boolean) => {
  const history = useHistory();

  return {
    id: 'edit',
    Icon: EditIcon,
    title: `Edytuj ${facility ? 'Gabinet' : 'Lekarza'}`,
    roles: [UserRole.Admin, UserRole.Manager],
    onAction: ({ id }) =>
      history.push(`/${facility ? 'facilities' : 'doctors'}/edit/${id}`),
  };
};

export const useActivateDoctorAction: DoctorActionHook = (
  facility?: boolean,
) => {
  const [activateDoctor] = useActivateUserMutation();
  const { enqueueSnackbar } = useSnackbar();

  return {
    id: 'activate',
    Icon: SpellcheckIcon,
    title: `Aktywuj ${facility ? 'gabinet' : 'lekarza'}`,
    roles: [UserRole.Admin, UserRole.Manager],
    actionColor: ActionColor.GREEN,
    visible: ({ user: { active, login } }) =>
      !active && !login.startsWith('NO_USER'),
    onAction: async ({ user: { login } }) => {
      await activateDoctor({
        variables: { email: login },
        update: (cache) => {
          const data = cache.readQuery<DoctorsQuery>({
            query: DoctorsDocument,
          });
          const doctors = data?.doctors;

          if (doctors) {
            cache.writeQuery<DoctorsQuery>({
              query: DoctorsDocument,
              data: {
                doctors: {
                  items: doctors.items.map((doctor) => {
                    if (doctor.user.login === login) {
                      return {
                        ...doctor,
                        user: { ...doctor.user, active: true },
                      };
                    }
                    return doctor;
                  }),
                  hasMore: doctors.hasMore,
                  total: doctors.total,
                },
              },
            });
          }
        },
      });
      enqueueSnackbar(`Lekarz ${login} został aktywowany.`, {
        variant: 'success',
      });
    },
  };
};

export const useResetPasswordDoctorAction: DoctorActionHook = (
  facility?: boolean,
) => {
  const snackBarError = useGraphQLError();
  const [initPasswordResetMutation] = useInitPasswordResetMutation({
    onError: (error) => snackBarError(error),
  });
  const { enqueueSnackbar } = useSnackbar();
  const confirmAction = useActionConfirm();
  const { t } = useTranslation();

  return {
    id: 'resetPassword',
    Icon: SettingsBackupRestoreIcon,
    roles: [UserRole.Admin, UserRole.Manager],
    title: `Resetuj hasło ${facility ? 'gabinetu' : 'lekarza'}`,
    actionColor: ActionColor.ORANGE,
    visible: ({ user: { active } }) => active,
    onAction: async ({ user: { login } }) => {
      await confirmAction({
        description: t('confirmationDialog.passwordReset.description'),
      });
      await initPasswordResetMutation({ variables: { email: login } });
      enqueueSnackbar(
        `Link do zmiany hasła został wysłany do lekarza '${login}'.`,
        {
          variant: 'success',
        },
      );
    },
  };
};

export const useChangeDoctorLoginAction: DoctorActionHook = () => {
  const snackBarError = useGraphQLError();

  const [changeLogin] = useChangeLoginMutation({
    onError: snackBarError,
  });

  const fireNewLoginPrompt = useModalInput(
    'dialogs.changeDoctorLogin.newLogin',
    'dialogs.changeDoctorLogin.title',
  );

  const { enqueueSnackbar } = useSnackbar();

  return {
    id: 'changeLogin',
    Icon: AlternateEmailOutlined,
    title: 'Zmiana loginu (e-mail)',
    roles: [UserRole.Admin, UserRole.Manager],
    actionColor: ActionColor.BLUE,
    visible: ({ user: { login } }) => !login.startsWith('NO_USER'),
    onAction: async ({ user }) => {
      const newLogin = await fireNewLoginPrompt();

      if (newLogin) {
        await changeLogin({ variables: { newLogin, userId: user.id } });

        enqueueSnackbar(
          'Login lekarza został zmieniony. Lekarz otrzyma wiadomość na nowy adres e-mail z prośbą o aktywację',
          { variant: 'success' },
        );
      }
    },
  };
};

export const useChangeDoctorDescriptionAction: DoctorActionHook = () => {
  const snackBarError = useGraphQLError();
  const { success } = useMessages();

  const [updateDoctor] = useUpdateDoctorMutation({
    onCompleted: () => success(),
    onError: snackBarError,
  });

  const fireNewDescriptionPrompt = useModalInput(
    'dialogs.changeDescription.newDescription',
    'dialogs.changeDescription.title',
  );

  return {
    id: 'changeDescription',
    Icon: Description,
    title: 'Zmiana notatki',
    roles: [UserRole.Admin, UserRole.Technician, UserRole.Manager],
    onAction: async (currentDoctor) => {
      const newDescription = await fireNewDescriptionPrompt(
        currentDoctor.user.contact.primaryDescription,
      );

      if (newDescription) {
        await updateDoctor({
          variables: {
            id: currentDoctor.id,
            doctor: {
              user: {
                contact: {
                  primaryDescription: newDescription,
                  firstName: currentDoctor.user.contact.firstName,
                  lastName: currentDoctor.user.contact.lastName,
                },
                login: currentDoctor.user.login,
              },
            },
          },
        });
      }
    },
  };
};

export const useDeleteDoctorAction: DoctorActionHook = (facility?: boolean) => {
  const [deleteDoctor] = useDeleteDoctorMutation();
  const { enqueueSnackbar } = useSnackbar();
  const selectDoctor = useSelectDoctor();

  return {
    id: 'delete',
    Icon: DeleteIcon,
    title: `Usuń ${facility ? 'gabinet' : 'lekarza'}`,
    roles: [UserRole.Admin, UserRole.Manager],
    actionColor: ActionColor.RED,
    onAction: async ({ id, user: { login } }) => {
      const selectedDoctor = await selectDoctor();
      await deleteDoctor({
        variables: { id, assignStudiesTo: selectedDoctor?.id },
      });
      enqueueSnackbar(`Lekarz ${login} został usunięty.`, {
        variant: 'success',
      });
    },
  };
};

export const useCreateComplaintAction: DoctorActionHook = () => {
  const history = useHistory();

  return {
    id: 'createComplaint',
    Icon: AssignmentLate,
    title: 'Utwórz reklamację lekarza',
    actionColor: ActionColor.ORANGE,
    onAction: ({ id }) => history.push(`/complaints/add?doctorId=${id}`),
    roles: [UserRole.Admin, UserRole.Registration, UserRole.Technician],
  };
};
