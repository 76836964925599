import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { StudyFormType } from '../types';
import { PaymentTypeEnum } from '../../../../../generated/graphql';

export interface UsePaymentsResult {
  /**
   * Recalculates payments based on specific conditions like studyType change,
   * urgentDescription or comparisonDescription changes.
   *
   * @param skipUpdate When true, only calculates but doesn't update form values.
   * Used for checking what would be updated without applying changes.
   * @returns The updated payments array without modifying the form
   */
  recalculatePayments: (skipUpdate?: boolean) => StudyFormType['payments'];

  /**
   * Applies a discount to all payments
   *
   * @param discountPercentage The discount percentage to apply
   */
  applyDiscountToAllPayments: (discountPercentage: number) => void;
}

export const usePayments = (): UsePaymentsResult => {
  const { getValues, setValue } = useFormContext<StudyFormType>();

  const recalculatePayments = useCallback(
    (skipUpdate = false) => {
      const payments = getValues('payments');
      const studyType = getValues('studyType');
      const urgentDescription = getValues('urgentDescription');
      const comparisonDescription = getValues('comparisonDescription');

      console.log('RECALCULATING PAYMENTS');

      // Enhanced validation
      if (
        !payments ||
        !Array.isArray(payments) ||
        payments.length === 0 ||
        !studyType
      ) {
        return payments;
      }

      // Create a copy of payments to avoid mutating the original
      const updatedPayments = JSON.parse(JSON.stringify(payments));

      // Handle Study payment
      const studyPaymentIndex = payments.findIndex(
        ({ type }) => type === PaymentTypeEnum.Study,
      );

      if (studyPaymentIndex > -1) {
        const studyPayment = payments[studyPaymentIndex];
        const studyTypePrices = studyType.studyTypePrices || [];
        const studyPricePreset = studyTypePrices[0]?.presetName;
        const studyPrice = studyTypePrices[0]?.price || 0;

        if (studyPricePreset && studyPayment) {
          const discount = studyPayment.discount || 0;

          updatedPayments[studyPaymentIndex] = {
            ...studyPayment,
            group: studyPricePreset,
            value: studyPrice * (1 - discount / 100),
          };
        }
      }

      // Handle Description payment
      const descriptionPaymentIndex = payments.findIndex(
        ({ type }) => type === PaymentTypeEnum.Description,
      );

      if (descriptionPaymentIndex > -1) {
        const descriptionPayment = payments[descriptionPaymentIndex];
        let descriptionPrice = studyType.descriptionPrice || 0;

        if (urgentDescription && studyType.urgentDescriptionPrice) {
          descriptionPrice = studyType.urgentDescriptionPrice;
        } else if (
          comparisonDescription &&
          studyType.comparisonDescriptionPrice
        ) {
          descriptionPrice = studyType.comparisonDescriptionPrice;
        }

        if (descriptionPayment) {
          const discount = descriptionPayment.discount || 0;

          updatedPayments[descriptionPaymentIndex] = {
            ...descriptionPayment,
            value: descriptionPrice * (1 - discount / 100),
          };
        }
      }

      // Only update the form if skipUpdate is false
      if (!skipUpdate) {
        setValue('payments', updatedPayments, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      return updatedPayments;
    },
    [getValues, setValue],
  );

  const applyDiscountToAllPayments = useCallback(
    (discountPercentage: number) => {
      const payments = getValues('payments');

      if (!payments) return;

      const updatedPayments = payments.map((payment) => {
        // Calculate original price (before any discount)
        const originalValue =
          payment.value / (1 - (payment.discount || 0) / 100);

        // Apply the new discount
        return {
          ...payment,
          discount: discountPercentage,
          value: originalValue * (1 - discountPercentage / 100),
        };
      });

      setValue('payments', updatedPayments, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [getValues, setValue],
  );

  return {
    recalculatePayments,
    applyDiscountToAllPayments,
  };
};
