import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CancelButton } from '../../../../../../common/components/button/CancelButton';
import { Form } from '../../../../../../common/components/form/Form';
import { RichTextField } from '../../../../../../common/components/form/fields/RichTextField';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import {
  CreateEmailTemplate,
  EmailTemplate,
} from '../../../../../../generated/graphql';

type Props = {
  onSubmit: (data: CreateEmailTemplate) => void;
  emailTemplate?: EmailTemplate;
};

const schema = yup.object().shape({
  name: yup.string().required(),
  content: yup.string().required(),
  embeddedType: yup.string().required(),
  id: yup.number(),
});

export const EmailTemplateForm: React.FC<Props> = ({
  onSubmit,
  emailTemplate,
}) => {
  const { t } = useTranslation();

  return (
    <Form<CreateEmailTemplate>
      onSubmit={onSubmit}
      formOptions={{
        defaultValues: emailTemplate || {
          embeddedType: '',
          content: '',
          name: '',
        },
        resolver: yupResolver(schema) as any,
      }}
    >
      <TextField
        name="name"
        label={t('settings.emailTemplates.fields.name')}
        fullWidth
        required
      />
      <TextField
        name="embeddedType"
        label={t('settings.emailTemplates.fields.embeddedType')}
        fullWidth
      />
      <Box mt={6}>
        <RichTextField
          name="content"
          label={t('settings.emailTemplates.fields.content')}
          defaultValue={emailTemplate?.content || ''}
          showToolbar
        />
      </Box>

      <Box mt={8}>
        <Button fullWidth variant="contained" type="submit" color="primary">
          {t('app.common.actions.save')}
        </Button>
        <CancelButton />
      </Box>
    </Form>
  );
};
