//TODO: Introduce types
export const tableReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SORT_CHANGE':
      return { ...state, orderBy: { ...state.orderBy, ...action.payload } };
    case 'PAGINATION_CHANGE':
      return {
        ...state,
        pagination: { ...state.pagination, ...action.payload },
        reload: false,
      };
    case 'FILTER_CHANGE':
      return {
        ...state,
        filter: action.payload,
        pagination: { ...state.pagination, skip: 0 },
        reload: false,
      };
    case 'RELOAD':
      return {
        ...state,
        pagination: { ...state.pagination, ...action.payload }, //Dummy copy so it is picked by useEffect hook
        reload: true,
      };
    case 'TOGGLE_FILTERS':
      return {
        ...state,
        filtersExpanded: !state.filtersExpanded,
        reload: false,
      };
    case 'SELECT_ROW':
      return {
        ...state,
        lastSelectedRowId: action.payload,
        reload: false,
      };
  }
};
