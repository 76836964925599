import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';

import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadFromURL } from '../../../../common/hooks/useFileDownload';
import { getFilenameFromUrl } from '../../../../common/utils/file.utils';
import { useGetDownloadUrlQuery } from '../../../../generated/graphql';

export type DownloadFileButtonProps = {
  fileUri: string;
  studyId: number;
  icon?: React.ReactNode;
  label?: string;
  filenameAsLabel?: boolean;
};

export const shouldDownloadAsync = (fileUri: string) => {
  return ['png', 'jpg', 'jpeg', 'bmp', 'webp', 'pdf'].some((extension) =>
    fileUri.toLocaleLowerCase().endsWith(extension),
  );
};

export const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({
  fileUri,
  icon,
  label,
  studyId,
  filenameAsLabel = false,
}) => {
  const { data } = useGetDownloadUrlQuery({
    variables: { filePath: fileUri, studyId },
  });
  const onDownload = useDownloadFromURL(fileUri, studyId);
  const [loading, setLoading] = useState(false);
  const handleOnDownload = async () => {
    setLoading(true);
    await onDownload(getFilenameFromUrl(fileUri));
    setLoading(false);
  };

  const { t } = useTranslation();

  const buttonLabel = label || t('pages.study.actions.download.buttonLabel');

  return shouldDownloadAsync(getFilenameFromUrl(fileUri)) ? (
    <DownloadFileButtonInternal
      onDownload={handleOnDownload}
      loading={loading}
      icon={icon}
      variant={filenameAsLabel ? 'text' : 'outlined'}
    >
      {filenameAsLabel ? getFilenameFromUrl(fileUri) : buttonLabel}
    </DownloadFileButtonInternal>
  ) : (
    <DownloadFileLink
      href={data?.getDownloadURL || fileUri}
      icon={icon}
      variant={filenameAsLabel ? 'text' : 'outlined'}
    >
      {filenameAsLabel ? getFilenameFromUrl(fileUri) : buttonLabel}
    </DownloadFileLink>
  );
};

const DownloadFileButtonInternal: React.FC<PropsWithChildren<{
  onDownload: () => void;
  loading: boolean;
  icon?: React.ReactNode;
  variant?: ButtonProps['variant'];
}>> = ({ loading, onDownload, icon, children, variant = 'outlined' }) => {
  return (
    <Button
      color="primary"
      variant={variant}
      style={{ justifyContent: variant === 'text' ? 'flex-start' : 'center' }}
      aria-label="download"
      onClick={onDownload}
      fullWidth
      startIcon={
        loading ? (
          <CircularProgress size={20} />
        ) : icon ? (
          icon
        ) : (
          <CloudDownload />
        )
      }
    >
      {children}
    </Button>
  );
};

const DownloadFileLink: React.FC<PropsWithChildren<{
  href: string;
  icon?: React.ReactNode;
  variant?: ButtonProps['variant'];
}>> = ({ href, icon, children, variant = 'outlined' }) => {
  return (
    <Button
      href={href}
      color="primary"
      variant={variant}
      aria-label="download"
      target="_blank"
      fullWidth
      style={{ justifyContent: variant === 'text' ? 'flex-start' : 'center' }}
      startIcon={icon || <CloudDownload />}
    >
      {children}
    </Button>
  );
};
