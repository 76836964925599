import {
  ReservationsDocument,
  ReservationsQuery,
  useOnReservationAddedSubscription,
  useOnReservationDeletedSubscription,
  useOnReservationUpdatedSubscription,
} from '../../../../../generated/graphql';

export const useReservationSubscriptions = () => {
  useOnReservationAddedSubscription({
    onData: ({ client, data }) => {
      const cache = client.cache;

      const existing = cache.readQuery<ReservationsQuery>({
        query: ReservationsDocument,
        variables: {
          deviceId: data?.data?.reservationAdded.deviceId,
        },
      });
      const reservations = existing?.reservations;
      if (!reservations || !data?.data?.reservationAdded) return;
      cache.writeQuery({
        query: ReservationsDocument,
        data: {
          reservations: [...reservations, data?.data.reservationAdded],
        },
        variables: {
          deviceId: data?.data.reservationAdded.deviceId,
        },
      });
    },
  });

  useOnReservationUpdatedSubscription({
    onData: ({ client }) => {
      client.refetchQueries({ include: 'all' });
    },
  });

  useOnReservationDeletedSubscription({
    onData: ({ client, data }) => {
      const cache = client.cache;

      cache.evict({
        id: cache.identify({
          __typename: 'Reservation',
          id: data?.data?.reservationDeleted,
        }),
      });
    },
  });
};
