import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CancelButton } from '../../../../../../common/components/button/CancelButton';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../../common/components/form/Form';
import { SoftwareInput, Software } from '../../../../../../generated/graphql';

type Props = {
  onSubmit: (data: SoftwareInput) => void;
  software?: Software;
};

export const SoftwareForm: React.FC<Props> = ({ onSubmit, software }) => {
  const { t } = useTranslation();

  const { id, ...softwareInput } = software || {};

  return (
    <Form<SoftwareInput>
      onSubmit={onSubmit}
      formOptions={{
        defaultValues: Object.keys(softwareInput).length
          ? softwareInput
          : {
              name: '',
              downloadURL: '',
            },
      }}
    >
      <TextField
        name="name"
        label={t('settings.software.fields.name')}
        fullWidth
      />
      <Box mt={6}>
        <TextField
          name="downloadURL"
          label={t('settings.software.fields.downloadURL')}
          fullWidth
        />
      </Box>

      <Box mt={8}>
        <Button fullWidth variant="contained" type="submit" color="primary">
          {t('app.common.actions.save')}
        </Button>
        <CancelButton />
      </Box>
    </Form>
  );
};
