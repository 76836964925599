import ShareIcon from '@mui/icons-material/Share';
import {
  ActionColor,
  ItemActionConfig,
} from '../../../../../common/types/actions';
import {
  StudyStatus,
  UserRole,
  useShareStudyMutation,
} from '../../../../../generated/graphql';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { useSelectDoctors } from '../../doctors/hooks/useSelectDoctor';

export interface StudyWithShareFields {
  id: number;
  status?: StudyStatus;
  hidden?: boolean | null;
}

export const useShareStudyAction = <
  T extends StudyWithShareFields
>(): ItemActionConfig<T, 'share'> => {
  const snackBarError = useGraphQLError();
  const { success } = useMessages();
  const [shareStudy] = useShareStudyMutation({
    onError: snackBarError,
    onCompleted: () => success(),
  });

  const selectDoctors = useSelectDoctors(
    'pages.study.actions.share.dialogDescription',
    'pages.study.actions.share.dialogTitle',
  );

  return {
    id: 'share',
    Icon: ShareIcon,
    roles: [
      UserRole.Admin,
      UserRole.Technician,
      UserRole.Manager,
      UserRole.Registration,
      UserRole.Doctor,
      UserRole.DescribingDoctor,
    ],
    title: 'Udostępnij badanie',
    actionColor: ActionColor.BLUE,
    visible: ({ status, hidden }: StudyWithShareFields) =>
      status === StudyStatus.Performed && !hidden,
    onAction: async ({ id }: StudyWithShareFields) => {
      const selectedDoctors = await selectDoctors();
      if (selectedDoctors) {
        await shareStudy({
          variables: {
            doctorIds: selectedDoctors.map(({ id }) => id),
            id,
          },
        });
      }
    },
  };
};
