import React, { useRef } from 'react';
import 'cropperjs/dist/cropper.css';
import './cropper.css';
import Cropper, { ReactCropperProps } from 'react-cropper';
import { Box, Button, Grid, Slider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Loop, ZoomIn } from '@mui/icons-material';

type Props = ReactCropperProps & {
  sourceImage: string;
  onCrop: (imageData: string) => void;
};

export const ImageCropper: React.FC<Props> = ({
  onCrop,
  sourceImage,
  ...cropperProps
}) => {
  const cropperRef = useRef<HTMLImageElement & { cropper: Cropper }>(null);
  const { t } = useTranslation();

  const onRotationChange = (_: any, newValue: number | number[]) => {
    cropperRef.current?.cropper.rotateTo(newValue as number);
  };

  const onZoomChange = (_: any, newValue: number | number[]) => {
    cropperRef.current?.cropper.zoomTo(newValue as number);
  };

  const onCropEvent = () => {
    const croppedImageData = cropperRef.current?.cropper
      .getCroppedCanvas({ fillColor: '#fff', maxWidth: 1024, maxHeight: 1024 })
      .toDataURL('image/jpeg', 0.9);
    if (croppedImageData) onCrop(croppedImageData);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={10}
      alignItems="center"
      mb={10}
    >
      <Cropper
        src={sourceImage}
        checkCrossOrigin={false}
        ref={cropperRef}
        {...cropperProps}
      />
      <Grid container spacing={2}>
        <Grid item>
          <Loop />
        </Grid>
        <Grid item xs>
          <Slider
            defaultValue={0}
            onChange={onRotationChange}
            aria-labelledby="rotate-slider"
            valueLabelDisplay="auto"
            step={1}
            min={-90}
            max={90}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <ZoomIn />
        </Grid>
        <Grid item xs>
          <Slider
            defaultValue={0.6}
            onChange={onZoomChange}
            aria-labelledby="zoom-slider"
            valueLabelDisplay="auto"
            step={0.03}
            min={0.1}
            max={2}
          />
        </Grid>
      </Grid>
      <Button variant="contained" color="primary" onClick={onCropEvent}>
        {t('app.common.images.crop.actions.cropImage')}
      </Button>
    </Box>
  );
};
