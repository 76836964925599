import { TFunction } from 'i18next';
import * as yup from 'yup';

export const PASSWORD_LENGTH = 8;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const passwordValidationSchema = (t: TFunction) =>
  yup.object().shape({
    password: yup
      .string()
      .required()
      .min(PASSWORD_LENGTH)
      .matches(PASSWORD_REGEX, t('validation.password.complexity')),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password')],
        t('validation.password.confirmPasswordMatch'),
      ),
  });
