import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CancelButton } from '../../../../../../common/components/button/CancelButton';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../../common/components/form/Form';
import { Outline } from '../../../../../../common/components/form/LabeledOutline';
import {
  AppConfig,
  MediaType,
  useAppConfigQuery,
} from '../../../../../../generated/graphql';
import { TagsField } from '../../../tags/TagsField';
import { useAppConfigApi } from '../../hooks/useAppConfigApi';

export const AppConfigPanel: React.FC = () => {
  const { data } = useAppConfigQuery();
  const { onSaveSubmit } = useAppConfigApi();

  const { t } = useTranslation();

  if (!data) return null;

  return (
    <Form<AppConfig>
      onSubmit={(appConfig) => onSaveSubmit(appConfig)}
      formOptions={{
        defaultValues: data.appConfig,
      }}
    >
      <TagsField
        name="paymentTypes"
        label={t('settings.appConfig.fields.paymentTypes')}
      />
      <TagsField
        name="priceGroups"
        label={t('settings.appConfig.fields.priceGroups')}
      />
      <TagsField
        name="radiationGroups"
        label={t('settings.appConfig.fields.radiationGroups')}
      />
      <TagsField
        name="specializations"
        label={t('settings.appConfig.fields.specializations')}
      />
      <TagsField
        name="regions"
        label={t('settings.appConfig.fields.regions')}
      />
      <TagsField
        name="deviceTypes"
        label={t('settings.appConfig.fields.deviceTypes')}
        placeholder=""
      />
      <Box padding={3} />
      <Outline label={t('settings.appConfig.fields.complaints.title')}>
        <Box padding={1} />
        <TagsField
          name="complaintTypes"
          label={t(
            'settings.appConfig.fields.complaints.fields.complaintTypes',
          )}
          placeholder=""
        />
        <TextField
          style={{ width: '500px' }}
          valueAsNumber
          name="complaintResolutionTimeHours"
          label={t(
            'settings.appConfig.fields.complaints.fields.complaintResolutionTimeHours',
          )}
          required
        />
      </Outline>

      <Box padding={3} />
      <Outline label={t('settings.appConfig.fields.carrierPrices.title')}>
        <Box display="flex" gap={6} marginY={4}>
          <TextField
            currency
            name={`carrierPrices.${MediaType.Disc}`}
            label={t('settings.appConfig.fields.carrierPrices.types.Disc')}
            valueAsNumber
            required
          />
          <TextField
            currency
            name={`carrierPrices.${MediaType.Film}`}
            label={t('settings.appConfig.fields.carrierPrices.types.Film')}
            valueAsNumber
            required
          />
          <TextField
            currency
            name={`carrierPrices.${MediaType.Pendrive}`}
            label={t('settings.appConfig.fields.carrierPrices.types.Pendrive')}
            valueAsNumber
            required
          />
        </Box>
      </Outline>
      <Box mt={8}>
        <Button fullWidth variant="contained" type="submit" color="primary">
          {t('app.common.actions.save')}
        </Button>
        <CancelButton />
      </Box>
    </Form>
  );
};
