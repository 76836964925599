import { yupResolver } from '@hookform/resolvers/yup';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../../common/components/form/Form';
import { passwordValidationSchema } from '../../../../../../common/validation/password';

import { CardContent, Button, Card, Typography, Box } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';

type Props = {
  onChangePassword: (newPassword: string) => void;
};

type ChangePasswordInput = {
  password: string;
  confirmPassword: string;
};

export const ChangePassword: React.FC<Props> = ({ onChangePassword }) => {
  const { t } = useTranslation();

  const onSubmit = (
    passwordInput: ChangePasswordInput,
    { reset }: UseFormReturn<ChangePasswordInput>,
  ) => {
    onChangePassword(passwordInput.password);
    reset({ password: '', confirmPassword: '' });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          {t('profile.changePassword.title')}
        </Typography>
        <Form<ChangePasswordInput>
          onSubmit={onSubmit}
          noElevation
          formOptions={{
            resolver: yupResolver(passwordValidationSchema(t)) as any,
          }}
        >
          <TextField
            name="password"
            label={t('profile.changePassword.form.newPassword')}
            password
            fullWidth
            htmlAutoComplete={false}
            required
          />
          <TextField
            variant="outlined"
            name="confirmPassword"
            label={t('profile.changePassword.form.confirmNewPassword')}
            password
            fullWidth
            required
            htmlAutoComplete={false}
          />
          <Box mt={4}>
            <Button fullWidth variant="contained" type="submit" color="primary">
              {t('profile.changePassword.button')}
            </Button>
          </Box>
        </Form>
      </CardContent>
    </Card>
  );
};
