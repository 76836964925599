import { Box, Grid } from '@mui/material';
import React from 'react';
import { Patient } from '../../../../../../generated/graphql';
import { PatientAddButton } from './PatientAddButton';
import { PreviousStudiesButton } from './PreviousStudiesButton';
import { GoToPatientButton } from './GoToPatientButton';

type PatientActionsProps = {
  patient: Patient;
  name: string;
  withAddButton?: boolean;
  withPreviousStudiesButton?: boolean;
  withGoToPatientButton?: boolean;
  disabled?: boolean;
};

export const PatientActions: React.FC<PatientActionsProps> = ({
  patient,
  name,
  withAddButton,
  withPreviousStudiesButton,
  withGoToPatientButton,
  disabled,
}) => {
  // If no actions are enabled, return null
  if (!withAddButton && !withPreviousStudiesButton && !withGoToPatientButton) {
    return null;
  }

  return (
    <Grid item xs={12} md={3} style={{ display: 'flex' }}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {withAddButton && (
          <PatientAddButton disabled={disabled} name={name} patient={patient} />
        )}

        {withPreviousStudiesButton && (
          <PreviousStudiesButton patient={patient} />
        )}

        {withGoToPatientButton && <GoToPatientButton patient={patient} />}
      </Box>
    </Grid>
  );
};
