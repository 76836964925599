import React from 'react';

import { Box, Button } from '@mui/material';
import { useDirectFileDownload } from '../../../../../common/hooks/useFileDownload';
import { DateTime } from 'luxon';

export const DoctorFinancialReportTab: React.FC = () => {
  const downloadReport = useDirectFileDownload();

  return (
    <Box p={8} gap={8} display="flex">
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          downloadReport(
            '/reports/doctor-financial-report',
            `raport_lekarze_${DateTime.now().toFormat('yyyy-MM-dd')}.csv`,
          )
        }
      >
        Raport Lekarzy (CSV)
      </Button>
    </Box>
  );
};
