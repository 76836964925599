import { useConfirm, ConfirmOptions } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';

export const defaultOptions: ConfirmOptions = {
  confirmationButtonProps: { color: 'secondary', variant: 'contained' },
  cancellationButtonProps: { variant: 'contained' },
};

export const useRemoveConfirm = () => {
  const confirm = useConfirm();
  const { t } = useTranslation();

  return (confirmOptions: ConfirmOptions = {}) =>
    confirm({
      title: t('confirmationDialog.remove.title'),
      description: t('confirmationDialog.remove.description'),
      cancellationText: t('confirmationDialog.remove.cancelButton'),
      confirmationText: t('confirmationDialog.remove.confirmButton'),
      ...confirmOptions,
    });
};

export const useActionConfirm = () => {
  const confirm = useConfirm();
  const { t } = useTranslation();

  return (confirmOptions: ConfirmOptions = {}) =>
    confirm({
      title: t('confirmationDialog.action.title'),
      description: t('confirmationDialog.action.description'),
      cancellationText: t('confirmationDialog.action.cancelButton'),
      confirmationText: t('confirmationDialog.action.confirmButton'),
      ...confirmOptions,
    });
};
