import { Button } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import {
  Complaint,
  ComplaintInput,
  ComplaintStatusEnum,
  ComplaintTypeEnum,
  ComplaintsDocument,
  ComplaintsQuery,
  Doctor,
  GetDoctorQuery,
  GetPatientQuery,
  Maybe,
  Patient,
  PatientsDocument,
  useAddComplaintMutation,
  useGetDoctorQuery,
  useGetPatientQuery,
} from '../../../../../generated/graphql';

import { ComplaintForm } from '../form/ComplaintForm';

export const createDefaultComplaint = (
  patient?: Maybe<GetPatientQuery['patient']>,
  doctor?: Maybe<GetDoctorQuery['doctor']>,
) => {
  return {
    patient: patient ? patient : undefined,
    doctor: doctor ? doctor : undefined,
    complaintReason: '',
    complaintDescription: '',
    responsiblePerson: undefined,
    complaintResolution: '',

    complainantName: patient?.contact
      ? `${patient.contact.firstName} ${patient.contact.lastName}`
      : doctor?.user.contact
      ? `${doctor.user.contact.firstName} ${doctor.user.contact.lastName}`
      : '',

    type: patient ? ComplaintTypeEnum.Patient : ComplaintTypeEnum.Doctor,
    status: ComplaintStatusEnum.InProgress,
    complainantPhoneNumber: '',
    complainantEmailAddress: doctor?.user.login ? doctor.user.login : '',
  };
};

export const ComplaintAdd: React.FC = () => {
  const { success } = useMessages();

  const [addComplaint] = useAddComplaintMutation({
    onCompleted: () => success(),
    refetchQueries: [ComplaintsDocument, PatientsDocument],
    context: { withGlobalLoading: true },
  });

  const history = useHistory();

  const queryParams = useQueryParams();
  const { data: patient, loading: patientLoading } = useGetPatientQuery({
    variables: {
      id: parseInt(queryParams.get('patientId') || '', 10),
    },
    skip: !queryParams.get('patientId'),
  });
  const { data: doctor, loading: doctorLoading } = useGetDoctorQuery({
    variables: {
      id: parseInt(queryParams.get('doctorId') || '', 10),
    },
    skip: !queryParams.get('doctorId'),
  });

  const onSubmit = async ({ status, createdAt, ...data }: Complaint) => {
    if (data.doctor?.id) {
      data.doctor = { id: data.doctor.id } as Doctor;
    }
    if (data.patient?.id) {
      data.patient = { id: data.patient.id } as Patient;
    }

    await addComplaint({
      variables: {
        newComplaint: data as ComplaintInput,
      },
      refetchQueries: [ComplaintsDocument],
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            complaints(
              existing: ComplaintsQuery['complaints'],
              { toReference },
            ) {
              if (data) {
                const newComplaint = toReference({
                  __typename: data.createComplaint.__typename,
                  id: data.createComplaint.id,
                });

                return {
                  ...existing,
                  items: [newComplaint, ...existing.items],
                  total: existing.total + 1,
                } as any;
              }
            },
          },
        });
      },
    });
    history.push('/complaints', { reload: true });
  };

  if (
    (queryParams.get('patientId') && patientLoading) ||
    (queryParams.get('doctorId') && doctorLoading)
  ) {
    return null;
  }

  return (
    <>
      <ComplaintForm
        onSubmit={onSubmit as any}
        complaint={
          createDefaultComplaint(
            patient?.patient,
            doctor?.doctor,
          ) as ComplaintInput
        }
      >
        <Button
          type="button"
          color="secondary"
          onClick={() => history.goBack()}
        >
          Anuluj
        </Button>
      </ComplaintForm>
    </>
  );
};
