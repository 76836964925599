import { OptionsObject, useSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';
import {
  isTokenExpiredError,
  isNetworkError,
  isMaintenanceError,
} from '../containers/apollo/GlobalErrorHandler';
import { useTranslation } from 'react-i18next';

const snackBarErrorOptions: OptionsObject = {
  variant: 'error',
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
} as const;

export const useGraphQLError = (addCallCenterNote = false) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return (error: ApolloError) =>
    error?.graphQLErrors?.forEach((err) => {
      const nestedMessage = err.extensions?.exception?.response?.message;

      if (nestedMessage?.forEach) {
        nestedMessage.forEach(({ message }: { message: string }) =>
          enqueueSnackbar(message, {
            ...snackBarErrorOptions,
          }),
        );
      } else if (err.extensions?.exception?.name === 'QueryFailedError') {
        enqueueSnackbar(t('app.errors.databaseError'), {
          ...snackBarErrorOptions,
        });
      } else if (!isNotCommonError(error)) {
        const callCenterNote = addCallCenterNote
          ? t('app.errors.callCenterNote')
          : '';

        enqueueSnackbar(`${err.message} ${callCenterNote}`, {
          ...snackBarErrorOptions,
        });
      }
    });
};

function isNotCommonError(error: ApolloError) {
  return (
    isTokenExpiredError(error) ||
    isNetworkError(error) ||
    isMaintenanceError(error)
  );
}
