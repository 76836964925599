const LOCAL_STORAGE_KEYS = ['pagination'];

export class HybridTableStorage implements Storage {
  length = 99;

  clear(): void {
    throw new Error('Method not implemented.');
  }
  getItem(key: string): string | null {
    const localStorageObject = JSON.parse(
      globalThis.localStorage.getItem(key) || '{}',
    );
    const sessionStorageObject = JSON.parse(
      globalThis.sessionStorage.getItem(key) || '{}',
    );

    const hybridTableObject = {
      ...localStorageObject,
      ...sessionStorageObject,
    };

    return Object.keys(sessionStorageObject).length
      ? JSON.stringify(hybridTableObject)
      : null;
  }
  key(): string | null {
    throw new Error('Method not implemented.');
  }
  removeItem(key: string): void {
    if (LOCAL_STORAGE_KEYS.includes(key)) {
      return globalThis.localStorage.removeItem(key);
    } else {
      return globalThis.sessionStorage.removeItem(key);
    }
  }
  setItem(key: string, value: string): void {
    const tableStorageObject = JSON.parse(value) as { [key: string]: any };

    for (const [entryKey, entryValue] of Object.entries(tableStorageObject)) {
      const localStorageItem = JSON.parse(
        globalThis.localStorage.getItem(key) || '{}',
      );

      if (LOCAL_STORAGE_KEYS.includes(entryKey)) {
        globalThis.localStorage.setItem(
          key,
          JSON.stringify({
            ...localStorageItem,
            [entryKey]: entryValue,
          }),
        );
      } else {
        globalThis.sessionStorage.setItem(
          key,
          JSON.stringify({
            ...JSON.parse(globalThis.sessionStorage.getItem(key) || '{}'),
            [entryKey]: entryValue,
          }),
        );
      }
    }
  }

  public static getInitialState(storageKey: string, initialState: {}) {
    return {
      ...initialState,
      ...JSON.parse(globalThis.localStorage.getItem(storageKey) || '{}'),
    };
  }

  public static removePersistedStateByFragment(fragment: string): void {
    const removeMatchingKeys = (storage: Storage) => {
      const keys: string[] = [];
      for (let i = 0; i < storage.length; i++) {
        const key = storage.key(i);
        if (key && key.includes(fragment)) {
          keys.push(key);
        }
      }
      keys.forEach((key) => storage.removeItem(key));
    };

    removeMatchingKeys(globalThis.localStorage);
    removeMatchingKeys(globalThis.sessionStorage);
  }
}
