import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import React from 'react';
import {
  Link as RouterLink,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useTableActions } from '../../../../../../common/components/table/hooks/useTableActions';
import {
  SortableColumn,
  Table,
} from '../../../../../../common/components/table/Table';
import { useMessages } from '../../../../../../common/hooks/useMessages';
import { ActionColor } from '../../../../../../common/types/actions';
import {
  StudyType,
  useGetStudyTypesQuery,
} from '../../../../../../generated/graphql';
import { ProtectedRoute } from '../../../auth/ProtectedRoute';
import { useStudyTypeApi } from '../../hooks/useStudyTypeApi';
import { StudyTypeForm } from './StudyTypeForm';

type Props = {};

const COLUMNS: SortableColumn<StudyType>[] = [
  {
    Header: 'Kod',
    accessor: 'code',
  },
  {
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    Header: 'Kolejność',
    accessor: 'onReferalOrder',
  },
];

export const StudyTypePanel: React.FC<Props> = () => {
  const { data } = useGetStudyTypesQuery();
  const { notFound } = useMessages();

  const history = useHistory();
  const { path } = useRouteMatch();

  const { onAddSubmit, onSaveSubmit, onDeleteStudyType } = useStudyTypeApi();

  const tableActions = useTableActions<StudyType, 'edit' | 'delete'>([
    {
      id: 'delete',
      Icon: DeleteIcon,
      title: 'Usuń typ badania',
      actionColor: ActionColor.RED,
      onAction: async ({ id }) => {
        await onDeleteStudyType(id);
        history.push(`${path}/`);
      },
    },
  ]);

  if (!data?.studyTypes?.edges) return null;

  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/`}>
        <Box>
          <Table<StudyType>
            columnConfig={[...COLUMNS, tableActions]}
            manualSortBy={false}
            pagination={false}
            data={data?.studyTypes.edges.map(({ node }) => node)}
            title="Typy badań"
            onRowClicked={(studyType) =>
              history.push(`${path}/edit/${studyType.id}`)
            }
          />
          <Box position="fixed" bottom={72} right={24}>
            <Fab
              color="primary"
              aria-label="add"
              component={RouterLink}
              to={`${path}/add`}
            >
              <AddIcon />
            </Fab>
          </Box>
        </Box>
      </ProtectedRoute>
      <ProtectedRoute
        path={`${path}/edit/:id`}
        render={(props) => {
          if (!props?.match?.params?.id) return null;

          const paramsId = parseInt(props.match.params.id, 10);

          const foundStudyType = data?.studyTypes.edges.find(
            ({ node: { id } }) => id === paramsId,
          );

          if (!foundStudyType?.node) {
            notFound(undefined, paramsId);
            return null;
          }

          return (
            <StudyTypeForm
              studyType={foundStudyType?.node}
              onSubmit={(newStudyType) =>
                onSaveSubmit(newStudyType as any, paramsId)
              }
            />
          );
        }}
      />
      <ProtectedRoute path={`${path}/add`}>
        <StudyTypeForm onSubmit={(newStudyType) => onAddSubmit(newStudyType)} />
      </ProtectedRoute>
    </Switch>
  );
};
