import { Box } from '@mui/material';

import { uniqueId } from 'lodash-es';
import React, { useMemo } from 'react';

import { CellProps } from 'react-table';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import { toLocaleDate } from '../../../../../common/utils/date.utils';
import {
  StudyFinancialRow,
  useStudyFinancialsDailyLazyQuery,
  StudyByPaymentMethodRow,
  useStudiesByPaymentMethodLazyQuery,
  StudyByBranchRow,
  useStudiesByBranchLazyQuery,
} from '../../../../../generated/graphql';
import { StudyReportFilters } from '../components/StudyFilters';

type Props = {};

type StudyByPaymentMethodTableRow = StudyByPaymentMethodRow & { id: string };

type StudyByBranchTableRow = StudyByBranchRow & { id: string };

type StudyFinancialTableRow = {
  id: string;
  countInternal2D: number;
  countExternal2D: number;
  countInternal3D: number;
  countExternal3D: number;
  sum: number;
  totalCount: number;
  studyDate: Date;
};

const COLUMNS: SortableColumn<StudyFinancialTableRow>[] = [
  {
    Header: 'Data',
    accessor: 'studyDate',
    sortType: 'datetime',
    Cell: ({
      value,
    }: CellProps<
      StudyFinancialTableRow,
      StudyFinancialTableRow['studyDate']
    >) => toLocaleDate(value),
    Footer: 'Razem',
  },
  {
    Header: 'Badania 2D nasze',
    accessor: 'countInternal2D',
    sortType: 'basic',
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.countInternal2D), 0),
  },
  {
    Header: 'Badania 2D obce',
    accessor: 'countExternal2D',
    sortType: 'basic',
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.countExternal2D), 0),
  },
  {
    Header: 'Badania 3D nasze',
    accessor: 'countInternal3D',
    sortType: 'basic',
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.countInternal3D), 0),
  },
  {
    Header: 'Badania 3D obce',
    accessor: 'countExternal3D',
    sortType: 'basic',
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.countExternal3D), 0),
  },
  {
    Header: 'Kwota',
    accessor: 'sum',
    sortType: 'basic',
    Cell: ({
      value,
    }: CellProps<StudyFinancialTableRow, StudyFinancialTableRow['sum']>) =>
      value.toFixed(2),
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.sum), 0).toFixed(2),
  },
  {
    Header: 'Liczba badań',
    accessor: 'totalCount',
    sortType: 'basic',
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.totalCount), 0),
  },
];

const PAYMENT_METHOD_COLUMNS: SortableColumn<StudyByPaymentMethodTableRow>[] = [
  {
    Header: 'Forma płatności',
    accessor: 'paymentMethod',
    width: 30,
    maxWidth: 30,
    Footer: 'Razem',
  },
  {
    Header: 'Ilość opłat',
    accessor: 'count',
    sortType: 'basic',
    width: 30,
    maxWidth: 30,
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.count), 0),
  },
  {
    Header: 'Łącznie',
    accessor: 'sum',
    sortType: 'basic',
    Cell: ({
      value,
    }: CellProps<
      StudyByPaymentMethodTableRow,
      StudyByPaymentMethodTableRow['sum']
    >) => value.toFixed(2),
    width: 30,
    maxWidth: 30,
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.sum), 0).toFixed(2),
  },
];

const BRANCH_COLUMNS: SortableColumn<StudyByBranchTableRow>[] = [
  {
    Header: 'Filia',
    accessor: 'branchName',
    width: 30,
    maxWidth: 30,
    Footer: 'Razem',
  },
  {
    Header: 'Ilość opłat',
    accessor: 'count',
    sortType: 'basic',
    width: 30,
    maxWidth: 30,
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.count), 0),
  },
  {
    Header: 'Ilość badań',
    accessor: 'studyCount',
    sortType: 'basic',
    width: 30,
    maxWidth: 30,
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.studyCount), 0),
  },
  {
    Header: 'Łącznie',
    accessor: 'sum',
    sortType: 'basic',
    Cell: ({
      value,
    }: CellProps<StudyByBranchTableRow, StudyByBranchTableRow['sum']>) =>
      value.toFixed(2),
    width: 30,
    maxWidth: 30,
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.sum), 0).toFixed(2),
  },
];

const transformData = (
  data?: StudyFinancialRow[],
): StudyFinancialTableRow[] => {
  if (!data) {
    return [];
  }

  return Object.values(
    data.reduce((acc, row) => {
      const { count, is3D, externalReferal, studyDate, sum } = row;

      const studyDateDate = new Date(studyDate).toISOString();

      if (!acc[studyDateDate]) {
        acc[studyDateDate] = {
          id: uniqueId(),
          totalCount: 0,
          sum: 0,
          countExternal2D: 0,
          countExternal3D: 0,
          countInternal2D: 0,
          countInternal3D: 0,
          studyDate: new Date(studyDateDate),
        };
      }

      if (is3D && externalReferal) {
        acc[studyDateDate].countExternal3D = count;
      } else if (!is3D && externalReferal) {
        acc[studyDateDate].countExternal2D = count;
      } else if (is3D) {
        acc[studyDateDate].countInternal3D = count;
      } else if (!is3D) {
        acc[studyDateDate].countInternal2D = count;
      }

      acc[studyDateDate].sum = acc[studyDateDate].sum + (sum ?? 0);
      acc[studyDateDate].totalCount = acc[studyDateDate].totalCount + count;

      return acc;
    }, {} as { [key: string]: StudyFinancialTableRow }),
  );
};

export const FinancialReportTab: React.FC<Props> = () => {
  /* const { t } = useTranslation(); */

  const [loadReport, { data }] = useStudyFinancialsDailyLazyQuery({
    fetchPolicy: 'no-cache',
    context: { withGlobalLoading: true },
  });

  const [
    loadStudiesByPaymentMethod,
    { data: paymentMethodData },
  ] = useStudiesByPaymentMethodLazyQuery({
    fetchPolicy: 'no-cache',
    context: { withGlobalLoading: true },
  });

  const [
    loadStudiesByBranch,
    { data: branchData },
  ] = useStudiesByBranchLazyQuery({
    fetchPolicy: 'no-cache',
    context: { withGlobalLoading: true },
  });

  const tableRows = useMemo(() => transformData(data?.studiesFinancial), [
    data?.studiesFinancial,
  ]);

  return (
    <>
      <StudyReportFilters
        onSearch={({ range, ...studyFilters }) => {
          loadReport({ variables: { filter: studyFilters } });
          loadStudiesByPaymentMethod({ variables: { filter: studyFilters } });
          loadStudiesByBranch({ variables: { filter: studyFilters } });
        }}
      />
      {data?.studiesFinancial &&
        paymentMethodData?.studiesGroupedByPaymentMethod &&
        branchData?.studiesGroupedByBranch && (
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            marginTop={8}
            gap={8}
          >
            <Box>
              <Table<StudyFinancialTableRow>
                data={tableRows}
                columnConfig={COLUMNS}
                title="Podział na typy badań"
                pagination={false}
                manualSortBy={false}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={8}>
              <Table<StudyByPaymentMethodTableRow>
                data={paymentMethodData?.studiesGroupedByPaymentMethod.map(
                  (row) => ({
                    ...row,
                    id: uniqueId(),
                  }),
                )}
                columnConfig={PAYMENT_METHOD_COLUMNS}
                title="Podział na formy płatności"
                pagination={false}
                manualSortBy={false}
              />
              <Table<StudyByBranchTableRow>
                data={branchData?.studiesGroupedByBranch.map((row) => ({
                  ...row,
                  id: uniqueId(),
                }))}
                columnConfig={BRANCH_COLUMNS}
                title="Podział na filie"
                pagination={false}
                manualSortBy={false}
              />
            </Box>
          </Box>
        )}
    </>
  );
};
