import { TypePolicies } from '@apollo/client';

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      appointments: {
        keyArgs: ['where', ['deviceId', 'startDateFrom', 'startDateTo']],
        read(existing) {
          // Return existing cache if available
          return existing;
        },
        merge(existing, incoming) {
          // If no new data, return existing
          if (!incoming) return existing;

          // Simple replacement strategy - return incoming data
          return incoming;
        },
      },
    },
  },
};

export default typePolicies;
