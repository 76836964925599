import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { createUseReducerPersisted } from 'use-persisted';
import { useTableActions } from '../../../../../common/components/table/hooks/useTableActions';
import { TableContext } from '../../../../../common/components/table/state/table.context';
import { tableReducer } from '../../../../../common/components/table/state/table.reducer';
import { HybridTableStorage } from '../../../../../common/components/table/state/table.storage';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import useDidMountEffect from '../../../../../common/hooks/useDidMountEffect';
import { toLocaleDateTime } from '../../../../../common/utils/date.utils';
import {
  Patient,
  usePatientsQuery,
  UserRole,
} from '../../../../../generated/graphql';
import { Protect } from '../../../components/auth/components/Protect';

import { PatientSearchPanel } from '../search/PatientSearch';
import { useStyles } from './PatientList.styles';
import { useTableRefresh } from '../../../../../common/hooks/useTableRefresh';
import {
  useCreateComplaintAction,
  useCreateReferalAction,
  useDeletePatientAction,
  useEditPatientAction,
  useSendPatientStudiesLinkAction,
} from '../hooks/usePatientActions';
import { useTranslation } from 'react-i18next';

type Props = {};

const initialState = {
  orderBy: {
    columnName: 'updatedAt',
    columnOrder: 'DESC',
  },
  pagination: {
    take: 20,
    skip: 0,
  },
  filter: {},
};

const tableStorage = new HybridTableStorage();

const usePersistedReducer = createUseReducerPersisted(
  'patientList',
  tableStorage,
);

export const PatientsList: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [state, dispatch] = usePersistedReducer(
    tableReducer,
    HybridTableStorage.getInitialState('patientList', initialState),
  );

  const { data, fetchMore } = usePatientsQuery({
    variables: {
      where: state.filter,
      pagination: { skip: 0, take: state.pagination.take },
      orderBy: [state.orderBy],
      withStudies: true,
    },
    context: { withGlobalLoading: true },
  });

  const editPatient = useEditPatientAction();
  const deletePatient = useDeletePatientAction();
  const createReferal = useCreateReferalAction();
  const createComplaint = useCreateComplaintAction();
  const sendStudiesLink = useSendPatientStudiesLinkAction();

  const { reloadAfterAction } = useTableRefresh(dispatch);

  const tableActions = useTableActions([
    createReferal,
    editPatient,
    reloadAfterAction(deletePatient),
    sendStudiesLink,
    createComplaint,
  ]);

  type CellType = { row: { original: Patient } };

  const columns = useMemo<SortableColumn<Patient>[]>(
    () => [
      {
        id: 'contact.lastName',
        Header: 'Nazwisko i imię',
        accessor: ({ contact }) => contact.lastName,
        Cell: ({ row: { original } }: CellType) =>
          `${original.contact.lastName} ${original.contact.firstName}`,
      },
      {
        id: 'contact.ssn',
        Header: 'PESEL',
        accessor: ({ contact }) => contact.ssn,
        Cell: ({ row: { original } }: CellType) => original.contact.ssn || null,
      },
      {
        id: 'contact.birthDate',
        Header: 'Data urodzenia',
        accessor: ({ contact }) => contact.birthDate,
        Cell: ({ row: { original } }: CellType) =>
          toLocaleDateTime(original.contact.birthDate, DateTime.DATE_SHORT),
      },
      {
        id: 'studies',
        disableSortBy: true,
        Header: 'Liczba badań',
        accessor: ({ studies }) => studies?.length || 0,
      },
      tableActions,

      //TODO: Evaluate using hiddenColumns
      /*{
        id: 'registrationDate',
        Header: 'Data rejestracji',
        accessor: ({ registrationDate }) => registrationDate,
        Cell: ({ row: { original } }: CellType) =>
          toLocaleDateTime(original.registrationDate, DateTime.DATETIME_SHORT),
      }*/
    ],
    [tableActions],
  );

  useDidMountEffect(() => {
    fetchMore({
      variables: {
        where: state.filter,
        pagination: state.pagination,
        orderBy: [state.orderBy],
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  }, [fetchMore, state.filter, state.pagination, state.orderBy]);

  return (
    <TableContext.Provider value={{ state, dispatch }}>
      <Box mb={8}>
        <PatientSearchPanel
          expanded={state.filtersExpanded}
          onSearch={(filter) =>
            dispatch({ type: 'FILTER_CHANGE', payload: filter })
          }
          filters={state.filter}
        />
      </Box>
      <Table<Patient>
        title="Lista Pacjentów"
        columnConfig={columns}
        data={data?.patients.items as Patient[]}
        totalCount={data?.patients.total || 0}
        onRowClicked={({ id }) => history.push(`/patients/view/${id}`)}
        emptyLabel={t('pages.patient.list.emptyLabel')}
      />

      <Protect roles={[UserRole.Admin, UserRole.Technician]}>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          component={RouterLink}
          to="/patients/add"
        >
          <AddIcon />
        </Fab>
      </Protect>
    </TableContext.Provider>
  );
};
