import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';

import { Analytics } from '@vercel/analytics/react';
import { createUploadLink } from 'apollo-upload-client';

import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';

import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { ReCaptchaProvider } from '@wojtekmaj/react-recaptcha-v3';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import 'typeface-open-sans';
import 'typeface-roboto';
import { GlobalErrorHandler } from '../../common/containers/apollo/GlobalErrorHandler';
import { GlobalLoading } from '../../common/containers/apollo/GlobalLoading';
import { useAuth } from '../../common/hooks/useAuth';
import { defaultOptions } from '../../common/hooks/useConfirmation';
import theme from '../theme';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { LoginPage } from './components/auth/login/LoginPage';
import { ResetPasswordPage } from './components/auth/reset-password/ResetPasswordPage';
import { ResetPasswordRequestPage } from './components/auth/reset-password/ResetPasswordRequestPage';
import { SignupPage } from './components/auth/signup/SignupPage';
import { CookieConsent } from './components/cookies/CookieConsent';
import { Dashboard } from './components/dashboard/Dashboard';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { useLanguage } from '../../common/hooks/useLanguage';
import { PatientStudiesLogoutPage } from './modules/patientStudies/list/PatientStudiesLogoutPage';
import { PatientStudiesPage } from './modules/patientStudies/list/PatientStudiesPage';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { createClient } from 'graphql-ws';
import typePolicies from '../../common/gql/typePolicies';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

/*const jss = create({
  plugins: [...jssPreset().plugins],
});*/

export const errorHandler = new StackdriverErrorReporter();

errorHandler.start({
  key: 'AIzaSyCLchAgDoQkiUeOx-osfLk-MT3Le3JHYe0',
  projectId: 'portallekarza',
  disabled: process.env.NODE_ENV !== 'production',
});

export default function App() {
  const { getAuthInfo, isAuthenticated } = useAuth();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { currentLang } = useLanguage();

  const uploadEnabledGraphQLLink = createUploadLink({
    uri: process.env.REACT_APP_API_GRAPHQL_ENDPOINT,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: isAuthenticated() ? `Bearer ${getAuthInfo().token}` : '',
      },
    };
  });

  const langLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'Accept-Language': currentLang,
      },
    };
  });

  const {
    link: networkStatusLink,
    useApolloNetworkStatus,
    useApolloNetworkStatusReducer,
  } = createNetworkStatusNotifier();

  const httpLink = ApolloLink.from([
    new ApolloLink((operation, forward) => {
      if (operation.variables) {
        const omitTypename = (key: any, value: any) =>
          key === '__typename' ? undefined : value;
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          omitTypename,
        );
      }
      return forward(operation).map((data) => {
        return data;
      });
    }),
    networkStatusLink,
    authLink,
    langLink,
    uploadEnabledGraphQLLink as any,
  ]);

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_API_GRAPHQL_WS_ENDPOINT as string,
      connectionParams: () => {
        return {
          headers: {
            authorization: isAuthenticated()
              ? `Bearer ${getAuthInfo().token}`
              : '',
            'Accept-Language': currentLang,
          },
        };
      },
    }),
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      typePolicies,
    }),
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });

  return (
    <ReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY || ''}
      language="pl"
      useEnterprise
    >
      <GoogleOAuthProvider clientId="1065393847252-bqbt4gspq53qnkppo3spcdo8d54um3la.apps.googleusercontent.com">
        <Analytics />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="pl">
              <SnackbarProvider
                maxSnack={5}
                dense={isMobile}
                domRoot={document.querySelector('root') as HTMLElement}
              >
                <ApolloProvider client={client}>
                  <BrowserRouter>
                    <ConfirmProvider defaultOptions={defaultOptions}>
                      <GlobalLoading
                        useApolloNetworkStatus={useApolloNetworkStatus}
                      />
                      <GlobalErrorHandler
                        useApolloNetworkStatus={useApolloNetworkStatus}
                        useApolloNetworkStatusReducer={
                          useApolloNetworkStatusReducer
                        }
                      />
                      <Switch>
                        <Route path="/login">
                          <LoginPage />
                        </Route>
                        <Route path="/signup">
                          <SignupPage />
                        </Route>
                        <Route path="/reset-password-request">
                          <ResetPasswordRequestPage />
                        </Route>
                        <Route path="/reset-password">
                          <ResetPasswordPage />
                        </Route>
                        <Route path="/patient-studies/">
                          <PatientStudiesPage />
                        </Route>
                        <Route path="/patient-studies-logout" exact>
                          <PatientStudiesLogoutPage />
                        </Route>
                        <ProtectedRoute path="/">
                          <Dashboard />
                        </ProtectedRoute>
                      </Switch>
                      <CookieConsent />
                    </ConfirmProvider>
                  </BrowserRouter>
                </ApolloProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </GoogleOAuthProvider>
    </ReCaptchaProvider>
  );
}
