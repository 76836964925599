import createUseStatePersisted from 'use-persisted-state';

export const initialState: CalendarSettings = {
  branchIds: [],
  deviceIds: [],
};

export type CalendarSettings = {
  branchIds: { id: number }[];
  deviceIds: { id: number; branchId: number }[];
};

export const useCalendarSettings = () =>
  createUseStatePersisted('calendar-settings')<CalendarSettings>(initialState);
