import { Box } from '@mui/material';
import React, { useState } from 'react';
import { SUPPORTED_LANGUAGES, useLanguage } from '../../hooks/useLanguage';

import EnglishIcon from './en.svg';
import PolishIcon from './pl.svg';
import UkrainianIcon from './ua.svg';

const ICONS: { [key in SUPPORTED_LANGUAGES]: string } = {
  pl: PolishIcon,
  en: EnglishIcon,
  ua: UkrainianIcon,
};

export const LanguageSelector: React.FC = () => {
  const [isSelecting, setSelecting] = useState(false);

  const { currentLang, setCurrentLang } = useLanguage();

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxHeight={48}
      style={{ zIndex: 10, position: 'relative' }}
    >
      <Box marginBottom={4}>
        <img
          aria-label={currentLang}
          src={ICONS[currentLang as keyof typeof ICONS]}
          width={32}
          height={32}
          onClick={() => setSelecting(!isSelecting)}
        />
      </Box>

      {Object.keys(ICONS)
        .filter((lang) => lang !== currentLang)
        .map((lang) => (
          <Box
            key={lang}
            paddingTop={4}
            style={{
              opacity: isSelecting ? 1 : 0,
              transition: 'opacity 0.3s ease-in',
            }}
          >
            <img
              aria-label={lang}
              src={ICONS[lang as keyof typeof ICONS]}
              width={36}
              height={36}
              onClick={() => {
                if (isSelecting) {
                  setCurrentLang(lang as keyof typeof ICONS);
                  setSelecting(false);
                } else {
                  setSelecting(true);
                }
              }}
            />
          </Box>
        ))}
    </Box>
  );
};
