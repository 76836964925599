import { User } from '../../generated/graphql';

type AuthInfo = Pick<User, 'roles' | 'id' | 'login'> & {
  token: string;
};

export const useAuth = () => {
  const getAuthInfo = () => {
    const storage = window.localStorage;
    const authInfoJSON = storage?.getItem('auth');
    const authInfo: AuthInfo = authInfoJSON ? JSON.parse(authInfoJSON) : {};
    return authInfo;
  };

  return {
    isAuthenticated: () => !!getAuthInfo().token,
    getAuthInfo,
    setAuthInfo: (authInfo: AuthInfo) => {
      window.localStorage.setItem('auth', JSON.stringify(authInfo));
    },
    removeAuthInfo: () => window.localStorage.removeItem('auth'),
  };
};
