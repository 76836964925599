import { Box, IconButton, TextFieldProps } from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  FastForward,
  FastRewind,
} from '@mui/icons-material';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SubmitOnChangeProps } from '../types';
import { DateField } from './DateField';
import { useDebouncedCallback } from 'use-debounce';
import { DEFAULT_DEBOUNCE_MILLIS } from '../consts';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';

type Props = Partial<DatePickerProps<never>> &
  TextFieldProps &
  SubmitOnChangeProps & {
    showDateFormatPlaceholder?: boolean;
  };

export const DateStepperField: React.FC<Props> = ({
  showDateFormatPlaceholder = true,
  submitOnChange,
  ...props
}) => {
  const formContext = useFormContext();

  const debouncedSubmitForm = useDebouncedCallback(
    () => {
      formContext.handleSubmit(submitOnChange ?? (() => {}))();
      debouncedSubmitForm.flush();
    },
    DEFAULT_DEBOUNCE_MILLIS,
    { leading: true },
  );

  const { setValue, getValues } = formContext;

  const goTo = useCallback(
    (days: number) => () => {
      setValue(
        props.name || '',
        DateTime.fromJSDate(getValues(props.name || ''))
          .plus({ days })
          .toJSDate(),
      );
      if (submitOnChange) {
        debouncedSubmitForm();
      }
    },
    [debouncedSubmitForm, getValues, props.name, setValue, submitOnChange],
  );

  return (
    <Controller
      name={props.name || ''}
      control={formContext?.control}
      render={({ field: { value, onChange }, formState }) => {
        // Persisted state (localStorage/globalStorage) is only capable of working with strings so we can convert it here
        const dateValue: Date =
          !!value && typeof value === 'string' ? new Date(value) : value;
        if (value !== dateValue) onChange(dateValue);

        const error = get(formState.errors, props.name || '');
        if (error && error.type === 'typeError') {
          error.message = 'Nieprawidłowy format daty';
        }

        return (
          <Box display="flex">
            <IconButton onClick={goTo(-7)} size="small" disableRipple>
              <FastRewind />
            </IconButton>
            <IconButton onClick={goTo(-1)} size="small" disableRipple>
              <ChevronLeft />
            </IconButton>
            <DateField
              name={props.name || ''}
              label={props.label || ''}
              variant="outlined"
              required
              loading={debouncedSubmitForm.isPending()}
              submitOnChange={submitOnChange}
              showDateFormatPlaceholder={showDateFormatPlaceholder}
            />
            <IconButton onClick={goTo(1)} size="small" disableRipple>
              <ChevronRight />
            </IconButton>
            <IconButton onClick={goTo(7)} size="small" disableRipple>
              <FastForward />
            </IconButton>
          </Box>
        );
      }}
    />
  );
};
