import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { DateTimeField } from '../../../../../../common/components/form/fields/DateTimeField';
import { UserAutocomplete } from '../../../users/fields/UserAutocomplete';

type Props = {};

export const MetadataTab: React.FC<Props> = () => {
  return (
    <Box mb={12}>
      <Grid container spacing={4} direction="row">
        <Grid item xs={12} md={3}>
          <DateTimeField
            label="Data ostatniej modyfikacji"
            variant="outlined"
            name="updatedAt"
            disabled
          />
          <DateTimeField
            label="Data dodania"
            variant="outlined"
            name="createdAt"
            disabled
          />

          <UserAutocomplete
            controllerProps={{
              required: true,
              label: 'Dodał',
              name: 'createdBy',
            }}
            autocompleteProps={{
              disabled: true,
            }}
          />
          <UserAutocomplete
            controllerProps={{
              required: true,
              label: 'Ostatnio modyfikował',
              name: 'updatedBy',
            }}
            autocompleteProps={{
              disabled: true,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
