import React from 'react';
import { useStyles } from './CenteredPage.styles';

interface CenteredPageProps {
  children: React.ReactNode;
}

export const CenteredPage: React.FC<CenteredPageProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
