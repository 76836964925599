import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';

import React from 'react';
import { useTranslation } from 'react-i18next';

export const EmailConfirmationSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box mt={8}>
      <Alert severity="success">
        {t('pages.signup.emailConfirmationSuccess.content')}
      </Alert>
    </Box>
  );
};
