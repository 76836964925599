import { DateRange, HourglassEmpty } from '@mui/icons-material';
import { TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { DEFAULT_DEBOUNCE_MILLIS } from '../consts';
import { SubmitOnChangeProps } from '../types';
import { useStyles } from './styles/DateField.styles';

type Props = Partial<DatePickerProps<DateTime>> &
  TextFieldProps &
  SubmitOnChangeProps & {
    showDateFormatPlaceholder?: boolean;
  };

export const DateField: React.FC<Props> = ({
  showDateFormatPlaceholder = false,
  submitOnChange,
  ...props
}) => {
  const formContext = useFormContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const debouncedSubmitForm = useDebouncedCallback(() => {
    formContext.handleSubmit(submitOnChange ?? (() => {}))();
  }, DEFAULT_DEBOUNCE_MILLIS);

  const {
    label,
    margin,
    variant,
    placeholder,
    required,
    InputProps,
    fullWidth,
  } = props;

  return (
    <Controller
      name={props.name || ''}
      control={formContext?.control ?? {}}
      render={({ field: { value, onChange }, formState }) => {
        // Convert to Luxon DateTime if the value is a string or a regular Date
        let dateValue: DateTime | null = null;
        if (value) {
          if (typeof value === 'string') {
            dateValue = DateTime.fromISO(value);
          } else {
            dateValue = DateTime.fromJSDate(new Date(value));
          }

          if (!dateValue.isValid) dateValue = null;
        }

        const error = get(formState.errors, props.name || '') as any;
        if (error && error.type === 'typeError') {
          error.message = 'Nieprawidłowy format daty';
        }

        return (
          <DatePicker
            {...(props as any)}
            mask="__.__.____"
            inputFormat="dd.MM.yyyy"
            className={classes.root}
            slots={{
              openPickerIcon: props.loading ? HourglassEmpty : DateRange,
            }}
            slotProps={{
              textField: {
                helperText:
                  error?.message ||
                  (formState.touchedFields[props.name || ''] && ''),
                required,
                placeholder,
                label: showDateFormatPlaceholder
                  ? `${label} (${t('app.common.fields.date.placeholder')})`
                  : label,
                margin: margin || 'dense',
                variant: variant || 'outlined',
                size: 'small',
                fullWidth,
                InputProps: InputProps,
                onBlur: () => {
                  if (!dateValue || !dateValue.isValid) {
                    onChange(null);
                  }
                },
                error: !!error?.message,
              },
            }}
            value={dateValue}
            onChange={(date: DateTime | null) => {
              onChange(date ? date.toJSDate() : null);
              if (submitOnChange) {
                debouncedSubmitForm();
              }
              if (date?.isValid) formContext.trigger(props.name || '');
            }}
          />
        );
      }}
    />
  );
};
