import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CURRENCY } from '../../../../../../common/components/form/consts';
import { DateTimeField } from '../../../../../../common/components/form/fields/DateTimeField';
import { SelectField } from '../../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { useModalInput } from '../../../../../../common/hooks/useModalInput';
import { ActionColor } from '../../../../../../common/types/actions';
import {
  MediaType,
  PaymentTypeEnum,
  useAppConfigQuery,
} from '../../../../../../generated/graphql';
import { MediaTypesDict } from '../../../referals/form/ReferalForm';
import { usePayments } from '../../hooks/usePayments';
import { StudyFormType } from '../../types';

type Props = {
  disabled: boolean;
};

export const PaymentsTabs: React.FC<Props> = ({ disabled }) => {
  const { control, getValues, watch, trigger } = useFormContext<
    StudyFormType
  >();
  const { fields, append, remove, update } = useFieldArray<
    StudyFormType,
    'payments',
    'fieldArrayId'
  >({
    control,
    name: 'payments',
    keyName: 'fieldArrayId',
  });

  const { applyDiscountToAllPayments } = usePayments();

  const fireDiscountPrompt = useModalInput(
    'dialogs.addDiscount.content',
    'dialogs.addDiscount.title',
    {
      maxRows: 1,
      minRows: 1,
      valueAsNumber: true,
      name: 'value',
      decimal: true,
    },
  );

  const addDiscount = useCallback(async () => {
    const currentDiscount = await fireDiscountPrompt();

    if (currentDiscount === undefined) return;

    const currentDiscountPercentage = Number(currentDiscount);
    applyDiscountToAllPayments(currentDiscountPercentage);
  }, [fireDiscountPrompt, applyDiscountToAllPayments]);

  const watchedStudyPayments = watch('payments');

  const { data: appConfig } = useAppConfigQuery();

  const { t } = useTranslation();

  const paymentOptions = useMemo(
    () =>
      Object.values(PaymentTypeEnum).map((paymentType) => ({
        value: paymentType,
        label: t(`fields.study.payments.type.${paymentType}`),
      })),
    [t],
  );

  return (
    <Box display="inline-block">
      {fields.map((field, i) => (
        <Box
          display="flex"
          flexDirection="column"
          marginBottom={4}
          key={field.fieldArrayId}
        >
          <Box
            display="flex"
            gap={5}
            flexDirection={{ xs: 'column', lg: 'row' }}
            marginBottom={4}
          >
            <DateTimeField
              name={`payments.${i}.createdAt`}
              label="Utworzono"
              defaultValue={
                field.createdAt ? DateTime.fromJSDate(field.createdAt) : null
              }
              disabled
            />
            <SelectField
              name={`payments.${i}.type`}
              label="Powód płatności"
              defaultValue={field.type}
              options={paymentOptions}
              disabled={disabled}
              required
              onChange={(event) => {
                const newPaymentType = event.target.value as PaymentTypeEnum;
                const studyType = getValues('studyType');
                if (
                  newPaymentType === PaymentTypeEnum.Description &&
                  studyType
                ) {
                  let price = studyType.descriptionPrice || 0;
                  if (
                    getValues('urgentDescription') &&
                    studyType.urgentDescriptionPrice
                  ) {
                    price =
                      studyType.urgentDescriptionPrice ||
                      studyType.descriptionPrice ||
                      0;
                  } else if (
                    getValues('comparisonDescription') &&
                    studyType.comparisonDescriptionPrice
                  ) {
                    price =
                      studyType.comparisonDescriptionPrice ||
                      studyType.descriptionPrice ||
                      0;
                  }
                  update(i, {
                    ...field,
                    value: price * (1 - (field.discount || 0) / 100),
                    type: newPaymentType,
                  });
                } else {
                  update(i, {
                    ...field,
                    value: 0,
                    type: newPaymentType,
                    group: null,
                  });
                }
              }}
            />
            <SelectField
              name={`payments.${i}.method`}
              label="Forma płatności"
              defaultValue={field.method}
              options={
                appConfig?.appConfig.paymentTypes?.map((paymentType) => ({
                  value: paymentType,
                  label: paymentType,
                })) || []
              }
              onChange={(event) => {
                if (
                  !watchedStudyPayments ||
                  watchedStudyPayments[i].type !== PaymentTypeEnum.Study
                )
                  return;

                watchedStudyPayments.forEach((payment, i) => {
                  if (payment.type !== PaymentTypeEnum.Study) {
                    update(i, {
                      ...payment,
                      method: event.target.value as string,
                    });
                    trigger('payments');
                  }
                });
              }}
              disabled={disabled}
              required
            />
            <SelectField
              name={`payments.${i}.group`}
              label="Typ płatności"
              defaultValue={appConfig?.appConfig?.priceGroups?.[0]}
              options={
                (watchedStudyPayments || [])[i]?.type === PaymentTypeEnum.Study
                  ? getValues('studyType')?.studyTypePrices?.map(
                      ({ presetName }) => ({
                        value: presetName || undefined,
                        label: presetName,
                      }),
                    ) || []
                  : Object.entries(MediaTypesDict).map(([key, value]) => ({
                      value: key,
                      label: value,
                    }))
              }
              onChange={(event) => {
                if (
                  (watchedStudyPayments || [])[i]?.type ===
                  PaymentTypeEnum.Study
                ) {
                  const studyTypePrices = getValues('studyType')
                    ?.studyTypePrices;

                  if (!studyTypePrices || studyTypePrices.length === 0) return;

                  const price =
                    studyTypePrices?.find(
                      ({ presetName }) => presetName === event.target.value,
                    )?.price || 0;

                  update(i, {
                    ...field,
                    type: PaymentTypeEnum.Study,
                    value: price * (1 - (field.discount || 0) / 100),
                    group: event.target.value as string,
                  });
                } else {
                  const dataCarrierPrices = appConfig?.appConfig.carrierPrices;
                  if (!dataCarrierPrices) return;

                  const firstDataCarrierPayment =
                    watch('payments')?.filter(
                      ({ type }) => type === PaymentTypeEnum.DataCarrier,
                    ).length === 1;

                  const price =
                    firstDataCarrierPayment &&
                    watch('payments')?.[i]?.group !== MediaType.Pendrive
                      ? 0
                      : dataCarrierPrices[event.target.value as string] || 0;

                  update(i, {
                    ...field,
                    type: PaymentTypeEnum.DataCarrier,
                    value: price * (1 - (field.discount || 0) / 100),
                    group: event.target.value as string,
                  });
                }
              }}
              disabled={
                disabled ||
                !(watchedStudyPayments || [])[i]?.type ||
                (watchedStudyPayments || [])[i]?.type ===
                  PaymentTypeEnum.Description
              }
            />
            <TextField
              name={`payments.${i}.discount`}
              label="Rabat (%)"
              defaultValue={field.discount || 0}
              valueAsNumber
              disabled
              style={{ width: 100 }}
            />
            <TextField
              currency
              name={`payments.${i}.value`}
              label="Wartość"
              defaultValue={field.value || 0}
              valueAsNumber
              required
              disabled={disabled}
            />
            <TextField
              name={`payments.${i}.comment`}
              label="Komentarz"
              defaultValue={field.comment}
              disabled={disabled}
            />

            {!disabled && (
              <IconButton
                aria-label="delete"
                style={{ color: ActionColor.RED }}
                onClick={() => remove(i)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
          <Divider />
        </Box>
      ))}
      {!!watchedStudyPayments?.length && (
        <Box display="flex" paddingTop={4}>
          <Typography variant="h6">
            {t('fields.study.payments.summary.label')}:{' '}
            {watchedStudyPayments
              ?.reduce((acc, { value = 0 }) => acc + value, 0)
              .toFixed(2)}
            {` ${DEFAULT_CURRENCY}`}
          </Typography>
        </Box>
      )}
      {!disabled && (
        <Box mt={6} display="flex" maxWidth={300}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              const payments = watch('payments');

              if (payments && payments[0]) {
                const firstPayment = payments[0];

                append({
                  createdAt: new Date(),
                  method: firstPayment.method,
                  discount: firstPayment.discount,
                });
              }
            }}
          >
            Dodaj opłatę
          </Button>
          <Button
            fullWidth
            variant="text"
            color="primary"
            onClick={addDiscount}
          >
            Nalicz rabat
          </Button>
        </Box>
      )}
    </Box>
  );
};
