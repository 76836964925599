import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close, Help } from '@mui/icons-material';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  showDownloads?: boolean;
};

export const HelpOpenFilePopup: React.FC<Props> = () => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'patientHelp',
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Tooltip title={t('app.common.fields.attachedFiles.help.zip')}>
        <IconButton
          aria-describedby="help-popover"
          {...bindTrigger(popupState)}
          size="large"
        >
          <Help fontSize="large" color="primary" />
        </IconButton>
      </Tooltip>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: isMobile ? 'bottom' : 'top',
          horizontal: 'center',
        }}
      >
        <Box width="auto" display="flex" flexDirection="column" p={10} gap={16}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            padding={4}
          >
            <Typography variant="h6">
              {t('app.common.fields.attachedFiles.help.zip')}
            </Typography>
            <IconButton size="small" onClick={() => popupState.setOpen(false)}>
              <Close />
            </IconButton>
          </Box>

          <iframe
            width={620}
            height={460}
            src="https://www.youtube.com/embed/Dvoqgfr-yMk?si=qiF4UuMfwRPwDefh"
            title={t('app.common.fields.attachedFiles.help.zip')}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Popover>
    </>
  );
};
