import { validateDoctorRegChecksum } from '../doctor-reg-number';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import {
  addressValidation,
  companyContactValidation,
  contactValidation,
} from '../contact/schema';

export type SignupType = 'doctor' | 'facility';

const licenseNumberValidation = (t: TFunction) =>
  yup
    .string()
    .required()
    .length(7)
    .matches(/^[\d]*$/, 'Numer może składać się wyłącznie z cyfr')
    .test(
      'licenseCheckSum',
      t('validation.doctor.licenseChecksum'),
      (value: any) => !!value && validateDoctorRegChecksum(value),
    );

export const signupValidationSchema = (t: TFunction, signupType: SignupType) =>
  yup.object().shape({
    contact:
      signupType === 'doctor' ? contactValidation : companyContactValidation,
    phoneNumber: yup.string().required(),
    ...(signupType === 'doctor' && {
      licenseNumber: licenseNumberValidation(t),
    }),
  });

export const doctorValidationSchema = (
  t: TFunction,
  signupType: SignupType,
) => {
  const phoneNoAddressValidation = yup.object().shape({
    phoneNumbers: yup.array().nullable().required().min(1),
    addresses: yup.array().nullable().of(addressValidation()),
  });

  return yup.object().shape({
    user: yup.object().shape({
      contact:
        signupType === 'doctor'
          ? contactValidation.concat(phoneNoAddressValidation as any)
          : companyContactValidation.concat(phoneNoAddressValidation as any),
    }),
    ...(signupType === 'doctor' && {
      licenseNumber: licenseNumberValidation(t),
    }),
  });
};
