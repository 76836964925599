import { Grid, Tooltip, Typography } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AsyncAutocompleteField,
  AsyncAutocompleteProps,
  AutocompleteItem,
} from '../../../../../common/components/form/fields/AsyncAutocompleteField';
import { Unarray } from '../../../../../common/types/unarray';
import { toLocaleDate } from '../../../../../common/utils/date.utils';
import {
  Maybe,
  Order,
  Patient,
  PatientsQuery,
  usePatientsLazyQuery,
} from '../../../../../generated/graphql';
import { PatientActions, PatientNoteAlert } from './components';

type Props<
  T extends AutocompleteItem,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = {
  controllerProps?: Partial<AsyncAutocompleteProps<T, Multiple, FreeSolo>>;
  autocompleteProps?: Partial<
    AutocompleteProps<T, Multiple, undefined, FreeSolo>
  >;
  multiple?: Multiple;
  withAddButton?: boolean;
  withPreviousStudiesButton?: boolean;
  withGoToPatientButton?: boolean;
};
type PatientType = Unarray<PatientsQuery['patients']['items']>;

export const DEFAULT_PATIENT_RENDER_OPTION = (option: PatientType) =>
  option.contact
    ? `${option.contact.firstName} ${option.contact.lastName} (${
        option.contact.ssn
          ? option.contact.ssn
          : toLocaleDate(option.contact.birthDate)
      })`
    : '';

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  patient: PatientType,
) => {
  return (
    <li {...props}>
      <Tooltip
        title={
          patient.contact?.primaryDescription ? (
            <>
              <Typography variant="caption">Notatka: </Typography>
              {patient.contact.primaryDescription}
            </>
          ) : null
        }
      >
        <span>{DEFAULT_PATIENT_RENDER_OPTION(patient)}</span>
      </Tooltip>
    </li>
  );
};

const getBirthDateFrom = (input?: string): Maybe<Date> => {
  var m = input?.match(/(\d{4})/);
  if (m) {
    return new Date(parseInt(m[1], 10), 0, 1, 0, 0, 0);
  } else return null;
};

export const PatientAutocomplete = <Multiple extends boolean | undefined>(
  props: Props<PatientType, Multiple, false>,
) => {
  const [loadPatients, { refetch, called }] = usePatientsLazyQuery({
    context: {
      disableGlobalLoading: true,
    },
  });
  const { watch } = useFormContext();

  const name = props.controllerProps?.name || 'patient';
  const selectedPatient = watch(name) as Patient;

  const loadPatientData = useCallback(
    async (autocompleteInputText?: string) => {
      const birthDateFrom =
        autocompleteInputText?.length !== 11
          ? getBirthDateFrom(autocompleteInputText)
          : null;
      const birthDateTo = birthDateFrom
        ? new Date(birthDateFrom.getFullYear(), 11, 31, 23, 59, 59)
        : null;

      const isSSN =
        autocompleteInputText &&
        autocompleteInputText?.length >= 5 &&
        new RegExp(`\\d{${autocompleteInputText?.length}}`, 'g').test(
          autocompleteInputText,
        );

      const query = {
        withStudies: false,
        where: {
          name: !isSSN
            ? autocompleteInputText
                ?.split(' ')
                .slice(
                  0,
                  birthDateFrom
                    ? autocompleteInputText?.split(' ').length - 1
                    : 2,
                )
                .join(' ')
            : null,
          ssn: isSSN ? autocompleteInputText : null,
          birthDateFrom: !isSSN ? birthDateFrom : null,
          birthDateTo: !isSSN ? birthDateTo : null,
        },
        pagination: { skip: 0, take: 15 },
        orderBy: [
          {
            columnName: 'contact.lastName',
            columnOrder: Order.Asc,
          },
        ],
      };

      const response = called
        ? await refetch(query)
        : await loadPatients({ variables: query });
      return response?.data?.patients?.items as PatientType[];
    },
    [refetch, loadPatients, called],
  );

  return (
    <Grid
      container
      spacing={4}
      alignContent="flex-start"
      justifyContent="flex-start"
    >
      <Grid item xs={12} md={9}>
        <AsyncAutocompleteField<PatientType, Multiple, false>
          {...props.autocompleteProps}
          name={name}
          loadData={loadPatientData}
          getOptionLabel={DEFAULT_PATIENT_RENDER_OPTION}
          renderOption={renderOption}
          label={props.controllerProps?.label || 'Pacjent'}
        />
        {selectedPatient?.contact?.primaryDescription && (
          <PatientNoteAlert note={selectedPatient.contact.primaryDescription} />
        )}
      </Grid>

      <PatientActions
        patient={selectedPatient}
        name={name}
        withAddButton={props.withAddButton}
        withPreviousStudiesButton={props.withPreviousStudiesButton}
        withGoToPatientButton={props.withGoToPatientButton}
        disabled={props.autocompleteProps?.disabled}
      />
    </Grid>
  );
};
