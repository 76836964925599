import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { styled } from '@mui/system';
import clsx from 'clsx';
import { get } from 'lodash-es';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DefaultValueProps,
  LabelProps,
  NameProps,
  SubmitOnChangeProps,
} from '../types';

type SelectOption = LabelProps & {
  value: string | number | undefined;
};

export type SelectFieldProps = Partial<SelectProps> &
  LabelProps &
  NameProps &
  SubmitOnChangeProps &
  DefaultValueProps & {
    options: SelectOption[];
  } & { withDefault?: boolean };

const RootBox = styled(Box)<Pick<SelectFieldProps, 'fullWidth'>>(
  ({ fullWidth }) => ({
    minWidth: '150px',
    width: fullWidth ? '100%' : 'auto',
  }),
);

export const SelectField: React.FC<SelectFieldProps> = ({
  withDefault,
  submitOnChange,
  ...props
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useFormContext();
  const error = get(errors, props.name);
  const { t } = useTranslation();

  return (
    <RootBox className={clsx(props.className || {})}>
      <FormControl
        variant="outlined"
        required={props.required}
        error={!!error}
        fullWidth
        size="small"
        margin="dense"
      >
        <InputLabel
          id={`select-label-${props.name}`}
          htmlFor={`select-label-${props.name}`}
          required={props.required}
          size="small"
          margin="dense"
        >
          {props.label}
        </InputLabel>
        <Controller
          control={control}
          name={props.name}
          render={({ field: { value, onChange, onBlur } }) => (
            <Select
              {...props}
              id={`select-${props.name}`}
              labelId={`select-label-${props.name}`}
              value={props.options.length ? value || '' : ''}
              margin="dense"
              onChange={(event, child) => {
                onChange(event, child);
                if (props.onChange) props.onChange(event, child);
                if (submitOnChange) handleSubmit(submitOnChange)(); // Use submitOnChange directly
              }}
              onBlur={onBlur}
              size="small"
            >
              {withDefault && (
                <MenuItem value="">
                  {t('app.common.fields.select.defaultOption')}
                </MenuItem>
              )}
              {props.options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {error && <FormHelperText>{error.message as string}</FormHelperText>}
      </FormControl>
    </RootBox>
  );
};
