import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Maybe } from '../../../../generated/graphql';

type Id = number | string;

export type MultiCheckboxEntry = {
  id: Id;
  name: string;
  remarks?: string;
  disabled?: boolean;
  comment?: Maybe<string>;
  subTypes?: Maybe<Array<string>>;
};

type SelectedIdentifier = Pick<
  MultiCheckboxEntry,
  'id' | 'remarks' | 'subTypes'
>;

type SelectedIdentifiers = Array<SelectedIdentifier>;

type ItemConverter = {
  to: (item: any) => SelectedIdentifier;
  from: (item: SelectedIdentifier) => any;
};

type Props = {
  items?: MultiCheckboxEntry[];
  label: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  showComment?: (entry: MultiCheckboxEntry) => boolean;
  subTypeOptions?: (entry: MultiCheckboxEntry) => string[];

  commentLabel?: string;
  dataConverter?: ItemConverter;
};

const itemConverter = (dataConverter: ItemConverter | undefined) => (
  entry: any,
): SelectedIdentifier => {
  let mappedEntry: SelectedIdentifier;

  if (dataConverter) {
    mappedEntry = dataConverter.to(entry);
  } else {
    mappedEntry = entry;
  }
  return mappedEntry;
};

const itemReverseConverter = (dataConverter: ItemConverter | undefined) => (
  entry: SelectedIdentifier,
) => {
  let rawEntry: any;

  if (dataConverter) {
    rawEntry = dataConverter.from(entry);
  } else {
    rawEntry = entry;
  }
  return rawEntry;
};

const elementExists = (dataConverter: ItemConverter | undefined) => (
  id: Id,
  fieldValue: any | SelectedIdentifiers,
) => {
  const converter = itemConverter(dataConverter);

  return fieldValue?.some((entry: any) => {
    const mappedEntry = converter(entry);
    return mappedEntry?.id === id;
  });
};

export const useStyles = makeStyles((theme) => ({
  commentField: {
    maxWidth: 200,
    marginBottom: theme.spacing(2),
  },
  comment: {
    color: '#aaa',
    whiteSpace: 'pre-line',
    marginLeft: 30,
    marginBottom: 10,
  },
}));

export const MultiCheckboxField: React.FC<Props> = ({
  label,
  items = [],
  required,
  name,
  disabled = false,
  showComment,
  subTypeOptions,
  commentLabel = '',
  dataConverter,
}) => {
  const { watch, control, formState } = useFormContext();
  const fieldValue = watch(name) as any[];
  const classes = useStyles();

  const converter = itemConverter(dataConverter);
  const reverseConverter = itemReverseConverter(dataConverter);
  const existenceChecker = elementExists(dataConverter);

  const handleSelect = useCallback(
    ({ id, remarks: comment, subTypes }: MultiCheckboxEntry) => {
      const newValues = existenceChecker(id, fieldValue)
        ? fieldValue?.filter((rawValue) => converter(rawValue).id !== id)
        : [
            ...(fieldValue ?? []),
            reverseConverter({ id, remarks: comment, subTypes }),
          ];

      return newValues;
    },
    [converter, existenceChecker, fieldValue, reverseConverter],
  );

  const handleRemarksChange = useCallback(
    ({ id, remarks: comment, subTypes }: MultiCheckboxEntry) => {
      return fieldValue.map((rawItem) =>
        converter(rawItem).id === id
          ? reverseConverter({ id, remarks: comment, subTypes })
          : rawItem,
      );
    },
    [converter, fieldValue, reverseConverter],
  );

  const getRemarksValue = useCallback(
    ({ id }: MultiCheckboxEntry) => {
      const item = fieldValue.find((rawItem) => converter(rawItem).id === id);
      return converter(item)?.remarks || '';
    },
    [converter, fieldValue],
  );

  const getSubTypesValue = useCallback(
    ({ id }: MultiCheckboxEntry) => {
      const item = fieldValue.find((rawItem) => converter(rawItem).id === id);
      return converter(item)?.subTypes || [];
    },
    [converter, fieldValue],
  );

  const isChecked = useCallback((id: Id) => existenceChecker(id, fieldValue), [
    existenceChecker,
    fieldValue,
  ]);

  const fieldError = formState.errors?.[name];

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!!formState.errors[name]}
      required={required}
    >
      <FormLabel component="legend">{label}</FormLabel>
      {fieldError && (
        <FormHelperText>{fieldError.message as string}</FormHelperText>
      )}
      <Box mt={4}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange } }) => (
            <FormGroup>
              <Grid container justifyContent="flex-start">
                {items.map((item) => (
                  <Grid
                    item
                    key={item.id}
                    xs={12}
                    //sm={items.length > 10 ? 6 : 12}
                    //md={items.length > 10 ? 4 : 12}
                  >
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" flexDirection="row">
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={disabled || item.disabled}
                              checked={isChecked(item.id)}
                              onChange={() => onChange(handleSelect(item))}
                              icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />}
                            />
                          }
                          label={item.name}
                        />
                        {isChecked(item.id) &&
                          showComment &&
                          showComment(item) && (
                            <TextField
                              id={`${item.id}_text`}
                              name={`${item.id}_text`}
                              label={commentLabel}
                              value={getRemarksValue(item)}
                              className={classes.commentField}
                              onChange={(e) => {
                                item.remarks = e.target.value;
                                onChange(
                                  handleRemarksChange({
                                    ...item,
                                    remarks: e.target.value,
                                  }),
                                );
                              }}
                              disabled={disabled}
                            />
                          )}
                        {isChecked(item.id) &&
                          subTypeOptions &&
                          subTypeOptions(item) && (
                            <Box
                              display="flex"
                              flexDirection={{ xs: 'column', md: 'row' }}
                            >
                              {subTypeOptions(item).map((option) => (
                                <FormControlLabel
                                  key={option}
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked={getSubTypesValue(item).includes(
                                        option,
                                      )}
                                      onChange={() => {
                                        const currentSubTypes = getSubTypesValue(
                                          item,
                                        );

                                        const subTypes = currentSubTypes.includes(
                                          option,
                                        )
                                          ? currentSubTypes?.filter(
                                              (opt) => opt !== option,
                                            )
                                          : [
                                              ...(currentSubTypes || []),
                                              option,
                                            ];

                                        onChange(
                                          handleRemarksChange({
                                            ...item,
                                            subTypes,
                                          }),
                                        );
                                      }}
                                      icon={<CircleUnchecked />}
                                      checkedIcon={<CircleCheckedFilled />}
                                    />
                                  }
                                  label={
                                    <Typography variant="caption">
                                      {option}
                                    </Typography>
                                  }
                                />
                              ))}
                            </Box>
                          )}
                      </Box>
                      {item.comment && (
                        <Typography
                          variant="caption"
                          className={classes.comment}
                        >
                          {item.comment}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          )}
        ></Controller>
      </Box>
    </FormControl>
  );
};
