import {
  Box,
  IconButton,
  Link,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close, Help } from '@mui/icons-material';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactCard } from '../../../../../common/components/contact-card/ContactCard';
import { Outline } from '../../../../../common/components/form/LabeledOutline';
import { useLanguage } from '../../../../../common/hooks/useLanguage';

type Props = {
  showDownloads?: boolean;
};

export const InstructionDownloadLink: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  const { currentLang } = useLanguage();
  const { t } = useTranslation();

  let downloadHref =
    'https://www.diagdent.pl/obrazki/file/CS-3D-Imaging-Light-PL.pdf';
  if (currentLang === 'en') {
    downloadHref =
      'https://www.diagdent.pl/obrazki/file/CS-3D-Imaging-Light-ENG.pdf';
  } else if (currentLang === 'ua') {
    downloadHref =
      'https://www.diagdent.pl/obrazki/file/CS-3D-Imaging-Light-UKR.pdf';
  }

  return (
    <Link
      style={{ cursor: 'pointer' }}
      target="_blank"
      href={downloadHref}
      onClick={onClick || (() => {})}
    >
      {t('pages.patient-studies.help.manual')}
    </Link>
  );
};

export const PatientHelpPopover: React.FC<Props> = ({
  showDownloads = true,
}) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'patientHelp',
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <IconButton
        aria-describedby="help-popover"
        {...bindTrigger(popupState)}
        size="large"
      >
        <Help fontSize="large" color="primary" />
      </IconButton>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: isMobile ? 'bottom' : 'top',
          horizontal: 'center',
        }}
      >
        <Box width="auto" display="flex" flexDirection="column" p={10} gap={16}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            padding={4}
          >
            <Typography variant="h6">
              {t('pages.patient-studies.help.title')}
            </Typography>
            <IconButton size="small" onClick={() => popupState.setOpen(false)}>
              <Close />
            </IconButton>
          </Box>

          <ContactCard
            contact={{
              emailAddresses: ['info@diagdent.pl'],
              firstName: 'Infolinia',
              lastName: '',
              primaryDescription: '🏢 8.00-20.00 / 9.00-15.00',
              phoneNumbers: ['733 70 90 90'],
            }}
          />
          {showDownloads && (
            <Outline label={t('pages.patient-studies.help.files-to-download')}>
              <Box display="flex" flexDirection="column" gap={8} p={4}>
                <InstructionDownloadLink
                  onClick={() => popupState.setOpen(false)}
                />

                <Link
                  style={{ cursor: 'pointer' }}
                  target="_blank"
                  href="https://www.diagdent.pl/obrazki/file/ViewData.exe"
                  onClick={() => popupState.setOpen(false)}
                >
                  {t('pages.patient-studies.help.viewer-download')}
                </Link>
              </Box>
            </Outline>
          )}
        </Box>
      </Popover>
    </>
  );
};
