import { Box } from '@mui/material';

import { uniqueId } from 'lodash-es';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CellProps } from 'react-table';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import {
  toLocaleDate,
  toLocaleDateTime,
} from '../../../../../common/utils/date.utils';
import {
  DailyStudyPaymentMethodRow,
  useDailyStudyPaymentsLazyQuery,
} from '../../../../../generated/graphql';
import { DailyStudyFilters } from '../components/DailyStudyFilters';

type Props = {};

type DailyStudyPaymentTableRow = DailyStudyPaymentMethodRow & { id: string };

export const DailyFinancialReportTab: React.FC<Props> = () => {
  const [, { data, refetch }] = useDailyStudyPaymentsLazyQuery({
    fetchPolicy: 'network-only',
    context: { withGlobalLoading: true },
  });

  const { t } = useTranslation();

  const COLUMNS: SortableColumn<DailyStudyPaymentTableRow>[] = useMemo(
    () => [
      {
        Header: 'Data badania',
        accessor: 'studyDate',
        Cell: ({
          value,
        }: CellProps<
          DailyStudyPaymentTableRow,
          DailyStudyPaymentTableRow['studyDate']
        >) => toLocaleDate(value),
        Footer: 'Razem',
      },
      {
        Header: 'Data płatności',
        accessor: 'studyPaymentDate',
        Cell: ({
          value,
        }: CellProps<
          DailyStudyPaymentTableRow,
          DailyStudyPaymentTableRow['studyDate']
        >) => toLocaleDateTime(value),
      },
      {
        Header: 'Pacjent',
        accessor: 'patientName',
        sortType: 'basic',
      },
      {
        Header: 'Typ płatności',
        accessor: 'paymentType',
        sortType: 'basic',
        Cell: ({
          value,
        }: CellProps<
          DailyStudyPaymentTableRow,
          DailyStudyPaymentTableRow['paymentType']
        >) => t(`fields.study.payments.type.${value}`),
      },
      {
        Header: 'Wykonał',
        accessor: 'performerName',
        sortType: 'basic',
      },
      {
        Header: 'Wartość',
        accessor: 'paymentValue',
        sortType: 'basic',
        Cell: ({
          value,
        }: CellProps<
          DailyStudyPaymentTableRow,
          DailyStudyPaymentTableRow['paymentValue']
        >) => value.toFixed(2),
        Footer: ({ rows }) =>
          rows
            .reduce((acc, curr) => (acc += curr.original.paymentValue), 0)
            .toFixed(2),
      },
      {
        Header: 'Forma płatności',
        accessor: 'paymentMethod',
        sortType: 'basic',
      },
      {
        Header: 'Filia',
        accessor: 'branchName',
        sortType: 'basic',
      },
    ],
    [t],
  );

  return (
    <>
      <DailyStudyFilters
        onSearch={(studyFilters) => {
          refetch({ filter: studyFilters });
        }}
      />
      {data?.studiesDailyFinancial && (
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          marginTop={8}
          gap={8}
        >
          <Table<DailyStudyPaymentTableRow>
            data={data?.studiesDailyFinancial.map((row) => ({
              ...row,
              id: uniqueId(),
            }))}
            columnConfig={COLUMNS}
            title="Dzienny raport płatności"
            pagination={false}
            manualSortBy={false}
          />
        </Box>
      )}
    </>
  );
};
