import { Box, Button, Grid, IconButton, Popover, Tooltip } from '@mui/material';
import { Close, Settings as SettingsIcon } from '@mui/icons-material';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../../../common/components/form/fields/SelectField';
import { Form } from '../../../../../../common/components/form/Form';
import { useAuth } from '../../../../../../common/hooks/useAuth';
import {
  useBranchesQuery,
  UserRole,
} from '../../../../../../generated/graphql';
import { BranchSelect } from '../../../../modules/common/fields/BranchSelect';
import { Protect } from '../../../auth/components/Protect';
import { ContextForm, useAppContextState } from '../../hooks/useSettingsState';

export const ContextPopover: React.FC = () => {
  const { t } = useTranslation();
  const { data: branches } = useBranchesQuery({
    variables: { filter: { ownBranch: { is: true } } },
  });

  const [settings, setSettings] = useAppContextState();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'contextPopover',
  });
  const { getAuthInfo } = useAuth();

  const handleSubmit = ({
    currentBranchId,
    currentRole,
    showAfterLogin,
  }: ContextForm) => {
    setSettings({
      ...settings,
      showAfterLogin,
      currentRole,
      currentBranch:
        branches?.branches.nodes.find(({ id }) => id === currentBranchId) ||
        null,
    });
  };

  const defaultValues = useMemo(
    () => ({
      ...settings,
      currentBranchId: settings.currentBranch?.id || null,
    }),
    [settings],
  );

  return (
    <>
      <Tooltip title={t('app.topBar.settings') || 'Settings'}>
        <IconButton
          aria-describedby="settings-popover"
          color="primary"
          {...bindTrigger(popupState)}
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box width={300} display="flex" flexDirection="column">
          <Box alignSelf="flex-end" padding={4}>
            <IconButton size="small" onClick={() => popupState.setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Form<ContextForm>
            onSubmit={handleSubmit}
            formOptions={{
              defaultValues,
            }}
          >
            <Grid container direction="column" spacing={8}>
              <Grid item>
                <Protect roles={[UserRole.Admin]} checkAllRoles>
                  <span>Wersja aplikacji:</span>
                  <br />
                  {(
                    process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA || 'localhost'
                  ).substring(0, 9)}
                </Protect>
              </Grid>
              <Grid item>
                <BranchSelect
                  ownOnly
                  name="currentBranchId"
                  label={t('settings.popover.currentBranch')}
                />
              </Grid>
              <Grid item>
                <Protect
                  roles={[
                    UserRole.Admin,
                    UserRole.Technician,
                    UserRole.DescribingDoctor,
                  ]}
                  checkAllRoles
                >
                  <SelectField
                    fullWidth
                    name="currentRole"
                    options={Object.values(UserRole)
                      .filter((role) => getAuthInfo().roles.includes(role))
                      .map((role) => ({
                        value: role,
                        label: t(`auth.roles.${role}`),
                      }))}
                    label={t('settings.popover.currentRole')}
                  />
                </Protect>
              </Grid>
              {/* 
            // TODO: Reintroduce this checkbox if necessary
            <Grid item>
              <CheckboxField
                name="showAfterLogin"
                label={t('settings.popover.showAfterLogin')}
              />
            </Grid> */}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  onClick={() => popupState.setOpen(false)}
                >
                  {t('app.common.actions.save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Popover>
    </>
  );
};
