import { Button, Fab, Hidden } from '@mui/material';
import { Done, ErrorOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import React, { useMemo } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { createUseReducerPersisted } from 'use-persisted';
import { TableCellLink } from '../../../../../common/components/table/cell/TableCellLink';
import { useTableActions } from '../../../../../common/components/table/hooks/useTableActions';
import { TableContext } from '../../../../../common/components/table/state/table.context';
import { tableReducer } from '../../../../../common/components/table/state/table.reducer';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import useDidMountEffect from '../../../../../common/hooks/useDidMountEffect';
import { EMPTY_ID_INPUT } from '../../../../../common/types/idInput';
import { toLocaleDateTime } from '../../../../../common/utils/date.utils';
import {
  Referal,
  useReferalsQuery,
  UserRole,
} from '../../../../../generated/graphql';
import { useTableRefresh } from '../../../../../common/hooks/useTableRefresh';
import {
  useCompleteReferalAction,
  useCreateStudyFromReferalAction,
  useDeleteReferalAction,
  useDownloadReferalAction,
  useEditReferalAction,
} from '../hooks/useReferalActions';
import { ReferalSearchPanel } from '../search/ReferalSearch';
import { useStyles } from './ReferalList.styles';
import Box from '@mui/material/Box';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { useTranslation } from 'react-i18next';
import { HybridTableStorage } from '../../../../../common/components/table/state/table.storage';

const initialState = {
  orderBy: {
    columnName: 'updatedAt',
    columnOrder: 'DESC',
  },
  pagination: {
    take: 20,
    skip: 0,
  },
  filter: {},
};
const usePersistedReducer = createUseReducerPersisted(
  'referalList',
  new HybridTableStorage(),
);

export const ReferalsList: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory<{ reload: boolean }>();
  const { t } = useTranslation();

  const [state, dispatch] = usePersistedReducer(
    tableReducer,
    HybridTableStorage.getInitialState('referalList', initialState),
  );

  const { data, fetchMore } = useReferalsQuery({
    variables: {
      pagination: { skip: 0, take: state.pagination.take },
      where: state.filter,
      orderBy: [state.orderBy],
    },
    context: { withGlobalLoading: true },
  });

  const { reloadAfterAction } = useTableRefresh(dispatch);

  const editReferal = useEditReferalAction();
  const completeReferal = useCompleteReferalAction();
  const createStudyFromReferal = useCreateStudyFromReferalAction();
  const deleteReferal = useDeleteReferalAction();
  const downloadReferal = useDownloadReferalAction();

  const tableActions = useTableActions([
    editReferal,
    reloadAfterAction(completeReferal),
    createStudyFromReferal,
    reloadAfterAction(deleteReferal),
    downloadReferal,
  ]);

  const [{ currentRole }] = useAppContextState();

  type CellType = { row: { original: Referal } };

  const columns = useMemo<SortableColumn<Referal>[]>(
    () => [
      {
        id: 'completed',
        width: 30,
        maxWidth: 30,
        accessor: ({ completed }) => completed,
        Cell: ({ value }: { value: Referal['completed'] }) =>
          value && <Done scale={0.3} color="secondary" />,
      },
      {
        id: 'patientContact.lastName',
        Header: 'Nazwisko i imię',
        accessor: ({ patient }) => patient.contact,
        Cell: ({ row: { original } }: CellType) => (
          <TableCellLink
            onClick={() =>
              history.push(`/patients/view/${original.patient.id}`)
            }
          >
            {original.patient.contact.lastName}{' '}
            {original.patient.contact.firstName}
          </TableCellLink>
        ),
      },
      {
        id: 'createdAt',
        Header: 'Data skierowania',
        accessor: ({ createdAt }) => createdAt,
        Cell: ({ value }: { value: Referal['createdAt'] }) =>
          toLocaleDateTime(value),
      },
      {
        disableSortBy: true,
        Header: 'Badania',
        accessor: ({ referalToStudyTypes }) => referalToStudyTypes,
        Cell: ({ row: { original } }: CellType) =>
          original.referalToStudyTypes
            .map(({ studyType: { name } }) => name)
            .join(', '),
      },
      {
        id: 'issuerDoctorUserContact.lastName',
        Header: 'Lekarz',
        accessor: ({ issuer }) => issuer.user.contact.lastName,
        Cell: ({ row: { original } }: CellType) => (
          <TableCellLink
            onClick={() => history.push(`/doctors/view/${original.issuer.id}`)}
          >
            {original.issuer?.user?.contact?.lastName}{' '}
            {original.issuer?.user?.contact?.firstName}
          </TableCellLink>
        ),
      },
      {
        id: 'requestDescription',
        Header: 'Prośba o opis',
        width: 50,
        accessor: ({ requestDescription }) => requestDescription,
        Cell: ({ row: { original } }: CellType) =>
          original.requestDescription && (
            <ErrorOutline className={classes.requestDescriptionIcon} />
          ),
      },
      tableActions,
    ],
    [history, tableActions, classes],
  );

  const filteredColumns = columns.filter(
    ({ id }) =>
      id !== 'issuerDoctorUserContact.lastName' ||
      currentRole !== UserRole.Doctor,
  );

  useDidMountEffect(() => {
    fetchMore({
      variables: {
        where: state.filter,
        pagination: state.pagination,
        orderBy: [state.orderBy],
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  }, [fetchMore, state.filter, state.pagination, state.orderBy]);

  const AddNewReferalButton = React.memo(() => (
    <Button
      variant="contained"
      color="primary"
      className={classes.addButton}
      component={RouterLink}
      to="/referals/add"
    >
      <Box whiteSpace="noWrap">Wystaw skierowanie</Box>
    </Button>
  ));

  return (
    <TableContext.Provider value={{ state, dispatch }}>
      <ReferalSearchPanel
        onSearch={({ issuer, ...filter }) =>
          dispatch({
            type: 'FILTER_CHANGE',
            payload: { ...filter, issuerId: issuer?.id },
          })
        }
        filters={{
          ...state.filter,
          issuer: state.filter.issuerId
            ? { id: state.filter.issuerId }
            : EMPTY_ID_INPUT(),
        }}
      />
      <Table<Referal>
        title="Lista Skierowań"
        columnConfig={filteredColumns}
        data={data?.referals.items as Referal[]}
        totalCount={data?.referals.total || 0}
        onRowClicked={({ id }) => history.push(`/referals/view/${id}`)}
        emptyLabel={
          <Box
            display="flex"
            gap={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {t('pages.referal.list.emptyLabel')}
            <AddNewReferalButton />
          </Box>
        }
        toolbarContent={
          <Hidden mdDown>
            <AddNewReferalButton />
          </Hidden>
        }
      />
      {(data?.referals?.total || 0) > 0 && (
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          component={RouterLink}
          to="/referals/add"
        >
          <AddIcon />
        </Fab>
      )}
    </TableContext.Provider>
  );
};
