import Paper from '@mui/material/Paper';
import { default as MaterialUITable } from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import React, { useCallback, useContext } from 'react';
import {
  Column,
  PluginHook,
  Row,
  SortingRule,
  TableInstance,
  TableOptions,
  usePagination,
  UsePaginationInstanceProps,
  useRowSelect,
  UseRowSelectInstanceProps,
  useSortBy,
  UseSortByColumnOptions,
  UseSortByInstanceProps,
  UseSortByOptions,
  useTable,
} from 'react-table';
import { TableRowItem } from '../../types/table';
import { Loading } from '../global/loading/Loading';
import { InfoLabel } from '../info-label/InfoLabel';
import { Body } from './body/Body';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';
import { usePreviousData } from './hooks/usePreviousData';
import { Pagination } from './pagination/Pagination';
import { TableContext } from './state/table.context';
import { useStyles } from './Table.styles';
import { TableToolbar } from './toolbar/Toolbar';

export type TableProps<T extends TableRowItem> = {
  columnConfig: Column<T>[];
  data: T[];
  defaultSort?: SortingRule<T>[];
  emptyLabel?: React.ReactNode;
  hasCollapsableFilters?: boolean;
  loading?: boolean;
  onRowClicked?: (row: Row<T>) => void;
  pagination?: boolean;
  rowColor?: (row: Row<T>) => string | undefined;
  selectable?: boolean;
  title: string;
  toolbarContent?: React.ReactNode;
  totalCount?: number;
  dense?: boolean;
} & Pick<UseSortByOptions<T>, 'manualSortBy'>;

export type SortableColumn<T extends TableRowItem> = Column<T> &
  UseSortByColumnOptions<T>;

export const Table = <T extends TableRowItem>({
  columnConfig,
  data,
  loading = false,
  onRowClicked,
  manualSortBy = true,
  defaultSort = [],
  pagination = true,
  selectable = false,
  dense = false,
  title,
  totalCount = 0,
  hasCollapsableFilters,
  toolbarContent,
  rowColor,
  emptyLabel,
}: TableProps<T>) => {
  const classes = useStyles();

  const { state } = useContext(TableContext);

  const prevData = usePreviousData(data);

  const getRowId = useCallback((row: TableRowItem) => row.id, []);

  //TODO: Extract selectable logic to separate module/hook/component
  const tablePlugins: PluginHook<T>[] = [useSortBy, usePagination];
  if (selectable) tablePlugins.push(useRowSelect);

  const {
    columns,
    gotoPage,
    prepareRow,
    rows,
    setPageSize: setTablePageSize,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable<T>(
    {
      data: data || prevData || [],
      columns: columnConfig,
      manualPagination: true,
      manualSortBy,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) =>
          String(row1.values[columnName])?.localeCompare(
            row2.values[columnName],
          ),
      },
      pageCount: Math.ceil(Math.min(totalCount, 100) / state.pagination.take),
      autoResetSelectedRows: false,
      initialState: {
        pageSize: state.pagination.take,
        sortBy: defaultSort, //TODO: set default also from state
        pageIndex: state.pagination.skip / state.pagination.take,
      } as any,
      getRowId,
      page: state.pagination.skip / state.pagination.take,
    } as TableOptions<T> & UseSortByOptions<T>,
    ...tablePlugins,
  ) as TableInstance<T> &
    UsePaginationInstanceProps<T> &
    UseSortByInstanceProps<T> &
    UseRowSelectInstanceProps<T> & { state: any };

  /* if () return null;
  else if () {
    return ;
  } else {
    return (
      
    );
  } */

  return (
    <>
      <Loading open={loading} />
      <div className={classes.root}>
        <TableContainer component={Paper} className={classes.paper}>
          <TableToolbar
            selectedRows={selectedRowIds}
            /* title={`${title} ${
        !isEmpty(state.filter) ? '(Wyniki zawężone)' : ''
      }`} */
            title={title}
            hasCollapsableFilters={hasCollapsableFilters}
          >
            {toolbarContent}
          </TableToolbar>

          <MaterialUITable
            className={classes.table}
            stickyHeader
            size={dense ? 'small' : 'medium'}
          >
            <Header
              classes={classes}
              columns={columns as any}
              selectable={selectable}
              setPageIndex={gotoPage}
            />
            {data && rows.length === 0 && emptyLabel ? (
              <tr>
                <td colSpan={42}>
                  <InfoLabel>{emptyLabel}</InfoLabel>
                </td>
              </tr>
            ) : (
              data && (
                <>
                  <Body
                    onRowClicked={onRowClicked}
                    rowColor={rowColor}
                    prepareRow={prepareRow}
                    selectedRowIds={selectedRowIds}
                    selectable={selectable}
                    rows={rows}
                    pageIndex={pageIndex}
                  />
                  {<Footer columns={columns as any} rows={rows} />}
                </>
              )
            )}
          </MaterialUITable>
          {pagination && rows?.length > 0 && (
            <Pagination
              gotoPage={gotoPage}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setTablePageSize={setTablePageSize}
              totalCount={totalCount}
            />
          )}
        </TableContainer>
      </div>
    </>
  );
};
