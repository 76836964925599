import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  FormControl,
} from '@mui/material';
import * as React from 'react';
import { useFormContext, Controller, Control } from 'react-hook-form';
import { get } from 'lodash-es';
import { NameProps } from '../types';
import { useStyles } from './styles/FormCheckBox.styles';

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

export const CheckboxField: React.FC<
  Partial<Control> & Partial<FormControlLabelProps> & NameProps
> = ({ name, label, value, disabled }) => {
  const { control, formState } = useFormContext();
  const classes = useStyles();

  const error = get(formState.errors, name);

  return (
    <FormControl error={!!error} disabled={disabled}>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Checkbox
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
              />
            )}
          ></Controller>
        }
        value={value}
        label={label}
      />
      {error && (
        <FormHelperText className={classes.root}>
          {error.message as string}
        </FormHelperText>
      )}
    </FormControl>
  );
};
