import { Box, Button, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, PropsWithChildren, useState } from 'react';
import { Form, FormProps } from '../../../../../common/components/form/Form';
import { DateTimeField } from '../../../../../common/components/form/fields/DateTimeField';
import { SelectField } from '../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import {
  Complaint,
  ComplaintInput,
  ComplaintStatusEnum,
  ComplaintTypeEnum,
  UpdateComplaintInput,
  UserRole,
  useAppConfigQuery,
} from '../../../../../generated/graphql';
import { StudySelect } from '../../common/fields/StudySelect';
import { DoctorAutocomplete } from '../../doctors/fields/DoctorAutocomplete';
import { PatientAutocomplete } from '../../patients/fields/PatientAutocomplete';
import { UserAutocomplete } from '../../users/fields/UserAutocomplete';
import { EMPTY_ID_INPUT } from '../../../../../common/types/idInput';

type Props = PropsWithChildren &
  Pick<FormProps<any>, 'noElevation'> & {
    onSubmit: (data: ComplaintInput | UpdateComplaintInput) => void;
    complaint?: ComplaintInput | Complaint;
    disabled?: boolean;
  };

export const EMPTY_COMPLAINT = () => ({
  complainantName: '',
  createdAt: DateTime.now(),
  complaintDescription: '',
  complaintReason: '',
  complaintResolution: '',
  status: ComplaintStatusEnum.InProgress,
  type: ComplaintTypeEnum.Patient,
  responsiblePerson: EMPTY_ID_INPUT(),
  study: null,
});

const useSubmitComplaint = ({ onSubmit }: Props) => {
  const submitComplaint = (formData: ComplaintInput) => {
    const responsiblePerson = formData.responsiblePerson?.id
      ? { id: formData.responsiblePerson.id }
      : null;
    const doctor = formData.doctor?.id ? { id: formData.doctor.id } : null;
    const patient = formData.patient?.id ? { id: formData.patient.id } : null;
    const study = formData.study?.id ? { id: formData.study.id } : null;

    const finalFormData = formData as Complaint;

    const {
      id,
      dueDate,
      createdById,
      createdAt,
      updatedById,
      ...rest
    } = finalFormData;

    onSubmit({
      ...rest,
      doctor,
      patient,
      study,
      responsiblePerson,
    } as ComplaintInput);
  };

  return submitComplaint;
};

export const ComplaintForm: FC<Props> = ({
  onSubmit,
  complaint,
  disabled = false,
  children,
  noElevation,
}) => {
  const [type, setType] = useState(complaint?.type || 'Patient');

  const submitComplaint = useSubmitComplaint({ onSubmit, complaint });

  const { data: appConfigData } = useAppConfigQuery();
  const complaintTypes = appConfigData?.appConfig?.complaintTypes || [];

  return (
    <Form<ComplaintInput & { status: ComplaintStatusEnum }>
      onSubmit={submitComplaint}
      noElevation={noElevation}
      formOptions={{
        defaultValues: (complaint as ComplaintInput) || EMPTY_COMPLAINT(),
        shouldFocusError: true,
      }}
      render={({ setValue, watch }) => {
        const complaintType = watch('type') || type;

        return (
          <>
            <Grid container spacing={4}>
              <Grid container spacing={4} item xs={12}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    autoFocus
                    name="complainantName"
                    label="Imię i Nazwisko"
                    disabled={disabled}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DateTimeField
                    name="createdAt"
                    label="Data reklamacji"
                    disabled={disabled}
                    required
                    defaultValue={DateTime.now()}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField
                    name="status"
                    label="Status reklamacji"
                    options={[
                      { value: 'InProgress', label: 'W trakcie' },
                      { value: 'Closed', label: 'Zamknięta' },
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectField
                  name="complaintReason"
                  label="Powód reklamacji"
                  disabled={disabled}
                  options={complaintTypes.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="complaintDescription"
                  label="Opis reklamacji"
                  minRows={4}
                  disabled={disabled}
                  maxRows={8}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="complaintResolution"
                  label="Rozwiązanie"
                  minRows={4}
                  disabled={
                    disabled || watch('status') !== ComplaintStatusEnum.Closed
                  }
                  maxRows={8}
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectField
                  name="type"
                  label="Typ"
                  options={[
                    { value: 'Patient', label: 'Od Pacjenta' },
                    { value: 'Doctor', label: 'Od Lekarza' },
                  ]}
                  disabled={disabled}
                  required
                  onChange={({ target: { value } }) => setType(value as string)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {complaintType === 'Patient' && (
                  <PatientAutocomplete
                    autocompleteProps={{
                      disabled,
                      multiple: false,
                      onChange: (_, val) => {
                        if (val) {
                          setValue(
                            'complainantName',
                            `${val.contact.firstName} ${val.contact.lastName}`,
                          );
                        }
                      },
                    }}
                  />
                )}
                {complaintType === 'Doctor' && (
                  <DoctorAutocomplete
                    autocompleteProps={{
                      disabled,
                      multiple: false,
                      onChange: (_, val) => {
                        if (val) {
                          setValue(
                            'complainantName',
                            `${val.user.contact.firstName} ${val.user.contact.lastName}`,
                          );
                        }
                      },
                    }}
                  />
                )}
                <UserAutocomplete
                  role={UserRole.Complaints}
                  controllerProps={{
                    required: true,
                    label: 'Osoba odpowiedzialna',
                    name: 'responsiblePerson',
                  }}
                  autocompleteProps={{
                    disabled,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <StudySelect
                  name="study.id"
                  label="Dotyczy badania"
                  patientId={watch('patient')?.id}
                  disabled={disabled || complaintType === 'Doctor'}
                />
              </Grid>
            </Grid>

            <Box mt={8}>
              {!disabled && (
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  Zapisz reklamację
                </Button>
              )}
            </Box>
            {children}
          </>
        );
      }}
    ></Form>
  );
};
