import { IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRemoveConfirm } from '../../../../../../common/hooks/useConfirmation';
import { ActionColor } from '../../../../../../common/types/actions';
import { useMyCouncilsQuery } from '../../../../../../generated/graphql';
import { useCouncilApi } from '../../hooks/useCouncilApi';
import { CouncilMembers } from '../../panel/CouncilMembers';

export const CouncilDoctorsTab: React.FC = () => {
  const { data } = useMyCouncilsQuery({
    fetchPolicy: 'network-only',
  });

  const { t } = useTranslation();
  const confirmRemoval = useRemoveConfirm();
  const { removeDoctorFromCouncil } = useCouncilApi();

  return (
    <TabPanel value="existing">
      <CouncilMembers
        emptyLabel={t('pages.council.panel.doctors.empty')}
        members={
          data &&
          (data.getMyCouncils[0]?.councilMembers
            .filter(({ owner }) => !owner)
            .map(({ councilMemberDoctor }) => ({
              contact: councilMemberDoctor.user.contact,
              profilePicture:
                councilMemberDoctor.user.profilePicture?.publicURL,
              councilId: data.getMyCouncils[0].id,
            })) ||
            [])
        }
        actions={(_, contactToRemove) => (
          <Tooltip
            title={t('pages.council.panel.doctors.actions.remove') as string}
          >
            <IconButton
              style={{ color: ActionColor.RED }}
              onClick={async () => {
                await confirmRemoval();
                removeDoctorFromCouncil({
                  variables: {
                    doctorId: data?.getMyCouncils[0].councilMembers.find(
                      ({
                        councilMemberDoctor: {
                          user: { contact },
                        },
                      }) => contact.id === contactToRemove.id,
                    )?.councilMemberDoctor.id as number,
                  },
                });
              }}
              size="large"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      ></CouncilMembers>
    </TabPanel>
  );
};
