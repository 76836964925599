import { AppBar, Paper, Tab, Tabs } from '@mui/material';
import {
  Computer,
  FormatListBulleted,
  Store,
  Person,
  MailOutline,
  AllInbox,
  Settings,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import { ProtectedRoute } from '../auth/ProtectedRoute';
import { AppConfigPanel } from './panels/app-config/AppConfigPanel';
import { BranchPanel } from './panels/branches/BranchPanel';
import { EmailTemplatePanel } from './panels/email-templates/EmailTemplatePanel';
import { MailingPanel } from './panels/mailing/MailingPanel';
import { SoftwarePanel } from './panels/software/SoftwarePanel';
import { StudyTypePanel } from './panels/study-types/StudyTypePanel';
import { UserPanel } from './panels/users/UserPanel';
import { TabPanel } from '../../../../common/components/tab-panel/TabPanel';
import { useAppContextState } from './hooks/useSettingsState';
import { UserRole } from '../../../../generated/graphql';

const SETTINGS_TAB_ROUTES = [
  'branches',
  'programs',
  'study-types',
  'users',
  'email-templates',
  'email-mailing',
  'app-config',
];

export const AdminPage: React.FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    Math.max(
      SETTINGS_TAB_ROUTES.findIndex((tab) => pathname.includes(tab)),
      0,
    ),
  );
  const history = useHistory();

  const handleTabSwitch = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    history.push(`/settings/${SETTINGS_TAB_ROUTES[newValue]}`);
  };

  const [{ currentRole }] = useAppContextState();

  const isAdmin = currentRole === UserRole.Admin;

  return (
    <Paper>
      <AppBar position="static" color="inherit">
        <Tabs
          value={selectedTab}
          onChange={handleTabSwitch}
          indicatorColor="primary"
        >
          <Tab icon={<Store />} label={t('settings.tabs.branches')}></Tab>
          <Tab
            icon={<Computer />}
            label={t('settings.tabs.programs')}
            disabled={!isAdmin}
          ></Tab>
          <Tab
            icon={<FormatListBulleted />}
            label={t('settings.tabs.studyTypes')}
            disabled={!isAdmin}
          ></Tab>
          <Tab icon={<Person />} label={t('settings.tabs.users')}></Tab>
          <Tab
            icon={<MailOutline />}
            label={t('settings.tabs.email-templates')}
            disabled={!isAdmin}
          ></Tab>
          <Tab
            icon={<AllInbox />}
            label={t('settings.tabs.mailing')}
            disabled={!isAdmin}
          ></Tab>
          <Tab
            icon={<Settings />}
            label={t('settings.tabs.app-config')}
            disabled={!isAdmin}
          ></Tab>
        </Tabs>
      </AppBar>
      <Switch>
        <ProtectedRoute exact path={`${match.path}/`}>
          <Redirect to={`${match.path}/branches`} />
        </ProtectedRoute>

        <ProtectedRoute path={`${match.path}/branches`}>
          <TabPanel selectedIndex={selectedTab} index={0}>
            <BranchPanel />
          </TabPanel>
        </ProtectedRoute>
        <ProtectedRoute
          path={`${match.path}/programs`}
          requiredRoles={[UserRole.Admin]}
        >
          <TabPanel selectedIndex={selectedTab} index={1}>
            <SoftwarePanel />
          </TabPanel>
        </ProtectedRoute>
        <ProtectedRoute
          path={`${match.path}/study-types`}
          requiredRoles={[UserRole.Admin]}
        >
          <TabPanel selectedIndex={selectedTab} index={2}>
            <StudyTypePanel />
          </TabPanel>
        </ProtectedRoute>
        <ProtectedRoute path={`${match.path}/users`}>
          <TabPanel selectedIndex={selectedTab} index={3}>
            <UserPanel />
          </TabPanel>
        </ProtectedRoute>
        <ProtectedRoute
          path={`${match.path}/email-templates`}
          requiredRoles={[UserRole.Admin]}
        >
          <TabPanel selectedIndex={selectedTab} index={4}>
            <EmailTemplatePanel />
          </TabPanel>
        </ProtectedRoute>
        <ProtectedRoute
          path={`${match.path}/email-mailing`}
          requiredRoles={[UserRole.Admin]}
        >
          <TabPanel selectedIndex={selectedTab} index={5}>
            <MailingPanel />
          </TabPanel>
        </ProtectedRoute>
        <ProtectedRoute
          path={`${match.path}/app-config`}
          requiredRoles={[UserRole.Admin]}
        >
          <TabPanel selectedIndex={selectedTab} index={6}>
            <AppConfigPanel />
          </TabPanel>
        </ProtectedRoute>
      </Switch>
    </Paper>
  );
};
