import * as yup from 'yup';
import {
  AppointmentBreakInput,
  AppointmentAddInput,
  AppointmentUpdateInput,
} from '../../../generated/graphql';

export const schema = yup
  .object<
    AppointmentAddInput | AppointmentUpdateInput | AppointmentBreakInput
  >()
  .shape({
    patient: yup
      .object<AppointmentAddInput>()
      .nullable()
      .when('isBreak', ([isBreak]: boolean[], schema) => {
        if (!isBreak) {
          return schema.autocompleteRequired();
        } else {
          return schema;
        }
      }),
    studyType: yup
      .object()
      .nullable()
      .when(['isBreak', 'id'], ([isBreak, id]: any[], schema) => {
        if (!isBreak && id) {
          return schema.autocompleteRequired();
        } else {
          return schema;
        }
      }),
    studyTypes: yup
      .array()
      .nullable()
      .when(['isBreak', 'id'], ([isBreak, id]: any[], schema: any) => {
        if (!isBreak && !id) {
          return schema.autocompleteMultipleRequired();
        } else {
          return schema;
        }
      }),
    startDate: yup
      .date()
      .required()
      .min(new Date(), 'Data początkowa musi być w przyszłości'),
    endDate: yup
      .date()
      .nullable()
      .required()
      .min(new Date(), 'Data końcowa musi być w przyszłości')
      .min(
        yup.ref('startDate'),
        'Data końcowa musi być późniejsza niż początkowa',
      ),
    device: yup.object({
      id: yup
        .number()
        .notOneOf([0], 'Gabinet jest wymagany')
        .typeError('Gabinet jest wymagany'),
    }),
    branch: yup.object({
      id: yup
        .number()
        .notOneOf([0], 'Filia jest wymagana')
        .typeError('Filia jest wymagana'),
    }),
  });
