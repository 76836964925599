import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DateField } from '../../../../../common/components/form/fields/DateField';
import { SelectField } from '../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { Form, FormProps } from '../../../../../common/components/form/Form';
import { useSSNCheck } from '../../../../../common/hooks/useSSNCheck';
import {
  extractBirthDateFromPESEL,
  extractGenderFromPESEL,
} from '../../../../../common/utils/pesel.utils';
import { fullContactValidation } from '../../../../../common/validation/contact/schema';
import { Gender, PatientInput } from '../../../../../generated/graphql';
import { TagsField } from '../../../components/tags/TagsField';
import { SSNField } from '../../common/fields/SSNField';
import { DateTime } from 'luxon';

const schema = (checkPESEL?: boolean) =>
  yup.object<PatientInput>().shape({
    contact: fullContactValidation(undefined, checkPESEL),
  });

type Props = PropsWithChildren &
  Pick<FormProps<any>, 'noElevation'> & {
    onSubmit: (data: PatientInput) => void;
    patient?: PatientInput;
    disabled?: boolean;
  };

export const EMPTY_PATIENT = () => ({
  contact: {
    birthDate: null,
    phoneNumbers: [],
    emailAddresses: [],
    firstName: '',
    lastName: '',
    ssn: '',
    gender: '', // A bit hacky, as this is neither 'Female' or 'Male', but this is to satisfy the Form useEffect hook that watches for defaults being replaced
  },
  registrationDate: new Date(),
});

export const PatientForm: FC<Props> = ({
  onSubmit,
  patient,
  disabled = false,
  children,
  noElevation,
}) => {
  const { t } = useTranslation();
  const { checkSSN, setCheckSSN } = useSSNCheck(patient?.contact?.ssn);

  const genderOptions = Object.values(Gender).map((gender) => ({
    value: gender,
    label: t(`fields.contact.gender.${gender}`),
  }));

  return (
    <Form<PatientInput>
      onSubmit={onSubmit}
      noElevation={noElevation}
      formOptions={{
        defaultValues: patient || EMPTY_PATIENT(),
        shouldFocusError: true,
        resolver: yupResolver(schema(checkSSN)) as any,
      }}
      render={({ setValue, clearErrors, trigger }) => {
        return (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  name="contact.firstName"
                  label="Imię"
                  disabled={disabled}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="contact.lastName"
                  label="Nazwisko"
                  disabled={disabled}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" gap={5}>
                  <SSNField
                    validate={checkSSN}
                    onValidationStateChange={(validationState) => {
                      setCheckSSN(validationState);
                      clearErrors('contact.ssn');
                    }}
                    name="contact.ssn"
                    disabled={disabled}
                    fullWidth
                    variant="outlined"
                    required
                    onBlur={(event) => {
                      const fieldValue = event.target.value;
                      setValue(
                        'contact.birthDate',
                        extractBirthDateFromPESEL(fieldValue),
                      );
                      setValue(
                        'contact.gender',
                        extractGenderFromPESEL(fieldValue),
                      );

                      if (fieldValue) {
                        trigger('contact.birthDate');
                        trigger('contact.gender');
                      }
                    }}
                  />
                  <SelectField
                    name="contact.gender"
                    label="Płeć"
                    options={genderOptions}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateField
                  openTo="year"
                  views={['year', 'month', 'day']}
                  name="contact.birthDate"
                  label="Data urodzenia"
                  disabled={disabled}
                  fullWidth
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TagsField
                  name="contact.phoneNumbers"
                  label="Numery telefonów"
                  placeholder="Dodaj kolejny..."
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TagsField
                  name="contact.emailAddresses"
                  label="Adresy e-mail"
                  placeholder="Dodaj kolejny..."
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="contact.primaryDescription"
                  label="Notatka"
                  defaultValue={patient?.contact?.primaryDescription}
                  minRows={4}
                  maxRows={8}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateField
                  defaultValue={DateTime.now()}
                  name="registrationDate"
                  label="Data rejestracji"
                  disabled={disabled}
                  fullWidth
                  variant="outlined"
                  required
                />
              </Grid>
            </Grid>

            <Box mt={8}>
              {!disabled && (
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  Zapisz pacjenta
                </Button>
              )}
            </Box>
            {children}
          </>
        );
      }}
    ></Form>
  );
};
