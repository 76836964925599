import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactCard } from '../../../../../common/components/contact-card/ContactCard';
import { useHelpUserContactsQuery } from '../../../../../generated/graphql';

export const LoginHelpPopover: React.FC = () => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'loginHelp',
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data } = useHelpUserContactsQuery({
    variables: { count: isMobile ? 1 : 3 },
  });

  return (
    <>
      <Tooltip title={t('login.actions.help') as string}>
        <IconButton
          aria-describedby="help-popover"
          {...bindTrigger(popupState)}
          size="large"
        >
          <HelpIcon />
        </IconButton>
      </Tooltip>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: isMobile ? 'bottom' : 'top',
          horizontal: 'center',
        }}
      >
        <Box width="auto" display="flex" flexDirection="column" p={10}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            padding={4}
          >
            <Typography variant="h6">{t('login.help.title')}</Typography>
            <IconButton size="small" onClick={() => popupState.setOpen(false)}>
              <Close />
            </IconButton>
          </Box>

          <Box display="flex" gap={10} flexDirection="row">
            {data?.helpUserContacts.map((contact) => (
              <ContactCard
                contact={contact}
                key={contact.profilePictureURL}
                profilePictureURL={contact.profilePictureURL}
              />
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
