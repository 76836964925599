import { useEffect } from 'react';
import { useGoogleReCaptcha } from '@wojtekmaj/react-recaptcha-v3';

const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName('grecaptcha-badge')[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? 'visible' : 'hidden';
  }
};

export const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    setTimeout(() => toggleCaptchaBadge(true), 1000);
    return () => toggleCaptchaBadge(false);
  }, []);

  return { executeRecaptcha };
};
