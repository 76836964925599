import { IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRemoveConfirm } from '../../../../../../common/hooks/useConfirmation';
import { ActionColor } from '../../../../../../common/types/actions';
import {
  Contact,
  useMyCouncilParticipationsQuery,
} from '../../../../../../generated/graphql';
import { useCouncilApi } from '../../hooks/useCouncilApi';
import { CouncilMembers } from '../../panel/CouncilMembers';
import { COUNCIL_MODE_CONVERSE } from '../SingleCouncilPanel';

export const CouncilPresenceTab: React.FC = () => {
  const { data: participationData } = useMyCouncilParticipationsQuery({
    fetchPolicy: 'network-only',
    skip: COUNCIL_MODE_CONVERSE,
  });

  const { t } = useTranslation();
  const confirmRemoval = useRemoveConfirm();
  const { removeDoctorFromCouncil } = useCouncilApi();

  return (
    <TabPanel value="others">
      <CouncilMembers
        emptyLabel={t('pages.council.panel.others.empty')}
        showInitials={false}
        members={
          participationData &&
          participationData.getMyCouncilParticipations.map((council) => {
            const owner = council.councilMembers.find(({ owner }) => owner);

            return {
              councilId: council.id,
              contact: owner?.councilMemberDoctor.user.contact as Contact,
            };
          })
        }
        actions={(councilId) => (
          <Tooltip
            title={t('pages.council.panel.others.actions.resign') as string}
          >
            <IconButton
              style={{ color: ActionColor.RED }}
              onClick={async () => {
                await confirmRemoval();
                removeDoctorFromCouncil({
                  variables: {
                    councilId,
                  },
                });
              }}
              size="large"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      ></CouncilMembers>
    </TabPanel>
  );
};
