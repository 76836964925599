import { useSnackbar } from 'notistack';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useOAuthConnectAccountMutation } from '../../../../../generated/graphql';

export const useOAuthConnect = () => {
  const snackBarError = useGraphQLError();
  const { enqueueSnackbar } = useSnackbar();

  const [connectOAuth] = useOAuthConnectAccountMutation({
    onError: snackBarError,
    onCompleted: () =>
      enqueueSnackbar('Konto zostało połączone', { variant: 'success' }),
  });

  return { connectOAuth };
};
