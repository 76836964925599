import { Fab, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import {
  Link as RouterLink,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useTableActions } from '../../../../../../common/components/table/hooks/useTableActions';
import {
  SortableColumn,
  Table,
} from '../../../../../../common/components/table/Table';
import { useMessages } from '../../../../../../common/hooks/useMessages';
import { ActionColor } from '../../../../../../common/types/actions';
import {
  EmailTemplate,
  useEmailTemplateQuery,
} from '../../../../../../generated/graphql';
import { ProtectedRoute } from '../../../auth/ProtectedRoute';
import { useEmailTemplateApi } from '../../hooks/useEmailTemplatesApi';
import { EmailTemplateForm } from './EmailTemplateForm';

type Props = {};

const COLUMNS: SortableColumn<EmailTemplate>[] = [
  {
    Header: 'Nazwa szablonu',
    accessor: 'name',
  },
  {
    Header: 'Kod szablonu wbudowanego',
    accessor: 'embeddedType',
  },
];

export const EmailTemplatePanel: React.FC<Props> = () => {
  const { data } = useEmailTemplateQuery();
  const { notFound } = useMessages();

  const history = useHistory();
  const { path } = useRouteMatch();

  const {
    onAddSubmit,
    onSaveSubmit,
    onDeleteEmailTemplate,
  } = useEmailTemplateApi();

  const tableActions = useTableActions<EmailTemplate, 'edit' | 'delete'>([
    {
      id: 'delete',
      Icon: DeleteIcon,
      title: 'Usuń szablon',
      actionColor: ActionColor.RED,
      onAction: async ({ id }) => {
        await onDeleteEmailTemplate(id);
        history.push(`${path}/`);
      },
    },
  ]);

  if (!data?.emailTemplates?.edges) return null;

  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/`}>
        <Box position="relative">
          <Table<EmailTemplate>
            columnConfig={[...COLUMNS, tableActions]}
            pagination={false}
            data={data?.emailTemplates.edges.map(({ node }) => node)}
            title="Szablony E-mail"
            manualSortBy={false}
            onRowClicked={(emailTemplate) =>
              history.push(`${path}/edit/${emailTemplate.id}`)
            }
          />
          <Box position="fixed" bottom={24} right={24}>
            <Fab
              color="primary"
              aria-label="add"
              component={RouterLink}
              to={`${path}/add`}
            >
              <AddIcon />
            </Fab>
          </Box>
        </Box>
      </ProtectedRoute>
      <ProtectedRoute
        path={`${path}/edit/:id`}
        render={(props) => {
          if (!props?.match?.params?.id) return null;

          const paramsId = parseInt(props.match.params.id);

          const foundEmailTemplate = data?.emailTemplates.edges.find(
            ({ node: { id } }) => id === paramsId,
          );

          if (!foundEmailTemplate?.node) {
            notFound(undefined, paramsId);
            return null;
          }

          return (
            <EmailTemplateForm
              emailTemplate={foundEmailTemplate?.node}
              onSubmit={(newEmailTemplate) =>
                onSaveSubmit(newEmailTemplate, paramsId)
              }
            />
          );
        }}
      />
      <ProtectedRoute path={`${path}/add`}>
        <EmailTemplateForm
          onSubmit={(newEmailTemplate) => onAddSubmit(newEmailTemplate)}
        />
      </ProtectedRoute>
    </Switch>
  );
};
