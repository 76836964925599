import React from 'react';

import { Loading } from '../../components/global/loading/Loading';
import { useAppRefresh } from '../../hooks/useAppRefresh';
import { ApolloNetworkStatusHook } from './types';

type Props = {
  useApolloNetworkStatus: ApolloNetworkStatusHook;
};

export const GlobalLoading: React.FC<Props> = ({ useApolloNetworkStatus }) => {
  useAppRefresh();

  const { numPendingQueries, numPendingMutations } = useApolloNetworkStatus({
    shouldHandleOperation: (operation) => {
      return operation.getContext().withGlobalLoading;
    },
  });

  if (numPendingMutations + numPendingQueries > 0) {
    return <Loading open />;
  } else {
    return null;
  }
};
