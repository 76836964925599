import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ActionColor } from '../../../../../common/types/actions';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(36),
      right: theme.spacing(12),
    },
    addButton: {
      width: '200px',
      marginRight: theme.spacing(10),
    },
    requestDescriptionIcon: {
      color: ActionColor.ORANGE,
    },
  }),
);
