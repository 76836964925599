import { Box, Link } from '@mui/material';
import Alert from '@mui/material/Alert';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { useResendConfirmationEmailMutation } from '../../../../../generated/graphql';

type Props = {
  onSend?: () => void;
};

export const EmailConfirmationResend: React.FC<Props> = ({ onSend }) => {
  const { t } = useTranslation();
  const { success } = useMessages();
  const [resendConfirmationEmail] = useResendConfirmationEmailMutation({
    onCompleted: () =>
      success(t('pages.signup.emailConfirmationSuccess.resendSuccess')),
  });
  const formContext = useFormContext();

  return (
    <Box mt={16}>
      <Alert severity="error">
        <Link
          component="button"
          onClick={async () => {
            resendConfirmationEmail({
              variables: {
                login: formContext.getValues('email'),
              },
            });
            if (onSend) onSend();
          }}
        >
          {t('pages.signup.emailConfirmationSuccess.resend')}
        </Link>
      </Alert>
    </Box>
  );
};
