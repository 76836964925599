export const CookieTermsEN = () => (
  <ol>
    <li>
      <p>
        The Site does not automatically collect any information, except for the
        information contained in cookies.
      </p>
    </li>
    <li>
      <p>
        Cookies are IT data, in particular text files which are stored in the
        Site User's end device and are intended for use of the Site's websites.
        Cookies usually contain the name of the website they come from, the
        length of time they are stored on a device and a unique number.
      </p>
    </li>
    <li>
      <p>
        The entity placing on the Site User's end device cookies and having
        access to them is the operators of the Site - Diagdent Polska Sp. z
        o.o., ul. Sądowa 14/1A, 50-046 Wrocław, KRS 0001064129, REGON 526697293,
        NIP 8971928708, CBCT Sp. z o.o., 03-253 Warszawa, ul. Białołęcka 166D,
        KRS: 935996, REGON: 520643089, NIP: 5242930030 oraz CBCT Polska Sp. z
        o.o., 02-439 Warszawa, ul. Zdobnicza 8, KRS: 936013, REGON: 520643333,
        NIP: 5223215013.
      </p>
    </li>
    <li>
      <p>Cookies are used for:</p>
    </li>
    <ol>
      <li>
        <p>
          Adapting the content of the Site's pages to the User's preferences and
          optimize the use of the websites; in particular, these files allow you
          to recognize the device of the User of the Site and appropriately
          display the website, tailored to its individual needs;
        </p>
      </li>
      <li>
        <p>
          creation of statistics that help to understand how Users of the Site
          use the websites, which makes it possible to improve their structure
          and content;
        </p>
      </li>
      <li>
        <p>
          maintaining a session of the User of the Site (after logging in),
          thanks to which the User does not have to re-enter its login and
          password on each sub-page of the Site;
        </p>
      </li>
    </ol>
    <li>
      <p>The following types of cookies are used within the Site:</p>
    </li>
    <ol>
      <li>
        <p>
          "necessary" cookies, enabling the use of services available within the
          Site, e.g. authentication cookies used for services requiring
          authentication within the Site;
        </p>
      </li>
      <li>
        <p>
          cookies used for security purposes, e.g. used to detect abuse of
          authentication on the Site;
        </p>
      </li>
      <li>
        <p>
          "performance" cookies, enabling the collection of information about
          how the Site's pages are used;
        </p>
      </li>
      <li>
        <p>
          "functional" cookies, enabling "remembering" User-selected settings
          and personalize the User interface User, e.g. with regard to the
          selected language or region from which the User comes from from which
          the User comes, the font size, the appearance of the website etc;
        </p>
      </li>
      <li>
        <p>
          "Advertising" cookies, enabling the delivery to Users of advertising
          content more tailored to their interests.
        </p>
      </li>
    </ol>
    <li>
      <p>
        In many cases, web browsing software Web pages (Internet browser) allows
        by default storage of cookies on the User's end device. Users of the
        Site may change their settings at any time regarding cookies. These
        settings can be changed in particular in such a way as to block the
        automatic handling of cookies in the User's web browser settings or
        inform about their each time placement in the device of the User of the
        Site. Detailed data about the possibility and methods of using cookies
        is available in the software settings (web browser).
      </p>
    </li>
    <li>
      <p>
        The Operator of the Site informs that restrictions on the use of cookies
        may affect some functionalities available on the websites of the Site.
      </p>
    </li>
    <li>
      <p>
        Cookies are placed on the Site User's end device and may also be used by
        those cooperating with Site Operator, i.e. its advertisers and partners.
      </p>
    </li>
  </ol>
);
