import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme, { contained?: boolean }>(
  (theme: Theme) => ({
    loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      position: ({ contained }) => (contained ? 'absolute' : 'fixed'),
    },
  }),
);
