import NotchedOutline from '@mui/material/OutlinedInput/NotchedOutline';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, { PropsWithChildren } from 'react';

import { LabelProps } from './types';
import { styled } from '@mui/styles';
import { InputLabel } from '@mui/material';

const useStyles = makeStyles<Theme, { disabled?: boolean }>({
  root: {
    position: 'relative',
  },
  content: ({ disabled }) => ({
    borderRadius: '4px',
    backgroundColor: disabled ? 'rgb(250,250,250)' : '#ffffff',
    padding: '6px 14px',
  }),
  inputLabel: {
    position: 'absolute',
  },
  outline: {
    borderColor: '#bbb',
  },
});

type Props = LabelProps & {
  id?: string;
  disabled?: boolean;
  fullWidth?: boolean;
};

const InnerContent = styled('div')({
  position: 'relative',
  zIndex: 1,
});

/*
 * Source: https://stackoverflow.com/questions/58402130/how-can-i-set-an-static-outlined-div-similar-to-material-uis-outlined-textfield/58421725#58421725
 */
export const Outline: React.FC<PropsWithChildren<Props>> = ({
  id,
  label,
  children,
  disabled,
  fullWidth,
}) => {
  const classes = useStyles({ disabled });
  const labelRef = React.useRef(null);

  return (
    <div
      style={{
        position: 'relative',
        marginTop: '2px',
        width: fullWidth ? '100%' : 'initial',
      }}
    >
      <InputLabel
        ref={labelRef}
        htmlFor={id}
        variant="outlined"
        className={classes.inputLabel}
        shrink
      >
        {label}
      </InputLabel>
      <div className={classes.root}>
        <div id={id} className={classes.content}>
          <NotchedOutline className={classes.outline} notched label={label} />
          <InnerContent>{children}</InnerContent>
        </div>
      </div>
    </div>
  );
};
