import { useSnackbar } from 'notistack';
import { useGraphQLError } from '../../../../../../common/hooks/useGraphQLError';
import {
  useGetCurrentUserQuery,
  useUpdateProfilePictureMutation,
  useDeleteExistingProfilePictureMutation,
  GetCurrentUserDocument,
  GetCurrentUserQuery,
} from '../../../../../../generated/graphql';

export const useProfilePicture = () => {
  const snackBarError = useGraphQLError();
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useGetCurrentUserQuery({
    onError: snackBarError,
  });

  const [updateProfilePicture] = useUpdateProfilePictureMutation({
    onError: snackBarError,
    onCompleted: () =>
      enqueueSnackbar('Zdjęcie profilowe zostało ustawione', {
        variant: 'success',
      }),
    update: (cache, result) => {
      const data = cache.readQuery<GetCurrentUserQuery>({
        query: GetCurrentUserDocument,
      });
      const currentUser = data?.currentUser;

      if (currentUser) {
        cache.writeQuery<GetCurrentUserQuery>({
          query: GetCurrentUserDocument,
          data: {
            currentUser: {
              ...currentUser,
              profilePicture: result.data?.updateProfilePicture,
            },
          },
        });
      }
    },
  });

  const [
    deleteExistingProfilePicture,
  ] = useDeleteExistingProfilePictureMutation({
    onError: snackBarError,
    onCompleted: () =>
      enqueueSnackbar('Zdjęcie profilowe zostało usunięte', {
        variant: 'success',
      }),
    update: (cache) => {
      const data = cache.readQuery<GetCurrentUserQuery>({
        query: GetCurrentUserDocument,
      });
      const currentUser = data?.currentUser;

      if (currentUser) {
        cache.writeQuery<GetCurrentUserQuery>({
          query: GetCurrentUserDocument,
          data: {
            currentUser: { ...currentUser, profilePicture: null },
          },
        });
      }
    },
  });

  return {
    profilePictureURI: data?.currentUser.profilePicture?.publicURL,
    updateProfilePicture,
    deleteExistingProfilePicture,
  };
};
