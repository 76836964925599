import { PartialDeep } from 'type-fest';
import {
  convertPlainToRawContentJSON,
  getRichTextAsPlainText,
} from '../../../../common/components/form/fields/RichTextField';
import { EMPTY_ID_INPUT } from '../../../../common/types/idInput';
import {
  AppConfig,
  Appointment,
  Maybe,
  MediaType,
  PaymentTypeEnum,
  Referal,
  StudyDataFragment,
  StudyInput,
  StudyStatus,
  StudyType,
  User,
} from '../../../../generated/graphql';
import { StudyFormType } from './types';
import { UserRole } from '../../../../generated/graphql';

export const getDefaultStudy = (
  appConfig?: AppConfig,
  performer?: PartialDeep<User>,
  defaultBranchId?: number,
  referal?: PartialDeep<Referal>,
  studyTypeId?: number,
  currentRole?: Maybe<UserRole>,
  appointment?: PartialDeep<Appointment>,
  studyTypes?: PartialDeep<StudyType>[],
): StudyFormType =>
  ({
    createdAt: new Date(),
    patient: appointment?.patient || referal?.patient || EMPTY_ID_INPUT(),
    performer: performer?.roles?.includes(UserRole.Technician)
      ? performer
      : EMPTY_ID_INPUT(),
    status:
      currentRole === UserRole.Registration
        ? StudyStatus.Paid
        : currentRole === UserRole.Technician
        ? StudyStatus.Performed
        : StudyStatus.Draft,
    studyDate: new Date(),
    studyType: getDefaultStudyType(studyTypeId, studyTypes),
    importantInformation: getRichTextAsPlainText(
      referal?.description || appointment?.comments || '',
    ),
    internalInformation: getRichTextAsPlainText(referal?.remarks || ''),
    descriptionRequestComment: referal?.requestDescription
      ? convertPlainToRawContentJSON('Proszę o wykonanie opisu')
      : '',
    describers: [],
    describingDoctors: [],
    supportingDoctors: [],
    notifyLeaderAndSupportingDoctors: true,
    branch: { id: defaultBranchId || 0 },
    leadingDoctor: referal?.issuer || undefined,
    leadingDoctorBranch: { id: 0 },
    payments: getDefaultPayments(appConfig, referal, studyTypeId, studyTypes),
    radiology: {
      voltageKilovolts: 0,
      anodeCurrentMiliamper: 0,
      exposureTimeSeconds: 0,
      radiationDoseMilligrayPerSquareCentimeter: 0,
      presetName: '',
    },
  } as any);

export const transformStudySchema = (
  {
    describingDoctors,
    createdById,
    updatedById,
    createdAt,
    updatedAt,
    updatedBy,
    createdBy,
    id,
    unassignedDescriber,
    missingDescription,
    hasDescription,
    ...study
  }: StudyFormType,
  referalId?: number,
  appointmentId?: number,
): StudyInput => {
  return {
    ...study,
    patient: { id: study.patient.id },
    describers: describingDoctors.map(({ id }) => ({ id })),
    leadingDoctor: study?.leadingDoctor ? { id: study.leadingDoctor.id } : null,
    leadingDoctorBranch: study?.leadingDoctorBranch?.id
      ? { id: study.leadingDoctorBranch.id }
      : null,
    supportingDoctors: study.supportingDoctors?.map((describer) => ({
      id: describer.id,
    })),
    studyType: { id: study.studyType.id },
    performer: study?.performer?.id ? { id: study.performer?.id } : null,
    branch: { id: study?.branch?.id || 0 },
    files: study.files?.map(({ id }) => ({ id })),
    referal: referalId ? { id: referalId } : undefined,
    appointment: appointmentId ? { id: appointmentId } : undefined,
    payments: study.payments?.map(
      ({ createdAt, createdById, fieldArrayId, ...payment }: any) => ({
        ...payment,
      }),
    ),
  };
};

export const convertStudyToFormStudy = (
  study: StudyDataFragment,
): StudyFormType => {
  if (isStudyFormType(study)) {
    return study;
  }

  return {
    ...study,
    describingDoctors: study?.describers?.map(({ doctor }) => doctor) || [],
    payments: study?.payments || [],
  };
};

const isStudyFormType = (
  study: StudyDataFragment | StudyFormType,
): study is StudyFormType => {
  return !!(study as StudyFormType).describingDoctors;
};

function getDefaultStudyType(
  studyTypeId?: number,
  studyTypes?: PartialDeep<StudyType>[],
): PartialDeep<StudyType> | undefined {
  if (studyTypes && studyTypeId) {
    return studyTypes.find((studyType) => studyType?.id === studyTypeId);
  }

  return studyTypeId ? { id: studyTypeId } : EMPTY_ID_INPUT();
}

function getDefaultPayments(
  appConfig?: AppConfig,
  referal?: PartialDeep<Referal>,
  studyTypeId?: number,
  studyTypes?: PartialDeep<StudyType>[],
) {
  const defaultPayments = [];

  const studyType = studyTypes?.find(
    (studyType) => studyType?.id === studyTypeId,
  );

  if (appConfig?.paymentTypes) {
    const studyTypePrices = studyType?.studyTypePrices;

    defaultPayments.push({
      type: PaymentTypeEnum.Study,
      // method: appConfig.paymentTypes[0],
      group: studyTypePrices?.[0]?.presetName,
      value: studyTypePrices?.[0]?.price || 0,
      createdAt: new Date(),
    });

    if (referal?.requestDescription) {
      defaultPayments.push({
        type: PaymentTypeEnum.Description,
        // method: appConfig.paymentTypes[0],
        value: studyType?.descriptionPrice || 0,
        createdAt: new Date(),
      });
    }

    if (appConfig?.carrierPrices) {
      defaultPayments.push({
        type: PaymentTypeEnum.DataCarrier,
        // method: appConfig.paymentTypes[0],
        value: 0,
        group: MediaType.Disc,
        createdAt: new Date(),
      });
    }
  }

  return defaultPayments;
}
