import React from 'react';
import {
  SelectField,
  SelectFieldProps,
} from '../../../../../common/components/form/fields/SelectField';
import {
  BranchFilter,
  InputMaybe,
  useBranchesQuery,
} from '../../../../../generated/graphql';

type Props = {
  ownOnly?: boolean;
  regions?: string[];
  allowEmpty?: boolean;
};

export const BranchSelect: React.FC<Partial<SelectFieldProps & Props>> = ({
  name,
  label,
  ownOnly,
  regions,
  allowEmpty = false,
  ...props
}) => {
  const filter: InputMaybe<BranchFilter> = {};
  if (ownOnly) filter.ownBranch = { is: true };
  if (regions && regions.length) filter.region = { in: regions };

  const { data: branches } = useBranchesQuery({
    variables: { filter },
  });

  const branchOptions =
    branches?.branches.nodes.map(({ id, name }) => ({
      value: id,
      label: name,
    })) || [];

  if (allowEmpty) branchOptions.unshift({ value: -1, label: 'Wszystkie' });

  return (
    <SelectField
      options={branchOptions}
      name={name || 'branch'}
      label={label || 'Filia'}
      {...props}
    />
  );
};
