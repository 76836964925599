import { useDoctorStampPicture } from './useDoctorStampPicture';
import { useMyStampPicture } from './useMyStampPicture';

export const useStampPicture = (doctorId?: number) => {
  const doctorStamp = useDoctorStampPicture(doctorId || -1);
  const myStamp = useMyStampPicture();

  if (doctorId) {
    return doctorStamp;
  } else {
    return myStamp;
  }
};
