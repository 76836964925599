import { Card, CardContent, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SwitchField } from '../../../../../../common/components/form/fields/SwitchField';
import { Form } from '../../../../../../common/components/form/Form';
import { InfoTooltip } from '../../../../../../common/components/info-tooltip/InfoTooltip';
import { useGraphQLError } from '../../../../../../common/hooks/useGraphQLError';
import {
  PreferencesInput,
  useGetCurrentUserQuery,
  useUpdatePreferencesMutation,
} from '../../../../../../generated/graphql';

export const UserPreferences: React.FC = () => {
  const onError = useGraphQLError();

  const { data } = useGetCurrentUserQuery({
    onError,
    variables: {
      withPreferences: true,
    },
  });

  const [updatePreferences] = useUpdatePreferencesMutation({
    onError,
  });

  const onSubmit = React.useCallback(
    (newPreferences: PreferencesInput) =>
      updatePreferences({ variables: { newPreferences } }),
    [updatePreferences],
  );

  const { t } = useTranslation();

  if (!data?.currentUser?.preferences) return null;

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{t('profile.preferences.title')}</Typography>
        <Form<PreferencesInput>
          noElevation
          formOptions={{ defaultValues: data?.currentUser?.preferences }}
          onSubmit={onSubmit}
        >
          <SwitchField
            submitOnChange={onSubmit}
            name="mailNotifications"
            label={
              <span>
                {t('profile.preferences.mailNotifications')}
                <InfoTooltip
                  text={t('profile.preferences.mailNotificationsDescription')}
                />
              </span>
            }
          />
        </Form>
      </CardContent>
    </Card>
  );
};
