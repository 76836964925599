import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionPanel } from '../../../../../common/components/action-panel/ActionPanel';
import { AddressField } from '../../../../../common/components/form/fields/AddressField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../common/components/form/Form';
import { ItemActionsConfig } from '../../../../../common/types/actions';
import { doctorValidationSchema } from '../../../../../common/validation/doctor/schema';
import {
  DescriberInfo,
  Doctor,
  DoctorInput,
  DoctorStatisticsOutput,
  useAppConfigQuery,
  User,
  UserRole,
} from '../../../../../generated/graphql';
import { UserForm } from '../../../components/auth/profile/user/UserForm';
import { BranchAutocomplete } from '../../common/fields/BranchAutocomplete';
import { DoctorActionType } from '../hooks/useDoctorActions';
import { DescribingDoctorFormPanel } from './DescribingDoctorForm';
import { StampPicture } from './StampPicture';
import { DateField } from '../../../../../common/components/form/fields/DateField';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';

type Props = React.PropsWithChildren & {
  onSubmit: (data: DoctorInput) => void;
  doctor?: DoctorInput & { describerInfo?: DescriberInfo } & { id?: number };
  disabled?: boolean;
  saveButtonLabel?: string;
  title?: string;
  actions?: ItemActionsConfig<Doctor, DoctorActionType>;
  stampSelection?: boolean;
  facility?: boolean;
  profile?: boolean;
};

const defaultValues: DoctorInput & { statistics: DoctorStatisticsOutput } = {
  user: {
    contact: {
      firstName: '',
      lastName: '',
      phoneNumbers: [],
      emailAddresses: [],
      companyName: '',
      nip: '',
      primaryDescription: '',
    },
    active: false,
    regions: [],
    vacationEnd: null,
    vacationStart: null,
    login: '',
  },
  licenseNumber: '',
  maxStudyHoursToDescribe: 0,
  describingStudyTypes: [],
  employedInBranches: [],
  specializations: [],
  statistics: {
    studiesValue: 0,
    loginCount7Days: 0,
    loginCount30Days: 0,
    loginCount90Days: 0,
    study2DCount7Days: 0,
    study2DCount30Days: 0,
    study2DCount90Days: 0,
    study3DCount7Days: 0,
    study3DCount30Days: 0,
    study3DCount90Days: 0,
    studiesCount: 0,
    lastLoginDate: null,
  },
};

//TODO: Extract ContactForm component and use here and on patient
export const DoctorForm: React.FC<Props> = ({
  onSubmit,
  doctor,
  disabled = false,
  saveButtonLabel,
  title,
  children,
  actions,
  stampSelection = false,
  facility = false,
  profile = false,
}) => {
  const { t } = useTranslation();
  const [showAddressField, setShowAddressField] = React.useState(false);

  React.useEffect(() => {
    if (doctor?.user.contact.addresses?.length) {
      setShowAddressField(true);
    }
  }, [doctor?.user.contact.addresses, showAddressField]);

  const { data: appConfigData } = useAppConfigQuery();
  const [{ currentRole }] = useAppContextState();

  return (
    <Form<DoctorInput>
      onSubmit={onSubmit}
      formOptions={{
        defaultValues: doctor || defaultValues,
        resolver: yupResolver(
          doctorValidationSchema(t, facility ? 'facility' : 'doctor'),
        ) as any,
      }}
      noElevation
      title={title}
      heading={
        actions &&
        doctor && (
          <ActionPanel
            config={actions}
            alignment="right"
            item={doctor as Doctor}
          />
        )
      }
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper>
            <Box p={8}>
              <UserForm
                prefix="user."
                user={doctor?.user as User}
                disabled={disabled}
                loginEditable={false}
                roles={[
                  UserRole.Admin,
                  UserRole.HelpContact,
                  UserRole.Registration,
                  UserRole.Technician,
                  UserRole.DescribingDoctor,
                  UserRole.Manager,
                ]}
                specializations={appConfigData?.appConfig.specializations}
              />
              {!facility && (
                <TextField
                  variant="outlined"
                  name="licenseNumber"
                  label="Numer PWZ"
                  disabled={disabled || profile}
                  fullWidth
                  required
                />
              )}
            </Box>
            <Box mt={6} p={8}>
              {showAddressField && (
                <AddressField
                  disabled={disabled}
                  label={t('app.common.fields.address')}
                  addressFieldName="user.contact.addresses[0]"
                  showCountry
                />
              )}
              <Box display={showAddressField ? 'none' : 'block'}>
                <Button
                  disabled={disabled}
                  color="primary"
                  onClick={() => {
                    if (doctor?.user?.contact) {
                      setShowAddressField(true);
                    }
                  }}
                >
                  {t('app.common.fields.addAddress')}
                </Button>
              </Box>
            </Box>
          </Paper>
          {!facility && (
            <Paper>
              <Box p={8} mb={6}>
                {stampSelection && (
                  <StampPicture doctorId={profile ? undefined : doctor?.id} />
                )}
              </Box>
            </Paper>
          )}
        </Grid>
        {currentRole &&
          [UserRole.Admin, UserRole.Manager].includes(currentRole) && (
            <Grid item xs={12} md={4}>
              <Paper>
                {doctor?.id && (
                  <Box padding={8}>
                    <Typography variant="h6">Statystyki</Typography>
                    <TextField
                      name="statistics.studiesValue"
                      label="Łączna wartość przypisanych badań"
                      disabled
                      fullWidth
                      currencyNoDecimal
                    />
                    <TextField
                      name="statistics.studiesCount"
                      label="Łączna liczba przypisanych badań"
                      disabled
                      fullWidth
                    />
                    <Box display="flex" gap={6}>
                      <TextField
                        name="statistics.loginCount7Days"
                        label="Logowań (7 dni)"
                        disabled
                        fullWidth
                      />
                      <TextField
                        name="statistics.study2DCount7Days"
                        label="Badań 2D (7 dni)"
                        disabled
                        fullWidth
                      />
                      <TextField
                        name="statistics.study3DCount7Days"
                        label="Badań 3D (7 dni)"
                        disabled
                        fullWidth
                      />
                    </Box>
                    <Box display="flex" gap={6}>
                      <TextField
                        name="statistics.loginCount30Days"
                        label="Logowań (30 dni)"
                        disabled
                        fullWidth
                      />
                      <TextField
                        name="statistics.study2DCount30Days"
                        label="Badań 2D (30 dni)"
                        disabled
                        fullWidth
                      />
                      <TextField
                        name="statistics.study3DCount30Days"
                        label="Badań 3D (30 dni)"
                        disabled
                        fullWidth
                      />
                    </Box>
                    <Box display="flex" gap={6}>
                      <TextField
                        name="statistics.loginCount90Days"
                        label="Logowań (90 dni)"
                        disabled
                        fullWidth
                      />
                      <TextField
                        name="statistics.study2DCount90Days"
                        label="Badań 2D (90 dni)"
                        disabled
                        fullWidth
                      />
                      <TextField
                        name="statistics.study3DCount90Days"
                        label="Badań 3D (90 dni)"
                        disabled
                        fullWidth
                      />
                    </Box>
                    <Box display="flex" gap={6} mt={2}>
                      <DateField
                        name="statistics.lastLoginDate"
                        label="Ostatnie logowanie"
                        disabled
                        fullWidth
                      />
                      <DateField
                        name="createdAt"
                        label="Data utworzenia konta"
                        disabled
                        fullWidth
                      />
                    </Box>
                  </Box>
                )}
                <Box padding={8}>
                  <BranchAutocomplete
                    controllerProps={{
                      name: 'employedInBranches',
                      label: 'Zatrudniony w placówkach',
                    }}
                    autocompleteProps={{
                      multiple: true,
                      disabled,
                    }}
                    multiple
                  />
                </Box>
              </Paper>
              <Box m={4}></Box>
            </Grid>
          )}
      </Grid>

      {doctor?.user?.roles?.includes(UserRole.DescribingDoctor) && !profile && (
        <Box mt={8}>
          <DescribingDoctorFormPanel
            disabled={disabled}
            describerInfo={doctor?.describerInfo}
          />
        </Box>
      )}

      <Box m={4} />

      {!disabled && (
        <Button variant="contained" type="submit" color="primary">
          {saveButtonLabel || 'Zapisz lekarza'}
        </Button>
      )}

      {children}
    </Form>
  );
};
