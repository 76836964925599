import { AutocompleteProps, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PartialDeep } from 'type-fest';
import { Form } from '../../../../../common/components/form/Form';
import { StudyType } from '../../../../../generated/graphql';
import { StudyTypeAutocomplete } from '../../common/fields/StudyTypeAutocomplete';

export const useSelectStudyType = (
  descriptionKey = 'dialogs.studyType.select.description',
  titleKey = 'dialogs.studyType.select.title',
): ((
  autocompleteProps?: PartialDeep<
    AutocompleteProps<StudyType, false, false, false>
  >,
) => Promise<StudyType | undefined>) => {
  const confirm = useConfirm();
  const { t } = useTranslation();
  const selectedStudyType = useRef<StudyType>();

  const onSubmit = (data?: { studyType: StudyType }) => {
    selectedStudyType.current = data?.studyType;
  };

  return async (autocompleteProps) => {
    await confirm({
      title: t(titleKey),
      description: (
        <>
          <Typography variant="body2">{t(descriptionKey)}</Typography>
          <Form<{ studyType: StudyType }> onSubmit={onSubmit} noElevation>
            <StudyTypeAutocomplete
              submitOnChange={onSubmit}
              autocompleteProps={autocompleteProps as any}
            />
          </Form>
        </>
      ),
      cancellationText: t('confirmationDialog.action.cancelButton'),
      confirmationText: t('dialogs.studyType.select.actions.select'),
    });
    return selectedStudyType.current;
  };
};
