import { Box, Theme, Typography, TypographyProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type Props = TypographyProps;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
  },
}));

export const InfoLabel: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      p={20}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="h6" className={classes.root} {...props}>
        {children}
      </Typography>
    </Box>
  );
};
