import { createUseStatePersisted } from 'use-persisted';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from './useQueryParams';
import useDidMountEffect from './useDidMountEffect';

const usePersistedState = createUseStatePersisted('lang', window.localStorage);

export type SUPPORTED_LANGUAGES = 'pl' | 'ua' | 'en';

export const useLanguage = () => {
  const queryParams = useQueryParams();

  const [currentLang, setCurrentLang] = usePersistedState<{
    lang: SUPPORTED_LANGUAGES;
  }>({
    lang: 'pl',
  });

  const { i18n } = useTranslation();

  useDidMountEffect(() => {
    if (!currentLang?.lang) {
      window.localStorage.removeItem('lang');
      window.location.reload();
    }

    i18n.changeLanguage(currentLang.lang);
  }, [currentLang, i18n]);

  useEffect(() => {
    if (queryParams.has('lang')) {
      setCurrentLang({ lang: queryParams.get('lang') as SUPPORTED_LANGUAGES });
    }
  }, [queryParams, setCurrentLang]);

  return {
    setCurrentLang: (lang: SUPPORTED_LANGUAGES) => setCurrentLang({ lang }),
    currentLang: currentLang.lang,
  };
};
