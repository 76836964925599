import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  useCreateCouncilMutation,
  useGetProfileQuery,
  useAcceptInviteToCouncilMutation,
  useRejectInviteToCouncilMutation,
  useRemoveDoctorFromCouncilMutation,
  useInviteDoctorToCouncilMutation,
} from '../../../../../generated/graphql';

const COUNCIL_QUERIES = [
  'MyCouncils',
  'MyCouncilInvites',
  'MyCouncilParticipations',
];

export const useCouncilApi = () => {
  const { success } = useMessages();
  const snackBarError = useGraphQLError();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [createCouncil] = useCreateCouncilMutation({
    onCompleted: () => success(),
    onError: snackBarError,
  });
  const [acceptInviteToCouncil] = useAcceptInviteToCouncilMutation({
    onCompleted: () => success(),
    onError: snackBarError,
    refetchQueries: COUNCIL_QUERIES,
  });
  const [rejectInviteToCouncil] = useRejectInviteToCouncilMutation({
    onCompleted: () =>
      enqueueSnackbar(t('pages.council.messages.rejectInviteSuccess'), {
        variant: 'success',
      }),
    onError: snackBarError,
    refetchQueries: COUNCIL_QUERIES,
  });
  const [removeDoctorFromCouncil] = useRemoveDoctorFromCouncilMutation({
    onCompleted: () => success(),
    onError: snackBarError,
    refetchQueries: COUNCIL_QUERIES,
  });
  const [inviteDoctorToCouncil] = useInviteDoctorToCouncilMutation({
    refetchQueries: COUNCIL_QUERIES,
    onError: snackBarError,
  });

  const { data } = useGetProfileQuery({
    onError: snackBarError,
  });

  return {
    createCouncil,
    acceptInviteToCouncil,
    rejectInviteToCouncil,
    removeDoctorFromCouncil,
    inviteDoctorToCouncil,
    doctorProfile: data?.currentDoctor,
  };
};
