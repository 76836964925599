import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { formatRelativeTime } from '../../../../../common/utils/date.utils';
import { DateTime } from 'luxon';
import { Box } from '@mui/material';
import { useModalInput } from '../../../../../common/hooks/useModalInput';

interface MessageProps {
  avatarUrl: string;
  message: string;
  timestampISO: string;
  isMine: boolean;
  backgroundColor?: string;
  onEdit?: (newComment: string) => void;
  onDelete?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: '10px',
      marginBottom: '5px',
      display: 'flex',
      justifyContent: 'center', // changed to center to use the conditional alignment below
      width: '100%',
      '&:hover $icons': {
        visibility: 'visible',
      },
    },
    messageContainer: {
      backgroundColor: '#e0e0e0',
      padding: '10px',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      whiteSpace: 'pre-wrap',
    },
    myMessageContainer: {
      backgroundColor: '#dcf8c6',
      marginRight: '10px',
      wordWrap: 'break-word',
      maxWidth: '60vw',
    },
    icons: {
      paddingLeft: '10px',
      visibility: 'hidden',
      [theme.breakpoints.down('md')]: {
        // Adjust visibility on small screens
        visibility: 'visible',
      },
    },
    // Added classes for left and right alignment
    leftAlign: {
      justifyContent: 'flex-start',
    },
    rightAlign: {
      justifyContent: 'flex-end',
      marginLeft: 'auto', // Pushes the box to the right
    },
  }),
);

const CommentMessage: React.FC<MessageProps> = ({
  avatarUrl,
  message,
  timestampISO,
  isMine,
  backgroundColor,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = React.useState(false);
  const formattedTimestamp = formatRelativeTime(timestampISO);

  const fireEditCommentPrompt = useModalInput(
    'dialogs.changeComment.content',
    'dialogs.changeComment.title',
  );

  const handleEdit = async (oldComment: string) => {
    const newComment = await fireEditCommentPrompt(oldComment);
    if (newComment && onEdit) {
      onEdit(newComment);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        className={isMine ? classes.rightAlign : classes.leftAlign}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection={isMine ? 'row-reverse' : 'row'} // Reverse order if mine
        >
          <Box display="flex" alignItems="center" gap={10}>
            <Avatar src={avatarUrl} />
            <Box display="flex" flexDirection="column">
              <Tooltip
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                title={DateTime.fromISO(timestampISO).toLocaleString(
                  DateTime.DATETIME_MED,
                )}
              >
                <Typography
                  variant="caption"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  {formattedTimestamp}
                </Typography>
              </Tooltip>
              <div
                className={`${classes.messageContainer} ${
                  isMine ? classes.myMessageContainer : ''
                }`}
                style={{ backgroundColor }}
              >
                <Typography>{message}</Typography>
                {isMine && (
                  <div className={classes.icons}>
                    <IconButton
                      onClick={() => handleEdit(message)}
                      size="small"
                    >
                      <EditIcon style={{ color: 'gray' }} fontSize="small" />
                    </IconButton>
                    <IconButton onClick={onDelete} size="small">
                      <DeleteIcon style={{ color: 'red' }} fontSize="small" />
                    </IconButton>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CommentMessage;
