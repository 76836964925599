import { Box } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DateField } from '../../../../../common/components/form/fields/DateField';
import { Form } from '../../../../../common/components/form/Form';

export const useChangeDescriptionDate = (): (() => Promise<
  Date | undefined
>) => {
  const confirm = useConfirm();
  const { t } = useTranslation();
  const formMethodsRef = useRef<any>();

  return async () => {
    await confirm({
      title: t('pages.study.actions.changeDescriptionDate.title'),
      description: (
        <Form<{ newDate: Date }>
          noElevation
          onSubmit={() => {}}
          render={(formMethods) => {
            formMethodsRef.current = formMethods;

            return (
              <Box gap={10} display="flex" flexDirection="column">
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    formMethods.setValue(
                      'newDate',
                      DateTime.now().plus({ days: 2 }).toJSDate(),
                    )
                  }
                >
                  {t('pages.study.actions.changeDescriptionDate.stat')}
                </Button> */}
                <DateField
                  name="newDate"
                  label={t('pages.study.actions.changeDescriptionDate.newDate')}
                  showDateFormatPlaceholder={false}
                  InputProps={{ startAdornment: null, endAdornment: null }}
                />
              </Box>
            );
          }}
        />
      ),
      cancellationText: t('confirmationDialog.action.cancelButton'),
      confirmationText: t(
        'pages.study.actions.changeDescriptionDate.submitButton',
      ),
    });
    return formMethodsRef.current.getValues('newDate');
  };
};
