import { useState, useEffect } from 'react';

export type CookieSettings = {
  necessary: boolean;
  security: boolean;
  performance: boolean;
  functional: boolean;
};

export const COOKIE_CONSENT_KEY = 'namimedCookiesConsent';
export const COOKIE_SETTINGS_KEY = 'namimedCookiesSettings';

// Default cookie settings
export const DEFAULT_COOKIE_SETTINGS: CookieSettings = {
  necessary: true, // Always enabled
  security: true,
  performance: true,
  functional: true,
};

export const useCookieSettings = () => {
  const [hasConsent, setHasConsent] = useState<boolean>(false);
  const [cookieSettings, setCookieSettings] = useState<CookieSettings>(
    DEFAULT_COOKIE_SETTINGS,
  );

  useEffect(() => {
    // Check if user has already given consent
    const savedConsent = localStorage.getItem(COOKIE_CONSENT_KEY) === 'true';
    setHasConsent(savedConsent);

    // Load saved cookie settings if they exist
    const savedSettings = localStorage.getItem(COOKIE_SETTINGS_KEY);
    if (savedSettings) {
      try {
        const parsedSettings = JSON.parse(savedSettings);
        setCookieSettings(parsedSettings);
      } catch (e) {
        // If parsing fails, use default settings
        setCookieSettings(DEFAULT_COOKIE_SETTINGS);
      }
    }
  }, []);

  const saveSettings = (settings: CookieSettings) => {
    localStorage.setItem(COOKIE_SETTINGS_KEY, JSON.stringify(settings));
    localStorage.setItem(COOKIE_CONSENT_KEY, 'true');
    setCookieSettings(settings);
    setHasConsent(true);
  };

  const acceptAll = () => {
    saveSettings(DEFAULT_COOKIE_SETTINGS);
  };

  const rejectAllExceptNecessary = () => {
    saveSettings({
      necessary: true,
      security: false,
      performance: false,
      functional: false,
    });
  };

  return {
    hasConsent,
    cookieSettings,
    saveSettings,
    acceptAll,
    rejectAllExceptNecessary,
  };
};
