import React from 'react';
import {
  SelectField,
  SelectFieldProps,
} from '../../../../../common/components/form/fields/SelectField';
import { Order, useStudiesQuery } from '../../../../../generated/graphql';

type Props = {
  patientId: number;
  studyTypeId: number;
};

export const StudySelect: React.FC<Partial<SelectFieldProps & Props>> = ({
  name,
  label,
  patientId,
  studyTypeId,
  ...props
}) => {
  const { data: studies } = useStudiesQuery({
    fetchPolicy: 'network-only',
    variables: {
      pagination: {
        skip: 0,
        take: 10,
      },
      where: {
        patient: {
          id: patientId,
        },
        studyTypeId,
      },
      orderBy: {
        columnName: 'createdAt',
        columnOrder: Order.Desc,
      },
    },
  });

  const studyOptions =
    studies?.studies.items.map(
      ({ leadingDoctor, id, studyType, studyDate }) => ({
        value: id,
        label: `Data: ${new Date(
          studyDate ?? 0,
        ).toLocaleDateString()}, Lekarz: ${
          leadingDoctor?.user.contact.firstName || ''
        } ${leadingDoctor?.user.contact.lastName || ''}, Typ: ${
          studyType.name
        } `,
      }),
    ) || [];

  return (
    <SelectField
      fullWidth
      options={studyOptions}
      name={name || 'study'}
      label={label || 'Badanie'}
      {...props}
      disabled={props.disabled || studyOptions.length === 0}
    />
  );
};
