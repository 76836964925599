import { IconButton, Tooltip } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useActionConfirm } from '../../../../../../common/hooks/useConfirmation';
import { ActionColor } from '../../../../../../common/types/actions';
import {
  Contact,
  useMyCouncilInvitesQuery,
} from '../../../../../../generated/graphql';
import { useCouncilApi } from '../../hooks/useCouncilApi';
import { CouncilMembers } from '../../panel/CouncilMembers';

export const InvitedDoctorsTab: React.FC = () => {
  const { data: inviteData } = useMyCouncilInvitesQuery({
    fetchPolicy: 'network-only',
  });

  const { t } = useTranslation();
  const confirm = useActionConfirm();
  const { acceptInviteToCouncil, rejectInviteToCouncil } = useCouncilApi();

  return (
    <TabPanel value="invites">
      <CouncilMembers
        emptyLabel={t('pages.council.panel.myInvites.empty')}
        showInitials={false}
        members={
          inviteData &&
          inviteData.getMyCouncilInvites?.map((council) => {
            const owner = council.councilMembers.find(({ owner }) => owner);

            return {
              councilId: council.id,
              contact: owner?.councilMemberDoctor.user.contact as Contact,
            };
          })
        }
        actions={(councilId) => (
          <>
            <Tooltip
              title={
                t('pages.council.panel.myInvites.actions.accept') as string
              }
            >
              <IconButton
                style={{ color: ActionColor.GREEN }}
                onClick={async () => {
                  acceptInviteToCouncil({
                    variables: {
                      councilId,
                    },
                  });
                }}
                size="large"
              >
                <CheckCircle />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                t('pages.council.panel.myInvites.actions.reject') as string
              }
            >
              <IconButton
                style={{ color: ActionColor.RED }}
                onClick={async () => {
                  await confirm({
                    description: t(
                      'pages.council.messages.confirm.rejectInvite',
                    ),
                  });
                  rejectInviteToCouncil({
                    variables: {
                      councilId,
                    },
                  });
                }}
                size="large"
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          </>
        )}
      ></CouncilMembers>
    </TabPanel>
  );
};
