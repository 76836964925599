import { Box, Dialog, DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';

import { FileUpload } from '../files/FileUpload';
import { ImageCropper } from './ImageCropper';
import { useStyles as useDropzoneStyles } from '../files/FileUpload';
import { ReactCropperProps } from 'react-cropper';

type Props = ReactCropperProps & {
  open: boolean;
  onClose: (imageData?: string) => void;
};

const useStyles = makeStyles({
  imageUploadPanel: {
    minHeight: '120px',
  },
});

export const ImageUploadDialog: React.FC<Props> = ({
  open,
  onClose,
  ...cropperProps
}) => {
  const [uploadedFileData, setUploadedFileData] = useState<string>();
  const classes = useStyles();
  const dropDownClasses = useDropzoneStyles({});

  return (
    <Dialog
      aria-labelledby="select-doctor-dialog-title"
      open={open}
      onClose={() => onClose()}
    >
      <DialogContent>
        <Box>
          <FileUpload
            classes={{
              ...dropDownClasses,
              root: classes.imageUploadPanel,
            }}
            showPreviews={false}
            showPreviewsInDropzone={false}
            maxFileSize={5000000}
            filesLimit={1}
            acceptedFiles={['image/*']}
            onDrop={([file]: File[]) => {
              const reader = new FileReader();
              reader.onload = () => {
                setUploadedFileData(reader.result as string);
              };
              reader.readAsDataURL(file);
            }}
          />
          <Box mt={10} />
          {uploadedFileData && (
            <ImageCropper
              sourceImage={uploadedFileData}
              onCrop={(imageData) => onClose(imageData)}
              {...cropperProps}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
