import { AddToQueue, AssignmentInd, Description } from '@mui/icons-material';
import { Alert, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DropzoneField } from '../../../../../../common/components/form/fields/DropzoneField';
import { useQueryParams } from '../../../../../../common/hooks/useQueryParams';
import { FileType } from '../../../../../../generated/graphql';
import { AttachedFiles } from '../../../../components/files/AttachedFiles';
import { StudyFormType } from '../../types';

type Props = {
  disabled: boolean;
  onDeleteFile: (fileId: number) => Promise<boolean>;
  study?: StudyFormType;
  isSupportingDoctor?: boolean;
};

export const FilesTab: React.FC<Props> = ({
  disabled,
  onDeleteFile,
  study,
  isSupportingDoctor,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<StudyFormType>();
  const queryParams = useQueryParams();

  const referalId = parseInt(queryParams.get('referalId') || '', 10);

  return (
    <Box mb={12}>
      <Grid container spacing={4} direction="row">
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={8}>
            <Typography variant="h6" align="center">
              {t('app.common.fields.attachedFiles.title.study')}
            </Typography>
            {!disabled && (
              <DropzoneField
                fileTypeLabel="plik z badaniem"
                name="files"
                required
                onDeleteFile={onDeleteFile}
                fileType={FileType.StudyImage}
                Icon={AddToQueue}
                iconColor="#7900bf"
              />
            )}
            {study &&
              onDeleteFile &&
              !!study?.files?.filter(
                ({ fileType }) => fileType === FileType.StudyImage,
              )?.length && (
                <AttachedFiles
                  title=""
                  files={study.files.filter(
                    ({ fileType }) => fileType === FileType.StudyImage,
                  )}
                  studyId={study.id}
                  onDeleteFile={onDeleteFile}
                  showActions={!disabled}
                />
              )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={8}>
            <Typography variant="h6" align="center">
              {t('app.common.fields.attachedFiles.title.description')}
            </Typography>
            {!disabled && (
              <DropzoneField
                name="files"
                fileTypeLabel="plik z opisem"
                onFilesUploaded={(uploadedFiles) => {
                  if (uploadedFiles?.length) {
                    setValue('notifyDoctorsAboutDescription', true);
                  }
                }}
                onDeleteFile={onDeleteFile}
                fileType={FileType.StudyDescription}
                Icon={Description}
                iconColor="#0277bd"
              />
            )}
            {study &&
              onDeleteFile &&
              !!study?.files?.filter(
                ({ fileType }) => fileType === FileType.StudyDescription,
              )?.length && (
                <AttachedFiles
                  title=""
                  files={study.files.filter(
                    ({ fileType }) => fileType === FileType.StudyDescription,
                  )}
                  studyId={study.id}
                  onDeleteFile={onDeleteFile}
                  showActions={!disabled}
                />
              )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={8}>
            <Typography variant="h6" align="center">
              {t('app.common.fields.attachedFiles.title.referal')}
            </Typography>
            {!disabled && (
              <DropzoneField
                name="files"
                required
                fileTypeLabel="skan/dokument skierowania"
                onDeleteFile={onDeleteFile}
                fileType={FileType.StudyReferal}
                Icon={AssignmentInd}
                iconColor="#2e7d32"
              />
            )}
            {study &&
              onDeleteFile &&
              !!study?.files?.filter(
                ({ fileType }) => fileType === FileType.StudyReferal,
              )?.length &&
              !isSupportingDoctor && (
                <AttachedFiles
                  title=""
                  files={study.files.filter(
                    ({ fileType }) => fileType === FileType.StudyReferal,
                  )}
                  studyId={study.id}
                  onDeleteFile={onDeleteFile}
                  showActions={!disabled}
                />
              )}
            {!study?.id && !!referalId && (
              <Alert severity="info">
                {t('app.common.fields.attachedFiles.referal.message')}
              </Alert>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
