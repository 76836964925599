import { Box, FormControl, FormHelperText } from '@mui/material';
import { DropzoneAreaProps } from 'react-mui-dropzone';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileType } from '../../../../generated/graphql';
import { FileArray, useFileUpload } from '../../../hooks/useFileUpload';
import { FileUpload, useDropzoneMessages } from '../../files/FileUpload';
import { Loading } from '../../global/loading/Loading';
import { useFieldRegister } from '../hooks/useFieldRegister';
import { NameProps, RequiredProps } from '../types';
import makeStyles from '@mui/styles/makeStyles';
import { useModalInput } from '../../../hooks/useModalInput';

class FileAlreadyExistsException {
  constructor(public readonly name: string) {}
}

type Props = Partial<DropzoneAreaProps> &
  RequiredProps &
  NameProps & {
    fileTypeLabel?: string;
    fileType: FileType;
    rejected?: boolean;
    onFilesUploaded?: (uploadedFiles: FileArray) => void;
    onDeleteFile: (fileId: number) => Promise<boolean>;
    iconColor?: string;
  };

export const useStyles = makeStyles(() => ({
  fieldset: {
    width: '100%',
  },
}));

export const DropzoneField: React.FC<Props> = ({
  fileTypeLabel,
  fileType,
  rejected = false,
  name,
  required,
  onDeleteFile,
  ...props
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  useFieldRegister(name);
  const { setValue, getValues, formState } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const dropzoneMessages = useDropzoneMessages(fileTypeLabel);
  const rejectionReasonPrompt = useModalInput(
    'dialogs.rejectedFileUpload.description',
    'dialogs.rejectedFileUpload.title',
  );

  const onUploadFiles = useFileUpload(({ total, loaded }) =>
    setProgress(Math.floor((loaded * 100) / total)),
  );

  const handleOnDrop = useCallback(
    async (files: File[]) => {
      setLoading(true);
      try {
        const existingFiles = getValues(name) as FileArray;

        const foundExistingFile = existingFiles?.find((existingFile) =>
          files.some(({ name }) => existingFile?.name === name),
        );
        if (foundExistingFile) {
          throw new FileAlreadyExistsException(foundExistingFile.name);
        }

        const rejectionReason = rejected
          ? await rejectionReasonPrompt()
          : undefined;

        const singleFileMode = props.filesLimit === 1;
        const fileHandles = await onUploadFiles(
          files,
          fileType,
          rejected,
          rejectionReason,
        );
        if (props.onFilesUploaded) props.onFilesUploaded(fileHandles);

        setValue(
          name,
          singleFileMode
            ? { ...fileHandles[0], name: files[0].name }
            : [...(existingFiles || []), ...fileHandles],
        );

        if (files.length) {
          enqueueSnackbar(
            dropzoneMessages.getFileUploadedMessage(
              files.map(({ name }) => name),
            ),
            { variant: 'success' },
          );
        }
      } catch (error) {
        if (error instanceof FileAlreadyExistsException) {
          enqueueSnackbar(`Plik '${error.name}' został już dołączony`, {
            variant: 'error',
          });
        }
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [
      dropzoneMessages,
      enqueueSnackbar,
      fileType,
      getValues,
      name,
      onUploadFiles,
      props,
      rejected,
      rejectionReasonPrompt,
      setValue,
    ],
  );

  const handleOnDelete = useCallback(
    async (file: File) => {
      setLoading(true);
      try {
        const savedFiles = getValues(name) as FileArray;

        const foundFile = savedFiles.find(
          (savedFile) => savedFile?.name === file.name,
        );

        if (foundFile) {
          await onDeleteFile(foundFile.id);

          setValue(
            name,
            savedFiles.filter((savedFile) => savedFile?.id !== foundFile.id),
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [getValues, name, onDeleteFile, setValue],
  );

  const classes = useStyles();

  let fieldError = formState.errors?.[name];
  if (fieldError?.type === 'required') {
    const files = getValues(name) as FileArray;
    if (files?.some((file) => file?.type === fileType) || !required) {
      fieldError = undefined;
    }
  }

  return (
    <Box mt={4} position="relative">
      <Loading
        contained
        open={loading}
        text={`${t(
          'app.common.fields.attachedFiles.loadingFiles',
        )}. ${progress} %`}
      />
      <FormControl
        component="fieldset"
        error={!!fieldError}
        className={classes.fieldset}
      >
        <FileUpload
          missingFiles={!!fieldError}
          onDrop={handleOnDrop}
          fileTypeLabel={fileTypeLabel}
          onDelete={handleOnDelete}
          {...props}
        />
        {fieldError && (
          <FormHelperText>{fieldError.message as string}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};
