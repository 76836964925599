import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, InputAdornment } from '@mui/material';

import React from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CancelButton } from '../../../../../../common/components/button/CancelButton';
import { RichTextField } from '../../../../../../common/components/form/fields/RichTextField';
import { SwitchField } from '../../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../../common/components/form/Form';

import {
  MailingInput,
  useAppConfigQuery,
  useMailingQuery,
} from '../../../../../../generated/graphql';

import { TagsField } from '../../../tags/TagsField';

type Props = {
  onSubmit: (data: MailingInput) => void;
  mailingId?: number;
};

const validationSchema = yup.object().shape({
  subject: yup.string().required(),
  content: yup.string().required(),
});

export const MailingForm: React.FC<Props> = ({ onSubmit, mailingId }) => {
  const { t } = useTranslation();

  const { data } = useMailingQuery({
    variables: { mailingId: mailingId || 0 },
    skip: !mailingId,
  });

  const { data: appConfigData } = useAppConfigQuery();
  const avaliableSpecializations = appConfigData?.appConfig.specializations
    ? appConfigData.appConfig.specializations
    : [];

  const { id, createdAt, createdById, status, ...mailingInput } =
    data?.mailing || {};

  return (
    <Form<MailingInput & { onlySelected: boolean }>
      onSubmit={async ({ onlySelected, ...mailing }) => onSubmit(mailing)}
      formOptions={{
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: Object.keys(mailingInput).length
          ? mailingInput
          : {
              subject: '',
              content: '',
              recipients: [],
              specializations: [],
              emailChannel: true,
              internalMessageChannel: true,
              showFooter: true,
              width: 600,
              important: false,
              selectedDoctors: [],
              onlySelected: false,
            },
        resolver: yupResolver(validationSchema) as any,
      }}
      render={({ getValues, setValue, watch }) => {
        const emailChannel = watch('emailChannel');
        const internalMessageChannel = watch('internalMessageChannel');

        return (
          <Box gap={8} display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              width="100%"
              gap={8}
            >
              <SwitchField
                name="onlySelected"
                label={t('settings.mailing.fields.onlySelected')}
                onChange={() => setValue('recipients', [])}
                defaultChecked={!!data?.mailing?.recipients?.length}
              />
              <TagsField
                disabled={!getValues('onlySelected')}
                name="recipients"
                label={t('settings.mailing.fields.selectedAddresses')}
                placeholder="Dodaj kolejny..."
                fullWidth
              />
              <TagsField
                disabled={!getValues('onlySelected')}
                options={avaliableSpecializations}
                name="specializations"
                label={t('settings.mailing.fields.selectedSpecializations')}
                placeholder="Dodaj specializacje..."
                fullWidth
              />
            </Box>
            <TextField
              name="subject"
              label={t('settings.mailing.fields.subject')}
              required
            />
            <RichTextField
              toolbar
              name="content"
              maxLength={30000}
              label={t('settings.mailing.fields.content')}
              defaultValue={data?.mailing?.content}
            />
            <Box display="flex" flexDirection="column">
              <Box>
                <SwitchField
                  name="internalMessageChannel"
                  onChange={() => setValue('important', false)}
                  label={t('settings.mailing.fields.internalMessagechannel')}
                  defaultChecked={true}
                />
                <SwitchField
                  name="important"
                  disabled={!internalMessageChannel}
                  label={t('settings.mailing.fields.important')}
                />
              </Box>

              <Box display="flex" alignItems="center">
                <SwitchField
                  name="emailChannel"
                  label={t('settings.mailing.fields.emailChannel')}
                />
                <SwitchField
                  name="showFooter"
                  label={t('settings.mailing.fields.showFooter')}
                  defaultChecked={true}
                  disabled={!emailChannel}
                />
                <TextField
                  name="width"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  valueAsNumber
                  disabled={!getValues('emailChannel')}
                  label={t('settings.mailing.fields.width')}
                />
              </Box>
            </Box>

            <Box mt={8}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
              >
                {t('app.common.actions.save')}
              </Button>
              <CancelButton />
            </Box>
          </Box>
        );
      }}
    ></Form>
  );
};
