import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  ContentState,
} from 'draft-js';
import MUIRichTextEditor, {
  TMUIRichTextEditorProps,
  TMUIRichTextEditorRef,
} from 'mui-rte';
import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Outline } from '../LabeledOutline';
import { HideIfEmptyProps, NameProps } from '../types';

type Props = NameProps &
  HideIfEmptyProps & {
    showToolbar?: boolean;
    placeholder?: string;
  } & TMUIRichTextEditorProps;

export const convertPlainToRawContentJSON = (plainText: string): string => {
  return JSON.stringify(
    convertToRaw(ContentState.createFromText(plainText ?? '')),
  );
};

export const getRichTextAsPlainText = (
  richTextCandidate?: string | null,
): string => {
  if (!richTextCandidate) return '';

  try {
    const parsedRichText = JSON.parse(richTextCandidate);
    return convertFromRaw(parsedRichText).getPlainText('\u0001');
  } catch {
    return richTextCandidate;
  }
};

export const useStyles = makeStyles<Theme, { readOnly?: boolean }>(() => ({
  rootDisabled: {
    backgroundColor: ({ readOnly }) => {
      return readOnly ? 'rgb(250,250,250)' : '#ffffff';
    },
  },
}));

const isFieldEmpty = (textContent: string | undefined | null): boolean => {
  if (!textContent) return true;

  return !convertFromRaw(JSON.parse(textContent)).hasText();
};

export const RichTextField: React.FC<Props> = (props) => {
  const { name, showToolbar = false } = props;
  const formContext = useFormContext();
  const ref = useRef<TMUIRichTextEditorRef>(null);
  const classes = useStyles({ readOnly: props.readOnly });

  if (props.hideIfEmpty && props.readOnly && isFieldEmpty(props.defaultValue))
    return null;

  return (
    <Controller
      name={name}
      control={formContext.control}
      render={({ field: { onChange, onBlur } }) => (
        <Outline label={props.label || ''} disabled={props.readOnly}>
          <MUIRichTextEditor
            ref={ref}
            id={name}
            toolbarButtonSize="small"
            toolbar={showToolbar}
            inlineToolbar
            maxLength={1000}
            {...props}
            classes={{ root: classes.rootDisabled }}
            label={props.placeholder || props.label || ''}
            onChange={(editorState) => {
              const rawValue = convertToRaw(editorState.getCurrentContent());
              onChange(
                rawValue?.blocks[0]?.text ? JSON.stringify(rawValue) : null,
              );
              EditorState.moveFocusToEnd(editorState);
            }}
            onBlur={() => {
              if (onBlur) onBlur();
              if (props.onBlur) {
                props.onBlur();
              }
            }}
          />
        </Outline>
      )}
    ></Controller>
  );
};
