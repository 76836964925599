export const fetchWithProgress = async (
  url: string,
  onProgress: (progress: number) => void,
) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const contentLength = response.headers.get('content-length');
  if (!contentLength) {
    throw new Error('Content-Length response header unavailable');
  }

  const total = parseInt(contentLength, 10);
  let loaded = 0;

  if (!response.body) return;

  const reader = response.body.getReader();
  const stream = new ReadableStream({
    start(controller) {
      function read() {
        reader
          .read()
          .then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }

            loaded += value.length;
            onProgress((loaded / total) * 100);
            controller.enqueue(value);
            read();
          })
          .catch((error) => {
            console.error('Error reading stream:', error);
            controller.error(error);
          });
      }
      read();
    },
  });

  return new Response(stream);
};
