import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import {
  useAppConfigQuery,
  useBranchesQuery,
} from '../../../../../../generated/graphql';
import { useFormContext } from 'react-hook-form';
import { StudyFormType } from '../../types';

type Props = {
  disabled?: boolean;
};

export const StudyRadiology: React.FC<Props> = ({ disabled = false }) => {
  const { data: appConfig } = useAppConfigQuery();
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<StudyFormType>();

  const { data: branches } = useBranchesQuery({
    context: {
      disableGlobalLoading: true,
    },
    variables: {
      filter: {
        id: { eq: watch('branch.id') },
      },
    },
  });

  const studyType = watch('studyType');
  const radiologyPreset = watch('radiology.presetName') as string;
  const branchId = watch('branch.id');

  useEffect(() => {
    if (studyType?.id && radiologyPreset && branchId) {
      const radiology = studyType.studyTypeRadiologies.find(
        ({ radiology }) => radiology.presetName === radiologyPreset,
      );

      setValue(
        'radiology.voltageKilovolts',
        radiology ? radiology.radiology.voltageKilovolts : 0,
      );
      setValue(
        'radiology.anodeCurrentMiliamper',
        radiology ? radiology.radiology.anodeCurrentMiliamper : 0,
      );
      setValue(
        'radiology.exposureTimeSeconds',
        radiology ? radiology.radiology.exposureTimeSeconds : 0,
      );
      setValue(
        'radiology.radiationDoseMilligrayPerSquareCentimeter',
        radiology
          ? radiology.radiology.radiationDoseMilligrayPerSquareCentimeter
          : 0,
      );
    }
  }, [studyType, radiologyPreset, setValue, branchId, branches]);

  const radiationGroups = appConfig?.appConfig?.radiationGroups;
  if (!radiationGroups) return null;

  return (
    <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={4}>
      <SelectField
        name={`radiology.presetName`}
        options={
          studyType?.studyTypeRadiologies
            ?.map(({ radiology: { presetName } }) => ({
              label: presetName || '',
              value: presetName || '',
            }))
            .filter(({ value }) =>
              branches?.branches?.nodes[0]?.devices.some(
                ({ radiationGroups }) => radiationGroups?.includes(value),
              ),
            ) || []
        }
        label={t('app.common.fields.radiology.preset')}
        disabled={disabled}
      />
      <TextField
        name={`radiology.voltageKilovolts`}
        label={t('app.common.fields.radiology.voltage')}
        valueAsNumber
        disabled={disabled}
      />
      <TextField
        name={`radiology.anodeCurrentMiliamper`}
        label={t('app.common.fields.radiology.current')}
        valueAsNumber
        disabled={disabled}
      />
      <TextField
        name={`radiology.exposureTimeSeconds`}
        label={t('app.common.fields.radiology.exposure')}
        valueAsNumber
        disabled={disabled}
      />
      <TextField
        name={`radiology.radiationDoseMilligrayPerSquareCentimeter`}
        label={t('app.common.fields.radiology.dose')}
        valueAsNumber
        disabled={disabled}
      />
    </Box>
  );
};
