import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Patient } from '../../../../../../generated/graphql';

type PreviousStudiesButtonProps = {
  patient: Patient;
};

export const PreviousStudiesButton: React.FC<PreviousStudiesButtonProps> = ({
  patient,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  if (!patient?.studies?.length || patient.studies.length <= 1) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => history.push(`/patients/view/${patient.id}`)}
    >
      {t('fields.patient.previousStudies', {
        count: patient?.studies?.length,
      })}
    </Button>
  );
};
