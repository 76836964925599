export const MUIRichTextEditor = {
  editor: {
    padding: '8px',
    minHeight: '100px',
  },
  editorReadOnly: {
    color: 'rgba(0, 0, 0, 0.68)',
    background: 'rgb(250,250,250)',
  },
  toolbar: {
    backgroundColor: '#ffffff',
  },
  placeHolder: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.38)',
    padding: 10,
  },
};
