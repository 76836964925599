import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import React from 'react';
import * as yup from 'yup';
import { CenteredPage } from '../../../../../common/components/centered-page/CenteredPage';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../common/components/form/Form';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import {
  PASSWORD_LENGTH,
  PASSWORD_REGEX,
} from '../../../../../common/validation/password';
import { useResetPasswordMutation } from '../../../../../generated/graphql';
import { TopBarLogo } from '../../top-bar/logo/TopBarLogo';

type ResetPasswordForm = { password: string; confirmPassword: string };

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required()
    .min(PASSWORD_LENGTH)
    .matches(
      PASSWORD_REGEX,
      'Hasło powinno składać się przynajmniej z 8 znaków, w tym małej i dużej litery oraz cyfry',
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Wartość jest różna od hasła'),
});

const ResetPasswordSuccess: React.FC = () => {
  return (
    <Paper>
      <Box mt={12} p={8}>
        <TopBarLogo alt="logo" src="/img/logo.svg" />
        <Box mt={4} mb={12}>
          <TopBarLogo width={250} alt="logo" src="/img/logoPortal.PNG" />
        </Box>
        <Alert severity="success">
          <AlertTitle>Hasło zostało zmienione.</AlertTitle>
          Klinij w poniższy przycisk aby przejść do strony logowania
        </Alert>
        <Box mt={4}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            href="/login"
          >
            Logowanie
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

const defaultValues: ResetPasswordForm = {
  password: '',
  confirmPassword: '',
};

export const ResetPasswordPage: React.FC = () => {
  const snackBarError = useGraphQLError();
  const [resetPassword, { data }] = useResetPasswordMutation({
    onError: snackBarError,
  });

  const queryParams = useQueryParams();

  const onSubmit = async ({ password }: ResetPasswordForm) => {
    await resetPassword({
      variables: {
        newPassword: password,
        token: queryParams.get('token') || '',
      },
    });
  };

  const resetPasswordSuccess = !!data?.resetPassword;

  return (
    <CenteredPage>
      {resetPasswordSuccess ? (
        <ResetPasswordSuccess />
      ) : (
        <Form<ResetPasswordForm>
          onSubmit={onSubmit}
          formOptions={{
            resolver: yupResolver(validationSchema) as any,
            defaultValues,
          }}
        >
          <TopBarLogo alt="logo" src="/img/logo.svg" />
          <Box mt={4} mb={8}>
            <TopBarLogo width={250} alt="logo" src="/img/logoPortal.PNG" />
          </Box>
          <TextField
            name="password"
            label="Podaj Nowe Hasło"
            password
            fullWidth
            htmlAutoComplete={false}
            required
          />
          <TextField
            variant="outlined"
            name="confirmPassword"
            label="Powtórz Hasło"
            password
            fullWidth
            required
            htmlAutoComplete={false}
          />
          <Box width={300} m={4} justifyContent="center">
            <Button variant="contained" type="submit" color="primary" fullWidth>
              Zapisz nowe hasło
            </Button>
          </Box>
        </Form>
      )}
    </CenteredPage>
  );
};
