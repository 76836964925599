import { Box, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../../../common/components/form/fields/TextField';
import { Outline } from '../../../../../../../common/components/form/LabeledOutline';
import { ActionColor } from '../../../../../../../common/types/actions';
import {
  StudyType,
  useAppConfigQuery,
} from '../../../../../../../generated/graphql';

export const StudyTypePrices: React.FC = () => {
  const { control } = useFormContext<StudyType>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray<
    StudyType,
    'studyTypePrices'
  >({
    name: 'studyTypePrices',
    control,
  });

  const { data: appConfig } = useAppConfigQuery();

  const priceGroups = appConfig?.appConfig?.priceGroups;

  return (
    <Outline label={t('settings.studyType.sections.prices')}>
      {priceGroups &&
        fields.map((studyTypePrice, index) => (
          <Box display="flex" gap={6} key={studyTypePrice.id}>
            <SelectField
              name={`studyTypePrices[${index}].presetName`}
              options={priceGroups.map((priceGroup) => ({
                label: priceGroup,
                value: priceGroup,
              }))}
              defaultValue={studyTypePrice.presetName}
              label={t('settings.studyType.fields.pricePreset')}
            />
            <TextField
              name={`studyTypePrices[${index}].price`}
              currency
              label={t('settings.studyType.fields.pricePrice')}
              defaultValue={studyTypePrice.price}
              valueAsNumber
              required
            />
            <IconButton
              aria-label="delete"
              style={{ color: ActionColor.RED }}
              onClick={() => remove(index)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      <Box mt={6}>
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={() => append({ price: 0, presetName: null })}
        >
          {t('settings.studyType.buttons.addNewPrice')}
        </Button>
      </Box>
    </Outline>
  );
};
