import { IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRemoveConfirm } from '../../../../../../common/hooks/useConfirmation';
import { ActionColor } from '../../../../../../common/types/actions';
import { useMyCouncilsQuery } from '../../../../../../generated/graphql';
import { useCouncilApi } from '../../hooks/useCouncilApi';
import { CouncilMembers } from '../../panel/CouncilMembers';

export const PendingInvitationsTab: React.FC = () => {
  const { data } = useMyCouncilsQuery({
    fetchPolicy: 'network-only',
  });

  const { t } = useTranslation();
  const confirmRemoval = useRemoveConfirm();
  const { removeDoctorFromCouncil } = useCouncilApi();

  return (
    <TabPanel value="pending">
      <CouncilMembers
        emptyLabel={t('pages.council.panel.pendingDoctors.empty')}
        showInitials={false}
        members={
          (data &&
            data.getMyCouncils[0]?.invitedMemberEmail?.map((inviteEmail) => ({
              contact: {
                firstName: inviteEmail,
                lastName: '',
                id: 0,
              },
              councilId: data.getMyCouncils[0].id,
            }))) ||
          []
        }
        actions={(_, contactToRemove) => (
          <Tooltip
            title={
              t('pages.council.panel.pendingDoctors.actions.remove') as string
            }
          >
            <IconButton
              style={{ color: ActionColor.RED }}
              onClick={async () => {
                await confirmRemoval();
                removeDoctorFromCouncil({
                  variables: {
                    doctorEmail: contactToRemove.firstName,
                  },
                });
              }}
              size="large"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      ></CouncilMembers>
    </TabPanel>
  );
};
