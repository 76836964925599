import { Alert, Box } from '@mui/material';
import React from 'react';

type PatientNoteAlertProps = {
  note: string;
};

export const PatientNoteAlert: React.FC<PatientNoteAlertProps> = ({ note }) => {
  return (
    <Box py={2}>
      <Alert
        severity="info"
        sx={{
          backgroundColor: 'rgba(156, 39, 176, 0.1)',
          '& .MuiAlert-icon': { color: 'rgb(156, 39, 176)' },
          '& .MuiAlert-message': { color: 'rgb(156, 39, 176)' },
        }}
      >
        {note}
      </Alert>
    </Box>
  );
};
