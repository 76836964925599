import * as yup from 'yup';

export const studyTypeValidation = yup.object().shape({
  code: yup.string().required(),
  name: yup.string().required(),
  studyTypePrices: yup
    .array()
    .nullable()
    .of(
      yup.object().nullable().shape({
        //presetName: yup.string().required(),
        price: yup.number().required(),
      }),
    ),
});
