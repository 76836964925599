import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete, {
  AutocompleteRenderGetTagProps,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import {
  StudyType,
  useGetStudyTypesForBranchQuery,
  useGetStudyTypesQuery,
} from '../../../../../generated/graphql';

import { get } from 'lodash-es';
import {
  NameProps,
  SubmitOnChangeProps,
} from '../../../../../common/components/form/types';
import { useCallback, useMemo } from 'react';

type Props<T, Multiple extends boolean | undefined> = SubmitOnChangeProps & {
  controllerProps?: Partial<AutocompleteRenderGetTagProps> & NameProps;
  autocompleteProps?: Partial<
    AutocompleteProps<T, Multiple, undefined, undefined>
  >;
  label?: string;
  multiple?: Multiple;
  required?: boolean;
  branchId?: number;
  deviceId?: number;
};

const useStyles = makeStyles({
  root: {
    minWidth: '300px',
  },
});

const useGetStudyTypes = (branchId?: number, deviceId?: number) => {
  const { data: allStudyTypes, loading: allLoading } = useGetStudyTypesQuery({
    context: {
      disableGlobalLoading: true,
    },
    skip: !!branchId,
  });

  const {
    data: studyTypesForBranch,
    loading: branchLoading,
  } = useGetStudyTypesForBranchQuery({
    context: {
      disableGlobalLoading: true,
    },
    variables: {
      branchId: branchId as number,
      deviceId: deviceId,
    },
    skip: !branchId,
  });

  const studyTypes = useMemo(
    () =>
      branchId
        ? studyTypesForBranch?.branchStudyTypes
        : allStudyTypes?.studyTypes?.edges?.map(({ node }) => node),
    [branchId, studyTypesForBranch, allStudyTypes],
  );

  // Memoize the sorted options
  const sortedStudyTypes = useMemo(() => {
    if (!studyTypes) return [];
    return [...studyTypes].sort((a, b) => a.name.localeCompare(b.name));
  }, [studyTypes]);

  return {
    loading: branchId ? branchLoading : allLoading,
    studyTypes,
    sortedStudyTypes,
  };
};

// Extracted outside component to avoid recreation
const getOptionLabel = (studytype: StudyType) => studytype?.name || '';
const getOptionSelected = (option?: StudyType, value?: StudyType) =>
  option?.id === value?.id;

export const StudyTypeAutocomplete = <Multiple extends boolean | undefined>(
  props: Props<StudyType, Multiple>,
) => {
  const { control, handleSubmit } = useFormContext();

  const { loading, studyTypes, sortedStudyTypes } = useGetStudyTypes(
    props.branchId,
    props.deviceId,
  );

  const classes = useStyles();

  const name = props.controllerProps?.name || 'studyType';

  // Memoize the onChange handler
  const handleChange = useCallback(
    (field: any, event: any, data: any, reason: any, details: any) => {
      field.onChange(data);
      if (props.autocompleteProps?.onChange) {
        props.autocompleteProps.onChange(event, data, reason, details);
      }
      if (props.submitOnChange) {
        handleSubmit(props.submitOnChange)();
      }
    },
    [props.autocompleteProps, props.submitOnChange, handleSubmit],
  );

  // Function to find the corresponding value from studyTypes while preserving reference equality
  const findValue = useCallback(
    (fieldValue: any) => {
      if (!fieldValue?.id || !studyTypes) return fieldValue || null;

      // First check if the current value is already in studyTypes (by reference)
      const isCurrentValueInStudyTypes = studyTypes.some(
        (studyType) => studyType === fieldValue,
      );

      if (isCurrentValueInStudyTypes) return fieldValue;

      // Find by ID only if necessary
      return (
        studyTypes.find(({ id }) => id === fieldValue.id) || fieldValue || null
      );
    },
    [studyTypes],
  );

  if (loading || !studyTypes) return <p>Ładowanie danych...</p>;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        // Calculate the correct value only when needed
        const autocompleteValue = props.multiple
          ? field?.value
          : findValue(field?.value);

        return (
          <Autocomplete
            {...props.autocompleteProps}
            {...field}
            className={classes.root}
            onChange={(event, data, reason, details) =>
              handleChange(field, event, data, reason, details)
            }
            value={autocompleteValue}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            options={sortedStudyTypes}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.label || 'Badanie'}
                variant="outlined"
                margin="dense"
                required={props.required}
                error={!!get(formState.errors, name)}
                helperText={get(formState.errors, name)?.message as string}
                fullWidth
              />
            )}
            multiple={props.multiple}
          />
        );
      }}
    ></Controller>
  );
};
