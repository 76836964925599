import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useContext } from 'react';
import { UseSortByColumnProps, UseTableColumnProps } from 'react-table';
import { SortableColumn } from '../Table';
import { useStyles } from '../Table.styles';
import { TableContext } from '../state/table.context';
import { TableRowItem } from '../../../types/table';

export interface HeaderProps<T extends TableRowItem> {
  classes: ReturnType<typeof useStyles>;
  columns: (UseTableColumnProps<T> &
    SortableColumn<T> &
    UseSortByColumnProps<T>)[];
  setPageIndex: (page: number) => void;
  selectable?: boolean;
}

export const Header = <T extends TableRowItem>(props: HeaderProps<T>) => {
  const { classes, columns, selectable = false, setPageIndex } = props;

  const { dispatch } = useContext(TableContext);

  return (
    <TableHead>
      <TableRow>
        {selectable && <TableCell padding="checkbox"></TableCell>}
        {columns.map((column, i) => {
          return (
            <TableCell
              key={column.id || i}
              className={classes.headerCell}
              style={{
                ...(column.maxWidth
                  ? { maxWidth: `${column.maxWidth}px` }
                  : {}),
                ...(column.minWidth
                  ? { minWidth: `${column.minWidth}px` }
                  : {}),
                ...(column.width ? { width: `${column.width}px` } : {}),
              }}
              sortDirection={column.isSortedDesc ? 'desc' : 'asc'}
            >
              {column.canSort ? (
                <TableSortLabel
                  className={classes.sortLabel}
                  active={column.isSorted}
                  direction={column.isSortedDesc ? 'desc' : 'asc'}
                  onClick={() => {
                    setPageIndex(0);
                    dispatch({
                      type: 'SORT_CHANGE',
                      payload: {
                        columnName: column.id,
                        columnOrder: column.isSortedDesc ? 'ASC' : 'DESC',
                      },
                    });
                    column.toggleSortBy(!column.isSortedDesc, false);
                  }}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    <span className={classes.visuallyHidden}>
                      {column.isSortedDesc
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                column.render('Header')
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
