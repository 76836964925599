import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { useAuth } from '../../../../../common/hooks/useAuth';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import {
  Contact,
  DoctorSignupInput,
  useOAuthLoginMutation,
  UserRole,
} from '../../../../../generated/graphql';
import { errorHandler } from '../../../App';
import { useAppContextState } from '../../settings/hooks/useSettingsState';

export type OAuthLoginType = 'facebook' | 'google';

export type OAuthState = Pick<Contact, 'firstName' | 'lastName'> & {
  token: string;
  login: string;
  type: OAuthLoginType;
};
export type SignupForm = DoctorSignupInput & {
  confirmPassword: string;
  acceptTerms: boolean;
  phoneNumber: string;
};

export const useOAuthLogin = <T>(
  oAuthResponse: T | undefined,
  oAuthStateFactory: (oAuthResponse: T) => OAuthState,
) => {
  const history = useHistory<{ oauth: OAuthState }>();
  const snackBarError = useGraphQLError();

  const { setAuthInfo } = useAuth();
  const [appContext, setAppContext] = useAppContextState();

  const [regulationsOpen, setRegulationsOpen] = useState(false);
  const [regulationsRequired, setRegulationsRequired] = useState(false);

  const proceedWithLogin = useCallback(async () => {
    //TODO: Go to location passed as props so the user is relocated to primary intent
    history.push(
      appContext.currentRole === UserRole.Doctor ||
        appContext.currentRole === UserRole.DentalOffice ||
        appContext.currentRole === UserRole.Laboratory
        ? '/patients'
        : appContext.currentRole === UserRole.Admin
        ? '/'
        : '/studies',
    );
  }, [history, appContext]);

  const [loginMutation] = useOAuthLoginMutation({
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.message === 'OAUTH_USER_NOT_FOUND') {
        if (!oAuthResponse) return;

        history.push({
          pathname: '/signup',
          state: {
            oauth: oAuthStateFactory(oAuthResponse),
          },
        });
      } else {
        snackBarError(error);
      }
    },
    onCompleted: (loginResult) => {
      const userData = loginResult?.oauthLogin;
      if (userData) {
        setAuthInfo({
          id: userData.user.id,
          roles: userData.user.roles,
          token: userData.token,
          login: userData.user.login,
        });
        setAppContext({
          ...appContext,
          currentRole:
            appContext.currentRole &&
            userData.user.roles.includes(appContext.currentRole)
              ? appContext.currentRole
              : userData.user.roles[0],
        });

        if (!userData.user.acceptedTerms) {
          setRegulationsRequired(true);
          setRegulationsOpen(true);
        } else {
          errorHandler.setUser(userData.user.login);
          proceedWithLogin();
        }
      }
    },
  });

  return {
    loginMutation,
    regulationsOpen,
    setRegulationsOpen,
    regulationsRequired,
    proceedWithLogin,
  };
};
