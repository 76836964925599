import React from 'react';
import {
  SelectField,
  SelectFieldProps,
} from '../../../../../common/components/form/fields/SelectField';
import { Order, useReferalsQuery } from '../../../../../generated/graphql';

type Props = {
  patientId: number;
  completed?: boolean;
  studyTypeId?: number;
  allowEmpty?: boolean;
};

export const ReferalSelect: React.FC<Partial<SelectFieldProps & Props>> = ({
  name,
  label,
  patientId,
  completed,
  studyTypeId,
  allowEmpty = false,
  ...props
}) => {
  const { data: referals } = useReferalsQuery({
    fetchPolicy: 'network-only',
    variables: {
      pagination: {
        skip: 0,
        take: 10,
      },
      where: {
        completed,
        patient: {
          id: patientId,
        },
        studyTypes: studyTypeId ? [studyTypeId] : undefined,
      },
      orderBy: {
        columnName: 'createdAt',
        columnOrder: Order.Desc,
      },
    },
  });

  const referalOptions =
    referals?.referals.items.map(
      ({ createdAt, issuer, id, referalToStudyTypes }) => ({
        value: id,
        label: `Data: ${new Date(
          createdAt ?? 0,
        ).toLocaleDateString()}, Lekarz: ${
          issuer?.user.contact.firstName || ''
        } ${
          issuer?.user.contact.lastName || ''
        }, Typ(y): ${referalToStudyTypes
          .map(({ studyType }) => studyType.name)
          .join(', ')} `,
      }),
    ) || [];

  if (allowEmpty) referalOptions.unshift({ value: -1, label: '' });

  return (
    <SelectField
      fullWidth
      options={referalOptions}
      name={name || 'referal'}
      label={label || 'Skierowanie'}
      {...props}
      disabled={props.disabled || referalOptions.length === 0}
      required={referalOptions.length > 0}
      autoFocus={referalOptions.length > 0}
    />
  );
};
