import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  useAddStudyTypeMutation,
  useUpdateStudyTypeMutation,
  useDeleteStudyTypeMutation,
  GetStudyTypesDocument,
  GetStudyTypesQuery,
  StudyTypeInput,
} from '../../../../../generated/graphql';
import { useHistory, useRouteMatch } from 'react-router';
import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';

export const useStudyTypeApi = () => {
  const { success, deleteSuccess } = useMessages();
  const history = useHistory();

  const [addStudyType] = useAddStudyTypeMutation({
    onCompleted: () => success(),
  });
  const [updateStudyType] = useUpdateStudyTypeMutation({
    onCompleted: () => success(),
  });
  const [deleteStudyType] = useDeleteStudyTypeMutation({
    onCompleted: () => deleteSuccess(),
  });

  const { path } = useRouteMatch();
  const confirmRemoval = useRemoveConfirm();

  const onAddSubmit = async (studyTypesInput: StudyTypeInput) => {
    await addStudyType({
      variables: { newStudyType: studyTypesInput },
      update: (cache, { data: newStudyType }) => {
        const data = cache.readQuery<GetStudyTypesQuery>({
          query: GetStudyTypesDocument,
        });
        const studyTypes = data?.studyTypes.edges;

        if (studyTypes && newStudyType) {
          cache.writeQuery<GetStudyTypesQuery>({
            query: GetStudyTypesDocument,
            data: {
              studyTypes: {
                edges: [
                  ...studyTypes,
                  {
                    node: newStudyType?.createOneStudyType,
                  },
                ],
              },
            },
          });
        }
      },
    });

    history.push(`${path}/`, { useCache: false });
  };

  const onSaveSubmit = (
    { id, ...studyType }: StudyTypeInput & { id: number },
    studyTypeId: number,
  ) => {
    updateStudyType({
      variables: { studyType, studyTypeId },
    });

    history.push(`${path}/`, { useCache: false });
  };

  const onDeleteStudyType = async (studyTypeId: number) => {
    await confirmRemoval();
    deleteStudyType({
      variables: { studyTypeId },
      update: (cache) => {
        const data = cache.readQuery<GetStudyTypesQuery>({
          query: GetStudyTypesDocument,
        });
        const studyTypes = data?.studyTypes.edges;

        if (studyTypes) {
          cache.writeQuery<GetStudyTypesQuery>({
            query: GetStudyTypesDocument,
            data: {
              studyTypes: {
                edges: studyTypes.filter(
                  ({ node: { id } }) => id !== studyTypeId,
                ),
              },
            },
          });
        }
      },
    });
  };

  return { onAddSubmit, onSaveSubmit, onDeleteStudyType };
};
