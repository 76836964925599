import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabContext } from '@mui/lab';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { Form } from '../../../../../common/components/form/Form';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import { StudyFormProps, StudyFormType } from '../types';
import { schema } from '../../../../../common/validation/study/schema';
import { FilesTab } from './tabs/FilesTab';
import { GeneralInfoTab } from './tabs/GeneralInfoTab';
import { PaymentsTabs } from './tabs/PaymentsTab';

import { UserRole } from '../../../../../generated/graphql';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { MetadataTab } from './tabs/MetadataTab';
import { RejectedFilesTab } from './tabs/RejectedFilesTab';

const useStyles = makeStyles({
  root: {
    maxWidth: '85vw',
  },
});

//TODO: Refactor to take advantage of `Form` common component
export const TabbedStudyForm: React.FC<PropsWithChildren<StudyFormProps>> = ({
  onSubmit,
  onDeleteFile,
  study,
  disabled = false,
  children,
  isSupportingDoctor = false,
}) => {
  const { t } = useTranslation();

  const queryParams = useQueryParams();
  const [selectedTab, setSelectedTab] = React.useState(
    queryParams.get('tab') || 'general',
  );

  const [{ currentRole }] = useAppContextState();

  const classes = useStyles();

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const showPaymentsAndMeta =
    currentRole &&
    [
      UserRole.Admin,
      UserRole.Manager,
      UserRole.Registration,
      UserRole.Technician,
    ].includes(currentRole);

  const TABS = [
    'general',
    'files',
    'rejectedFiles',
    ...(showPaymentsAndMeta ? ['payments', 'metadata'] : []),
  ];

  const tabs = [
    <GeneralInfoTab disabled={disabled} study={study} />,
    <FilesTab
      disabled={disabled}
      onDeleteFile={onDeleteFile}
      isSupportingDoctor={isSupportingDoctor}
      study={study}
    />,
    <RejectedFilesTab
      disabled={disabled}
      onDeleteFile={onDeleteFile}
      study={study}
    />,
    ...(showPaymentsAndMeta
      ? [<PaymentsTabs disabled={disabled} />, <MetadataTab />]
      : []),
  ].map((tab, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Box py={12} style={{ overflowX: 'hidden' }} key={i}>
      {tab}
    </Box>
  ));

  return (
    <Form<StudyFormType>
      onSubmit={onSubmit as any}
      watchFields={[
        'incognitoDescription',
        'leadingDoctor',
        'supportingDoctors',
        'describers',
        'describingDoctors',
        'studyType',
        'hidden',
      ]}
      showLeaveUnsavedPrompt={!disabled}
      formOptions={{
        defaultValues: study,
        resolver: yupResolver(schema) as any,
      }}
      render={({ getValues, watch }) => {
        const patientContact = watch('patient.contact');

        return (
          <>
            <Box className={classes.root}>
              <TabContext value={selectedTab}>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons
                  style={{ maxWidth: '100vw' }}
                  allowScrollButtonsMobile
                >
                  <Tab
                    value="general"
                    label={t('pages.study.edit.tabs.general')}
                  />
                  <Tab
                    value="files"
                    label={t('pages.study.edit.tabs.files')}
                    disabled={!getValues('studyType')?.id}
                  />
                  <Tab
                    value="rejectedFiles"
                    label={t('pages.study.edit.tabs.rejectedFiles')}
                    disabled={!getValues('studyType')?.id}
                  />
                  {showPaymentsAndMeta && (
                    <Tab
                      value="payments"
                      label={t('pages.study.edit.tabs.payments')}
                      disabled={!getValues('studyType')?.id}
                    />
                  )}
                  {showPaymentsAndMeta && (
                    <Tab
                      value="metadata"
                      label={t('pages.study.edit.tabs.meta')}
                      disabled={!getValues('studyType')?.id}
                    />
                  )}
                </Tabs>

                <Box marginTop={8} marginLeft={4} display="flex" gap={4}>
                  <Typography variant="h6">{`Pacjent: `}</Typography>
                  {patientContact && (
                    <Typography variant="h6">{`${patientContact.firstName} ${patientContact.lastName}`}</Typography>
                  )}
                </Box>

                <SwipeableViews
                  index={TABS.indexOf(selectedTab)}
                  onChangeIndex={(index) => {
                    if (index < TABS.length)
                      TABS.length && setSelectedTab(TABS[index]);
                  }}
                >
                  {tabs}
                </SwipeableViews>
              </TabContext>
            </Box>
            {children}
          </>
        );
      }}
    />
  );
};
