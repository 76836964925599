import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '../../../../../common/components/form/Form';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import {
  GetCurrentUserDocument,
  useGetCurrentUserQuery,
  User,
  useUpdateUserMutation,
} from '../../../../../generated/graphql';
import { UserForm } from './user/UserForm';

export const useUserProfile = () => {
  const snackBarError = useGraphQLError();

  const { data } = useGetCurrentUserQuery({
    onError: snackBarError,
    fetchPolicy: 'network-only',
  });
  const [updateUser, { data: updatedUser }] = useUpdateUserMutation({
    onError: snackBarError,
    refetchQueries: [GetCurrentUserDocument],
  });

  return {
    profile: updatedUser ? updatedUser.updateUser : data?.currentUser,
    updateProfile: updateUser,
  };
};

type Props = {
  id: number;
};

export const UserProfile: React.FC<Props> = () => {
  const { profile, updateProfile } = useUserProfile();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const onSubmitProfile = async ({ id, ...user }: User) => {
    await updateProfile({
      variables: { user, id },
      onCompleted: () =>
        enqueueSnackbar('Profil został zapisany', { variant: 'success' }),
    });
  };

  if (!profile) return null;

  const { profilePicture, emailConfirmed, ...userInput } = profile;

  return (
    <Box gap={10} display="flex" flexDirection="column">
      <Form<User>
        onSubmit={onSubmitProfile}
        formOptions={{ defaultValues: userInput }}
      >
        <Box mb={4}>
          <UserForm user={{ ...userInput }} loginEditable={false} />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Zapisz profil
        </Button>
        <Button
          type="button"
          color="secondary"
          onClick={() => history.goBack()}
        >
          Anuluj
        </Button>
      </Form>
    </Box>
  );
};
