import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ExitToApp from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { default as React } from 'react';
import { TopBarLogo } from './logo/TopBarLogo';
import { useStyles } from './TopBar.styles';
import { ContextPopover } from '../settings/components/popover/ContextPopover';
import { useAppContextState } from '../settings/hooks/useSettingsState';
import { Hidden, useMediaQuery, useTheme } from '@mui/material';
import { Protect } from '../auth/components/Protect';
import {
  useGetCurrentUserQuery,
  UserRole,
} from '../../../../generated/graphql';
import { useProfilePicture } from '../auth/profile/hooks/useProfilePicture';
import { CouncilBadge } from '../../modules/councils/badge/CouncilBadge';
import { MessageBadge } from '../../modules/messages/badge/MessageBadge';
import { getContactName } from '../../../../common/utils/contact.utils';

type Props = {
  handleOpenDrawer: () => void;
  handleLogout: () => void;
};

export const TopBar: React.FC<Props> = ({ handleOpenDrawer, handleLogout }) => {
  const classes = useStyles();
  const [settings] = useAppContextState();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { profilePictureURI } = useProfilePicture();

  const { data } = useGetCurrentUserQuery();

  return (
    <AppBar color="inherit" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="secondary"
          aria-label="open drawer"
          onClick={handleOpenDrawer}
          edge="start"
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Hidden only="xs">
          <Box mt={-4}>
            <TopBarLogo
              alt="logoPortal"
              width={isMobile ? 100 : 200}
              src="/img/logoPortal.PNG"
              href="/"
            />
          </Box>
        </Hidden>
        <div className={classes.logo}>
          <TopBarLogo alt="logo" src="/img/logo.svg" />
        </div>

        <MessageBadge />
        {(settings?.currentRole === UserRole.Doctor ||
          settings?.currentRole === UserRole.DentalOffice ||
          settings?.currentRole === UserRole.Laboratory) && <CouncilBadge />}

        {!isMobile && (
          <Box
            mx={4}
            gap={12}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            {[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.Manager,
              UserRole.Registration,
            ].includes(settings?.currentRole as UserRole) && (
              <Typography variant="subtitle2">
                Filia:{' '}
                <Typography color="secondary">
                  {settings.currentBranch?.name}
                </Typography>
              </Typography>
            )}
            <Typography variant="subtitle2">
              Zalogowany jako:
              <Typography color="secondary">
                {data?.currentUser.contact &&
                  getContactName(data.currentUser.contact)}
              </Typography>
            </Typography>
          </Box>
        )}

        {profilePictureURI && (
          <Avatar
            alt="profilePic"
            src={`${profilePictureURI}?rand=${Math.floor(
              Math.random() * 10000,
            )}`}
          />
        )}

        <Protect
          checkAllRoles
          roles={[
            UserRole.Admin,
            UserRole.Technician,
            UserRole.DescribingDoctor,
            UserRole.Manager,
            UserRole.Registration,
          ]}
        >
          <ContextPopover />
        </Protect>

        <Tooltip title="Wyloguj">
          <IconButton
            color="secondary"
            aria-label="Log out"
            onClick={handleLogout}
            className={classes.logoutButton}
            size="large"
          >
            <ExitToApp />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};
