import SvgIconComponent from '@mui/material/SvgIcon';
import { ButtonProps } from '@mui/material';
import { UserRole } from '../../generated/graphql';

export enum ActionColor {
  ORANGE = '#f9a825',
  YELLOW = '#fdd835',
  RED = '#dd2c00',
  BLUE = '#0277bd',
  GREEN = '#2e7d32',
}

export type BaseActionConfig = {
  title: string;
  Icon?: typeof SvgIconComponent;
  showProgress?: boolean;
  actionColor?: ActionColor | string;
  roles?: UserRole[];
  anyRoles?: UserRole[];
  forbiddenRoles?: UserRole[];
  context?: any;
};

export type StandardActionConfig = BaseActionConfig & {
  onAction: () => void | Promise<void>;
};

export type ItemActionsConfig<T extends object, AT> = (Partial<ButtonProps> &
  ItemActionConfig<T, AT>)[];

export type ItemActionConfig<T, AT> = BaseActionConfig & {
  id: AT;
  onAction: (item: T) => void | Promise<void>;
  visible?: (item: T) => boolean;
  visibleForMultiple?: boolean;
};

export type ItemActionHook<T, AT, A = undefined> = (
  arg?: A,
) => ItemActionConfig<T, AT>;
