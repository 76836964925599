import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import {
  Link as RouterLink,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { createUseReducerPersisted } from 'use-persisted';
import { CheckboxField } from '../../../../../../common/components/form/fields/CheckboxField';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { SearchPanel } from '../../../../../../common/components/search/SearchPanel';
import {
  SortableColumn,
  Table,
} from '../../../../../../common/components/table/Table';
import { useTableActions } from '../../../../../../common/components/table/hooks/useTableActions';
import { TableContext } from '../../../../../../common/components/table/state/table.context';
import { tableReducer } from '../../../../../../common/components/table/state/table.reducer';
import { HybridTableStorage } from '../../../../../../common/components/table/state/table.storage';
import useDidMountEffect from '../../../../../../common/hooks/useDidMountEffect';
import { ActionColor } from '../../../../../../common/types/actions';
import {
  Branch,
  BranchSortFields,
  SortDirection,
  useBranchesQuery,
} from '../../../../../../generated/graphql';
import { ProtectedRoute } from '../../../auth/ProtectedRoute';
import { useBranchApi } from '../../hooks/useBranchApi';
import { BranchForm } from './BranchForm';

type Props = {};

const COLUMNS: SortableColumn<Branch>[] = [
  {
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    Header: 'Addres',
    accessor: ({ address }) =>
      address
        ? `${address.street} ${address.postalCode}, ${address.city},  ${address.country}`
        : '',
  },
  {
    Header: 'Addres(y) e-mail',
    disableSortBy: true,
    accessor: ({ emailAddresses }) =>
      emailAddresses ? emailAddresses.join(', ') : '',
  },
  {
    Header: 'Numer(y) telefonu',
    disableSortBy: true,
    accessor: ({ phoneNumbers }) =>
      phoneNumbers ? phoneNumbers.join(', ') : '',
  },
];

const initialState = {
  orderBy: {
    columnName: 'updatedAt',
    columnOrder: 'DESC',
  },
  pagination: {
    take: 10,
    skip: 0,
  },
  filter: {
    name: '',
    ownBranch: false,
  },
};
const usePersistedReducer = createUseReducerPersisted(
  'branchList',
  new HybridTableStorage(),
);

export const BranchPanel: React.FC<Props> = () => {
  const [state, dispatch] = usePersistedReducer(
    tableReducer,
    HybridTableStorage.getInitialState('branchList', initialState),
  );

  const { data, fetchMore } = useBranchesQuery({
    variables: {
      sorting: [{ direction: SortDirection.Asc, field: BranchSortFields.Name }],
      paging: { limit: state.pagination.take, offset: state.pagination.skip },
      filter: {
        name: { iLike: state.filter.name ? `%${state.filter.name}%` : '%' },
        ownBranch: { is: state.filter.ownBranch },
      },
    },
  });

  useDidMountEffect(() => {
    fetchMore({
      variables: {
        sorting: [
          { direction: SortDirection.Asc, field: BranchSortFields.Name },
        ],
        paging: {
          offset: state.pagination.skip,
          limit: state.pagination.take,
        },
        filter: {
          name: { iLike: state.filter.name ? `%${state.filter.name}%` : '%' },
          ownBranch: { is: state.filter.ownBranch },
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  }, [fetchMore, state.filter, state.pagination, state.orderBy]);

  const history = useHistory();
  const { path } = useRouteMatch();

  const { onSaveSubmit, onDeleteBranch } = useBranchApi();

  const tableActions = useTableActions<Branch, 'edit' | 'delete'>([
    {
      id: 'delete',
      Icon: DeleteIcon,
      title: 'Usuń filię',
      actionColor: ActionColor.RED,
      onAction: async ({ id }) => {
        await onDeleteBranch(id);
        history.push(`${path}/`);
      },
    },
  ]);

  if (!data?.branches) return null;

  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/`}>
        <Box position="relative">
          <TableContext.Provider value={{ state, dispatch }}>
            <SearchPanel
              defaultValues={state.filter}
              onSearch={(filter) =>
                dispatch({ type: 'FILTER_CHANGE', payload: filter })
              }
              resetValues={{ name: '', ownBranch: false }}
              mainFields={
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', md: 'row' }}
                  gap={6}
                >
                  <TextField
                    name="name"
                    label="Nazwa filii/placówki"
                    fullWidth
                  />
                  <CheckboxField name="ownBranch" label="Nasza placówka" />
                </Box>
              }
            />
            <Table<Branch>
              columnConfig={[...COLUMNS, tableActions]}
              data={data?.branches.nodes as Branch[]}
              title="Filie / Placówki"
              manualSortBy={false}
              totalCount={data?.branches.totalCount || 0}
              onRowClicked={(branch) =>
                history.push(`${path}/edit/${branch.id}`)
              }
            />
          </TableContext.Provider>
          <Box position="fixed" bottom={24} right={24}>
            <Fab
              color="primary"
              aria-label="add"
              component={RouterLink}
              to={`${path}/add`}
            >
              <AddIcon />
            </Fab>
          </Box>
        </Box>
      </ProtectedRoute>
      <ProtectedRoute
        path={`${path}/edit/:id`}
        render={(props) => {
          if (!props?.match?.params?.id) return null;

          const paramsId = parseInt(props.match.params.id);

          return (
            <BranchForm
              branchId={paramsId}
              onSubmit={(newBranch) => onSaveSubmit(newBranch, +paramsId)}
            />
          );
        }}
      />
      <ProtectedRoute path={`${path}/add`}>
        <BranchForm onSubmit={(newBranch) => onSaveSubmit(newBranch)} />
      </ProtectedRoute>
    </Switch>
  );
};
