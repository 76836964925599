import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',

      justifyContent: 'center',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },

      alignItems: 'center',
      flexDirection: 'column',

      backgroundColor: theme.palette.background.default,
      backgroundImage: `url('/img/background.svg')`,
      backgroundSize: 'cover',
      [theme.breakpoints.down('md')]: {
        backgroundPosition: '100% 100%',
        backgroundRepeat: 'repeat-x',
        backgroundSize: 'auto 100%',
      },
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
    },
  }),
);
