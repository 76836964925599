import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { CancelButton } from '../../../../../common/components/button/CancelButton';
import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';
import { ActionColor } from '../../../../../common/types/actions';
import { EMPTY_ID_INPUT } from '../../../../../common/types/idInput';
import {
  AppointmentBreakInput,
  AppointmentAddInput,
  AppointmentUpdateInput,
  Branch,
  Device,
  useGetAppointmentQuery,
} from '../../../../../generated/graphql';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { AppointmentForm } from '../form/AppointmentForm';
import { useAppointments } from '../hooks/useAppointments';

import { Link as RouterLink } from 'react-router-dom';

export type AppointmentLocationState = {
  defaultDate: Date;
  branch: Omit<Branch, 'employedDoctors'>;
  device: Device;
};

function isAppointmentBreakInput(
  input: AppointmentAddInput | AppointmentUpdateInput | AppointmentBreakInput,
): input is AppointmentBreakInput {
  return (
    (input as AppointmentAddInput | AppointmentUpdateInput)?.patient?.id ===
    undefined
  );
}

function isAppointmentUpdateInput(
  input: AppointmentAddInput | AppointmentUpdateInput | AppointmentBreakInput,
): input is AppointmentUpdateInput {
  return (input as AppointmentUpdateInput)?.id !== undefined;
}

export const AppointmentEdit: React.FC = () => {
  const [settings] = useAppContextState();
  const { t } = useTranslation();
  const history = useHistory();

  const confirmRemoval = useRemoveConfirm();

  const { id: appointmentId } = useParams<{ id: string }>();
  const location = useLocation<AppointmentLocationState>();

  const { data } = useGetAppointmentQuery({
    variables: {
      id: parseInt(appointmentId, 10),
    },
    skip: appointmentId === 'add' || !appointmentId,
  });

  const {
    addAppointment,
    updateAppointment,
    deleteAppointment,
    addBreak,
  } = useAppointments(() => setTimeout(() => history.goBack()));

  const onSubmit = async (
    data: AppointmentAddInput | AppointmentUpdateInput | AppointmentBreakInput,
  ) => {
    const inputCommon = {
      branch: {
        id: data.branch?.id,
      },
      device: {
        id: data.device?.id,
      },
      startDate: data.startDate,
      comments: data.comments,
    };

    if (isAppointmentBreakInput(data)) {
      addBreak({
        variables: {
          data: {
            ...inputCommon,
            endDate: data.endDate,
          },
        },
      });
    } else {
      if (isAppointmentUpdateInput(data)) {
        const updateInput: AppointmentUpdateInput = {
          ...inputCommon,
          patient: {
            id: data.patient.id,
          },
          studyType: {
            id: data.studyType.id,
          },
          id: data.id,
        };

        if (data.referal?.id) {
          updateInput.referal = {
            id: data.referal.id,
          };
        }

        if (data.study?.id) {
          updateInput.study = {
            id: data.study.id,
          };
        }

        updateAppointment({
          variables: { data: updateInput, id: data.id },
        });
      } else {
        const addInput: AppointmentAddInput = {
          ...inputCommon,
          patient: {
            id: data.patient.id,
          },
          studyTypes: data.studyTypes.map(({ id }) => ({ id })),
        };

        if (data.referal?.id) {
          addInput.referal = {
            id: data.referal.id,
          };
        }

        addAppointment({
          variables: {
            data: addInput,
          },
        });
      }
    }
  };

  return (
    <Box maxWidth={1000}>
      <AppointmentForm
        appointment={
          data?.appointment
            ? data.appointment
            : {
                branch: location.state?.branch ?? {
                  id: settings?.currentBranch?.id,
                },
                device: location.state?.device || 0,
                startDate: location.state?.defaultDate ?? new Date(),
                isBreak: false,
                patient: EMPTY_ID_INPUT(),
                studyType: EMPTY_ID_INPUT(),
                referal: EMPTY_ID_INPUT(),
                comments: '',
              }
        }
        onSubmit={(appointment) =>
          onSubmit(
            appointment as
              | AppointmentAddInput
              | AppointmentUpdateInput
              | AppointmentBreakInput,
          )
        }
      >
        <Box display="flex" flexDirection="column" gap={4}>
          {data?.appointment.id && !data?.appointment.study?.id && (
            <>
              <Button
                style={{
                  color: ActionColor.RED,
                  borderColor: ActionColor.RED,
                }}
                variant="outlined"
                type="button"
                onClick={async () => {
                  await confirmRemoval();
                  deleteAppointment({
                    variables: { id: data.appointment.id },
                  });
                }}
                fullWidth
              >
                {t('pages.appointment.actions.delete')}
              </Button>
              {!data?.appointment.isBreak && (
                <Button
                  style={{
                    color: ActionColor.BLUE,
                    borderColor: ActionColor.BLUE,
                  }}
                  variant="outlined"
                  type="button"
                  fullWidth
                  component={RouterLink}
                  to={`/studies/add?appointmentId=${data?.appointment?.id}&studyTypeId=${data.appointment.studyType?.id}&referalId=${data.appointment.referal?.id}`}
                >
                  {t('pages.appointment.actions.create-study')}
                </Button>
              )}
            </>
          )}
          {(!data?.appointment.id || !data?.appointment.isBreak) &&
            !data?.appointment.study?.id && (
              <Button
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
              >
                {t('pages.appointment.actions.save')}
              </Button>
            )}
          <CancelButton />
        </Box>
      </AppointmentForm>
    </Box>
  );
};
