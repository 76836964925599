import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      borderBottomRightRadius: theme.spacing(3),
      borderBottomLeftRadius: theme.spacing(3),
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    logoutButton: {
      marginLeft: theme.spacing(1),
    },
    logo: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
  }),
);
