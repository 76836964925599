import AssignmentIcon from '@mui/icons-material/Assignment';
import EventIcon from '@mui/icons-material/Event';
import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import { isEmpty } from 'lodash-es';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { ContactCard } from '../../../../../common/components/contact-card/ContactCard';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  PatientInput,
  PatientsDocument,
  useGetPatientQuery,
  UserRole,
  useUpdatePatientMutation,
} from '../../../../../generated/graphql';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { PatientForm } from '../form/PatientForm';
import { PatientAppointments } from './PatientAppointments';
import { PatientStudies } from './PatientStudies';

type Props = {
  disabled?: boolean;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`patient-tabpanel-${index}`}
      aria-labelledby={`patient-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

export const PatientEdit: React.FC<Props> = ({ disabled = false }) => {
  const { id: patientId } = useParams<{ id: string }>();
  const { success } = useMessages();
  const snackBarError = useGraphQLError();
  const history = useHistory();

  const [updatePatient] = useUpdatePatientMutation({
    onCompleted: () => {
      success();
      history.push(`/patients/view/${data?.patient.id}`);
    },
    onError: snackBarError,
    refetchQueries: [PatientsDocument],
  });

  const { data } = useGetPatientQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parseInt(patientId, 10),
      withAppointments: true,
    },
  });

  const [appContext] = useAppContextState();

  const { getAuthInfo } = useAuth();

  const appointmentsTabRoles = [
    UserRole.Admin,
    UserRole.Technician,
    UserRole.Registration,
    UserRole.Manager,
  ];

  const studiesTabRoles = [
    UserRole.Admin,
    UserRole.Technician,
    UserRole.Doctor,
    UserRole.DentalOffice,
    UserRole.DescribingDoctor,
    UserRole.Registration,
    UserRole.Manager,
  ];

  const showAppointmentsTab = appointmentsTabRoles.includes(
    appContext.currentRole as UserRole,
  );

  const showStudiesTab = studiesTabRoles.includes(
    appContext.currentRole as UserRole,
  );

  // Start with studies tab (1) for roles that can't see appointments
  // or with appointments tab (0) for roles that can see both
  const [tabValue, setTabValue] = React.useState(() => {
    if (!showAppointmentsTab && showStudiesTab) return 0;
    return 0;
  });

  const onSubmit = (patient: PatientInput) => {
    updatePatient({
      variables: { id: parseInt(patientId, 10), patient },
    });
  };

  if (!data?.patient || !appContext.currentRole) return null;

  const canEditPatient =
    (getAuthInfo().id === data?.patient?.createdById &&
      isEmpty(data?.patient?.studies)) ||
    [UserRole.Admin, UserRole.Technician].includes(appContext.currentRole);

  const {
    createdById,
    updatedById,
    studies,
    id,
    appointments,
    ...patient
  } = data.patient;
  const appointmentsCount = data.patient.appointments?.length || 0;
  const studiesCount = data.patient.studies?.length || 0;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box mb={8}>
        {disabled ? (
          <Grid container spacing={4} direction="column">
            <Grid item xs={12} md={3}>
              <ContactCard
                showBirthDate
                contact={data.patient.contact}
                actions={
                  canEditPatient ? (
                    <Button
                      variant="text"
                      onClick={() =>
                        history.push(`/patients/edit/${data?.patient.id}`)
                      }
                      color="primary"
                      fullWidth
                    >
                      Edytuj pacjenta
                    </Button>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="patient information tabs"
                >
                  {showAppointmentsTab && (
                    <Tab
                      icon={<EventIcon />}
                      iconPosition="start"
                      label={`Wizyty (${appointmentsCount})`}
                      id="patient-tab-0"
                      aria-controls="patient-tabpanel-0"
                    />
                  )}
                  {showStudiesTab && (
                    <Tab
                      icon={<AssignmentIcon />}
                      iconPosition="start"
                      label={`Badania (${studiesCount})`}
                      id="patient-tab-1"
                      aria-controls="patient-tabpanel-1"
                    />
                  )}
                </Tabs>
              </Box>
              {showAppointmentsTab && (
                <TabPanel value={tabValue} index={0}>
                  <PatientAppointments
                    appointments={data.patient.appointments || []}
                  />
                </TabPanel>
              )}
              {showStudiesTab && (
                <TabPanel value={tabValue} index={showAppointmentsTab ? 1 : 0}>
                  <PatientStudies studies={data?.patient?.studies || []} />
                </TabPanel>
              )}
            </Grid>
          </Grid>
        ) : (
          <PatientForm
            onSubmit={onSubmit}
            patient={patient}
            disabled={disabled}
          >
            <Button
              type="button"
              color="secondary"
              onClick={() => history.goBack()}
              fullWidth
            >
              Anuluj
            </Button>
          </PatientForm>
        )}
      </Box>
    </>
  );
};
