import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  MyMessagesDocument,
  MyMessagesQuery,
  useDeleteMessagesMutation,
  useMarkMessageAsReadMutation,
} from '../../../../../generated/graphql';

export const useMessagesApi = () => {
  const { deleteSuccess } = useMessages();

  const [deleteMessages] = useDeleteMessagesMutation({
    onCompleted: () => deleteSuccess(),
  });

  const [markMessagesAsRead] = useMarkMessageAsReadMutation();

  const confirmRemoval = useRemoveConfirm();

  const onDeleteMessages = async (
    messageIds?: number[],
    important?: boolean,
  ) => {
    await confirmRemoval();
    deleteMessages({
      variables: { messageIds, includeImportant: important },
      update: (cache) => {
        const data = cache.readQuery<MyMessagesQuery>({
          query: MyMessagesDocument,
        });
        const messages = data?.allMessages;

        if (messages) {
          cache.writeQuery<MyMessagesQuery>({
            query: MyMessagesDocument,
            data: {
              allMessages: messageIds
                ? messages.filter(({ id }) => !messageIds.includes(id))
                : [],
            },
          });
        }
      },
    });
  };

  const onMarkMessagesAsRead = async (
    messageIds?: number[],
    important?: boolean,
  ) => {
    markMessagesAsRead({
      variables: { messageIds, includeImportant: important },
      update: (cache) => {
        const data = cache.readQuery<MyMessagesQuery>({
          query: MyMessagesDocument,
        });
        const messages = data?.allMessages;

        if (messages) {
          cache.writeQuery<MyMessagesQuery>({
            query: MyMessagesDocument,
            data: {
              allMessages: messages.map((message) => ({
                ...message,
                read:
                  !messageIds || messageIds.includes(message.id)
                    ? true
                    : message.read,
              })),
            },
          });
        }
      },
    });
  };

  return { onDeleteMessages, onMarkMessagesAsRead };
};
