import { useSnackbar } from 'notistack';
import React from 'react';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import {
  Doctor,
  DoctorInput,
  useGetProfileQuery,
  useUpdateCurrentDoctorMutation,
} from '../../../../../generated/graphql';
import { DoctorForm } from '../../../modules/doctors/form/DoctorForm';

const useDoctorProfile = () => {
  const snackBarError = useGraphQLError();

  const { data } = useGetProfileQuery({
    onError: snackBarError,
  });
  const [updateDoctor] = useUpdateCurrentDoctorMutation({
    onError: snackBarError,
    refetchQueries: ['GetProfile'],
  });

  return {
    profile: data?.currentDoctor,
    updateProfile: updateDoctor,
  };
};

export const DoctorProfile: React.FC = () => {
  const { profile, updateProfile } = useDoctorProfile();

  const { enqueueSnackbar } = useSnackbar();

  const onSubmitProfile = async (doctor: Doctor) => {
    if (!profile) return;

    const {
      id,
      createdAt,
      employedInBranches,
      describerInfo,
      describingStudyTypes,
      maxStudyHoursToDescribe,
      stampImage,
      facility,
      ...profileInput
    } = doctor;

    const {
      createdAt: userCreatedAt,
      updatedAt,
      preferences,
      profilePicture,
      ...doctorUserInput
    } = doctor.user;

    await updateProfile({
      variables: { doctor: { ...profileInput, user: doctorUserInput } },
      onCompleted: () =>
        enqueueSnackbar('Profil został zapisany', { variant: 'success' }),
    });
  };

  if (!profile) return null;

  return (
    <DoctorForm
      onSubmit={onSubmitProfile as any}
      doctor={profile as DoctorInput}
      saveButtonLabel="Zapisz profil"
      stampSelection
      facility={profile?.facility}
      profile
    ></DoctorForm>
  );
};
