import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Refresh } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '../form/Form';
import { Resolver } from 'react-hook-form';

const useStyles = makeStyles(() =>
  createStyles({
    focused: {},
    root: {
      backgroundColor: 'white',
      '&$focused': {
        backgroundColor: 'white',
      },
    },
    content: {
      margin: '8px 0 !important',
    },
  }),
);

type Props = {
  defaultValues: {};
  resetValues: {};
  defaultExpanded?: boolean;
  expanded?: boolean;
  onSearch: (filterInput: any) => void;
  mainFields: React.ReactNode;
  collapsableFields?: React.ReactNode;
  title?: string;
  resolver?: Resolver<{}, any> | undefined;
};

export const useSearchPanel = (defaultExpanded = false) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const toggleExpand = () => setExpanded(!expanded);

  return { toggleExpand, expanded };
};

export const SearchPanel: React.FC<Props> = ({
  defaultExpanded = false,
  defaultValues,
  resetValues,
  expanded,
  onSearch,
  mainFields,
  collapsableFields,
  title,
  resolver,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expandedInternally, setExpandedInternally] = useState(defaultExpanded);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isExpansionControlledInternally = expanded === undefined;

  return (
    <Form
      onSubmit={(data: any) => onSearch(data)}
      formOptions={{ defaultValues, resolver }}
      showLeaveUnsavedPrompt={false}
      noElevation
      render={({ reset }) => (
        <div>
          <Accordion
            defaultExpanded={defaultExpanded}
            expanded={
              isExpansionControlledInternally && !!collapsableFields
                ? expandedInternally
                : !!collapsableFields && expanded
            }
          >
            <AccordionSummary
              classes={{
                root: classes.root,
                focusVisible: classes.focused,
                content: classes.content,
              }}
              style={{ cursor: 'default' }}
              expandIcon={
                collapsableFields && isExpansionControlledInternally ? (
                  <Tooltip title={t('app.searchPanel.actions.expand') || ''}>
                    <IconButton
                      onClick={() => setExpandedInternally(!expandedInternally)}
                      size="large"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                ) : null
              }
            >
              <Box display="flex" flexDirection="column">
                <Box p={4}>
                  <Typography variant="h6">
                    {title || t('app.searchPanel.defaultTitle')}
                  </Typography>
                </Box>
                <Box
                  mx={4}
                  p={4}
                  display="flex"
                  flexDirection={isMobile ? 'column' : 'row'}
                  alignContent="center"
                  alignItems="center"
                >
                  {mainFields}
                  <Box p={4} ml={4} display="flex" flexDirection="row">
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      style={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
                      startIcon={<Refresh />}
                    >
                      {t('app.searchPanel.actions.search')}
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        const defaults = { ...resetValues };

                        reset(defaults);
                        onSearch(defaults);
                      }}
                    >
                      {t('app.searchPanel.actions.reset')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box p={4}>{collapsableFields}</Box>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    ></Form>
  );
};
