import { useHistory, useRouteMatch } from 'react-router';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  AppConfig,
  AppConfigDocument,
  useSaveAppConfigMutation,
} from '../../../../../generated/graphql';

export const useAppConfigApi = () => {
  const { success } = useMessages();
  const history = useHistory();

  const [saveAppConfig] = useSaveAppConfigMutation({
    onCompleted: () => success(),
  });

  const { path } = useRouteMatch();

  const onSaveSubmit = (appConfig: AppConfig) => {
    saveAppConfig({
      variables: { data: appConfig },
      refetchQueries: [AppConfigDocument],
    });

    history.push(`${path}/`, { useCache: false });
  };

  return { onSaveSubmit };
};
