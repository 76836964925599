import { NoteAdd } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';
import { useDirectFileDownload } from '../../../../../common/hooks/useFileDownload';
import {
  ActionColor,
  ItemActionHook,
} from '../../../../../common/types/actions';
import {
  Referal,
  useCompleteReferalMutation,
  useDeleteReferalMutation,
  useGetReferalLazyQuery,
  UserRole,
} from '../../../../../generated/graphql';
import { useSelectStudyType } from '../../studyTypes/hooks/useSelectStudyType';

export type ReferalActionType =
  | 'edit'
  | 'delete'
  | 'complete'
  | 'download'
  | 'createStudy';
type ReferalActionHook = ItemActionHook<Referal, ReferalActionType>;

export const useEditReferalAction: ReferalActionHook = () => {
  const history = useHistory();

  return {
    id: 'edit',
    Icon: EditIcon,
    title: 'Edytuj',
    actionColor: ActionColor.GREEN,
    roles: [UserRole.Admin, UserRole.Doctor],

    onAction: ({ id }) => history.push(`/referals/edit/${id}`),
    visible: ({ completed }) =>
      !history.location.pathname.includes('edit') && !completed,
  };
};

export const useDeleteReferalAction: ReferalActionHook = () => {
  const confirmRemoval = useRemoveConfirm();
  const [deleteReferal] = useDeleteReferalMutation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return {
    id: 'delete',
    Icon: DeleteIcon,
    title: 'Usuń',
    roles: [UserRole.Admin, UserRole.Doctor],
    actionColor: ActionColor.RED,
    onAction: async ({ id }) => {
      await confirmRemoval();
      await deleteReferal({ variables: { id } });
      enqueueSnackbar(`Skierowanie ${id} zostało usunięte.`, {
        variant: 'success',
      });
      history.push({ pathname: '/referals', state: { reload: true } });
    },
    visible: ({ completed }) => !completed,
  };
};

export const useCompleteReferalAction: ReferalActionHook = () => {
  const [completeReferal] = useCompleteReferalMutation();
  const { enqueueSnackbar } = useSnackbar();

  return {
    id: 'complete',
    Icon: PlaylistAddCheck,
    title: 'Zrealizuj',
    actionColor: ActionColor.GREEN,
    roles: [UserRole.Admin, UserRole.Technician],
    visible: ({ completed }) => !completed, //TODO: Check current user role
    onAction: async ({ id }) => {
      await completeReferal({ variables: { id } });
      enqueueSnackbar(`Skierowanie ${id} zostało zrealizowane.`, {
        variant: 'success',
      });
    },
  };
};

export const useCreateStudyFromReferalAction: ReferalActionHook = () => {
  const history = useHistory();
  const selectStudyType = useSelectStudyType();
  const { enqueueSnackbar } = useSnackbar();

  const [loadReferal, { called, refetch }] = useGetReferalLazyQuery({
    fetchPolicy: 'network-only',
  });

  return {
    id: 'createStudy',
    Icon: NoteAdd,
    title: 'Utwórz badanie na podstawie skierowania',
    actionColor: ActionColor.BLUE,
    roles: [UserRole.Admin, UserRole.Technician, UserRole.Registration],
    visible: ({ completed }) => !completed,
    onAction: async ({ id }) => {
      const referal = called
        ? await refetch({
            id,
            withStudies: true,
          })
        : await loadReferal({
            variables: {
              id,
              withStudies: true,
            },
          });

      const referalStudyTypes = referal.data?.referal.referalToStudyTypes;
      const existingReferalStudies = referal.data?.referal?.studies;

      if (existingReferalStudies?.length === referalStudyTypes?.length) {
        enqueueSnackbar(
          `Dla tego skierowania wystawiono już wszyskie badania.`,
          {
            variant: 'info',
          },
        );
      } else if (referalStudyTypes?.length === 1) {
        history.push(
          `/studies/add?referalId=${id}&studyTypeId=${referalStudyTypes[0].studyType.id}`,
        );
      } else {
        const selectedStudyType = await selectStudyType({
          getOptionDisabled: (studyType) => {
            const studyTypeOnReferal = referalStudyTypes?.some(
              ({ studyType: { id } }) => studyType.id === id,
            );
            const studyExistsAlerady = existingReferalStudies?.some(
              ({ studyType: { id } }) => studyType.id === id,
            );

            return !studyTypeOnReferal || !!studyExistsAlerady;
          },
        });

        if (selectedStudyType) {
          history.push(
            `/studies/add?referalId=${id}&studyTypeId=${selectedStudyType.id}`,
          );
        }
      }
    },
  };
};

export const useDownloadReferalAction: ReferalActionHook = () => {
  const downloadReferal = useDirectFileDownload();

  return {
    id: 'download',
    actionColor: ActionColor.GREEN,
    Icon: CloudDownloadIcon,
    showProgress: true,
    title: 'Pobierz',
    onAction: async ({ id, patient }) =>
      downloadReferal(
        `/prints/referal/${id}`,
        `skierowanie-${patient.contact.firstName}-${patient.contact.lastName}`,
      ),
  };
};
