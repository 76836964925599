import { AppBar, Paper, Tab, Tabs } from '@mui/material';
import { Assessment, Person, Store, TrendingUp } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import { TabPanel } from '../../../../common/components/tab-panel/TabPanel';
import { ProtectedRoute } from '../../components/auth/ProtectedRoute';
import { DailyFinancialReportTab } from './financials/DailyFinancialReportTab';
import { FinancialReportTab } from './financials/FinancialReportTab';
import { StudyReportTab } from './studies/StudyReportTab';
import { DoctorFinancialReportTab } from './financials/DoctorFinancialReportTab';
import { UserRole } from '../../../../generated/graphql';
import { useAppContextState } from '../../components/settings/hooks/useSettingsState';

const REPORT_TAB_ROUTES = [
  'financials',
  'daily-financial',
  'studies',
  'doctors',
];

export const ReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    Math.max(
      REPORT_TAB_ROUTES.findIndex((tab) => pathname.includes(tab)),
      0,
    ),
  );
  const history = useHistory();

  const handleTabSwitch = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    history.push(`/reports/${REPORT_TAB_ROUTES[newValue]}`);
  };

  const [{ currentRole }] = useAppContextState();
  const isAdminOrManager =
    !!currentRole && [UserRole.Admin, UserRole.Manager].includes(currentRole);

  return (
    <Paper>
      <AppBar position="static" color="inherit">
        <Tabs
          value={selectedTab}
          onChange={handleTabSwitch}
          indicatorColor="primary"
        >
          <Tab icon={<TrendingUp />} label={t('reports.tabs.financials')}></Tab>
          <Tab
            icon={<Assessment />}
            label={t('reports.tabs.dailyFinancial')}
          ></Tab>
          <Tab icon={<Store />} label={t('reports.tabs.studies')}></Tab>
          <Tab
            icon={<Person />}
            disabled={!isAdminOrManager}
            label={t('reports.tabs.doctors')}
          ></Tab>
        </Tabs>
      </AppBar>
      <Switch>
        <ProtectedRoute exact path={`${match.path}/`}>
          <Redirect to={`${match.path}/financials`} />
        </ProtectedRoute>

        <ProtectedRoute path={`${match.path}/financials`}>
          <TabPanel selectedIndex={selectedTab} index={0}>
            <FinancialReportTab />
          </TabPanel>
        </ProtectedRoute>
        <ProtectedRoute path={`${match.path}/daily-financial`}>
          <TabPanel selectedIndex={selectedTab} index={1}>
            <DailyFinancialReportTab />
          </TabPanel>
        </ProtectedRoute>
        <ProtectedRoute path={`${match.path}/studies`}>
          <TabPanel selectedIndex={selectedTab} index={2}>
            <StudyReportTab />
          </TabPanel>
        </ProtectedRoute>
        <ProtectedRoute
          path={`${match.path}/doctors`}
          requiredRoles={[UserRole.Admin]}
        >
          <TabPanel selectedIndex={selectedTab} index={3}>
            <DoctorFinancialReportTab />
          </TabPanel>
        </ProtectedRoute>
      </Switch>
    </Paper>
  );
};
