import { Link } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SignupConsentModal } from '../../../ui/app/components/auth/signup/SignupConsentModal';
import { RegulationsModal } from '../../../ui/app/components/regulations/RegulationsDialog';
import { CheckboxField } from '../form/fields/CheckboxField';

type Props = {
  type?: 'patient' | 'doctor';
};

export const AcceptTerms: React.FC<Props> = ({ type }) => {
  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const [reguluationsOpen, setRegulationsOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <CheckboxField
        name="acceptTerms"
        label={
          <small>
            {t('app.regulations.form.accept')}{' '}
            <Link
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                setConsentModalOpen(true);
              }}
            >
              {t('app.regulations.form.policy')}
            </Link>{' '}
            {t('app.regulations.form.and')}{' '}
            <Link
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                setRegulationsOpen(true);
              }}
            >
              {t('app.regulations.form.regulations')}
            </Link>
            .
          </small>
        }
      />
      <RegulationsModal
        type={type}
        open={reguluationsOpen}
        onClose={() => setRegulationsOpen(false)}
      />
      <SignupConsentModal
        open={consentModalOpen}
        onClose={() => setConsentModalOpen(false)}
      />
    </>
  );
};
