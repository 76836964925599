export const CookieTermsUA = () => (
  <ol>
    <li>
      <p>
        Сервіс не збирає ніякої інформації автоматичним способом, за винятком
        інформації, що міститься в файлах cookie.
      </p>
    </li>
    <li>
      <p>
        Файли cookie (так звані "cookies") - це ІТ-дані, зокрема текстові файли,
        які зберігаються на кінцевому пристрої Користувача Сервісу та призначені
        для використання інтернет-сайтів Сервісу Файли cookie зазвичай містять
        назву інтернет-сайту, з якого вони походять, тривалість їх зберігання на
        кінцевому пристрої та унікальний номер.
      </p>
    </li>
    <li>
      <p>
        Суб'єкт,що розміщує на кінцевому пристрої Користувача Сервісу файли
        cookie та доступ до них здійснюється оператором Сервісу Diagdent Polska
        Sp. z o.o., ul. Sądowa 14/1A, 50-046 Wrocław, KRS 0001064129, REGON
        526697293, NIP 8971928708, CBCT Sp. z o.o., 03-253 Warszawa, ul.
        Białołęcka 166D, KRS: 935996, REGON: 520643089, NIP: 5242930030 oraz
        CBCT Polska Sp. z o.o., 02-439 Warszawa, ul. Zdobnicza 8, KRS: 936013,
        REGON: 520643333, NIP: 5223215013.
      </p>
    </li>
    <li>
      <p>Файли cookie використовуються з метою:</p>
    </li>
    <ol>
      <li>
        <p>
          адаптації змісту інтернет-сайтів Сервісу до уподобань Користувача
          Користувача та з метою оптимізації використання інтернет-сайтів;
          зокрема ці файли дозволяють розпізнати пристрій Користувача Сервісу та
          належним чином відображати інтернет-сайт, адаптований до його
          індивідуальних потреб;
        </p>
      </li>
      <li>
        <p>
          створення статистичних даних, які допоможуть нам зрозуміти, як
          Користувачі Сервісу використовують інтернет-сайти, які дають
          можливість удосконалити їх структуру та зміст;
        </p>
      </li>
      <li>
        <p>
          ведення сесії Користувача Сервісу (після входу в систему), завдяки
          чому Користувачеві не потрібно повторно вводити логін і пароль на
          кожній підсторінці Сервісу;
        </p>
      </li>
    </ol>
    <li>
      <p>В рамках Сервісу використовуються наступні типи файлів cookie:</p>
    </li>
    <ol>
      <li>
        <p>
          „необхідні” файли cookie для забезпечення можливості використання
          послуг доступних на інтернет-сайті, наприклад, файли cookie
          використовуються для надання послуг, що потребують автентифікації в
          межах Сервісу;
        </p>
      </li>
      <li>
        <p>
          файли cookie для забезпечення безпеки, наприклад використовуються для
          виявлення зловживань з аутентифікацією в рамках Сервісу;
        </p>
      </li>
      <li>
        <p>
          „експлуатаційні” файли cookie, що дозволяють збирати інформацію про
          те, як використовуються інтернет-сайти Сервісу;
        </p>
      </li>
      <li>
        <p>
          „функціональні” файли cookie, що дозволяють „запам'ятовувати” обрані
          налаштування Користувача та персоналізувати інтерфейс Користувача,
          наприклад, з урахуванням обраної мови або регіону походження
          Користувача, розміру шрифту, вигляду інтернет-сайту тощо;
        </p>
      </li>
      <li>
        <p>
          „рекламні” файли cookie, що дозволяють надавати Користувачам рекламний
          контент, що більше відповідає їхнім інтересам.
        </p>
      </li>
    </ol>
    <li>
      <p>
        У багатьох випадках програмне забезпечення для перегляду інтернет-сайтів
        (веб-браузер) за замовчуванням дозволяє зберігання файлів cookie на
        кінцевому обладнанні Користувача. Користувачі Сервісу можуть в будь-який
        момент змінити свої налаштування щодо файлів cookie. Ці налаштування
        можуть бути змінені, зокрема з метою блокування автоматичної обробки
        файли cookie в налаштуваннях вашого веб-браузера або інформування про
        розміщення щоразу в пристрої Користувача Сервісу. Детальна інформація
        про можливість та порядок обслуговування файлів cookie доступна в
        налаштуваннях Вашого програмного забезпечення (веб-браузера).
      </p>
    </li>
    <li>
      <p>
        Оператор Cервісу інформує Вас про те, що обмеження на використання
        файлів cookie можуть впливати на деякі функціональні можливості,
        доступні на інтернет-сайті Сервісу.
      </p>
    </li>
    <li>
      <p>
        Файли cookie, розміщені в кінцевому обладнанні Користувача Сервісу,
        також можуть бути використані рекламодавцями та партнерами, які
        співпрацюють з оператором Сервісу.
      </p>
    </li>
  </ol>
);
