/**
 *  Verifies if the doctor's 7-digit registration number is valid by comparting the checksum (1st digit)
 *  with mod11 of sum of other digits multiplied by index value
 *  https://nil.org.pl/rejestry/centralny-rejestr-lekarzy/zasady-weryfikowania-nr-prawa-wykonywania-zawodu
 */
export const validateDoctorRegChecksum = (doctorRegNumber: string): boolean => {
  if (doctorRegNumber.length !== 7) return false;

  const [checkSum, ...regNumberDigits] = doctorRegNumber
    .split('')
    .map((d) => parseInt(d));

  return (
    regNumberDigits.reduce((acc, digit, i) => (acc += digit * (i + 1))) % 11 ===
    checkSum
  );
};
