import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CookieSettings } from '../../../../common/hooks/useCookieSettings';

interface CookieConfigDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (settings: CookieSettings) => void;
  onReject: () => void;
  initialSettings: CookieSettings;
}

export const CookieConfigDialog: React.FC<CookieConfigDialogProps> = ({
  open,
  onClose,
  onSave,
  onReject,
  initialSettings,
}) => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<CookieSettings>(initialSettings);

  // Reset settings when dialog opens
  useEffect(() => {
    if (open) {
      setSettings(initialSettings);
    }
  }, [open, initialSettings]);

  const handleChange = (name: keyof CookieSettings) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSettings({
      ...settings,
      [name]: event.target.checked,
    });
  };

  const handleSave = () => {
    onSave(settings);
    onClose();
  };

  const handleRejectAll = () => {
    onReject();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('app.cookieConsent.configureTitle')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          {t('app.cookieConsent.configureDescription')}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.necessary}
                onChange={handleChange('necessary')}
                color="primary"
                disabled
              />
            }
            label={
              <Box>
                <Typography variant="subtitle2">
                  {t('app.cookieConsent.necessaryCookies')}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {t('app.cookieConsent.necessaryCookiesDescription')}
                </Typography>
              </Box>
            }
          />
          <Divider sx={{ my: 1 }} />

          <FormControlLabel
            control={
              <Switch
                checked={settings.security}
                onChange={handleChange('security')}
                color="primary"
              />
            }
            label={
              <Box>
                <Typography variant="subtitle2">
                  {t('app.cookieConsent.securityCookies')}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {t('app.cookieConsent.securityCookiesDescription')}
                </Typography>
              </Box>
            }
          />
          <Divider sx={{ my: 1 }} />

          <FormControlLabel
            control={
              <Switch
                checked={settings.performance}
                onChange={handleChange('performance')}
                color="primary"
              />
            }
            label={
              <Box>
                <Typography variant="subtitle2">
                  {t('app.cookieConsent.performanceCookies')}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {t('app.cookieConsent.performanceCookiesDescription')}
                </Typography>
              </Box>
            }
          />
          <Divider sx={{ my: 1 }} />

          <FormControlLabel
            control={
              <Switch
                checked={settings.functional}
                onChange={handleChange('functional')}
                color="primary"
              />
            }
            label={
              <Box>
                <Typography variant="subtitle2">
                  {t('app.cookieConsent.functionalCookies')}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {t('app.cookieConsent.functionalCookiesDescription')}
                </Typography>
              </Box>
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRejectAll} color="secondary">
          {t('app.cookieConsent.rejectAll')}
        </Button>
        <Button onClick={onClose} color="primary">
          {t('app.common.actions.cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t('app.common.actions.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
