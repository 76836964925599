import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  DoctorInput,
  useAddDoctorMutation,
} from '../../../../../generated/graphql';
import { DoctorForm } from '../form/DoctorForm';

type Props = {
  disabled?: boolean;
  facility?: boolean;
};

const EMPTY_DOCTOR = (facility: boolean): DoctorInput => ({
  user: {
    login: 'NO_USER',

    contact: {
      birthDate: null,
      phoneNumbers: [],
      emailAddresses: [],
      companyName: facility ? '' : null,
      nip: facility ? '' : null,
      firstName: '',
      lastName: '',
    },
  },
});

export const DoctorAdd: React.FC<Props> = ({ disabled = false, facility }) => {
  const { success } = useMessages();
  const snackBarError = useGraphQLError();
  const [addDoctor] = useAddDoctorMutation({
    onCompleted: () => {
      success();
      setTimeout(() =>
        history.push(facility ? '/facilities' : '/doctors', {
          useCache: false,
        }),
      );
    },
    onError: snackBarError,
  });

  const history = useHistory();

  const onSubmit = async (data: DoctorInput) => {
    await addDoctor({
      variables: {
        data: {
          noUserContact: data.user.contact,
          licenseNumber: data.licenseNumber,
          specializations: data.specializations,
        },
      },
    });
  };

  return (
    <div>
      <DoctorForm
        onSubmit={onSubmit}
        doctor={{ ...EMPTY_DOCTOR(facility || false) }}
        disabled={disabled}
        title={facility ? 'Dodaj gabinet' : 'Dodaj lekarza'}
        facility={facility}
      >
        <Button
          type="button"
          color="secondary"
          onClick={() => history.goBack()}
        >
          Anuluj
        </Button>
      </DoctorForm>
    </div>
  );
};
