import { Box, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1250,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    alignSelf: 'center',
  },
}));

const MissingStampAlert: React.FC = () => {
  const classes = useStyles();

  return (
    <Box mb={4} className={classes.root}>
      <Alert
        severity="error"
        classes={{
          root: classes.alert,
          icon: classes.icon,
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1">
            Przed wystawieniem pierwszego skierowania należy ustawić pieczątkę.
            Można to zrobić na stronie{' '}
            <Link href="/profile">
              <strong>Mój Profil</strong>
            </Link>{' '}
            klikając na ikonkę edycji obok obrazu pieczątki.
            <br />
          </Typography>
          <Box ml={4}>
            <img
              height={90}
              src="/img/stamp_placeholder.png"
              alt="stamp_placeholder"
            ></img>
          </Box>
        </Box>
      </Alert>
    </Box>
  );
};

export default MissingStampAlert;
