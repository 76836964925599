import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Delete, PhotoCamera } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageUploadDialog } from '../../../../../common/components/images/ImageUploadDialog';
import { useProfilePicture } from './hooks/useProfilePicture';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(48),
      height: theme.spacing(48),
    },
    noAvatar: {
      fontSize: 64,
    },
  }),
);

export const ProfilePicture: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [cropData, setCropData] = useState<string | undefined>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    profilePictureURI,
    updateProfilePicture,
    deleteExistingProfilePicture,
  } = useProfilePicture();

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6">
            {t('profile.profilePicture.title')}
          </Typography>
          <IconButton onClick={() => setDialogOpen(true)} size="large">
            <Avatar
              alt="profile"
              src={
                `${profilePictureURI}?rand=${Math.floor(
                  Math.random() * 10000,
                )}` || cropData
              }
              className={classes.large}
            >
              <PhotoCamera className={classes.noAvatar} />
            </Avatar>
          </IconButton>
          <IconButton
            onClick={async () => {
              await deleteExistingProfilePicture();
              setCropData(undefined);
            }}
            aria-label="delete profile picture"
            size="large"
          >
            <Delete fontSize="small" color="error" />
          </IconButton>
        </CardContent>
      </Card>
      <ImageUploadDialog
        initialAspectRatio={1}
        aspectRatio={1}
        open={dialogOpen}
        onClose={(croppedImageData) => {
          if (croppedImageData) {
            setCropData(croppedImageData);
            updateProfilePicture({
              variables: { updateProfilePicturePictureData: croppedImageData },
            });
          }

          setDialogOpen(false);
        }}
      />
    </>
  );
};
