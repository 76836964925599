import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AttachFileOutlined,
  Close,
  Delete,
  GridView,
  QrCode,
  Share,
} from '@mui/icons-material';
import { isNil } from 'lodash-es';
import { useSnackbar } from 'notistack';

import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outline } from '../../../../common/components/form/LabeledOutline';
import { useRemoveConfirm } from '../../../../common/hooks/useConfirmation';
import { ActionColor } from '../../../../common/types/actions';
import { toLocaleDateTime } from '../../../../common/utils/date.utils';
import {
  File,
  useGetDownloadUrlQuery,
  UserRole,
} from '../../../../generated/graphql';
import { Protect } from '../auth/components/Protect';
import { DownloadFileButton, shouldDownloadAsync } from './DownloadFileButton';

import IconDICOM from './png/dicom.png';
import IconIMG from './png/jpg.png';
import IconPDF from './png/pdf.png';
import IconZIP from './png/zip.png';
import { HelpOpenFilePopup } from './components/HelpOpenFilePopup';
import { PreviewFileButton } from './PreviewFileButton';
import { getFilenameFromUrl } from '../../../../common/utils/file.utils';
import { ExtractFileButton } from './ExtractFileButton';
import { useAuth } from '../../../../common/hooks/useAuth';
import { QRCodeCanvas } from 'qrcode.react';

export type SimplifiedFile = Pick<
  File,
  | 'id'
  | 'uri'
  | 'fileType'
  | 'mimeType'
  | 'createdAt'
  | 'rejected'
  | 'rejectionReason'
>;

const ICON_SIZE = 48;

type Props = {
  files: Array<SimplifiedFile>;
  onDeleteFile?: (fileId: number) => void;
  showActions?: boolean;
  studyId: number;
  title?: string;
  rejected?: boolean;
};

const getIconImage = (src: string, alt: string) => (
  <img alt={alt} src={src} width={ICON_SIZE} height={ICON_SIZE}></img>
);

const isZipFile = (file: SimplifiedFile) => {
  const extension = file.uri.split('.').pop()?.toLocaleLowerCase() as string;
  return ['zip', 'gzip', 'rar'].includes(extension);
};

const getFileIcon = (file: SimplifiedFile): JSX.Element => {
  const extension = file.uri.split('.').pop()?.toLocaleLowerCase() as string;

  if (['zip', 'gzip', 'rar'].includes(extension)) {
    return getIconImage(IconZIP, 'zip file');
  } else if (['jpg', 'jpeg', 'png', 'bmp'].includes(extension)) {
    return getIconImage(IconIMG, 'image file');
  } else if (['pdf'].includes(extension)) {
    return getIconImage(IconPDF, 'pdf file');
  } else if (['dcm'].includes(extension)) {
    return getIconImage(IconDICOM, 'dcm file');
  }

  return <AttachFileOutlined color="primary" />;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      height: 'auto',
      width: 'auto',
      marginRight: theme.spacing(12),
    },
    filename: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '200px',
      },
    },
    downloadLink: {
      cursor: 'pointer',
    },
    fileAttributes: {
      whiteSpace: 'pre-wrap',
    },
  }),
);

export const AttachedFiles: React.FC<Props> = ({
  files,
  onDeleteFile,
  studyId,
  showActions,
  title,
  rejected = false,
}) => {
  const { t } = useTranslation();

  return (
    <Outline
      label={
        !isNil(title)
          ? title
          : t('app.common.fields.attachedFiles.title.general')
      }
    >
      <List dense>
        {files
          .filter(({ rejected: fileRejected }) => {
            return fileRejected === rejected;
          })
          .map((file, index) => (
            <Fragment key={file.id}>
              <AttachedFileItem
                file={file}
                studyId={studyId}
                onDeleteFile={onDeleteFile}
                showActions={showActions}
              />
              {index !== files.length - 1 && <Divider />}
            </Fragment>
          ))}
      </List>
    </Outline>
  );
};

const basename = (fileUri: string) => fileUri.split(/[\\/]/).pop();

const QRCodeDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  downloadURL: string;
}> = ({ open, onClose, downloadURL }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('app.common.fields.attachedFiles.qrCode')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ display: 'flex', justifyContent: 'center', padding: '24px' }}
      >
        <QRCodeCanvas value={downloadURL || ''} size={256} />
      </DialogContent>
    </Dialog>
  );
};

const AttachedFileItem: React.FC<
  { file: SimplifiedFile } & Pick<
    Props,
    'onDeleteFile' | 'showActions' | 'studyId'
  >
> = ({ file, onDeleteFile, studyId, showActions = true }) => {
  const { t } = useTranslation();

  const { data } = useGetDownloadUrlQuery({
    variables: { filePath: file.uri, studyId },
  });

  const [qrDialogOpen, setQrDialogOpen] = React.useState(false);

  const { getAuthInfo } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const confirmRemoval = useRemoveConfirm();
  const classes = useStyles();

  // Function to share the file URL by copying to clipboard
  const handleShareClick = () => {
    if (data?.getDownloadURL) {
      navigator.clipboard
        .writeText(data.getDownloadURL)
        .then(() => {
          enqueueSnackbar(
            t(
              'app.messages.copied-to-clipboard',
              'File URL copied to clipboard',
            ),
            {
              variant: 'success',
            },
          );
        })
        .catch((error) => {
          console.error('Failed to copy URL: ', error);
          enqueueSnackbar(t('app.messages.copy-failed', 'Failed to copy URL'), {
            variant: 'error',
          });
        });
    }
  };

  const fileIcon = useMemo(() => getFileIcon(file), [file]);

  const viewerEnabled = process.env.VERCEL_ENV !== 'production';

  return (
    <ListItem>
      <ListItemIcon>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Icon className={classes.icon}>{fileIcon}</Icon>
        </Box>
      </ListItemIcon>
      <ListItemText
        className={classes.filename}
        secondaryTypographyProps={{ component: 'div' }}
        primary={
          <Tooltip title={basename(file.uri) || ''} enterDelay={1000}>
            <Link
              className={classes.downloadLink}
              href={data?.getDownloadURL}
              target="_blank"
            >
              <Typography noWrap>{basename(file.uri)}</Typography>
            </Link>
          </Tooltip>
        }
        secondary={
          <>
            <Box display="flex" justifyContent="space-between">
              <span>
                {t('app.common.fields.attachedFiles.fileType')}:{' '}
                {t(`fields.fileTypes.${file.fileType}`)}
                <br />
                {t('app.common.fields.attachedFiles.attachedDate')}:{' '}
                {toLocaleDateTime(file.createdAt)}
                {file.rejected && (
                  <>
                    <br />
                    {t('app.common.fields.attachedFiles.rejectionReason')}:{' '}
                    {file.rejectionReason}
                  </>
                )}
              </span>
              <Box display="flex">
                {isZipFile(file) && (
                  <>
                    <HelpOpenFilePopup />
                    <IconButton
                      color="primary"
                      onClick={() => setQrDialogOpen(true)}
                      size="large"
                      aria-label="show qr code"
                    >
                      <QrCode />
                    </IconButton>
                    <QRCodeDialog
                      open={qrDialogOpen}
                      onClose={() => setQrDialogOpen(false)}
                      downloadURL={data?.getDownloadURL || ''}
                    />
                  </>
                )}
                {/* Share button to copy URL to clipboard */}
                {data?.getDownloadURL && (
                  <Tooltip title={t('app.common.actions.share', 'Share')} arrow>
                    <IconButton
                      color="primary"
                      onClick={handleShareClick}
                      size="large"
                      aria-label="share file url"
                    >
                      <Share />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Box mt={8}>
              <DownloadFileButton fileUri={file.uri} studyId={studyId} />
              {isZipFile(file) && (
                <>
                  <Box mt={2} />
                  <ExtractFileButton fileUri={file.uri} studyId={studyId} />
                  <Box mt={2} />
                  {viewerEnabled && (
                    <Badge
                      badgeContent="Nowość"
                      color="error"
                      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                      sx={{
                        '& .MuiBadge-badge': {
                          fontSize: '0.6rem',
                          height: '16px',
                          minWidth: '16px',
                          padding: '0 4px',
                        },
                        width: '100%',
                      }}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{ justifyContent: 'center' }}
                        aria-label="preview"
                        onClick={() =>
                          window.open(
                            `https://dicom-web-viewer-1065393847252.europe-west3.run.app/viewer/dicomjson?url=${
                              process.env.REACT_APP_API_ENDPOINT
                            }/files/${file.id}/metadata&token=${
                              getAuthInfo().token
                            }`,
                            '_blank',
                          )
                        }
                        fullWidth
                        startIcon={<GridView />}
                      >
                        {t('pages.study.actions.download.previewLabel')}
                      </Button>
                    </Badge>
                  )}
                </>
              )}
              <Box mt={2} />
              {shouldDownloadAsync(
                getFilenameFromUrl(data?.getDownloadURL || ''),
              ) &&
                data?.getDownloadURL && (
                  <PreviewFileButton downloadURL={data.getDownloadURL} />
                )}

              {showActions && onDeleteFile && (
                <Protect roles={[UserRole.Admin, UserRole.Technician]}>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    style={{ color: ActionColor.RED }}
                    onClick={async () => {
                      await confirmRemoval();
                      onDeleteFile(file.id);
                      enqueueSnackbar('Plik został pomyślnie usunięty', {
                        variant: 'success',
                      });
                    }}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </Protect>
              )}
            </Box>
          </>
        }
      />
    </ListItem>
  );
};
