import { Link, LinkProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { MouseEvent } from 'react';

export const useStyles = makeStyles(() => ({
  link: {
    textAlign: 'left',
  },
}));

export const TableCellLink: React.FC<LinkProps<'a'>> = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.link}>
      <Link
        variant="body2"
        {...props}
        onClick={(event: MouseEvent<HTMLAnchorElement>) => {
          if (props.onClick) {
            props.onClick(event);
          }
          event.preventDefault();
          event.stopPropagation();
        }}
      />
    </span>
  );
};
