import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(36),
      right: theme.spacing(12),
    },
  }),
);
