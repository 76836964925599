import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  PatientInput,
  useAddPatientMutation,
  useUpdatePatientMutation,
} from '../../../../../generated/graphql';

export const useAddOrEditPatient = () => {
  const { success } = useMessages();
  const snackBarError = useGraphQLError();

  const [addPatient] = useAddPatientMutation({
    onCompleted: () => success(),
    onError: snackBarError,
  });

  const [updatePatient] = useUpdatePatientMutation({
    onCompleted: () => success(),
    onError: snackBarError,
  });

  const addOrEditPatient = async (
    patientInput: PatientInput,
  ): Promise<PatientInput | undefined> => {
    if (patientInput.id) {
      const updatedPatient = await updatePatient({
        variables: { id: patientInput.id, patient: patientInput },
      });

      return updatedPatient?.data?.updatePatient;
    } else {
      const addedPatient = await addPatient({
        variables: { newPatient: patientInput },
      });

      return addedPatient?.data?.addPatient;
    }
  };

  return { addOrEditPatient };
};
