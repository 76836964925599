import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from './useInterval';
import { HybridTableStorage } from '../components/table/state/table.storage';

export const useAppRefresh = () => {
  const prevBuildId = useRef<string | undefined>(
    process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
  );

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const showUpdateNotification = useCallback(() => {
    enqueueSnackbar(t('app.messages.newVersionMessage'), {
      variant: 'info',
      action: () => (
        <Button
          color="secondary"
          size="small"
          variant="contained"
          onClick={() => {
            HybridTableStorage.removePersistedStateByFragment('List');
            window.location.reload();
          }}
        >
          OK
        </Button>
      ),
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      persist: true,
      preventDuplicate: true,
    });
  }, [enqueueSnackbar, t]);

  useInterval(
    async () => {
      // For local dev environment, we don't want to check for updates since the API is not running on the same host
      if (process.env.NODE_ENV === 'development') return;

      const data = await fetch('/api/build/handler').then((res) => res.json());

      if (data?.commit && prevBuildId && prevBuildId.current !== data?.commit) {
        await navigator.serviceWorker
          .getRegistration(`/service-worker.js`)
          .then(async (registration) => {
            if (registration) {
              const waitingServiceWorker = registration.waiting;

              if (waitingServiceWorker) {
                waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
              }
              if (registration) await registration.update();
            }
          });
        showUpdateNotification();
      }
    },
    250 * 1000,

    // Run once 2 seconds after page is opened (to wait for Service Worker)
    10000,
  );
};
