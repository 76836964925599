import { AssignmentLate, MailOutline, PostAdd } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import {
  useActionConfirm,
  useRemoveConfirm,
} from '../../../../../common/hooks/useConfirmation';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import {
  ActionColor,
  ItemActionHook,
} from '../../../../../common/types/actions';
import {
  Patient,
  useDeletePatientMutation,
  useSendPatientStudiesLinkMutation,
  UserRole,
} from '../../../../../generated/graphql';

export type PatientActionType =
  | 'edit'
  | 'delete'
  | 'createComplaint'
  | 'createReferal'
  | 'sendStudiesLink';

type PatientActionHook = ItemActionHook<Patient, PatientActionType>;

export const useEditPatientAction: PatientActionHook = () => {
  const history = useHistory();

  return {
    id: 'edit',
    Icon: EditIcon,
    title: 'Edytuj pacjenta',
    actionColor: ActionColor.ORANGE,
    roles: [UserRole.Admin, UserRole.Registration, UserRole.Technician],
    onAction: ({ id }) => history.push(`/patients/edit/${id}`),
  };
};

export const useDeletePatientAction: PatientActionHook = () => {
  const confirmRemoval = useRemoveConfirm();
  const snackBarError = useGraphQLError();
  const [deletePatient] = useDeletePatientMutation({
    onError: (error) => snackBarError(error),
    onCompleted: () =>
      enqueueSnackbar(`Pacjent został usunięty.`, {
        variant: 'success',
      }),
  });
  const { enqueueSnackbar } = useSnackbar();

  return {
    id: 'delete',
    Icon: DeleteIcon,
    title: 'Usuń pacjenta',
    actionColor: ActionColor.RED,
    roles: [UserRole.Admin, UserRole.Registration, UserRole.Technician],
    onAction: async ({ id }) => {
      await confirmRemoval();
      await deletePatient({ variables: { id } });
    },
  };
};

export const useCreateReferalAction: PatientActionHook = () => {
  const history = useHistory();

  return {
    id: 'createReferal',
    Icon: PostAdd,
    title: 'Utwórz skierowanie dla pacjenta',
    actionColor: ActionColor.BLUE,
    onAction: ({ id }) => history.push(`/referals/add?patientId=${id}`),
    forbiddenRoles: [UserRole.DentalOffice],
  };
};

export const useCreateComplaintAction: PatientActionHook = () => {
  const history = useHistory();

  return {
    id: 'createComplaint',
    Icon: AssignmentLate,
    title: 'Utwórz reklamację pacjenta',
    actionColor: ActionColor.ORANGE,
    onAction: ({ id }) => history.push(`/complaints/add?patientId=${id}`),
    roles: [UserRole.Admin, UserRole.Registration, UserRole.Technician],
  };
};

export const useSendPatientStudiesLinkAction: PatientActionHook = () => {
  const confirm = useActionConfirm();
  const snackBarError = useGraphQLError();
  const [sendPatientLink] = useSendPatientStudiesLinkMutation({
    onError: (error) => snackBarError(error),
    onCompleted: () =>
      enqueueSnackbar(`Link z badaniami został wysłany do pacjenta`, {
        variant: 'success',
      }),
  });
  const { enqueueSnackbar } = useSnackbar();

  return {
    id: 'sendStudiesLink',
    Icon: MailOutline,
    title: 'Wyślij link z badaniami do pacjenta',
    actionColor: ActionColor.BLUE,
    roles: [
      UserRole.Admin,
      UserRole.Registration,
      UserRole.Technician,
      UserRole.Manager,
    ],
    onAction: async ({ id }) => {
      await confirm();
      await sendPatientLink({ variables: { id } });
    },
    visible: (item) => !!item?.contact?.emailAddresses?.length,
  };
};
