import { Box } from '@mui/material';
import { Alarm } from '@mui/icons-material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useAuth } from '../../../../../../common/hooks/useAuth';
import { ActionColor } from '../../../../../../common/types/actions';
import { toLocaleDateTime } from '../../../../../../common/utils/date.utils';
import { Maybe, Study, UserRole } from '../../../../../../generated/graphql';

type Props = {
  studyListItem: Study;
};

export const StudyListDescriptionTime: React.FC<Props> = ({
  studyListItem,
}) => {
  const { getAuthInfo } = useAuth();

  const dueDate = useMemo<Maybe<Date> | undefined>(() => {
    let dueDateInner = studyListItem.describers?.[0]?.dueDate;
    if (getAuthInfo().roles.includes(UserRole.DescribingDoctor)) {
      dueDateInner =
        studyListItem.describers?.find(
          ({ doctor }) => doctor.user.id === getAuthInfo().id,
        )?.dueDate || dueDateInner;
    }
    return dueDateInner;
  }, [getAuthInfo, studyListItem.describers]);

  if (!dueDate || !studyListItem.missingDescription) return null;

  const durationUntilDueDate = DateTime.fromISO(
    new Date(dueDate).toISOString(),
  ).diffNow(['days', 'hours', 'minutes']);

  const showWarning = durationUntilDueDate.get('days') < 3;

  return (
    <Box
      display="flex"
      gap={5}
      color={showWarning ? ActionColor.RED : 'default'}
    >
      <Alarm fontSize="small" />
      {toLocaleDateTime(dueDate, DateTime.DATE_SHORT)}
    </Box>
  );
};
