import React, { useMemo } from 'react';
import { useStyles } from './TopBarLogo.styles';
import { Box, Link } from '@mui/material';
import classNames from 'classnames';

export type Props = {
  src: string;
  alt: string;
  href?: string;
  width?: number;
  className?: string;
};

export const TopBarLogo: React.FC<Props> = ({
  src,
  alt,
  href,
  width = 150,
  className,
}: Props) => {
  const classes = useStyles();

  const Img = useMemo(
    () => (
      <img
        alt={alt}
        src={src}
        width={width}
        className={classNames(classes.logoImg, className)}
      />
    ),
    [alt, classes.logoImg, src, width, className],
  );

  return (
    <Box display="flex" justifyContent="center">
      <div className={classes.root}>
        {href ? <Link href={href}>{Img}</Link> : Img}
      </div>
    </Box>
  );
};
