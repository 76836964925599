import React, { useState } from 'react';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import { useTranslation } from 'react-i18next';
import { useGraphQLError } from '../../../../../../common/hooks/useGraphQLError';
import { useAcceptTermsMutation } from '../../../../../../generated/graphql';
import { RegulationsModal } from '../../../regulations/RegulationsDialog';
import { useOAuthConnect } from '../../oauth/useOAuthConnect';
import { OAuthState, useOAuthLogin } from '../../oauth/useOAuthLogin';
import FacebookLogo from './FacebookLogo.svg';
import { OAuthButton } from '../oauth-button/OAuthButton';

type Props = {
  connectToCurrentAccount?: boolean;
};

const createOAuthState = (facebookInfo: ReactFacebookLoginInfo): OAuthState => {
  const { first_name, last_name, email, accessToken } = facebookInfo;

  return {
    token: accessToken,
    firstName: first_name,
    lastName: last_name,
    login: email || '',
    type: 'facebook' as const,
  };
};

export const FacebookLoginButton: React.FC<Props> = ({
  connectToCurrentAccount,
}) => {
  const { t } = useTranslation();
  const [facebookInfo, setFacebookInfo] = useState<ReactFacebookLoginInfo>();
  const {
    loginMutation,
    regulationsOpen,
    regulationsRequired,
    setRegulationsOpen,
    proceedWithLogin,
  } = useOAuthLogin<ReactFacebookLoginInfo>(facebookInfo, createOAuthState);

  const snackBarError = useGraphQLError();
  const [acceptTermsMutation] = useAcceptTermsMutation({
    onError: (error) => snackBarError(error),
  });

  const { connectOAuth } = useOAuthConnect();

  return (
    <>
      <FacebookLogin
        appId="169246252822832"
        fields="first_name,last_name,email"
        isMobile={false}
        disableMobileRedirect={true}
        callback={async (data: ReactFacebookLoginInfo) => {
          if (!data) return;
          setFacebookInfo(data);

          const context = {
            headers: {
              access_token: data.accessToken,
              oauth_type: 'facebook',
            },
          };

          if (connectToCurrentAccount) {
            connectOAuth({ context });
          } else {
            loginMutation({ context });
          }
        }}
        // @ts-ignore
        render={({ onClick }) => (
          <OAuthButton
            logo={FacebookLogo}
            text={
              t('login.actions.facebookLoginButton') ||
              'Kontynuuj przez Facebooka'
            }
            onClick={onClick}
          />
        )}
      />
      <RegulationsModal
        onAccept={
          regulationsRequired
            ? async () => {
                await acceptTermsMutation();
                proceedWithLogin();
              }
            : undefined
        }
        open={regulationsOpen}
        onClose={() => setRegulationsOpen(false)}
      />
    </>
  );
};
