import { Box, Button } from '@mui/material';
import { Unarchive } from '@mui/icons-material';

import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressModal from '../../../../common/components/progress-modal/ProgressModal';
import { useDownloadAndExtractFromURL } from '../../../../common/hooks/useFileDownload';
import { isChrome } from '../../../../common/utils/browser.utils';

export type ExtractFileButtonProps = {
  fileUri: string;
  studyId: number;
};

export const ExtractFileButton: React.FC<ExtractFileButtonProps> = ({
  fileUri,
  studyId,
}) => {
  const {
    onDownload,
    openProgress,
    progress,
    currentTask,
  } = useDownloadAndExtractFromURL(fileUri, studyId);
  const { t } = useTranslation();

  const isUsingChromium = isChrome();
  if (!isUsingChromium) return null;

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Button
          color="primary"
          variant="outlined"
          style={{ justifyContent: 'center' }}
          aria-label="download"
          onClick={() => onDownload()}
          fullWidth
          startIcon={<Unarchive />}
        >
          {t('pages.study.actions.download.extractLabel')}
        </Button>
        {/* <Button
          color="primary"
          variant="outlined"
          style={{ justifyContent: 'center' }}
          aria-label="download"
          onClick={() => onDownload(true)}
          fullWidth
          startIcon={<OpenInNew />}
        >
          {t('pages.study.actions.download.extractAndOpenLabel')}
        </Button> */}
      </Box>

      <ProgressModal
        open={openProgress}
        title="Pobieranie i rozpakowywanie plików"
        progress={progress}
        secondaryLabel={currentTask}
      />
    </>
  );
};
