import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookLoginButton } from '../../components/facebook-login/FacebookLoginButton';
import { GoogleLoginButton } from '../../components/google-login/GoogleLoginButton';

export const ConnectSocialLogin: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{t('profile.socialLogin.title')}</Typography>
        <FacebookLoginButton connectToCurrentAccount />
        <GoogleLoginButton connectToCurrentAccount />
      </CardContent>
    </Card>
  );
};
