import { Box } from '@mui/material';

import Chart from 'react-apexcharts';

import { uniqueId } from 'lodash-es';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import { useDirectFileDownload } from '../../../../../common/hooks/useFileDownload';
import {
  StudyByGenderRow,
  StudyByStudyTypeRow,
  useStudiesByGenderLazyQuery,
  useStudiesByStudyTypeLazyQuery,
} from '../../../../../generated/graphql';
import { StudyReportFilters } from '../components/StudyFilters';

type Props = {};

type StudyByStudyTypeTableRow = StudyByStudyTypeRow & { id: string };
type StudyByGenderTableRow = StudyByGenderRow & { id: string };

const COLUMNS: SortableColumn<StudyByStudyTypeTableRow>[] = [
  {
    Header: 'Typ badania',
    accessor: 'studyType',
    Footer: 'Razem',
  },
  {
    Header: 'Liczba badań',
    accessor: 'count',
    sortType: 'basic',
    Footer: ({ rows }) =>
      rows.reduce((acc, curr) => (acc += curr.original.count), 0),
  },
];

export const StudyReportTab: React.FC<Props> = () => {
  const { t } = useTranslation();

  const downloadReport = useDirectFileDownload();

  const GENDER_COLUMNS = useMemo<SortableColumn<StudyByGenderTableRow>[]>(
    () => [
      {
        Header: 'Płeć',
        accessor: 'gender',
        Cell: ({
          value,
        }: CellProps<StudyByGenderTableRow, StudyByGenderTableRow['gender']>) =>
          t(`fields.contact.gender.${value || 'Unknown'}`),
        width: 30,
        maxWidth: 30,
        Footer: 'Razem',
      },
      {
        Header: 'Liczba badań',
        accessor: 'count',
        sortType: 'basic',
        width: 30,
        maxWidth: 30,
        Footer: ({ rows }) =>
          rows.reduce((acc, curr) => (acc += curr.original.count), 0),
      },
    ],
    [t],
  );

  const [loadStudiesByStudyType, { data }] = useStudiesByStudyTypeLazyQuery({
    context: { withGlobalLoading: true },
  });

  const [
    loadStudiesByGender,
    { data: genderData },
  ] = useStudiesByGenderLazyQuery({ context: { withGlobalLoading: true } });

  return (
    <>
      <StudyReportFilters
        onSearch={({ range, ...studyFilters }) => {
          loadStudiesByGender({ variables: { filter: studyFilters } });
          loadStudiesByStudyType({ variables: { filter: studyFilters } });
        }}
        onDownload={({ studyDateFrom, studyDateTo, regions = [] }) => {
          const startDate = DateTime.fromJSDate(
            new Date(studyDateFrom),
          ).toFormat('dd-MM-yyyy');
          const endDate = DateTime.fromJSDate(new Date(studyDateTo)).toFormat(
            'dd-MM-yyyy',
          );

          const params = new URLSearchParams();
          params.append('startDate', startDate);
          params.append('endDate', endDate);
          if (regions?.length) {
            params.append('regions', regions.join(','));
          }

          const reportPath = `/reports/rejected-studies-report?${params.toString()}`;

          downloadReport(
            reportPath,
            `raport_badań_${DateTime.now().toFormat('yyyy-MM-dd')}.csv`,
          );
        }}
      />

      {!data?.studiesGroupedByStudyType ||
      !genderData?.studiesGroupedByGender ? null : (
        <Box display="flex" marginTop={8} gap={8} flexDirection="column">
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
            <Table<StudyByStudyTypeTableRow>
              data={data?.studiesGroupedByStudyType.map((row) => ({
                ...row,
                id: uniqueId(),
              }))}
              columnConfig={COLUMNS}
              title="Podział na typy badań"
              pagination={false}
              manualSortBy={false}
              dense
            />
            <Chart
              options={{
                labels: data?.studiesGroupedByStudyType.map(
                  ({ studyType }) => studyType as string,
                ),
                legend: {
                  show: false,
                },
              }}
              series={data?.studiesGroupedByStudyType.map(({ count }) => count)}
              type="pie"
              width={600}
            />
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
            <Table<StudyByGenderTableRow>
              data={genderData?.studiesGroupedByGender.map((row) => ({
                ...row,
                id: uniqueId(),
              }))}
              columnConfig={GENDER_COLUMNS}
              title="Podział na płeć"
              pagination={false}
              manualSortBy={false}
              dense
            />
            <Chart
              options={{
                labels: genderData?.studiesGroupedByGender.map(({ gender }) =>
                  t(`fields.contact.gender.${gender || 'Unknown'}`),
                ),
                legend: {
                  show: false,
                },
              }}
              series={genderData?.studiesGroupedByGender.map(
                ({ count }) => count,
              )}
              type="pie"
            />
          </Box>
        </Box>
      )}
    </>
  );
};
