import { Box, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import React from 'react';

type Props = {
  text: string;
};

export const InfoTooltip: React.FC<Props> = ({ text }) => {
  return (
    <Box p={4} display="inline">
      <Tooltip title={text}>
        <HelpOutline fontSize="small" />
      </Tooltip>
    </Box>
  );
};
