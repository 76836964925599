import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { get, isEmpty } from 'lodash-es';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import React from 'react';
import { useDebounce } from 'use-debounce';
import * as yup from 'yup';
import { HideIfEmptyProps, SubmitOnChangeProps } from '../types';
import makeStyles from '@mui/styles/makeStyles';

export type AutocompleteItem = { id: string | number };

export type AsyncAutocompleteProps<
  T extends AutocompleteItem,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Partial<AutocompleteProps<T, Multiple, undefined, FreeSolo>> &
  Pick<AutocompleteProps<T, Multiple, undefined, FreeSolo>, 'getOptionLabel'> &
  HideIfEmptyProps &
  SubmitOnChangeProps & {
    loadData: (inputValue?: string) => Promise<T[]>;
    multiple?: Multiple;
    freeSolo?: FreeSolo;
    name: string;
    label: string | React.ReactNode;
    required?: boolean;
  };

yup.addMethod(yup.object, 'autocompleteRequired', function (
  this: yup.ObjectSchema<any>,
) {
  return this.test(
    'autocompleteRequired',
    'To pole jest wymagane',
    (value) => !!value?.id,
  );
});

yup.addMethod(yup.array, 'autocompleteMultipleRequired', function (
  this: yup.ArraySchema<any, any, any, any, any>,
) {
  return this.test(
    'autocompleteMultipleRequired',
    'Przynajmniej jedna wartość powinna być wybrana',
    (value: AutocompleteItem[]) =>
      !!(value?.length && value?.every((val) => !!val?.id)),
  );
});

/* type ValidationFunction = (
  value: AutocompleteItem | AutocompleteItem[],
) => string | boolean;

type ValidationDictionary = {
  [key in 'required' | 'maxElements']: (config?: any) => ValidationFunction;
};

export const validations: ValidationDictionary = {
  required: () => value => {
    const isValid = Array.isArray(value)
      ? !!value?.length && value?.every(val => val)
      : !!value?.id;

    return isValid || 'To pole jest wymagane';
  },
  maxElements: (maxElements: number) => value => {
    const isValid = Array.isArray(value) && value.length <= maxElements;

    return isValid || 'To pole posiada zbyt wiele wartości';
  },
}; */

const useStyles = makeStyles({
  root: {
    minWidth: '250px',
  },
});

export const AsyncAutocompleteField = <
  T extends AutocompleteItem,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AsyncAutocompleteProps<T, Multiple, FreeSolo>,
) => {
  const {
    required,
    loadData,
    multiple,
    freeSolo,
    label,
    name,
    submitOnChange,
    hideIfEmpty,
    ...autocompleteProps
  } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<T[]>([]);
  const [inputText, setInputText] = React.useState<string>('');
  const [debouncedInputText] = useDebounce(inputText, 500);
  const formContext = useFormContext();

  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      if (debouncedInputText || open) {
        setLoading(true);
        setOptions([]);
        try {
          const data = await loadData(debouncedInputText);
          setOptions(data || []);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [debouncedInputText, loadData, open]);

  const fieldValue = useWatch({
    control: formContext.control,
    name: props.name,
  });

  if (hideIfEmpty && props.disabled && isEmpty(fieldValue)) return null;

  return (
    <Controller
      name={props.name}
      control={formContext?.control}
      render={({ field, formState }) => (
        <Autocomplete<T, Multiple, undefined, FreeSolo>
          filterOptions={(options) => options}
          {...field}
          {...autocompleteProps}
          className={classes.root}
          multiple={multiple}
          freeSolo={freeSolo}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(event, value, reason, details) => {
            autocompleteProps?.onChange?.(event, value, reason, details);
            field.onChange(value);
            if (props.submitOnChange) {
              formContext.handleSubmit(props.submitOnChange)();
            }
          }}
          isOptionEqualToValue={(option, value) => {
            return option?.id === value?.id;
          }}
          options={options}
          value={multiple ? field?.value : field.value || null}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              label={label}
              variant="outlined"
              value={inputText}
              margin="dense"
              fullWidth
              onChange={(value) => setInputText(value.target.value)}
              error={!!get(formState?.errors, name)}
              helperText={get(formState?.errors, name)?.message as string}
              InputProps={{
                ...params.InputProps,
                required,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    ></Controller>
  );
};
