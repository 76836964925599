import { PostAdd } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router';
import {
  ActionColor,
  ItemActionHook,
} from '../../../../../common/types/actions';
import { Complaint, UserRole } from '../../../../../generated/graphql';

export type ComplaintActionType =
  | 'edit'
  | 'delete'
  | 'createReferal'
  | 'sendStudiesLink';
type ComplaintActionHook = ItemActionHook<Complaint, ComplaintActionType>;

export const useEditComplaintAction: ComplaintActionHook = () => {
  const history = useHistory();

  return {
    id: 'edit',
    Icon: EditIcon,
    title: 'Edytuj pacjenta',
    actionColor: ActionColor.ORANGE,
    roles: [UserRole.Admin, UserRole.Registration, UserRole.Technician],
    onAction: ({ id }) => history.push(`/complaints/${id}`),
  };
};

/* export const useDeleteComplaintAction: ComplaintActionHook = () => {
  const confirmRemoval = useRemoveConfirm();
  const snackBarError = useGraphQLError();
  const [deleteComplaint] = useDeleteComplaintMutation({
    onError: (error) => snackBarError(error),
    onCompleted: () =>
      enqueueSnackbar(`Pacjent został usunięty.`, {
        variant: 'success',
      }),
  });
  const { enqueueSnackbar } = useSnackbar();

  return {
    id: 'delete',
    Icon: DeleteIcon,
    title: 'Usuń pacjenta',
    actionColor: ActionColor.RED,
    roles: [UserRole.Admin, UserRole.Registration, UserRole.Technician],
    onAction: async ({ id }) => {
      await confirmRemoval();
      await deleteComplaint({ variables: { id } });
    },
  };
}; */

export const useCreateReferalAction: ComplaintActionHook = () => {
  const history = useHistory();

  return {
    id: 'createReferal',
    Icon: PostAdd,
    title: 'Utwórz skierowanie dla pacjenta',
    actionColor: ActionColor.BLUE,
    onAction: ({ id }) => history.push(`/referals/add?complaintId=${id}`),
    forbiddenRoles: [UserRole.DentalOffice],
  };
};
