import AddIcon from '@mui/icons-material/Add';
import { Box, Chip, Fab } from '@mui/material';
import React, { useMemo } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { createUseReducerPersisted } from 'use-persisted';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import { useTableActions } from '../../../../../common/components/table/hooks/useTableActions';
import { TableContext } from '../../../../../common/components/table/state/table.context';
import { tableReducer } from '../../../../../common/components/table/state/table.reducer';
import { HybridTableStorage } from '../../../../../common/components/table/state/table.storage';
import useDidMountEffect from '../../../../../common/hooks/useDidMountEffect';
import { toLocaleDateTime } from '../../../../../common/utils/date.utils';
import {
  Complaint,
  UserRole,
  useComplaintsQuery,
} from '../../../../../generated/graphql';
import { Protect } from '../../../components/auth/components/Protect';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import {
  useCreateReferalAction,
  useEditComplaintAction,
} from '../hooks/useComplaintActions';
import { ComplaintSearchPanel } from '../search/ComplaintSearch';
import { useStyles } from './ComplaintList.styles';

type Props = {};

const initialState = {
  orderBy: {
    columnName: 'updatedAt',
    columnOrder: 'DESC',
  },
  pagination: {
    take: 20,
    skip: 0,
  },
  filter: {},
};

const tableStorage = new HybridTableStorage();

const usePersistedReducer = createUseReducerPersisted(
  'complaintList',
  tableStorage,
);

const trimText = (text: string, maxLength = 50) =>
  text && text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

export const ComplaintsList: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [state, dispatch] = usePersistedReducer(
    tableReducer,
    HybridTableStorage.getInitialState('complaintList', initialState),
  );

  const { data, fetchMore } = useComplaintsQuery({
    variables: {
      where: state.filter,
      pagination: { skip: 0, take: state.pagination.take },
      orderBy: [state.orderBy],
    },
    context: { withGlobalLoading: true },
  });

  const editComplaint = useEditComplaintAction();
  //const deleteComplaint = useDeleteComplaintAction();
  const createReferal = useCreateReferalAction();

  const tableActions = useTableActions([createReferal, editComplaint]);

  type CellType = { row: { original: Complaint } };

  const columns = useMemo<SortableColumn<Complaint>[]>(
    () => [
      {
        id: 'complainantName',
        Header: 'Zgłaszający',
        accessor: 'complainantName',
      },
      {
        id: 'createdAt',
        Header: 'Data zgłoszenia',
        accessor: 'createdAt',
        Cell: ({ row: { original } }: CellType) =>
          toLocaleDateTime(original.createdAt, DateTime.DATETIME_SHORT),
      },
      {
        id: 'type',
        Header: 'Typ',
        accessor: ({ type }) => type,
        Cell: ({ row: { original } }: CellType) => (
          <Chip
            variant="outlined"
            label={original.type === 'Patient' ? 'Od Pacjenta' : 'Od Lekarza'}
          />
        ),
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }: CellProps<Complaint>) => (
          <Chip
            variant="outlined"
            label={original.status === 'Closed' ? 'Zamknięta' : 'W trakcie'}
            color={original.status === 'Closed' ? 'success' : 'warning'}
          />
        ),
      },
      {
        id: 'dueDate',
        Header: 'Termin',
        accessor: ({ dueDate, status }) =>
          //If overdue the color in red and bold
          DateTime.fromJSDate(new Date(dueDate)).toMillis() <
            DateTime.now().toMillis() && status !== 'Closed' ? (
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              {toLocaleDateTime(dueDate, DateTime.DATETIME_SHORT)}
            </span>
          ) : (
            toLocaleDateTime(dueDate, DateTime.DATETIME_SHORT)
          ),
      },
      {
        id: 'complaintReason',
        Header: 'Powód skargi',
        accessor: ({ complaintReason }) => trimText(complaintReason),
      },
      {
        id: 'complaintDescription',
        Header: 'Opis skargi',
        accessor: ({ complaintDescription }) => trimText(complaintDescription),
      },
      {
        id: 'responsiblePerson',
        Header: 'Odpowiedzialna osoba',
        accessor: ({ responsiblePerson }) =>
          responsiblePerson?.contact
            ? `${responsiblePerson?.contact.firstName} ${responsiblePerson.contact.lastName}`
            : 'Brak przypisanej osoby',
      },
      tableActions,
    ],
    [tableActions],
  );

  useDidMountEffect(() => {
    fetchMore({
      variables: {
        where: state.filter,
        pagination: state.pagination,
        orderBy: [state.orderBy],
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  }, [fetchMore, state.filter, state.pagination, state.orderBy]);

  return (
    <TableContext.Provider value={{ state, dispatch }}>
      <Box mb={8}>
        <ComplaintSearchPanel
          expanded={state.filtersExpanded}
          onSearch={({ responsiblePersonId, ...filter }) => {
            dispatch({
              type: 'FILTER_CHANGE',
              payload: {
                ...filter,
                responsiblePersonId,
              },
            });
          }}
          filters={state.filter}
        />
      </Box>
      <Table<Complaint>
        title="Lista reklamacji"
        columnConfig={columns}
        data={data?.complaints.items as Complaint[]}
        totalCount={data?.complaints.total || 0}
        onRowClicked={({ id }) => history.push(`/complaints/${id}`)}
        rowColor={({ original }) => {
          return DateTime.fromJSDate(new Date(original.dueDate)).toMillis() <
            DateTime.now().toMillis() && original.status !== 'Closed'
            ? 'rgb(255,0,0,0.08)'
            : undefined;
        }}
        emptyLabel={t('pages.complaint.list.emptyLabel')}
      />

      <Protect roles={[UserRole.Admin, UserRole.Technician]}>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          component={RouterLink}
          to="/complaints/add"
        >
          <AddIcon />
        </Fab>
      </Protect>
    </TableContext.Provider>
  );
};
