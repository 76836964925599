import React, { useState } from 'react';
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useGraphQLError } from '../../../../../../common/hooks/useGraphQLError';
import { useAcceptTermsMutation } from '../../../../../../generated/graphql';
import { RegulationsModal } from '../../../regulations/RegulationsDialog';
import { useOAuthConnect } from '../../oauth/useOAuthConnect';
import { OAuthState, useOAuthLogin } from '../../oauth/useOAuthLogin';
import GoogleLogo from './GoogleLogo.svg';
import { OAuthButton } from '../oauth-button/OAuthButton';

type Props = {
  connectToCurrentAccount?: boolean;
};

type Response = { access_token: string };

export type Profile = {
  name: string;
  given_name: string;
  family_name: string;
  email?: string;
  picture: string;
  iss: string;
  sub: string;
};

const createOAuthState = (
  googleInfo: { access_token: string } & Profile,
): OAuthState => {
  const { access_token } = googleInfo;

  return {
    token: access_token,
    firstName: googleInfo.given_name,
    lastName: googleInfo.family_name,
    login: googleInfo.email || '',
    type: 'google' as const,
  };
};

export const GoogleLoginButton: React.FC<Props> = ({
  connectToCurrentAccount,
}) => {
  const { t } = useTranslation();
  const [googleInfo, setGoogleInfo] = useState<Response & Profile>();
  const {
    loginMutation,
    regulationsOpen,
    regulationsRequired,
    setRegulationsOpen,
    proceedWithLogin,
  } = useOAuthLogin<Response & Profile>(googleInfo, createOAuthState);

  useGoogleOneTapLogin({
    onSuccess: async (response) => {
      if (!response.credential) return;

      const decodedProfile = jwtDecode<Profile>(response.credential);

      const client = google.accounts.oauth2.initTokenClient({
        client_id:
          response.clientId ||
          '1065393847252-bqbt4gspq53qnkppo3spcdo8d54um3la.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/userinfo.profile',
        hint: decodedProfile.email,
        prompt: 'none',
        callback: (tokenResponse) => {
          const context = {
            headers: {
              access_token: tokenResponse.access_token,
              oauth_type: 'google',
            },
          };

          setGoogleInfo({
            access_token: tokenResponse.access_token,
            ...decodedProfile,
          });

          if (connectToCurrentAccount) {
            connectOAuth({ context });
          } else {
            loginMutation({
              context,
            });
          }
        },
      });
      client.requestAccessToken();
    },
  });

  const login = useGoogleLogin({
    onSuccess: async (response: Response) => {
      if (!response) return;

      const data = (await fetch(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        },
      ).then((res) => res.json())) as Profile;

      setGoogleInfo({ ...response, ...data });

      const context = {
        headers: {
          access_token: response.access_token,
          oauth_type: 'google',
        },
      };

      if (connectToCurrentAccount) {
        connectOAuth({ context });
      } else {
        loginMutation({
          context,
        });
      }
    },
    onError: (error) => console.error(error),
    scope: 'email profile',
  });

  const snackBarError = useGraphQLError();
  const [acceptTermsMutation] = useAcceptTermsMutation({
    onError: (error) => snackBarError(error),
  });

  const { connectOAuth } = useOAuthConnect();

  return (
    <>
      <OAuthButton
        logo={GoogleLogo}
        text={t('login.actions.googleLoginButton') || 'Kontynuuj przez Google'}
        onClick={() => login()}
      />
      <RegulationsModal
        onAccept={
          regulationsRequired
            ? async () => {
                await acceptTermsMutation();
                proceedWithLogin();
              }
            : undefined
        }
        open={regulationsOpen}
        onClose={() => setRegulationsOpen(false)}
      />
    </>
  );
};
