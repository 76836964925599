import {
  Button,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import { ExitToApp } from '@mui/icons-material';

import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { CenteredPage } from '../../../../../common/components/centered-page/CenteredPage';
import { LanguageSelector } from '../../../../../common/components/lang-selector/LanguageSelector';

import { isTokenExpiredError } from '../../../../../common/containers/apollo/GlobalErrorHandler';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import { useRecaptcha } from '../../../../../common/hooks/useRecaptcha';
import { toLocaleDateTime } from '../../../../../common/utils/date.utils';
import {
  usePatientStudiesMutation,
  useResendPatientStudiesEmailMutation,
} from '../../../../../generated/graphql';

import { useSendPatientStudiesEmailMutation } from '../../../../../generated/graphql';
import { TopBarLogo } from '../../../components/top-bar/logo/TopBarLogo';
import {
  PatientStudyCard,
  PatientStudyCardSkeleton,
} from '../components/patient-study-card/PatientStudyCard';
import { PatientStudyForm } from '../components/PatientStudyForm';
import { PatientStudyInput } from '../types';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '80vw',
      minWidth: '50vw',
    },
    margin: theme.spacing(8),
  },
}));

export const PatientStudiesPage: React.FC = () => {
  const snackBarError = useGraphQLError(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const queryParams = useQueryParams();
  const patientJWTToken = queryParams.get('token') || '';
  const history = useHistory();

  const { t } = useTranslation();

  const { setAuthInfo, getAuthInfo } = useAuth();

  const [resendLink] = useResendPatientStudiesEmailMutation({
    onError: snackBarError,
  });

  const [
    sendLink,
    { error: sendLinkError, called: sendLinkCalled, loading: sendLinkLoading },
  ] = useSendPatientStudiesEmailMutation({ onError: snackBarError });

  const resendStudiesEmail = useCallback(async () => {
    await resendLink();
    enqueueSnackbar(t('pages.patient-studies.messages.after-send-link'), {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, [enqueueSnackbar, resendLink, t]);

  const [loadStudies, { data, loading }] = usePatientStudiesMutation({
    onError: (error) => {
      if (isTokenExpiredError(error)) {
        const snackKey = enqueueSnackbar(
          t('pages.patient-studies.messages.expired-auth'),
          {
            variant: 'warning',
            action: (
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  closeSnackbar(snackKey);
                  resendStudiesEmail();
                }}
              >
                {t('pages.patient-studies.actions.resend-link')}
              </Button>
            ),
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            persist: true,
          },
        );
      }
      return snackBarError(error);
    },
  });

  useEffect(() => {
    if (patientJWTToken) {
      setAuthInfo({
        token: patientJWTToken,
        login: '',
        roles: [],
        id: 0,
      });
    }
    history.replace('/patient-studies');
  }, [history, patientJWTToken, setAuthInfo]);

  const classes = useStyles();

  const { executeRecaptcha } = useRecaptcha();

  const onSubmit = async (data: PatientStudyInput) => {
    const token = await executeRecaptcha?.('patientStudies');
    const context = {
      headers: {
        recaptcha: token,
      },
    };

    if (!getAuthInfo().token) {
      await sendLink({
        variables: data,
        context,
      });
    } else {
      loadStudies({ variables: { socialSecurityNumber: data.ssn }, context });
    }
  };

  const logout = () => {
    window.localStorage.removeItem('auth');
    history.push('/patient-studies-logout');
  };

  return (
    <CenteredPage>
      {!data && !loading ? (
        <PatientStudyForm
          onSubmit={onSubmit}
          isSubmitted={
            !sendLinkError?.message && sendLinkCalled && !sendLinkLoading
          }
        />
      ) : (
        <Box
          display="flex"
          gap={8}
          flexDirection="column"
          className={classes.root}
        >
          <Paper>
            <Box display="flex">
              <Box
                display="flex"
                flexDirection="column"
                gap={6}
                padding={8}
                marginRight="auto"
                justifyContent="center"
                flex={1}
              >
                <Typography variant="h5">
                  {t('pages.patient-studies.title')}
                </Typography>
                {data?.patientStudies && (
                  <Box
                    display="flex"
                    flexDirection={{ sm: 'column', md: 'row' }}
                    gap={8}
                  >
                    <span>
                      <Typography variant="caption">
                        {t('pages.patient-studies.labels.patient')}
                      </Typography>
                      <Typography variant="body2">
                        {data?.patientStudies.patientName}
                      </Typography>
                    </span>
                    <span>
                      <Typography variant="caption">
                        {t('pages.patient-studies.labels.birth-date')}
                      </Typography>
                      <Typography variant="body2">
                        {data?.patientStudies.patientBirthDate &&
                          toLocaleDateTime(
                            data?.patientStudies.patientBirthDate,
                            DateTime.DATE_SHORT,
                          )}
                      </Typography>
                    </span>
                  </Box>
                )}
              </Box>
              <Hidden mdDown>
                <Box
                  display="flex"
                  marginRight="auto"
                  justifyContent="center"
                  flex={1}
                >
                  <TopBarLogo alt="logo" src="/img/logo.svg" />
                </Box>
              </Hidden>
              <Box
                display="flex"
                marginLeft="auto"
                justifyContent="flex-end"
                alignItems="flex-start"
                flex={1}
              >
                <Box mt={6}>
                  <LanguageSelector />
                </Box>

                <Tooltip title={t('pages.patient-studies.actions.logout')}>
                  <IconButton
                    onClick={() => logout()}
                    aria-label="logout"
                    color="primary"
                    size="large"
                  >
                    <ExitToApp fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Paper>
          <Grid container spacing={4}>
            {data?.patientStudies?.studies
              ? data.patientStudies.studies.map((patientStudy) => (
                  <Grid item xs={12} md={6} key={patientStudy.id}>
                    <PatientStudyCard key={patientStudy.id} {...patientStudy} />
                  </Grid>
                ))
              : [...Array(4)].map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item xs={12} md={6} key={index}>
                    <PatientStudyCardSkeleton />
                  </Grid>
                ))}
          </Grid>
        </Box>
      )}
    </CenteredPage>
  );
};
