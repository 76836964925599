import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useSnackbar } from 'notistack';
import { useGetDownloadUrlQuery } from '../../generated/graphql';
import { useAuth } from './useAuth';
import { useState } from 'react';
import { fetchWithProgress } from '../utils/network.utils';

export type FileDownloadOptions = {
  pathPrefix?: string;
};

export const useDirectFileDownload = ({
  pathPrefix,
}: FileDownloadOptions = {}) => {
  const { getAuthInfo } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  return async (pathSuffix: string, filename: string) => {
    try {
      const url = `${
        pathPrefix || process.env.REACT_APP_API_ENDPOINT
      }${pathSuffix}`;
      const authHeader = `Bearer ${getAuthInfo().token}`;
      await downloadFile(url, filename, {
        headers: { Authorization: authHeader },
      });
    } catch (err: any) {
      if (err.message === 'no_data_found') {
        enqueueSnackbar('Brak pasujących danych', { variant: 'warning' });
      } else {
        enqueueSnackbar(`Błąd podczas pobierania pliku: ${err}`, {
          variant: 'error',
        });
      }
    }
  };
};

export const useDownloadFromURL = (fileURI: string, studyId: number) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useGetDownloadUrlQuery({
    variables: { filePath: fileURI, studyId },
  });

  return async (filename: string) => {
    try {
      await downloadFile(
        fileURI.startsWith('http') ? fileURI : data?.getDownloadURL || '',
        filename,
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Wystąpił błąd podczas pobierania pliku', {
        variant: 'error',
      });
    }
  };
};

export const useDownloadAndExtractFromURL = (
  fileURI: string,
  studyId: number,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useGetDownloadUrlQuery({
    variables: { filePath: fileURI, studyId },
  });

  const [progress, setProgress] = useState(0);
  const [currentTask, setCurrentTask] = useState('');
  const [open, setOpen] = useState(false);

  return {
    onDownload: async () => {
      try {
        const dirHandle = await window.showDirectoryPicker();
        const hasPermission = await verifyPermission(dirHandle, true);
        if (!hasPermission) {
          throw new Error(
            'User did not grant permission to write to the file system',
          );
        }

        setOpen(true);
        setCurrentTask('Pobieranie danych...');

        const zipBlob = await downloadZip(
          fileURI.startsWith('http') ? fileURI : data?.getDownloadURL || '',
          setProgress,
        );

        setCurrentTask('Rozpakowywanie...');
        const files = await extractZip(zipBlob);

        await saveExtractedFiles(dirHandle, files, setProgress);

        enqueueSnackbar('Pliki zostały pomyślnie rozpakowane', {
          variant: 'success',
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar('Wystąpił błąd podczas pobierania pliku', {
          variant: 'error',
        });
      } finally {
        setOpen(false);
      }
    },
    progress,
    openProgress: open,
    currentTask,
  };
};

const downloadFile = async (url: string, filename: string, options?: any) => {
  const response = await fetch(url, options);
  if (response.status === 404) {
    throw new Error('no_data_found');
  }
  if (!response.ok) {
    throw new Error(`download_error: ${await response.text()}`);
  }
  const blob = await response.blob();
  if (!blob.size) {
    throw new Error(`file_corrupted: ${await response.text()}`);
  }
  saveAs(blob, filename);
};

async function extractZip(blob: Blob) {
  const zip = await JSZip.loadAsync(blob);
  const files: { relativePath: string; zipEntry: JSZip.JSZipObject }[] = [];
  zip.forEach((relativePath, zipEntry) => {
    files.push({ relativePath, zipEntry });
  });
  return files;
}

async function verifyPermission(
  fileHandle: FileSystemHandle,
  withWrite: boolean,
) {
  const opts = {} as any;
  if (withWrite) {
    opts.mode = 'readwrite';
  }

  // Check if we already have permission, if so, return true.
  if ((await fileHandle.queryPermission(opts)) === 'granted') {
    return true;
  }

  // Request permission to the file, if the user grants permission, return true.
  if ((await fileHandle.requestPermission(opts)) === 'granted') {
    return true;
  }

  // The user did not grant permission, return false.
  return false;
}

async function saveExtractedFiles(
  dirHandle: FileSystemDirectoryHandle,
  files: { relativePath: string; zipEntry: JSZip.JSZipObject }[],
  setProgress: (progress: number) => void,
) {
  const totalEntries = files.length;
  let processedEntries = 0;

  const directoryCache = new Map();

  const getDirectoryHandle = async (
    currentDirHandle: FileSystemDirectoryHandle,
    segment: string,
  ) => {
    const cacheKey = currentDirHandle + '/' + segment;
    if (!directoryCache.has(cacheKey)) {
      const dirHandle = await currentDirHandle.getDirectoryHandle(segment, {
        create: true,
      });
      directoryCache.set(cacheKey, dirHandle);
    }
    return directoryCache.get(cacheKey);
  };

  const processFile = async (
    relativePath: string,
    zipEntry: JSZip.JSZipObject,
  ) => {
    const pathSegments = relativePath.split('/').filter((a) => !!a);
    let currentDirHandle = dirHandle;

    for (let i = 0; i < pathSegments.length; i++) {
      const segment = pathSegments[i].replace(/[^\x20-\x7F]/g, '');
      const isLastSegment = i === pathSegments.length - 1;

      if (isLastSegment) {
        if (zipEntry.dir) {
          // Create directory
          await getDirectoryHandle(currentDirHandle, segment);
        } else {
          // Create and write to file
          const fileHandle = await currentDirHandle.getFileHandle(segment, {
            create: true,
          });

          const writable = await fileHandle.createWritable();
          const content = await zipEntry.async('blob');
          await writable.write(content);
          await writable.close();
        }
      } else {
        // Navigate to the next directory
        currentDirHandle = await getDirectoryHandle(currentDirHandle, segment);
      }
    }

    processedEntries++;
    if (processedEntries % 10 === 0 || processedEntries === totalEntries) {
      const currentProgress = Math.round(
        (processedEntries / totalEntries) * 100,
      );
      setProgress(currentProgress);
    }
  };

  // Process files in parallel with a higher limit to improve performance
  const BATCH_SIZE = 50;
  for (let i = 0; i < files.length; i += BATCH_SIZE) {
    const batch = files.slice(i, i + BATCH_SIZE);
    await Promise.all(
      batch.map(({ relativePath, zipEntry }) =>
        processFile(relativePath, zipEntry),
      ),
    );
  }

  /* if (openLocally) {
    // Get the first *.dcm file in the dirHandle passed as an argument recursively
    const firstDicomFilePath = await getFirstDicomFileWithPath(dirHandle);

    if (firstDicomFilePath) {
      const path = 'F:\\Badania\\' + firstDicomFilePath.path;
      const encodedPath = encodeURIComponent(path);
      window.location.href = 'open-file://' + encodedPath;
    }
  } */
}

/* async function getFirstDicomFileWithPath(
  dirHandle: FileSystemDirectoryHandle,
  currentPath: string = '',
): Promise<{ fileHandle: FileSystemFileHandle; path: string } | null> {
  for await (const entry of dirHandle.values()) {
    // Create the new path, including the current directory or file name
    const newPath = currentPath ? `${currentPath}\\${entry.name}` : entry.name;

    if (entry.kind === 'file' && entry.name.endsWith('.dcm')) {
      return { fileHandle: entry, path: newPath };
    } else if (entry.kind === 'directory') {
      // Recursively search in the subdirectory
      const result = await getFirstDicomFileWithPath(entry, newPath);
      if (result) {
        return result;
      }
    }
  }
  return null; // Return null if no .dcm file is found
} */

async function downloadZip(
  url: string,
  setProgress: (progress: number) => void,
) {
  const response = await fetchWithProgress(url, setProgress);
  if (!response?.ok) throw new Error('Network response was not ok');
  return await response.blob();
}
