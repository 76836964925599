//import { DevTool } from '@hookform/devtools';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  FormProvider,
  useForm,
  UseFormProps,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import * as yup from 'yup';
import { useStyles } from './Form.styles';
import localePL from './validation/yup-locale';

yup.setLocale(localePL);

export type FormProps<T extends FieldValues> = {
  onSubmit: (data: T, formMethods: UseFormReturn<T>) => void;
  dataTestid?: string;
  formOptions?: UseFormProps<T>;
  noElevation?: boolean;
  inline?: boolean;
  showLeaveUnsavedPrompt?: boolean;
  title?: string;
  heading?: React.ReactNode;
  render?: (formMethods: UseFormReturn<T>) => React.ReactNode;
  watchFields?: (keyof T)[];

  /** 
     In cases when we don't want all the form fields to be reset after submit we can define the useForm hook 
     in the component that declares the Form and pass it as argument 
  */
  useFormReturn?: UseFormReturn<T>;
};

export const Form = <T extends FieldValues>({
  children,
  onSubmit,
  formOptions = {},
  dataTestid,
  noElevation,
  inline,
  showLeaveUnsavedPrompt = true,
  title,
  heading,
  render,
  watchFields = [],
  useFormReturn,
}: React.PropsWithChildren<FormProps<T>>) => {
  const formMethods = useForm<T>({
    ...formOptions,
    mode: 'onBlur',
  });

  const [showPrompt, setShowPrompt] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (showLeaveUnsavedPrompt) setShowPrompt(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, [showLeaveUnsavedPrompt]);

  const useFormResult = useFormReturn || formMethods;

  const { handleSubmit, reset } = useFormResult;

  const classes = useStyles({ noElevation, inline });
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useWatch({
    control: useFormResult.control as Control,
    name: watchFields as string[],
  });

  useEffect(() => {
    if (formOptions.defaultValues) {
      reset(formOptions.defaultValues as any, {
        keepDirty: true,
        keepDirtyValues: true,
        keepIsSubmitted: true,
        keepSubmitCount: true,
      });
    }
  }, [formOptions.defaultValues, reset]);

  const Form = (
    <>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
        {title && (
          <Typography noWrap variant="h6" className={classes.title}>
            {title}
          </Typography>
        )}
        {heading}
      </Box>
      <FormProvider {...useFormResult}>
        {showPrompt && (
          <Prompt
            when={
              showLeaveUnsavedPrompt &&
              useFormResult.formState.isDirty &&
              !(
                useFormResult.formState.isSubmitting ||
                useFormResult.formState.isSubmitted ||
                useFormResult.formState.submitCount > 0
              )
            }
            message={t('app.dirtyNavigationConfirm')}
          />
        )}

        <form
          {...(dataTestid ? { 'data-testid': dataTestid } : {})}
          onSubmit={(event) => {
            event.stopPropagation();
            event.preventDefault();
            handleSubmit(((data: T) => onSubmit(data, useFormResult)) as any)(
              event,
            );
          }}
          className={classes.form}
          noValidate
          autoComplete="off"
        >
          {render ? render(useFormResult) : children}
        </form>
        {/* {process.env.NODE_ENV === 'development' && (
          <DevTool control={useFormResult.control as any} />
        )} */}
      </FormProvider>
    </>
  );

  return noElevation ? (
    <div className={classes.root}>{Form}</div>
  ) : (
    <Paper className={classes.root}>{Form}</Paper>
  );
};
