import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContactCard,
  ContactCardSkeleton,
} from '../../../../../common/components/contact-card/ContactCard';
import { useHelpUserContactsQuery } from '../../../../../generated/graphql';
import axios from 'axios';

type Props = {};

interface YoutubePlaylistItem {
  kind: string;
  etag: string;
  id: string;
  snippet: {
    publishedAt: string;
    channelId: string;
    title: string;
    description: string;
    thumbnails: {
      default: {
        url: string;
        width: number;
        height: number;
      };
      medium: {
        url: string;
        width: number;
        height: number;
      };
      high: {
        url: string;
        width: number;
        height: number;
      };
      standard: {
        url: string;
        width: number;
        height: number;
      };
      maxres: {
        url: string;
        width: number;
        height: number;
      };
    };
    channelTitle: string;
    playlistId: string;
    position: number;
    resourceId: {
      kind: string;
      videoId: string;
    };
    videoOwnerChannelTitle: string;
    videoOwnerChannelId: string;
  };
  contentDetails: {
    videoId: string;
    videoPublishedAt: string;
  };
}

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
  },
}));

const fetchVideos = async () => {
  const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
  const playlistId = process.env.REACT_APP_YOUTUBE_PLAYLIST_ID;
  if (!apiKey && !playlistId) return;

  const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&playlistId=${playlistId}&key=${apiKey}`;

  const { data } = await axios.get(url);

  return data.items;
};

export const HelpPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: helpUsers } = useHelpUserContactsQuery({
    variables: {
      count: 16,
    },
  });

  const [videos, setVideos] = useState<YoutubePlaylistItem[]>([]);
  useEffect(() => {
    fetchVideos().then((data) => setVideos(data));
  }, []);

  const softwareInfo = [
    {
      name: 'TeamViewer',
      image:
        'https://www.lekarz.diagdent.pl/obrazki/image/portal_lekarza/teamviewer.png',
      url: 'https://www.teamviewer.com/pl/do-pobrania/windows/',
    },
    {
      name: 'Program RAYFace',
      image:
        'https://www.lekarz.diagdent.pl/obrazki/image/ikony/basicblue-min.png',
      url: 'https://www.diagdent.pl/RAYFace_v2.1.1.26_Setup.zip',
    },
    {
      name: 'Przeglądarka Exocad View',
      image:
        'https://www.lekarz.diagdent.pl/obrazki/image/ikony/exocad_icon.png',
      url: 'https://exocad.com/our-products/exocad-webview',
    },
    {
      name: 'Przeglądarka do zdjęć 2D',
      image: 'https://www.lekarz.diagdent.pl/obrazki/image/ikony/icon1.png',
      url: 'https://www.lekarz.diagdent.pl/obrazki/file/dicom-processor.zip',
    },
    {
      name: 'Przeglądarka do zdjęć 3D (Win 10/11 64bit)',
      image: 'https://www.lekarz.diagdent.pl/obrazki/image/ikony/icon1.png',
      url: 'https://www.diagdent.pl/CS3DSuite.exe',
    },
    {
      name: 'Przeglądarka do zdjęć 3D (Win 7 32/64 bit)',
      image: 'https://www.lekarz.diagdent.pl/obrazki/image/ikony/icon1.png',
      url:
        'https://www.lekarz.diagdent.pl/obrazki/file/CS3DSuite-3.5.18-x86.exe_.zip',
    },
    {
      name: 'WinRAR 64bit',
      image: 'https://www.lekarz.diagdent.pl/obrazki/image/ikony/winrar_x.png',
      url: 'https://www.lekarz.diagdent.pl/obrazki/file/wrar560pl.exe',
    },
    {
      name: 'WinRAR 32bit',
      image: 'https://www.lekarz.diagdent.pl/obrazki/image/ikony/winrar_x.png',
      url: 'https://www.lekarz.diagdent.pl/obrazki/file/winrar-x64-560pl.exe',
    },
    {
      name: 'Informacje o RODO',
      image: 'https://www.lekarz.diagdent.pl/icon/pdf.png',
      url:
        'https://www.lekarz.diagdent.pl/obrazki/file/klauzula-inf-dla-pacjentow-diagdentpdf.pdf',
    },
  ];

  return (
    <Paper>
      <Box p={8}>
        <Typography variant="h6">{t('pages.help.title')}</Typography>
        <Box m={8} width="100%">
          <Grid container spacing={8}>
            {helpUsers?.helpUserContacts?.length
              ? helpUsers?.helpUserContacts?.map((user) => (
                  <Grid item md={12} lg={6}>
                    <ContactCard
                      key={user.lastName}
                      contact={user}
                      className={classes.card}
                      profilePictureURL={user.profilePictureURL}
                    />
                  </Grid>
                ))
              : [...Array(4)].map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item xs={12} md={6} key={index}>
                    <ContactCardSkeleton />
                  </Grid>
                ))}
          </Grid>
        </Box>
        <Box>
          <Typography variant="h6">
            {t('pages.help.additionalInformation')}
          </Typography>
          <Box p={8}>
            <Grid container spacing={4}>
              {softwareInfo.map(({ name, image, url }) => (
                <Grid item xs={12} md={6} lg={4} key={name}>
                  <Link
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <Box my={4} height={60}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                          <img src={image} alt={name} width={50} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="button">{name}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box mt={8}>
          <Typography variant="h6">{t('pages.help.videos')}</Typography>
          <Box mt={8}></Box>
          <Grid container spacing={8}>
            {videos?.map((item) => (
              <Grid item xs={12} sm={8} lg={6} xl={4} key={item.id}>
                <iframe
                  width={420}
                  height={315}
                  src={`https://www.youtube.com/embed/${item.contentDetails.videoId}`}
                  title={item.snippet.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};
