import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import clsx from 'clsx';
import React, { PropsWithChildren, useContext } from 'react';
import { useStyles } from './Toolbar.styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import { TableContext } from '../state/table.context';
import { ToggleButton } from '@mui/material';

interface TableToolbarProps {
  title: string;
  selectedRows?: any; // TODO Record<IdType<any>, boolean> ?;
  hasCollapsableFilters?: boolean;
}

export const TableToolbar: React.FC<PropsWithChildren<TableToolbarProps>> = (
  props,
) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(TableContext);
  const {
    title,
    selectedRows,
    hasCollapsableFilters = false,
    children,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selectedRows?.length > 0,
      })}
    >
      {selectedRows?.length > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {selectedRows?.length} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {title}
        </Typography>
      )}
      {selectedRows?.length > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        hasCollapsableFilters && (
          <Tooltip title="Pokaz/Schowaj Filtry">
            <ToggleButton
              value="filters"
              selected={state.filtersExpanded}
              onChange={() => dispatch({ type: 'TOGGLE_FILTERS' })}
            >
              <FilterListIcon />
            </ToggleButton>
          </Tooltip>
        )
      )}
      {children}
    </Toolbar>
  );
};
