import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { handlePickersModalClick } from '../../../../../common/utils/modal.utils';
import {
  Patient,
  PatientInput,
  useGetPatientQuery,
} from '../../../../../generated/graphql';
import { EMPTY_PATIENT, PatientForm } from '../form/PatientForm';
import { useAddOrEditPatient } from '../hooks/useAddOrEditPatient';

type Props = {
  open: boolean;
  onClose: (patient?: PatientInput) => void;
  patient?: Patient;
};

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '80%',
  },
  scrollPaper: {
    alignItems: 'end',
  },
}));

export const PatientAddDialog: React.FC<Props> = ({
  open,
  onClose,
  patient,
}) => {
  const { t } = useTranslation();

  const { data } = useGetPatientQuery({
    variables: {
      id: patient?.id as number,
      withStudies: false,
    },
    skip: !patient?.id,
  });

  const { addOrEditPatient } = useAddOrEditPatient();

  const onSubmit = async (patientInput: PatientInput) => {
    const savedPatient = await addOrEditPatient(patientInput);
    onClose(savedPatient);
  };

  const classes = useStyles();

  let formPatient;
  if (data?.patient) {
    const { createdById, updatedById, ...patientWithoutIds } = data.patient;
    formPatient = patientWithoutIds;
  } else {
    formPatient = EMPTY_PATIENT();
  }

  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="add-patient-dialog-title"
      open={open}
      disableEnforceFocus
      classes={{ paper: classes.paper, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>{t('dialogs.patient.add.title')}</DialogTitle>
      {/* handlePickersModalClick below and disabledEnforceFocus above are workarounds 
          for https://github.com/mui-org/material-ui-pickers/issues/1852, 
          to be removed when upgraded to MUI v5 */}
      <DialogContent onClick={handlePickersModalClick}>
        <PatientForm onSubmit={onSubmit} patient={formPatient} noElevation>
          <Button type="button" color="secondary" onClick={() => onClose()}>
            {t('app.common.actions.cancel')}
          </Button>
        </PatientForm>
      </DialogContent>
    </Dialog>
  );
};
