import { Grid } from '@mui/material';
import React from 'react';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { SearchPanel } from '../../../../../common/components/search/SearchPanel';
import { SearchDateTextField } from '../../../../../common/components/search/fields/date/SearchDateTextField';

import { SelectField } from '../../../../../common/components/form/fields/SelectField';
import {
  ComplaintFiltersInput,
  UserRole,
} from '../../../../../generated/graphql';
import { UserAutocomplete } from '../../users/fields/UserAutocomplete';

type Props = {
  onSearch: (filter: ComplaintFiltersInput) => void;
  filters?: ComplaintFiltersInput;
  expanded: boolean;
};

export const DEFAULTS = {
  complainantName: '',
  status: null,
  complaintReason: '',
  complaintDateFrom: null,
  complaintDateTo: null,
  responsiblePersonId: null,
};

export const ComplaintSearchPanel: React.FC<Props> = ({
  expanded,
  onSearch,
  filters = { ...DEFAULTS },
}) => {
  return (
    <SearchPanel
      expanded={expanded}
      onSearch={onSearch}
      defaultValues={{ ...filters }}
      resetValues={{ ...DEFAULTS }}
      mainFields={
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md="auto">
            <TextField
              fullWidth
              name="complainantName"
              label="Imię lub nazwisko"
              submitOnChange={onSearch}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <SelectField
              name="status"
              label="Status reklamacji"
              options={[
                { value: 'InProgress', label: 'W trakcie' },
                { value: 'Closed', label: 'Zamknięta' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <TextField
              fullWidth
              name="complaintReason"
              label="Powód reklamacji"
              submitOnChange={onSearch}
            />
          </Grid>

          <Grid item xs={12} md="auto">
            <SearchDateTextField
              fromName="complaintDateFrom"
              toName="complaintDateTo"
              submitOnChange={onSearch}
              label="Data zgłoszenia"
            />
          </Grid>

          <Grid item xs={12} md="auto">
            <UserAutocomplete
              role={UserRole.Complaints}
              controllerProps={{
                required: true,
                label: 'Osoba odpowiedzialna',
                name: 'responsiblePerson',
              }}
            />
          </Grid>
        </Grid>
      }
    />
  );
};
