import React from 'react';

import { Grid, FormControl, FormLabel, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextField } from './TextField';
import { Address } from '../../../../generated/graphql';

export const EMPTY_ADDRESS: Partial<Address> = {
  street: '',
  city: '',
  postalCode: '',
  country: 'PL',
};

type Props = {
  label: string;
  addressFieldName: string;
  showCountry?: boolean;
  disabled?: boolean;
};

export const AddressField: React.FC<Props> = ({
  addressFieldName,
  label,
  showCountry = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <Box minWidth={400}>
        <Grid container direction="column">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                label={t('fields.address.street')}
                name={`${addressFieldName}.street`}
                disabled={disabled}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label={t('fields.address.postalCode')}
                name={`${addressFieldName}.postalCode`}
                disabled={disabled}
              ></TextField>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            label={t('fields.address.city')}
            name={`${addressFieldName}.city`}
            disabled={disabled}
          ></TextField>

          <Box display={showCountry ? 'block' : 'none'}>
            <TextField
              fullWidth
              label={t('fields.address.country')}
              name={`${addressFieldName}.country`}
              disabled={disabled}
            ></TextField>
          </Box>
        </Grid>
      </Box>
    </FormControl>
  );
};
