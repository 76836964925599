import { Fab, Tooltip } from '@mui/material';
import {
  AssignmentTurnedInOutlined,
  BuildOutlined,
  PaymentOutlined,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import { createUseReducerPersisted } from 'use-persisted';
import { TableCellLink } from '../../../../../common/components/table/cell/TableCellLink';
import { useTableActions } from '../../../../../common/components/table/hooks/useTableActions';
import { TableContext } from '../../../../../common/components/table/state/table.context';
import { tableReducer } from '../../../../../common/components/table/state/table.reducer';
import { HybridTableStorage } from '../../../../../common/components/table/state/table.storage';
import {
  SortableColumn,
  Table,
} from '../../../../../common/components/table/Table';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';
import useDidMountEffect from '../../../../../common/hooks/useDidMountEffect';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { ActionColor } from '../../../../../common/types/actions';
import { ColumnVisibilityConfig } from '../../../../../common/types/table';
import { toLocaleDateTime } from '../../../../../common/utils/date.utils';
import { filterColumns } from '../../../../../common/utils/table.utils';
import {
  Study,
  StudyStatus,
  useDeleteStudyMutation,
  UserRole,
  useStudiesQuery,
} from '../../../../../generated/graphql';
import { Protect } from '../../../components/auth/components/Protect';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { useTableRefresh } from '../../../../../common/hooks/useTableRefresh';
import {
  convertFromFormModel,
  FORM_DEFAULTS,
  StudySearchPanel,
} from '../search/StudySearch';
import { DoctorCell } from './components/DoctorCell';
import { StudyListDescriptionIcon } from './components/StudyListDescriptionIcon';
import { StudyListDescriptionTime } from './components/StudyListDescriptionTime';
import { useStyles } from './StudyList.styles';
import { useShareStudyAction } from '../actions/share';

const SHARED_STUDY_COLOR = 'rgb(25,118,210,0.05)';
const DRAFT_STUDY_COLOR = 'rgb(210,25,25,0.05)';
const PAID_STUDY_COLOR = 'rgb(210,210,25,0.05)';

type Props = {};

const initialState = {
  orderBy: {
    columnName: 'updatedAt',
    columnOrder: 'DESC',
  },
  pagination: {
    take: 20,
    skip: 0,
  },
  filter: convertFromFormModel(FORM_DEFAULTS),
  filtersExpanded: false,
  lastSelectedRowId: 0,
};

const usePersistedReducer = createUseReducerPersisted(
  'studyList',
  new HybridTableStorage(),
);

const COLUMN_VISIBLE_FOR: ColumnVisibilityConfig = {
  'branch.name': [
    UserRole.Admin,
    UserRole.Technician,
    UserRole.DescribingDoctor,
    UserRole.Manager,
  ],
  'studyDescribers.dueDate': [
    UserRole.Admin,
    UserRole.DescribingDoctor,
    UserRole.Technician,
    UserRole.Manager,
  ],
};

export const StudyList: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [state, dispatch] = usePersistedReducer(
    tableReducer,
    HybridTableStorage.getInitialState('studyList', initialState),
  );

  const { reloadTable } = useTableRefresh(dispatch);

  const { data, fetchMore } = useStudiesQuery({
    variables: {
      pagination: { skip: state.pagination.skip, take: state.pagination.take },
      where: state.filter,
      orderBy: [state.orderBy],
    },
    context: { withGlobalLoading: true },
    fetchPolicy: state.reload ? 'network-only' : 'cache-and-network',
  });

  const snackBarError = useGraphQLError();
  const { success } = useMessages();
  const [deleteStudy] = useDeleteStudyMutation({
    onError: snackBarError,
    onCompleted: () => success(),
  });

  const { enqueueSnackbar } = useSnackbar();
  const confirmRemoval = useRemoveConfirm();

  const { getAuthInfo } = useAuth();

  const [{ currentRole }] = useAppContextState();

  const shareAction = useShareStudyAction<Study>();
  const tableActions = useTableActions<Study, 'edit' | 'delete' | 'share'>([
    {
      id: 'edit',
      Icon: EditIcon,
      roles: [
        UserRole.Admin,
        UserRole.Technician,
        UserRole.Manager,
        UserRole.Registration,
      ],
      title: 'Edytuj badanie',
      actionColor: ActionColor.ORANGE,
      onAction: ({ id }) => history.push(`/studies/edit/${id}`),
    },
    {
      id: 'delete',
      Icon: DeleteIcon,
      roles: [
        UserRole.Admin,
        UserRole.Technician,
        UserRole.Manager,
        UserRole.Registration,
      ],
      title: 'Usuń badanie',
      actionColor: ActionColor.RED,
      visible: ({ status }) => {
        if (currentRole === UserRole.Registration) {
          return status === StudyStatus.Draft || status === StudyStatus.Paid;
        }
        return true;
      },
      onAction: async ({ id }) => {
        await confirmRemoval();
        await deleteStudy({
          variables: { id },
        });
        reloadTable();
        enqueueSnackbar(`Badanie zostało usunięte.`, {
          variant: 'success',
        });
      },
    },
    shareAction,
  ]);

  const columns = useMemo<SortableColumn<Study>[]>(
    () => [
      /*{
        id: 'id',
        Header: 'Numer',
        accessor: ({ id }) => id,
        Cell: ({ value }: CellProps<Study, Study['id']>) => (
          <Link
            component="button"
            variant="body2"
            onClick={() => history.push(`/studies/view/${value}`)}
          >
            {value}
          </Link>
        ),
      },*/
      {
        id: 'status',
        Header: '',
        accessor: ({ status }) => status,
        Cell: ({ value }: CellProps<Study, Study['status']>) => {
          let IconComponent = null;

          switch (value) {
            case StudyStatus.Draft:
              IconComponent = <BuildOutlined />;
              break;
            case StudyStatus.Paid:
              IconComponent = <PaymentOutlined />;
              break;
            case StudyStatus.Performed:
              IconComponent = <AssignmentTurnedInOutlined />;
              break;
          }

          return (
            <Tooltip title={t(`fields.study.status.options.${value}`) || ''}>
              {IconComponent}
            </Tooltip>
          );
        },
        width: 30,
        maxWidth: 30,
      },
      {
        id: 'patientContact.lastName',
        Header: 'Nazwisko i imię pacjenta',
        accessor: ({ patient }) => patient,
        Cell: ({ value: patient }: CellProps<Study, Study['patient']>) => (
          <TableCellLink
            onClick={() => history.push(`/patients/view/${patient?.id}`)}
          >
            {patient?.contact?.lastName} {patient?.contact?.firstName}
          </TableCellLink>
        ),
      },
      {
        id: 'patientContact.birthDate',
        Header: 'Data urodzenia pacjenta',
        accessor: ({ patient }) => patient,
        Cell: ({ value: patient }: CellProps<Study, Study['patient']>) =>
          toLocaleDateTime(
            patient?.contact?.birthDate || undefined,
            DateTime.DATE_SHORT,
          ),
      },
      {
        id: 'studyDate',
        Header: 'Data badania',
        accessor: ({ studyDate }) => studyDate,
        Cell: ({ value }: CellProps<Study, Study['studyDate']>) =>
          toLocaleDateTime(value),
      },
      {
        id: 'studyType.name',
        Header: 'Typ',
        accessor: ({ studyType }) => studyType.name,
      },
      {
        id: 'leadingDoctorUserContact.lastName',
        Header: 'Lekarz',
        accessor: ({ leadingDoctor }) => leadingDoctor?.user.contact.lastName,
        Cell: ({ row: { original } }: CellProps<Study>) => {
          return <DoctorCell study={original} />;
        },
      },
      {
        id: 'branch.name',
        Header: 'Filia',
        accessor: ({ branch }) => branch?.name,
      },
      {
        id: 'descriptionProblems',
        Header: 'Opis',
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<Study>) => (
          <StudyListDescriptionIcon studyListItem={original} />
        ),
      },
      {
        id: 'studyDescribers.dueDate',
        Header: 'Termin opisu',

        accessor: ({ describers }) => describers,
        Cell: ({ row: { original } }: CellProps<Study>) => (
          <StudyListDescriptionTime studyListItem={original} />
        ),
      },
      tableActions,
    ],
    [history, t, tableActions],
  );

  const filteredColumns = currentRole
    ? filterColumns(columns, COLUMN_VISIBLE_FOR, currentRole)
    : [];

  useDidMountEffect(() => {
    fetchMore({
      variables: {
        where: state.filter,
        pagination: state.pagination,
        orderBy: [state.orderBy],
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return fetchMoreResult;
      },
    });
  }, [fetchMore, state.filter, state.orderBy, state.pagination]);

  return (
    <TableContext.Provider value={{ state, dispatch }}>
      <StudySearchPanel
        onSearch={(filter) =>
          dispatch({
            type: 'FILTER_CHANGE',
            payload: convertFromFormModel(filter),
          })
        }
      />
      <Table<Study>
        columnConfig={filteredColumns}
        //TODO: Fix types
        data={data?.studies.items as Study[]}
        hasCollapsableFilters
        onRowClicked={async ({ id }) => {
          await dispatch({
            type: 'SELECT_ROW',
            payload: id,
          });
          history.push(`/studies/view/${id}`);
        }}
        rowColor={({ original }) => {
          const studyStatus = original.status;
          const sharedStudy =
            original.leadingDoctor?.user.id !== getAuthInfo().id;

          if (studyStatus === StudyStatus.Paid) return PAID_STUDY_COLOR;
          else if (studyStatus === StudyStatus.Draft) return DRAFT_STUDY_COLOR;
          else if (sharedStudy) return SHARED_STUDY_COLOR;
        }}
        title="Lista Badań"
        totalCount={data?.studies.total}
        emptyLabel={t('pages.study.list.emptyLabel')}
      />

      <Protect
        roles={[
          UserRole.Admin,
          UserRole.Technician,
          UserRole.Manager,
          UserRole.Registration,
        ]}
      >
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          component={RouterLink}
          to="/studies/add"
        >
          <AddIcon />
        </Fab>
      </Protect>
    </TableContext.Provider>
  );
};
