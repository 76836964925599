import {
  MyCouncilInvitesDocument,
  MyCouncilInvitesQuery,
  useCouncilInviteAddedSubscription,
  useCouncilInviteAcceptedSubscription,
  useCouncilInviteRejectedSubscription,
} from '../../../../../generated/graphql';
import { useSnackbar } from 'notistack';

// Helper function for subscription error handling
const handleSubscriptionError = (subscriptionName: string, error: any) => {
  console.error(`${subscriptionName} subscription error:`, error);
};

export const useCouncilSubscription = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Subscription for new council invites
  useCouncilInviteAddedSubscription({
    onData: ({ client, data }) => {
      if (!data?.data?.councilInviteAdded) return;

      try {
        // Read existing invites from cache
        const existingData = client.cache.readQuery<MyCouncilInvitesQuery>({
          query: MyCouncilInvitesDocument,
        });

        if (existingData) {
          // Update cache with new invite
          client.cache.writeQuery({
            query: MyCouncilInvitesDocument,
            data: {
              getMyCouncilInvites: [
                data.data.councilInviteAdded,
                ...(existingData.getMyCouncilInvites || []),
              ],
            },
          });

          // Show notification
          enqueueSnackbar('Otrzymałeś nowe zaproszenie do konsylium', {
            variant: 'info',
            autoHideDuration: 3000,
          });
        } else {
          // If cache is empty, refetch
          client.refetchQueries({
            include: [MyCouncilInvitesDocument],
          });
        }
      } catch (error) {
        console.error('Error updating council invites cache:', error);
        // Fallback to refetch
        client.refetchQueries({
          include: [MyCouncilInvitesDocument],
        });
      }
    },
    onError: (error) => handleSubscriptionError('CouncilInviteAdded', error),
  });

  // Subscription for accepted invites
  useCouncilInviteAcceptedSubscription({
    onData: ({ client, data }) => {
      if (!data?.data?.councilInviteAccepted) return;

      // Simply refetch to ensure consistency
      client.refetchQueries({
        include: [MyCouncilInvitesDocument],
      });
    },
    onError: (error) => handleSubscriptionError('CouncilInviteAccepted', error),
  });

  // Subscription for rejected invites
  useCouncilInviteRejectedSubscription({
    onData: ({ client, data }) => {
      if (!data?.data?.councilInviteRejected) return;

      // Refetch to ensure cache consistency with server
      client.refetchQueries({
        include: [MyCouncilInvitesDocument],
      });
    },
    onError: (error) => handleSubscriptionError('CouncilInviteRejected', error),
  });
};
