import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';
import { useTranslation } from 'react-i18next';
import { CookieConfigDialog } from './CookieConfigDialog';
import { useCookieSettings } from '../../../../common/hooks/useCookieSettings';

interface CookieSettingsButtonProps {
  size?: 'small' | 'medium' | 'large';
  color?: string;
}

export const CookieSettingsButton: React.FC<CookieSettingsButtonProps> = ({
  size = 'medium',
  color,
}) => {
  const { t } = useTranslation();
  const [configOpen, setConfigOpen] = useState(false);
  const {
    cookieSettings,
    saveSettings,
    rejectAllExceptNecessary,
  } = useCookieSettings();

  const handleOpenConfig = () => {
    setConfigOpen(true);
  };

  const handleCloseConfig = () => {
    setConfigOpen(false);
  };

  return (
    <>
      <Tooltip title={t('app.cookieConsent.configure')}>
        <IconButton
          onClick={handleOpenConfig}
          size={size}
          sx={color ? { color } : undefined}
        >
          <CookieIcon />
        </IconButton>
      </Tooltip>

      <CookieConfigDialog
        open={configOpen}
        onClose={handleCloseConfig}
        onSave={saveSettings}
        onReject={rejectAllExceptNecessary}
        initialSettings={cookieSettings}
      />
    </>
  );
};
