import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PartialDeep } from 'type-fest';
import { Form } from '../../../../../common/components/form/Form';
import { CheckboxField } from '../../../../../common/components/form/fields/CheckboxField';
import { DateTimeField } from '../../../../../common/components/form/fields/DateTimeField';
import { SelectField } from '../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import {
  Appointment,
  AppointmentAddInput,
  AppointmentBreakInput,
  AppointmentUpdateInput,
  useBranchesQuery,
  useGetStudyTypesQuery,
} from '../../../../../generated/graphql';
import { BranchSelect } from '../../common/fields/BranchSelect';
import { StudyTypeAutocomplete } from '../../common/fields/StudyTypeAutocomplete';
import { PatientAutocomplete } from '../../patients/fields/PatientAutocomplete';

import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router';
import { schema } from '../../../../../common/validation/appointment/schema';
import { ReferalSelect } from '../../common/fields/ReferalSelect';
import { StudySelect } from '../../common/fields/StudySelect';
import { FormWatchTimer } from '../components/FormWatchTimer';
import { DateTime } from 'luxon';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  onSubmit: (
    data: AppointmentAddInput | AppointmentUpdateInput | AppointmentBreakInput,
  ) => void;
  appointment:
    | PartialDeep<
        AppointmentAddInput | AppointmentUpdateInput | AppointmentBreakInput
      >
    | PartialDeep<Appointment>;
};

function isAppointment(
  input: PartialDeep<
    | AppointmentAddInput
    | AppointmentUpdateInput
    | AppointmentBreakInput
    | Appointment
  >,
): input is PartialDeep<Appointment> {
  return (input as PartialDeep<Appointment>).id !== undefined;
}

export const AppointmentForm: React.FC<Props> = ({
  onSubmit,
  appointment,
  children,
}) => {
  const { t } = useTranslation();
  const { data: branches } = useBranchesQuery({
    variables: { filter: { ownBranch: { is: true } } },
  });

  const { data: studyTypes } = useGetStudyTypesQuery();

  const { pathname } = useLocation();

  const isCompleted = isAppointment(appointment) && !!appointment.study?.id;

  const isReferalCompleted =
    isAppointment(appointment) && appointment.referal?.completed;

  return (
    <Form<AppointmentAddInput | AppointmentUpdateInput | AppointmentBreakInput>
      onSubmit={onSubmit}
      formOptions={{
        defaultValues: appointment as any,
        resolver: yupResolver(schema) as any,
      }}
      render={({ watch, setValue, getValues }) => {
        return (
          <>
            <FormWatchTimer
              defaultDuration={300}
              restartOn={['endDate']}
              startOnFilled={['startDate', 'endDate']}
            />
            <Box
              display="flex"
              gap={8}
              flexDirection={{ xs: 'column', lg: 'row' }}
              alignItems="center"
            >
              <BranchSelect
                ownOnly
                label="Filia"
                name="branch.id"
                required
                onChange={() => {
                  setValue('studyType', { id: 0 });
                  setValue('device', { id: 0 });
                }}
              />
              {branches?.branches && (
                <SelectField
                  required
                  options={
                    branches?.branches?.nodes
                      .find(({ id }) => id === watch('branch.id'))
                      ?.devices?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })) || []
                  }
                  name="device.id"
                  label={t('pages.appointment.fields.room')}
                  onChange={() => setValue('studyType', { id: 0 })}
                />
              )}
              <CheckboxField name="isBreak" label="Przerwa" />
            </Box>
            <StudyTypeAutocomplete
              controllerProps={{
                name: isAppointment(appointment) ? 'studyType' : 'studyTypes',
              }}
              autocompleteProps={{
                disabled: watch('isBreak'),
                onChange: (_, studyType) => {
                  //@ts-ignore
                  const startDate = watch('startDate') as Date;
                  if (studyType === null) return;

                  const studyTypes = Array.isArray(studyType)
                    ? studyType
                    : [studyType];

                  if (studyTypes.length === 0) return;

                  const totalDuration = studyTypes.reduce(
                    (acc, { studyTypeDevices }) =>
                      acc +
                      studyTypeDevices.reduce(
                        (acc, { durationMinutes }) => acc + durationMinutes,
                        0,
                      ),
                    0,
                  );

                  //add minutes to start date
                  const endDate = new Date(startDate);
                  endDate.setMinutes(
                    new Date(startDate).getMinutes() + (totalDuration || 0),
                  );

                  //@ts-ignore
                  setValue('endDate', endDate);
                },
              }}
              multiple={pathname.includes('new')}
              branchId={getValues('branch.id')}
              deviceId={getValues('device.id')}
            />
            <Box
              display="flex"
              gap={8}
              flexDirection={{ xs: 'column', lg: 'row' }}
            >
              <DateTimeField
                disablePast
                name="startDate"
                minutesStep={15}
                label={t('pages.appointment.fields.startDate')}
                fullWidth
                onDateTimeChange={(date) => {
                  const startDate = (date as DateTime).toJSDate();

                  const studyType = studyTypes?.studyTypes.edges.find(
                    ({ node: { id } }) => watch('studyType')?.id === id,
                  )?.node;

                  if (studyType?.studyTypeDevices?.length === 0) return;

                  const totalDuration = studyType?.studyTypeDevices.reduce(
                    (acc, { durationMinutes }) => acc + durationMinutes,
                    0,
                  );

                  //add minutes to start date
                  const endDate = new Date(startDate);
                  endDate.setMinutes(
                    startDate.getMinutes() + (totalDuration || 0),
                  );

                  //@ts-ignore
                  setValue('endDate', endDate);
                }}
              />
              <DateTimeField
                disablePast
                name="endDate"
                minutesStep={15}
                label={t('pages.appointment.fields.endDate')}
                fullWidth
                disabled={!watch('isBreak')}
                defaultValue={null}
              />
            </Box>
            <PatientAutocomplete
              withAddButton={!isCompleted}
              autocompleteProps={{ disabled: watch('isBreak') }}
              withGoToPatientButton={watch('patient.id') !== undefined}
            />
            <TextField
              name="comments"
              label="Komentarz"
              multiline
              minRows={2}
              maxRows={8}
              fullWidth
              hideIfEmpty
            />
            <ReferalSelect
              name="referal.id"
              patientId={watch('patient')?.id}
              completed={isReferalCompleted}
              disabled={!watch('patient.id') || isReferalCompleted}
            />
            {isAppointment(appointment) && (
              <StudySelect
                name="study.id"
                label="Zrealizowane badanie"
                patientId={watch('patient')?.id}
                studyTypeId={watch('studyType')?.id}
                disabled={!watch('patient.id') || isReferalCompleted}
              />
            )}

            <Box mt={8}>{children}</Box>
          </>
        );
      }}
    />
  );
};
