import React from 'react';

import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useStyles } from './ActionPanel.styles';
import { ItemActionsConfig } from '../../types/actions';
import { useAppContextState } from '../../../ui/app/components/settings/hooks/useSettingsState';

type ButtonAlignment = 'left' | 'center' | 'right';

export type ActionPanelProps<T extends object, AT> = {
  config: ItemActionsConfig<T, AT>;
  alignment: ButtonAlignment;

  // TODO: Handle multiple items for instance when ActionPanel
  // appears as a popover on the table when multiple items are selected.
  item: T;
};

export const ActionPanel = <T extends object, AT>({
  config,
  alignment,
  item,
}: React.PropsWithChildren<ActionPanelProps<T, AT>>) => {
  const classes = useStyles({ alignment });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [{ currentRole }] = useAppContextState();

  return (
    <div className={classes.root}>
      {config.map(
        ({
          id,
          onAction,
          title,
          Icon,
          roles,
          actionColor: color,
          visible = () => true,
          ...buttonProps
        }) =>
          visible(item) &&
          currentRole &&
          (!roles || roles?.includes(currentRole)) && (
            <Button
              variant="outlined"
              key={id}
              style={{ color }}
              onClick={() => onAction(item)}
              className={classes.button}
              startIcon={Icon && <Icon />}
              {...buttonProps}
            >
              {!isMobile && title}
            </Button>
          ),
      )}
    </div>
  );
};
