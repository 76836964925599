import { Box, Button } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStatePersisted } from 'use-persisted';
import { Form } from '../../../../../common/components/form/Form';
import {
  Maybe,
  StudyReportFiltersInput,
  UserRole,
} from '../../../../../generated/graphql';

import { DateStepperField } from '../../../../../common/components/form/fields/DateStepperField';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { BranchSelect } from '../../common/fields/BranchSelect';

const usePersistedSearchForm = createUseStatePersisted(
  'dailyStudyFilters',
  globalThis.localStorage,
);

const getDefaultValues = (currentBranch?: Maybe<{ id: number }>) => ({
  studyPaymentDate: DateTime.now().startOf('day').toJSDate(),
  branchId: currentBranch?.id,
});

type DailyStudyFiltersForm = {
  studyPaymentDate: Date;
  branchId?: number;
};

type Props = { onSearch: (filters: StudyReportFiltersInput) => void };

const convertFormModelToInput = ({
  branchId,
  studyPaymentDate,
}: Partial<ReturnType<typeof getDefaultValues>>): StudyReportFiltersInput => {
  return {
    branchId,
    studyDateFrom: DateTime.fromJSDate(studyPaymentDate || new Date())
      .startOf('day')
      .toJSDate(),
    studyDateTo: DateTime.fromJSDate(studyPaymentDate || new Date())
      .endOf('day')
      .toJSDate(),
  };
};

export const DailyStudyFilters: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation();

  const [{ currentRole, currentBranch }] = useAppContextState();

  const [studyForm, setStudyForm] = usePersistedSearchForm<
    DailyStudyFiltersForm
  >(getDefaultValues(currentBranch));

  return (
    <Form<DailyStudyFiltersForm>
      onSubmit={(formData) => {
        onSearch(convertFormModelToInput(formData));
        setStudyForm(formData);
      }}
      showLeaveUnsavedPrompt={false}
      formOptions={{
        defaultValues: studyForm,
      }}
      render={({ reset }) => (
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={10}
          alignItems="center"
        >
          <DateStepperField
            name="studyPaymentDate"
            label={t('reports.filters.studyPaymentDate')}
          />
          <BranchSelect
            ownOnly
            label="Filia"
            name="branchId"
            disabled={currentRole !== UserRole.Admin}
            required
            allowEmpty
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="medium"
          >
            {t('app.searchPanel.actions.generate')}
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              const defaults = getDefaultValues(currentBranch);
              reset(defaults);
              onSearch(convertFormModelToInput(defaults));
            }}
          >
            {t('app.searchPanel.actions.reset')}
          </Button>
        </Box>
      )}
    ></Form>
  );
};
