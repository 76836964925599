import { DateTime } from 'luxon';
import {
  AppointmentsDocument,
  AppointmentsQuery,
  useOnAppointmentAddedSubscription,
  useOnAppointmentDeletedSubscription,
  useOnAppointmentUpdatedSubscription,
  useOnReservationAddedSubscription,
  useOnReservationUpdatedSubscription,
  useOnReservationDeletedSubscription,
} from '../../../../../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { appointmentRefetchNeeded } from '../../../../../common/gql/appointmentCachePolicy';
import { useSnackbar } from 'notistack';

// Helper function for connection error logging and handling
const handleSubscriptionError = (subscriptionName: string, error: any) => {
  console.error(`${subscriptionName} subscription error:`, error);
  // We could also notify the user about connection issues here
};

export const useAppointmentSubscriptions = () => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'appointments',
    });
  }, [client]);

  // Monitor subscription status
  useEffect(() => {
    let reconnectTimer: NodeJS.Timeout | undefined;
    let reconnectAttempts = 0;
    const MAX_RECONNECT_ATTEMPTS = 5;
    const RECONNECT_DELAY = 5000; // 5 seconds

    const checkSubscriptions = () => {
      if (!navigator.onLine) {
        console.log(
          'Network is offline, will attempt to reconnect when online',
        );

        // Show error to user
        enqueueSnackbar(
          'Połączenie sieciowe przerwane. Aktualizacje kalendarza mogą być opóźnione.',
          {
            variant: 'warning',
            persist: true,
            key: 'network-offline',
          },
        );

        // Listen for online event to retry
        window.addEventListener('online', retrySubscriptions, { once: true });
      }
    };

    const retrySubscriptions = () => {
      console.log(
        'Network is back online, attempting to reconnect subscriptions',
      );

      // Show info to user
      enqueueSnackbar(
        'Połączenie sieciowe przywrócone. Synchronizacja kalendarza...',
        {
          variant: 'info',
          autoHideDuration: 3000,
          key: 'network-online',
        },
      );

      appointmentRefetchNeeded(true); // Force a refetch to sync missed updates

      // Clear any pending timer
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }

      // Reset reconnect attempts
      reconnectAttempts = 0;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const attemptReconnect = () => {
      if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        reconnectAttempts++;
        console.log(
          `Attempting to reconnect (${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS})...`,
        );

        // Force a refetch to test connection
        appointmentRefetchNeeded(true);

        // Schedule next attempt
        reconnectTimer = setTimeout(attemptReconnect, RECONNECT_DELAY);
      } else {
        console.log('Max reconnect attempts reached');
        enqueueSnackbar(
          'Nie można zsynchronizować kalendarza. Proszę odświeżyć stronę.',
          {
            variant: 'error',
            persist: true,
            key: 'reconnect-failed',
          },
        );
      }
    };

    // Set up listeners
    window.addEventListener('offline', checkSubscriptions);

    return () => {
      window.removeEventListener('offline', checkSubscriptions);
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }
    };
  }, [enqueueSnackbar]);

  useOnAppointmentAddedSubscription({
    onData: ({ client, data }) => {
      if (!data?.data?.appointmentAdded) return;

      const cache = client.cache;

      const variables = {
        where: {
          deviceId: data?.data?.appointmentAdded.device.id,
          startDateFrom: DateTime.fromJSDate(
            new Date(data?.data.appointmentAdded.startDate),
          )
            .startOf('day')
            .toISO(),
          startDateTo: DateTime.fromJSDate(
            new Date(data.data?.appointmentAdded.startDate),
          )
            .endOf('day')
            .toISO(),
        },
        pagination: {
          skip: 0,
          take: 100,
        },
      };

      const existing = cache.readQuery<AppointmentsQuery>({
        query: AppointmentsDocument,
        variables,
      });
      const appointments = existing?.appointments;
      if (!appointments) {
        // If we can't find the appointments in cache, trigger a refetch
        appointmentRefetchNeeded(true);
        return;
      }
      cache.writeQuery({
        query: AppointmentsDocument,
        data: {
          appointments: [...appointments, data?.data.appointmentAdded],
        },
        variables,
      });
    },
    onError: (error) => handleSubscriptionError('AppointmentAdded', error),
  });

  useOnAppointmentUpdatedSubscription({
    onData: ({ client, data }) => {
      if (!data?.data?.appointmentUpdated) return;

      // Signal that appointment data needs to be refreshed
      appointmentRefetchNeeded(true);

      // Properly update the specific cache entry with all fields
      const appointment = data.data.appointmentUpdated;
      client.cache.modify({
        id: client.cache.identify(appointment),
        fields: {
          startDate: () => appointment.startDate,
          endDate: () => appointment.endDate,
          patient: () => appointment.patient,
          studyType: () => appointment.studyType,
          device: () => appointment.device,
          isBreak: () => appointment.isBreak,
          comments: () => appointment.comments,
          // Update any other fields that exist in the schema
        },
      });
    },
    onError: (error) => handleSubscriptionError('AppointmentUpdated', error),
  });

  useOnAppointmentDeletedSubscription({
    onData: ({ client, data }) => {
      if (!data?.data?.appointmentDeleted) return;

      const cache = client.cache;

      cache.evict({
        id: cache.identify({
          __typename: 'Appointment',
          id: data?.data?.appointmentDeleted,
        }),
      });

      // After evicting the deleted appointment, signal refetch needed
      appointmentRefetchNeeded(true);
    },
    onError: (error) => handleSubscriptionError('AppointmentDeleted', error),
  });

  // Add error handling to reservation subscriptions too
  useOnReservationAddedSubscription({
    onData: ({ data }) => {
      if (!data?.data?.reservationAdded) return;
      appointmentRefetchNeeded(true);
    },
    onError: (error) => handleSubscriptionError('ReservationAdded', error),
  });

  useOnReservationUpdatedSubscription({
    onData: ({ data }) => {
      if (!data?.data?.reservationUpdated) return;
      appointmentRefetchNeeded(true);
    },
    onError: (error) => handleSubscriptionError('ReservationUpdated', error),
  });

  useOnReservationDeletedSubscription({
    onData: ({ data }) => {
      if (!data?.data?.reservationDeleted) return;
      appointmentRefetchNeeded(true);
    },
    onError: (error) => handleSubscriptionError('ReservationDeleted', error),
  });
};
