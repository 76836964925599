import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import DeleteIcon from '@mui/icons-material/Delete';
import PauseIcon from '@mui/icons-material/PauseCircleFilled';
import PlayIcon from '@mui/icons-material/PlayCircleFilled';

import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import {
  SortableColumn,
  Table,
} from '../../../../../../common/components/table/Table';
import { useTableActions } from '../../../../../../common/components/table/hooks/useTableActions';
import { ActionColor } from '../../../../../../common/types/actions';
import { toLocaleDateTime } from '../../../../../../common/utils/date.utils';
import {
  MailingFragmentFragment,
  MailingSortFields,
  MailingStatus,
  SortDirection,
  useMailingsQuery,
} from '../../../../../../generated/graphql';
import { ProtectedRoute } from '../../../auth/ProtectedRoute';
import { useMailingApi } from '../../hooks/useMailingApi';
import { MailingForm } from './MailingForm';

type Props = {};

const COLUMNS = (t: TFunction): SortableColumn<MailingFragmentFragment>[] => [
  {
    Header: 'Temat',
    accessor: 'subject',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row: { original } }) =>
      t(`settings.mailing.fields.status.${original.status}`) as string,
  },
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: 'createdAt',
    Cell: ({ row: { original } }) => toLocaleDateTime(original.createdAt),
  },
];

export const MailingPanel: React.FC<Props> = () => {
  const { data } = useMailingsQuery({
    variables: {
      sorting: [
        { field: MailingSortFields.CreatedAt, direction: SortDirection.Desc },
      ],
    },
  });

  const history = useHistory();
  const { path } = useRouteMatch();

  const {
    addMailing,
    saveMailing,
    deleteMailing,
    startMailing,
    stopMailing,
  } = useMailingApi();

  const { t } = useTranslation();

  const tableActions = useTableActions<
    MailingFragmentFragment,
    'edit' | 'delete' | 'start' | 'stop'
  >([
    {
      id: 'delete',
      Icon: DeleteIcon,
      title: 'Usuń szablon',
      actionColor: ActionColor.RED,
      onAction: async ({ id }) => {
        await deleteMailing(id);
        history.push(`${path}/`);
      },
    },
    {
      id: 'start',
      Icon: PlayIcon,
      title: 'Rozpocznij wysyłkę',
      actionColor: ActionColor.GREEN,
      onAction: ({ id }) => startMailing(id),
      visible: ({ status }) =>
        [MailingStatus.Draft, MailingStatus.Suspended].includes(status),
    },
    {
      id: 'stop',
      Icon: PauseIcon,
      title: 'Zatrzymaj wysyłkę',
      actionColor: ActionColor.YELLOW,
      onAction: ({ id }) => stopMailing(id),
      visible: ({ status }) => status === MailingStatus.Sending,
    },
  ]);

  if (!data?.mailings?.edges) return null;

  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/`}>
        <Box position="relative">
          <Table<MailingFragmentFragment>
            columnConfig={[...COLUMNS(t), tableActions]}
            pagination={false}
            data={data?.mailings.edges.map(({ node }) => node)}
            title="Mailingi"
            manualSortBy={false}
            onRowClicked={(mailing) =>
              history.push(`${path}/edit/${mailing.id}`)
            }
          />
          <Box position="fixed" bottom={24} right={24}>
            <Fab
              color="primary"
              aria-label="add"
              component={RouterLink}
              to={`${path}/add`}
            >
              <AddIcon />
            </Fab>
          </Box>
        </Box>
      </ProtectedRoute>
      <ProtectedRoute
        path={`${path}/edit/:id`}
        render={(props) => {
          if (!props?.match?.params?.id) return null;

          const paramsId = parseInt(props.match.params.id);

          const foundMailing = data?.mailings.edges.find(
            ({ node: { id } }) => id === paramsId,
          );

          return (
            <MailingForm
              mailingId={foundMailing?.node.id}
              onSubmit={(newMailing) => saveMailing(newMailing, paramsId)}
            />
          );
        }}
      />
      <ProtectedRoute path={`${path}/add`}>
        <MailingForm onSubmit={(newMailing) => addMailing(newMailing)} />
      </ProtectedRoute>
    </Switch>
  );
};
