import { Button, Grid, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { AlertProps } from '@mui/lab';
import { DateTime } from 'luxon';
import React from 'react';
import { useGraphQLError } from '../../../../../../common/hooks/useGraphQLError';
import { useMessages } from '../../../../../../common/hooks/useMessages';
import { toLocaleDateTime } from '../../../../../../common/utils/date.utils';
import {
  StudyDescriber,
  UserRole,
  useUpdateDescriptionDateMutation,
} from '../../../../../../generated/graphql';
import { Protect } from '../../../../components/auth/components/Protect';
import { useChangeDescriptionDate } from '../../hooks/useChangeDescriptionDate';
import { TextField } from '@mui/material';

type Props = {
  describer: StudyDescriber;
  studyId: number;
  studyHasDescriptionAlready: boolean;
  disabled?: boolean;
};

export const DescriberDescription: React.FC<Props> = ({
  describer,
  studyId,
  studyHasDescriptionAlready = false,
  disabled = false,
}) => {
  const showChangeDescriptionDateDialog = useChangeDescriptionDate();

  const { success } = useMessages();
  const onError = useGraphQLError();

  const [updateDate] = useUpdateDescriptionDateMutation({
    onCompleted: () => success(),
    onError,
  });

  if (!describer.dueDate) return null;

  const durationUntilDueDate = DateTime.fromISO(
    new Date(describer.dueDate).toISOString(),
  ).diffNow(['days', 'hours', 'minutes']);

  const isInThePast = durationUntilDueDate.valueOf() < 0;

  const formattedDuration = isInThePast
    ? 'Przekroczono czas na uzupełnienie opisu!'
    : durationUntilDueDate.toFormat(
        "'(Pozostało ' d 'dni', h 'godzin' oraz m 'minut)'",
      );

  const severity: AlertProps['color'] = studyHasDescriptionAlready
    ? 'info'
    : durationUntilDueDate.get('days') < 1 &&
      durationUntilDueDate.get('hours') < 6
    ? 'error'
    : durationUntilDueDate.get('days') < 2
    ? 'warning'
    : 'info';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Opis badania</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="description"
          label="Opis badania"
          multiline
          rows={4}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <Alert
          severity={severity}
          action={
            <Protect roles={[UserRole.Admin, UserRole.Manager]}>
              <Button
                color="inherit"
                size="small"
                onClick={async () => {
                  const newDate = await showChangeDescriptionDateDialog();
                  if (newDate) {
                    updateDate({
                      variables: {
                        doctorId: describer.doctor.id,
                        id: studyId,
                        newDate,
                      },
                    });
                  }
                }}
              >
                Zmień datę
              </Button>
            </Protect>
          }
        >
          <b>
            {describer.doctor.user.contact.firstName}{' '}
            {describer.doctor.user.contact.lastName}{' '}
          </b>
          {' Termin załączenia opisu: '}
          <b>{toLocaleDateTime(new Date(describer.dueDate))}</b>
          {'. '}
          {!studyHasDescriptionAlready && formattedDuration}
        </Alert>
      </Grid>
    </Grid>
  );
};
