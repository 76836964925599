import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CancelButton } from '../../../../../common/components/button/CancelButton';
import { Form } from '../../../../../common/components/form/Form';
import { TagsField } from '../../../components/tags/TagsField';

export type CouncilFormType = { invites: string[] };

const schema = yup.object<CouncilFormType>().shape({
  invites: yup.array().of(yup.string().email().required()).required(),
});

type Props = {
  onSubmit: (data: CouncilFormType) => void;
};

export const CouncilForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Form<CouncilFormType>
      onSubmit={onSubmit}
      formOptions={{
        defaultValues: {
          invites: [],
        },
        resolver: yupResolver(schema) as any,
      }}
      render={() => (
        <>
          <TagsField
            name="invites"
            label="Adresy e-mail lekarzy"
            placeholder="Adresy e-mail lekarzy, do zaproszenia do Konsylium"
            required
          />
          <Box mt={8}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              color="primary"
              //disabled={!getValues('invites')?.length}
            >
              {t('pages.council.panel.form.inviteToCouncil')}
            </Button>
            <CancelButton />
          </Box>
        </>
      )}
    ></Form>
  );
};
