import { DateTime, DateTimeFormatOptions } from 'luxon';

/**
 * @returns Date part only in ISO format (YYYY-MM-DD).
 */
export const toISODate = (date?: string | Date) => {
  if (!date) return '';

  const dateTime =
    date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  return dateTime.toISODate();
};

/**
 * @returns Date and time in locale's format.
 */
export const toLocaleDateTime = (
  date?: string | Date | null,
  format: DateTimeFormatOptions = DateTime.DATETIME_SHORT,
  twoDigitDates = true,
) => {
  if (!date) return '';

  if (twoDigitDates) {
    format.day = '2-digit';
    format.month = '2-digit';
  }

  const dateTime =
    date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  return dateTime.toLocaleString(format).replace(',', '');
};

/**
 * @returns Date part only in locale's format.
 */
export const toLocaleDate = (
  date?: string | Date | null,
  format: DateTimeFormatOptions = DateTime.DATE_SHORT,
) => toLocaleDateTime(date, format);

/**
 * @returns today's date with time set to end of day.
 */
export const todayStart = () => DateTime.local().set({ hour: 0, minute: 0 });

/**
 * @returns today's date with time set to end of day.
 */
export const todayEnd = () => DateTime.local().set({ hour: 23, minute: 59 });

/**
 * @returns today's date with time set to end of day.
 */
export const formatRelativeTime = (dateISO: string) => {
  const now = DateTime.now();
  const targetDateTime = DateTime.fromISO(dateISO);

  const diff = now.diff(targetDateTime, [
    'years',
    'months',
    'days',
    'hours',
    'minutes',
  ]);

  if (diff.days >= 3) {
    return targetDateTime.toLocaleString(DateTime.DATE_MED);
  } else {
    return targetDateTime.toRelative();
  }
};
