import * as yup from 'yup';

import {
  PaymentTypeEnum,
  StudyInput,
  StudyStatus,
} from '../../../generated/graphql';

export const paymentValidation = yup.object().shape({
  method: yup.string().nullable().required(),
  value: yup.number().moreThan(-0.01, 'Kwota musi być dodatnia').required(),
  type: yup.string().oneOf(Object.values(PaymentTypeEnum)).required(),
});

export const schema = yup.object<StudyInput>().shape({
  supportingDoctors: yup.array().nullable().max(9),
  performer: yup
    .object<StudyInput>()
    .nullable()
    .when('status', ([status]: StudyStatus[], schema) => {
      if (status === StudyStatus.Performed) {
        return schema.autocompleteRequired();
      } else {
        return schema;
      }
    }),
  // leadingDoctor: yup.object().nullable().autocompleteRequired(),
  patient: yup.object().nullable().autocompleteRequired(),
  studyType: yup.object().nullable().autocompleteRequired(),
  studyDate: yup.date().nullable().required(),
  branch: yup.object({
    id: yup
      .number()
      .notOneOf([0], 'Filia jest wymagana')
      .typeError('Filia jest wymagana'),
  }),
  status: yup.string().oneOf(Object.values(StudyStatus)),
  files: yup.array().when('status', ([status]: StudyStatus[], schema: any) => {
    if (status === StudyStatus.Performed) {
      return schema.required().min(1);
    }
    return schema;
  }),
  payments: yup.array().nullable().of(paymentValidation),
});
