import { Box, Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateField } from '../../../../../common/components/form/fields/DateField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { DescriberInfo } from '../../../../../generated/graphql';
import { StudyTypeAutocomplete } from '../../common/fields/StudyTypeAutocomplete';

type Props = {
  disabled?: boolean;
  describerInfo?: DescriberInfo;
};

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8),
  },
  maxStudyHoursField: {
    width: '400px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export const DescribingDoctorFormPanel: React.FC<Props> = ({
  disabled,
  describerInfo,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">
        {t('pages.doctor.edit.describingDoctor.title')}
      </Typography>
      <Box mt={8} display="flex" flexDirection="column" gap={8}>
        <Box display="flex" flexDirection="column" gap={8}>
          <Typography variant="body1">
            {t('pages.doctor.edit.describingDoctor.fields.assignedStudies')}:{' '}
            <strong>{describerInfo?.assignedStudies}</strong>
          </Typography>
          <Typography variant="body1">
            {t(
              'pages.doctor.edit.describingDoctor.fields.totalAssignedStudies',
            )}
            : <strong>{describerInfo?.totalAssignedStudies}</strong>
          </Typography>
          <Typography variant="body1">
            {t(
              'pages.doctor.edit.describingDoctor.fields.descriptionOccupancyHours',
            )}
            : <strong>{describerInfo?.descriptionOccupancyHours}</strong>
          </Typography>
        </Box>

        <Grid container direction="column">
          <Grid item>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              gap={8}
            >
              <DateField
                name="user.vacationStart"
                label={t(
                  'pages.doctor.edit.describingDoctor.fields.vacationStart',
                )}
                disabled={disabled}
              />
              <DateField
                name="user.vacationEnd"
                label={t(
                  'pages.doctor.edit.describingDoctor.fields.vacationEnd',
                )}
                disabled={disabled}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              type="number"
              label={t(
                'pages.doctor.edit.describingDoctor.fields.maxStudyHoursToDescribe',
              )}
              name="maxStudyHoursToDescribe"
              className={classes.maxStudyHoursField}
              valueAsNumber
              disabled={disabled}
            />
          </Grid>

          <Grid>
            <StudyTypeAutocomplete
              controllerProps={{
                name: 'describingStudyTypes',
              }}
              autocompleteProps={{
                disabled,
              }}
              label={t(
                'pages.doctor.edit.describingDoctor.fields.describingStudyTypes',
              )}
              multiple
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
