import { Gender } from '../../generated/graphql';

export const validatePESEL = (pesel: string): boolean => {
  if (!pesel.match(/[0-9]{11}/)) return false;

  const wages = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
  let total = 0;

  for (let i = 0; i < wages.length; i++) {
    total += parseInt(pesel.substring(i, i + 1), 10) * wages[i];
  }
  total = total % 10;

  return total === parseInt(pesel.substring(10, 11), 10);
};

export const extractBirthDateFromPESEL = (pesel: string): Date | null => {
  if (!validatePESEL(pesel)) return null;

  let year = parseInt(pesel.substring(0, 2), 10);
  let month = parseInt(pesel.substring(2, 4), 10) - 1;
  const day = parseInt(pesel.substring(4, 6), 10);

  if (month >= 80) {
    year = year + 1800;
    month = month - 80;
  } else if (month >= 60) {
    year = year + 2200;
    month = month - 60;
  } else if (month >= 40) {
    year = year + 2100;
    month = month - 40;
  } else if (month >= 20) {
    year = year + 2000;
    month = month - 20;
  } else {
    year += 1900;
  }

  const birthDate = new Date();
  birthDate.setFullYear(year, month, day);
  return birthDate;
};

export const extractGenderFromPESEL = (pesel: string): Gender | null => {
  if (!validatePESEL(pesel)) return null;

  let gender = null;

  if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
    gender = Gender.Male;
  } else {
    gender = Gender.Female;
  }

  return gender;
};
