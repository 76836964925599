import * as React from 'react';

export const TableContext = React.createContext({
  state: {
    orderBy: {
      columnName: 'id',
      columnOrder: 'ASC',
    },
    pagination: {
      take: 10,
      skip: 0,
    },
    filter: {},
    filtersExpanded: false,
    lastSelectedRowId: null,
  },
  dispatch: (_action: any) => {},
});
