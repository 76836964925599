import { Box } from '@mui/material';
import React from 'react';
import { SelectField } from '../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { SearchPanel } from '../../../../../common/components/search/SearchPanel';
import {
  DoctorFiltersInput,
  useAppConfigQuery,
} from '../../../../../generated/graphql';

type Props = {
  onSearch: (filter: DoctorFiltersInput) => void;
  filters?: DoctorFiltersInput;
};

export const DEFAULTS = {
  name: '',
  region: '',
  facility: true,
  showNoUserDoctors: true,
};

export const FacilitySearchPanel: React.FC<Props> = ({
  onSearch,
  filters = { ...DEFAULTS },
}) => {
  const { data: appConfigData } = useAppConfigQuery();

  const regions = appConfigData?.appConfig?.regions || [];

  return (
    <SearchPanel
      defaultValues={filters}
      onSearch={onSearch}
      resetValues={{ ...DEFAULTS }}
      mainFields={
        <Box
          display="flex"
          gap={10}
          alignItems="center"
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <TextField
            name="name"
            label="Nazwa"
            submitOnChange={onSearch}
            fullWidth
          />
          <SelectField
            label="Region"
            name="region"
            submitOnChange={onSearch}
            options={regions.map((region) => ({
              label: region,
              value: region,
            }))}
            fullWidth
          />
        </Box>
      }
    />
  );
};
