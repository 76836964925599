import { Route, Redirect, RouteProps } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { useAuth } from '../../../../common/hooks/useAuth';
import { UserRole } from '../../../../generated/graphql';

type Props = RouteProps & {
  requiredRoles?: UserRole[];
  anyRoles?: UserRole[];
  forbiddenRoles?: UserRole[]; // Add the new prop type here
};

export const ProtectedRoute: React.FC<Props> = ({
  children,
  requiredRoles,
  anyRoles,
  forbiddenRoles, // Destructure the new prop
  render: originalRender,
  ...rest
}) => {
  const { isAuthenticated, getAuthInfo } = useAuth();

  // Get the user's roles from getAuthInfo()
  const userRoles = getAuthInfo().roles;

  // Check whether any of the user's roles are forbidden
  const isForbidden = forbiddenRoles?.some((forbiddenRole) =>
    userRoles.includes(forbiddenRole),
  );

  // If the route is forbidden, we don't need to check any further
  if (isForbidden) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  // Determine if the user has any of the required or allowed roles
  const hasRequiredRoles = requiredRoles?.some((role) =>
    userRoles.includes(role),
  );
  const hasAnyRoles = anyRoles?.some((role) => userRoles.includes(role));

  // The user should have at least one of the required roles or be allowed by having any role
  const hasRoles =
    hasRequiredRoles || hasAnyRoles || (!requiredRoles && !anyRoles);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() && hasRoles ? (
          originalRender ? (
            originalRender(props)
          ) : (
            (children as ReactNode)
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
