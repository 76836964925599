import React from 'react';
import { Outline } from '../../../../../../../common/components/form/LabeledOutline';

import { Box, Button, IconButton } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SelectField } from '../../../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../../../common/components/form/fields/TextField';
import { ActionColor } from '../../../../../../../common/types/actions';
import { BranchInput } from '../../../../../../../generated/graphql';

import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useAppConfigQuery } from '../../../../../../../generated/graphql';
import { TagsField } from '../../../../tags/TagsField';

type Props = {};

export const BranchDevices: React.FC<Props> = () => {
  const { control } = useFormContext<BranchInput>();
  const { fields, append, remove } = useFieldArray<BranchInput, 'devices'>({
    name: 'devices',
    control,
  });

  const { t } = useTranslation();
  const { data: appConfig } = useAppConfigQuery();

  const deviceTypes = appConfig?.appConfig?.deviceTypes;

  if (!deviceTypes) return null;

  return (
    <Outline label={t('settings.branch.fields.devices.title')} fullWidth>
      {fields.map((studyTypeDevice, index) => (
        <Box key={studyTypeDevice.id} display="flex" gap={6} pt={4}>
          <SelectField
            name={`devices[${index}].deviceType`}
            options={deviceTypes.map((deviceGroup) => ({
              label: deviceGroup,
              value: deviceGroup,
            }))}
            defaultValue={studyTypeDevice.deviceType}
            label={t('settings.branch.fields.devices.deviceType')}
          />
          <TextField
            name={`devices[${index}].name`}
            defaultValue={studyTypeDevice.name}
            label={t('settings.branch.fields.devices.name')}
          />
          <TagsField
            name={`devices[${index}].radiationGroups`}
            label={t('settings.branch.fields.radiationGroups')}
            placeholder=""
            options={appConfig?.appConfig.radiationGroups || []}
            freeSolo={false}
            fullWidth
          />
          <IconButton
            aria-label="delete"
            style={{ color: ActionColor.RED }}
            onClick={() => remove(index)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Box mt={6}>
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={() => append({ deviceType: '', name: '' })}
        >
          {t('settings.branch.buttons.addNewDevice')}
        </Button>
      </Box>
    </Outline>
  );
};
