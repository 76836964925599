import { Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../../../common/components/form/Form';
import { Doctor } from '../../../../../generated/graphql';
import { DoctorAutocomplete } from '../fields/DoctorAutocomplete';

export const useSelectDoctor = (
  descriptionKey = 'dialogs.doctor.remove.selectOtherDoctorLabel',
  titleKey = 'dialogs.doctor.remove.title',
): (() => Promise<Doctor | undefined>) => {
  const confirm = useConfirm();
  const { t } = useTranslation();
  const selectedDoctor = useRef<Doctor>();

  const onSubmit = (data?: { doctor: Doctor }) => {
    selectedDoctor.current = data?.doctor;
  };

  return async () => {
    await confirm({
      title: t(titleKey),
      description: (
        <>
          <Typography variant="body2">{t(descriptionKey)}</Typography>
          <Form<{ doctor: Doctor }> onSubmit={onSubmit} noElevation>
            <DoctorAutocomplete submitOnChange={onSubmit} />
          </Form>
        </>
      ),
      cancellationText: t('confirmationDialog.action.cancelButton'),
      confirmationText: t('confirmationDialog.action.confirmButton'),
    });
    return selectedDoctor.current;
  };
};

export const useSelectDoctors = (
  descriptionKey = 'dialogs.doctor.remove.selectOtherDoctorLabel',
  titleKey = 'dialogs.doctor.remove.title',
): (() => Promise<Doctor[] | undefined>) => {
  const confirm = useConfirm();
  const { t } = useTranslation();
  const selectedDoctors = useRef<Doctor[]>();

  const onSubmit = (data?: { doctors: Doctor[] }) => {
    selectedDoctors.current = data?.doctors;
  };

  return async () => {
    await confirm({
      title: t(titleKey),
      description: (
        <>
          <Typography variant="body2">{t(descriptionKey)}</Typography>
          <Form<{ doctors: Doctor[] }> onSubmit={onSubmit} noElevation>
            <DoctorAutocomplete
              ignoreSelf
              submitOnChange={onSubmit}
              autocompleteProps={{ multiple: true }}
              controllerProps={{ name: 'doctors' }}
            />
          </Form>
        </>
      ),
      cancellationText: t('confirmationDialog.action.cancelButton'),
      confirmationText: t('confirmationDialog.action.confirmButton'),
    });
    return selectedDoctors.current;
  };
};
