import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStatePersisted } from 'use-persisted';
import { DateField } from '../../../../../common/components/form/fields/DateField';
import { SelectField } from '../../../../../common/components/form/fields/SelectField';
import { Form } from '../../../../../common/components/form/Form';
import {
  Maybe,
  StudyReportFiltersInput,
  UserRole,
  useAppConfigQuery,
} from '../../../../../generated/graphql';

import * as yup from 'yup';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { BranchSelect } from '../../common/fields/BranchSelect';
import { TagsField } from '../../../components/tags/TagsField';

const usePersistedSearchForm = createUseStatePersisted(
  'studyFilters',
  globalThis.sessionStorage,
);

enum TimeRange {
  Today = 'Today',
  Yesterday = 'Yesterday',
  ThisWeek = 'ThisWeek',
  LastWeek = 'LastWeek',
  ThisMonth = 'ThisMonth',
  LastMonth = 'LastMonth',
  Custom = 'Custom',
}

const getDefaultValues = (
  currentRole?: Maybe<UserRole>,
  currentBranch?: Maybe<{ id: number }>,
) => ({
  range: TimeRange.Today,
  studyDateFrom: DateTime.now().startOf('day').toJSDate(),
  studyDateTo: DateTime.now().endOf('day').toJSDate(),
  branchId: currentRole === UserRole.Admin ? -1 : currentBranch?.id,
  regions: [],
});

type Props = {
  onSearch: (filters: StudyReportFiltersInput & { range?: TimeRange }) => void;
  onDownload?: (filters: StudyReportFiltersInput) => void;
};

export const StudyReportFilters: React.FC<Props> = ({
  onSearch,
  onDownload,
}) => {
  const { t } = useTranslation();

  const [{ currentRole, currentBranch }] = useAppContextState();
  const { data: appConfigData } = useAppConfigQuery();

  const [studyForm, setStudyForm] = usePersistedSearchForm<
    StudyReportFiltersInput & { range?: TimeRange }
  >(getDefaultValues(currentRole, currentBranch));

  const regions = appConfigData?.appConfig?.regions || [];
  return (
    <Form<StudyReportFiltersInput & { range: TimeRange }>
      onSubmit={({ range, studyDateFrom, studyDateTo, branchId, regions }) => {
        onSearch({
          branchId,
          studyDateFrom: DateTime.fromJSDate(studyDateFrom)
            .startOf('day')
            .toJSDate(),
          studyDateTo: DateTime.fromJSDate(studyDateTo).endOf('day').toJSDate(),
          regions,
        });
        setStudyForm({ range, studyDateFrom, studyDateTo, branchId, regions });
      }}
      showLeaveUnsavedPrompt={false}
      formOptions={{
        defaultValues: studyForm,
        resolver: yupResolver(
          yup.object().shape({
            studyDateFrom: yup.date(),
            studyDateTo: yup
              .date()
              .min(yup.ref('studyDateFrom'), 'Nieprawidłowy zakres')
              .when('studyDateFrom', ([studyDateFrom], schema) => {
                return schema.max(
                  DateTime.fromJSDate(studyDateFrom)
                    .plus({ year: 1 })
                    .toJSDate(),
                  'Zbyt długi zakres',
                );
              }),
          }),
        ) as any,
      }}
      render={({ setValue, watch, formState, reset, handleSubmit }) => (
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={10}
          alignItems="center"
        >
          <SelectField
            name="range"
            label={t('reports.filters.rangeSelect.label')}
            options={Object.keys(TimeRange)
              .filter((range) => {
                if (
                  currentRole === UserRole.Technician &&
                  ['LastMonth', 'Custom'].includes(range)
                ) {
                  return false;
                }
                return true;
              })
              .map((range) => ({
                value: range,
                label: t(`reports.filters.rangeSelect.options.${range}`),
              }))}
            onChange={(event) => {
              const range = event.target.value as TimeRange;

              if (range === TimeRange.Today) {
                setValue(
                  'studyDateFrom',
                  DateTime.now().startOf('day').toJSDate(),
                );
                setValue('studyDateTo', DateTime.now().endOf('day').toJSDate());
              } else if (range === TimeRange.Yesterday) {
                setValue(
                  'studyDateFrom',
                  DateTime.now().minus({ days: 1 }).startOf('day').toJSDate(),
                );
                setValue(
                  'studyDateTo',
                  DateTime.now().minus({ days: 1 }).endOf('day').toJSDate(),
                );
              } else if (range === TimeRange.ThisWeek) {
                setValue(
                  'studyDateFrom',
                  DateTime.now().startOf('week').toJSDate(),
                );
                setValue(
                  'studyDateTo',
                  DateTime.now().endOf('week').toJSDate(),
                );
              } else if (range === TimeRange.LastWeek) {
                setValue(
                  'studyDateFrom',
                  DateTime.now().minus({ weeks: 1 }).startOf('week').toJSDate(),
                );
                setValue(
                  'studyDateTo',
                  DateTime.now().minus({ weeks: 1 }).endOf('week').toJSDate(),
                );
              } else if (range === TimeRange.ThisMonth) {
                setValue(
                  'studyDateFrom',
                  DateTime.now().startOf('month').toJSDate(),
                );
                setValue(
                  'studyDateTo',
                  DateTime.now().endOf('month').toJSDate(),
                );
              } else if (range === TimeRange.LastMonth) {
                setValue(
                  'studyDateFrom',
                  DateTime.now()
                    .minus({ months: 1 })
                    .startOf('month')
                    .toJSDate(),
                );
                setValue(
                  'studyDateTo',
                  DateTime.now().minus({ months: 1 }).endOf('month').toJSDate(),
                );
              }
            }}
          ></SelectField>
          <DateField
            name="studyDateFrom"
            label={t('reports.filters.studyDateFrom')}
            variant="outlined"
            required
            disabled={watch('range') !== TimeRange.Custom}
          />
          <DateField
            name="studyDateTo"
            label={t('reports.filters.studyDateTo')}
            variant="outlined"
            required
            disabled={watch('range') !== TimeRange.Custom}
          />
          <Box flexGrow={1} minWidth={200}>
            <TagsField
              name="regions"
              freeSolo={false}
              label="Regiony"
              placeholder="Wybierz regiony..."
              disabled={currentRole !== UserRole.Admin}
              options={regions || ['Nie skonfigurowano regionów']}
              required
            />
          </Box>
          <BranchSelect
            regions={watch('regions') || undefined}
            ownOnly
            label="Filia"
            name="branchId"
            disabled={currentRole !== UserRole.Admin}
            required
            allowEmpty
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="medium"
            disabled={!formState.isValid}
          >
            {t('app.searchPanel.actions.generate')}
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              const defaults = getDefaultValues(currentRole, currentBranch);
              reset(defaults);
              onSearch(defaults);
            }}
          >
            {t('app.searchPanel.actions.reset')}
          </Button>
          {onDownload && (
            <Button
              type="button"
              color="primary"
              onClick={() => handleSubmit((form) => onDownload(form))()}
            >
              {t('app.searchPanel.actions.downloadRejected')}
            </Button>
          )}
        </Box>
      )}
    ></Form>
  );
};
