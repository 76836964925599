import { AutocompleteProps } from '@mui/material/Autocomplete';
import { useCallback } from 'react';
import {
  AsyncAutocompleteField,
  AsyncAutocompleteProps,
  AutocompleteItem,
} from '../../../../../common/components/form/fields/AsyncAutocompleteField';
import { SubmitOnChangeProps } from '../../../../../common/components/form/types';
import { Unarray } from '../../../../../common/types/unarray';
import {
  BranchesQuery,
  BranchSortFields,
  SortDirection,
  useBranchesLazyQuery,
} from '../../../../../generated/graphql';

type Props<
  T extends AutocompleteItem,
  Multiple extends boolean | undefined
> = SubmitOnChangeProps & {
  controllerProps?: Partial<AsyncAutocompleteProps<T, Multiple, false>>;
  autocompleteProps?: Partial<
    AutocompleteProps<T, Multiple, undefined, undefined>
  >;
  multiple?: Multiple;
  ownOnly?: boolean;
};
export type BranchType = Unarray<BranchesQuery['branches']['nodes']>;

export const BranchAutocomplete = <Multiple extends boolean | undefined>(
  props: Props<BranchType, Multiple>,
) => {
  const [loadBranches, { refetch, called }] = useBranchesLazyQuery({
    context: {
      disableGlobalLoading: true,
    },
  });

  const loadBranchData = useCallback(
    async (autocompleteInputText?: string) => {
      const query = {
        sorting: [
          { direction: SortDirection.Asc, field: BranchSortFields.Name },
        ],
        paging: {
          offset: 0,
          limit: 20,
        },
        filter: {
          name: {
            iLike: autocompleteInputText ? `%${autocompleteInputText}%` : '%',
          },
          ownBranch: { is: !!props.ownOnly },
        },
      };

      const response = called
        ? await refetch(query)
        : await loadBranches({ variables: query });
      return response?.data?.branches?.nodes as BranchType[];
    },
    [called, loadBranches, props.ownOnly, refetch],
  );

  const getOptionLabel = (branch: BranchType) => branch.name;

  return (
    <AsyncAutocompleteField<BranchType, Multiple>
      getOptionLabel={getOptionLabel}
      loadData={loadBranchData}
      {...props.autocompleteProps}
      name={props.controllerProps?.name || 'branch'}
      label={props.controllerProps?.label || 'Lekarz'}
      submitOnChange={props.submitOnChange}
    />
  );
};
