import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import {
  ComplaintInput,
  ComplaintsDocument,
  UpdateComplaintInput,
  useGetComplaintQuery,
  useUpdateComplaintMutation,
} from '../../../../../generated/graphql';
import { ComplaintForm } from '../form/ComplaintForm';
import { useMessages } from '../../../../../common/hooks/useMessages';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';

type Props = {
  disabled?: boolean;
};

export const ComplaintEdit: React.FC<Props> = ({ disabled = false }) => {
  const { id: complaintId } = useParams<{ id: string }>();

  const { success } = useMessages();
  const snackBarError = useGraphQLError();
  const history = useHistory();

  const { data } = useGetComplaintQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parseInt(complaintId, 10),
    },
  });

  const [updateComplaint] = useUpdateComplaintMutation({
    onCompleted: () => {
      success();
      history.push(`/complaints`);
    },
    onError: snackBarError,
    refetchQueries: [ComplaintsDocument],
  });

  const onSubmit = (complaint: UpdateComplaintInput) => {
    updateComplaint({
      variables: { id: parseInt(complaintId, 10), complaint },
      fetchPolicy: 'network-only',
      refetchQueries: [ComplaintsDocument],
    });
  };

  return (
    <>
      <Box mb={8}>
        <ComplaintForm
          onSubmit={onSubmit as any}
          complaint={data?.complaint as ComplaintInput}
          disabled={disabled}
        >
          <Button
            type="button"
            color="secondary"
            onClick={() => history.goBack()}
            fullWidth
          >
            Anuluj
          </Button>
        </ComplaintForm>
      </Box>
    </>
  );
};
