import {
  useUploadFileMutation,
  FileType,
  usePrepareUploadMutation,
  useConfirmUploadMutation,
  IdInput,
} from '../../generated/graphql';
import { useGraphQLError } from './useGraphQLError';

import axios from 'axios';

type ProgressEvent = {
  loaded: number;
  total: number;
};

type OnProgressFunction = (event: ProgressEvent) => void;

type FileUploadType = 'direct' | 'two-phased';
type FileUploadFunction = (
  files: File[],
  fileType: FileType,
  rejected?: boolean,
  rejectionReason?: string,
) => Promise<FileArray>;

export type FileArray = Array<
  (IdInput & { name: string; type?: FileType }) | undefined
>;

export const useDirectFileUpload = () => {
  const snackBarError = useGraphQLError();

  const [uploadFile] = useUploadFileMutation({
    onError: snackBarError,
  });

  return (
    files: File[],
    fileType: FileType,
    rejected = false,
    rejectionReason = '',
  ) =>
    Promise.all(
      files.map(async (file) => {
        const uploadedFile = await uploadFile({
          variables: {
            file,
            fileType,
            rejected,
            rejectionReason,
            fileSizeInBytes: file.size,
          },
        });
        if (uploadedFile.data) {
          return {
            ...uploadedFile.data.uploadFile,
            name: file.name,
          };
        }
      }),
    );
};

export const useTwoPhaseFileUpload = (
  onUploadProgress?: OnProgressFunction,
) => {
  const snackBarError = useGraphQLError();

  const [prepareUpload] = usePrepareUploadMutation({
    onError: snackBarError,
  });
  const [confirmUpload] = useConfirmUploadMutation({
    onError: snackBarError,
  });

  return (
    files: File[],
    fileType: FileType,
    rejected = false,
    rejectionReason = '',
  ) =>
    Promise.all(
      files.map(async (file) => {
        const prepUploadResponse = await prepareUpload({
          variables: {
            file: {
              fileType,
              fileSizeInBytes: file.size,
              filename: file.name,
              mimetype: file.type,
              rejected,
              rejectionReason,
            },
          },
        });
        const { fileId, uploadSignedURL } =
          prepUploadResponse.data?.prepareUpload || {};

        if (uploadSignedURL && fileId) {
          await axios.put(uploadSignedURL, file, {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress,
          });
          const { data } = await confirmUpload({
            variables: { fileId },
          });

          if (data?.confirmUpload) {
            return {
              id: fileId,
              name: file.name,
              type: fileType,
            };
          }
        }
      }),
    );
};

const selectUploadHook: (
  onUploadProgress?: OnProgressFunction,
) => FileUploadFunction =
  (process.env.REACT_APP_UPLOAD_TYPE as FileUploadType) === 'direct'
    ? () => useDirectFileUpload()
    : (onUploadProgress) => useTwoPhaseFileUpload(onUploadProgress);

export const useFileUpload = selectUploadHook;
