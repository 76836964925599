import { yupResolver } from '@hookform/resolvers/yup';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Card, IconButton, Theme, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import useHotjar from 'react-use-hotjar';
import * as yup from 'yup';
import { CenteredPage } from '../../../../../common/components/centered-page/CenteredPage';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { Form } from '../../../../../common/components/form/Form';
import { LabelDivider } from '../../../../../common/components/label-divider/LabelDivider';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import {
  LoginInput,
  useAcceptTermsMutation,
  useConfirmEmailMutation,
  useLoginMutation,
  UserRole,
} from '../../../../../generated/graphql';
import { CookieSettingsButton } from '../../cookies/CookieSettingsButton';
import { RegulationsModal } from '../../regulations/RegulationsDialog';
import { useAppContextState } from '../../settings/hooks/useSettingsState';
import { FacebookLoginButton } from '../components/facebook-login/FacebookLoginButton';
import { GoogleLoginButton } from '../components/google-login/GoogleLoginButton';

import { errorHandler } from '../../../App';
import { EmailConfirmationResend } from './EmailConfirmationResend';
import { EmailConfirmationSuccess } from './EmailConfirmationSuccess';
import { LoginHelpPopover } from './LoginHelpPopup';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    borderRadius: theme.spacing(4),
    borderWidth: theme.spacing(1),
    borderStyle: 'solid',
    borderColor: '#91D1AF',
    margin: theme.spacing(2),
  },
  media: {
    width: theme.spacing(190),
  },
  form: {
    padding: theme.spacing(8),
  },
  actionIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    width: '100%',
    gap: theme.spacing(1),
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(6),
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  smallLogo: {
    width: '150px',
    height: 'auto',
  },
  largeLogo: {
    width: '250px',
    height: 'auto',
  },
}));

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

export const LoginPage: React.FC = () => {
  const snackBarError = useGraphQLError();
  const queryParams = useQueryParams();
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [
    showResendEmailConfirmation,
    setShowResendEmailConfirmation,
  ] = useState(false);

  const [loginMutation] = useLoginMutation({
    onError: (error) => {
      if (
        error?.graphQLErrors?.[0]?.extensions?.originalError?.error ===
        'UserNotConfirmed'
      ) {
        setShowResendEmailConfirmation(true);
      }
      return snackBarError(error);
    },
  });
  const [acceptTermsMutation] = useAcceptTermsMutation({
    onError: (error) => snackBarError(error),
  });

  const [appContext, setAppContext] = useAppContextState();
  const history = useHistory();
  const [confirmEmailMutation] = useConfirmEmailMutation({
    onError: (error) => snackBarError(error),
    onCompleted: (data) => setEmailConfirmed(data?.confirmEmail),
  });

  const [regulationsOpen, setRegulationsOpen] = useState(false);
  const [regulationsRequired, setRegulationsRequired] = useState(false);

  const { setAuthInfo } = useAuth();

  const { t } = useTranslation();
  const classes = useStyles();

  const { identifyHotjar } = useHotjar();

  useEffect(() => {
    const confirmationToken = queryParams.get('token');
    if (confirmationToken && !emailConfirmed) {
      confirmEmailMutation({
        variables: {
          confirmInput: confirmationToken,
        },
      });
    }
    // Forced single execution
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proceedWithLogin = useCallback(async () => {
    //TODO: Go to location passed as props so the user is relocated to primary intent
    history.push(
      appContext.currentRole === UserRole.Doctor ||
        appContext.currentRole === UserRole.DentalOffice ||
        appContext.currentRole === UserRole.Laboratory
        ? '/patients'
        : appContext.currentRole === UserRole.Admin
        ? '/'
        : '/studies',
    );
  }, [history, appContext]);

  const onSubmit = async (data: LoginInput) => {
    const loginResult = await loginMutation({
      variables: { loginData: data },
    });
    const userData = loginResult?.data?.login;
    if (userData && userData.user) {
      setAuthInfo({
        id: userData.user.id,
        roles: userData.user.roles,
        token: userData.token,
        login: userData.user.login,
      });
      setAppContext({
        ...appContext,
        currentRole:
          appContext.currentRole &&
          userData.user.roles.includes(appContext.currentRole)
            ? appContext.currentRole
            : userData.user.roles[0],
      });
      try {
        // Only call identifyHotjar if it exists and is ready
        if (typeof identifyHotjar === 'function') {
          identifyHotjar(
            userData.user.id.toString(),
            (userData as any).user,
            console.info,
          );
        }
      } catch (error) {
        console.warn('Hotjar identification failed:', error);
      }
      errorHandler.setUser(userData.user.login);

      if (!userData.user.acceptedTerms) {
        setRegulationsRequired(true);
        setRegulationsOpen(true);
      } else {
        proceedWithLogin();
      }
    }
  };

  return (
    <CenteredPage>
      <Card className={classes.root}>
        <div className={classes.form}>
          <Form<LoginInput>
            onSubmit={onSubmit}
            showLeaveUnsavedPrompt={false}
            formOptions={{ resolver: yupResolver(validationSchema) }}
            noElevation
          >
            <div className={classes.logoContainer}>
              <img
                alt="logo"
                src="/img/logo.svg"
                className={classes.smallLogo}
              />
            </div>
            <Box mb={4}></Box>
            <div className={classes.logoContainer}>
              <img
                alt="logo"
                src="/img/logoPortal.PNG"
                className={classes.largeLogo}
              />
            </div>
            <Box mb={6}></Box>
            {emailConfirmed && <EmailConfirmationSuccess />}
            {showResendEmailConfirmation && (
              <EmailConfirmationResend
                onSend={() => setShowResendEmailConfirmation(false)}
              />
            )}
            <Box mt={4} maxWidth={400} width="100%" justifyContent="center">
              <Box mt={4} mb={4}></Box>

              <TextField name="email" label="Login" fullWidth />
              <TextField name="password" label="Hasło" password fullWidth />
              <Box display="flex" flexDirection="column" width="100%">
                <Box mt={8} display="flex" flexDirection="column" width="100%">
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    fullWidth
                  >
                    Zaloguj się
                  </Button>
                  <LabelDivider>
                    <Typography variant="body2" style={{ color: '#aaaaaa' }}>
                      {t('login.divider.label')}
                    </Typography>
                  </LabelDivider>

                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    gap={2}
                  >
                    <GoogleLoginButton />
                    <FacebookLoginButton />
                  </Box>

                  <Box className={classes.bottomSection}>
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      mb={2}
                    >
                      <Button component={Link} to="/signup" variant="text">
                        Załóż konto
                      </Button>
                      <Button
                        component={Link}
                        to="/reset-password-request"
                        variant="text"
                      >
                        Zmień hasło
                      </Button>
                    </Box>
                    <Box className={classes.actionIcons}>
                      <LoginHelpPopover />

                      <Tooltip title={t('login.actions.terms') as string}>
                        <IconButton
                          onClick={() => setRegulationsOpen(true)}
                          size="large"
                        >
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>

                      <CookieSettingsButton size="large" />

                      <RegulationsModal
                        onAccept={
                          regulationsRequired
                            ? async () => {
                                await acceptTermsMutation();
                                proceedWithLogin();
                              }
                            : undefined
                        }
                        open={regulationsOpen}
                        onClose={() => setRegulationsOpen(false)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Form>
        </div>
      </Card>
    </CenteredPage>
  );
};
