import React, { useState } from 'react';
import { Column, CellProps } from 'react-table';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useStyles } from '../../table/Table.styles';
import { ItemActionConfig } from '../../../types/actions';
import { useAuth } from '../../../hooks/useAuth';
import { useAppContextState } from '../../../../ui/app/components/settings/hooks/useSettingsState';

type ActionCallbacks = {
  onError?: (error: Error) => void;
  onSuccess?: () => void;
};

export type TableActionConfig<T, AT> = ActionCallbacks &
  ItemActionConfig<T, AT>;

export const useTableActions = <T extends object, AT>(
  actionsConfig: TableActionConfig<T, AT>[],
): Column<T> => {
  const classes = useStyles();
  const { isAuthenticated, getAuthInfo } = useAuth();
  const [appContext] = useAppContextState();

  return {
    Header: 'Akcje',
    id: 'actions',
    Cell: ({ row: { original } }: CellProps<T>) => (
      <span className={classes.actions}>
        {actionsConfig.map(
          ({
            id,
            onAction,
            Icon,
            title,
            actionColor: color,
            visible,
            showProgress = false,
            roles,
            forbiddenRoles,
            onSuccess,
            onError,
          }) => {
            if (visible && !visible(original)) return null;
            else if (roles) {
              const currentRole = appContext.currentRole;
              const hasRole = currentRole && roles?.includes(currentRole);
              if (!isAuthenticated() || !hasRole) return null;
            } else if (forbiddenRoles) {
              // Get the user's roles from getAuthInfo()
              const userRoles = getAuthInfo().roles;

              // Check whether any of the user's roles are forbidden
              const isForbidden = forbiddenRoles?.some((forbiddenRole) =>
                userRoles.includes(forbiddenRole),
              );
              if (isForbidden) return null;
            }

            return (
              <Tooltip title={title} key={`${id}`}>
                <Box display="inline" onClick={(e) => e.stopPropagation()}>
                  <LoadingIcon
                    showProgress={showProgress}
                    render={(setLoading) => (
                      <IconButton
                        className={classes.iconButton}
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await onAction(original);
                            if (onSuccess) onSuccess();
                          } catch (e) {
                            if (onError && e instanceof Error) onError(e);
                            else throw e;
                          } finally {
                            setLoading(false);
                          }
                        }}
                        size="large"
                      >
                        {Icon ? (
                          <Icon style={{ color: color || 'inherit' }}>
                            {title}
                          </Icon>
                        ) : (
                          title
                        )}
                      </IconButton>
                    )}
                  />
                </Box>
              </Tooltip>
            );
          },
        )}
      </span>
    ),
  };
};

const LoadingIcon: React.FC<{
  showProgress: boolean;
  render: (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => React.ReactElement;
}> = ({ render, showProgress }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  return showProgress && loading ? (
    <CircularProgress size={24} className={classes.iconLoading} />
  ) : (
    render(setLoading)
  );
};
