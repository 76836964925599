import { Box, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../../../../common/components/form/fields/SelectField';
import { TextField } from '../../../../../../../common/components/form/fields/TextField';
import { Outline } from '../../../../../../../common/components/form/LabeledOutline';
import { ActionColor } from '../../../../../../../common/types/actions';
import {
  StudyType,
  useAppConfigQuery,
} from '../../../../../../../generated/graphql';

export const StudyTypeDevices = () => {
  const { control } = useFormContext<StudyType>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray<
    StudyType,
    'studyTypeDevices'
  >({
    name: 'studyTypeDevices',
    control,
  });

  const { data: appConfig } = useAppConfigQuery();

  const deviceTypes = appConfig?.appConfig?.deviceTypes;
  if (!deviceTypes) return null;

  return (
    <Outline label={t('settings.studyType.sections.deviceTimes')}>
      {fields.map((studyTypeDevice, index) => (
        <Box key={studyTypeDevice.id} display="flex" gap={6} pt={4}>
          <SelectField
            name={`studyTypeDevices[${index}].deviceType`}
            options={deviceTypes.map((deviceGroup) => ({
              label: deviceGroup,
              value: deviceGroup,
            }))}
            defaultValue={studyTypeDevice.deviceType}
            label={t('app.common.fields.devices.type')}
          />
          <TextField
            name={`studyTypeDevices[${index}].durationMinutes`}
            defaultValue={studyTypeDevice.durationMinutes}
            label={t('app.common.fields.devices.duration')}
            valueAsNumber
          />
          <IconButton
            aria-label="delete"
            style={{ color: ActionColor.RED }}
            onClick={() => remove(index)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Box mt={6}>
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={() => append({ deviceType: '', durationMinutes: 0 })}
        >
          {t('settings.studyType.buttons.addNewDevice')}
        </Button>
      </Box>
    </Outline>
  );
};
