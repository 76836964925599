import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const useFieldRegister = (name: string) => {
  const { register, unregister } = useFormContext();

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [name, register, unregister]);
};
