import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DateTimeField } from '../../../../../../common/components/form/fields/DateTimeField';
import { RichTextField } from '../../../../../../common/components/form/fields/RichTextField';
import { SelectField } from '../../../../../../common/components/form/fields/SelectField';
import { SwitchField } from '../../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { Outline } from '../../../../../../common/components/form/LabeledOutline';
import {
  FileType,
  PaymentTypeEnum,
  StudyDescriber,
  UserRole,
  Gender,
  StudyStatus,
} from '../../../../../../generated/graphql';
import { Protect } from '../../../../components/auth/components/Protect';
import { useAppContextState } from '../../../../components/settings/hooks/useSettingsState';
import { BranchSelect } from '../../../common/fields/BranchSelect';
import { StudyTypeAutocomplete } from '../../../common/fields/StudyTypeAutocomplete';
import { DoctorAutocomplete } from '../../../doctors/fields/DoctorAutocomplete';
import { PatientAutocomplete } from '../../../patients/fields/PatientAutocomplete';
import { UserAutocomplete } from '../../../users/fields/UserAutocomplete';
import { DescriberDescription } from '../components/DescriberDescription';
import { useStyles } from '../StudyForm.styles';
import { StudyFormType } from '../../types';
import { StudyRadiology } from '../components/StudyRadiology';
import { useTranslation } from 'react-i18next';
import { usePayments } from '../../hooks/usePayments';

type Props = {
  disabled: boolean;
  study?: StudyFormType;
};

export const GeneralInfoTab: React.FC<Props> = ({ disabled, study }) => {
  const classes = useStyles();
  const [appContext] = useAppContextState();
  const { t } = useTranslation();
  const { recalculatePayments } = usePayments();

  const { setValue, watch, formState, getValues } = useFormContext<
    StudyFormType
  >();

  return (
    <Grid container className={classes.grid} direction="column">
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <DateTimeField
            variant="outlined"
            name="studyDate"
            label="Data badania"
            disabled={disabled || appContext.currentRole !== UserRole.Admin}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <StudyTypeAutocomplete
            branchId={getValues('branch.id')}
            autocompleteProps={{
              disabled: disabled || !!watch('id'),
              onChange: (_, studyType) => {
                setValue('describingDoctors', []);

                if (
                  studyType &&
                  studyType.studyTypePrices &&
                  studyType.studyTypePrices.length > 0
                ) {
                  recalculatePayments();
                }
              },
            }}
            required
            multiple={false}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectField
            fullWidth
            name="status"
            disabled={disabled}
            options={Object.values(StudyStatus).map((status) => ({
              value: status,
              label: t(`fields.study.status.options.${status}`),
            }))}
            label={t('fields.study.status.label')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <PatientAutocomplete
            withAddButton={!disabled}
            withPreviousStudiesButton={disabled}
            autocompleteProps={{
              disabled,
              onChange: (_, patient) => {
                if (patient?.contact?.birthDate) {
                  const gender = patient?.contact?.gender;

                  if (gender) {
                    const adultPresetName =
                      gender === Gender.Female ? 'Kobieta' : 'Mężczyzna';
                    setValue('radiology.presetName', adultPresetName);
                  }
                }
              },
              freeSolo: false,
              multiple: false,
            }}
            controllerProps={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <UserAutocomplete
            role={UserRole.Technician}
            controllerProps={{
              required: true,
              label: 'Wykonał',
              name: 'performer',
            }}
            autocompleteProps={{
              disabled,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <BranchSelect
            ownOnly
            label="Filia"
            name="branch.id"
            disabled={disabled || appContext.currentRole !== UserRole.Admin}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            gap={4}
            flexDirection={{ xs: 'column', lg: 'row' }}
          >
            <DoctorAutocomplete
              controllerProps={{
                name: 'leadingDoctor',
                label: 'Lekarz Prowadzący / Firma',
                required: true,
              }}
              autocompleteProps={{
                disabled,
                style: { flexGrow: 2 },
              }}
              showNoUserDoctors
              showFacilities={false}
            />
            <SelectField
              label="Placówka lekarza"
              options={[
                ...(watch('leadingDoctor')?.employedInBranches || []).map(
                  ({ name, id }) => ({
                    label: name,
                    value: id,
                  }),
                ),
              ]}
              fullWidth
              className={classes.branchSelect}
              name="leadingDoctorBranch.id"
            />
          </Box>
          {watch('leadingDoctor')?.user.contact.primaryDescription && (
            <Box py={2}>
              <Alert severity="info">
                {watch('leadingDoctor')?.user.contact.primaryDescription}
              </Alert>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <DoctorAutocomplete
            controllerProps={{
              label: 'Lekarze opisujący',
              name: 'describingDoctors',
            }}
            autocompleteProps={{
              multiple: true,
              onChange: (_, value) => {
                if (!formState.dirtyFields?.notifyDescribers) {
                  setValue('notifyDescribers', !!value?.length);
                }
              },
              disabled: disabled || !watch('studyType'),
              getOptionDisabled: (doctor) => {
                const { vacationStart, vacationEnd } = doctor?.user;

                let onVacation = false;

                if (!vacationStart && !vacationEnd) {
                  onVacation = false;
                }
                const today = new Date().getTime();
                if (vacationStart && vacationEnd) {
                  const vacationStartTime = new Date(vacationStart).getTime();
                  const vacationEndTime = new Date(vacationEnd).getTime();
                  onVacation =
                    today > vacationStartTime && today < vacationEndTime;
                } else if (vacationStart) {
                  const vacationStartTime = new Date(vacationStart).getTime();
                  onVacation = today > vacationStartTime;
                } else if (vacationEnd) {
                  const vacationEndTime = new Date(vacationEnd).getTime();
                  onVacation = today < vacationEndTime;
                }

                let overBooked;

                if (
                  !(doctor.maxStudyHoursToDescribe || 0) ||
                  !doctor?.describerInfo?.descriptionOccupancyHours
                ) {
                  overBooked = false;
                }
                overBooked =
                  (doctor?.describerInfo?.descriptionOccupancyHours || 0) +
                    (watch('studyType')?.timeToDescribe || 0) / 60 >
                  (doctor.maxStudyHoursToDescribe ||
                    0 ||
                    Number.MAX_SAFE_INTEGER);

                return overBooked || onVacation;
              },
            }}
            roles={[UserRole.DescribingDoctor]}
            describingStudyTypeId={watch('studyType')?.id}
            hideIfEmpty
          />

          {disabled &&
            study &&
            watch('describers')?.map((describer) => (
              <Protect
                key={describer.id}
                roles={[
                  UserRole.Admin,
                  UserRole.DescribingDoctor,
                  UserRole.Technician,
                  UserRole.Manager,
                ]}
              >
                <DescriberDescription
                  studyId={study.id}
                  describer={describer as StudyDescriber}
                  studyHasDescriptionAlready={
                    !!study.files?.some(
                      (file) => file.fileType === FileType.StudyDescription,
                    )
                  }
                  disabled={disabled}
                />
              </Protect>
            ))}
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={10}>
          <DoctorAutocomplete
            controllerProps={{
              label: 'Lekarze wspomagający',
              name: 'supportingDoctors',
            }}
            autocompleteProps={{ disabled, multiple: true }}
            showNoUserDoctors
            hideIfEmpty
          />
          {!disabled &&
            watch('supportingDoctors')?.map(
              (supportingDoctor) =>
                supportingDoctor.user.contact.primaryDescription && (
                  <Box py={2}>
                    <Alert severity="info">
                      <b>
                        {supportingDoctor.user.contact.firstName}{' '}
                        {supportingDoctor.user.contact.lastName}{' '}
                      </b>{' '}
                      {supportingDoctor.user.contact.primaryDescription}
                    </Alert>
                  </Box>
                ),
            )}
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        {((appContext.currentRole !== UserRole.Doctor &&
          appContext.currentRole !== UserRole.DentalOffice &&
          appContext.currentRole !== UserRole.Laboratory) ||
          !study?.incognitoDescription) && (
          <Grid item xs={12} md={8}>
            <RichTextField
              name="descriptionRequestComment"
              label="Prośba o opis"
              defaultValue={study?.descriptionRequestComment}
              readOnly={disabled}
              hideIfEmpty
              onBlur={() => {
                const payments = getValues('payments');
                const studyType = getValues('studyType');

                if (
                  getValues('descriptionRequestComment') &&
                  payments &&
                  !payments.find(
                    ({ type }) => type === PaymentTypeEnum.Description,
                  ) &&
                  studyType
                ) {
                  setValue('payments', [
                    ...payments,
                    {
                      type: PaymentTypeEnum.Description,
                      method: payments[0]?.method,
                      value: studyType.descriptionPrice,
                      discount: 0,
                    },
                  ]);
                }
              }}
            />
          </Grid>
        )}
        <Protect
          roles={[
            UserRole.Admin,
            UserRole.Technician,
            UserRole.DescribingDoctor,
            UserRole.Manager,
          ]}
        >
          <Grid item xs={12} md={4}>
            <Box pt={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <SwitchField
                    label="Opis Incognito"
                    name="incognitoDescription"
                    disabled={disabled}
                    onChange={(_, checked) =>
                      setValue(
                        'notifyDoctorsAboutDescription',
                        watch('hidden') ? false : !checked,
                      )
                    }
                  />
                  <SwitchField
                    label="Opis CITO"
                    name="urgentDescription"
                    disabled={disabled}
                    onChange={(_, checked) => {
                      if (checked) {
                        setValue('urgentDescription', true);
                        setValue('comparisonDescription', false);
                      } else {
                        setValue('urgentDescription', false);
                      }
                      recalculatePayments();
                    }}
                  />
                  <SwitchField
                    label="Opis porównawczy"
                    name="comparisonDescription"
                    disabled={disabled}
                    onChange={(_, checked) => {
                      if (checked) {
                        setValue('comparisonDescription', true);
                        setValue('urgentDescription', false);
                      } else {
                        setValue('comparisonDescription', false);
                      }
                      recalculatePayments();
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SwitchField
                    label="Ukryj badanie (brak zgody)"
                    name="hidden"
                    disabled={disabled}
                    onChange={(_, checked) => {
                      if (checked) {
                        setValue('notifyLeaderAndSupportingDoctors', false);
                        setValue('notifyDoctorsAboutDescription', false);
                      } else {
                        setValue('notifyLeaderAndSupportingDoctors', true);
                        setValue(
                          'notifyDoctorsAboutDescription',
                          !!watch('describingDoctors')?.length,
                        );
                      }
                    }}
                  />
                  <SwitchField
                    name="externalReferal"
                    label={t('fields.study.externalReferal')}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Protect>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            name="importantInformation"
            label="Ważne informacje"
            multiline
            minRows={2}
            maxRows={8}
            fullWidth
            disabled={disabled}
            hideIfEmpty
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Protect
            roles={[
              UserRole.Admin,
              UserRole.Technician,
              UserRole.DescribingDoctor,
              UserRole.Manager,
            ]}
          >
            <TextField
              name="internalInformation"
              label="Informacje wewnętrzne"
              fullWidth
              multiline
              minRows={2}
              maxRows={8}
              disabled={disabled}
              hideIfEmpty
            />
          </Protect>
        </Grid>
      </Grid>
      <Protect
        roles={[
          UserRole.Admin,
          UserRole.Technician,
          UserRole.Manager,
          UserRole.DescribingDoctor,
        ]}
      >
        <Box mt={4} gap={16} display="flex" flexDirection="column">
          <Outline label="Powiadomienia przy tworzeniu badania">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SwitchField
                  name="notifyLeaderAndSupportingDoctors"
                  label="Do lekarza prowadzącego i lekarzy wspomagających o nowym badaniu"
                  disabled={disabled || !!watch('hidden')}
                  onChange={(_, checked) => {
                    if (checked && getValues().incognitoDescription)
                      setValue('incognitoDescription', false);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SwitchField
                  name="notifyDoctorsAboutDescription"
                  label="O opisie do lekarzy"
                  disabled={
                    disabled ||
                    !!getValues().incognitoDescription ||
                    !!watch('hidden')
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SwitchField
                  name="notifyDescribers"
                  label="Do lekarzy opisujących"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Outline>
          <Outline label="Dane radiologiczne">
            <StudyRadiology disabled={disabled} />
          </Outline>
        </Box>
      </Protect>
    </Grid>
  );
};
