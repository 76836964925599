import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { Mail } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useUnreadMessages } from '../hooks/useUnreadMessages';

export const MessageBadge: React.FC = () => {
  const { unreadCount } = useUnreadMessages();
  const { t } = useTranslation();

  return unreadCount ? (
    <Box mx={4}>
      <Tooltip title={t('app.messages.badge.title') as string}>
        <IconButton component={Link} to="/messages" size="large">
          <Badge color="error" variant="standard" badgeContent={unreadCount}>
            <Mail />
          </Badge>
        </IconButton>
      </Tooltip>
    </Box>
  ) : null;
};
