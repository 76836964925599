import { Column } from 'react-table';
import { UserRole } from '../../generated/graphql';
import { ColumnVisibilityConfig } from '../types/table';

export const filterColumns = <T extends object = {}>(
  columns: Column<T>[],
  columnVisibilityConfig: ColumnVisibilityConfig,
  currentRole: UserRole,
) =>
  columns.filter(
    ({ id = '' }) =>
      !columnVisibilityConfig[id] ||
      columnVisibilityConfig[id].includes(currentRole),
  );
