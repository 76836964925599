import { yupResolver } from '@hookform/resolvers/yup';
import { AddToQueue, Description } from '@mui/icons-material';
import { Alert, Box, Grid, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../../../common/components/form/Form';
import { Outline } from '../../../../../common/components/form/LabeledOutline';
import { DateTimeField } from '../../../../../common/components/form/fields/DateTimeField';
import { DropzoneField } from '../../../../../common/components/form/fields/DropzoneField';
import { RichTextField } from '../../../../../common/components/form/fields/RichTextField';
import { SelectField } from '../../../../../common/components/form/fields/SelectField';
import { SwitchField } from '../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../common/components/form/fields/TextField';
import { schema } from '../../../../../common/validation/study/schema';
import {
  FileType,
  StudyDescriber,
  UserRole,
} from '../../../../../generated/graphql';
import { Protect } from '../../../components/auth/components/Protect';
import { AttachedFiles } from '../../../components/files/AttachedFiles';
import { useAppContextState } from '../../../components/settings/hooks/useSettingsState';
import { BranchSelect } from '../../common/fields/BranchSelect';
import { StudyTypeAutocomplete } from '../../common/fields/StudyTypeAutocomplete';
import { DoctorAutocomplete } from '../../doctors/fields/DoctorAutocomplete';
import { PatientAutocomplete } from '../../patients/fields/PatientAutocomplete';
import { UserAutocomplete } from '../../users/fields/UserAutocomplete';
import { StudyFormProps, StudyFormType } from '../types';
import { useStyles } from './StudyForm.styles';
import { DescriberDescription } from './components/DescriberDescription';

//TODO: Refactor to take advantage of `Form` common component
export const StudyForm: React.FC<PropsWithChildren<StudyFormProps>> = ({
  onSubmit,
  onDeleteFile,
  study,
  disabled = true,
  children,
  withAddPatient = false,
  isSupportingDoctor = false,
}) => {
  const classes = useStyles();
  const [appContext] = useAppContextState();
  const { t } = useTranslation();

  return (
    <Form<StudyFormType>
      onSubmit={onSubmit as any}
      watchFields={[
        'incognitoDescription',
        'leadingDoctor',
        'supportingDoctors',
        'describers',
        'describingDoctors',
        'studyType',
      ]}
      showLeaveUnsavedPrompt={!disabled}
      formOptions={{
        defaultValues: study,
        resolver: yupResolver(schema) as any,
      }}
      render={({ setValue, formState, getValues, watch }) => (
        <>
          <Grid container className={classes.grid} direction="column">
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <DateTimeField
                  variant="outlined"
                  name="studyDate"
                  label="Data badania"
                  disabled={disabled}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <StudyTypeAutocomplete
                  autocompleteProps={{
                    disabled,
                  }}
                  required
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} md={6}>
                <PatientAutocomplete
                  withAddButton={withAddPatient}
                  withPreviousStudiesButton={disabled}
                  autocompleteProps={{
                    disabled,
                  }}
                  controllerProps={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UserAutocomplete
                  role={UserRole.Technician}
                  controllerProps={{
                    required: true,
                    label: 'Wykonał',
                    name: 'performer',
                  }}
                  autocompleteProps={{
                    disabled,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <BranchSelect
                  ownOnly
                  label="Filia"
                  name="branch.id"
                  disabled={disabled}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box display="flex" gap={4}>
                  <DoctorAutocomplete
                    controllerProps={{
                      name: 'leadingDoctor',
                      label: 'Lekarz Prowadzący / Firma',
                      required: true,
                    }}
                    autocompleteProps={{
                      disabled,
                      style: { flexGrow: 2 },
                    }}
                    showNoUserDoctors
                    showFacilities={false}
                  />
                  <SelectField
                    label="Placówka lekarza"
                    options={[
                      ...(watch('leadingDoctor')?.employedInBranches || []).map(
                        ({ name, id }) => ({
                          label: name,
                          value: id,
                        }),
                      ),
                    ]}
                    withDefault
                    fullWidth
                    className={classes.branchSelect}
                    name="leadingDoctorBranch.id"
                    disabled={disabled}
                  />
                </Box>
                {watch('leadingDoctor')?.user.contact.primaryDescription && (
                  <Box py={2}>
                    <Alert severity="info">
                      {watch('leadingDoctor')?.user.contact.primaryDescription}
                    </Alert>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <DoctorAutocomplete
                  controllerProps={{
                    label: 'Lekarze opisujący',
                    name: 'describingDoctors',
                  }}
                  autocompleteProps={{
                    multiple: true,
                    onChange: (_, value) => {
                      if (!formState.dirtyFields?.notifyDescribers) {
                        setValue('notifyDescribers', !!value?.length);
                      }
                    },
                    disabled: disabled || !watch('studyType'),
                    getOptionDisabled: (doctor) => {
                      const { vacationStart, vacationEnd } = doctor?.user;

                      let onVacation = false;

                      if (!vacationStart && !vacationEnd) {
                        onVacation = false;
                      }
                      const today = new Date().getTime();
                      if (vacationStart && vacationEnd) {
                        const vacationStartTime = new Date(
                          vacationStart,
                        ).getTime();
                        const vacationEndTime = new Date(vacationEnd).getTime();
                        onVacation =
                          today > vacationStartTime && today < vacationEndTime;
                      } else if (vacationStart) {
                        const vacationStartTime = new Date(
                          vacationStart,
                        ).getTime();
                        onVacation = today > vacationStartTime;
                      } else if (vacationEnd) {
                        const vacationEndTime = new Date(vacationEnd).getTime();
                        onVacation = today < vacationEndTime;
                      }

                      let overBooked;

                      if (
                        !(doctor.maxStudyHoursToDescribe || 0) ||
                        !doctor?.describerInfo?.descriptionOccupancyHours
                      ) {
                        overBooked = false;
                      }
                      overBooked =
                        (doctor?.describerInfo?.descriptionOccupancyHours ||
                          0) +
                          (watch('studyType')?.timeToDescribe || 0) / 60 >
                        (doctor.maxStudyHoursToDescribe ||
                          0 ||
                          Number.MAX_SAFE_INTEGER);

                      return overBooked || onVacation;
                    },
                  }}
                  roles={[UserRole.DescribingDoctor]}
                  describingStudyTypeId={watch('studyType')?.id}
                  hideIfEmpty
                />

                {disabled &&
                  study &&
                  watch('describers')?.map((describer) => (
                    <Protect
                      roles={[
                        UserRole.Admin,
                        UserRole.DescribingDoctor,
                        UserRole.Technician,
                        UserRole.Manager,
                      ]}
                    >
                      <DescriberDescription
                        studyId={study.id}
                        describer={describer as StudyDescriber}
                        studyHasDescriptionAlready={
                          !!study.files?.some(
                            (file) =>
                              file.fileType === FileType.StudyDescription,
                          )
                        }
                        disabled={disabled}
                      />
                    </Protect>
                  ))}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={10}>
                <DoctorAutocomplete
                  controllerProps={{
                    label: 'Lekarze wspomagający',
                    name: 'supportingDoctors',
                  }}
                  autocompleteProps={{ disabled, multiple: true }}
                  hideIfEmpty
                  showNoUserDoctors
                />
                {!disabled &&
                  watch('supportingDoctors')?.map(
                    (supportingDoctor) =>
                      supportingDoctor.user.contact.primaryDescription && (
                        <Box py={2}>
                          <Alert severity="info">
                            <b>
                              {supportingDoctor.user.contact.firstName}{' '}
                              {supportingDoctor.user.contact.lastName}{' '}
                            </b>{' '}
                            {supportingDoctor.user.contact.primaryDescription}
                          </Alert>
                        </Box>
                      ),
                  )}
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              {((appContext.currentRole !== UserRole.Doctor &&
                appContext.currentRole !== UserRole.DentalOffice &&
                appContext.currentRole !== UserRole.Laboratory) ||
                !study?.incognitoDescription) && (
                <Grid item xs={12} md={6}>
                  <RichTextField
                    name="descriptionRequestComment"
                    label="Prośba o opis"
                    defaultValue={study?.descriptionRequestComment}
                    readOnly={disabled}
                    hideIfEmpty
                  />
                </Grid>
              )}
              <Protect
                roles={[
                  UserRole.Admin,
                  UserRole.Technician,
                  UserRole.DescribingDoctor,
                  UserRole.Manager,
                ]}
              >
                <Grid item xs={12} md={2}>
                  <Box pt={4}>
                    <SwitchField
                      label="Opis Incognito"
                      name="incognitoDescription"
                      disabled={disabled}
                      onChange={(_, checked) =>
                        setValue('notifyDoctorsAboutDescription', !checked)
                      }
                    />
                  </Box>
                </Grid>
              </Protect>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="importantInformation"
                  label="Ważne informacje"
                  multiline
                  minRows={2}
                  maxRows={8}
                  fullWidth
                  disabled={disabled}
                  hideIfEmpty
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Protect
                  roles={[
                    UserRole.Admin,
                    UserRole.Technician,
                    UserRole.DescribingDoctor,
                    UserRole.Manager,
                  ]}
                >
                  <TextField
                    name="internalInformation"
                    label="Informacje wewnętrzne"
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={8}
                    disabled={disabled}
                    hideIfEmpty
                  />
                </Protect>
              </Grid>
            </Grid>
            <Box mt={4}>
              <Protect
                roles={[UserRole.Admin, UserRole.Technician, UserRole.Manager]}
              >
                <Outline label="Powiadomienia przy tworzeniu badania">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <SwitchField
                        name="notifyLeaderAndSupportingDoctors"
                        label="Do lekarza prowadzącego i lekarzy
                wspomagających o nowym badaniu"
                        disabled={disabled}
                        onChange={(_, checked) => {
                          if (checked && getValues().incognitoDescription)
                            setValue('incognitoDescription', false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <SwitchField
                        name="notifyDoctorsAboutDescription"
                        label="O opisie do lekarzy"
                        disabled={
                          disabled || !!getValues().incognitoDescription
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <SwitchField
                        name="notifyDescribers"
                        label="Do lekarzy opisujących"
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                </Outline>
              </Protect>
            </Box>
          </Grid>
          <Box mb={12}>
            <Grid container spacing={4} direction="row">
              <Grid item xs={12} md={4}>
                <Box display="flex" flexDirection="column" gap={8}>
                  <Typography variant="h6" align="center">
                    {t('app.common.fields.attachedFiles.title.study')}
                  </Typography>
                  {!disabled && (
                    <DropzoneField
                      fileTypeLabel="plik z badaniem"
                      name="files"
                      required
                      onDeleteFile={onDeleteFile}
                      fileType={FileType.StudyImage}
                      Icon={AddToQueue}
                      iconColor="#7900bf"
                    />
                  )}
                  {study &&
                    onDeleteFile &&
                    !!study?.files?.filter(
                      ({ fileType }) => fileType === FileType.StudyImage,
                    )?.length && (
                      <AttachedFiles
                        title=""
                        files={study.files.filter(
                          ({ fileType }) => fileType === FileType.StudyImage,
                        )}
                        studyId={study.id}
                        onDeleteFile={onDeleteFile}
                        showActions={!disabled}
                      />
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display="flex" flexDirection="column" gap={8}>
                  <Typography variant="h6" align="center">
                    {t('app.common.fields.attachedFiles.title.description')}
                  </Typography>
                  {!disabled && (
                    <DropzoneField
                      name="files"
                      fileTypeLabel="plik z opisem"
                      onFilesUploaded={(uploadedFiles) => {
                        if (uploadedFiles?.length) {
                          setValue('notifyDoctorsAboutDescription', true);
                        }
                      }}
                      onDeleteFile={onDeleteFile}
                      fileType={FileType.StudyDescription}
                      Icon={Description}
                      iconColor="#0277bd"
                    />
                  )}
                  {study &&
                    onDeleteFile &&
                    !!study?.files?.filter(
                      ({ fileType }) => fileType === FileType.StudyDescription,
                    )?.length && (
                      <AttachedFiles
                        title=""
                        files={study.files.filter(
                          ({ fileType }) =>
                            fileType === FileType.StudyDescription,
                        )}
                        studyId={study.id}
                        onDeleteFile={onDeleteFile}
                        showActions={!disabled}
                      />
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display="flex" flexDirection="column" gap={8}>
                  <Typography variant="h6" align="center">
                    {t('app.common.fields.attachedFiles.title.referal')}
                  </Typography>
                  {study &&
                    onDeleteFile &&
                    !!study?.files?.filter(
                      ({ fileType }) => fileType === FileType.StudyReferal,
                    )?.length &&
                    !isSupportingDoctor && (
                      <AttachedFiles
                        title=""
                        files={study.files.filter(
                          ({ fileType }) => fileType === FileType.StudyReferal,
                        )}
                        studyId={study.id}
                        onDeleteFile={onDeleteFile}
                        showActions={!disabled}
                      />
                    )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {children}
        </>
      )}
    ></Form>
  );
};
