import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PartialDeep } from 'type-fest';
import { SwitchField } from '../../../../../../common/components/form/fields/SwitchField';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import {
  User,
  UserRole,
  useAppConfigQuery,
} from '../../../../../../generated/graphql';
import { useAppContextState } from '../../../settings/hooks/useSettingsState';
import { TagsField } from '../../../tags/TagsField';
import { Protect } from '../../components/Protect';

export const DEFAULT_USER = {
  contact: {
    firstName: '',
    lastName: '',
    phoneNumbers: [],
    emailAddresses: [],
    companyName: '',
    primaryDescription: '',
    birthDate: null,
    nip: '',
  },
  active: false,
  emailConfirmed: false,
  regions: [],
};

type Props = {
  prefix?: string;
  user?: PartialDeep<User>;
  disabled?: boolean;
  loginEditable?: boolean;
  roles?: string[];
  specializations?: string[] | null;
};

export const UserForm: React.FC<Props> = ({
  prefix = '',
  user,
  disabled = false,
  loginEditable = true,
  roles,
  specializations,
}) => {
  const { t } = useTranslation();

  const isNoUser = user?.login?.startsWith('NO_USER');

  const { data } = useAppConfigQuery();
  const [{ currentRole }] = useAppContextState();

  return (
    <>
      <Grid container direction="column">
        {loginEditable ? (
          <Protect
            roles={[UserRole.Admin, UserRole.Technician, UserRole.Manager]}
          >
            <Grid item>
              <TextField
                name={`${prefix}login`}
                label="Login (e-mail)"
                required
                fullWidth
              />
            </Grid>
          </Protect>
        ) : (
          !isNoUser && (
            <Box mb={4}>
              <Typography
                variant="body1"
                color={disabled ? 'textSecondary' : 'textPrimary'}
              >
                Login: {user?.login}
              </Typography>
            </Box>
          )
        )}
        <Grid container spacing={4}>
          {user?.contact?.companyName !== null ? (
            <Grid item xs={12}>
              <TextField
                name={`${prefix}contact.companyName`}
                label="Nazwa firmy"
                disabled={disabled}
                required
                fullWidth
              />
              <TextField
                name={`${prefix}contact.nip`}
                label="NIP"
                disabled={disabled}
                required
                fullWidth
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  name={`${prefix}contact.firstName`}
                  label="Imię"
                  disabled={disabled}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name={`${prefix}contact.lastName`}
                  label="Nazwisko"
                  disabled={disabled}
                  required
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
        <Protect roles={[UserRole.Admin]}>
          <Grid item>
            {user?.contact?.companyName === null && (
              <TagsField
                name="specializations"
                freeSolo={false}
                label="Specjalizacja"
                placeholder="Dodaj specjalizacje..."
                disabled={disabled}
                options={
                  Array.isArray(specializations)
                    ? [...specializations]
                    : ['Nie skonfigurowano specjalizacji']
                }
              />
            )}
          </Grid>
        </Protect>
      </Grid>
      <Protect roles={[UserRole.Admin, UserRole.Technician, UserRole.Manager]}>
        <TextField
          name={`${prefix}contact.primaryDescription`}
          label="Notatka..."
          defaultValue={user?.contact?.primaryDescription || ''}
          minRows={4}
          disabled={disabled}
          maxRows={8}
          multiline
          fullWidth
        />
      </Protect>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Protect roles={[UserRole.Admin]}>
            <TagsField
              name={`${prefix}regions`}
              freeSolo={false}
              label="Regiony"
              placeholder="Przypisz regiony..."
              disabled={disabled || currentRole !== UserRole.Admin}
              options={
                data?.appConfig.regions || ['Nie skonfigurowano regionów']
              }
            />
          </Protect>
        </Grid>
        {!isNoUser && (
          <>
            <Grid item xs={12} md={9}>
              <Protect roles={[UserRole.Admin]}>
                <TagsField
                  name={`${prefix}roles`}
                  freeSolo={false}
                  label="Role w systemie"
                  placeholder="Dodaj role..."
                  disabled={disabled}
                  fixedOptions={[UserRole.Doctor]}
                  options={
                    roles || [
                      UserRole.Admin,
                      UserRole.Complaints,
                      UserRole.HelpContact,
                      UserRole.Registration,
                      UserRole.Technician,
                      UserRole.Manager,
                      UserRole.DentalOffice,
                      UserRole.Laboratory,
                    ]
                  }
                  getOptionLabel={(role) => t(`auth.roles.${role}`)}
                />
              </Protect>
            </Grid>
            <Protect roles={[UserRole.Admin]}>
              <Grid item xs={12} md={3}>
                <Box pt={8}>
                  <SwitchField
                    name={`${prefix}active`}
                    label="Konto Aktywne"
                    disabled={disabled}
                  />
                </Box>
              </Grid>
            </Protect>
          </>
        )}
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TagsField
            name={`${prefix}contact.phoneNumbers`}
            label="Numery telefonów"
            placeholder="Dodaj kolejny..."
            disabled={disabled}
            required
          />
        </Grid>
      </Grid>
    </>
  );
};
