import { useMessages } from '../../../../../common/hooks/useMessages';
import {
  useAddMailingMutation,
  useUpdateMailingMutation,
  MailingInput,
  useDeleteMailingMutation,
  useStartMailingMutation,
  useStopMailingMutation,
  MailingsQuery,
  MailingsDocument,
} from '../../../../../generated/graphql';
import { useHistory, useRouteMatch } from 'react-router';
import { useRemoveConfirm } from '../../../../../common/hooks/useConfirmation';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import { useTranslation } from 'react-i18next';

export const useMailingApi = () => {
  const { success, deleteSuccess } = useMessages();
  const onError = useGraphQLError();
  const history = useHistory();
  const { t } = useTranslation();

  const [addMailingMutation] = useAddMailingMutation({
    onCompleted: () => success(),
    onError,
  });
  const [updateMailingMutation] = useUpdateMailingMutation({
    onCompleted: () => success(),
    onError,
  });
  const [deleteMailingMutation] = useDeleteMailingMutation({
    onCompleted: () => deleteSuccess(),
    onError,
  });
  const [startMailingMutation] = useStartMailingMutation({
    onError,
  });
  const [stopMailingMutation] = useStopMailingMutation({
    onError,
  });

  const { path } = useRouteMatch();
  const confirmRemoval = useRemoveConfirm();

  const addMailing = (mailingInput: MailingInput) => {
    addMailingMutation({
      variables: { newMailing: mailingInput },
      update: (cache, { data: newMailing }) => {
        const data = cache.readQuery<MailingsQuery>({
          query: MailingsDocument,
        });
        const mailing = data?.mailings.edges;

        if (mailing && newMailing) {
          cache.writeQuery<MailingsQuery>({
            query: MailingsDocument,
            data: {
              mailings: {
                edges: [
                  ...mailing,
                  {
                    node: newMailing?.createOneMailing,
                  },
                ],
              },
            },
          });
        }
      },
    });

    history.push(`${path}/`, { useCache: false });
  };

  const saveMailing = async (input: MailingInput, mailingId: number) => {
    await updateMailingMutation({
      variables: { mailing: input, mailingId },
      refetchQueries: [MailingsDocument],
    });

    history.push(`${path}/`, { useCache: false });
  };

  const deleteMailing = async (mailingId: number) => {
    await confirmRemoval();
    deleteMailingMutation({
      variables: { mailingId },
      update: (cache) => {
        const data = cache.readQuery<MailingsQuery>({
          query: MailingsDocument,
        });
        const mailing = data?.mailings.edges;

        if (mailing) {
          cache.writeQuery<MailingsQuery>({
            query: MailingsDocument,
            data: {
              mailings: {
                edges: mailing.filter(({ node: { id } }) => id !== mailingId),
              },
            },
          });
        }
      },
    });
  };

  const startMailing = async (mailingId: number) => {
    await startMailingMutation({
      variables: { mailingId },
      refetchQueries: [MailingsDocument],
    });

    success(t('settings.mailing.messages.mailingStarted'));
  };

  const stopMailing = async (mailingId: number) => {
    await stopMailingMutation({
      variables: { mailingId },
      refetchQueries: [MailingsDocument],
    });
    success(t('settings.mailing.messages.mailingStopped'));
  };

  return { addMailing, saveMailing, deleteMailing, startMailing, stopMailing };
};
