import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Mail,
  Person,
  Phone,
  SvgIconComponent,
  Notes,
  Cake,
  AssignmentInd,
} from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { Contact, HelpContact, Maybe } from '../../../generated/graphql';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';

import { getRichTextAsPlainText } from '../form/fields/RichTextField';
import { getUserInitials } from '../../utils/user.utils';
import { toLocaleDateTime } from '../../utils/date.utils';
import { DateTime } from 'luxon';

const useStyles = makeStyles(() => ({
  root: {
    // add your custom styles here
  },
  avatar: {
    width: 120,
    height: 120,
  },
  avatarIcon: {
    fontSize: '4rem',
  },
}));

type Props = {
  contact: Omit<Contact | HelpContact, 'id'>;
  actions?: React.ReactNode;
  className?: string;
  profilePictureURL?: Maybe<string>;
  showInitials?: boolean;
  showBirthDate?: boolean;
};

export const ContactCardSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
        >
          <Skeleton variant="circular" width={120} height={120} />

          <Box m={2} display="flex" flexDirection="column">
            <Skeleton variant="rectangular" width={250} height={20} />
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={200} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export const ContactCard: React.FC<Props> = ({
  contact,
  actions,
  className,
  profilePictureURL,
  showInitials = false,
  showBirthDate = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card className={cn(classes.root, className)}>
      <CardContent>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
        >
          {profilePictureURL ? (
            <Avatar
              className={classes.avatar}
              alt="profile-picture"
              src={`${profilePictureURL}?rand=${Math.floor(
                Math.random() * 10000,
              )}`}
            >
              {showInitials ? (
                getUserInitials(contact as Contact)
              ) : (
                <Person className={classes.avatarIcon} />
              )}
            </Avatar>
          ) : (
            <Avatar className={classes.avatar} alt="profile-picture">
              <Person className={classes.avatarIcon} />
            </Avatar>
          )}

          <Box ml={2}>
            {contact.firstName && (
              <Typography variant="h6">{`${contact.firstName} ${contact.lastName}`}</Typography>
            )}
            {contact.companyName && (
              <Typography variant="h6">{`${contact.companyName}`}</Typography>
            )}

            {contact.ssn && !isEmpty(contact.ssn) && (
              <ContactCardLine Icon={AssignmentInd}>
                <Typography variant="body2">{contact.ssn}</Typography>
              </ContactCardLine>
            )}
            {contact.nip && !isEmpty(contact.nip) && (
              <ContactCardLine Icon={AssignmentInd}>
                <Typography variant="body2">{contact.nip}</Typography>
              </ContactCardLine>
            )}
            {showBirthDate && (
              <ContactCardLine Icon={Cake}>
                {contact.birthDate && (
                  <Typography variant="body2">
                    {toLocaleDateTime(contact.birthDate, DateTime.DATE_SHORT)}
                  </Typography>
                )}
              </ContactCardLine>
            )}
            {contact.phoneNumbers && !isEmpty(contact.phoneNumbers) && (
              <ContactCardLine Icon={Phone}>
                <Typography variant="body2">
                  <Link href={`tel:${contact.phoneNumbers[0]}`}>
                    {contact.phoneNumbers[0]}
                  </Link>
                </Typography>
              </ContactCardLine>
            )}
            {contact.emailAddresses && !isEmpty(contact.emailAddresses) && (
              <ContactCardLine Icon={Mail}>
                <Typography variant="body2">
                  <Link
                    href={`mailto:${contact.emailAddresses[0]}`}
                    target="_blank"
                  >
                    {contact.emailAddresses[0]}
                  </Link>
                </Typography>
              </ContactCardLine>
            )}
            {contact.primaryDescription &&
              !isEmpty(contact.primaryDescription) && (
                <ContactCardLine Icon={Notes}>
                  <Tooltip
                    title={getRichTextAsPlainText(contact.primaryDescription)}
                    arrow
                  >
                    <Box textOverflow="ellipsis" width={200} overflow="hidden">
                      <Typography noWrap variant="body2">
                        {getRichTextAsPlainText(contact.primaryDescription)}
                      </Typography>
                    </Box>
                  </Tooltip>
                </ContactCardLine>
              )}
          </Box>
        </Box>
      </CardContent>
      {actions && (
        <Box display="flex" justifyContent="flex-end">
          <CardActions disableSpacing>{actions}</CardActions>
        </Box>
      )}
    </Card>
  );
};

interface ContactCardLineProps {
  Icon: SvgIconComponent;
  children: React.ReactNode;
}

const ContactCardLine: React.FC<ContactCardLineProps> = ({
  Icon,
  children,
}) => (
  <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
    <Box display="flex" alignItems="center" mr={2}>
      <Icon fontSize="small" color="disabled" />
    </Box>
    {children}
  </Box>
);
