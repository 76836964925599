import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { CenteredPage } from '../../../../../common/components/centered-page/CenteredPage';
import { LanguageSelector } from '../../../../../common/components/lang-selector/LanguageSelector';

import { TopBarLogo } from '../../../components/top-bar/logo/TopBarLogo';

export const PatientStudiesLogoutPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CenteredPage>
      <div
        style={{
          position: 'fixed',
          top: 60,
          right: 80,
          transform: 'scale(1.2)',
        }}
      >
        <LanguageSelector />
      </div>
      <Paper>
        <Box padding={8} flexDirection="column" gap={16} display="flex">
          <TopBarLogo alt="logo" src="/img/logo.svg" />
          <Alert severity="info">
            {t('pages.patient-studies.logout-message')}
          </Alert>
        </Box>
      </Paper>
    </CenteredPage>
  );
};
