import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import { ToggleOff, ToggleOn } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  TextFieldProps,
} from '../../../../../common/components/form/fields/TextField';

type Props = TextFieldProps & {
  validate: boolean;
  onValidationStateChange: (validationState: boolean) => void;
};

export const SSNField: React.FC<Props> = ({
  validate,
  onValidationStateChange,
  ...props
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const { t } = useTranslation();

  return (
    <TextField
      label={props.label || 'PESEL / Identyfikator'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={t('fields.contact.ssnValidation') as string}>
              <IconButton
                aria-label="Toggle SSN validation"
                onClick={() => onValidationStateChange(!validate)}
                onMouseDown={handleMouseDown}
                edge="end"
                disabled={props.disabled}
                size="large"
              >
                {validate ? (
                  <ToggleOn color="primary" />
                ) : (
                  <ToggleOff color="error" />
                )}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
