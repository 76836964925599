import * as yup from 'yup';
import { addressValidation } from '../../../../../../../common/validation/contact/schema';

export const branchValidationSchema = () =>
  yup.object().shape({
    name: yup.string().required(),
    address: yup.lazy((value: any) => {
      switch (typeof value) {
        case 'undefined':
          return yup.mixed().nullable();
        default:
          return addressValidation();
      }
    }),
  });
