import { TextField, TextFieldProps } from '@mui/material';
import { DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import type {} from '@mui/x-date-pickers/AdapterLuxon';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

type Props = Omit<DateTimePickerProps<DateTime>, 'renderInput'> &
  TextFieldProps & {
    onDateTimeChange?: (date: DateTime | null) => void;
  };

export const DateTimeField: React.FC<Props> = (props) => {
  const formContext = useFormContext();

  const { label, margin, variant, fullWidth, placeholder, required } = props;

  const anchorEl = useRef<HTMLDivElement>(null);

  return (
    <Controller
      name={props.name || ''}
      control={formContext?.control}
      render={({ field: { value, onChange }, formState }) => {
        // Convert to Luxon DateTime if the value is a string or a regular Date
        const dateValue = value ? DateTime.fromJSDate(new Date(value)) : null;

        if (!formState.dirtyFields[props.name || ''] && !value) {
          value = null;
        }

        const error = get(formState.errors, props.name || '');
        if (error && error.type === 'typeError') {
          error.message = 'Nieprawidłowy format daty';
        }

        return (
          <MobileDateTimePicker
            {...(props as any)}
            slots={{
              textField: (renderProps) => (
                <TextField
                  {...renderProps}
                  ref={anchorEl}
                  {...{
                    label,
                    margin: margin || 'dense',
                    variant: variant || 'outlined',
                    fullWidth,
                    placeholder,
                    required,
                  }}
                  onBlur={() => {
                    if (!dateValue || !isFinite(+dateValue?.toJSDate())) {
                      onChange(null);
                    }
                  }}
                  error={!!get(formState.errors, props.name || '')}
                  helperText={get(formState.errors, props.name || '')?.message}
                />
              ),
            }}
            value={dateValue}
            onChange={(date: DateTime | null) => {
              props.onDateTimeChange?.(date);
              const jsDate = date ? date.toJSDate() : null;
              onChange(jsDate);
              if (date?.isValid) {
                if (date?.isValid) formContext.trigger(props.name);
              }
            }}
          />
        );
      }}
    />
  );
};
