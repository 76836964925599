import createUseStatePersisted from 'use-persisted-state';
import { Branch, Maybe, UserRole } from '../../../../../generated/graphql';

export const initialState: Context = {
  currentBranch: null,
  currentRole: null,
  showAfterLogin: true,
};

export type Context = {
  currentBranch: Maybe<Omit<Branch, 'employedDoctors'>>;
  currentRole: Maybe<UserRole>;
  showAfterLogin: boolean;
};

export type ContextForm = {
  currentBranchId: Maybe<number>;
  currentRole: Maybe<UserRole>;
  showAfterLogin: boolean;
};

export const useAppContextState = () =>
  createUseStatePersisted('app-context')<Context>(initialState);
