import { Box, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useAuth } from '../../../../../common/hooks/useAuth';
import { useGraphQLError } from '../../../../../common/hooks/useGraphQLError';
import {
  useChangeLoginMutation,
  useChangePasswordMutation,
  UserRole,
} from '../../../../../generated/graphql';
import { useAppContextState } from '../../settings/hooks/useSettingsState';
import { ChangePassword } from './change-password/ChangePassword';
import { ConnectSocialLogin } from './connect-social-login/ConnectSocialLogin';
import { DoctorProfile } from './DoctorProfile';
import { ProfileActions } from './profile-actions/ProfileActions';
import { ProfilePicture } from './ProfilePicture';
import { UserPreferences } from './user/UserPreferences';
import { UserProfile } from './UserProfile';

export const ProfilePage: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { getAuthInfo } = useAuth();
  const [appContext] = useAppContextState();

  const snackBarError = useGraphQLError();
  const [changePassword] = useChangePasswordMutation({
    onError: snackBarError,
    onCompleted: () =>
      enqueueSnackbar('Hasło zostało zmienione', { variant: 'success' }),
  });

  const [changeLogin] = useChangeLoginMutation({
    onError: snackBarError,
    onCompleted: () =>
      enqueueSnackbar(
        'Login został zmieniony. Prosimy o aktywację nowego loginu klikając w link w wiadomości e-mail, którą do Państwa wysłaliśmy.',
        { variant: 'success' },
      ),
  });

  const onChangePassword = async (newPassword: string) => {
    await changePassword({
      variables: { newPassword },
    });
  };

  const onChangeLogin = async (newLogin: string) => {
    await changeLogin({
      variables: { newLogin },
    });
  };

  const { id } = getAuthInfo();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        {appContext.currentRole &&
        [
          UserRole.Doctor,
          UserRole.DescribingDoctor,
          UserRole.DentalOffice,
          UserRole.Laboratory,
        ].includes(appContext.currentRole) ? (
          <DoctorProfile />
        ) : (
          <UserProfile id={id} />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={4} direction="column">
          <Grid item>
            <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
              <ProfilePicture />
              <Box flex={1} ml={{ xs: 0, lg: 4 }} mt={{ xs: 4, lg: 0 }}>
                <UserPreferences />
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <ConnectSocialLogin />
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
              <Box flex={1}>
                <ProfileActions onChangeLogin={onChangeLogin} />
              </Box>
              <Box flex={1} ml={{ xs: 0, lg: 4 }} mt={{ xs: 4, lg: 0 }}>
                <ChangePassword onChangePassword={onChangePassword} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
