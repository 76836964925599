import { Button } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Patient } from '../../../../../../generated/graphql';
import { PatientAddDialog } from '../../add/PatientAddDialog';

type PatientAddButtonProps = {
  disabled?: boolean;
  name: string;
  patient: Patient;
};

export const PatientAddButton: React.FC<PatientAddButtonProps> = ({
  disabled,
  name,
  patient,
}) => {
  const [patientAddModalOpen, setPatientAddModalOpen] = useState(false);
  const { setValue, trigger } = useFormContext();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setPatientAddModalOpen(true)}
        disabled={disabled}
      >
        {patient?.id
          ? t('fields.patient.editButton')
          : t('fields.patient.addButton')}
      </Button>
      <PatientAddDialog
        onClose={async (newPatient) => {
          setPatientAddModalOpen(false);
          if (newPatient) {
            setValue(name, newPatient, {
              shouldDirty: true,
              shouldValidate: true,
            });
            await trigger(name);
          }
        }}
        open={patientAddModalOpen}
        patient={patient}
      />
    </Fragment>
  );
};
