import { createTheme, PaletteOptions } from '@mui/material/styles';
import { MUIRichTextEditor } from '../../common/components/form/fields/styles/RichTextField.styles';
import { plPL } from '@mui/material/locale';
import { plPL as plPLPicker } from '@mui/x-date-pickers/locales';

const palette: PaletteOptions = {
  primary: { main: '#3ab880', contrastText: '#fff' }, // Changed to a slightly darker green
  secondary: { main: '#3ab880' }, // Changed to a blue shade
  text: { disabled: 'rgba(0, 0, 0, 0.58)' },
  error: { main: '#d32f2f' }, // Slightly different red for error
};

const shape = {
  borderRadius: 6, // Increased border radius for a softer look
};

const typography = {
  fontFamily: [
    'Poppins', // New font added
    'Lato',
    'Open Sans',
    'Roboto',

    'Helvetica',
    'Arial',
    'Nunito Sans',
    'sans-serif',
  ].join(','),
  fontSize: 14, // Base font size
};

const spacing = 3;

export default createTheme(
  {
    palette,
    shape,
    spacing,
    typography,
    shadows: [
      'none',
      '0px 1px 3px rgba(0, 0, 0, 0.12)', // Light shadow for elevation
      '0px 1px 5px rgba(0, 0, 0, 0.2)',
      '0px 1px 8px rgba(0, 0, 0, 0.24)',
      '0px 1px 10px rgba(0, 0, 0, 0.26)',
      '0px 1px 14px rgba(0, 0, 0, 0.28)',
      '0px 1px 18px rgba(0, 0, 0, 0.30)',
    ],
    overrides: {
      MUIRichTextEditor: {
        ...MUIRichTextEditor,
      },
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiClockNumber: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.16)',
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              background: 'rgba(250, 250, 250, 0.8)', // Slightly changed background color
            },
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            zIndex: 9999,
            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)', // Added shadow for Floating Action Button
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            width: 'calc(100% - 55px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          shrink: {
            width: 'unset',
            whiteSpace: 'nowrap',
            overflow: 'unset',
            textOverflow: 'ellipsis',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 700,
            backgroundColor: '#f5f5f5', // Added background color to table headers
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&$selected': {
              backgroundColor: 'rgba(46, 125, 50, 0.07) !important', // Adjusted selected row color
            },
          },
          hover: {
            '&:hover': {
              backgroundColor: 'rgba(0, 136, 209, 0.1) !important', // Adjusted hover color
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '0.875em', // Slightly smaller font size for tooltips
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            paddingTop: 2,
            paddingBottom: 2,
            color: '#2e7d32', // Changed checkbox color
          },
        },
      },
    },
  } as any,
  plPLPicker,
  plPL,
);
