import { Chip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FileCopyOutlined } from '@mui/icons-material';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { get } from 'lodash-es';
import { FocusEvent, ReactNode, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { DEFAULT_DEBOUNCE_MILLIS } from '../../../../common/components/form/consts';
import {
  NameProps,
  SubmitOnChangeProps,
} from '../../../../common/components/form/types';
import { useMessages } from '../../../../common/hooks/useMessages';

type Props<FreeSolo extends boolean | undefined> = {
  label: string;
  options?: string[];
  fixedOptions?: string[];
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  submitOnChangeDelay?: number;
  handleOnChange?: (data: string[]) => void;
  autocomplete?: boolean;
} & NameProps &
  SubmitOnChangeProps &
  Partial<AutocompleteProps<string, true, true, FreeSolo>>;

export const TagsField = <FreeSolo extends boolean | undefined = undefined>(
  props: Props<FreeSolo>,
) => {
  const {
    autocomplete,
    label,
    options = [],
    fixedOptions = [],
    placeholder,
    disabled = false,
    name,
    required,
    freeSolo = true,
    submitOnChange,
    handleOnChange,
    submitOnChangeDelay = DEFAULT_DEBOUNCE_MILLIS,
    ...autocompleteProps
  } = props;

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    trigger,
    handleSubmit,
  } = useFormContext();

  const [inputValue, setInputValue] = useState('');

  const debouncedSubmitForm = useDebouncedCallback(
    handleSubmit(submitOnChange ?? (() => {})),
    submitOnChangeDelay,
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      switch (event.key) {
        case ',':
        case 'Tab':
        case 'Enter': {
          event.preventDefault();
          event.stopPropagation();
          if (event.target?.value?.length > 0 && freeSolo) {
            setValue(name, [...(getValues(name) ?? []), event.target.value], {
              shouldDirty: true,
            });
            trigger(name);
            setInputValue(''); // clear input after adding chip
          }
          break;
        }
      }
    },
    [freeSolo, getValues, name, setValue, trigger],
  );

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target?.value?.length > 0 && freeSolo) {
      setValue(name, [...(getValues(name) ?? []), event.target.value], {
        shouldDirty: true,
      });
      trigger(name);
      setInputValue(''); // clear input after blur
    }
  };

  const { success } = useMessages();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <Autocomplete
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
            defaultValue={[]}
            disabled={disabled}
            multiple
            freeSolo={freeSolo as FreeSolo}
            autoSelect
            value={value || []}
            onChange={(_, data, reason) => {
              if (reason !== 'blur') {
                onChange(data);
                trigger(name);
              }
              if (submitOnChange) {
                debouncedSubmitForm();
              }
              if (handleOnChange) {
                handleOnChange(data);
              }
            }}
            onBlur={() => {}}
            options={options}
            getOptionLabel={(option) => option || ''}
            filterSelectedOptions
            renderInput={(params: AutocompleteRenderInputParams) => {
              (params.inputProps as any).onKeyDown = handleKeyDown;
              return (
                <TextField
                  {...params}
                  onBlur={handleBlur}
                  required={required}
                  variant="outlined"
                  label={label}
                  placeholder={placeholder}
                  margin="dense"
                  fullWidth
                  error={!!get(errors, name)}
                  helperText={get(errors, name)?.message as ReactNode}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: autocomplete ? 'on' : 'new-password',
                  }}
                />
              );
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const label = autocompleteProps.getOptionLabel
                  ? autocompleteProps.getOptionLabel(option)
                  : option || '';

                return (
                  <Chip
                    label={label}
                    {...getTagProps({ index })}
                    disabled={fixedOptions.indexOf(option) !== -1 || disabled}
                    icon={<FileCopyOutlined />}
                    onClick={() => {
                      navigator?.clipboard?.writeText(label);
                      success('Skopiowano wartość do schowka');
                    }}
                  />
                );
              })
            }
            {...autocompleteProps}
          />
        );
      }}
    />
  );
};
