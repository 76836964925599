export const CookieTerms = () => (
  <ol>
    <li>
      <p>
        Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem
        informacji zawartych w plikach cookies.
      </p>
    </li>
    <li>
      <p>
        Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
        szczególności pliki tekstowe, które przechowywane są w urządzeniu
        końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron
        internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony
        internetowej, z której pochodzą, czas przechowywania ich na urządzeniu
        końcowym oraz unikalny numer.
      </p>
    </li>
    <li>
      <p>
        Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu
        pliki cookies oraz uzyskującymi do nich dostęp jest operator Serwisu,
        podmiot leczniczy: Diagdent Polska Sp. z o.o., ul. Sądowa 14/1A, 50-046
        Wrocław, KRS 0001064129, REGON 526697293, NIP 8971928708, CBCT Sp. z
        o.o., 03-253 Warszawa, ul. Białołęcka 166D, KRS: 935996, REGON:
        520643089, NIP: 5242930030 oraz CBCT Polska Sp. z o.o., 02-439 Warszawa,
        ul. Zdobnicza 8, KRS: 936013, REGON: 520643333, NIP: 5223215013.
      </p>
    </li>
    <li>
      <p>Pliki cookies wykorzystywane są w celu:</p>
    </li>
    <ol>
      <li>
        <p>
          dostosowania zawartości stron internetowych Serwisu do preferencji
          Użytkownika oraz optymalizacji korzystania ze stron internetowych; w
          szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika
          Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do
          jego indywidualnych potrzeb;
        </p>
      </li>
      <li>
        <p>
          tworzenia statystyk, które pomagają zrozumieć, w jaki sposób
          Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia
          ulepszanie ich struktury i zawartości;
        </p>
      </li>
      <li>
        <p>
          utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której
          Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać
          loginu i hasła;
        </p>
      </li>
    </ol>
    <li>
      <p>W ramach Serwisu stosowane są następujące rodzaje plików cookies:</p>
    </li>
    <ol>
      <li>
        <p>
          „niezbędne” pliki cookies, umożliwiające korzystanie z usług
          dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies
          wykorzystywane do usług wymagających uwierzytelniania w ramach
          Serwisu;
        </p>
      </li>
      <li>
        <p>
          pliki cookies służące do zapewnienia bezpieczeństwa, np.
          wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w
          ramach Serwisu;
        </p>
      </li>
      <li>
        <p>
          „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o
          sposobie korzystania ze stron internetowych Serwisu;
        </p>
      </li>
      <li>
        <p>
          „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych
          przez Użytkownika ustawień i personalizację interfejsu Użytkownika,
          np. w zakresie wybranego języka lub regionu, z którego pochodzi
          Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;
        </p>
      </li>
      <li>
        <p>
          „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom
          treści reklamowych bardziej dostosowanych do ich zainteresowań.
        </p>
      </li>
    </ol>
    <li>
      <p>
        W wielu przypadkach oprogramowanie służące do przeglądania stron
        internetowych (przeglądarka internetowa) domyślnie dopuszcza
        przechowywanie plików cookies w urządzeniu końcowym Użytkownika.
        Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień
        dotyczących plików cookies. Ustawienia te mogą zostać zmienione w
        szczególności w taki sposób, aby blokować automatyczną obsługę plików
        cookies w ustawieniach przeglądarki internetowej bądź informować o ich
        każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe
        informacje o możliwości i sposobach obsługi plików cookies dostępne są w
        ustawieniach oprogramowania (przeglądarki internetowej).
      </p>
    </li>
    <li>
      <p>
        Operator Serwisu informuje, że ograniczenia stosowania plików cookies
        mogą wpłynąć na niektóre funkcjonalności dostępne na stronach
        internetowych Serwisu.
      </p>
    </li>
    <li>
      <p>
        Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i
        wykorzystywane mogą być również przez współpracujących z operatorem
        Serwisu reklamodawców oraz partnerów.
      </p>
    </li>
  </ol>
);
