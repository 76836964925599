import { Box, Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { CellProps } from 'react-table';
import { createUseReducerPersisted } from 'use-persisted';
import { useTableActions } from '../../../../../../common/components/table/hooks/useTableActions';
import { TableContext } from '../../../../../../common/components/table/state/table.context';
import { tableReducer } from '../../../../../../common/components/table/state/table.reducer';
import {
  SortableColumn,
  Table,
} from '../../../../../../common/components/table/Table';
import { useRemoveConfirm } from '../../../../../../common/hooks/useConfirmation';
import { useGraphQLError } from '../../../../../../common/hooks/useGraphQLError';
import { ActionColor } from '../../../../../../common/types/actions';
import {
  useDeleteUserMutation,
  User,
  UserRole,
  useUsersQuery,
} from '../../../../../../generated/graphql';
import { useTableRefresh } from '../../../../../../common/hooks/useTableRefresh';
import { ProtectedRoute } from '../../../auth/ProtectedRoute';
import { UserEdit } from './UserEdit';
import { SearchPanel } from '../../../../../../common/components/search/SearchPanel';
import { TextField } from '../../../../../../common/components/form/fields/TextField';
import { TagsField } from '../../../tags/TagsField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

type Props = {};

const COLUMNS = (t: TFunction): SortableColumn<User>[] => [
  {
    Header: 'Login',
    accessor: 'login',
  },
  {
    Header: 'Imię / Firma',
    id: 'contact.firstName',
    accessor: ({ contact }) =>
      contact.firstName.length ? contact.firstName : contact.companyName,
  },
  {
    Header: 'Nazwisko',
    id: 'contact.lastName',
    accessor: ({ contact }) => contact.lastName,
  },
  {
    Header: 'Rola',
    disableSortBy: true,
    accessor: ({ roles }) => roles,
    Cell: ({ value }: CellProps<User, User['roles']>) =>
      `${value.map((role) => t(`auth.roles.${role}`)).join(', ')}`,
  },
];

const initialState = {
  orderBy: {
    columnName: 'login',
    columnOrder: 'ASC',
  },
  pagination: {
    take: 10,
    skip: 0,
  },
  filter: {
    roles: [
      UserRole.Technician,
      UserRole.Manager,
      UserRole.Admin,
      UserRole.DescribingDoctor,
      UserRole.HelpContact,
      UserRole.Registration,
      UserRole.DentalOffice,
      UserRole.Laboratory,
    ],
  },
};
const usePersistedReducer = createUseReducerPersisted(
  'userList',
  globalThis.sessionStorage,
);

const validationSchema = yup.object().shape({
  name: yup.string(),
  roles: yup.array().required().min(1),
});

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(32),
    right: theme.spacing(32),
  },
}));

export const UserPanel: React.FC<Props> = () => {
  const [state, dispatch] = usePersistedReducer(tableReducer, initialState);
  const { reloadTable } = useTableRefresh(dispatch);

  const { data, fetchMore } = useUsersQuery({
    variables: {
      where: state.filter,
      pagination: {
        skip: state.pagination.skip || 0,
        take: state.pagination.take,
      },
      orderBy: [state.orderBy],
    },
    fetchPolicy: 'network-only',
  });
  const history = useHistory();
  const snackBarError = useGraphQLError();
  const [deleteUser] = useDeleteUserMutation({ onError: snackBarError });
  const { enqueueSnackbar } = useSnackbar();
  const confirmRemoval = useRemoveConfirm();
  const { t } = useTranslation();

  const classes = useStyles();

  const tableActions = useTableActions<User, 'edit' | 'delete'>([
    {
      id: 'edit',
      Icon: EditIcon,
      title: 'Edytuj użytkownika',
      actionColor: ActionColor.ORANGE,
      roles: [UserRole.Admin, UserRole.Manager],
      onAction: ({ id }) => history.push(`${path}/edit/${id}`),
    },
    {
      id: 'delete',
      Icon: DeleteIcon,
      title: 'Usuń użytkownika',
      actionColor: ActionColor.RED,
      onAction: async ({ id }) => {
        await confirmRemoval();
        await deleteUser({
          variables: { id },
        });
        reloadTable();
        enqueueSnackbar(`Użytkownik został usunięty.`, {
          variant: 'success',
        });

        history.push(`${path}/`);
      },
    },
  ]);

  const { path } = useRouteMatch();

  useEffect(() => {
    fetchMore({
      variables: {
        where: state.filter,
        pagination: state.pagination,
        orderBy: [state.orderBy],
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  }, [fetchMore, state.filter, state.pagination, state.orderBy]);

  if (!data?.users?.items) return null;

  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/`}>
        <Box position="relative">
          <TableContext.Provider value={{ state, dispatch }}>
            <SearchPanel
              defaultValues={state.filter}
              resolver={yupResolver(validationSchema) as any}
              onSearch={(filter) =>
                dispatch({ type: 'FILTER_CHANGE', payload: filter })
              }
              resetValues={{
                name: '',
                roles: [
                  UserRole.Technician,
                  UserRole.Manager,
                  UserRole.Admin,
                  UserRole.DescribingDoctor,
                  UserRole.HelpContact,
                  UserRole.Registration,
                  UserRole.DentalOffice,
                  UserRole.Laboratory,
                ],
              }}
              mainFields={
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', md: 'row' }}
                  gap={6}
                  width="100%"
                >
                  <TextField name="name" label="Nazwisko użytkownika" />
                  <TagsField
                    fullWidth
                    name="roles"
                    freeSolo={false}
                    label="Role w systemie"
                    options={[
                      UserRole.Admin,
                      UserRole.HelpContact,
                      UserRole.Registration,
                      UserRole.Technician,
                      UserRole.Manager,
                      UserRole.DentalOffice,
                      UserRole.Laboratory,
                    ]}
                    getOptionLabel={(role) => t(`auth.roles.${role}`)}
                  />
                </Box>
              }
            />
            <Table<User>
              columnConfig={[...COLUMNS(t), tableActions]}
              data={data?.users.items as User[]}
              totalCount={data?.users?.total || 0}
              title="Użytkownicy systemu"
            />
          </TableContext.Provider>
          <Box position="absolute" bottom={-72} right={24}>
            <Fab
              color="primary"
              aria-label="add"
              className={classes.fab}
              component={RouterLink}
              to={`${path}/add`}
            >
              <AddIcon />
            </Fab>
          </Box>
        </Box>
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/add`}>
        <UserEdit />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/edit/:id`} exact>
        <UserEdit />
      </ProtectedRoute>
    </Switch>
  );
};
